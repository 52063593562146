export const settings = {
    ws: {
        base: {
            prod: {
                baseUrl: 'https://apigateway.fcs-monitoreo.com',
                prefix: '',
                apiVersion: '',
                files: '/hits/imagenes',
                monitor: {
                    url: 'https://api.fcs-monitoreo.com/covid-19',
                    credentials: {
                        email: "monitor.cms@globalhitss.com",
                        password: "8rK@1eHf_p"
                    }
                }
            },
            dev: {
                baseUrl: 'http://40.80.223.123',
                prefix: '',
                apiVersion: '',
                files: '/hits/imagenes',
                monitor: {
                    url: 'https://api.fcs-monitor.net/covid-19',
                    credentials: {
                        email: "monitor.cms@globalhitss.com",
                        password: "8rK@1eHf_p"
                    }
                }
            }
        },
        res: {
            public: {
                access: {
                    prefix: '/access',
                    resources: {
                        signIn: '/signin',
                        passwordRecovery: '/password-recovery',
                        register: '/register',
                        registerPassword: '/register/password'
                    }
                },
                verificationCode: {
                    prefix: '/verification-code',
                    resources: {
                        validate: '/validate',
                        generate: '/generate'
                    }
                },
            },
            admin: {
                menu: {
                    segment: '',
                    prefix: '/menu',
                    resources: {
                        group: '/:group',
                    }
                },
                permission: {
                    prefix: '/permission'
                },
                profile: {
                    prefix: '/profile'
                },
                modules: {
                    segment: '',
                    prefix: '/modules',
                    resources: {
                        getOne: '/:uuid',
                    }
                },
                user: {
                    prefix: '/user',
                    resources: {
                        getOne: '/:uuid',
                        edit: '/:uuid',
                        delete: '/:uuid'
                    }
                },
                group: {
                    prefix: '/group',
                    resources: {
                        getOne: '/:uuid',
                        edit: '/:uuid',
                        delete: '/:uuid',
                        list: '/list',
                        token: '/token',
                        businessDelete: '/deleteBusinessUser',
                    }
                },
                business: {
                    prefix: '/group/business',
                    resources: {
                        getOne: '/:uuid',
                        edit: '/:uuid',
                        delete: '/:uuid'
                    }
                },
                contents: {
                    segment: '/content',
                    prefix: '/content',
                    resources: {
                        getOne: '/:contentTypeId/:contentId/:langId',
                        edit: '/:contentId',
                        delete: '/:contentId?content_type_id=:contentType',
                        bulk: '/bulk',
                        publish: '/bulk',
                        unpublish: '/bulk',
                        lock: '/lock/:contentId',
                        unlock: '/unlock/:contentId',
                        clone: '/clone'
                    }
                },
                contentsType: {
                    segment: '/content',
                    prefix: '/contenttype',
                    resources: {
                        getFields: '/:contentId/field',
                        getOne: '/:contentId',
                        edit: '/:contentId',
                        delete: '/:contentId'
                    }
                },
                role: {
                    prefix: '/role',
                    resources: {
                        getOne: '/:id',
                        edit: '/:id',
                        delete: '/:id'
                    }
                },
                language: {
                    segment: '/lang',
                    prefix: '/language',
                    resources: {
                        getOne: '/:uuid',
                        edit: '/:uuid',
                        delete: '/:uuid'
                    }
                },
                log: {
                    prefix: '/log',
                    segment: '',
                    services: '/services',
                },
                menus: {
                    prefix: '/menu',
                    resources: {
                        bulkUpdate: '/bulk',
                        bulkCreate: '/bulk',
                        readOne: '/:id',
                        update: '/:id',
                        delete: '/:id'
                    }
                },
                forms: {
                    override: {
                        baseUrl: 'https://virtserver.swaggerhub.com',
                        prefix: '/moviedo/abarcms',
                        apiVersion: '/1.0.0'
                    },
                    prefix: '/forms',
                    resources: {
                        bulkUpdate: '/bulk',
                        bulkCreate: '/bulk',
                        readOne: '/:id',
                        update: '/:id',
                        delete: '/:id'
                    }
                },
                formFields: {
                    override: {
                        baseUrl: 'https://virtserver.swaggerhub.com',
                        prefix: '/moviedo/abarcms',
                        apiVersion: '/1.0.0'
                    },
                    prefix: '/forms/:uuid/field',
                    resources: {
                        bulkUpdate: '/bulk',
                        bulkCreate: '/bulk',
                        readOne: '/:uuid',
                        update: '/:uuid',
                        delete: '/:uuid'
                    }
                },
                appointments: {
                    prefix: '/appointment',
                    resources: {
                        bulkUpdate: '/bulk',
                        bulkCreate: '/bulk',
                        readOne: '/:uuid',
                        update: '/:uuid',
                        delete: '/:uuid'
                    }
                },
                dank: {
                    prefix: '/module',
                    segment: '/dank'
                },
                sidenav: {
                    prefix: '/module',
                    segment: ''
                },
                statement: {
                    override: {
                        prefix: '/moviedo/abarcms',
                        apiVersion: '/1.0.0'
                    },
                    prefix: '/statements',
                    resources: {
                        update: '/:id',
                        delete: '/:id'
                    }
                },
                faq: {
                    prefix: '/faq',
                    resources: {
                        update: '/:id',
                        delete: '/:id'
                    }
                },
                media: {
                    segment: '/media',
                    prefix: '',
                    resources: {
                        update: '/:id',
                        delete: '/:id',
                        getOne: '/:id'
                    }
                },
                mediaguga: {
                    prefix: '/media-guga',
                    resources: {
                        update: '/:id',
                        delete: '/:id'
                    }
                },
                contentType: {
                    segment: '/content',
                    prefix: '/contenttype',
                    resources: {
                        readOne: '/:id',
                        update: '/:id',
                        delete: '/:id'
                    }
                },
                contentTypeField: {
                    segment: '/content',
                    prefix: '/contenttype/:uuid/field',
                    resources: {
                        readOne: '/:id',
                        update: '/:id',
                        delete: '/:id'
                    }
                },
                field: {
                    segment: '/content',
                    prefix: '/field',
                    resources: {}
                },
                acessors: {
                    prefix: '/acessors',
                    resources: {
                        getOne: '/:id',
                        edit: '/:id',
                        delete: '/:id',
                        branchs: '/sucursales'
                    }
                },
                notification: {
                    prefix: '/notification',
                    resources: {
                        getOne: '/:uuid',
                        edit: '/:uuid',
                        delete: '/:uuid'
                    },
                    rules: {
                        prefix: '/rule',
                    },
                },
                configuration: {
                    prefix: '/configuration',
                    resources: {
                        edit: '/:uuid'
                    }
                },
                poll: {
                    prefix: '/poll',
                    resources: {
                        getOne: '/:uuid',
                        edit: '/:uuid',
                        delete: '/:uuid',
                        deleteBatch: '/batch',
                        all: '/all',
                        noSection: '/without-sections'
                    },
                    question: {
                        prefix: '/question',
                    },
                    typeQuestion: {
                        prefix: '/type',
                    },
                    groups: {
                        prefix: '/groups/update'
                    },
                },
                monitor: {
                    login: '/v1/login',
                    poll: {
                        prefix: '/v2/poll/{id}',
                        resource: {
                            add: '/add',
                            update: '/update',
                        },
                        section: '/{poll}/section',
                        business: '/{poll}/companys',
                        question: '/{section}/question',
                        activate: '/activate/{poll}',
                    },
                    list: '/v2/poll/{system}/question/{id}?admin=1',
                    filter_users: '/v2/admin/{id}/usuarios',
                },
                email: {
                    prefix: '/email'
                }
            }
        }
    },
    frontendUrl: 'http://www.sitio.com/',
    appName: 'AbarCMS',
    signIn: {
        enabled: true,
        std: {
            enabled: true,
            provider: 'auto'
        },
        google: {
            enabled: true,
            provider: 'google',
            clientId: '716145209663-5s6g552a89ujkooqan9le53gd9ep892j.apps.googleusercontent.com'
        },
        successRedirect: {
            default: '/dashboard'
        }
    },
    passwordRecovery: {
        enabled: true
    },
    register: {
        enabled: true
    },
    dashboard: {
        enabled: true,
        redirect: '/contents'
    },
    contents: {
        enabled: true
    },
    contentEdit: {
        enabled: true
    },
    contentTypes: {
        enabled: true
    },
    contentTypeEdit: {
        enabled: true
    },
    validate: {
        username: '.{2,}',
        password: '.{8,32}',
        name: '.{2,}',
        lastname: '.{2,}',
        email: '(.+)@(.+){2,}\\.(.+){2,}',
        code: '[a-zA-Z0-9]{1}',
        phone: '^[1-9][0-9]{9}'
    }
};
