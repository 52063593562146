<main>
  <!-- Content -->
  <div class="content">
    <abarcms-cms-side-menu (hiddenMenu)="hiddenMenu($event)"></abarcms-cms-side-menu>
    <section class="content-wrap" [class.active]="isMenuOpen">
      <router-outlet></router-outlet>
    </section>
  </div>
  <div class="fix"></div>
  <footer>
    <!-- footer -->
  </footer><!-- / -->
  <abarcms-loader-full *ngIf="!sessionService.user">Cargando...</abarcms-loader-full>
</main>