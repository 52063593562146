import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { AlertNotificationService } from 'src/app/services/alert-notification.service';
import { Alert, AlertType } from 'src/app/dto/Alert';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit, OnDestroy {

  @Input() id = 'default-alert'
  @Input() fade = true

  alerts: Alert[] = []
  alertSubscription: Subscription
  routeSubscription: Subscription
  color:string=''

  constructor(private router: Router,
    private alertNotificationService: AlertNotificationService) { }

  ngOnInit(): void {
    // subscribe to new alert notifications
    this.alertSubscription = this.alertNotificationService.onAlert(this.id)
      .subscribe(
        alert => {
          // clear alerts when an empty alert is received
          if (!alert.message) {
            // filter out alerts without 'keepAfterRouteChange' flag
            this.alerts = this.alerts.filter(x => x.keepAfterRouteChange)

            // remove 'keepAfterRouteChange' flag on the rest
            this.alerts.forEach(x => delete x.keepAfterRouteChange)
            return
          }
          if(alert.type == 0) {//Success
            this.color = 'Success'
          } else if (alert.type == 1) {//Error
            this.color = 'Error'
          } else if (alert.type == 2) {//Info
            this.color = 'Info'
          } else if (alert.type == 3) {//Warning
            this.color = 'Warning'
          }

          // add alert to array
          this.alerts.push(alert)

          // auto close alert if required
          if (alert.autoClose) {
            setTimeout(() => this.removeAlert(alert), 8000)
          }
        }
      )

    // clear alerts on location change
    this.routeSubscription = this.router.events
      .subscribe(
        event => {
          if (event instanceof NavigationStart) {
            this.alertNotificationService.clear(this.id)
          }
        }
      )
  }

  ngOnDestroy() {
    // unsubscribe to avoid memory leaks
    this.alertSubscription.unsubscribe()
    this.routeSubscription.unsubscribe()
  }

  removeAlert(alert: Alert) {
    // check if already removed to prevent error on auto close
    if (!this.alerts.includes(alert)) return

    if (this.fade) {
      // fade out alert
      this.alerts.find(x => x === alert).fade = true

      // remove alert after faded out
      setTimeout(() => {
        this.alerts = this.alerts.filter(x => x !== alert)
      }, 250)
    } else {
      // remove alert
      this.alerts = this.alerts.filter(x => x !== alert)
    }
  }

  cssClass(alert: Alert) {
    if (!alert) return

    const classes = []

    const alertTypeClass = {
      [AlertType.Success]: 'Success', //'28a745',
      [AlertType.Error]: 'Error', //'dc3545',
      [AlertType.Info]: 'Info', //'17a2b8',
      [AlertType.Warning]: 'Warning' //'ffc107'
    }

    classes.push(alertTypeClass[alert.type])

    return classes.join(' ')
  }

  close(alert) {
    this.removeAlert(alert)
  }
}
