import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { constants } from 'src/app/constants';
import { getPermissions, getPermissionsPage, getStorage } from 'src/app/utils/_utils';

@Component({
    selector: 'abarcms-group-detail-list',
    templateUrl: './group-detail-list.component.html',
    styleUrls: ['./group-detail-list.component.css']
})
export class GroupDetailListComponent implements OnInit {

    @Input('show-name') public showName: boolean = true;
    @Input('bussness-data') public data: Array<any> = null;
    @Input('group-data') public uuid: String = null;
    @Input() business: boolean = true;
    permissions: any
    @Input('click-event-enabled') public clickEventEnable: boolean = false;
    @Output() clicked: EventEmitter<any> = new EventEmitter();

    constructor() { }

    ngOnInit(): void {
        this.permissions = getPermissions('/groups-business')
    }

    clickOn(business) {
        if (this.clickEventEnable) {
            this.clicked.emit({
                business: this.business,
                data: business
            });
        }
    }
    existPermission(): boolean {
        return this.permissions.includes('Access')
    }
}
