import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SettingsService } from "src/app/services";
import { getHeaders, getMonitorUserId } from "./http-monitor";
import { HttpMonitorService } from "./http-monitor.service";
@Injectable({
  providedIn: "root",
})
export class VerPaginaService {
  private baseUrl: string;
  pathInfo: any;
  constructor(
    private settingsService: SettingsService,
    private http: HttpClient,
    private monitorService: HttpMonitorService
  ) {
    this.pathInfo = this.settingsService.get("pathInfo");
    this.baseUrl = this.pathInfo.base.monitor.url;
  }

  getAccesToken(dataAcces: any): Observable<any> {
    let url = this.baseUrl + "/v1/login";
    return this.http.post(url, dataAcces).pipe(
      map((json) => {
        const result = json;
        return result as any;
      })
    );
  }

  async getAllPaginas(data: any): Promise<Observable<any>> {
    let monitorUserId = await this.monitorService.getMonitorUserId();
    const headers = await this.monitorService.getHeaders();
    let url = this.baseUrl + "/v2/admin/" + monitorUserId + "/allPaginas";
    return this.http.post(url, data, { headers }).pipe(
      map((json) => {
        const result = json;
        return result as any;
      })
    );
  }
}
