<abarcms-loader-full *ngIf="loading"></abarcms-loader-full>

<div class="container-fluid">
    <div class="card border-card">
        <div class="card-content">
            <div class="row">
                <div class="col-12 card__title ">
                    <h2 class="title-list">{{pageAction['titlePage']}}</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label for="nombreGrupo">Nombre del grupo</label>
                        <input type="text" class="form-control" id="nombreGrupo"
                            placeholder="Nombre del grupo" [(ngModel)]="group.name" (keyup)="validaNombre()"
                            [style]="validaError(errors.name) ? 'border-color: red; margin-bottom: 3px; color: red ':''">
                        <span *ngIf="validaError(errors.name)" style="color: red; font-size: 14px;">{{errors.name}}</span>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group ">
                        <label for="correoContactoGrupo">Correo electrónico del contacto del grupo</label>
                        <input class="form-control" type="email" name="correoContactoGrupo"
                            id="correoContactoGrupo" placeholder="Correo principal" (keyup)="validaCorreo()" [(ngModel)]="group.email"
                            [style]="validaError(errors.email) ? 'border-color: red; margin-bottom: 3px; color: red ':''">
                        <span *ngIf="validaError(errors.email)" style="color: red; font-size: 14px;">{{errors.email}}</span>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group row">
                        <div class="col-12">
                            <label for="telefonoContactoGrupo">Teléfono de contacto del grupo</label>
                        </div>
                        <div class="col-6">
                            <input class="form-control" type="text" name="telefonoContactoGrupo"
                                id="telefonoContactoGrupo" placeholder="Telefono" [(ngModel)]="group.phone" NumbersOnly
                                [allowDecimals]="false" [allowSign]="false" (keyup)="validaTelefono()" maxlength="10"
                                [style]="validaError(errors.phone) ? 'border-color: red; margin-bottom: 3px; color: red ':''">
                            <span *ngIf="validaError(errors.phone)" style="color: red; font-size: 14px;">{{errors.phone}}</span>
                        </div>
                        <div class="col-6">
                            <input class="form-control" type="text"
                                name="telefonoGrupoExtension" id="telefonoGrupoExtension"
                                [(ngModel)]="group.ext" placeholder="Ext"
                                NumbersOnly [allowDecimals]="false" [allowSign]="false"
                                [style]="validaError(errors.ext) ? 'border-color: red; margin-bottom: 3px; color: red ':''">
                            <span *ngIf="validaError(errors.ext)" style="color: red; font-size: 14px;">{{errors.ext}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="botonera" *ngIf="!showUIitemsEdit">
                <button class="btn btn-dark btn-block m-0" routerLink="/groups-business">Regresar</button>

                <button type="button" class="btn btn-primary btn-block m-0" (click)="guardarItem('create')" *ngIf="showButtonSave">Siguiente</button><!--onSubmit()-->
            </div>
            <div class="botonera" *ngIf="showUIitemsEdit">
                <button type="button" class="btn btn-dark btn-block m-0" (click)="cancel()">Cancelar</button>

                <button type="button" class="btn btn-primary btn-block m-0" (click)="guardarItem('save')" *ngIf="showButtonSave">Guardar</button>
            </div>
        </div>
    </div>
</div>