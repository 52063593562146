<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            {{title}}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClose()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p class="alert alert-danger" *ngIf="!message">Estas apunto de eliminar este registro</p>
        <p *ngIf="message" [innerHTML]="message"></p>
        <form class="text-left" *ngIf="item">
            <div class="form-group" *ngIf="item.uuid || item.id">
                <label for="uuid">Identificador</label>
                <input type="text" readonly class="form-control" value="{{(item.uuid) ? item.uuid : item.id}}">
            </div>
            <div class=" form-group" *ngIf="item.name">
                <label for="name">Nombre{{(item.lastname) ? '(s)' : ''}}</label>
                <input type="text" readonly class="form-control" value="{{item.name}}">
            </div>
            <div class="form-group" *ngIf="item.lastname">
                <label for="lastname">Apellido(s)</label>
                <input type="text" readonly class="form-control" value="{{item.lastname}}">
            </div>
            <div class="form-group" *ngIf="item.email">
                <label for="email">Email</label>
                <input type="email" readonly class="form-control" value="{{item.email}}">
            </div>
        </form>
    </div>
    <div class="modal-footer justify-content-end">
        <div class="col-md-4">
            <button class="btn-primary text-center" (click)="onConfirmDelete()">Aceptar</button>
        </div>
        <div class="col-md-4">
            <button class="btn-secondary text-center" (click)="onCancelDelete()">Cancelar</button>
        </div>
    </div>
</div>