import { ResponseData } from '../dto/ResponseData';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { constants } from '../constants';
import { getStorage, setStorage, existStorage } from './_utils';
import { throwError } from 'rxjs';
import { SeccionMonitorService } from '../screens/admin/seccion-monitor/services/seccion-monitor.service';
import { element } from 'protractor';
/**
 *
 * @param response Respuesta obtenida de la promesa
 */
export function createResponseData(rs: ResponseData, modalHelper?: any): ResponseData {
    let r: ResponseData = new ResponseData();
    r.headers = rs.headers;
    r.status = rs.status;
    r.body = rs.body;
    if (modalHelper) {
        modalHelper.changeLoading(false);
    }
    return r;
}
/**
 *
 * @param options Valores a enviar en la cabecera
 */
export function makeHeaders(o: object): object {
    for (const item in o) {
        o[item] = o[item].toString();
    }
    let objectHeader = {
        'services':'services',
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+sessionStorage.getItem('monitorToken'),
        ...o
    }
    let headers: HttpHeaders = new HttpHeaders(objectHeader);
    return { headers: headers, observe: 'response' }
}
/**
 *
 * @param options Valores a enviar en la cabecera
 */
export function makeHeadersFormData(o: object): object {
    for (const item in o) {
        o[item] = o[item].toString();
    }
    let objectHeader = {
        'Authorization':'Bearer '+sessionStorage.getItem('monitorToken'),
        ...o
    }
    let headers: HttpHeaders = new HttpHeaders(objectHeader);
    return { headers: headers, observe: 'response' }
}
/**
 *
 * @param rs Respuesta obtenida de la promesa
 */
export function getHeaders(rs: ResponseData) {
    let headers = [];
    if (!!rs.headers) {
        rs.headers.keys().map(i => {
            headers.push(rs.headers.get(i));
        });
    }
    return headers;
}
/**
 *
 * @param rs Respuesta obtenida de la promesa
 * @param key Nombre de la cabecera a obtener dicho valor, si no existe retornara null
 */
export function getHeader(rs: ResponseData, k: string) {
    return rs.headers.get(k);
}
/**
 *
 * @param rs Respuesta obtenida de la promesa
 */
export function getData(rs: ResponseData, item: any = null) {
    let data = rs.body;
    if (data) {
        if (item) {
            if (!data[item]) return null;
            return data[item]
        }
    }
    return data;
}
export function getObject(rs: ResponseData, item: any = null) {
    let data = rs.body;
    if (data) {
        if (item) {
            if (Array.isArray(data)) {
                if (!data[0][item]) return null;
                return data[0][item];
            }
            if (!data[item]) return null;
            return data[item];
        }
        if (Array.isArray(data)) return data[0];
    }
    return data;
}
export function getArray(rs: ResponseData) {
    let data = rs.body;
    if (data) {
        if (Array.isArray(data) == false) return [data];
        return data;
    }
    return [];
}
/**
 *
 * @param rs Respuesta obtenida de la promesa
 */
export function getStatus(rs: ResponseData) {
    //console.log ('getStatus rs' + rs )
    if (!rs) {
        return undefined;
    }
    let status = {
        'httpStatus': rs.status,
        'codeStatus': '',
        'innerStatus': '',
        'message': '',
    }
    if (getHeaders(rs).length === 0) {
        status.codeStatus = 'No pudo leer el header';
        status.message = 'No pudo leer el header';
    } else {
        //console.log ('getStatus else' + rs )
        status.codeStatus = getHeader(rs, 'x-status-code');
        status.innerStatus = getHeader(rs, 'x-inner-status-code');
        status.message = getMessage(rs);
    }
    return status;
}
/**
 *
 * @param rs Respuesta obtenida de la promesa
 */
export function getValid(rs: ResponseData, options: any, callback?: Function): any {
    let valid = false
    const { httpStatus, codeStatus, innerStatus, message } = getStatus(rs);
    if (httpStatus < 300) {
        valid = true;
    }
    if (valid) {

        if (callback) {
            callback()
        }
        return createResponseData(rs, options.modalHelper)
    }
    else {
            return paintModalError(rs, options);
            
    }
}
/**
 *
 * @param rs Respuesta obtenida de la promesa
 */
export function getMessage(rs: ResponseData) {

    if (!rs) {
        return undefined;
    }
    return getHeader(rs, 'x-status-message');
}
/**
 *
 * @param pr parametros a buscar en query params
 * @param ar ActiveRoute
 */
export function getQueryParams(dt: any, ar: ActivatedRoute) {
    ar.queryParams.subscribe((rs) => {
        Object.keys(rs).map(i => {
            dt[i] = rs[i];
        })
    });
    return dt;
}

export function paintModalError(rs, options) {
    let error
    let msg
    if (rs.status>=400 && rs.status<=440) {
        let e = rs.error['error'];
        if(Array.isArray(e)) {
            msg = ""
            e.forEach(element=>{
                msg += element.error
            })
        }else {
            msg = error != null && error!='' ? error:getMessage(rs)
            msg =  !msg ? msg : rs.error['message']
            msg =  !msg ? msg : rs.error
        }
        error = {
            title: options.hasOwnProperty('title') ? options.title : 'Error',
            message: msg ,
            show: true
        }
        
    }else{
        error = {
            title: options.hasOwnProperty('title') ? options.title : 'Error',
            message: options.settings.get('production') ? getMessage(rs) : getStatus(rs),
            show: true
        }
        
    }
    options.modalHelper.changeErrorModal(error);
        options.modalHelper.changeLoading(false);
        return false;
}

export function hiddenModalLoading(loading: object, modalHelper) {
    let stop = false;
    for (const key in loading) {
        if (loading[key]) {
            stop = true;
            break;
        }
    }
    modalHelper.changeLoading(stop);
}

export function changeImageProfile(imageID: string, imageURL: string, modalHelper) {
    let userData = getStorage(constants.ls.sessionProfile, true);
    if (!!userData) {
        userData = { ...userData, image: imageID, imageURL: imageURL };
        setStorage(constants.ls.sessionProfile, userData);
    }
    modalHelper.changeImageProfile(imageURL);
}

export function handleError(error: HttpErrorResponse, options: any) {
        return paintModalError(error, options);
    
}
