import { Component, Input, Output, EventEmitter, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { Paginator } from 'src/app/dto';

@Component({
  selector: 'app-endpoint-paginator',
  templateUrl: './endpoint-paginator.component.html',
  styleUrls: ['./endpoint-paginator.component.css']
})
export class EndpointPaginatorComponent implements OnInit, OnChanges {

    @Input() items: Array<any>;
    @Input() paginator: Paginator;
    @Input() public restartPagination: boolean;
    @Output() changePage: EventEmitter<any> = new EventEmitter();

    public totalPages = 1;
    public pagination = [];
    private ellipsisBefore = false;
    private ellipsisAfter = false;

    ngOnInit() {
        this.initializePaginator();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.items) {
            this.items = changes.items.currentValue;
        }
        this.initializePaginator();
    }

    private initializePaginator = () => {
        if (!!this.items && this.items.length > 0) {
            this.totalPages = Math.ceil(this.items.length / this.paginator.itemsPerPage);
        }
        this.paginate();
    }

    public setPage(page: number) {
        this.paginate();
        this.changePage.emit(page);
    }

    public paginate = () => {
        this.pagination = [];
        this.ellipsisAfter = false;
        this.ellipsisBefore = false;
        for (let i = 0; i < this.totalPages; i++) {
            const decider = this.doPagination(i + 1, this.paginator, this.totalPages);
            if (!!decider) {
              this.pagination.push(decider);
            }
        }
    }

    private doPagination = (current: number, paginator: Paginator, total: number) => {
        if (total >= 5) {
            if (current < paginator.currentPage - 2 && current > 2 && !this.ellipsisBefore) {
                if (paginator.currentPage > 2) {
                    this.ellipsisBefore = true;
                    return '...';
                }
                return undefined;
            }
            if (current > paginator.currentPage + 2 && current <= total - 2 && !this.ellipsisAfter) {
                if (paginator.currentPage <= total - 2) {
                    this.ellipsisAfter = true;
                    return '...';
                }
                return undefined;
            }
            if (current <= 3 || current >= total - 2 || (current >= paginator.currentPage - 1 && current <= paginator.currentPage + 1)) {
                return current;
            }
            return undefined;
        }
        return current;
    }
}
