import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { PollChoices, PollQuestion, PollSection } from 'src/app/dto/Poll';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { clone } from 'src/app/utils/_utils';

@Component({
  selector: 'app-poll-question-type',
  templateUrl: './poll-question-type.component.html',
  styleUrls: ['./poll-question-type.component.css']
})
export class PollQuestionTypeComponent implements OnInit, OnDestroy, OnChanges {
  subscription: Subscription = new Subscription
  @Input() data: PollChoices[] = []
  @Input() index: number
  @Input() parent: PollQuestion
  @Input() section: PollSection
  @Input() type: string
  @Input() isFormPublicate: boolean = false
  @Output() onAction: EventEmitter<any> = new EventEmitter

  questions: PollQuestion[] = []
  module: string = 'choices'
  choiceData = {
    questions: [],
    questionSelected: [],
    questionSelectedData: [],
    choiceTitle: '',
    choiceTitleFail: false,
    validation: false,
  }
  showComponent: boolean = false

  constructor(
    private modalHelper: ModalHelperService
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.modalHelper.poll$
        .subscribe(
          value => {
            if (value?.questions) {
              this.questions = this.questionsActive()
            }
            if (value?.validation) {
              this.choiceData.validation = value?.validation
            }
          }
        )
    )
  }
  // --
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
  // --
  ngOnChanges(changes: SimpleChanges) {
    if (changes.type) {
      if (changes.type.currentValue != changes.type.previousValue) {
        this.reset()
      }
    }
    this.getQuestions()
  }
  // --
  reset() {
    this.choiceData = {
      questions: [],
      questionSelected: [],
      questionSelectedData: [],
      choiceTitle: '',
      choiceTitleFail: false,
      validation: false,
    }
    // --
    if (this.data.length) {
      this.data.map((xData, iData) => {
        this.choiceData.questionSelected[iData] = ''
        if (xData.nested.length) {
          this.choiceData.questionSelected[iData] = xData.nested[0].uuid
        }
        this.onSection('nested', iData)
        if (iData == this.data.length - 1) {
          this.showComponent = true
        }
      })
    }
    else {
      this.showComponent = true
    }
  }
  // --
  onSection(action: string, index?: number) {
    switch (action) {
      case 'add':
        this.choiceData.choiceTitleFail = false
        if (this.choiceData.choiceTitle.trim().length) {
          this.onAction.emit({
            module: this.module,
            action: 'add',
            data: new PollChoices({ title: this.choiceData.choiceTitle, parentuuid: this.parent.uuid }),
          })
          setTimeout(() => {
            this.choiceData.choiceTitle = ''
            this.choiceData.questionSelected[this.data.length - 1] = ''
            this.choiceData.questionSelectedData[this.data.length - 1] = ''
          })
        }
        else {
          this.choiceData.choiceTitleFail = true
        }
        this.modalHelper.pollChange({
          module: 'questions',
          invalidForm: true,
          section: this.section.uuid,
          invalid: this.choiceData.choiceTitleFail,
          errors: this.choiceData.choiceTitleFail ? ['choice'] : []
        })
        break;
      case 'delete':
        this.questionsActive().map(x => {
          if (x.uuid == this.choiceData.questionSelected[index]) {
            x.parentuuid = this.parent.uuid
          }
        })
        this.data.splice(index, 1)
        this.choiceData.questionSelected.splice(index, 1)
        this.choiceData.questionSelectedData.splice(index, 1)
        this.getQuestions()
        this.modalHelper.pollChange({
          nestedQuestions: this.choiceData.questionSelectedData,
          question: this.parent.uuid,
          section: this.section.uuid,
        })
        break;
      case 'nested':
        if (this.choiceData.questionSelected[index]) {
          this.questions.map(x => {
            if (x.uuid == this.choiceData.questionSelected[index]) {
              x.parentuuid = this.data[index].uuid
              this.choiceData.questionSelectedData[index] = x
            }
          })
          this.data[index].nested = []
          this.data[index].nested.push(this.choiceData.questionSelectedData[index])
        }
        else {
          this.data[index].nested = []
        }

        this.getQuestions()
        this.modalHelper.pollChange({
          nestedQuestions: this.choiceData.questionSelected,
          question: this.parent.uuid,
          section: this.section.uuid,
        })
        break;
    }
  }
  // --
  nestedAux: any = {}
  setQuestions(index: number): any {
    let questions = []
    let nestedQuestions = []
    this.nestedAux = {}
    if (this.choiceData.questionSelected.length) {
      this.questions.map(xQuestion => {
        xQuestion.choices.map(xChoices => {
          xChoices.nested.map(xNested => {
            nestedQuestions.push(xNested.uuid)
            // x.choices.map(xChoices => {
            //   xChoices.nested.map(xNested => {
            if (!this.nestedAux[xQuestion.uuid]) {
              this.nestedAux[xQuestion.uuid] = []
            }
            if (!this.nestedAux[xQuestion.uuid][xNested.uuid]) {
              this.nestedAux[xQuestion.uuid].push(xNested.uuid)
            }
            //   })
            // })
          })
        })
      })
      this.questions.map((x, ix) => {
        if (!nestedQuestions.includes(x.uuid)) {
          questions.push(x)
        }
        if (this.choiceData.questionSelected[index] == x.uuid) {
          questions.push(x)
        }
      })
    }
    return questions
  }
  // --
  getQuestions() {
    setTimeout(() => {
      this.modalHelper.pollChange({
        questions: true
      })
    }, 500)
  }
  // --
  questionsActive(): any {
    return this.section.questions.filter(x => x.active)
  }
  // --
  errorChoicesLength(): boolean {
    return this.data.length < 2
  }
  // --
  existParentQuestion(question, uuid) {
    if (this.nestedAux[question]) {
      return this.nestedAux[question].includes(uuid)
    }
    return false
  }
  // --
  // --
  media: any = {
    filesToUpload: [],
    files: [],
    isSingleFiles: null,
    mediaChange: false,
  }
  isSingleFile($event) {
    this.media['isSingleFiles'] = $event;
    if (this.media['files'].length) if (this.media['isSingleFiles']) this.media['files'] = [this.media['files'].pop()];
  }
  // --
  getFilesToUpload($event) {
    setTimeout(() => {
      this.media['filesToUpload'] = $event;
      if (this.media['filesToUpload'].length) {
        let url = this.media['filesToUpload'][0].hasOwnProperty('url') ? 'url' : 'url';
        this.choiceData.choiceTitle = this.media['filesToUpload'][0][url] + ''
        this.onSection('add')
        setTimeout(() => {
          this.media.filesToUpload = []
          this.media.files = []
        }, 200)
      }
      else {
        this.choiceData.choiceTitle = ''
      }
    }, 50)
  }
}