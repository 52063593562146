<div class="card " style="min-height: 380px;" *ngIf="mode == 'questions'">
    <div class="card-body">
        <div class="form-group">
            <label class="title" for="estadoGrupo">Preguntas</label>
            <p class="subtitle">Seleccione la pestaña para agregar o visualizar las preguntas</p>

            <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <ng-container *ngFor="let section of data; index as keySection">
                        <ng-container *ngIf="section.active">
                            <a class="nav-section nav-link" [attr.id]="'nav-'+section.systemname+'-tab'"
                                data-toggle="tab" [href]="'#nav-'+section.systemname" role="tab"
                                [attr.aria-controls]="'nav-'+section.systemname" aria-selected="true"
                                [ngClass]="{'is-invalid': validateData.haveError[keySection]}"
                                [ngStyle]="validateData.haveError[keySection] && {'border-color': 'red','margin-bottom': '3px'}"
                                placement="bottom"
                                [ngbTooltip]="validateData.error[keySection] ? 'Complete los campos requeridos' : ''">
                                {{section.title}}
                            </a>
                        </ng-container>
                    </ng-container>
                </div>
            </nav>
            <div class="tab-content mt-3" id="nav-tabContent">
                <ng-container *ngFor="let section of data; index as keySection">
                    <ng-container *ngIf="section.active">
                        <div class="tab-pane fade" [attr.id]="'nav-'+section.systemname" role="tabpanel"
                            [attr.aria-labelledby]="'nav-'+section.systemname+'-tab'">

                            <div class="form-group">
                                <label class="title">Descripción de la sección</label>
                                <textarea class="form-control" cols="3" placeholder="Descripción de la sección"
                                    style="height: 73px !important;" [(ngModel)]="section.description"
                                    [readonly]="isFormPublicate"></textarea>
                                <!-- <textarea class="form-control" cols="3" placeholder="Descripción de la sección"
                                    style="height: 73px !important;" [(ngModel)]="section.description"
                                    (keydown)="onWrite(keySection, 'description')"
                                    (blur)="onWrite(keySection, 'description')"
                                    [ngClass]="{'is-invalid invalidFieldForm': validateData.field[keySection].description}"
                                    [ngStyle]="validateData.field[keySection].description && {'border-color': 'red','margin-bottom': '3px'}"></textarea>
                                <div class=" invalidFieldForm" style="color: red;font-size: 13px;">
                                    <div *ngIf="validateData.field[keySection].description">
                                        Este campo es requerido
                                    </div>
                                </div> -->
                            </div>

                            <div class="form-group" cdkDropList (cdkDropListDropped)="drop($event, keySection)">
                                <ng-container
                                    *ngFor="let question of section.questions; index as key; last as lastItem">
                                    <ng-container *ngIf="question.active">
                                        <div class="row" cdkDrag>
                                            <div class="custom-placeholder" *cdkDragPlaceholder></div>

                                            <button type="button" cdkDragHandle placement="right"
                                                ngbTooltip="Arrastra el bloque a la posición deseada"
                                                class="btn btn-link mb-0" [ngClass]="{disabled: isFormPublicate}">
                                                <span class="fas fa-bars" *ngIf="!isFormPublicate"></span>
                                            </button>

                                            <div class="col-10">
                                                <app-poll-question [data]="question" [parent]="section" [index]="key"
                                                    [indexParent]="keySection" (onAction)="onActionQuestion($event)"
                                                    [pollTypeQuestions]="pollTypeQuestions"
                                                    [isFormPublicate]="isFormPublicate">
                                                </app-poll-question>
                                            </div>

                                            <ng-container *ngIf="!isFormPublicate">
                                                <div class="col-1" *ngIf="lastItem">
                                                    <div class="dropdown">
                                                        <button class="button-add-q btn btn-primary" type="button"
                                                            id="dropdownMenuAdd" data-toggle="dropdown"
                                                            aria-haspopup="true" aria-expanded="false">
                                                            <span aria-hidden="true" class="fas fa-plus"></span>
                                                        </button>
                                                        <ul class="dropdown-menu dropdown-menu-right"
                                                            aria-labelledby="dropdownMenuAdd">
                                                            <li>
                                                                <a class="dropdown-item"
                                                                    (click)="onActionQuestion('add', keySection)">
                                                                    Agregar nueva pregunta
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a class="dropdown-item"
                                                                    (click)="onActionQuestion('add-repository', keySection)">
                                                                    Agregar pregunta desde repositorio
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </ng-container>

                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>

                        </div>
                    </ng-container>
                </ng-container>
            </div>

        </div>
    </div>
</div>

<ng-container *ngIf="mode == 'repository'">
    <div class="mt-4">
        <ng-container *ngFor="let section of data; index as keySection">
            <ng-container *ngFor="let question of section.questions; index as key; last as lastItem">
                <ng-container *ngIf="question.active">
                    <app-poll-question [data]="question" [parent]="section" [index]="key" [indexParent]="keySection"
                        (onAction)="onActionQuestion($event)" [pollTypeQuestions]="pollTypeQuestions" [mode]="mode">
                    </app-poll-question>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</ng-container>

<div class="modal fade show" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static"
    [style.display]="'block'" *ngIf="questionData.showRepository > -1">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg" role="document">
        <div class="modal-content">
            <div class="py-2">
                <div class="col-12">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="onActionQuestion('add-repository')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="col-12 mt-3">
                    <label>Preguntas</label>
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" placeholder="" aria-label="Recipient's username"
                            aria-describedby="question-repository">
                        <div class="input-group-append">
                            <button class="btn btn-secondary" type="button" id="question-repository">Buscar</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="list-group">
                    <ng-container *ngFor="let item of questionsRepository">
                        <a class="list-group-item list-group-item-action">
                            <div class="d-flex justify-content-between">
                                {{ item.title }}
                                <button class="btn btn-primary icon-pulls mb-0" placement="left"
                                    ngbTooltip="Añadir pregunta a la encuesta" (click)="onActionQuestion('copy', item)">
                                    <span aria-hidden="true" class="fas fa-plus-square"></span>
                                </button>
                            </div>
                        </a>
                    </ng-container>
                    <p *ngIf="questionsRepository.length == 0" class="alert alert-warning text-center">
                        No se encontraron datos
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>