<div class="container-fluid">
    <div class="card border-card">
        <div class="card-content">
            <div class="row title-elements">
                <div class="mx-auto">
                    <img src="assets/images/logo.svg" class="monitor-logo" alt="">
                </div>
                <div class="col-12">
                    <h4>Bienvenido al administrador de contenidos de monitor</h4>
                </div>
            </div>
            <div class="row mb-20 top-log">
                <div class="card__title col-6">
                    <h3>Últimas actividades</h3>
                </div>
                <div class="col-6" style="margin-top: -10px;">
                    <div class="col-12 col-xl-6">
                        <!-- <abarcms-input-search [items]="logsFound" [fieldsToSearch]="['module']"
                            (dataFound)="dataFound($event)" [placeholder]="'Buscar...'">
                        </abarcms-input-search> -->
                    </div>
                </div>
            </div>
            <div class="mt-30">
                <div id="contenido-table_wrapper" class="dataTables_wrapper no-footer">
                    <table style="width:100%" class="table">
                        <thead>
                            <tr>
                                <th>Módulo</th>
                                <th>Acción</th>
                                <th>Fecha</th>
                                <th style="max-width: 475px !important;">Elemento</th>
                                <th>Usuario</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of logsPage" style="height: 45.5px !important;">
                                <td style="font-size: 14px;">{{ item.module }}</td>
                                <td style="font-size: 14px;">{{ item.actions }}</td>
                                <td style="font-size: 14px;">{{ item.created }}</td>
                                <td
                                    style="font-size: 14px; max-width: 475px !important; overflow-y: hidden; overflow-x: auto; height: 45.5px;">
                                    {{ subString(item.field, 65) }}</td>
                                <td style="font-size: 14px;">{{ item.name }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-12 mt-4">
                <abarcms-paginator [total]="paginator.total" [initialPage]="paginator.current"
                    [perPage]="paginator.perPage" [maxPages]="5" [lblFirst]="'Primera'" [lblLast]="'Última'"
                    [lblPrev]="'Anterior'" [lblNext]="'Siguiente'" [btnSec]="true" [btnPri]="true"
                    (changePage)="changePage($event)" [align]="'center'"></abarcms-paginator>
            </div>
            <p *ngIf="logsPage.length == 0" class="alert alert-warning text-center">No se encontraron datos</p>
        </div>
    </div>
</div>