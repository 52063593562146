import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SettingsService } from "src/app/services";
import { makeHeaders } from "src/app/utils/_http";
import { getHeaders, getMonitorToken, getMonitorUserId } from "./http-monitor";
import { HttpMonitorService } from "./http-monitor.service";

@Injectable({
  providedIn: "root",
})
export class CrearPaginaService {
  private baseUrl: string;
  pathInfo: any;
  constructor(
    private settingsService: SettingsService,
    private http: HttpClient,
    private monitorService: HttpMonitorService
  ) {
    this.pathInfo = this.settingsService.get("pathInfo");
    this.baseUrl = this.pathInfo.base.monitor.url;
  }

  async setCreatePage(data: any): Promise<Observable<any>> {
    let monitorUserId = await this.monitorService.getMonitorUserId();
    let monitorToken = await this.monitorService.getMonitorToken();
    let url = this.baseUrl + "/v2/admin/" + monitorUserId + "/seccion/pagina";
    return this.http.post<any>(
      url,
      data,
      makeHeaders({ Authorization: "Bearer " + monitorToken })
    );
  }

  async setUpdatePage(data: any, idPagina: string): Promise<Observable<any>> {
    let monitorUserId = await this.monitorService.getMonitorUserId();
    let monitorToken = await this.monitorService.getMonitorToken();
    let url =
      this.baseUrl +
      "/v2/admin/" +
      monitorUserId +
      "/seccion/pagina/" +
      idPagina;
    return this.http.put<any>(
      url,
      data,
      makeHeaders({ Authorization: "Bearer " + monitorToken })
    );
  }

  async getOnePage(idPagina: number): Promise<Observable<any>> {
    let monitorUserId = await this.monitorService.getMonitorUserId();
    const headers = await this.monitorService.getHeaders();
    let url =
      this.baseUrl +
      "/v2/admin/" +
      monitorUserId +
      "/seccion/pagina/" +
      idPagina;
    return this.http.get<any>(url, { headers });
  }
}
