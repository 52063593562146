<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">
            {{title}}
        </h5>
    </div>
    <div class="modal-body">
        <p *ngIf="message" [innerHTML]="message"></p>
    </div>
    <div class="modal-footer justify-content-end">
        <div class="col-md-4">
            <button class="btn-primary text-center" (click)="onConfirm()">Aceptar</button>
        </div>
    </div>
</div>