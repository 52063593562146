export const constants = {
    ls: {
        session: '_ls_session_access_token',
        codeVerify: '_ls_code_verify',
        passwordRecovery: '_ls_password_recovery',
        sessionPermission: '_ls_session_permissions',
        pagesPermission: '_ls_page_permissions',
        pageMenu: '_ls_page_menu',
        sessionProfile: '_ls_session_profile',
        catalogRoles: '_ls_catalog_roles',
        catalogGroups: '_ls_catalog_groups',
        catalogModules: '_ls_catalog_modules',
        catalogPermissions: '_ls_catalog_permissions',
        catalogFields: '_ls_catalog_fields',
        catalogLogs: '_ls_catalog_logs',
        catalogPolls: '_ls_catalog_polls',
        sessionMonitor: '_ls_session_monitor',
    },
    permission: {
        create: 'Create',
        edit: 'Update',
        delete: 'Delete',
        read: 'Access',
        status: 'Status',
        traduction: 'Translate'
    },
    ignoredFields: [
        'id',
        'uuid',
        'title',
        'locked',
        'status',
        'language',
        'system_name',
        'content_type',
        'parentuuid',
        'author',
        'password',
        'created',
        'updated',
        'deleted',
    ],
    logs: {
        post: 'Creación',
        put: 'Actualización',
        delete: 'Eliminación',
        patch: 'Actualización',
        login: 'Inicio de sesión'
    },
    screens: {
        signIn: {
            path: 'sign-in'
        },
        register: {
            path: 'register',
            code: 'code-verify',
            add: 'add-password',
            confirm: 'confirm'
        },
        passwordRecovery: {
            path: 'password-recovery',
            code: 'code-verify',
            change: 'update-password',
            confirm: 'confirm'
        },
        dashboard: {
            path: 'dashboard'
        },
        users: {
            path: 'users',
            create: 'create',
            edit: 'edit',
            read: 'read',
            delete: 'delete'
        },
        contentTypes: {
            getFields: '/:contentId/field',
            getOne: '/:contentId',
            edit: '/:contentId',
            delete: '/:contentId'
        },
        contents: {
            create: 'create',
            delete: 'delete',
            edit: 'edit',
            path: 'contents',
            read: 'read',
            getOne: 'getOne',
            bulk: 'bulk',
            lock: 'lock',
            unlock: 'unlock',
            recover: 'recover',
            clone: 'clone'
        },
        roles: {
            path: 'roles',
            create: 'create',
            edit: 'edit',
            read: 'read',
            delete: 'delete'
        },
        groups: {
            path: 'groups-business',
            create: 'create',
            edit: 'edit',
            read: 'read',
            delete: 'delete',
            createTemplate: 'create-template',
            listCompany: 'listCompany',
        },
        business: {
            path: 'groups',
            create: 'create',
            edit: 'edit',
            read: 'read',
            delete: 'delete'
        },
        languages: {
            path: 'idioms',
            create: 'create',
            edit: 'edit',
            delete: 'delete'
        },
        media: {
            path: 'media',
            create: 'create',
            edit: 'edit',
            delete: 'delete'
        },
        folder: {
            path: 'media/folder',
            create: 'create',
            edit: 'edit',
            delete: 'delete'
        },
        tag: {
            path: 'media/tag',
            create: 'create',
            edit: 'edit',
            delete: 'delete'
        },
        acessors: {
            path: 'acessors',
            create: 'create',
            edit: 'edit',
            delete: 'delete'
        },
        notifications: {
            path: 'notifications',
            create: 'create',
            edit: 'edit',
            read: 'read',
            delete: 'delete'
        },
        avisos: {
            path: 'avisos',
            create: 'create',
            edit: 'edit',
            read: 'read',
            delete: 'delete'
        },
        empresas: {
            path: 'empresas',
            empresa_nueva: 'empresa_nueva',
            empresa_edicion: 'empresa_edicion',
            editByGroup: 'editByGroup',
            empresa_eliminacion: 'empresa_eliminacion'
        },
        polls: {
            path: 'polls',
            create: 'create',
            edit: 'edit',
            read: 'read',
            delete: 'delete'
        },
        questions: {
            path: 'polls/questions',
            create: 'create',
            edit: 'edit',
            read: 'read',
            delete: 'delete'
        },
        configuration: {
            path: 'configuration',
            create: 'create',
            edit: 'edit',
            delete: 'delete'
        },
    },
    pagination: {
        lblFirst: 'Primera',
        lblLast: 'Última',
        lblPrev: 'Anterior',
        lblNext: 'Siguiente',
        currentPage: 1,
        itemsPerPage: 1000,
        maxPages: 1000,
        size: 'lg',
        align: 'center'
    },
    paginationConfig: {
        'X-Current-Page': 0,
        'X-Items-Page': 1000
    },
    datesFormat: {
        month: 1,
        day: 2,
        year: 0,
        hour: 3,
        minute: 4,
        seconds: null
    },
    statuses: {
        published: 1,
        unpublished: 0
    },
    messages: {
        passwordRecovery: {
            success: 'Correo de recuperación de contraseña enviado',
            error: "Error al recuperar la contraseña, Correo inválido",
        }
    },
    estatusPolls: {
        inactivo: 'INACTIVO',
        activo: 'ACTIVO',
        noAutorizado: 'NO AUTORIZADO'
    },
    months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
};
