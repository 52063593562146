import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseData } from 'src/app/dto/ResponseData';

@Injectable({
  providedIn: 'root'
})
export abstract class FaqDataService {
  abstract create(body: any): Observable<ResponseData>;
  abstract read(): Observable<ResponseData>;
  abstract update(id: any, body: any): Observable<ResponseData>;
  abstract delete(id: string): Observable<ResponseData>;
}
