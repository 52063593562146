import {
  Directive,
  Output,
  Input,
  EventEmitter,
  HostBinding,
  HostListener
} from '@angular/core';

@Directive({
  selector: '[appDragAndDropFileUpload]'
})
export class DragAndDropFileUploadDirective {
  @HostBinding('class.fileover') fileOver: boolean;
  @Output() fileDropped: EventEmitter<Array<any>> = new EventEmitter();
  constructor() { }
  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }
  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }
  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    let files: Array<any> = [];
    for (let i = 0; i < evt.dataTransfer.files.length; i++) {
      files.push(evt.dataTransfer.files[i]);
    }
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }
}