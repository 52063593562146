import { AfterContentInit, Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator/paginator";
import { CatGrupoVisualizacion } from "../../dto/secciones-monitorDTO";
import { SeccionMonitorService } from "../../services/seccion-monitor.service";
import { constantes } from "../../constants/seccion-monitor";
import { AsignaGrupoPaginaService } from "../../services/asigna-grupo-pagina.service";
import { Router } from "@angular/router";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-grupo-visualizacion",
  templateUrl: "./grupo-visualizacion.component.html",
  styleUrls: ["./grupo-visualizacion.component.css"],
})
export class GrupoVisualizacionComponent implements OnInit, AfterContentInit {
  pageSize = constantes.pagination.pageSize; //<-------------------------------------
  i: number = 1; //         --
  desde: number = 0; //      --variables para el paginador
  hasta: number = constantes.pagination.pageSize; //      --
  total_rows: number = 0; //<-----------------------------------
  catGruposSelected: number;
  inputSearhGV: string;
  inputNewGV: string;
  inputEstado: string;
  inputMunicipio: string;
  inputSitio: string;
  catGruposVisualizacion: CatGrupoVisualizacion[];
  grupoVisualizacion: any = [];
  vista: string;
  title: string;

  grupos: any = [];
  companies: any = [];
  states: any = [];
  municipios: any = [];
  workPlaces: any = [];
  users: any = [];
  enfermedades: any = [];
  typeUsers: any = [];

  selectedGroup: number;
  selectedG: number;
  selectedGroup1: number;
  selectedCompany: number;
  selectedState: number;
  selectedS: number;
  selectedS1: number;
  selectedMunicipio: number;
  selectedWorkPlace: any;
  total_users: number = 0;
  newGrupos: any = [];
  total_typeGroup: number = 0;
  loading: boolean = false;
  enableButton: boolean = false;
  typeGroup: number = 0;
  typeGroupChecked: number;
  inputTextTypeGroup: string = "";
  inputSearchGroup: string = "";
  inputTextCompany: string = "";
  inputSearchTGV: string = "";
  inputSearchG: string = "";
  inputTextTypeG: string = "";
  inputTextC: string = "";
  selectedM: number;
  selectedWorkP: number;
  activeSearch: boolean;
  origin: string = constantes.origin;
  details: any = [];
  idWorkPlace: number;
  selectedGenero: string = "";
  selectedEnfermedad: number = 0;
  selectedTypeUser: number = 0;

  clicks: number = 0;
  inputUserId: number;
  constructor(
    private router: Router,
    private seccionesMonitorService: SeccionMonitorService,
    private asignaGrupoPaginaService: AsignaGrupoPaginaService,
    public dialog: MatDialog
  ) {
    this.vista = constantes.vista;
    this.activeSearch = constantes.activeSearch;
    if (constantes.title == "") {
      constantes.title = "Consultar grupos de visualización";
    }
    this.getCatGruposVisualizacion();
    if (
      constantes.groupVisualization.nameGV != "" &&
      this.vista == "toSearch"
    ) {
      this.initialSearh();
    }
  }

  ngOnInit(): void {
    this.title = constantes.title;

    if (constantes.vista == "toUpdate") {
      let filters: any = constantes.detallesGrupoVisualizacion.filters;

      if (constantes.detallesGrupoVisualizacion.idTGV == 1) {
        this.inputNewGV = constantes.detallesGrupoVisualizacion.nameGV;
        this.typeGroupChecked = constantes.detallesGrupoVisualizacion.idTGV;
        this.inputSearchG = filters != null ? filters.name : "";
      }
      if (constantes.detallesGrupoVisualizacion.idTGV == 2) {
        this.getAllGroup();
        this.inputNewGV = constantes.detallesGrupoVisualizacion.nameGV;
        this.typeGroupChecked = constantes.detallesGrupoVisualizacion.idTGV;
        this.selectedGroup = filters != null ? filters.groupIds : null;
        this.inputTextC = filters != null ? filters.name : "";
      }
      if (constantes.detallesGrupoVisualizacion.idTGV == 3) {
        this.getAllGroup();
        this.inputNewGV = constantes.detallesGrupoVisualizacion.nameGV;
        this.typeGroupChecked = constantes.detallesGrupoVisualizacion.idTGV;
        if (filters != null) {
          if (filters.groupsIds != undefined) {
            this.selectedGroup1 = filters.groupsIds[0];
            this.selectionGroup1();
            this.selectedCompany = filters.companyIds[0];
            this.selectionCompany();
            this.selectedS = filters != null ? filters.stateIds[0] : null;
            this.selectionState();
            this.selectedM = filters != null ? filters.municipalityIds : null;
            this.selectionMunicipio();
          } else {
            this.getState(filters.companyIds[0]);
            this.selectedS = filters != null ? filters.stateIds[0] : null;
            this.selectionState();
            this.selectedM = filters != null ? filters.municipalityIds : null;
            this.selectionMunicipio();
          }
        }
      }
      if (constantes.detallesGrupoVisualizacion.idTGV == 4) {
        this.inputNewGV = constantes.detallesGrupoVisualizacion.nameGV;
        this.typeGroupChecked = constantes.detallesGrupoVisualizacion.idTGV;
        this.getAllGroup();
        this.getTypeUsers();
        this.getEnfermedades();
        if (
          filters.groupsIds.length < 0 &&
          filters.userTypeId < 0 &&
          filters.sex < 0 &&
          filters.diseases < 0
        ) {
          this.buscar();
        } else {
          this.selectedTypeUser =
            filters.userTypeId.length > 0 ? filters.userTypeId[0] : null;
          this.selectionTypeUsers();
          this.selectedGenero = filters.sex.length > 0 ? filters.sex[0] : "";
          this.selectionGenero();
          this.selectedEnfermedad =
            filters.diseases.length > 0 ? filters.diseases[0] : null;
          this.selectionEnfermedades();
          if (filters.workPlaces > 0) {
            this.selectedGroup1 = filters.groupsIds[0];
            this.selectionGroup1();
            this.selectedCompany = filters.idCompanys[0];
            this.selectionCompany();
            this.getState(this.selectedCompany);
            this.selectedS = filters.stateIds[0];
            this.selectionState();
            this.selectedM = filters.municipalityIds[0];
            constantes.usersGroupByWorkP.municipalityId =
              filters.municipalityIds[0];
            this.seccionesMonitorService
              .getMunicipios(this.selectedCompany, this.selectedS)
              .subscribe(
                (result) => {
                  this.municipios = result.data;
                  let companyStatesMunicipalityId = this.municipios.filter(
                    (mpio) => mpio.municipalityId == this.selectedM
                  );

                  if (companyStatesMunicipalityId[0] != undefined) {
                    this.seccionesMonitorService
                      .getWorkPlaceByMunicipio(
                        companyStatesMunicipalityId[0]
                          .companyStatesMunicipalityId
                      )
                      .subscribe(
                        (result) => {
                          this.workPlaces = result.data;
                          this.loading = false;
                        },
                        (error) => {
                          console.log(error);
                          this.loading = false;
                        }
                      );
                  }
                },
                (error) => {
                  console.log(error);
                }
              );
            this.selectedWorkPlace = filters.workPlaces;
            this.selectionWorkPlace();
          } else if (filters.idCompanys > 0) {
            this.selectedGroup1 = filters.groupsIds[0];
            this.selectionGroup1();
            this.selectedCompany = filters.idCompanys[0];
            this.selectionCompany();
          }
        }
      }
    }
  } //FIN OnInit()

  ngAfterContentInit(): void {
    //Busqueda para editar GRUPO DE VISUALIZACION
    if (sessionStorage.getItem("grupoVisualizacion") == "true") {
      this.buscar();
    }
  }

  buscar() {
    if (this.vista == "toSearch") {
      this.clicks = this.clicks + 1;
      this.activeSearch = true;
      constantes.activeSearch = true;
      constantes.inputs.inputSearchTGV = this.inputSearchTGV;
    } else {
      this.clicks = this.clicks + 1;
      if (this.typeGroupChecked == 1) {
        this.enableButton = true;
        this.activeSearch = true;
        constantes.activeSearch = true;
        constantes.inputs.inputSearchGroup = this.inputSearchG;
      }
      if (this.typeGroupChecked == 2) {
        this.enableButton = true;
        this.activeSearch = true;
        constantes.activeSearch = true;
        constantes.ids.groupIds = this.selectedGroup;
        constantes.inputs.inputTextCompany = this.inputTextC;
        this.selectedG = this.selectedGroup;
        this.inputTextCompany = this.inputTextC;
        this.typeGroup = this.typeGroupChecked;
      }
      if (this.typeGroupChecked == 3) {
        this.enableButton = true;
        if (this.selectedCompany == null) {
          this.openConfirmDialog("Seleccione al menos un grupo y una compañia");
        } else {
          this.activeSearch = true;
          constantes.activeSearch = true;
          constantes.ids.groupIds = this.selectedGroup;
          constantes.ids.companyIds = this.selectedCompany;
          constantes.ids.stateIds = this.selectedS;
          constantes.ids.municipalityIds = this.selectedM;
          this.typeGroup = this.typeGroupChecked;
          this.selectedCompany = this.selectedCompany;
          this.selectedState = this.selectedS;
          this.selectedMunicipio = this.selectedM;
        }
      }
      if (this.typeGroupChecked == 4) {
        this.enableButton = true;
        this.activeSearch = true;
        constantes.activeSearch = true;
        this.typeGroup = this.typeGroupChecked;
        constantes.ids.groupIds = this.selectedGroup;
        constantes.ids.userId = this.inputUserId;
        //constantes.ids.companyIds=this.selectedCompany
        //constantes.ids.workPlaceIds=this.selectedWorkPlace
        //this.selectedCompany=this.selectedCompany
        //this.selectedState=this.selectedS
        //this.selectedMunicipio=this.selectedM
        //this.selectedWorkPlace=this.selectedWorkPlace
      }
    }
  }

  getCatGruposVisualizacion() {
    this.loading = true;
    this.seccionesMonitorService.getCatGrupoVisualizacion().subscribe(
      (result) => {
        this.catGruposVisualizacion = result.data;
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.loading = false;
      }
    );
  }

  verSeleccion(): void {
    let filtrarIdsSeccionados = this.newGrupos.filter(function (filtro) {
      return filtro.checked == true;
    });
    for (let index = 0; index < filtrarIdsSeccionados.length; index++) {}
    console.log(filtrarIdsSeccionados);
  }

  initialSearh() {
    this.typeGroupChecked = constantes.ids.idCatGV;
    this.inputSearchTGV = constantes.groupVisualization.nameGV;
    constantes.inputs.inputSearchTGV = constantes.groupVisualization.nameGV;
    this.activeSearch = true;
    constantes.vista = "toSearch";
    //constantes.ids.idCatGV=0
    //constantes.groupVisualization.nameGV=''
    //this.inputTextTypeGroup=constantes.groupVisualization.nameGV
    //this.typeGroup=constantes.ids.idCatGV
  }

  getAllGroup(): void {
    this.loading = true;
    this.seccionesMonitorService.geAllGroup().subscribe(
      (result) => {
        this.grupos = result.groups;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );
  }
  /***********************
   **INICIO SELECTIONS**
   ***********************/
  selectionGroup(): void {}
  selectionGroup1(): void {
    constantes.usersGroupByWorkP.groupId = this.selectedGroup1;
    this.activeSearch = false;
    constantes.ids.grupoId = this.selectedGroup1;
    this.loading = true;
    this.selectedGroup = null;
    this.selectedCompany = null;
    constantes.usersGroupByWorkP.companyId = 0;
    this.selectedS = null;
    constantes.usersGroupByWorkP.stateId = 0;
    this.selectedM = null;
    constantes.usersGroupByWorkP.municipalityId = 0;
    this.selectedWorkPlace = null;
    constantes.usersGroupByWorkP.workPlaceId = [];
    if (this.selectedGroup1 != undefined) {
      this.seccionesMonitorService
        .geAllCompaniesByIdGroup(this.selectedGroup1)
        .subscribe(
          (result) => {
            if (result.code == 404) {
              this.openConfirmDialog("No se encontró ninguna empresa");
              this.companies = [];
            }
            if (result.code == 200) {
              this.companies = result.groupsByCompanys[this.selectedGroup1][0];
            }
            this.loading = false;
          },
          (error) => {
            console.log(error);
            this.loading = false;
          }
        );
    }
  }
  selectionCompany(): void {
    constantes.usersGroupByWorkP.companyId = this.selectedCompany;
    this.activeSearch = false;
    constantes.ids.companyIds = this.selectedCompany;
    this.selectedS = null;
    constantes.usersGroupByWorkP.stateId = 0;
    this.selectedM = null;
    constantes.usersGroupByWorkP.municipalityId = 0;
    this.selectedWorkPlace = null;
    constantes.usersGroupByWorkP.workPlaceId = [];
    this.getState(this.selectedCompany);
  }
  getState(companId: number): void {
    this.loading = true;
    this.selectedS = null;
    this.seccionesMonitorService.geAllStates(companId).subscribe(
      (result) => {
        if (result.code == 200) {
          if (result.data.length == 0) {
            this.openConfirmDialog("No se encontró ningún estado");
            this.states = [];
          } else {
            this.states = result.data;
          }
        }
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.loading = false;
      }
    );
  }
  selectionState(): void {
    constantes.usersGroupByWorkP.stateId = this.selectedS;
    constantes.ids.stateIds = this.selectedS;
    this.loading = true;
    this.selectedM = null;
    constantes.usersGroupByWorkP.municipalityId = 0;
    this.selectedWorkPlace = null;
    constantes.usersGroupByWorkP.workPlaceId = [];
    this.seccionesMonitorService
      .getMunicipios(this.selectedCompany, this.selectedS)
      .subscribe(
        (result) => {
          if (result.code == 200) {
            if (result.data.length == 0) {
              this.openConfirmDialog("No se encontró ningún municipio");
              this.municipios = [];
            } else {
              this.municipios = result.data;
            }
          }
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.loading = false;
        }
      );
  }
  selectionMunicipio(): void {
    constantes.usersGroupByWorkP.municipalityId = this.selectedM;

    constantes.ids.municipalityIds = this.selectedM;
    if (this.typeGroupChecked == 4) {
      this.loading = true;
    }
    this.selectedWorkPlace = null;
    constantes.usersGroupByWorkP.workPlaceId = [];
    if (this.typeGroupChecked == 4) {
      let idmunicipio = this.selectedM;

      let companyStatesMunicipalityId = this.municipios.filter(function (
        municipio
      ) {
        return municipio.municipalityId == idmunicipio;
      });

      this.seccionesMonitorService
        .getWorkPlaceByMunicipio(
          companyStatesMunicipalityId[0].companyStatesMunicipalityId
        )
        .subscribe(
          (result) => {
            if (result.code == 200) {
              if (result.data.length == 0) {
                this.openConfirmDialog(
                  "No se encontró ningún sitio de trabajo"
                );
                this.workPlaces = [];
              } else {
                this.workPlaces = result.data;
              }
            }
            this.loading = false;
          },
          (error) => {
            console.log(error);
            this.loading = false;
          }
        );
    }
  }
  selectionWorkPlace() {
    constantes.usersGroupByWorkP.workPlaceId = this.selectedWorkPlace;
    //constantes.ids.workPlaceIds=this.selectedWorkPlace
    this.activeSearch = false;
  }
  /********************
   **FIN SELECTIONS**
   ********************/

  async guardarAsignacion(): Promise<void> {
    if (constantes.paginas.displayGroupId > 0) {
      let data = {
        idGrupoVisualizacion: constantes.paginas.displayGroupId,
      };
      (
        await this.asignaGrupoPaginaService.setUpdatePage(
          data,
          constantes.paginas.pageId
        )
      ).subscribe(
        (result) => {
          this.openConfirmDialog("Actualizado corectamente");
          if (
            sessionStorage.getItem("secciones") == "true" &&
            sessionStorage.getItem("paginas") == "true"
          ) {
            constantes.groupVisualization.nameGV = "";
            this.inputTextTypeGroup = "";
            constantes.vista = "toSearch";
            constantes.activeSearch = false;
            constantes.title = "Asignar páginas";
            this.router.navigate(["/seccionesMonitor/asignarPagina"]);
          } else {
            constantes.vista = "toSearch";
            constantes.activeSearch = false;
            constantes.title = "Consultar páginas";
            constantes.origin = "";
            this.router.navigate(["/seccionesMonitor/verPagina"]);
          }

          sessionStorage.removeItem("paginas");
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      if (
        sessionStorage.getItem("secciones") == "true" &&
        sessionStorage.getItem("paginas") == "true"
      ) {
        constantes.groupVisualization.nameGV = "";
        this.inputTextTypeGroup = "";
        constantes.vista = "toSearch";
        constantes.activeSearch = false;
        constantes.title = "Asignar páginas";
        this.router.navigate(["/seccionesMonitor/asignarPagina"]);
      } else {
        constantes.vista = "toSearch";
        constantes.activeSearch = false;
        constantes.title = "Consultar páginas";
        constantes.origin = "";
        this.router.navigate(["/seccionesMonitor/verPagina"]);
      }

      sessionStorage.removeItem("paginas");
    }
  }

  checkingTypeGroup(checked: boolean): void {
    this.selectedGroup = null;
    this.selectedGroup1 = null;
    this.selectedCompany = null;
    this.selectedS = null;
    this.selectedS1 = null;
    this.selectedM = null;
    this.selectedWorkPlace = null;
    this.activeSearch = false;
    constantes.ids.idCatGV = this.typeGroupChecked;
    constantes.detallesGrupoVisualizacion.idTGV = this.typeGroupChecked;
    if (this.vista == "toSearch") {
    } else {
      if (this.typeGroupChecked == 1) {
        console.log("tipo:" + this.typeGroupChecked);
        this.inputSearchG = "";
      }
      if (this.typeGroupChecked == 2) {
        this.inputTextC = "";
        this.selectedGroup = 0;
        console.log("tipo:" + this.typeGroupChecked);
        this.getAllGroup();
      }
      if (this.typeGroupChecked == 3) {
        this.inputSearchGroup = "";
        this.inputSearchTGV = "";
        this.inputTextCompany = "";
        console.log("tipo:" + this.typeGroupChecked);
        this.getAllGroup();
      }
      if (this.typeGroupChecked == 4) {
        this.inputSearchGroup = "";
        this.inputSearchTGV = "";
        this.inputTextCompany = "";
        console.log("tipo:" + this.typeGroupChecked);
        this.getAllGroup();
        this.getEnfermedades();
        this.getTypeUsers();
      }
    }
  }
  openViewCreate() {
    sessionStorage.setItem("grupoVisualizacion", "true");
    constantes.title = "Crear grupo de  visualización";
    constantes.vista = "toCreate";
    constantes.activeSearch = false;
    constantes.detallesGrupoVisualizacion.filters = {};
    this.router.navigate(["/seccionesMonitor/crearGrupoVisualizacion"]);
  }

  resetElements(): void {
    this.inputTextTypeG = "";
    this.inputTextTypeGroup = "";
    this.inputSearchGroup = "";
    this.typeGroupChecked = null;
    this.typeGroup = null;
    this.inputNewGV = "";
  }
  cancelar() {
    constantes.usersGroupByWorkP.groupId = 0;
    constantes.usersGroupByWorkP.companyId = 0;
    constantes.usersGroupByWorkP.stateId = 0;
    constantes.usersGroupByWorkP.municipalityId = 0;
    constantes.usersGroupByWorkP.workPlaceId = [];
    constantes.ids.typeUserId = 0;
    constantes.ids.genero = "";
    constantes.ids.enfermedadId = 0;
    constantes.ids.userId = 0;
    constantes.elementsIds = [];
    if (
      sessionStorage.getItem("paginas") == "true" ||
      sessionStorage.getItem("grupoVisualizacion") == "true" ||
      this.vista == "toCreate"
    ) {
      if (
        sessionStorage.getItem("paginas") == "true" &&
        sessionStorage.getItem("grupoVisualizacion") != "true"
      ) {
        constantes.vista = "toSearch";
        constantes.activeSearch = false;
        constantes.origin = "";
        constantes.title = "Consultar páginas";
        this.router.navigate(["/seccionesMonitor/verPagina"]);
        sessionStorage.removeItem("paginas");
      } else if (
        sessionStorage.getItem("grupoVisualizacion") == "true" &&
        sessionStorage.getItem("paginas") == "true"
      ) {
        constantes.vista = "toSearch";
        constantes.activeSearch = true;
        constantes.title = "Asignar grupo de visualización";
        this.router.navigate(["/seccionesMonitor/asignarGrupoVisualizacion"]);
        sessionStorage.removeItem("grupoVisualizacion");
      } else {
        constantes.inputs.inputSearchTGV = "";
        constantes.ids.idCatGV = 0;
        constantes.vista = "toSearch";
        constantes.activeSearch = false;
        constantes.title = "Consultar grupo de visualización";
        this.router.navigate(["/seccionesMonitor/grupoVisualizacion"]);
        sessionStorage.removeItem("grupoVisualizacion");
      }
    }
  }

  //////////////REC
  guardarSeleccion(): void {
    if (this.inputNewGV != "" && this.inputNewGV != undefined) {
      this.saveNewGVToCatGroups();
    } else {
      this.openConfirmDialog("El campo nombre es necesario");
    }
  }

  async saveNewGVToCatGroups(): Promise<void> {
    let newGVJson = {
      tipoGrupoVisualizacionid: this.typeGroupChecked,
      nombre: this.inputNewGV,
      filtros: constantes.jSONs.filters,
      ids: constantes.newElementsIds,
    };
    console.log(newGVJson);
    (
      await this.seccionesMonitorService.addGrupoVisualizacion(newGVJson)
    ).subscribe(
      (result) => {
        if (result.code == 200) {
          console.log("Nuevo grupo de visualización guardado correctamente");
          this.openConfirmDialog("Se guardo correctamente");

          constantes.vista = "toSearch";
          constantes.jSONs.groupsSelected = [];
          this.inputNewGV = "";
          constantes.newElementsIds = [];

          if (sessionStorage.getItem("grupoVisualizacion") == "true") {
            if (sessionStorage.getItem("paginas") == "true") {
              constantes.title = "Asignar grupo de visualización";
              constantes.activeSearch = true;
              this.router.navigateByUrl(
                "/seccionesMonitor/asignarGrupoVisualizacion"
              );
            } else {
              this.typeGroupChecked = 0;
              constantes.ids.idCatGV = 0;
              constantes.activeSearch = false;
              constantes.title = "Consultar grupos de visualización";
              this.router.navigateByUrl("/seccionesMonitor/grupoVisualizacion");
            }
            sessionStorage.removeItem("grupoVisualizacion");
          }
          this.loading = false;
        }

        //this.setUpComponent();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  guardarCambiosSeleccion(): void {
    this.updateGV();
  }

  async updateGV(): Promise<void> {
    this.loading = true;
    /*let idsSeleccionados=[];
    let filtrarIdsSeccionados=constantes.selectedElementsIds.filter(function(grupos){return grupos.checked==true;});
    console.log(filtrarIdsSeccionados)
    for (let index = 0; index < filtrarIdsSeccionados.length; index++) {
      idsSeleccionados.push(filtrarIdsSeccionados[index].Id)      
    }*/
    let updateGVJson = {
      tipoGrupoVisualizacionid: this.typeGroupChecked,
      nombre: this.inputNewGV,
      filtros: constantes.jSONs.filters,
      ids: constantes.elementsIds,
    };
    (
      await this.seccionesMonitorService.updateGrupoVisualizacion(
        updateGVJson,
        constantes.usersGroupByWorkP.displayGroupId
      )
    ).subscribe(
      (result) => {
        if (result.code == 200) {
          console.log("Nuevo grupo de visualización guardado correctamente");
          this.openConfirmDialog("Actualizado correctamente");
          constantes.vista = "toSearch";
          constantes.jSONs.groupsSelected = [];
          this.inputNewGV = "";
          constantes.elementsIds = [];

          if (sessionStorage.getItem("grupoVisualizacion") == "true") {
            if (sessionStorage.getItem("paginas") == "true") {
              constantes.title = "Asignar grupo de visualización";
              constantes.activeSearch = true;
              this.router.navigateByUrl(
                "/seccionesMonitor/asignarGrupoVisualizacion"
              );
            } else {
              this.typeGroupChecked = 0;
              constantes.ids.idCatGV = 0;
              constantes.activeSearch = false;
              constantes.title = "Consultar grupos de visualización";
              this.router.navigateByUrl("/seccionesMonitor/grupoVisualizacion");
            }
            sessionStorage.removeItem("grupoVisualizacion");
          }
          this.loading = false;
        }
      },
      (error) => {
        console.log(error);
        this.loading = false;
      }
    );
  }

  changeUsersPage(e: PageEvent) {
    this.desde = e.pageIndex * e.pageSize;
    this.hasta = this.desde + e.pageSize;
  }

  openConfirmDialog(mensaje: string | null): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { origen: "crearPagina", titulo: mensaje },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 1) {
        console.log("The dialog was closed");
      } else if (result == 0) {
      }
    });
  }
  selectionTypeUsers(): void {
    constantes.ids.typeUserId = this.selectedTypeUser;
  }
  selectionGenero(): void {
    constantes.ids.genero = this.selectedGenero;
  }
  selectionEnfermedades(): void {
    constantes.ids.enfermedadId = this.selectedEnfermedad;
  }

  getEnfermedades(): void {
    this.seccionesMonitorService.getEnfermedades().subscribe(
      (result) => {
        this.enfermedades = result.diseases;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getTypeUsers(): void {
    this.seccionesMonitorService.getTypeUsers().subscribe(
      (result) => {
        this.typeUsers = result.data;
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
