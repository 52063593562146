import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { constants } from 'src/app/constants';
import { ResponseData } from 'src/app/dto';
import { Configuration } from 'src/app/dto/Configuration';
import { FilesPipe } from 'src/app/pipes/files.pipe';
import { AlertNotificationService, SettingsService } from 'src/app/services';
import { ConfigurationDataService } from 'src/app/services/data/configuration-data.service';
import { MediaService } from 'src/app/services/media.service';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { getObject } from 'src/app/utils/_http';
import { getPermissionsPage } from 'src/app/utils/_utils';

@Component({
    selector: 'app-configuration',
    templateUrl: './configuration.component.html',
    styleUrls: ['./configuration.component.css']
})
export class ConfigurationComponent implements OnInit {
    subscription: Subscription = new Subscription
    permissions: any = {}
    pageAction: {
        action: any,
        uuid: any,
        titlePage: any,
    } = {
            action: null,
            uuid: null,
            titlePage: null,
        }

    form: FormGroup
    showComponent: boolean = false

    configData = {
        logo: {
            filesToUpload: [],
            files: [],
            isSingleFiles: null,
            mediaChange: false,
        },
        image: {
            filesToUpload: [],
            files: [],
            isSingleFiles: null,
            mediaChange: false,
        },
        buttonDisabled: false
    }

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        public alertNotificationService: AlertNotificationService,
        public settings: SettingsService,
        public configurationService: ConfigurationDataService,
        public mediaService: MediaService,
        public modalHelper: ModalHelperService,
        public filesPipe: FilesPipe,
    ) { }

    ngOnInit(): void {
        this.pageAction = {
            action: constants.screens.notifications[this.router.url.split('/').pop()] ?? null,
            uuid: this.router.url.split('/')[2] ?? null,
            titlePage: 'Configuración'
        };
        this.permissions = getPermissionsPage(this.router.url)
        this.getConfiguration()
    }
    // --
    getConfiguration() {
        this.subscription.add(
            this.configurationService.read()
                .subscribe(
                    (rs: ResponseData) => {
                        if (rs.status == 200) {
                            this.setForm(getObject(rs))
                            this.pageAction.action = 'edit'
                            this.pageAction.uuid = this.f.uuid.value
                        }
                        else {
                            this.setForm()
                            this.pageAction.action = 'create'
                        }
                    }
                )
        )
    }
    // --
    getMedia(type: string, uuid: string) {
        this.subscription.add(
            this.mediaService.getOne(uuid)
                .subscribe(
                    rs => {
                        if (rs.hasOwnProperty('error')) {
                            let error = {
                                title: 'Error al obtener la imagen',
                                message: this.settings.get('production') ? rs.error['message'] : rs.error,
                                show: true
                            }
                            this.modalHelper.changeErrorModal(error);
                            //   this.modalHelper.changeLoading(false);
                            console.error(error);
                        } else {
                            // this.configData[type].files.push(JSON.stringify(rs))
                            this.configData[type].files.push(rs)
                        }
                    }
                )
        )
    }
    // -- 
    setForm(data?: any): void {
        this.form = this.formBuilder.group(new Configuration({}))
        Object.keys(this.form.controls).map(x => {
            if (x != 'image' && x != 'logo') {
                this.form.controls[x].setValidators(Validators.required)
            }
        })

        if (data) {
            this.form.setValue(data)
            if (this.form.value.image) {
                this.getMedia('image', this.form.value.image)
            }
            if (this.form.value.logo) {
                this.getMedia('logo', this.form.value.logo)
            }
            this.showComponent = true
        }
        else {
            this.showComponent = true
        }
        // -- inicialization
    }
    // --
    get f() {
        return this.form.controls
    }
    // --
    onAction(type: string, value?: any) {
        switch (type) {
            case 'image':
            case 'logo':
                setTimeout(() => {
                    this.configData[type].filesToUpload = value;
                    if (this.configData[type].filesToUpload.length) {
                        let id = this.configData[type].filesToUpload[0].hasOwnProperty('uuid') ? 'uuid' : 'id';
                        let url = this.configData[type].filesToUpload[0].hasOwnProperty('url') ? 'url' : 'url';
                        let alt = this.configData[type].filesToUpload[0].hasOwnProperty('alt') ? 'alt' : 'alt';
                        this.form.patchValue({ [type]: this.configData[type].filesToUpload[0][id] });
                        this.configData[type].files[0] = {
                            alt: this.configData[type].filesToUpload[0][alt],
                            status: true,
                            url: this.configData[type].filesToUpload[0][url],
                            uuid: this.configData[type].filesToUpload[0][id],
                        }
                    }
                    else {
                        this.form.patchValue({ [type]: '' });
                    }
                }, 50)
                break;
        }
    }
    // --
    isSingleFile($event, type: string) {
        this.configData[type].isSingleFiles = $event;
        if (this.configData[type].files.length) if (this.configData[type].isSingleFiles) this.configData[type].files = [this.configData[type].files.pop()];
    }
    // --
    resetPicture(type: string): void {
        this.form.patchValue({
            [type]: ''
        });
        this.configData[type].files = []
    }
    // --
    save() {
        this.form.patchValue({ active: Number(this.form.value.active) })
        if (this.form.invalid) {
            Object.values(this.form.controls).map(x => {
                x.markAsTouched()
            })
        }
        else {
            switch (this.pageAction.action) {
                case 'create':
                    this.subscription.add(
                        this.configurationService.create(this.form.value)
                            .subscribe(
                                (rs: ResponseData) => {
                                    this.alertNotificationService.success('Configuración', 'Se guardo la configuración correctamente', { autoClose: true, keepAfterRouteChange: true });
                                    this.router.navigateByUrl('/dashboard');
                                },
                                error => {
                                    console.error(error)
                                    this.alertNotificationService.error('Configuración', 'Ocurrio un error al guardar la configuración', { autoClose: true, keepAfterRouteChange: true });
                                }
                            )
                    )
                    break;
                case 'edit':
                    this.subscription.add(
                        this.configurationService.update(this.form.value.uuid, this.form.value)
                            .subscribe(
                                (rs: ResponseData) => {
                                    this.alertNotificationService.success('Configuración', 'Se actualizó la configuración correctamente', { autoClose: true, keepAfterRouteChange: true });
                                    this.router.navigateByUrl('/dashboard');
                                },
                                error => {
                                    console.error(error)
                                    this.alertNotificationService.error('Configuración', 'Ocurrio un error al actualizar la configuración', { autoClose: true, keepAfterRouteChange: true });
                                }
                            )
                    )
                    break;
            }
        }
    }
}
