<div class="container-fluid">
    <div class="card border-card">
        <div class="card-content">
            <!--Cabecera-->
            <div class="row mb-20">
                <div class="card__title col-12">
                    <div class="d-flex justify-content-between">
                        <h2 class="title-list align-self-center">Listado de preguntas</h2>
                        <div class="col-4">
                            <abarcms-input-search class="align-self-center" [items]="question"
                                [fieldsToSearch]="['title']" (dataFound)="dataFound($event)"
                                [placeholder]="'Buscar...'">
                            </abarcms-input-search>
                        </div>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="dataTable.showTable">
                <div class="mt-30">
                    <div id="contenido-table_wrapper" class="dataTables_wrapper no-footer">
                        <table style="width:100%">
                            <thead>
                                <tr>
                                    <!-- <th>
                                    <span class="blue-checkbox">
                                        <input type="checkbox" (click)="selectedAllItems()"
                                            ([ngModel])="_selectedAllItems">
                                        <label for=""></label>
                                    </span>
                                </th> -->
                                    <th>NOMBRE</th>
                                    <th>CREADO</th>
                                    <th>ACCIONES</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let question of questionList; let i = index">
                                    <!-- <td>
                                    <span class="blue-checkbox">
                                        <input type="checkbox" class="userItem" id="userItem-{{i}}"
                                            [value]="question.uuid">
                                        <label for=""></label>
                                    </span>
                                </td> -->
                                    <td>{{ question.title }}</td>
                                    <td>
                                        {{question.createdat | date: 'dd/MM/yyyy'}}
                                    </td>

                                    <td>
                                        <div class="btn-group btn-group-sm" role="group" aria-label="User action">
                                            <ng-container *ngIf="!permissions?.edit">
                                                <button type="button" class="btn btn-warning" *ngIf="permissions?.read"
                                                    (click)="actionItem('read', question.uuid)">
                                                    <i class="far fa-eye"
                                                        style="position: relative; left: -2px; top: 2px;"></i>
                                                </button>
                                            </ng-container>
                                            <button type="button" class="btn btn-primary"
                                                (click)="actionItem('edit', question.uuid)" *ngIf="permissions?.edit">
                                                <i class="fas fa-pencil-alt"></i>
                                            </button>
                                            <button type="button" class="btn btn-danger"
                                                (click)="actionItem('delete', question)" *ngIf="permissions?.delete">
                                                <i class="fas fa-trash-alt"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="col-12 mt-4">
                        <abarcms-paginator [items]="questionPage" [initialPage]="paginator.current"
                            [perPage]="paginator.perPage" [maxPages]="5" [lblFirst]="'Primera'" [lblLast]="'Última'"
                            [lblPrev]="'Anterior'" [lblNext]="'Siguiente'" [btnSec]="true" [btnPri]="true"
                            (changePage)="changePage($event)" [align]="'center'"></abarcms-paginator>
                        <p *ngIf="!questionPage.length" class="alert alert-warning text-center">
                            No se encontraron datos
                        </p>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<abarcms-modal title="Eliminar pregunta" *ngIf="deleteModal.show" (close)="deleteItem()" [btnMiddle]="'Cancelar'"
    [btnRight]="'Aceptar'" (middle)="deleteItem()" (right)="deleteItem(deleteModal)">
    <p class="alert alert-danger">Estas apunto de eliminar esta pregunta</p>
    <form class="text-left">
        <div class="form-group">
            <label for="id">Identificador</label>
            <input type="text" readonly class="form-control" value="{{deleteModal.item.uuid}}">
        </div>
        <div class=" form-group">
            <label for="name">Nombre de la pregunta</label>
            <input type="text" readonly class="form-control" value="{{deleteModal.item.title}}">
        </div>
    </form>
</abarcms-modal>