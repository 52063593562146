	<div class="container-fluid container-fluid-fixed">
		<div class="row">
			<div class="col-md-6 img-screen">
				<div class="img-container">
					<img src="assets/images/covid.png" alt="image not found">
				</div>
			</div>
			<div class="col-md-6">
				<div class="col-md-12">
					<a href="">
						<img class="mx-auto d-block logo-login mt-4 mb-4" src="assets/images/logo.png" alt="image not found">
					</a>
				</div>
				<div class="col-md-12">

					<div class="card login-card">
						<div class="card-body">
							<form [formGroup]="forma" (ngSubmit)="onSignIn()" *ngIf="settings['signIn']['std']['enabled']">
								<div class="form-group">
									<label for="mail">USUARIO</label>
									<!-- <input autocomplete="off" id="mail" name="mail" type="text" class="form-control" placeholder="mi.usuario / email@mail.com" [(ngModel)]="data.username"> -->
									<input autocomplete="off" id="mail" type="text" class="form-control"
										placeholder="Correo electrónico" formControlName="username"
										[ngClass]="{'is-invalid invalidFieldForm': validUsername}"
										[ngStyle]="validUsername && {'border-color': 'red','margin-bottom': '3px'}">
									<div class="invalidFieldForm" style="color: red;font-size: 13px;" *ngIf="validUsername">
										<ng-container>{{ validationText.email }}</ng-container>
									</div>
								</div>
								<div class="form-group">
									<label for="password">CONTRASEÑA</label>
									<!-- <input autocomplete="off" id="password" name="password" type="password" class="form-control" placeholder="**********" [(ngModel)]="data.password"> -->
									<input autocomplete="off" id="password" type="password" class="form-control"
										placeholder="Contraseña" formControlName="password"
										[ngClass]="{'is-invalid invalidFieldForm': validPassword}"
										[ngStyle]="validPassword && {'border-color': 'red','margin-bottom': '3px'}">
									<div class="invalidFieldForm" style="color: red;font-size: 13px;" *ngIf="validPassword">
										<ng-container>
											{{ validationText.textlength | replace:'{minlength}':'8' | replace:'{maxlength}':'32' }}
										</ng-container>
									</div>
								</div>
								<div class="col text-center">
									<button type="submit" class="btn btn-secondary btn-sm background-black">INICIAR SESION</button>
								</div>
							</form>

							<div class="link-wrap mt-10 text-center" *ngIf="settings['passwordRecovery']['enabled']">
								<a (click)="onPasswordRecovery()" class="basic-link link-lost-password">¿Olvidaste tu
									contraseña? Da clic aquí</a>
							</div>
							<div class="google-login" *ngIf="settings['signIn']['google']['enabled']">

							</div>
						</div>
					</div>
				</div>
				<div class="col-md-12 text-center">
					<p class="description-login text-justify-center">{{settingsData?.text}}</p>
				</div>
			</div>
		</div>
	</div>
	<footer class="footer text-right mr-3"> <strong> CMS Monitor </strong> 2021 v1.0 </footer>
<abarcms-modal title="Datos inválidos" *ngIf="showValidationErrorModal" (close)="showValidationErrorModal = false"
	[btnRight]="'Aceptar'" (right)="showValidationErrorModal = false">
	{{ validationError }}
</abarcms-modal>
<abarcms-modal title="Error (Google)" *ngIf="showGoogleSignInError" (close)="showGoogleSignInError = false"
	[btnRight]="'Aceptar'" (right)="showGoogleSignInError = false">
	<ng-container *ngIf="settings.get('production')">
		{{ googleSignInError }}
	</ng-container>
	<ng-container *ngIf="!settings.get('production')">
		{{ googleSignInError | json }}
	</ng-container>
</abarcms-modal>
<abarcms-modal title="Error de autenticación" *ngIf="showAuthenticationError" (close)="showAuthenticationError = false"
	[btnRight]="'Aceptar'" (right)="showAuthenticationError = false">
	{{ authenticationError }}
</abarcms-modal>
<abarcms-loader-full *ngIf="sessionDataService['signIn']['loading']">Ingresando</abarcms-loader-full>
