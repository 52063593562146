import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'frecuencyFormat'
})
export class FrecuencyFormatPipe implements PipeTransform {

  frecuencyString: string;

    transform(value: string): string {

        switch (value) {
            case '0':
                this.frecuencyString = 'Único';
                break;
            
            case '1':
                this.frecuencyString = 'Diario';
                break;
            
            case '2':
                this.frecuencyString = 'Semanal';
                break;
            case '3':
                this.frecuencyString = 'Mensual';
                break;
            case '4':
                this.frecuencyString = 'Anual';
                break;
            default:
                this.frecuencyString = 'Único'
                break;
        }

        return this.frecuencyString;
    }

}
