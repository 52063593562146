import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { constants } from 'src/app/constants';
import { AlertNotificationService, SettingsService } from 'src/app/services';
import { MediaService } from 'src/app/services/media.service';
import { labels } from 'src/app/strings';
import { NotificationsDataService } from 'src/app/services/data/notifications-data.service';
import { Subscription } from 'rxjs';
import { existStorage, getPermissionsPage, getStorage, setStorage } from 'src/app/utils/_utils';
import { PushNotification, PushNotificationSchedule } from 'src/app/dto/PushNotifications';
import { DatePipe } from '@angular/common';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { ResponseData } from 'src/app/dto';
import { PollGroup } from 'src/app/dto/Poll';
import { GroupDataService } from 'src/app/services/data/group-data.service';
import { getArray, getObject } from 'src/app/utils/_http';

@Component({
    selector: 'app-push-notifications-action',
    templateUrl: './push-notifications-action.component.html',
    styleUrls: ['./push-notifications-action.component.css']
})
export class PushNotificationsActionComponent implements OnInit {
    subscription: Subscription = new Subscription
    permissions: any = {}
    pageAction: {
        action: any,
        uuid: any,
        titlePage: any,
    } = {
            action: null,
            uuid: null,
            titlePage: null,
        }

    form: FormGroup
    formGroup: FormGroup
    formSchedule: FormGroup
    formRules: FormGroup[] = []
    groups: PollGroup[] = []
    groupsShow: boolean = false

    condition: any[] = [
        { id: 1, sysname: 'igual', name: 'Igual a', type: 'numeric' },
        // { id: 2, sysname: 'igual_no', name: 'No es igual a', type: 'numeric' },
        // { id: 3, sysname: 'mayor', name: 'Es mayor que', type: 'numeric' },
        // { id: 4, sysname: 'menor', name: 'Es menor que', type: 'numeric' },
        // { id: 5, sysname: 'comienza', name: 'Comienza con', type: 'string' },
        // { id: 6, sysname: 'termina', name: 'Termina con', type: 'string' },
        // { id: 7, sysname: 'contiene', name: 'Contiene', type: 'string' },
        // { id: 8, sysname: 'contiene_no', name: 'No Contiene', type: 'string' },
        // { id: 9, sysname: 'si', title: 'Si', type: 'boolean' },
        // { id: 10, sysname: 'no', title: 'No', type: 'numeric' },
    ]

    field = [
        {
            id: 1, sysname: 'usuarios', name: 'Usuarios', fielddb: [
                { id: 1, sysname: "id_usuario", name: 'Id Usuario', type: 'numeric', unique: false },
                { id: 2, sysname: "tipo_usuario", name: 'Tipo Usuario', type: 'numeric', unique: false },
                { id: 3, sysname: "edad", name: 'Edad', type: 'numeric', unique: true },
                {
                    id: 4, sysname: "genero", name: 'Género', type: 'select', unique: true, options: [
                        {
                            title: 'Masculino',
                            value: 'm'
                        },
                        {
                            title: 'Femenino',
                            value: 'f'
                        }
                    ]
                },
                { id: 5, sysname: "cp", name: 'Código Postal', type: 'numeric', unique: false },
                // { id: 6, sysname: "riesgo", name: 'Riesgo', type: 'numeric' },
                // { id: 7, sysname: "basal", name: 'Condición Basal', type: 'numeric' },
            ]
        },
        {
            id: 2, sysname: 'sintomas', name: 'Síntomas', fielddb: [
                { id: 1, sysname: "oximetria", name: 'Oximetria', type: 'numeric', unique: true },
                { id: 2, sysname: "temperatura", name: 'Temperatura', type: 'numeric', unique: true },
                { id: 3, sysname: 9, "name": "Debilidad o cansancio", type: 'boolean', unique: true },
                { id: 4, sysname: 10, "name": "Diarrea", type: 'boolean', unique: true },
                { id: 5, sysname: 4, "name": "Dolor de cabeza", type: 'boolean', unique: true },
                { id: 6, sysname: 15, "name": "Dolor de espalda baja", type: 'boolean', unique: true },
                { id: 7, sysname: 2, "name": "Dolor de garganta", type: 'boolean', unique: true },
                { id: 8, sysname: 8, "name": "Dolor de huesos y/o articulaciones", type: 'boolean', unique: true },
                { id: 9, sysname: 13, "name": "Dolor muscular", type: 'boolean', unique: true },
                { id: 10, sysname: 5, "name": "Escalofrío", type: 'boolean', unique: true },
                { id: 11, sysname: 3, "name": "Fiebre", type: 'boolean', unique: true },
                { id: 12, sysname: 14, "name": "Náuseas y/o vómitos", type: 'boolean', unique: true },
                { id: 13, sysname: 12, "name": "Pérdida del gusto", type: 'boolean', unique: true },
                { id: 14, sysname: 11, "name": "Pérdida del olfato", type: 'boolean', unique: true },
                { id: 15, sysname: 6, "name": "Secreción y goteo nasal", type: 'boolean', unique: true },
                { id: 16, sysname: 1, "name": "Tos", type: 'boolean', unique: true },
            ]
        },
        // {
        //     id: 3, sysname: 'app', name: 'Aplicación Móvil', fielddb: [
        //         { id: 1, sysname: "os", name: 'OS', type: 'string', unique: false },
        //         { id: 2, sysname: "version", name: 'Version App', type: 'string', unique: false },
        //     ]
        // },
    ]

    pushData: any = {
        frequency: [
            { sysname: 'unico', title: 'Único', value: null },
            { sysname: 'diario', title: 'Diario', value: null },
            { sysname: 'semanal', title: 'Semanal', value: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado'] },
            { sysname: 'mensual', title: 'Mensual', value: [] },
            { sysname: 'anual', title: 'Anual', value: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'] },
        ],
        frequencySelected: 0,
        calendar: {
            frequency: <NgbDateStruct>{},
            fromdate: <NgbDateStruct>{},
            todate: <NgbDateStruct>{},
            mindateFrom: <NgbDateStruct>{},
            mindateTo: <NgbDateStruct>{},
            time: <NgbDateStruct>{},
        },
        media: {
            filesToUpload: [],
            files: [],
            isSingleFiles: null,
            mediaChange: false,
        },
        rules: {
            field: [],
            condition: [],
            fieldSelected: [],
            fielddbSelected: [],
            conditionSelected: [],
        }
    }

    validateData = {
        invalid: 0,
        invalidForm: 0,
        sectionFormCounter: 0,
        sectionFormCounterReponse: 0,
    }

    showComponent: boolean = false
    showAnualToMonth: boolean = false

    validateUsers: boolean = false

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        public notificationService: NotificationsDataService,
        public alertNotificationService: AlertNotificationService,
        public settings: SettingsService,
        public datePipe: DatePipe,
        public modalHelper: ModalHelperService,
        public mediaService: MediaService,
        private groupService: GroupDataService,
    ) { }

    ngOnInit(): void {
        // --
        this.loginMonitor()
        // --
        setTimeout(() => {
            this.pageAction = {
                action: constants.screens.notifications[this.router.url.split('/').pop()] ?? null,
                uuid: this.router.url.split('/')[2] ?? null,
                titlePage: constants.screens.notifications[this.router.url.split('/').pop()] == constants.screens.notifications.edit ? labels.notifications.update.titlePage : labels.notifications.create.titlePage
            };
            this.permissions = getPermissionsPage(this.router.url)
            // this.getRules()
            this.getGroupsAndBusiness()
            if (this.pageAction.action == 'edit') {
                this.getNotification()
            }
            else {
                this.setForm()
            }
            // --
            this.subscription.add(
                this.modalHelper.poll$
                    .subscribe(
                        value => {
                            if (value?.module) {
                                if (value.module == 'validFormResponse') {
                                    if (value.invalid) {
                                        this.validateData.invalidForm++
                                    }
                                    this.validateData.sectionFormCounterReponse++

                                    if (this.validateData.sectionFormCounter == this.validateData.sectionFormCounterReponse) {
                                        if (!this.validateData.invalidForm) {
                                            this.savePush()
                                        }
                                    }
                                }
                            }
                        }
                    )
            )
        }, 500)
    }
    // --
    getMedia(uuid: string) {
        this.subscription.add(
            this.mediaService.getOne(uuid)
                .subscribe(
                    rs => {
                        if (rs.hasOwnProperty('error')) {
                            let error = {
                                title: 'Error al obtener la imagen',
                                message: this.settings.get('production') ? rs.error['message'] : rs.error,
                                show: true
                            }
                            this.modalHelper.changeErrorModal(error);
                            console.error(error);
                        } else {
                            this.pushData.media.files.push(rs)
                        }
                    }
                )
        )
    }
    // --
    // getRules() {
    //     this.subscription.add(
    //         this.notificationService.readRules()
    //             .subscribe(
    //                 rs => {
    //                     this.pushData.rules.field = getObject(rs).rules
    //                     this.pushData.rules.condition = getObject(rs).conditions
    //                 }
    //             )
    //     )
    // }
    // --
    getGroupsAndBusiness() {
        this.subscription.add(
            this.groupService.readDropdown()
                .subscribe(
                    rs => {
                        this.groups = getArray(rs)
                        this.groupsShow = true
                    },
                    error => {
                        this.groupsShow = true
                    }
                )
        )
    }
    // --
    getNotification() {
        this.subscription.add(
            this.notificationService.readOne(this.pageAction.uuid)
                .subscribe(
                    (rs) => {
                        this.setForm(getObject(rs))
                    }
                )
        )
    }
    // -- 
    setForm(data?: any): void {
        let dateCurrent = new Date()
        this.form = this.formBuilder.group(new PushNotification({}))
        Object.keys(this.form.controls).map(x => {
            if (x !== 'image') {
                this.form.controls[x].setValidators(Validators.required)
            }
        })

        this.formSchedule = this.formBuilder.group(new PushNotificationSchedule({}))
        Object.values(this.formSchedule.controls).map(x => {
            x.setValidators(Validators.required)
        })

        this.formGroup = this.formBuilder.group({
            groups: [[], Validators.required],
        })

        this.typeCatalog()

        if (data) {
            this.form.setValue(data)
            this.form.patchValue({ rules: [] })
            this.form.patchValue({ created: this.datePipe.transform(data.created != "01/01/0001 00:00:00" ? data.created : new Date, 'yyyy-MM-ddTHH:mm:00') })
            this.formGroup.patchValue({ groups: data.groups })
            this.formSchedule.setValue(data.schedule)
            this.setFormRules('set', data.rules)
            // this.getMedia(this.form.value.image)
            this.showComponent = true
        }
        else {
            this.form.patchValue({ created: this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH:mm:00') })
            this.setFormRules('add', true)
            this.showComponent = true
        }
        this.form.patchValue({ timezone: dateCurrent.getTimezoneOffset() })
        // -- inicialization
        this.minDate('calendar')
        this.onAction('frequency')
    }

    indexSelectedField: number = -1
    setFormRules(action: string, value?: any) {
        switch (action) {
            case 'add':
                let valid = true
                if (this.formRules.length) {
                    if (this.formRules[this.formRules.length - 1].invalid) {
                        valid = false
                        Object.values(this.formRules[this.formRules.length - 1].controls).map(x => {
                            return x.markAsTouched()
                        })
                    }
                }
                if (valid) {
                    if (value != undefined && value != null) {
                        this.indexSelectedField = -1
                        this.pushData.rules.fieldSelected.push(0)
                        this.getList('field', this.pushData.rules.fieldSelected.length - 1)
                    }
                    else {
                        this.indexSelectedField = this.pushData.rules.fieldSelected[0]
                        this.pushData.rules.fieldSelected.push(this.indexSelectedField)
                        this.getList('field', this.pushData.rules.fieldSelected.length - 1)
                    }
                    // this.pushData.rules.fielddbSelected.push(0)
                    // this.pushData.rules.conditionSelected.push(0)
                    // --
                    this.pushData.rules.fielddbSelected[this.pushData.rules.fielddbSelected.length] = this.setFielddbSelectedID(this.pushData.rules.fieldSelected.length - 1, this.pushData.rules.fieldSelected[this.pushData.rules.fieldSelected.length - 1])
                    if (this.pushData.rules.fielddbSelected[this.pushData.rules.fielddbSelected.length - 1] == -1) {
                        this.pushData.rules.fieldSelected[this.pushData.rules.fieldSelected.length - 1] = this.setFieldSelectedID(this.pushData.rules.fieldSelected.length - 1)
                        this.pushData.rules.fielddbSelected[this.pushData.rules.fielddbSelected.length - 1] = this.setFielddbSelectedID(this.pushData.rules.fieldSelected.length - 1, this.pushData.rules.fieldSelected[this.pushData.rules.fieldSelected.length - 1])
                    }
                    this.getList('condition', this.pushData.rules.fieldSelected.length - 1)
                    // --
                    this.formRules.push(
                        this.formBuilder.group({
                            field: [this.setValueRules('field'), Validators.required],
                            fielddb: [this.setValueRules('fielddb'), Validators.required],
                            condition: [this.setValueRules('condition'), Validators.required],
                            value: [this.setValueRules('value'), Validators.required],
                        })
                    )
                }
                break;
            case 'delete':
                this.pushData.rules.fieldSelected.splice(value, 1)
                this.pushData.rules.fielddbSelected.splice(value, 1)
                this.pushData.rules.conditionSelected.splice(value, 1)
                this.pushData.rules.field.splice(value, 1)
                this.pushData.rules.condition.splice(value, 1)
                this.formRules.splice(value, 1)
                if (!this.formRules.length) {
                    this.setFormRules('add', true)
                }
                if (this.formRules.length == 1) {
                    this.indexSelectedField = -1
                }
                this.userExistOnBusiness(this.getUsersRules(), this.form.value.groups ? this.form.value.groups.reduce((ac, el) => ac = [...ac, ...el.business.map(x => x.monitorid)], []) : [])
                break;
            case 'set':
                value.map((x, i) => {
                    this.pushData.rules.fieldSelected.push(x.field - 1)
                    this.pushData.rules.fielddbSelected.push(x.fielddb - 1)
                    this.pushData.rules.conditionSelected.push(x.condition - 1)
                    this.getList('field', i)
                    // this.pushData.rules.fielddbSelected[i] = this.setFielddbSelectedID(i, this.pushData.rules.fieldSelected[i])
                    // if (this.pushData.rules.fielddbSelected[i] == -1) {
                    //     this.pushData.rules.fieldSelected[i] = this.setFieldSelectedID(i)
                    //     this.pushData.rules.fielddbSelected[i] = this.setFielddbSelectedID(i, this.pushData.rules.fieldSelected[i])
                    // }
                    this.getList('condition', i)
                    // --
                    this.formRules.push(
                        this.formBuilder.group({
                            field: [this.setValueRules('field'), Validators.required],
                            fielddb: [this.setValueRules('fielddb'), Validators.required],
                            condition: [this.setValueRules('condition'), Validators.required],
                            value: [x.value, Validators.required],
                        })
                    )
                })
                setTimeout(() => {
                    this.userExistOnBusiness(this.getUsersRules(), this.form.value.groups ? this.form.value.groups.reduce((ac, el) => ac = [...ac, ...el.business.map(x => x.monitorid)], []) : [])
                }, 200)
                break;
        }
    }
    // -- 
    get f() {
        return this.form.controls
    }
    // -- 
    get fg() {
        return this.formGroup.controls
    }
    // -- 
    get fs() {
        return this.formSchedule.controls
    }
    // --
    setValueRules(type: string): any {
        switch (type) {
            case 'field':
                return this.pushData.rules.field[this.pushData.rules.fieldSelected.length - 1][this.pushData.rules.fieldSelected[this.pushData.rules.fieldSelected.length - 1]].id
            case 'fielddb':
                return this.pushData.rules.field[this.pushData.rules.fieldSelected.length - 1][this.pushData.rules.fieldSelected[this.pushData.rules.fieldSelected.length - 1]].fielddb[this.pushData.rules.fielddbSelected[this.pushData.rules.fielddbSelected.length - 1]].id
            case 'condition':
                if (this.getList('condition', this.pushData.rules.conditionSelected.length - 1) == 'boolean') {
                    return 1
                }
                return this.pushData.rules.condition[this.pushData.rules.conditionSelected.length - 1][this.pushData.rules.conditionSelected[this.pushData.rules.conditionSelected.length - 1]].id
            case 'value':
                if (this.getList('condition', this.pushData.rules.conditionSelected.length - 1) == 'boolean') {
                    return this.pushData.rules.field[this.pushData.rules.conditionSelected.length - 1][this.pushData.rules.fieldSelected[this.pushData.rules.conditionSelected.length - 1]].fielddb[this.pushData.rules.fielddbSelected[this.pushData.rules.conditionSelected.length - 1]].sysname.toString()
                }
                return ''
        }
    }
    // -- 
    minDate(type: string) {
        let dateMin: Date
        switch (type) {
            case 'fromdate':
                dateMin = new Date()
                if (this.fs.fromdate.value) {
                    dateMin = new Date(this.fs.fromdate.value)
                }
                else {
                    this.formSchedule.patchValue({ 'fromdate': this.datePipe.transform(dateMin, 'yyyy-MM-ddTHH:mm:00') })
                }
                this.pushData.calendar.fromdate = { year: dateMin.getFullYear(), month: dateMin.getMonth() + 1, day: dateMin.getDate() }
                this.pushData.calendar.mindateFrom = this.pushData.calendar.fromdate
                break;
            case 'todate':
                dateMin = new Date(this.fs.fromdate.value)
                let date = new Date()
                if (this.fs.todate.value) {
                    date = new Date(this.fs.todate.value)
                }
                this.pushData.calendar.todate = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
                this.pushData.calendar.mindateTo = { year: dateMin.getFullYear(), month: dateMin.getMonth() + 1, day: dateMin.getDate() }
                if (date < dateMin) {
                    this.pushData.calendar.todate = { year: dateMin.getFullYear(), month: dateMin.getMonth() + 1, day: dateMin.getDate() }
                    this.formSchedule.patchValue({ 'todate': this.datePipe.transform(dateMin, 'yyyy-MM-ddTHH:mm:00') })
                }
                break;
            case 'time':
                dateMin = new Date(this.fs.fromdate.value)
                this.pushData.calendar.time = { hour: dateMin.getHours(), minute: dateMin.getMinutes(), second: 0 }
                break;
            case 'calendar':
                this.minDate('fromdate')
                this.minDate('todate')
                this.minDate('time')
                break;
        }
    }
    // --
    typeCatalog(): any {
        let index: number
        this.pushData.frequency.find((x, i) => {
            if (x.sysname == 'mensual') {
                index = i
            }
        })
        let day = 0
        let lastDate = 31
        if (this.fs.month.value) {
            lastDate = new Date(2020, this.fs.month.value, 0).getDate()
        }
        this.pushData.frequency[index].value = []
        while (day < lastDate) {
            this.pushData.frequency[index]?.value.push(day + 1)
            day++
        }
        return this.pushData.frequency[index]?.value
    }
    // --
    onAction(type: any, value?: any) {
        switch (type) {
            case 'frequency':
                this.showAnualToMonth = false
                let key = 0
                this.pushData.frequency.map((x, i) => {
                    if (x.sysname == this.fs.frequency.value) {
                        key = i
                    }
                })
                this.pushData.frequencySelected = key
                // -- Unico
                if (key == 0) {
                    this.formSchedule.patchValue({ 'todate': '' })
                    this.formSchedule.patchValue({ 'day': -1 })
                    this.formSchedule.patchValue({ 'month': -1 })
                    this.formSchedule.patchValue({ 'weekday': -1 })
                }
                else {
                    this.formSchedule.patchValue({ 'todate': this.datePipe.transform(new Date(this.fs.fromdate.value), 'yyyy-MM-ddTHH:mm:00') })
                }
                // -- Diario
                if (key == 1) {
                    this.formSchedule.patchValue({ 'day': -1 })
                    this.formSchedule.patchValue({ 'month': -1 })
                    this.formSchedule.patchValue({ 'weekday': -1 })
                }
                // -- Semanal
                if (key == 2) {
                    this.formSchedule.patchValue({ 'day': -1 })
                    this.formSchedule.patchValue({ 'month': -1 })
                    if (!this.formSchedule.value.weekday) {
                        this.formSchedule.patchValue({ 'weekday': new Date(this.formSchedule.value.fromdate).getDay() })
                    }
                }
                // -- Mensual
                if (key == 3) {
                    if (!this.formSchedule.value.day) {
                        this.formSchedule.patchValue({ 'day': new Date(this.formSchedule.value.fromdate).getDate() })
                    }
                    this.formSchedule.patchValue({ 'month': -1 })
                    this.formSchedule.patchValue({ 'weekday': -1 })
                }
                // -- Anual
                if (key == 4) {
                    if (!this.formSchedule.value.month) {
                        this.formSchedule.patchValue({ 'day': new Date(this.formSchedule.value.fromdate).getDate() })
                        this.formSchedule.patchValue({ 'month': new Date(this.formSchedule.value.fromdate).getMonth() + 1 })
                    }
                    this.formSchedule.patchValue({ 'weekday': -1 })
                }

                this.minDate('todate')
                break;
            case 'groups':
                this.form.patchValue({ groups: [...value.data] })
                this.userExistOnBusiness(this.getUsersRules(), this.form.value.groups ? this.form.value.groups.reduce((ac, el) => ac = [...ac, ...el.business.map(x => x.monitorid)], []) : [])
                break;
            case 'fromdate':
            case 'todate':
                let { year, month, day } = this.pushData.calendar[type]
                let date = new Date(year, month - 1, day)
                this.formSchedule.patchValue({ [type]: this.datePipe.transform(date, 'yyyy-MM-ddTHH:mm:00') })
                if (type == 'fromdate') {
                    this.minDate('todate')
                }
                break;
            case 'month':
                this.typeCatalog()
                this.formSchedule.patchValue({ [type]: value + 1 })
                this.showAnualToMonth = true
                break;
            case 'day':
                this.formSchedule.patchValue({ [type]: value + 1 })
                break;
            case 'weekday':
                this.formSchedule.patchValue({ [type]: value })
                break;
            case 'image':
                setTimeout(() => {
                    this.pushData.media.filesToUpload = value;
                    if (this.pushData.media.filesToUpload.length) {
                        let id = this.pushData.media.filesToUpload[0].hasOwnProperty('uuid') ? 'uuid' : 'id';
                        let url = this.pushData.media.filesToUpload[0].hasOwnProperty('url') ? 'url' : 'url';
                        let alt = this.pushData.media.filesToUpload[0].hasOwnProperty('alt') ? 'alt' : 'alt';
                        // this.form.patchValue({ image: { uuid: this.pushData.media.filesToUpload[0][id] !== undefined ? this.pushData.media.filesToUpload[0][id] : '', url: this.pushData.media.filesToUpload[0][url], alt: this.pushData.media.filesToUpload[0][alt] } });
                        this.form.patchValue({ image: this.pushData.media.filesToUpload[0][url] });
                        this.pushData.media.files[0] = {
                            alt: this.pushData.media.filesToUpload[0][alt],
                            status: true,
                            url: this.pushData.media.filesToUpload[0][url],
                            uuid: this.pushData.media.filesToUpload[0][id],
                        }
                    }
                    else {
                        this.form.patchValue({ image: '' });
                    }
                }, 50)
                break;
            case 'rules':
                let index = value.split('-')[0]
                let field = value.split('-')[1]
                if (field != 'value') {
                    let indexCondition = this.pushData.rules.conditionSelected[index] * 1
                    if (field == 'field') {
                        this.pushData.rules.fielddbSelected[index] = this.setFielddbSelectedID(index, this.pushData.rules.fieldSelected[index])
                    }
                    // --
                    this.formRules[index].patchValue({ field: this.pushData.rules.field[index][this.pushData.rules.fieldSelected[index]].id })
                    this.formRules[index].patchValue({ fielddb: this.pushData.rules.field[index][this.pushData.rules.fieldSelected[index]].fielddb[this.pushData.rules.fielddbSelected[index]].id })
                    if (this.pushData.rules.condition[index][indexCondition]) {
                        this.formRules[index].patchValue({ condition: this.pushData.rules.condition[index][indexCondition].id })
                    }
                    this.formRules[index].patchValue({ value: '' })
                    // --
                    if (this.getList('condition', index) == 'boolean') {
                        this.formRules[index].patchValue({ condition: 1 })
                        this.formRules[index].patchValue({ value: this.pushData.rules.field[index][this.pushData.rules.fieldSelected[index]].fielddb[this.pushData.rules.fielddbSelected[index]].sysname.toString() })
                    }
                }
                this.usersNotExistBusiness[index] = false
                break;
            case 'save':
                // --
                if (!this.form.value.created) {
                    this.form.patchValue({ created: this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH:mm:ss') })
                }
                // --
                let time = this.pushData.calendar.time
                let fromDate = this.pushData.calendar.fromdate
                let toDate = this.pushData.calendar.todate

                if (!this.formSchedule.value.todate) {
                    this.formSchedule.patchValue({ todate: this.datePipe.transform(new Date(fromDate.year, fromDate.month - 1, fromDate.day, time.hour, time.minute, 0), 'yyyy-MM-ddTHH:mm:ss') })
                }
                else {
                    this.formSchedule.patchValue({ todate: this.datePipe.transform(new Date(toDate.year, toDate.month - 1, toDate.day, time.hour, time.minute, 0), 'yyyy-MM-ddTHH:mm:ss') })
                }
                this.formSchedule.patchValue({ fromdate: this.datePipe.transform(new Date(fromDate.year, fromDate.month - 1, fromDate.day, time.hour, time.minute, 0), 'yyyy-MM-ddTHH:mm:ss') })
                this.formSchedule.patchValue({ weekday: Number(this.formSchedule.value.weekday) })
                this.formSchedule.patchValue({ day: Number(this.formSchedule.value.day) })
                this.formSchedule.patchValue({ month: Number(this.formSchedule.value.month) })

                this.formSchedule.controls.todate.clearValidators()
                this.formSchedule.controls.todate.updateValueAndValidity()
                this.formSchedule.controls.weekday.clearValidators()
                this.formSchedule.controls.weekday.updateValueAndValidity()
                this.formSchedule.controls.day.clearValidators()
                this.formSchedule.controls.day.updateValueAndValidity()
                this.formSchedule.controls.month.clearValidators()
                this.formSchedule.controls.month.updateValueAndValidity()
                if (this.pushData.frequencySelected > 1) {
                    this.formSchedule.controls.todate.setValidators(Validators.required)
                    this.formSchedule.controls.todate.updateValueAndValidity()
                }
                if (this.pushData.frequencySelected > 2) {
                    this.formSchedule.controls.weekday.setValidators(Validators.required)
                    this.formSchedule.controls.weekday.updateValueAndValidity()
                }
                if (this.pushData.frequencySelected > 3) {
                    this.formSchedule.controls.day.setValidators(Validators.required)
                    this.formSchedule.controls.day.updateValueAndValidity()
                }
                if (this.pushData.frequencySelected > 4) {
                    this.formSchedule.controls.day.setValidators(Validators.required)
                    this.formSchedule.controls.day.updateValueAndValidity()
                    this.formSchedule.controls.month.setValidators(Validators.required)
                    this.formSchedule.controls.month.updateValueAndValidity()
                }
                // --
                this.validateData.invalid = 0
                this.formRules.map(x => {
                    if (x.invalid) {
                        this.validateData.invalid = 1
                        Object.values(x.controls).map(y => {
                            y.markAsTouched()
                        })
                    }
                })
                if (!this.validateData.invalid) {
                    let rules: any = []
                    this.formRules.map((x, y) => {
                        rules.push(x.value)
                        if (y == this.formRules.length - 1) {
                            this.form.patchValue({ rules: rules })
                        }
                    })
                }

                if (this.form.invalid || this.formSchedule.invalid) {
                    this.validateData.invalid = 1
                    Object.values(this.form.controls).concat(Object.values(this.formSchedule.controls)).forEach(control => {
                        control.markAsTouched()
                    });
                }
                else {
                    this.form.patchValue({ schedule: this.formSchedule.value })
                }
                // -- llamamos las validaciones de los componentes internos
                this.validateData.invalidForm = 0
                this.validateData.sectionFormCounter = 1
                this.validateData.sectionFormCounterReponse = 0
                this.modalHelper.pollChange({
                    module: 'validForm',
                })
                break;
            case 'validate-users':
                this.userExistOnBusiness(this.getUsersRules(), this.form.value.groups ? this.form.value.groups.reduce((ac, el) => ac = [...ac, ...el.business.map(x => x.monitorid)], []) : [])
                break;
        }
    }
    // --
    resetPicture(): void {
        this.form.patchValue({
            image: ''
        });
        this.pushData.media.files = []
    }
    // --
    isSingleFile($event) {
        this.pushData.media.isSingleFiles = $event;
        if (this.pushData.media.files.length) if (this.pushData.media.isSingleFiles) this.pushData.media.files = [this.pushData.media.files.pop()];
    }
    // --
    savePush() {
        if (!this.validateData.invalid && !this.usersNotExistBusiness.filter(x => x).length) {
            this.callToApi(this.form.value)
        }
    }
    // --
    callToApi(form: any) {
        if (!form.todate) {
            form.todate = form.fromdate
        }
        switch (this.pageAction.action) {
            case 'create':
                this.subscription.add(
                    this.notificationService.create(form)
                        .subscribe(
                            (rs: ResponseData) => {
                                this.alertNotificationService.success('Notificaciones Push', 'Se creo la notificación push correctamente', { autoClose: true, keepAfterRouteChange: true });
                                this.router.navigateByUrl('/notifications');
                            },
                            error => {
                                console.error(error)
                                this.alertNotificationService.error('Notificaciones Push', 'Ocurrio un error al crear la notificación push', { autoClose: true, keepAfterRouteChange: true });
                            }
                        )
                )
                break;
            case 'edit':
                this.subscription.add(
                    this.notificationService.update(form.uuid, form)
                        .subscribe(
                            (rs: ResponseData) => {
                                this.alertNotificationService.success('Notificaciones Push', 'Se actualizó la notificación push correctamente', { autoClose: true, keepAfterRouteChange: true });
                                this.router.navigateByUrl('/notifications');
                            },
                            error => {
                                console.error(error)
                                this.alertNotificationService.error('Notificaciones Push', 'Ocurrio un error al actualizar la notificación push', { autoClose: true, keepAfterRouteChange: true });
                            }
                        )
                )
                break;
        }
    }
    // --
    getList(type: string, index: number): any {
        let typeData
        switch (type) {
            case 'field':
                this.pushData.rules.field[index] = this.field
                return null
            case 'condition':
                typeData = this.pushData.rules.field[index][this.pushData.rules.fieldSelected[index]].fielddb[this.pushData.rules.fielddbSelected[index]].type
                this.pushData.rules.condition[index] = this.condition.filter(x => x.type == typeData)
                this.pushData.rules.conditionSelected[index] = 0
                return typeData
        }
    }
    // --
    existOptionSelected(index: number, indexField: number, indexFielddb: any): any {
        let exist: boolean = true
        Array.from(this.formRules.values()).map((x, i) => {
            let value = x.value
            if (i != index) {
                if (value.field == this.pushData.rules.field[index][indexField].id) {
                    if (value.fielddb == this.pushData.rules.field[index][indexField].fielddb[indexFielddb].id && this.pushData.rules.field[index][indexField].fielddb[indexFielddb].unique) {
                        exist = false
                    }
                }
            }
        })
        return exist
    }
    // --
    setFielddbSelectedID(index: number, indexField: number): any {
        let exist: number[] = []
        let indexValue: number = -1
        let indexValueAux: number = null
        let valuesExistUnique: number[] = []
        if (Array.from(this.formRules.values()).length) {
            Array.from(this.formRules.values()).map((x, i) => {
                if (2 == x.value.field) {
                    valuesExistUnique.push(x.value.fielddb)
                }
                // let value = x.value
                // // if (i != index) {
                // this.pushData.rules.field[index][indexField].fielddb.map((y, yi) => {
                //     if (this.pushData.rules.field[index][indexField].id == value.field && y.id == value.fielddb) {
                //         exist.push(yi)
                //     }
                // })
                // // }
                // if (i == Array.from(this.formRules.values()).length - 1) {
                //     this.pushData.rules.field[index][indexField].fielddb.map((y, yi) => {
                //         if (!exist.includes(yi) || !y.unique) {
                //             if (indexValue == -1) {
                //                 indexValue = yi
                //             }
                //         }
                //     })
                // }
                this.pushData.rules.field[index][indexField].fielddb.map((y, yi) => {
                    if (indexValue == -1) {
                        if (!y.unique) {
                            indexValue = yi
                        }
                    }
                    else {
                        return false;
                    }
                })
            })
        }
        else {
            indexValue = 0
        }
        // -- Logica para no repetir las opciones de el campo de la db de las reglas
        if (indexValue == -1) {
            this.pushData.rules.field[index][indexField].fielddb.map((y, yi) => {
                if (y.unique) {
                    if (!valuesExistUnique.includes(y.id)) {
                        if (indexValueAux == null) {
                            indexValueAux = yi
                        }
                    }
                }
            })
            indexValue = indexValueAux != null ? indexValueAux : 0
        }
        return indexValue
    }
    // --
    setFieldSelectedID(index: number): any {
        let exist: number[] = []
        let indexValue: number = -1
        if (Array.from(this.formRules.values()).length) {
            Array.from(this.formRules.values()).map((x, i) => {
                let value = x.value
                if (i != index) {
                    this.pushData.rules.field[index].map((y, yi) => {
                        if (y.id == value.field) {
                            exist.push(yi)
                        }
                    })
                }
                if (i == Array.from(this.formRules.values()).length - 1) {
                    this.pushData.rules.field[index].map((y, yi) => {
                        if (!exist.includes(yi)) {
                            if (indexValue == -1) {
                                indexValue = yi
                            }
                        }
                    })
                }
            })
        }
        else {
            indexValue = 0
        }
        return indexValue
    }
    // --
    monitor: any = null
    paginate: any = {
        pageSize: 500,
        page: 1,
    }
    usersNotExistBusiness: any[] = []
    loginMonitor() {
        if (existStorage(constants.ls.sessionMonitor)) {
            this.monitor = getStorage(constants.ls.sessionMonitor, true)
        }
        else {
            this.subscription.add(
                this.notificationService.monitorLogin()
                    .subscribe(
                        rs => {
                            this.monitor = getObject(rs)
                            if (this.monitor) {
                                setStorage(constants.ls.sessionMonitor, this.monitor)
                            }
                        }
                    )
            )
        }
    }
    // --
    userExistOnBusiness(users: any[], business: any[], currentPage?: any, data?: any) {
        this.usersNotExistBusiness = []
        if (users.length && business.length) {
            if (!currentPage) {
                currentPage = this.paginate.page
            }
            let body = {
                "pageSize": this.paginate.pageSize,
                "page": currentPage,
                "sortDirection": "asc",
                "sort": "us.id",
                "filters": {
                    "idCompanys": business,
                    "idUsers": users,
                    "age": null,
                    "diseases": [],
                    "sex": [],
                    "postalCode": [],
                    "type": [],
                    "plantilla": [],
                    "filters-symptoms": null
                }
            }
            this.subscription.add(
                this.notificationService.monitorFilterUsers(this.monitor, body)
                    .subscribe(
                        rs => {
                            let result = getObject(rs)
                            if (!data) {
                                data = []
                            }
                            data = [...data, ...result.data.usuarios]
                            let totalPages = result.data.total_rows / this.paginate.pageSize
                            //-- Verificamos si la division es par obtenemos el módulo -> total de registros %  total por pagina -> 11254 % 500 -> 0.508 -> Si es <> a cero agregamos una página extra -> tendriamos 23 paginas
                            if (result.data.total_rows % result.data.pageSize != 0) {
                                totalPages++;
                            }
                            if (totalPages > currentPage) {
                                currentPage += 1
                                this.userExistOnBusiness(users, business, currentPage, data)
                            }
                            else {
                                this.processDataFilter(data)
                            }
                        }
                    )
            )
        }
        else {
            if (!business.length) {
                Array.from(this.formRules).map((x, i) => {
                    if (1 == x.value.field && 1 == x.value.fielddb && 1 == x.value.condition) {
                        this.usersNotExistBusiness[i] = x.value.value
                    }
                })
            }
        }
    }
    // --
    processDataFilter(data: any[]) {
        this.usersNotExistBusiness = []
        Array.from(this.formRules).map((x, i) => {
            if (1 == x.value.field && 1 == x.value.fielddb && 1 == x.value.condition) {
                if (!data.find(y => y.id_usuario == x.value.value)) {
                    this.usersNotExistBusiness[i] = x.value.value
                }
            }
        })
    }
    // --
    getUsersRules(): any[] {
        let users = []
        Array.from(this.formRules).map((x, i) => {
            if (1 == x.value.field && 1 == x.value.fielddb && 1 == x.value.condition) {
                users.push(x.value.value)
            }
        })
        return users
    }
    // --
    showIndexSelectedField(index): boolean {
        let show = true
        if (this.indexSelectedField != -1) {
            show = this.indexSelectedField == index
        }
        return show
    }
    // --
    haveOptionsToAdd(): boolean {
        let show = true
        if (this.indexSelectedField != -1) {
            let itemsnotUnique = this.field[this.indexSelectedField].fielddb.filter(x => !x.unique)
            let itemsUnique = this.field[this.indexSelectedField].fielddb.filter(x => x.unique)
            if (this.pushData.rules.fielddbSelected.length == itemsUnique.length) {
                show = false
            }
            if (itemsnotUnique.length) {
                show = true
            }
        }
        return show
    }

    getMonth(key: number): string {
        let months = [
            'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
        ]
        return months[key - 1]
    }
}