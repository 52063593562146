import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-boton',
  templateUrl: './boton.component.html',
  styleUrls: ['./boton.component.css']
})
export class BotonComponent implements OnInit {
  @Input() textContent!:string;
  @Input() backgroundColor!:string;

  constructor() { }

  ngOnInit(): void {
  }

}
