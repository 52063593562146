<div class="container-fluid">
    <div class="card border-card">
        <div class="card-content">
            <div class="row">
                <div class=" card__title col-auto">
                    <h3>Aplicar Plantilla</h3>
                </div>
            </div>
            <form [formGroup]="empresaForm" novalidate>
                <div class="row">
                    <div class="col-12 col-md-4">
                        <div class="card">
                            <div class="card-body">
                                <div class="form-group ">
                                    <label for="rolName">*Nombre Plantilla:</label>
                                    <select class="form-control" id="selectPlantilla"
                                        formControlName="plantillas" (change)="onPlantillaChange($event)"
                                        [ngStyle]="empresaForm.get('plantillas').errors && (empresaForm.get('plantillas').dirty || empresaForm.get('plantillas').touched) && {'border-color': 'red','margin-bottom': '3px'}">
                                        <option value="">Seleccione</option>
                                        <option value="{{item.id}}" *ngFor="let item of plantillas">
                                            {{item.name}}</option>
                                    </select>
                                    <div *ngIf="empresaForm.get('plantillas').errors && (empresaForm.get('plantillas').dirty || empresaForm.get('plantillas').touched)">
                                        <p class="invalidFieldForm" *ngIf="empresaForm.get('plantillas').hasError('required')">Este
                                            campo es requerido</p>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                    <div class="col-12 col-md-8">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-6">
                                        <app-groups-business-template [plantilla]="buscarPlantilla"
                                            [data]="fg.groups.value" [permissions]="permissions">
                                        </app-groups-business-template>
                                        
                                    </div>
                                    <div class="form-group col-6">
                                        <label for="rolName">Id Monitor:</label>
                                        <input class="form-control" type="number" formControlName="idMonitor"
                                            id="" placeholder="Id Monitor">
                                        
                                    </div>
                                    <div class="col-12">
                                        <button type="button" (click)="buscarPlantilla()"
                                            class="btn btn-primary btn-block m-0" [disabled]="empresaForm.invalid">
                                            Buscar
                                        </button>
                                    </div>


                                    <!-- <div class="col-xs-4 col-md-4 col-lg-5 col-xl-5">
                                        <div class="mb-lg-3 ml-3">
                                            <app-groups-business-template [plantilla]="buscarPlantilla"
                                                [data]="fg.groups.value" [permissions]="permissions">
                                            </app-groups-business-template>
                                        </div>
                                    </div>
                                    <div class="input-group col-xs-4 col-md-4 col-lg-5 col-xl-5">
                                        <label for="rolName">Id Monitor:</label>
                                            <input style="margin-left:10px"  class="form-control" type="number" formControlName="idMonitor"
                                                id="" placeholder="Id Monitor">
                                    </div>
                                    <div class="input-group col-xs-4 col-md-4 col-lg-2 col-xl-2">
                                        <button type="button" (click)="buscarPlantilla()"
                                            class="btn btn-success btn-block m-0" [disabled]="empresaForm.invalid">
                                            Buscar
                                        </button>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </form>
            
            <ng-container *ngIf="dataTable.showTable">
                <div class="row">
                    <div class="col-12">
                        <div class="container-fluid">
                            <abarcms-input-search [items]="dataTable.empleados"
                                [fieldsToSearch]="['nombre','idusuario']" (dataFound)="dataFound($event)"
                                placeholder="Para buscar puede introducir el nombre o id de usuario">
                            </abarcms-input-search>
                            <div id="contenido-table_wrapper" class="dataTables_wrapper no-footer mt-4">
                                <table style="width:100%">
                                    <thead>
                                        <tr>
                                            <th>
                                                <p>Empresa</p>
                                            </th>
                                            <th>
                                                <p>Id Monitor</p>
                                            </th>
                                            <th>
                                                <p>Nombre Empleado</p>
                                            </th>
                                            <th>
                                                <div>Asignar {{paginator.total ? paginator.total : ''}}</div>
                                                <input type="checkbox" (change)="selectedAllItems($event)"
                                                    [checked]="dataTable.totalChecked == dataTable.empleados.length">
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody style="border-top: 1px solid #ddd ">
                                        <tr *ngFor="let item of dataTable.empleadosList">
                                            <td>{{ item.empresa }}</td>
                                            <td>{{ item.idusuario }}</td>
                                            <td>{{ item.nombre }}</td>
                                            <td><input type="checkbox" (change)="onChecked(item.idusuario)"
                                                    [checked]="item.checked">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-4">
                    <abarcms-paginator [items]="dataTable.empleadosPage" [initialPage]="paginator.current"
                        [perPage]="paginator.perPage" [maxPages]="5" [lblFirst]="'Primera'" [lblLast]="'Última'"
                        [lblPrev]="'Anterior'" [lblNext]="'Siguiente'" [btnSec]="true" [btnPri]="true"
                        (changePage)="changePage($event)" [align]="'center'"></abarcms-paginator>
                    <p *ngIf="!dataTable.empleados.length" class="alert alert-warning text-center">
                        No se encontraron datos
                    </p>
                </div>
            </ng-container>
            
            <div class="botonera mt-4">
                <button class="btn btn-dark float-left btn-block" routerLink="/dashboard">
                    Cancelar
                </button>
                <button type="button" (click)="guardarPlantilla()" class="btn btn-primary btn-block my-0"
                    [disabled]="empresaForm.invalid">
                    Guardar
                </button>
            </div>
        </div>

        <!-- <div class="col-12 mb-4">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                </div>
            </div>
        </div> -->
    </div>
</div>