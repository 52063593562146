


<div class="container-fluid">
    <div class="card border-card">
        <div class="card-content">
            <div class="row">
                <div class=" card__title col-auto">
                    <h2 [hidden]="!crear">Crear Plantilla</h2>
                    <h2 [hidden]="crear">Actualizar Plantilla</h2>
                </div>
            </div>
            <form [formGroup]="createForm" novalidate>
                <div class="row">
                    <div class="col-12 col-md-4">
                        <div class="card">
                            <div class="card-body">
                                <div class="form-group ">
                                    <label for="rolName">*Nombre de Plantilla:</label>
                                    <input class="form-control" type="text" formControlName="namePlantilla" placeholder="Nombre de Plantilla" [ngStyle]="createForm.get('namePlantilla').errors && (createForm.get('namePlantilla').dirty || createForm.get('namePlantilla').touched) && {'border-color': 'red','margin-bottom': '3px'}" (keyup)="keyFunc($event)">
                                    
                                </div>
                                <div class="col-12" *ngIf="createForm.get('namePlantilla').errors && (createForm.get('namePlantilla').dirty || createForm.get('namePlantilla').touched)">
                                    <p class="invalidFieldForm"  *ngIf="createForm.get('namePlantilla').hasError('required')">Este campo es requerido</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    
                    <div class="col-12 col-md-8">
                        <div class="card">
                            <div class="card-body">
                                <ngb-tabset [destroyOnHide]="false">

                                    <ngb-tab>
                                        <ng-template ngbTabTitle>Información General</ng-template>
                                        <ng-template ngbTabContent>
                                            <div class="card-body">
                                                <div class="form-group">
                                                    <table style="width:100%">
                                                        <thead>
                                                            <tr>
                                                                <th style="max-width:200px">Campos a Deshabilitar</th>
                                                                <th> <input type="checkbox"
                                                                    (change)="selectedAllInfo($event)"
                                                                    [checked]="checkItem('info')"> 
                                                                    Agregar 
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style="border-top: 1px solid #ddd ">
                                                            <tr *ngFor="let item of groupInfoGeneral" class="mt-2">
                                                                <td style="max-width:200px">{{ item.alias }}</td>
                                                                <td><input type="checkbox"  id="{{item.idcamposmodulomonitor}}" [value]= item (change)="getSelectedInfoGral($event, item.idcamposmodulomonitor)" [checked]="checkItem('info', item)">
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngb-tab>
                                    <ngb-tab>
                                        <ng-template ngbTabTitle>Información de la Empresa</ng-template>
                                        <ng-template ngbTabContent>
                                            <div class="card-body">
                                                <div class="form-group">
                                                    <table style="width:100%">
                                                        <thead>
                                                            <tr>
                                                                <th style="max-width:200px">Asignación de Permisos</th>
                                                                <th> <input type="checkbox"
                                                                    (change)="selectedAllEmpresa($event)"
                                                                    [checked]="checkItem('empresa')">
                                                                    Agregar
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style="border-top: 1px solid #ddd ">
                                                            <tr *ngFor="let item of groupEmpresa">
                                                                <td style="max-width:200px">{{ item.alias }}</td>
                                                                <td><input type="checkbox"
                                                                        id="{{item.idcamposmodulomonitor}}"
                                                                        [value]=item
                                                                        (change)="getSelectedInfoEmpresa($event, item.idcamposmodulomonitor)"
                                                                        [checked]="checkItem('empresa', item)">
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        
                                                    </table>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngb-tab>
                                    <ngb-tab>
                                        <ng-template ngbTabTitle>Sitio de Trabajo</ng-template>
                                        <ng-template ngbTabContent>
                                            <div class="card-body">
                                                <div class="form-group">
                                                    <table style="width:100%">
                                                        <thead>
                                                            <tr>
                                                                <th style="max-width:200px">Habilitar Sitio(s) de Trabajo</th>
                                                                <th> <input type="checkbox"
                                                                    (change)="selectedAllTrabajo($event)"
                                                                    [checked]="checkItem('trabajo')">
                                                                    Agregar
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style="border-top: 1px solid #ddd ">
                                                            <tr *ngFor="let item of groupTrabajo">
                                                                <td style="max-width:200px">{{ item.alias }}</td>
                                                                <td><input type="checkbox"
                                                                        id="{{item.idcamposmodulomonitor}}"
                                                                        [value]=item
                                                                        (change)="getSelectedInfoTrabajo($event, item.idcamposmodulomonitor)"
                                                                        [checked]="checkItem('trabajo', item)">
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        
                                                    </table>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngb-tab>

                                </ngb-tabset>
                                
                            </div>
                        </div>
                    </div>
                    
                </div>
                
                <div class="botonera mt-4">
                    <button type="submit" class="btn btn-dark btn-block" (click)="cancel()">
                        Cancelar
                    </button>
                    
                    <button type="submit" (click)="saveData()" class="btn btn-primary btn-block my-0"
                    [disabled]="!isValidName()">
                        Guardar
                    </button>
                </div>
            </form>
            
        </div>
    </div>
</div>