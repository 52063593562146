import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MediaDataService } from "./data/media-data.service";
import { Media } from "../dto/Media";
import { SessionService } from "./session.service";
import { Observable, of } from "rxjs";
import { ResponseData } from "../dto/ResponseData";
import { tap, catchError, map } from "rxjs/operators";
import {
  getValid,
  getStatus,
  getData,
  getObject,
  getArray,
  makeHeadersFormData,
} from "../utils/_http";
import { getMonitorUserId } from "src/app/screens/admin/seccion-monitor/services/http-monitor";
import { SettingsService } from "src/app/services";
import { makeHeaders } from "src/app/utils/_http";
import { HttpMonitorService } from "../screens/admin/seccion-monitor/services/http-monitor.service";

@Injectable({
  providedIn: "root",
})
export class MediaService {
  private baseUrl: string;
  pathInfo: any;

  constructor(
    protected session: SessionService,
    private mediaDataService: MediaDataService,
    private settingsService: SettingsService,
    private http: HttpClient,
    private monitorService: HttpMonitorService
  ) {
    this.pathInfo = this.settingsService.get("pathInfo");
    this.baseUrl = this.pathInfo.base.monitor.url;
  }

  getAll(): Observable<any> {
    return this.mediaDataService
      .read(this.session.accessToken.accessToken)
      .pipe(
        map((rs) => {
          if (rs) {
            if (rs.status < 300) {
              return getArray(rs);
            } else {
              return { error: getStatus(rs) };
            }
          }
        }),
        catchError(this.handleError("getAllMedia", []))
      );
  }

  getOne(media_id: any): Observable<any> {
    return this.mediaDataService.readOne(media_id).pipe(
      map((rs) => {
        if (rs) {
          if (rs.status < 300) {
            return getObject(rs);
          } else {
            return { error: getStatus(rs) };
          }
        }
      }),
      catchError(this.handleError("getByID", {}))
    );
  }

  delete(id: any): Observable<any> {
    return this.mediaDataService
      .delete(id, this.session.accessToken.accessToken)
      .pipe(
        map((rs) => {
          if (rs) {
            if (rs.status < 300) {
              return {};
            } else {
              return { error: getStatus(rs) };
            }
          }
        }),
        catchError(this.handleError("getByID", {}))
      );
  }

  create(body: any): Observable<any> {
    return this.mediaDataService
      .create(body, this.session.accessToken.accessToken)
      .pipe(
        map((rs) => {
          if (rs) {
            if (rs.status < 300) {
              return getArray(rs);
            } else {
              return { error: getStatus(rs) };
            }
          }
        }),
        catchError(this.handleError("getByID", {}))
      );
  }

  async createImgCms(formData: any): Promise<Observable<any>> {
    let monitorUserId: string = await this.monitorService.getMonitorUserId();
    let url = this.baseUrl + "/v2/admin/" + monitorUserId + "/storageImage";

    const headers = makeHeadersFormData({});

    return this.http.post(url, formData, headers);
  }

  udpdate(id: any, body: any): Observable<any> {
    return this.mediaDataService
      .update(id, body, this.session.accessToken.accessToken)
      .pipe(
        map((rs) => {
          if (rs) {
            if (rs.status < 300) {
              return {};
            } else {
              return { error: getStatus(rs) };
            }
          }
        }),
        catchError(this.handleError("getByID", {}))
      );
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
