import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseData } from 'src/app/dto/ResponseData';
import { SettingsService } from '../../settings.service';
import { ApiService } from '../../api.service';

@Injectable({
  providedIn: 'root'
})
export class ApplyTemplateDataWsService {
  private pathInfo: any;
  private baseUrl: string;
  private segment: any;
  constructor(
    private settings: SettingsService,
    private api: ApiService
  ) {
    this.pathInfo = this.settings.get('pathInfo');
    this.baseUrl = this.pathInfo.base.baseUrl + this.pathInfo.base.prefix;
    this.segment = this.pathInfo.res.admin['role'];
  }
  private read(body: object, accessToken: string): Observable<ResponseData> {
    let endpoint = this.baseUrl + '/usuariosMonitor';
    let options = {
      'X-Auth-Token': accessToken,
      modal: true
    };
    return this.api.post(endpoint, body, true, options);
  }
  private create(body: object, accessToken: string): Observable<ResponseData> {
    let options = {
      'X-Auth-Token': accessToken,
      modal: true
    };
    let endpoint = this.baseUrl + '/asignarPlantilla';
    return this.api.post(endpoint, body, true, options);
  }

}
