<div class="container-fluid polls-action" *ngIf="showComponent">
  <div class="card border-card">
    <div class="card-body row">

      <div class="col-12 card__title">
        <h2 class="title-list">{{pageAction.titlePage}}</h2>
      </div>
      <div class="col-12">

        <div class="row">
          <div class="col-12">
            <!--info encuesta -->
            <div>
              <app-polls-questions [action]="'edit'" [data]="poll.sections" (onAction)=" onAction($event)"
                [pollTypeQuestions]="pollTypeQuestions" mode="repository">
              </app-polls-questions>
            </div>
          </div>

        </div>
        <!-- <div class="row">
          <div class="col-12 col-lg-7 label-poll">
            <div class="card " style="min-height: 380px;">
              <div class="card-body">
                <h5 class="card-title">Encuestas relacionadas</h5>
                <ul class="polls-related m-0 p-0">
                  <li class="pr-2" (click)="hiddenPollFunction">
                    <p class="m-0">Encuesta N</p>
                    <input type="hidden" name="" id="" [(ngModel)]="pollsHidden">
                    <a data-toggle="tooltip" data-placement="top" title="Ocultar">
                      <span aria-hidden="true" class="far fa-eye"></span>
                    </a>
                  </li>
                </ul>

              </div>
            </div>
          </div>

        </div> -->

        <div class="botonera">
          <button type="button" class="btn  btn-dark btn-block mb-0" routerLink="/polls/questions">Regresar</button>
          <button type="button" class="btn btn-primary btn-block my-0" *ngIf="showButtonSave">Guardar</button>
        </div>

      </div>
    </div>
  </div>

</div>