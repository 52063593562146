
<div class="headerClose"><div class="close"  (click)="closeDialog()">
    ×
</div>
<br>
<div mat-dialog-content class="contentDialog" *ngIf="data.titulo!= undefined && data.titulo!= null && data.titulo!=''">
    {{data.titulo}}
</div>
<div *ngIf="data.origen=='crearPagina'">
  <div class="divRow groupButton">
    <app-boton [textContent]="'Aceptar'" [backgroundColor]="'#000000'" class="buttonCancelar" (click)="closeDialog()"></app-boton>
  </div>
</div>
<div *ngIf="data.origen=='deleteSection'">
  <div>Desea continuar con la eliminación</div>
  <div class="divRow groupButton">
    <app-boton [textContent]="'Aceptar'" (click)="deleteSection()"></app-boton>
    <app-boton [textContent]="'Cancelar'" [backgroundColor]="'#000000'" class="buttonCancelar" (click)="closeDialog()"></app-boton>
</div>
</div>
<div *ngIf="data.origen=='invalidForm'">
  <div>Por favor verifica los campos requeridos</div>
  <div class="divRow groupButton">
    <app-boton [textContent]="'Aceptar'" [backgroundColor]="'#000000'" class="buttonCancelar" (click)="closeDialog()"></app-boton>
</div>
</div>

<div *ngIf="data.origen=='dialogConfirm'">
  <div>{{data.mensaje}}</div>
  <div class="divRow groupButton">
    <app-boton [textContent]="'Aceptar'" [backgroundColor]="'#000000'" class="buttonCancelar" (click)="closeDialog()"></app-boton>
  </div>
</div>