export class Paginator {
    currentPage: number;
    itemsPerPage: number;
    maxPages: number;
    size: string;
    align: string;
    lblFirst: string;
    lblLast: string;
    lblPrev: string;
    lblNext: string;
}