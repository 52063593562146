<div class="container-fluid">
    <div class="card border-card">
        <div class="card-content">
            <div class="row justify-content-between mb-20">
                <div class="card__title col-auto">
                    <h2>{{pageAction['titlePage']}}</h2>
                </div>
            </div>
            <div class="wrap">
                <form [formGroup]="formaRole">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="name">Nombre del rol</label>
                                <input autocomplete="off" type="text" class="form-control" placeholder=""
                                    formControlName="name" [ngClass]="{'is-invalid invalidFieldForm': validName}"
                                    [ngStyle]="validName && {'border-color': 'red','margin-bottom': '3px'}"
                                    [readonly]="permissions.read">
                                <div class="invalidFieldForm" style="color: red;font-size: 13px;" *ngIf="validName">
                                    Debe ingresar al menos 2 caracteres
                                </div>
                            </div>
                            <!-- <div class="form-group">
                                <label for="lastname">Descripción</label>
                                <textarea class="form-control" id="description" rows="3" formControlName="description"
                                    [ngClass]="{'is-invalid invalidFieldForm': validDescription}"
                                    [ngStyle]="validDescription && {'border-color': 'red','margin-bottom': '3px'}"
                                    [readonly]="permissions.read"></textarea>
                            </div> -->
                        </div>
                        <!-- <div class="col-3">
                            <div class="form-group">
                                <label for="email">Estado</label>
                                <select *ngIf="dataRole" class="form-control" placeholder="" formControlName="active"
                                    [ngClass]="{'is-invalid invalidFieldForm': validStatus}"
                                    [ngStyle]="validStatus && {'border-color': 'red','margin-bottom': '3px'}">
                                    <option *ngFor="let item of dataNecessary.optionsSelected" [value]="item.value"
                                        [selected]="formaRole.value.active === item.value">{{item.name}}</option>
                                </select>
                            </div>
                        </div> -->
                    </div>
                </form>
            </div>
            <div class="mt-30" *ngIf="permissionsList.length && modulesList.length && dataNecessary.selectedCheck">
                <h2>Permisos</h2>
                <table id="contenido-table" class="display table table-striped" style="width:100%">
                    <thead>
                        <tr>
                            <th>Seleccionar todo</th>
                            <th *ngFor="let permission of permissionsList" style="text-align: center;">
                                <div>{{ permission.name }}</div>
                                <span class="blue-checkbox">
                                    <input type="checkbox" (click)="selectedAllItems(permission)"
                                        [checked]="isCheckedAll(permission)"
                                        [attr.disabled]="permissions.read ? true : null">
                                    <label for=""></label>
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody style="border-top: 1px solid #ddd;">
                        <tr style="padding: 15px 0px;" *ngFor="let module of modulesList">
                            <td>{{ module.name }}</td>
                            <td style="text-align: center;" *ngFor="let permission of permissionsList">
                                <span class="blue-checkbox">
                                    <input type="checkbox" (click)="selectedItem(permission, module)"
                                        [checked]="isChecked(permission, module)"
                                        [attr.disabled]="permissions.read ? true : null">
                                    <label for=""></label>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <app-paginator [items]="modulesList" [initialPage]="1" [perPage]="10" [maxPages]="5"
                    [lblFirst]="'Primera'" [lblLast]="'Última'" [lblPrev]="'Anterior'" [lblNext]="'Siguiente'"
                    [btnSec]="true" [btnPri]="true" (changePage)="changePage($event)" [align]="'center'">
                </app-paginator> -->
            <p *ngIf="modulesList.length == 0" class="alert alert-warning text-center">No se encontraron datos</p>

            <div class="botonera mtb-20">
                <button type="button" class="btn btn-dark btn-block" (click)="goBack()">Cancelar</button>
                <button type="button" (click)="saveRole()" class="btn btn-primary btn-block my-0"
                    *ngIf="permissions.create || permissions.edit">Guardar</button>
                
            </div>
        </div>
    </div>
</div>