import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseData } from 'src/app/dto/ResponseData';
import { SettingsService } from '../../settings.service';
import { ApiService } from '../../api.service';
import { constants } from '../../../constants';
import { http_build_query } from 'src/app/utils/_utils';

@Injectable({
  providedIn: 'root'
})
export class ContentsTypeDataWsService {
  
    private pathInfo: any;
    private baseUrl: string;
    private segment: any;
    private readonly methods: any = constants.screens.contentTypes;

    constructor(
        private settings: SettingsService,
        private api: ApiService
    ) {
        this.pathInfo = this.settings.get('pathInfo');
        this.baseUrl  = this.pathInfo.base.baseUrl + this.pathInfo.base.prefix;
        this.segment  = this.pathInfo.res.admin.contentsType;
    }

    private create = (body: object): Observable<ResponseData> => this.api.post(this.endpointQuotation(), body);

    private delete = (id: string): Observable<ResponseData> => this.api.delete(this.endpointQuotation(id, this.methods.delete), id);

    private endpointQuotation = (id?: string, method?: string): string => {
        switch (method) {
            case this.methods.edit:
            case this.methods.delete: {
                return `${this.baseUrl}${this.segment.prefix}${this.segment.resources.delete.replace(':contentId', id)}`;
            }
            case this.methods.getFields: {
                return `${this.baseUrl}${this.segment.prefix}${this.segment.resources.getFields.replace(':contentId', id)}`;
            }
            default: return `${this.baseUrl}${this.segment.prefix}`;
        }
    }

    private read = (filters?: any, headers?: any): Observable<ResponseData> => {
        try {
            return this.api.get(`${this.endpointQuotation()}${!!filters ? '?' + http_build_query(filters) : ''}`, headers)
        } catch (e) {
            console.error(e);
        }
    }

    private readFields = (id: string, headers?: any): Observable<ResponseData> =>
        this.api.get(`${this.endpointQuotation(id, this.methods.getFields)}`, headers)

    private readOne = (id: string): Observable<ResponseData> =>
        this.api.get(this.endpointQuotation(id))

    private update = (id: string, body: object): Observable<ResponseData> =>
        this.api.put(this.endpointQuotation(id, this.methods.edit), body)
}
