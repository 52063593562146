/* tslint:disable:only-arrow-functions no-shadowed-variable */
import { Component, OnInit, HostBinding, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/services/settings.service';
import { SessionService } from 'src/app/services/session.service';
import { MenuItem } from 'src/app/dto/MenuItem';
import { MenuDataService } from 'src/app/services/data/menu-data.service';
import { constants } from 'src/app/constants';
import { constantes } from 'src/app/screens/admin/seccion-monitor/constants/seccion-monitor';
import { getValid, getArray } from 'src/app/utils/_http';
import { getStorage, setStorage, existStorage, addMenus, slugifyPipe, getPermissionsPage } from 'src/app/utils/_utils';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { MediaService } from 'src/app/services/media.service';
import { SessionDataService } from 'src/app/services/data/session-data.service';
import { AuthenticationCredential } from '../../dto/AuthenticationCredential';
import { ResponseData } from 'src/app/dto';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
  // tslint:disable-next-line
  selector: 'abarcms-cms-side-menu',
  templateUrl: './cms-side-menu.component.html',
  styleUrls: ['./cms-side-menu.component.css']
})
export class CmsSideMenuComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  @HostBinding('class.active')
  isMenuOpen = false;
  showSignOutConfirm = false;
  menus: MenuItem[] = [];
  menus2: MenuItem[] = [];
  subMenu:MenuItem[] = [];
  imageProfileURL: string = null;
  @Output() hiddenMenu = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    public settings: SettingsService,
    public session: SessionService,
    public menuDataService: MenuDataService,
    private modalHelper: ModalHelperService,
    protected mediaService: MediaService,
    public sessionDataService: SessionDataService,
  ) {
    this.subscription.add(
      modalHelper.imageProfile$
        .subscribe(
          data => {
            this.imageProfileURL = data;
          }
        )
    )
  }
  ngOnInit() {
    this.getMediaProfile();
    this.getMenu();
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    this.hiddenMenu.emit(this.isMenuOpen);
  }
  goTo(route) {
    if (route == null || route === '') {
      return;
    }
    const routes: any[] = [];
    if (typeof (route) === 'string') {
      routes.push(route);
    }
    this.router.navigate(routes);
  }
  actionItem() {
    let permissions = getPermissionsPage('/users');
    const uuid = this.session.user.uuid;
    this.router.navigate(['users', uuid, permissions?.edit ? 'edit' : 'read']);
  }
  onSignOut() {
    this.showSignOutConfirm = true;
  }
  signOut() {
    let credential = this.session.accessToken.accessToken;
    const uuid = this.session.user.uuid
    this.sessionDataService.signOut(credential, uuid)
      .subscribe(
        rs => {
          this.logout()
        },
        err => {
          this.logout()
        }
      )
  }
  logout() {
    this.session.accessToken = null;
    this.session.user = null;
    this.router.navigate([`/${constants.screens.signIn.path}`]);
  }
  onMenuClick(menu: MenuItem, subMenu?: MenuItem) {
    let toNavigate: string[] = subMenu ? subMenu.params : menu.params;
    let action: string;
    action = menu.action ?? "";
    if (subMenu) {
      action = subMenu.action ?? null;
    }
    let route: string;
    if (menu.params) {
      route = menu.params.join();
    }
    if (!this.isMenuOpen && (this.router.url == route || route.length == 0)) {
      this.toggleMenu();
    }
    if (action) {
      switch (action) {
        case 'nav':
          this.router.navigate(toNavigate);
          if (this.isMenuOpen) {
            this.toggleMenu();
          }
          this.closeAll();
          this.resetConstants();
          break;
      }
    }
    if (subMenu) {
      subMenu.show = this.closeSubmenus(subMenu.menus);
    }
    menu.show = this.closeSubmenus(menu.menus);
  }
  closeSubmenus(submenus: any) {
    if (submenus && submenus.length > 0) {
      return submenus.show = !submenus.show;
    }
    return false;
  }
  closeAll(menu: MenuItem[] = null, isMenu2: boolean = false) {
    if (!menu) {
      menu = this.menus;
    }
    menu.forEach(
      menuItem => {
        menuItem.show = false;
      }
    );
    if (!isMenu2) {
      return this.closeAll(this.menus2, true);
    }
    return true;
  }
  isURL(v: string): boolean {
    return (v.split(' ').length === 1);
  }
  private getMenu() {
    let menuPermissions = []
    this.session.user.roles.map(x => {
      x.permission.map(y => {
        if (y?.permission.length) {
          let items = y.permission.find((z: any) => z.sysname == 'Access')
          if (items) {
            menuPermissions.push(y.module)
            

          }
        }
      })
    })
    menuPermissions.push('Secciones monitor') 
    menuPermissions.push('Avisos')
    menuPermissions.push('Empresas')
    console.log("menuPermissions")
    console.log(menuPermissions)

    if (existStorage(constants.ls.pageMenu)) {
      let menus = addMenus(getStorage(constants.ls.pageMenu,true));
      console.log("Menu: de getMenu")
      console.log(menus)
      if (menus.hasOwnProperty('menus')) {
        this.menus = menus.menus.filter(x => menuPermissions.includes(x.title))      
      }
      if (menus.hasOwnProperty('menus')) {
        this.menus2 = menus.menus2.filter(x => menuPermissions.includes(x.title))
        
      }
      return false;
    }
    this.menuDataService.read(this.session.accessToken.accessToken)
      .subscribe(
        (rs: ResponseData) => {
          if (getValid(rs, { title: 'Error al obtener el menu', modalHelper: this.modalHelper, settings: this.settings })) {
            if (getArray(rs).length) {
              console.log("ARRAY MENU: ")
              getArray(rs).push(
                {
                  "uuid": "d04aa0cc-81d1-4c9f-a1f8-af0f40a107-m8",
                  "id": 10,
                  "icon": "",
                  "title": "Secciones monitor",
                  "systemname": "seccionMonitor",
                  "menuVisible": true,
                  "params": [
                      ""
                  ],
                  "action": "",
                  "group": 1,
                  "menus": [
                      {
                          "uuid": "d04aa0cc-81d1-4c9f-a1f8-af0f40a107-m23",
                          "id": 20,
                          "icon": "",
                          "title": "Secciones",
                          "systemname": "Secciones",
                          "menuVisible": true,
                          "params": [
                              "/seccionesMonitor/consultarSecciones"
                          ],
                          "action": "nav",
                          "group": 1,
                          "menus": []
                      },
                      {
                          "uuid": "d04aa0cc-81d1-4c9f-a1f8-af0f40a107-m24",
                          "id": 21,
                          "icon": "",
                          "title": "Páginas",
                          "systemname": "paginas",
                          "menuVisible": true,
                          "params": [
                              "/seccionesMonitor/verPagina"
                          ],
                          "action": "nav",
                          "group": 1,
                          "menus": []
                      },
                      {
                          "uuid": "d04aa0cc-81d1-4c9f-a1f8-af0f40a107-m25",
                          "id": 22,
                          "icon": "",
                          "title": "Grupo de visualización",
                          "systemname": "grupoVisualizacion",
                          "menuVisible": true,
                          "params": [
                              "/seccionesMonitor/grupoVisualizacion"
                          ],
                          "action": "nav",
                          "group": 1,
                          "menus": []
                      }
                      ,
                      {
                          "uuid": "d04aa0cc-81d1-4c9f-a1f8-af0f40a107-m25",
                          "id": 22,
                          "icon": "",
                          "title": "Ordenar elementos",
                          "systemname": "ordenarElementos",
                          "menuVisible": true,
                          "params": [
                              "/seccionesMonitor/ordenarElementos"
                          ],
                          "action": "nav",
                          "group": 1,
                          "menus": []
                      }
                  ]
              }
              )
              // Se agrega Menu Avisos a la respuesta del servicio de 
              getArray(rs).push(
                {
                  "uuid": "p05aa0cg-81d1-4c9f-a1f2-af0f40a107-k3",
                  "id": 100,
                  "icon": "",
                  "title": "Avisos",
                  "systemname": "avisos",
                  "menuVisible": true,
                  "params": [
                      ""
                  ],
                  "action": "",
                  "group": 1,
                  "menus": [
                    {
                      "uuid": "03ce80a8-a54e-4883-a934-d355f5feabd1",
                      "id": 25,
                      "icon": "",
                      "title": "Creación de avisos",
                      "systemname": "crear_nuevo_aviso",
                      "menuVisible": true,
                      "params": [
                          "/avisos/alta"
                      ],
                      "action": "nav",
                      "group": 1,
                      "menus": []
                  }
                  ]
              }
              )
              // Se agrega Menu Empresas a la respuesta del servicio 
              getArray(rs).push(
                {
                  "uuid": "p12aa0cg-23d3-4c5f-a1f2-af4f34a154-k7",
                  "id": 101,
                  "icon": "",
                  "title": "Empresas",
                  "systemname": "empresas",
                  "menuVisible": true,
                  "params": [
                      ""
                  ],
                  "action": "",
                  "group": 1,
                  "menus": [
                    {
                      "uuid": "23ce35a6-a56e-2674-a690-f665f0feabd4",
                      "id": 26,
                      "icon": "",
                      "title": "Lista de empresas",
                      "systemname": "lista_empresas",
                      "menuVisible": true,
                      "params": [
                          "/empresas"
                      ],
                      "action": "nav",
                      "group": 1,
                      "menus": []
                    },
                    {
                        "uuid": "25ce93a2-a72e-3353-a357-d938f5feabd6",
                        "id": 27,
                        "icon": "",
                        "title": "Creación de empresas",
                        "systemname": "empresa_nueva",
                        "menuVisible": true,
                        "params": [
                            "/empresas/empresa_nueva"
                        ],
                        "action": "nav",
                        "group": 1,
                        "menus": []
                    }
                  ]
                }
              )
              console.log(getArray(rs))
              setStorage(constants.ls.pageMenu, getArray(rs));
              let menus = addMenus(getStorage(constants.ls.pageMenu, true));
              if (menus.hasOwnProperty('menus')) this.menus = menus.menus.filter(x => menuPermissions.includes(x.title))
              if (menus.hasOwnProperty('menus')) this.menus2 = menus.menus2.filter(x => menuPermissions.includes(x.title))
            }
          }
        },
        error => {
          console.error(error);
          getValid(error, { title: `Error`, modalHelper: this.modalHelper, settings: this.settings });
        }
      );
  }
  private getMediaProfile() {
    if (existStorage(constants.ls.sessionProfile)) {
      let { image, ...user } = getStorage(constants.ls.sessionProfile, true);
      if (!!image) {
        this.imageProfileURL = image.url;
      }
    }
  }

  getPermission(url: string): boolean {
    if (url.indexOf('/create') != -1) {
      let permission = getPermissionsPage(url)
      return permission.create
    }
    return true
  }
  
  resetConstants():void{
    constantes.groupVisualization.vista='toSearch'
    constantes.paginas.vista=''
    constantes.paginas.pageId=0
    constantes.secciones.vista='toSearch'
    constantes.secciones.atachPagesIds=[]
    constantes.secciones.detachPagesIds=[]
    constantes.secciones.sectionId=0
    constantes.elementsIds=[]
    constantes.newElementsIds=[]
    constantes.origin=''
    constantes.detallesGrupoVisualizacion.idGV=0
    constantes.detallesGrupoVisualizacion.idTGV=0
    constantes.detallesGrupoVisualizacion.nameGV=''
    constantes.inputs.inputSearchTGV=''
    constantes.groupVisualization.nameGV=''
    constantes.ids.idCatGV=0
    constantes.ids.idGV=0
    constantes.ids.idGrupo=0
    constantes.vista='toSearch'
    constantes.activeSearch=false
    constantes.title=''
    constantes.detallesGrupoVisualizacion.filters={};
    sessionStorage.removeItem('secciones')
    sessionStorage.removeItem('paginas')
    sessionStorage.removeItem('grupoVisualizacion')
    constantes.usersGroupByWorkP.groupId=0;
    constantes.usersGroupByWorkP.companyId=0
    constantes.usersGroupByWorkP.stateId=0
    constantes.usersGroupByWorkP.municipalityId=0
    constantes.usersGroupByWorkP.workPlaceId=[]
    constantes.ids.typeUserId=0
    constantes.ids.genero=''
    constantes.ids.enfermedadId=0
    constantes.ids.userId=0
  }
}
