<div class="btn-filter-group">
    {{title}}
    <button class="btn btn-default" data-toggle="collapse" href="#drop-filter-options" role="button" aria-expanded="false" aria-controls="drop-filter-options">
        <span class="fas fa-filter" arial-hidden="true"></span>
    </button>
    <div class="collapse" id="drop-filter-options" style="position: absolute;">
        <div class="card card-body filter-group-box">
            <form>
                <div class="form-group">
                    <input type="text" class="form-control input-search" placeholder="Ingrese el nombre del grupo o empresa">
                </div>
                <ul class="company-list-box">
                    <li class="main-company" *ngFor="let grupo of data; let groupIndex = index">
                        <div class="form-group form-check no-margin-bottom">
                            <input type="checkbox" class="form-check-input" id="{{ grupo.uuid }}" (click)="selectedAllItems(grupo)">
                            <label class="form-check-label no-margin-bottom" for="exampleCheck1">{{grupo.name}}</label>
                        </div>
                        <ul class="sub-company">
                            <li *ngFor="let empresa of grupo.business; let empresaIndex = index">
                                <div class="form-group form-check no-margin-bottom">
                                    <input type="checkbox" class="form-check-input empresaItem-{{grupo.system_name}}" id="empresItem-{{empresa.uuid}}" [value]="empresa.uuid">
                                    <label class="form-check-label no-margin-bottom" for="exampleCheck1">{{empresa.name}}</label>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </form>
        </div>
    </div>
</div>
