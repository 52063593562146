import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseData } from 'src/app/dto/ResponseData';

@Injectable({
    providedIn: 'root'
})
export abstract class GroupDataService {
    abstract create(body: any, accessToken: string): Observable<ResponseData>;
    abstract read(pagination: any, searchText?: string): Observable<ResponseData>;
    abstract readDropdown(accessToken?: string): Observable<ResponseData>;
    abstract readOne(uuid: any, accessToken: string): Observable<ResponseData>;
    abstract update(uuid: any, body: any, accessToken: string): Observable<ResponseData>;
    //abstract delete(uuid: string, accessToken: string): Observable<ResponseData>;
    abstract delete(body: any, accessToken: string): Observable<ResponseData>;
    abstract token(body: object, accessToken: string): Observable<ResponseData>;
}
