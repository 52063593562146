
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/services/settings.service';
import { constants } from 'src/app/constants';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PollQuestion } from 'src/app/dto/Poll';
import { Subscription } from 'rxjs';
import { PollDataService } from 'src/app/services/data/poll-data.service';
import { getArray, getHeader, getObject } from 'src/app/utils/_http';
import { NotificationsDataService } from 'src/app/services/data/notifications-data.service';
import { AlertNotificationService } from 'src/app/services';
import { clone, getPermissionsPage } from 'src/app/utils/_utils';


@Component({
  selector: 'app-questions-list',
  templateUrl: './questions-list.component.html',
  styleUrls: ['./questions-list.component.css']
})

export class QuestionsListComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription
  permissions: any = {}
  constants = constants
  question: PollQuestion[] = []
  questionPage: PollQuestion[] = []
  questionList: PollQuestion[] = []


  deleteModal = { show: false, item: null, type: '' }
  _selectedAllItems: boolean = false
  loading: any = {
    user: true
  }
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  }

  searchText: string = ''
  paginator: {
    current: number,
    perPage: number,
    total: number
  } = {
      current: 1,
      perPage: 10,
      total: 0,
    }
  dataTable: any = {
    size: 100,
    showTable: false
  }

  constructor(
    public settings: SettingsService,
    private router: Router,
    public pollService: PollDataService,
    public alertNotificationService: AlertNotificationService,
  ) { }

  ngOnInit(): void {
    this.permissions = getPermissionsPage(this.router.url)
    this.getRepositoryQuestions()
  }
  // --
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
  // --
  getRepositoryQuestions(perPage?: any, current?: any, total?: any): void {
    this.question = []
    this.questionPage = []
    this.questionList = []
    this.dataTable.showTable = false
    // --
    let paginator = clone(this.paginator)
    paginator.perPage = perPage ? perPage : 1
    paginator.current = current ? current : paginator.current
    this.subscription.add(
      this.pollService.repositoryRead(paginator)
        .subscribe(
          (rs) => {
            if (perPage) {
              getArray(rs).map((x, i) => {
                this.question.push(x)
                this.questionPage.push(x)
                if (i == getArray(rs).length - 1) {
                  if (current < total) {
                    current++
                    this.getRepositoryQuestions(this.dataTable.size, current, total)
                  }
                }
              })
              this.dataTable.showTable = true
            }
            else {
              this.paginator.total = getHeader(rs, 'X-Total-Items')
              let total = this.paginator.total / this.dataTable.size
              let page = Math.trunc(total)
              page += total - page > 0 ? 1 : 0
              this.getRepositoryQuestions(this.dataTable.size, 1, page)
            }
          }
        )
    )
  }
  // --
  actionItem(actionName: string, item: any = null) {
    if (actionName == 'delete') {
      this.deleteModal.show = true
      this.deleteModal.item = item
      this.deleteModal.type = 'eliminar'
    }
    else if (actionName == 'copy') {
      this.copyQuestion(item)
    }
    else if (item) this.router.navigate([constants.screens.questions.path, item, constants.screens.questions[actionName]])
    else this.router.navigate([constants.screens.questions.path, constants.screens.questions[actionName]])
  }
  // --
  dataFound(event) {
    console.log(event)
    this.questionPage = event
  }
  // --
  changePage(event: any) {
    this.questionList = event;
  }
  // --
  selectedAllItems() {
    this._selectedAllItems = !this._selectedAllItems
    let items = document.querySelectorAll('.userItem');
    [].map.call(items, (item, index) => {
      items[index]['checked'] = this._selectedAllItems
    })
  }
  // --
  deleteItem(data: any = null) {
    this.deleteModal.show = false;
    if (data) {
      data.item.active = 0
      data.item.validation = null
      this.pollService.repositoryUpdate(data.item.uuid, data.item)
        .subscribe(
          rs => {
            if (rs.status == 200) {
              this.paginator.current = 1
              this.getRepositoryQuestions()
              this.alertNotificationService.success('Pregunta', 'Se eliminó la pregunta con éxito', { autoClose: false, keepAfterRouteChange: true });
            }
            else {
              this.alertNotificationService.warn('Pregunta', 'Ocurrio un error eliminando la pregunta, intente mas tarde', { autoClose: false, keepAfterRouteChange: true });
            }
          },
          error => {
            this.alertNotificationService.error('Pregunta', 'Ocurrio un error, intente más tarde', { autoClose: true, keepAfterRouteChange: true });
          }
        )
    }
    else {
      this.deleteModal.item = null;
    }
  }
  // --
  loadPage(_pageNumber) {
    // this.page = _pageNumber
    // this.getQuestions()
  }
  // --
  copyQuestion(item: PollQuestion) {

    /* this.questioDataService.readOne(item, this.session.accessToken, this.page, this.itemsPerPage)
    .subscribe((rs: ResponseData) => {
      if (rs) {
        if (getValid(rs, { title: `Error al obtener la encuesta`, modalHelper: this.modalHelper, settings: this.settings })) {
            this.sectionCopy = getData(rs)
            this.modalHelper.changeLoading(false)
            this.loading = false
            this.insertSectionCopy(this.sectionCopy)
        }
      }
    }) */

  }
  // --
  insertSectionCopy(question: PollQuestion) {
    /*  question.title = question.title.concat(' COPY')
     this.questioDataService.create(question, this.session.accessToken.accessToken)
       .subscribe((rs: ResponseData) => {
         if (getValid(rs, { title: `Error al copiar la encuesta`, modalHelper: this.modalHelper, settings: this.settings })) {
           this.router.navigate([constants.screens.questions.path])
         }
       }) */


    /*
    delete poll['uuid']
    if(poll.sections){
        let sections: Array<Section> = poll.sections
        for (let index = 0 index < sections.length index++) {
            delete sections[index]['uuid']
        }
    }
    */
  }
}
