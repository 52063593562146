import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { constants } from "src/app/constants";
import {
  AlertNotificationService,
  SessionService,
  SettingsService,
} from "src/app/services";
import { ModalHelperService } from "src/app/services/modal-helper.service";
import { labels } from "src/app/strings";
import { getValid } from "src/app/utils/_http";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DatePipe } from "@angular/common";
import { getPermissionsPage } from "src/app/utils/_utils";
import { ConfirmDeleteComponent } from "src/app/views/confirm-delete/confirm-delete.component";
import { CompanyService } from "src/app/screens/admin/seccion-monitor/services/company.service";

@Component({
  selector: "app-company-profile",
  templateUrl: "./company-profile.component.html",
  styleUrls: ["./company-profile.component.css"],
  providers: [DatePipe],
})
export class CompanyProfileComponent implements OnInit {
  idGrupo = null;
  loading: boolean = false;
  constructor(
    private modalSrv: NgbModal,
    private companySrv: CompanyService,
    protected session: SessionService,
    private modalHelper: ModalHelperService,
    public settings: SettingsService,
    private router: Router,
    protected alertNotificationService: AlertNotificationService
  ) {}

  ngOnInit(): void {
    this.permissions = getPermissionsPage(this.router.url);
    const urlParams = this.router.url.split("/");
    this.idGrupo = urlParams[2] ?? null;
    this.getTableData(this.idGrupo);
  }
  init: boolean = true;
  companys: Array<any> = [];
  permissions: any;
  constants = constants;
  searchText: string = "";
  paginator: {
    current: number;
    perPage: number;
    total: number;
  } = {
    current: 1,
    perPage: 10,
    total: 0,
  };

  createEmpresa() {
    this.router.navigate([
      constants.screens.empresas.path,
      0,
      this.idGrupo,
      constants.screens.empresas.empresa_nueva,
    ]);
  }
  editEmpresa(item) {
    this.router.navigate([
      constants.screens.empresas.path,
      item.companyId,
      this.idGrupo,
      constants.screens.empresas.editByGroup,
    ]);
  }
  activarInactivarEmpresa(item) {
    let deleteModal = this.modalSrv.open(ConfirmDeleteComponent);
    deleteModal.componentInstance.title = "Cambiar estatus de la empresa";
    deleteModal.componentInstance.message = item.status
      ? labels.empresas.delete.confirmDeleteMessage
      : labels.empresas.active.confirmActiveMessage;
    deleteModal.result
      .then(async (_response) => {
        if (_response === "CONFIRMED") {
          this.loading = true;
          (
            await this.companySrv.activarInactivarEmpresa(
              item.companyId,
              !item.status
            )
          ).subscribe(
            (resp) => {
              this.loading = false;
              this.alertNotificationService.success(
                "Aviso",
                "El registro se modifico exitosamente.",
                { autoClose: true, keepAfterRouteChange: true }
              );
              this.getTableData(this.idGrupo);
            },
            (error) => {}
          );
        }
      })
      .catch((res) => {});
  }

  async getTableData(idGrupo): Promise<void> {
    let body = {
      pageSize: this.paginator.perPage,
      page: this.paginator.current,
      sortDirection: "asc",
      sort: "c.name",
      filters: {
        name: this.searchText,
        groupIds: [idGrupo],
      },
    };
    (await this.companySrv.allEmpresas(body)).subscribe((_rs) => {
      if (
        getValid(_rs, {
          title: `Error al obtener los grupos`,
          modalHelper: this.modalHelper,
          settings: this.settings,
        })
      ) {
        this.paginator.total = _rs.body.data.pagination.total_rows;
        this.companys = _rs.body.data.companys;
        this.modalHelper.changeLoading(false);
      }
    });
  }

  onSearchChange(event: any) {
    this.paginator.current = 1;
    this.getTableData(this.idGrupo);
  }

  changePage($event: any) {
    this.paginator = $event;
    if (this.init) {
      this.init = false;
    } else {
      this.getTableData(this.idGrupo);
    }
  }
}
