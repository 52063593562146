<div class="container-fluid">
    <div class="card border-card">
        <div class="card-content size" >

            <h6>Crear Pagina</h6>
            <div class="card">
                <div class="card-body">
                    <div class="form-group ">
                        <label for="rolName">*Nombre Sección:</label>
                        <input class="form-control" type="text" formControlName="nombre" placeholder="Nombre">
                    </div>      
                </div>
            </div>
            <div class="card size">
                <div class="card-body">        
                    <div class="form-group ">
                        <label for="rolName">*Descripción:</label>
                        <input class="form-control" type="textarea" formControlName="descripcion" placeholder="Descripción">
                    </div>
                </div>
            </div>
            <div class="card size">
                <div class="card-body">
                    <div class="form-group ">
                        <label for="rolName">*Icono:</label>
                            <input class="form-control" type="text" formControlName="nombre" placeholder="icono">
                    </div>   
                </div>
            </div>
            <div class="card size">
                <div class="card-body">
                    <div class="form-group ">
                        <label for="rolName">*Color:</label>
                        <input class="form-control" type="text" formControlName="nombre" placeholder="Nombre">
                    </div>   
                </div>
            </div>
            <div class="card size">
                <div class="card-body">
                    <div class="form-group ">
                        <label for="rolName">*Estatus:</label><br>
                            <input type="radio" formControlName="nombre"><label for="">Activo</label>
                            <input type="radio" formControlName="nombre"><label for="">Inactivo</label>
                    </div> 
                </div>
            </div>
            <app-boton [textContent]="'Guardar'"></app-boton>

        </div>
    </div>
</div>
