import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'abarcms-loader-modal',
  templateUrl: './loader-modal.component.html',
  styleUrls: ['./loader-modal.component.css']
})
export class LoaderModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
