import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ResponseData } from 'src/app/dto/ResponseData';
import { SettingsService } from '../../settings.service';
import { createResponseData, makeHeaders } from 'src/app/utils/_http';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../api.service';

@Injectable({
    providedIn: 'root'
})
export class BusinessDataWsService {
    private pathInfo: any;
    private baseUrl: string;
    private segment: any;
    constructor(
        private settings: SettingsService,
        private api: ApiService
    ) {
        this.pathInfo = this.settings.get('pathInfo');
        this.baseUrl = this.pathInfo.base.baseUrl + this.pathInfo.base.prefix;
        this.segment = this.pathInfo.res.admin['business'];
    }
    private create(body: object, accessToken: string): Observable<ResponseData> {
        let endpoint = this.baseUrl + this.segment.prefix;
        return this.api.post(endpoint, body, true, true);
    }
    private read(accessToken: string): Observable<ResponseData> {
        let endpoint = this.baseUrl + this.segment.prefix;
        return this.api.get(endpoint);
    }
    private readOne(uuid: any, accessToken: string): Observable<ResponseData> {
        let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.getOne.replace(':uuid', uuid);
        return this.api.get(endpoint);
    }
    private update(uuid: any, body: object, accessToken: string): Observable<ResponseData> {
        let endpoint = this.baseUrl + this.segment.prefix;
        return this.api.put(endpoint, body, true, true);
    }
    /* private delete(uuid: any, accessToken: string): Observable<ResponseData> {
        let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.delete.replace(':uuid', uuid);
        return this.api.delete(endpoint, uuid, true, true);
    }*/
    private delete(body: object, accessToken: string): Observable<ResponseData> {
        let endpoint = this.baseUrl + this.segment.prefix;
        return this.api.patch(endpoint, body, true, true);
    }

    private updateToken(body: object, accessToken: string): Observable<ResponseData> {
        let endpoint = this.baseUrl +'/business/token';
        return this.api.post(endpoint, body, true, true);
    }
    private gerearUpdateToken(body: object, accessToken: string): Observable<ResponseData> {
        let endpoint = this.baseUrl +'/business/token';
        return this.api.post(endpoint, body, true, true);
    }
}
