import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseData } from 'src/app/dto/ResponseData';
import { SettingsService } from '../../settings.service';
import { ApiService } from '../../api.service';

@Injectable({
  providedIn: 'root'
})
export class RoleDataWsService {
  private pathInfo: any;
  private baseUrl: string;
  private segment: any;
  constructor(
    private settings: SettingsService,
    private api: ApiService
  ) {
    this.pathInfo = this.settings.get('pathInfo');
    this.baseUrl = this.pathInfo.base.baseUrl + this.pathInfo.base.prefix;
    this.segment = this.pathInfo.res.admin['role'];
  }
  private create(body: object, accessToken: string): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix;
    return this.api.post(endpoint, body, true, true);
  }
  private read(accessToken: string): Observable<ResponseData> {
    let options = {
      'X-Auth-Token': accessToken,
      'X-Current-Page': 1,
      'X-Items-Page': 20
  };
    let endpoint = this.baseUrl + this.segment.prefix;
    return this.api.get(endpoint, options);
  }
  private readOne(id: any, accessToken: string): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.getOne.replace(':id', id);
    return this.api.get(endpoint);
  }
  private update(id: any, body: object, accessToken: string): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.edit.replace(':id', id);
    return this.api.put(endpoint, body, true, true);
  }
  private delete(id: any, accessToken: string): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.delete.replace(':id', id);
    return this.api.delete(endpoint, id, true, true);
  }
  private dataValidate(id: any, accessToken: string): Observable<ResponseData> {
    let endpoint = this.baseUrl + "/checkRoleUsers" +  this.segment.resources.delete.replace(':id', id);
    return this.api.get(endpoint);
  }
  private validateName(body: object, accessToken: string): Observable<ResponseData>{
    let endpoint = this.baseUrl + "/validateName";
    return this.api.post(endpoint, body);
  }
  private readPaginator(accessToken: string, page: number, itemsPerPage: number): Observable<ResponseData> {
    let options = {
      'X-Auth-Token': accessToken,
      'X-Current-Page': page,
      'X-Items-Page': itemsPerPage
  };
    let endpoint = this.baseUrl + this.segment.prefix;
    return this.api.get(endpoint, options);
  }
}
