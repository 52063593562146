import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { GifloadComponent } from "../../gifload/gifload.component";
import { AlertNotificationService, SettingsService } from "src/app/services";
import { NotificationsDataService } from "src/app/services/data/notifications-data.service";
import { Subscription } from "rxjs";
import {
  Aviso,
  AvisoDysplayGroupResponse,
  AvisoPageResponse,
  AvisoUsersResponse,
} from "src/app/dto/Aviso";
import { DatePipe } from "@angular/common";
import { ModalHelperService } from "src/app/services/modal-helper.service";
import { AvisoService } from "../../seccion-monitor/services/avisos.service";
import { constants } from "src/app/constants";
import { labels } from "src/app/strings";

@Component({
  selector: "app-push-avisos-action",
  templateUrl: "./push-avisos-action.component.html",
  styleUrls: ["./push-avisos-action.component.css"],
})
export class PushAvisosActionComponent implements OnInit {
  subscription: Subscription = new Subscription();
  permissions: any = {};
  pageAction: {
    action: any;
    uuid: any;
    titlePage: any;
  } = {
    action: null,
    uuid: null,
    titlePage: null,
  };

  form: FormGroup;
  formGroup: FormGroup;
  formSchedule: FormGroup;

  groups: Array<any>;
  groupSelected: any | null = null;

  pages: Array<any>;
  pageSelected: any | null = null;

  showComponent: boolean = true;
  showListUsuarios: boolean = false;
  banderaIsNoFiltro: boolean = false;

  empresas: Array<any>;
  enfermedades: Array<any>;
  tipos: Array<any>;
  plantillas: Array<any>;
  usuarios: Array<any>;

  empresaSelected: any | null = null;
  enfermedadSelected: any | null = null;
  tipoSelected: any | null = null;
  plantillaSelected: any | null = null;
  edad: any | null = null;
  cp: any | null = null;
  usuariosSelected: Array<any> = [];

  initOptionText: any = {
    height: 400,
    menubar: true,
    toolbar_mode: "sliding",
    plugins: [
      "advlist autolink lists link image charmap print preview anchor",
      "searchreplace visualblocks code fullscreen",
      "insertdatetime media table paste code help wordcount",
    ],
    toolbar:
      "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | removeformat",
    mergetags_list: [
      { value: "First.Name", title: "First Name" },
      { value: "Email", title: "Email" },
    ],
    language: "es_MX",
  };
  //    initOptionText: any = { height: 400, menubar: true, toolbar_mode: 'sliding',plugins: [ 'advlist autolink lists link image charmap print preview anchor', 'searchreplace visualblocks code fullscreen', 'insertdatetime media table paste code help wordcount'], toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | removeformat',mergetags_list: [ { value: 'First.Name', title: 'First Name' }, { value: 'Email', title: 'Email' }, ], language: 'es_MX' }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public notificationService: NotificationsDataService,
    public alertNotificationService: AlertNotificationService,
    public settings: SettingsService,
    public datePipe: DatePipe,
    public modalHelper: ModalHelperService,
    public avisoService: AvisoService,
    private ngModal: NgbModal
  ) {}

  ngOnInit(): void {
    this.setForm();
    this.groupSelected = null;
    this.pageSelected = null;
    this.pageAction = {
      action:
        constants.screens.avisos[this.router.url.split("/").pop()] ?? null,
      uuid: this.router.url.split("/")[2] ?? null,
      titlePage:
        constants.screens.avisos[this.router.url.split("/").pop()] ==
        constants.screens.avisos.edit
          ? labels.avisos.update.titlePage
          : labels.avisos.create.titlePage,
    };
    this.cargarFiltrosUsuarios();
  }

  setForm(): void {
    this.form = this.formBuilder.group(new Aviso({}));
  }

  indexSelectedField: number = -1;

  get f() {
    return this.form.controls;
  }
  get fg() {
    return this.formGroup.controls;
  }
  get fs() {
    return this.formSchedule.controls;
  }

  limpiaTipoAccion() {
    this.form.patchValue({
      message: "",
      messagePush: "",
      isMenssagePush: false,
    });
    /*this.form.value.mensaje = "";
        this.form.value.mensajePush = "";
        this.form.value.isMenssagePush = false*/
  }
  async limpiaTipoEnvio() {
    this.groupSelected = null;
    this.form.value.groupSelected = null;
    this.showListUsuarios = false;
    if (this.form.value.tipoEnvio == 1) {
      //Por Usuario
      this.cargarFiltrosUsuarios();
    } else if (this.form.value.tipoEnvio == 2) {
      //Por Página
      let body = {
        pageSize: 5000,
        page: 1,
        sortDirection: "asc",
        sort: "p.name",
        filters: {
          name: "",
          withAssignedSections: true,
          withTrashed: null,
        },
      };
      (await this.avisoService.allPaginas(body)).subscribe(
        (result) => {
          this.pages = result.body.data.pages;
        },
        (error) => {
          console.log(error);
        }
      );
    } else if (this.form.value.tipoEnvio == 3) {
      //Por Grupo
      let body = {
        pageSize: 5000,
        page: 1,
        sortDirection: "asc",
        sort: "dg.name",
        filters: {
          name: "",
          displayGroupTypeId: [],
        },
      };
      (await this.avisoService.allGrupoVisualizacion(body)).subscribe(
        (result) => {
          this.groups = result.body.data.displayGroup;
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
  async cargarFiltrosUsuarios() {
    (await this.avisoService.allCompany()).subscribe(
      (result) => {
        this.empresas = result.body.companies;
      },
      (error) => {
        console.log(error);
      }
    );
    (await this.avisoService.allDiseases()).subscribe(
      (result) => {
        this.enfermedades = result.body.diseases;
      },
      (error) => {
        console.log(error);
      }
    );
    (await this.avisoService.allTypeUsers()).subscribe(
      (result) => {
        this.tipos = result.body.data;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  limpiarFiltro() {
    this.form = this.formBuilder.group(new Aviso(this.form.value, true));
    /*this.form.value.empresaSelected = null
        this.form.value.enfermedadSelected =  null
        this.form.value.tipoSelected = null
        this.form.value.plantillaSelected = null
        this.form.value.edad = null
        this.form.value.cp = null*/
    this.empresaSelected = null;
    this.enfermedadSelected = null;
    this.usuariosSelected = null;
    this.tipoSelected = null;
    this.showListUsuarios = false;
  }
  async buscarUsuarios() {
    let filtros: any = {};
    filtros.sex = ["m", "f"];
    this.banderaIsNoFiltro = false;
    let banderaIsNoFiltro = true;
    if (this.empresaSelected) {
      filtros.idCompanys = [this.empresaSelected.company_id];
      banderaIsNoFiltro = false;
    }
    if (this.form.value.edad) {
      filtros.age = { condition: "=", value: this.form.value.edad };
      banderaIsNoFiltro = false;
    }
    if (this.enfermedadSelected) {
      filtros.diseases = [this.enfermedadSelected.id];
      banderaIsNoFiltro = false;
    }
    if (this.form.value.cp) {
      filtros.postalCode = this.form.value.cp;
      banderaIsNoFiltro = false;
    }
    if (this.tipoSelected) {
      filtros.type = [this.tipoSelected.id];
      banderaIsNoFiltro = false;
    }
    if (banderaIsNoFiltro) {
      this.banderaIsNoFiltro = banderaIsNoFiltro;
      return;
    }

    let filter = {
      pageSize: 15000,
      page: 1,
      sortDirection: "asc",
      sort: "us.id",
      filters: filtros,
    };

    (await this.avisoService.allUsuarios(filter)).subscribe(
      (result) => {
        this.usuarios = result.body.data.usuarios;
        this.selectAllForDropdownItems(this.usuarios);
        this.showListUsuarios = true;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items) => {
      items.forEach((element) => {
        element["selectedAllGroup"] = "selectedAllGroup";
      });
    };

    allSelect(items);
  }
  onMaterialGroupChange() {}
  async guardar() {
    let avisoResponse: any = {};

    avisoResponse.type = Number(this.form.value.tipoAccion);
    avisoResponse.title = this.form.value.title;
    avisoResponse.imageUrl = this.form.value.imageUrl;
    avisoResponse.status = true;
    if (this.form.value.isMenssagePush) {
      avisoResponse.message = btoa(this.form.value.message);
      avisoResponse.messagePush = this.form.value.messagePush;
    } else {
      avisoResponse.message = this.form.value.messagePush;
    }
    if (this.form.value.tipoEnvio == 1) {
      //Por Usuario
      avisoResponse.userIds = this.form.value.usuariosSelected;
    } else if (this.form.value.tipoEnvio == 2) {
      //Por pantalla
      avisoResponse.pageId = this.form.value.pageSelected.id;
    } else if (this.form.value.tipoEnvio == 3) {
      //Por Grupo
      avisoResponse.displayGroupId = this.form.value.groupSelected.id;
    }
    this.ngModal.open(GifloadComponent);
    (await this.avisoService.saveAviso(avisoResponse)).subscribe(
      (result) => {
        this.alertNotificationService.success(
          "Aviso",
          result.body.status.message,
          { autoClose: true, keepAfterRouteChange: true }
        );
        this.form = this.formBuilder.group(new Aviso({}));
        this.showListUsuarios = false;
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
