import { Injectable } from '@angular/core';
import { ResponseData } from 'src/app/dto/ResponseData';
import { SettingsService } from '../../settings.service';
import { ApiService } from '../../api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContentTypeDataWsService {
  private pathInfo: any;
  private baseUrl: string;
  private segment: any;
  constructor(
    private settings: SettingsService,
    private api: ApiService
  ) {
    this.pathInfo = this.settings.get('pathInfo');
    this.baseUrl = this.pathInfo.base.baseUrl + this.pathInfo.base.prefix;
    this.segment = this.pathInfo.res.admin['contentType'];
  }
  private create(body: object): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix;
    return this.api.post(endpoint, body);
  }
  private read(): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix;
    return this.api.get(endpoint);
  }
  private readOne(id: any): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.readOne.replace(':id', id);
    return this.api.get(endpoint);
  }
  private update(id: any, body: object): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.update.replace(':id', id);
    return this.api.put(endpoint, body);
  }
  private delete(id: any): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.delete.replace(':id', id);
    return this.api.delete(endpoint, id);
  }
}
