export * from 'src/app/services/data/ws/index';
export * from 'src/app/services/data/statement-data.service';
export * from 'src/app/services/data/faq-data.service';
export * from 'src/app/services/data/media-guga-data.servcie';
export * from 'src/app/services/data/content-type-data.service';
export * from 'src/app/services/data/content-type-field-data.service';
export * from 'src/app/services/data/field-data.service';
export * from 'src/app/services/data/contents-data.service';
export * from 'src/app/services/data/contentsType-data.service';
export * from 'src/app/services/data/formularios-data.service';
export * from 'src/app/services/data/formularios-field-data.service';
export * from 'src/app/services/data/citas-data.service';
