import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { constants } from 'src/app/constants';
import { camelCase, clone, existStorage, getPermissionsPage, getStorage, permissionPageUser, setStorage, slugifyPipe } from 'src/app/utils/_utils';
import { Poll, PollGroup, PollQuestion, PollSchedule, PollSection, PollTypeQuestionOption } from 'src/app/dto/Poll';
import { DatePipe } from '@angular/common';
import { PollDataService } from 'src/app/services/data/poll-data.service';
import { getArray, getObject } from 'src/app/utils/_http';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { AlertNotificationService, SessionService } from 'src/app/services';
import { ResponseData } from 'src/app/dto';
import { GroupDataService } from 'src/app/services/data/group-data.service';
import { SessionDataService } from 'src/app/services/data/session-data.service';
import { EmailDataService } from 'src/app/services/data/email-data.servcie';

@Component({
  selector: 'app-polls-action',
  templateUrl: './polls-action.component.html',
  styleUrls: ['./polls-action.component.css']
})
export class PollsActionComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription
  permissions: any = {}
  pageAction: {
    action: any,
    uuid: any,
    titlePage: any,
  } = {
      action: null,
      uuid: null,
      titlePage: null,
    }

  pollData = {
    showView: false,
    typePublication: 'manual',
    minStartdate: null,
    minEnddate: null,
  }
  isFormPublicate: boolean = false
  isFormDesactivated: boolean = false
  formOriginal: any = {}
  form: FormGroup
  formShedule: FormGroup
  formGroup: FormGroup
  formSection: FormGroup
  pollTypeQuestions: PollTypeQuestionOption[]
  groups: PollGroup[] = []

  nestedQuestions: any = {}
  questionsToDelete: any = []

  validateData = {
    invalidForm: 0,
    sectionFormCounter: 0,
    sectionFormCounterReponse: 0,
    sectionFormError: [],
  }

  monitor: any = {
    sectionCount: <number>0,
    questionCount: <number>0
  }
  groupsShow: boolean = false
  showButtonSave: boolean = false

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private datePipe: DatePipe,
    private pollService: PollDataService,
    private groupService: GroupDataService,
    private modalHelper: ModalHelperService,
    private alertNotificationService: AlertNotificationService,
    private session: SessionService,
    private emailService: EmailDataService,
  ) { }

  ngOnInit(): void {
    this.pageAction = {
      action: constants.screens.polls[this.router.url.split('/').pop()] ?? null,
      uuid: this.router.url.split('/')[2] ?? null,
      titlePage: this.router.url.split('/').pop() == 'create' ? 'Crear encuesta' : 'Editar encuesta'
    }
    this.permissions = getPermissionsPage(this.router.url)
    this.showButtonSave = permissionPageUser(this.permissions, this.pageAction.action, this.router, '/polls')
    this.getTypeQuestion()
    this.getGroupsAndBusiness()
    this.loginMonitor()
    this.setForm()
    if (this.pageAction.action == 'create') {
      this.form.setValue(new Poll({}))
      this.pollData.showView = true
    }
    else {
      this.subscription.add(
        this.pollService.readOne(this.pageAction.uuid)
          .subscribe(
            rs => {
              let poll: Poll = new Poll(getObject(rs))
              poll.email = !!poll.email ? poll.email : this.session.user.email
              poll.status = slugifyPipe(poll.status, '_')
              poll.sections.map((xSection, iSection) => {
                let questions = []
                this.flatPoll(xSection.questions, questions)
                xSection.questions = questions
              })
              setTimeout(() => {
                this.form.setValue(poll)
                this.formGroup.setValue({ groups: poll.groups })
                this.formSection.setValue({ sections: poll.sections })
                if (poll.schedule['enddate'] && poll.schedule['startdate']) {
                  this.pollData.typePublication = 'automa'
                  this.formShedule.setValue(poll.schedule)
                }
                // --
                this.formOriginal = clone(poll)
                this.formOriginal.sections.map(x => {
                  x.questions.map(y => {
                    if (y.validation) {
                      if (!y.validation.uuidoption && !y.validation.value) {
                        y.validation = null
                      }
                    }
                  })
                })
                if (this.formOriginal.status == 'activo' || this.formOriginal.status == 'publicado') {
                  this.isFormPublicate = true
                }
                if (this.formOriginal.status == 'desactivado') {
                  this.isFormDesactivated = true
                }
                this.pollData.showView = true
              }, 500)
            },
            error => {
              this.alertNotificationService.error('Encuesta', 'Ocurrio un error, intente más tarde', { autoClose: true, keepAfterRouteChange: true });
              this.router.navigateByUrl('/polls')
            }
          )
      )
    }
    this.subscription.add(
      this.modalHelper.poll$
        .subscribe(
          value => {
            if (value?.nestedQuestions) {
              if (!this.nestedQuestions[value.section]) {
                this.nestedQuestions[value.section] = {}
              }
              if (!this.nestedQuestions[value.section][value.question]) {
                this.nestedQuestions[value.section][value.question] = {}
              }
              this.nestedQuestions[value.section][value.question] = {
                question: value.question,
                section: value.section,
                nested: value.nestedQuestions,
              }
              this.questionsToDelete = []
              Object.values(this.nestedQuestions).map(xSection => {
                Object.values(xSection).map(xQuestion => {
                  xQuestion.nested.map(xNested => {
                    this.questionsToDelete.push(xNested)
                  })
                })
              })
            }
            if (value?.module) {
              if (value.module == 'validFormResponse') {
                if (value.invalid) {
                  this.validateData.invalidForm++
                }
                if (value.errors) {
                  value.errors.map(x => {
                    this.validateData.sectionFormError.push(x)
                  })
                }
                this.validateData.sectionFormCounterReponse++
                if (this.validateData.sectionFormCounter == this.validateData.sectionFormCounterReponse) {
                  setTimeout(() => {
                    if (this.validateData.invalidForm || this.validateData.sectionFormError.length) {
                      console.log('Errores de validacion de encuesta', this.validateData.invalidForm)
                      console.log('Errores encontrados en las secciones', this.validateData.sectionFormError)
                    }
                    if (!this.validateData.invalidForm && this.validateData.sectionFormError.length == 0) {
                      this.savePoll()
                    }
                  })
                }
              }
            }
            if (value?.action) {
              if (value?.action == 'add-poll-monitor') {
                if (value?.sectionCount) {
                  this.monitor.sectionCount--
                  if (this.monitor.sectionCount == 0) {
                    this.setPollForMonitor(value.form, value.args)
                  }
                }
                if (value?.questionCount) {
                  this.monitor.questionCount--
                  if (this.monitor.questionCount == 0) {
                    this.setPollForMonitor(value.form, value.args)
                  }
                }
              }
            }
          }
        )
    )
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
  // --
  getTypeQuestion() {
    this.subscription.add(
      this.pollService.typeQuestion()
        .subscribe(
          rs => {
            this.pollTypeQuestions = getArray(rs)
          }
        )
    )
  }
  // --
  getGroupsAndBusiness() {
    this.subscription.add(
      this.groupService.readDropdown()
        .subscribe(
          rs => {
            this.groups = getArray(rs)
            this.groupsShow = true
          },
          error => {
            this.groupsShow = true
          }
        )
    )
  }
  // --
  loginMonitor() {
    if (existStorage(constants.ls.sessionMonitor)) {
      this.monitor = getStorage(constants.ls.sessionMonitor, true)
    }
    else {
      this.subscription.add(
        this.pollService.monitorLogin()
          .subscribe(
            rs => {
              this.monitor = getObject(rs)
              if (this.monitor) {
                setStorage(constants.ls.sessionMonitor, this.monitor)
              }
            }
          )
      )
    }
  }
  // --
  setForm(): void {
    this.form = this.formBuilder.group({
      uuid: ['', Validators.required],
      active: [1, Validators.required],
      title: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(150)]],
      systemname: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(250)]],
      description: ['', Validators.required],
      status: ['', Validators.required],
      schedule: [{}, Validators.required],
      groups: [[], Validators.required],
      sections: [[], Validators.required],
      order: [0],
      email: [this.session.user.email, Validators.required],
    })
    this.formGroup = this.formBuilder.group({
      groups: [[], Validators.required],
    })
    this.formSection = this.formBuilder.group({
      sections: [[], Validators.required],
    })
    this.formShedule = this.formBuilder.group({
      startdate: [''],
      enddate: [''],
    })
  }
  // --
  get f() {
    return this.form.controls
  }
  get fg() {
    return this.formGroup.controls
  }
  get fsc() {
    return this.formSection.controls
  }
  get fs() {
    return this.formShedule.controls
  }
  // -- 
  minDate(type: string): void | any {
    switch (type) {
      case 'startdate':
        if (!this.pollData.minStartdate) {
          this.pollData.minStartdate = new Date()
        }
        return this.pollData.minStartdate
      case 'enddate':
        return this.formShedule.value.startdate
      case 'change':
        this.formShedule.patchValue({ enddate: '' })
        break;
      case 'finish':
        if (this.formShedule.value && this.formShedule.value.enddate) {
          this.form.patchValue({
            shedule: {
              startdate: this.datePipe.transform(this.formShedule.value.startdate, 'yyyy-MM-ddTHH:mm:ss'),
              enddate: this.datePipe.transform(this.formShedule.value.enddate, 'yyyy-MM-ddTHH:mm:ss')
            }
          })
        }
        break;
      case 'reset':
        this.formShedule.reset()
        this.form.patchValue({ shedule: new PollSchedule({}) })
        break;
    }
  }
  // --
  onChangeForm(form: FormGroup, field?: string, value?: any) {
    switch (field) {
      case 'systemname':
        if (this.pageAction.action == 'create') {
          form.patchValue({ [field]: slugifyPipe(form.value['title'], '_') })
        }
        break;
    }
  }
  // --
  onAction(event: any) {
    if (event?.module) {
      switch (event.module) {
        case 'sections':
          switch (event.action) {
            case 'add':
              this.formSection.patchValue({ [event.module]: [...event.data, new PollSection({})] })
              break;
            case 'delete':
              // if (this.pageAction.action == 'create') {
              // this.fsc[event.module].value.splice(event.data, 1)
              // }
              // else {
              this.formSection.patchValue({ [event.module]: [...event.data] })
              // }
              break;
            case 'save':
              this.form.patchValue({ [event.module]: [...event.data] })
              break;
          }
          break;
        case 'groups':
          switch (event.action) {
            case 'business':
            case 'delete':
              this.formGroup.patchValue({ [event.module]: [...event.data] })
              this.form.patchValue({ [event.module]: [...event.data] })
              break;
          }
          break;
      }
    }
    else {
      switch (event) {
        case 'title':
          if (this.pageAction.action == 'create') {
            setTimeout(() => {
              this.form.patchValue({ systemname: `${slugifyPipe(this.form.value.title, '_')}_${this.form.value.uuid}` })
            })
          }
          break;
        case 'status':
          if (this.form.value.status == 'desactivado') {
            this.form.patchValue({ 'active': 0 })
          }
          else {
            this.form.patchValue({ 'active': 1 })
          }
          break;
        case 'save':
          if (this.pollData?.typePublication == 'automa') {
            this.formShedule.controls.startdate.setValidators(Validators.required)
            this.formShedule.controls.enddate.setValidators(Validators.required)
            this.formShedule.controls.startdate.updateValueAndValidity()
            this.formShedule.controls.enddate.updateValueAndValidity()
          }

          if (this.form.invalid || this.formShedule.invalid) {
            Object.values(this.form.controls).concat(Object.values(this.formShedule.controls)).forEach(control => {
              control.markAsTouched();
            });
          }
          // --
          this.validateData.invalidForm = 0
          this.validateData.sectionFormCounter = 2
          this.validateData.sectionFormCounterReponse = 0
          this.validateData.sectionFormError = []

          this.form.value.sections.map((xSection: PollSection, iSection) => {
            this.validateData.sectionFormCounter++
            xSection.polluuid = this.form.value.uuid
            xSection.questions.map((xQuestion: PollQuestion, iQuestion) => {
              if (!this.questionsToDelete.includes(xQuestion.uuid)) {
                xQuestion.parentuuid = xSection.uuid
              }
            })
          })

          this.modalHelper.pollChange({
            module: 'validForm',
          })
          break;
      }
    }
  }
  // --
  flatPoll(poll: any[], questions) {
    poll.map((xQuestion, iQuestion) => {
      questions.push(xQuestion)
      if (xQuestion.choices.length) {
        xQuestion.choices.map(xChoice => {
          this.flatPoll(xChoice.nested, questions)
        })
      }
    })
  }
  // --
  formClone: Poll
  savePoll() {
    if (!this.validateData.invalidForm) {
      this.form.patchValue({ schedule: this.formShedule.value })
      this.formClone = clone(this.form.value)
      this.formClone.order = 0;
      this.formClone.sections.map((xSection, iSection) => {
        xSection.order = iSection
        let questions: PollQuestion[] = []
        xSection.questions.map((xQuestion, iQuestion) => {
          xQuestion.order = iQuestion
          if (!this.questionsToDelete.includes(xQuestion.uuid)) {
            xQuestion.parentuuid = xSection.uuid
            questions.push(xQuestion)
          }
          if (iQuestion == xSection.questions.length - 1) {
            xSection.questions = questions
          }
        })
        if (iSection == this.formClone.sections.length - 1) {
          this.setCounterMonitor(this.formClone)
          this.setPollForMonitor(this.formClone, {
            original: this.formOriginal,
            current: this.form.value,
            data: null,
            system: 'cms',
            module: 'poll'
          })
        }
      })

    }
  }
  // --
  callToApi(form: any, args: any) {
    switch (this.pageAction.action) {
      case 'create':
        switch (args.system) {
          case 'monitor':
            switch (args.module) {
              case 'poll':
                this.subscription.add(
                  this.pollService.monitorAddPoll(args.data, this.monitor)
                    .subscribe(
                      (rs: ResponseData) => {
                        let data = getObject(rs)
                        if (data) {
                          this.monitor.poll = data.token
                          this.setPollForMonitor(form, { ...args, module: 'business' })
                        }
                      },
                      error => {
                        console.error(error)
                        this.alertNotificationService.error('Encuesta', 'Ocurrio un error al agregar la encuesta en Monitor', { autoClose: true, keepAfterRouteChange: true });
                        this.pageAction.action = 'edit'
                        this.formClone.status = 'pendiente'
                        this.callToApi({ ...form, status: 'pendiente' }, { ...args, system: 'cms', finish: true })
                      }
                    )
                )
                break;
              case 'business':
                this.subscription.add(
                  this.pollService.monitorAddBusiness(args.data, this.monitor)
                    .subscribe(
                      (rs: ResponseData) => {
                        let data = getObject(rs)
                        if (data) {
                          this.setPollForMonitor(form, { ...args, module: 'section' })
                        }
                      },
                      error => {
                        console.error(error)
                        this.alertNotificationService.error('Encuesta', 'Ocurrio un error al agregar la encuesta en Monitor', { autoClose: true, keepAfterRouteChange: true });
                        this.pageAction.action = 'edit'
                        this.formClone.status = 'pendiente'
                        this.callToApi({ ...form, status: 'pendiente' }, { ...args, system: 'cms', finish: true })
                      }
                    )
                )
                break;
              case 'section':
                this.subscription.add(
                  this.pollService.monitorAddSection(args.data, this.monitor)
                    .subscribe(
                      (rs: ResponseData) => {
                        let data = getObject(rs)
                        if (data) {
                          if (!this.monitor?.section) {
                            this.monitor.section = []
                          }
                          this.monitor.section[args.index] = data.token_section
                          this.modalHelper.pollChange({
                            action: 'add-poll-monitor',
                            sectionCount: true,
                            form: form,
                            args: { ...args, module: 'question' }
                          })
                        }
                      },
                      error => {
                        console.error(error)
                        this.alertNotificationService.error('Encuesta', 'Ocurrio un error al crear la encuesta en Monitor', { autoClose: true, keepAfterRouteChange: true });
                        this.pageAction.action = 'edit'
                        this.formClone.status = 'pendiente'
                        this.callToApi({ ...form, status: 'pendiente' }, { ...args, system: 'cms', finish: true })
                      }
                    )
                )
                break;
              case 'question':
                this.subscription.add(
                  this.pollService.monitorAddQuestion(args.data, this.monitor)
                    .subscribe(
                      (rs: ResponseData) => {
                        this.modalHelper.pollChange({
                          action: 'add-poll-monitor',
                          questionCount: true,
                          form: form,
                          args: { ...args, module: 'activated' }
                        })
                      },
                      error => {
                        console.error(error)
                        this.alertNotificationService.error('Encuesta', 'Ocurrio un error al crear la encuesta en Monitor', { autoClose: true, keepAfterRouteChange: true });
                        this.pageAction.action = 'edit'
                        this.formClone.status = 'pendiente'
                        this.callToApi({ ...form, status: 'pendiente' }, { ...args, system: 'cms', finish: true })
                      }
                    )
                )
                break;
              case 'activated':
                this.subscription.add(
                  this.pollService.monitorActivated(this.monitor)
                    .subscribe(
                      (rs: ResponseData) => {
                        this.sendEmail()
                      },
                      error => {
                        console.error(error)
                        this.alertNotificationService.error('Encuesta', 'Ocurrio un error al crear la encuesta en Monitor', { autoClose: true, keepAfterRouteChange: true });
                        this.pageAction.action = 'edit'
                        this.formClone.status = 'pendiente'
                        this.callToApi({ ...form, status: 'pendiente' }, { ...args, system: 'cms', finish: true })
                      }
                    )
                )
                break;
            }
            break;
          case 'cms':
            this.subscription.add(
              this.pollService.create(form)
                .subscribe(
                  (rs: ResponseData) => {
                    this.alertNotificationService.success('Encuesta', 'Se creo la encuesta correctamente en el CMS', { autoClose: true, keepAfterRouteChange: true });
                    if (this.formClone.status == 'activo' || this.formClone.status == 'publicado') {
                      this.callToApi(form, { ...args, system: 'monitor' })
                    }
                    else {
                      this.sendEmail()
                    }
                  },
                  error => {
                    console.error(error)
                    this.alertNotificationService.error('Encuesta', 'Ocurrio un error al crear la encuesta en el CMS', { autoClose: true, keepAfterRouteChange: true });
                  }
                )
            )
            break;
        }
        break;
      case 'edit':
        switch (args.system) {
          case 'monitor':
            switch (args.module) {
              case 'poll':
                this.subscription.add(
                  this.pollService.monitorUpdatePoll(args.data, this.monitor)
                    .subscribe(
                      (rs: ResponseData) => {
                        let data = getObject(rs)
                        if (this.changeGroups) {
                          this.monitor.poll = data.token
                          this.setPollForMonitor(form, { ...args, module: 'business' })
                        }
                        else {
                          this.monitor.poll = data.token
                          this.setPollForMonitor(form, { ...args, module: 'activated' })
                        }
                      },
                      error => {
                        console.error(error)
                        this.alertNotificationService.error('Encuesta', 'Ocurrio un error al actualizar la encuesta en Monitor', { autoClose: true, keepAfterRouteChange: true });
                        this.pageAction.action = 'edit'
                        this.formClone = clone(this.formOriginal)
                        this.callToApi({ ...this.formClone }, { ...args, system: 'cms', finish: true })
                      }
                    )
                )
                break;
              case 'business':
                this.subscription.add(
                  this.pollService.monitorAddBusiness(args.data, this.monitor)
                    .subscribe(
                      (rs: ResponseData) => {
                        let data = getObject(rs)
                        if (data) {
                          this.monitor.poll = data.token
                          this.setPollForMonitor(form, { ...args, module: 'activated' })
                        }
                      },
                      error => {
                        console.error(error)
                        this.alertNotificationService.error('Encuesta', 'Ocurrio un error al actualizar la encuesta en Monitor', { autoClose: true, keepAfterRouteChange: true });
                        this.pageAction.action = 'edit'
                        this.formClone = clone(this.formOriginal)
                        this.callToApi({ ...this.formClone }, { ...args, system: 'cms', finish: true })
                      }
                    )
                )
                break;
              case 'activated':
                this.subscription.add(
                  this.pollService.monitorActivated(this.monitor)
                    .subscribe(
                      (rs: ResponseData) => {
                        this.sendEmail()
                      },
                      error => {
                        console.error(error)
                        this.alertNotificationService.error('Encuesta', 'Ocurrio un error al actualizar la encuesta en Monitor', { autoClose: true, keepAfterRouteChange: true });
                        this.pageAction.action = 'edit'
                        this.formClone = clone(this.formOriginal)
                        this.callToApi({ ...this.formClone }, { ...args, system: 'cms', finish: true })
                      }
                    )
                )
                break;
            }
            break;
          case 'cms':
            this.subscription.add(
              this.pollService.update(form.uuid, form)
                .subscribe(
                  (rs: ResponseData) => {
                    this.alertNotificationService.success('Encuesta', 'Se actualizó la encuesta correctamente en el CMS', { autoClose: true, keepAfterRouteChange: true });
                    if (args?.finish) {
                      this.router.navigateByUrl('/polls');
                    }
                    else {
                      if (!this.existChange()) {
                        this.router.navigateByUrl('/polls');
                      }
                      else {
                        this.existPoll(form, args)
                      }
                    }
                  },
                  error => {
                    console.error(error)
                    this.alertNotificationService.error('Encuesta', 'Ocurrio un error al actualizar la encuesta en el CMS', { autoClose: true, keepAfterRouteChange: true });
                  }
                )
            )
            break;
        }
        break;
    }
  }
  // --
  setPollForMonitor(form: Poll, args: any) {
    switch (args.module) {
      case 'poll':
        let encuesta: any = {
          "name": form.title,
          "system_name": form.systemname,
          "description": form.description,
          "status": form.active,
          "startPublicationDate": !!form.schedule.startdate ? this.datePipe.transform(form.schedule.startdate, 'yyyy-MM-dd HH:mm:ss') : null,
          "endPublicationDate": !!form.schedule.enddate ? this.datePipe.transform(form.schedule.enddate, 'yyyy-MM-dd HH:mm:ss') : null,
        }
        // -- payload agregar encuesta - /v2/poll/2732/add
        this.callToApi(form, { ...args, data: encuesta })
        break;
      case 'business':
        let business = {
          companys: this.getBusinessID(form)
        }
        this.callToApi(form, { ...args, data: business })
        break;
      case 'section':
        setTimeout(() => {
          form.sections.map((xSection, iSection) => {
            let seccion = {
              "title": xSection.title,
              "position": xSection.order,
              "status": xSection.active,
              // "description": xSection.description,
            }
            // -- payload agregar secciones - /v2/poll/2732/add/quiz_poll_evaluar-cubrebocas/section
            this.callToApi(form, { ...args, data: seccion, index: iSection })
          })
        })
        break;
      case 'question':
        setTimeout(() => {
          form.sections.map((xSection, iSection) => {
            let preguntasOpciones = []
            this.setPollQuestionForMonitor(xSection.questions, preguntasOpciones)
            preguntasOpciones.map((item, index) => {
              this.monitor.index = iSection
              // -- payload agregar preguntas - /v2/poll/2732/add/quiz_poll_1594345202/section/quiz_section_1594345494/question
              this.callToApi(form, { ...args, data: item })
            })
          })
        })
        break;
      case 'activated':
        // -- Activamos la encuesta /v2/poll/2732/activate/quiz_poll_1594769931
        this.callToApi(form, { ...args })
        break;
    }
  }
  setPollQuestionForMonitor(questions: PollQuestion[], opciones: any) {
    questions.map((xQuestion, iQuestion) => {
      opciones.push({
        "question": xQuestion.title,
        "type": this.getTypePollQuestionForMonitor(xQuestion.typeuuid),//"SELECT | MULTISELECT | OPEN",
        "position": iQuestion,
        "status": xQuestion.active,
        "choices": []
      })
      if (xQuestion.choices.length) {
        xQuestion.choices.map((xChoice, iChoice) => {
          opciones[opciones.length - 1].choices.push(
            {
              "choice": xChoice.title,
              "position": iChoice,
              "nested": []
            }
          )
          if (xChoice.nested.length) {
            this.setPollQuestionForMonitor(xChoice.nested, opciones[opciones.length - 1].choices[opciones[opciones.length - 1].choices.length - 1].nested)
          }
          else {
            opciones[opciones.length - 1].choices[opciones[opciones.length - 1].choices.length - 1].nested = null
          }
        })
      }
      else {
        opciones[opciones.length - 1].choices = null
      }
    })
  }
  // --
  getTypePollQuestionForMonitor(uuid: string): string {
    let type = this.pollTypeQuestions.find(x => x.uuid == uuid)
    if (type) {
      switch (type.systemname) {
        case 'abierta':
          return 'OPEN'
        case 'opcion_unica':
          return 'SELECT'
        case 'opcion_multiple':
          return 'MULTISELECT'
        case 'opcion_unica_imagen':
          return 'IMAGE'
      }
    }
  }
  // --
  setCounterMonitor(form: Poll) {
    this.monitor.sectionCount = 0
    this.monitor.questionCount = 0
    form.sections.map(x => {
      this.monitor.sectionCount = this.monitor.sectionCount + 1
      this.monitor.questionCount = this.monitor.questionCount + x.questions.length
    })
  }
  // --
  getBusinessID(form: any): number[] {
    let idsExist = []
    form.groups.map(x => {
      x.business.map(y => {
        if (y.uuid) {
          idsExist.push(y.uuid)
        }
      })
    })
    let ids = []
    this.groups.map(x => {
      x.business.map(y => {
        if (y.monitorid) {
          if (!idsExist.includes(y.uuid)) {
            ids.push(y.monitorid)
          }
        }
      })
    })
    return ids
  }
  // --
  existPoll(form: any, args: any) {
    this.subscription.add(
      this.pollService.monitorListPoll(this.monitor, this.formClone.systemname)
        .subscribe(
          rs => {
            let exist: any = getObject(rs).data
            if (this.formClone.status == 'activo' || this.formClone.status == 'publicado') {
              if (exist.pollExist) {
                this.pageAction.action = 'edit'
                args.data = { ...args.data, id: exist.idPoll }
              }
              else {
                this.pageAction.action = 'create'
              }
              this.callToApi(form, { ...args, system: 'monitor' })
            }
            else {
              if (exist.pollExist) {
                this.pageAction.action = 'edit'
                args.data = { ...args.data, id: exist.idPoll }
                this.callToApi(form, { ...args, system: 'monitor' })
              }
              else {
                this.sendEmail()
              }
            }
          },
          error => {
            console.error(error)
            this.alertNotificationService.error('Encuesta', 'Ocurrio un error al obtener la existencia de la encuesta en monitor', { autoClose: true, keepAfterRouteChange: true });
            this.pageAction.action = 'edit'
            this.formClone = clone(this.formOriginal)
            this.callToApi(this.formClone, { ...args, system: 'cms', finish: true })
          }
        )
    )
  }
  // --
  changeGroups: boolean = false
  existChange(): boolean {
    this.changeGroups = false
    let haveChange = false
    let items = [
      'active',
      'title',
      'systemname',
      'description',
      'status',
    ]
    items.map(x => {
      if (this.formClone[x] != this.formOriginal[x]) {
        haveChange = true
      }
    })
    if (JSON.stringify(this.formClone.schedule) != JSON.stringify(this.formOriginal.shedule)) {
      haveChange = true
    }
    if (JSON.stringify(this.formClone.groups) != JSON.stringify(this.formOriginal.groups)) {
      haveChange = true
      this.changeGroups = true
    }
    return haveChange
  }
  // --
  sendEmail() {
    let newStatus = {
      'activo': 'Publicado',
      'publicado': 'Publicado',
      'autorizado': 'Autorizado',
      'no_autorizado': 'No Autorizado',
      'desactivado': 'Desactivado',
    }
    if (newStatus[this.formClone.status]) {
      let body = {
        subject: 'CMS Monitor - Encuesta',
        to: this.formClone.email,
        plainTextContent: `¡Hola! Hemos detectado cambios en la encuesta ${this.formClone.title}`,
        htmlContent: `¡Hola! <br><br> La encuesta <b> ${this.formClone.title}</b> fue ${newStatus[this.formClone.status]}`,
      }
      this.subscription.add(
        this.emailService.sendEmail(body)
          .subscribe(
            rs => {
              this.router.navigateByUrl('/polls');
            },
            err => {
              this.router.navigateByUrl('/polls');
            }
          )
      )
    }
    else {
      this.router.navigateByUrl('/polls');
    }
  }
}
