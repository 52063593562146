import { Observable } from "rxjs";

/**
 * Decorator para clase que extiende a los métodos.
 * Espera que el metodo regrese un observable.
 * Cuando se invoca el método, se establece la variable
 * metodo.loading = true
 * Cuando el observable regresa algo,
 * metodo.loading = false
 * 
 * Útil para colocar este decorador en servicios que cargan cosas asincronas y regresan
 * un observable. En automatico indicará si el metodo está cargando algo o no.
 * De esta forma, se puede anclar un "loader view" que dependa del status de "cargando".
 */
export function LoadProgress<TFunction extends Function>(target: TFunction): TFunction     {
    for(var i in target.prototype){
        (function(){
            var methodName = ""+i;
            var method = target.prototype[methodName];
            if(typeof(method) == 'function'){
                var nm = {
                    value:function(){
                        this[methodName].loading = true; //El setting lo ponemos en el nuevo metodo, que es lo que estará leyendo cualquier instancia... y es cada uno por instancia... no se aplica a los metodos del abstract!
                        var observable = method.apply(this,arguments);
                        if(observable != undefined && observable != null && observable instanceof Observable){
                            observable.subscribe((a)=>{
                                this[methodName].loading = false;
                            });
                        }else{
                            this[methodName].loading = false;
                        }
                        return observable;
                    }
                };
                Object.defineProperty(target.prototype,methodName,nm);
            }
        })();
    }
    return target;
}