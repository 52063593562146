import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { SessionService } from 'src/app/services/session.service';
import { RoleDataService } from 'src/app/services/data/role-data.service';
import { getValid, getMessage, getStatus, getArray, hiddenModalLoading, getObject } from 'src/app/utils/_http';
import { Router } from '@angular/router';
import { constants } from 'src/app/constants';
import { labels } from 'src/app/strings';
import { Role } from 'src/app/dto/Role';
import { AlertNotificationService } from 'src/app/services/alert-notification.service';
import { getPermissions, setStorage, existStorage, getStorage, removeStorage, getPermissionsPage } from 'src/app/utils/_utils';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { BasicUserInfo } from 'src/app/dto';
import { UsersDataService } from 'src/app/services/data/users-data.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDeleteComponent } from 'src/app/views/confirm-delete/confirm-delete.component';


@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesListComponent implements OnInit {
  roles: Array<Role> = [];
  rolesList: Array<Role> = [];
  rolesPage: Array<Role> = [];
  deleteModal: any = { show: false, item: null };
  permissions: any = {}
  userRoles: number[] = [];
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };

  searchText: string = ''
  paginator: {
    current: number,
    perPage: number,
    total: number
  } = {
      current: 1,
      perPage: 10,
      total: 0,
    }
  init: boolean = true
  constructor(
    private session: SessionService,
    public settings: SettingsService,
    public roleDataService: RoleDataService,
    private router: Router,
    protected alertNotificationService: AlertNotificationService,
    private modalHelper: ModalHelperService,
    private usersDataService: UsersDataService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit(): void {
    this.permissions = getPermissionsPage(this.router.url);
    this.getRoles();
  }
  checkRoleOwner(item) {
    let roleID = item.hasOwnProperty('uuid') ? item['uuid'] : item['id'];
    return !this.userRoles.includes(roleID);
  }
  getRoles() {
    this.roleDataService.readPaginator(this.session.accessToken.accessToken, this.paginator.current, this.paginator.perPage)
      .subscribe(
        rs => {
          this.rolesPage = getArray(rs);
          this.paginator.total = rs.headers.get('X-Total-Items');
        }
      )
  }
  actionItem(actionName: string, item: any = null) {
    if (actionName == 'delete') {
      item.id = item.hasOwnProperty('uuid') ? item.uuid : item.id;
      this.roleDataService.dataValidate(item.id, this.session.accessToken.accessToken).subscribe(resp => {
        if (resp.body) {
          let deleteModal = this.modalService.open(ConfirmDeleteComponent);
          deleteModal.componentInstance.title = 'No se puede Borrar este ROL';
          deleteModal.componentInstance.message = labels.role.nodelete.confirmMessage;
        }
        else {
          this.deleteModal.item = item;
          const modalRef = this.modalService.open(ConfirmDeleteComponent, this.ngbModalOptions)
          modalRef.componentInstance.title = "Eliminar Rol";
          modalRef.componentInstance.item = this.deleteModal.item;
          modalRef.result.then((res) => {
            this.init = true
            this.paginator.current = 1
            this.getRoles()
          });
        }
      });
    }
    else {
      if (item) {
        item = item.hasOwnProperty('uuid') ? item.uuid : item.id;
        this.router.navigate([constants.screens.roles.path, item, constants.screens.roles[actionName]]);
      }
      else this.router.navigate([constants.screens.roles.path, constants.screens.roles[actionName]]);
    }
  }
  dataFound($event: any) {
    this.rolesList = $event;
  }
  changePage($event: any) {
    this.paginator = $event
    if (this.init) {
      this.init = false
    }
    else {
      this.getRoles()
    }
  }
}

