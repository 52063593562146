import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BasicUserInfo } from 'src/app/dto/BasicUserInfo';
import { SessionService } from 'src/app/services/session.service';
import { UsersDataService } from 'src/app/services/data/users-data.service';
import { GroupDataService } from 'src/app/services/data/group-data.service';
import { getValid, getArray, hiddenModalLoading } from 'src/app/utils/_http';
import { SettingsService } from 'src/app/services/settings.service';
import { constants } from 'src/app/constants';
import { RoleDataService } from 'src/app/services/data/role-data.service';
import { Role } from 'src/app/dto/Role';
import { Group } from 'src/app/dto/group.dto';
import { getStorage, getPermissionsPage } from 'src/app/utils/_utils';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { AlertNotificationService } from 'src/app/services';

@Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit, OnDestroy {
    subscription: Subscription = new Subscription
    permissionPage = [];
    constants = constants;
    roles: Array<Role> = [];
    users: Array<BasicUserInfo> = [];
    usersList: Array<BasicUserInfo> = [];
    usersPage: Array<BasicUserInfo> = [];
    userLogged: BasicUserInfo;
    deleteModal = { show: false, item: null };
    _selectedAllItems: boolean = false;
    permissions: any = {};

    searchText: string = ''
    paginator: {
        current: number,
        perPage: number,
        total: number
    } = {
            current: 1,
            perPage: 10,
            total: 0,
        }
    init: boolean = true

    constructor(
        private session: SessionService,
        public settings: SettingsService,
        public usersDataService: UsersDataService,
        private groupDataSrv: GroupDataService,
        private router: Router,
        public roleDataService: RoleDataService,
        private modalHelper: ModalHelperService,
        private alertNotificationService: AlertNotificationService,
    ) { }
    ngOnInit(): void {
        this.userLogged = getStorage(constants.ls.sessionProfile, true);
        this.permissions = getPermissionsPage(this.router.url);
        this.getUsers()
    }
    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe()
        }
    }
    getUsers() {
        this.subscription.add(
            this.usersDataService.read(this.session.accessToken.accessToken, this.paginator.current, this.paginator.perPage)
                .subscribe(
                    rs => {
                        this.paginator.total = rs.headers.get('x-total-items');
                        this.usersPage = getArray(rs)
                    }
                )
        )
    }
    changePage(event: any) {
        this.paginator = event
        if (this.init) {
            this.init = false
        }
        else {
            this.getUsers()
        }
    }
    dataFound($event) {
        this.usersList = $event;
    }
    openBusiness(business) {
        this.router.navigate(['groups-business', business.data.uuid, 'edit']);
    }
    actionItem(actionName: string, item: any = null) {
        if (actionName == 'delete') {
            this.deleteModal.show = true;
            this.deleteModal.item = item;
        }
        else if (item) this.router.navigate([constants.screens.users.path, item, constants.screens.users[actionName]]);
        else this.router.navigate([constants.screens.users.path, constants.screens.users[actionName]]);
    }
    selectedAllItems() {
        this._selectedAllItems = !this._selectedAllItems;
        let items = document.querySelectorAll('.userItem');
        [].map.call(items, (item, index) => {
            items[index]['checked'] = this._selectedAllItems;
        })
    }
    // --
    deleteItem(data: any = null) {
        this.deleteModal.show = false
        if (data) {
            this.subscription.add(
                this.usersDataService.delete(data.uuid)
                    .subscribe(
                        rs => {
                            this.paginator.current = 1
                            this.getUsers()
                            this.alertNotificationService.success('Usuarios', 'Se eliminó el usuario con éxito', { autoClose: false, keepAfterRouteChange: true })
                        },
                        error => {
                            this.alertNotificationService.error('Usuarios', 'Ocurrio un error, intente más tarde', { autoClose: true, keepAfterRouteChange: true })
                        }
                    )
            )
        }
        else {
            this.deleteModal.item = null
        }
    }
}
