import { Component, OnInit } from '@angular/core';
import { constants } from 'src/app/constants';
import { Router } from '@angular/router';
import { CreateTemplateDataService } from 'src/app/services/data/create-template-data.service';
import { SessionService } from 'src/app/services/session.service';
import { hiddenModalLoading, getArray} from 'src/app/utils/_http';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { Plantilla } from 'src/app/dto/PlantillaList';

@Component({
  selector: 'app-list-template',
  templateUrl: './list-template.component.html',
  styleUrls: ['./list-template.component.css']
})
export class ListTemplateComponent implements OnInit {

  plantillas: Array<Plantilla> = [];

  // Datos de prueba
  datosPlantillas: any[] = [
    {id:"1", name: "plantilla1", status: true, date: "20/09/2021"},
    {id:"2", name: "plantilla2", status: true, date: "21/09/2021"},
    {id:"3", name: "plantilla3", status: true, date: "22/09/2021"},
  ]

  constructor(
    private router: Router,
    public createTemplateDataService: CreateTemplateDataService,
    private session: SessionService,
    private modalHelper: ModalHelperService
  ) { }

  ngOnInit(): void {
    this.getPlantillas();
  }

  getPlantillas() {
    //this.plantillas = this.datosPlantillas;
    // Llamar al servicio de listar
    this.createTemplateDataService.readAll(this.session.accessToken.accessToken)
      .subscribe(
        rs => {
          if(rs.body.code == 200){
            this.plantillas = rs.body.plantillas;
            this.modalHelper.changeLoading(false);
          }else{
            this.plantillas = [];
          }
        }
      )
  }

  actionItem(actionName: string, item: any = null) {
    if(item){
      item = item.idplantillapermisomonitor
      this.router.navigate([constants.screens.groups.path, constants.screens.groups.createTemplate, item]);
    }else{
      this.router.navigate([constants.screens.groups.path, constants.screens.groups.createTemplate]);
    }
  }

}
