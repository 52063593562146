import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { Group } from 'src/app/dto/group.dto';

@Component({
    selector: 'abarcms-group-filter',
    templateUrl: './group-filter.component.html',
    styleUrls: ['./group-filter.component.css']
})
export class GroupFilterComponent implements OnInit {

    @Input('button-title') title: string;
    @Input('data') data: Array<Group>;
    private _selectedAllItems: boolean;

    constructor(
        private elRef:ElementRef
    ) { }

    ngOnInit(): void {

    }

    selectedAllItems(_mainItem) {
        this._selectedAllItems = !this._selectedAllItems;
        let items = this.elRef.nativeElement.querySelectorAll('.empresItem-' + _mainItem.system_name);
        //let items = document.getElementsByClassName('.empresItem-'+_mainItem.system_name);
        [].map.call(items, (item, index) => {
            items[index]['checked'] = this._selectedAllItems;
        })
    }

}
