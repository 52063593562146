import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DashboardWidget } from "src/app/dto/DashboardWidget";
import { HttpHeaders } from "@angular/common/http";
import { ResponseData } from 'src/app/dto';

@Injectable({
  providedIn: "root"
})
export abstract class DashboardDataService {
  abstract getDashboardWidgets(
    username: string,
    accessToken: string
  ): Observable<DashboardWidget[][][]>;
  abstract getDashboardWidgetData(type: string): Observable<any>;
  abstract read(accessToken: string, page: number, itemsPerPage: number): Observable<ResponseData>;
  abstract readPaginator(accessToken: string, page: number, itemsPerPage: number): Observable<ResponseData>;
}
