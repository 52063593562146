<div *ngIf="data && data.length==1" >
  <abarcms-dashboard-card *ngFor="let widget of data[0]" [data]="widget"></abarcms-dashboard-card>
</div>

<div class="row" *ngIf="data && data.length>=2">
  <div class="col-lg-5 col-6">
    <abarcms-dashboard-card *ngFor="let widget of data[0]" [data]="widget"></abarcms-dashboard-card>
  </div>
  <div class="col-lg-7 col-6">
    <abarcms-dashboard-card *ngFor="let widget of data[1]" [data]="widget"></abarcms-dashboard-card>
  </div>
</div>