<aside class="lateral-menu" [ngClass]="{'active': isMenuOpen}">
  <div class="collapse-menu" (click)="toggleMenu()">
    <div class="text-left ml-2">
      <a class="collapse-disabled">
        <span class="light-text">Abrir</span>
        <i class="fas fa-arrow-right"></i>
      </a>
    </div>
    <div class="text-right mr-2">
      <a class="collapse-active">
        <i class="fas fa-arrow-left"></i>
        <span class="light-text">Cerrar</span>
      </a>
    </div>
  </div>
  <div class="logo-wrap">
    <a (click)="goTo('/dashboard')">
      <img src="assets/images/logo.svg" alt="">
    </a>
  </div>
  <div class="profile-wrap">
    <div class="visible-content">
      <a (click)="actionItem()" *ngIf="session.user">
        <div class="profile-pic">
          <ng-container *ngIf="imageProfileURL == null">
            <span>{{session.user.name?.charAt(0)}}{{session.user.lastname?.charAt(0)}}</span>
          </ng-container>
          <ng-container *ngIf="imageProfileURL">
            <img [src]="imageProfileURL" alt="image-profile">
          </ng-container>
        </div>
      </a>
    </div>
    <div class="toggle-content">
      <a (click)="actionItem()" *ngIf="session.user">
        <h6 class="profile-name">{{session.user.name}} {{session.user.lastname}}</h6>
      </a>
      <a (click)="onSignOut()" class="log-out" *ngIf="settings['signIn']['enabled']">Cerrar sesión</a>
    </div>
  </div>
  <div class="menu-wrap">

    <ng-container *ngFor="let menu of menus">
      <div class="menu-item-wrap">
        <div class="menu-item" (click)="onMenuClick(menu)">
          <div class="visible-content">
            <div class="img-wrap" *ngIf="menu.icon && isURL(menu.icon)">
              <img src="{{menu.icon}}" alt="">
            </div>
            <div class="img-wrap" *ngIf="menu.icon && !isURL(menu.icon)">
              <i class="{{menu.icon}}"></i>
            </div>
          </div>
          <div class="toggle-content">
            <p>{{menu.title}}</p>
            <i class="fas fa-chevron-down" *ngIf="menu.menus && menu.menus.length > 0"></i>
          </div>
        </div>
        <ul class="submenu" *ngIf="menu.menus && menu.menus.length>0" [ngClass]="{'active':menu.show}">
          <ng-container *ngFor="let submenu of menu.menus">
            <ng-container *ngIf=" (submenu.params[0])">
              <li>
                <a (click)="onMenuClick(menu, submenu)">{{submenu.title}}</a>
              </li>
            </ng-container>
          </ng-container>
        </ul>
      </div>
    </ng-container>

  </div>
</aside>
<abarcms-modal *ngIf="showSignOutConfirm" (close)="showSignOutConfirm = false">
  <p>¿Desea cerrar sesión?</p>
  <div class="mtb-20 text-center">
    <a googleSignOut (signOut)="signOut()"><button class="btn-primary">Cerrar sesión</button></a>
    <button class="btn-outline" (click)="showSignOutConfirm = false">Regresar</button>
  </div>
</abarcms-modal>