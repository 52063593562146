import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { constants } from "src/app/constants";
import { SettingsService, AlertNotificationService } from "src/app/services";
import { CompanyService } from "src/app/screens/admin/seccion-monitor/services/company.service";
import { ModalHelperService } from "src/app/services/modal-helper.service";
import { SessionService } from "src/app/services/session.service";
import { labels } from "src/app/strings";
import { getValid } from "src/app/utils/_http";
import { getPermissionsPage } from "src/app/utils/_utils";
import { ConfirmDeleteComponent } from "src/app/views/confirm-delete/confirm-delete.component";

@Component({
  selector: "app-company-list",
  templateUrl: "./company-list.component.html",
  styleUrls: ["./company-list.component.css"],
})
export class CompanyListComponent implements OnInit {
  constructor(
    private modalSrv: NgbModal,
    private companySrv: CompanyService,
    protected session: SessionService,
    private modalHelper: ModalHelperService,
    public settings: SettingsService,
    private router: Router,
    protected alertNotificationService: AlertNotificationService
  ) {
    this.getTableData();
  }

  ngOnInit(): void {
    this.permissions = getPermissionsPage(this.router.url);
  }

  init: boolean = true;

  companys: Array<any> = [];
  permissions: any;
  constants = constants;

  searchText: string = "";

  paginator: {
    current: number;
    perPage: number;
    total: number;
  } = {
    current: 1,
    perPage: 10,
    total: 0,
  };

  createEmpresa() {
    this.router.navigate([
      constants.screens.empresas.path,
      constants.screens.empresas.empresa_nueva,
    ]);
  }
  editEmpresa(item) {
    this.router.navigate([
      constants.screens.empresas.path,
      item.companyId,
      constants.screens.empresas.empresa_edicion,
    ]);
  }
  activarInactivarEmpresa(item) {
    let deleteModal = this.modalSrv.open(ConfirmDeleteComponent);
    deleteModal.componentInstance.title = "Cambiar estatus de la empresa";
    deleteModal.componentInstance.message = item.status
      ? labels.empresas.delete.confirmDeleteMessage
      : labels.empresas.active.confirmActiveMessage;
    deleteModal.result
      .then(async (_response) => {
        if (_response === "CONFIRMED") {
          (
            await this.companySrv.activarInactivarEmpresa(
              item.companyId,
              !item.status
            )
          ).subscribe(
            (resp) => {
              this.alertNotificationService.success(
                "Aviso",
                "El registro se modifico exitosamente.",
                { autoClose: true, keepAfterRouteChange: true }
              );
              this.getTableData();
            },
            (error) => {}
          );
        }
      })
      .catch((res) => {});
  }

  async getTableData(): Promise<void> {
    let body = {
      pageSize: this.paginator.perPage,
      page: this.paginator.current,
      sortDirection: "asc",
      sort: "c.name",
      filters: {
        name: this.searchText,
      },
    };
    (await this.companySrv.allEmpresas(body)).subscribe((_rs) => {
      if (
        getValid(_rs, {
          title: `Error al obtener los grupos`,
          modalHelper: this.modalHelper,
          settings: this.settings,
        })
      ) {
        this.paginator.total = _rs.body.data.pagination.total_rows;
        this.companys = _rs.body.data.companys;
        this.modalHelper.changeLoading(false);
      }
    });
  }

  onSearchChange(event: any) {
    this.paginator.current = 1;
    this.getTableData();
  }

  changePage($event: any) {
    this.paginator = $event;
    if (this.init) {
      this.init = false;
    } else {
      this.getTableData();
    }
  }
}
