import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/services';
import { Router } from '@angular/router';
import { constants } from 'src/app/constants';
import { existStorage, getStorage } from 'src/app/utils/_utils';

@Component({
  selector: 'abarcms-cms-authed-admin',
  templateUrl: './cms-authed-admin.component.html',
  styleUrls: ['./cms-authed-admin.component.css']
})
export class CmsAuthedAdminComponent implements OnInit {
  isMenuOpen = false;
  constructor(
    public sessionService: SessionService,
    private router: Router
  ) {
  }
  ngOnInit() {
    const pages = getStorage(constants.ls.pagesPermission, true);
    if (pages) {
      const found = Object.keys(pages).find(pageItem => {
        return this.router.url.indexOf(pageItem) != -1
      });
      if (!found) {
        this.router.navigate([constants.screens.dashboard.path]);
      }
    }
  }
  hiddenMenu($event) {
    this.isMenuOpen = $event;
  }
}
