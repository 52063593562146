import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'abarcms-dashboard-widget-metrics',
  templateUrl: './dashboard-widget-metrics.component.html',
  styleUrls: ['./dashboard-widget-metrics.component.css']
})
export class DashboardWidgetMetricsComponent implements OnInit {

  @Input('data')
  public data = [];
  constructor() { }

  ngOnInit() {
  }

}
