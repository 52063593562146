import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { makeHeaders, createResponseData, getStatus, getValid, handleError } from 'src/app/utils/_http';
import { SessionService } from './session.service';
import { Router } from '@angular/router';
import { ModalHelperService } from './modal-helper.service';
import { LogsDataService } from './data/logs-data.service';
import { constants } from '../constants';
import { catchError, map } from 'rxjs/operators';
import { SettingsService } from '.';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    private pathInfo: any;
    get options() {
        const token = this.session
            && this.session.accessToken
            && this.session.accessToken.accessToken
            ? this.session.accessToken.accessToken : '';
        return {
            'X-Auth-Token': token
        }
    };

    constructor(
        private http: HttpClient,
        protected session: SessionService,
        private modalHelper: ModalHelperService,
        private settingsService: SettingsService,
        private logsDataService: LogsDataService,
    ) { }

    public get(path: string, headers: any = {}, token: boolean = true): Observable<any> {
        let customHeaders: any = token ? this.options : {};
        customHeaders = { ...customHeaders, ...headers };
        if (headers.hasOwnProperty('modal')) {
            if (headers.modal === true) {
                this.modalHelper.changeLoading(true);
            }
        }
        return this.http
            .get<any>(
                `${path}`,
                makeHeaders(customHeaders)
            )
            .pipe(
                map(response => {
                    return getValid(response, { modalHelper: this.modalHelper, settings: this.settingsService })
                })
            )
    }

    public post(path: string, body: any = {}, token: boolean = true, header: any = {}, log: boolean = false): Observable<any> {
        if (header.hasOwnProperty('modal')) {
            if (header.modal === true) {
                this.modalHelper.changeLoading(true);
            }
        }
        return this.http
            .post<any>(
                `${path}`,
                JSON.stringify(body),
                makeHeaders(token ? { ...this.options, ...header } : header)
            )
            .pipe(
                map(response => {
                    return getValid(response, { modalHelper: this.modalHelper, settings: this.settingsService }, () => {
                        if (log) {
                            if (body?.password) {
                                delete body.password;
                            }
                            this.logsDataService.create(
                                this.getModule(path),
                                'post',
                                JSON.stringify(body)
                            )
                        }
                    })
                })
            )
    }

    public put(path: string, body: any = {}, token: boolean = true, log: boolean = false, header: any = {}): Observable<any> {
        if (header.hasOwnProperty('modal')) {
            if (header.modal === true) {
                this.modalHelper.changeLoading(true);
            }
        }
        return this.http
            .put<any>(
                `${path}`,
                JSON.stringify(body),
                makeHeaders(token ? { ...this.options, ...header } : header)
            )
            .pipe(
                map(response => {
                    return getValid(response, { modalHelper: this.modalHelper, settings: this.settingsService }, () => {
                        if (log) {
                            if (body?.password) {
                                delete body.password;
                            }
                            this.logsDataService.create(
                                this.getModule(path),
                                'put',
                                JSON.stringify(body)
                            )
                        }
                    })
                })
            )
    }

   /* public patch(path: string, body: any = {}, token: boolean = true, log: boolean = false, header: any = {}): Observable<any> {
        if (header.hasOwnProperty('modal')) {
            if (header.modal === true) {
                this.modalHelper.changeLoading(true);
            }
        }
        return this.http
            .patch<any>(
                `${path}`,
                JSON.stringify(body),
                makeHeaders(token ? { ...this.options, ...header } : header)
            )
            .pipe(
                map(response => {
                    return getValid(response, { modalHelper: this.modalHelper, settings: this.settingsService }, () => {
                        if (log) {
                            if (body?.password) {
                                delete body.password;
                            }
                            this.logsDataService.create(
                                this.getModule(path),
                                'put',
                                JSON.stringify(body)
                            )
                        }
                    })
                })
            )
    }*/

    public delete(path: string, id: any, token: boolean = true, log: boolean = false, header: any = {}): Observable<any> {
        if (header.hasOwnProperty('modal')) {
            if (header.modal === true) {
                this.modalHelper.changeLoading(true);
            }
        }
        return this.http
            .delete<any>(
                `${path}`,
                makeHeaders(token ? { ...this.options, ...header } : header)
            )
            .pipe(
                map(response => {
                    return getValid(response, { modalHelper: this.modalHelper, settings: this.settingsService }, () => {
                        if (log) {
                            this.logsDataService.create(
                                this.getModule(path),
                                'delete',
                                JSON.stringify({ identificador: id.toString() })
                            )
                        }
                    })
                })
            )
    }

    public patch(path: string, body: any = {}, token: boolean = true, log: boolean = false, header: any = {}): Observable<any> {
        if (header.hasOwnProperty('modal')) {
            if (header.modal === true) {
                this.modalHelper.changeLoading(true);
            }
        }
        return this.http
        .patch<any>(
            `${path}`,
            JSON.stringify(body),
            makeHeaders(token ? { ...this.options, ...header } : header)
        )
        .pipe(
            map(response => {
                return getValid(response, { modalHelper: this.modalHelper, settings: this.settingsService }, () => {
                    if (log) {
                        this.logsDataService.create(
                            this.getModule(path),
                            'patch',
                            JSON.stringify(body)
                            )
                        }
                    })
                })
            )
    }


    private getModule = (path: string) => {
        const module = path.split('/')[3]
        return module;
    }
}
