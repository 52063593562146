import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CatGrupoVisualizacion } from '../../dto/secciones-monitorDTO';
import { SeccionMonitorService } from '../../services/seccion-monitor.service';
import { constantes } from '../../constants/seccion-monitor';

@Component({
  selector: 'app-catgrupovisualizacion',
  templateUrl: './catgrupovisualizacion.component.html',
  styleUrls: ['./catgrupovisualizacion.component.css']
})
export class CatgrupovisualizacionComponent implements OnInit,OnChanges {
  catGruposSelected:string;
  catGruposVisualizacion:CatGrupoVisualizacion[];
  @Input() cambio:number;
  displayGroupTypeId:number;
  constructor(private seccionesMonitorService:SeccionMonitorService) {
   }

   ngOnChanges(changes: SimpleChanges): void {
   }
  ngOnInit(): void {
    this.getCatGruposVisualizacion();
    this.displayGroupTypeIdChecked();

  }
  displayGroupTypeIdChecked():void{
    constantes.secciones.displayGroupTypeId=this.displayGroupTypeId
    console.log("click dede componente"+constantes.secciones.displayGroupTypeId)
  }

  getCatGruposVisualizacion(){
    this.seccionesMonitorService.getCatGrupoVisualizacion().subscribe(result=>{
      console.log(result)
      this.catGruposVisualizacion=result.data;
    })
  }
}
