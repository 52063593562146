import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseData } from 'src/app/dto/ResponseData';

@Injectable({
  providedIn: 'root'
})
export abstract class ContentsTypeDataService {
  abstract create(body: any): Observable<ResponseData>;
  abstract read(filters?: any, headers?: any): Observable<ResponseData>;
  abstract readFields(id: string, headers?: any): Observable<ResponseData>;
  abstract update(id: any, body: any): Observable<ResponseData>;
  abstract delete(id: string): Observable<ResponseData>;
}
