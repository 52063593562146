import { Injectable } from '@angular/core';
import { SessionDataService } from '../session-data.service';
import { AuthenticationCredential } from 'src/app/dto/AuthenticationCredential';
import { Observable, Subject, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '../../settings.service';
import { createResponseData, makeHeaders } from 'src/app/utils/_http';
import { ResponseData } from 'src/app/dto/ResponseData';
import { ModalHelperService } from '../../modal-helper.service';

@Injectable({
  providedIn: 'root'
})
export class SessionDataWsService implements SessionDataService {
  pathInfo: any;
  private baseUrl: string;
  private public: any;
  private admin: any;
  constructor(private http: HttpClient,
    private settings: SettingsService,
    private modalHelper: ModalHelperService
  ) {
    this.pathInfo = this.settings.get('pathInfo');
    this.baseUrl = this.pathInfo.base.baseUrl + this.pathInfo.base.prefix;
    this.public = this.pathInfo.res.public['access'];
    this.admin = this.pathInfo.res.admin['profile'];
  }
  signIn(credential: AuthenticationCredential): Observable<ResponseData> {
    let options = {};
    let endpoint = this.baseUrl + this.public.prefix + this.public.resources.signIn;
    let obs: Subject<ResponseData> = new Subject<ResponseData>();
    this.http.post<ResponseData>(endpoint, credential, makeHeaders(options))
      .subscribe((response: ResponseData) => {
        obs.next(createResponseData(response))
        obs.error(throwError(response))
      },
        error => {
          let dataError = {
            title: 'Error de autenticación',
            message: 'Datos de usuario y contraseña inválidos',
            show: true
          }
          this.modalHelper.changeErrorModal(dataError);
          this.modalHelper.changeLoading(false);
        })
    return obs.asObservable();
  }
  getUser(credential: AuthenticationCredential): Observable<ResponseData> {
    let options = { 'X-Auth-Token': credential.token['accessToken'] };
    let endpoint = this.baseUrl + this.admin.prefix;
    let obs: Subject<ResponseData> = new Subject<ResponseData>();
    this.http.get<ResponseData>(endpoint, makeHeaders(options))
      .subscribe((response: ResponseData) => {
        obs.next(createResponseData(response))
        obs.error(throwError(response))
      });
    return obs.asObservable();
  }
  signOut(credential: AuthenticationCredential, uuid: string): Observable<ResponseData> {
    let options = { 'X-Auth-Token': credential, "modal": false };
    let endpoint = this.baseUrl + "/setLastLogin/" + uuid;
    let obs: Subject<ResponseData> = new Subject<ResponseData>();
    this.http.get<ResponseData>(endpoint, makeHeaders(options))
      .subscribe((response: ResponseData) => {
        obs.next(createResponseData(response))
        obs.error(throwError(response))
      });
    return obs.asObservable();
  }
}
