import { Directive, Input, NgZone, Output, EventEmitter, ElementRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Directiva para habilitar google sign in en un elemento cualquiera.
 * Requiere poner esto en el <head>: <script src="https://apis.google.com/js/platform.js" async defer></script>
 * Uso:
 * <elemento [googleSignIn]="ClientId" (signIn)="fn($event)" (error)="fn($event)"></elemento>
 */
@Directive({
  selector: '[googleSignIn]'
})
export class GoogleSignInDirective {

  @Input('googleSignIn')
  public clientId:string = null;

  @Output('signIn')
  public signInEE:EventEmitter<any> = new EventEmitter<any>();

  @Output('error')
  public errorEE:EventEmitter<any> = new EventEmitter<any>();

  /**
   * Contiene el google api object.
   */
  private _gapi = null;
  /**
   * Contiene la instancia de auth2 ya con el id del cliente.
   */
  private _auth2 = null;

  public gapi:BehaviorSubject<any> = new BehaviorSubject(this._gapi);
  public auth2:BehaviorSubject<any> = new BehaviorSubject(this._auth2); 

  constructor(private ngZone:NgZone, el:ElementRef) {
    this.initGAPI();
    this.setupSignInButton(el.nativeElement);
  }

  initGAPI(){
    if(this._gapi != null){
      return this._gapi;
    }
    var that = this;
    this._gapi = window['gapi'];
    if(!(this._gapi)){
      setTimeout(()=>{
        this.initGAPI();
      },1000);
      return;
    }
    this._gapi.load('auth2',()=>{
        that.gapi.next(that._gapi);
        that._auth2 = that._gapi.auth2.init({
          'client_id':this.clientId
        });
        that.auth2.next(that._auth2);
        that._auth2.then(()=>{//Actualizamos cuando se carguen los datos del ya autenticado.
          that.auth2.next(that._auth2);
        });
      } 
    )
  }
  public setupSignInButton(element){
    this.auth2.subscribe((auth2)=>{
      if(auth2 != null){
        var that = this;
        let x = element;
        window['gapi_auth2']=auth2;
        this._auth2.attachClickHandler(x, {},
          (googleUser)=> {
            var profile = googleUser.getBasicProfile();
            this.ngZone.run(()=>{
              this.signInEE.emit({
                'auth2':auth2,
                'googleUser':googleUser,
                'profile':profile,
                'accessToken':this.getAccessToken(auth2['currentUser'].get()),
                'idToken':googleUser.getAuthResponse().id_token
              });
            });
          }, (error)=> {
            this.ngZone.run(()=>{
              this.errorEE.emit(error);
            });
            
          });
      }
    })
  }
  getAccessToken(a):string{
    for(var i in a){
      let v = a[i];
      if(typeof(v)=='object'){
        if(typeof(v['access_token']) == 'string'){
          let at:string = v['access_token'];
          return at;
        }
      }
    }
    return null;
  }
}
