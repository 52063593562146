<abarcms-loader-full *ngIf="loading"></abarcms-loader-full>

<div class="container-fluid">
    <div class="card border-card">
        <div class="card-content">
            <div class="row mb-20">
                <div class="card__title col-xs-12 col-md-4 col-lg-4">
                    <h2 class="title-list">Empresa</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="etiqueta">Nombre de la empresa:</label>
                        <input type="text" [class]="'form-control'" placeholder="Nombre de empresa" [(ngModel)]="company.nombre"
                        [style]="validaError(errors.nombre) ? 'border-color: red; margin-bottom: 3px; color: red ':''" (change)="validaNombre()">
                        <span *ngIf="validaError(errors.nombre)" style="color: red; font-size: 14px;">{{errors.nombre}}</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="etiqueta">País:</label>
                        <ng-select [items]="paises" [multiple]="false" [closeOnSelect]="true"
                          [hideSelected]="true" bindLabel="name"  bindValue="id" placeholder="Selecciona un país" [(ngModel)]="company.id_pais" 
                          [class]="validaError(errors.id_pais) ? 'ngSelectError ':''"
                          [style]="validaError(errors.id_pais) ? 'border-color: red; margin-bottom: 3px; color: red ':''"
                          (change)="validaPais()">
                      </ng-select>
                      <span *ngIf="validaError(errors.id_pais)" style="color: red; font-size: 14px;">{{errors.id_pais}}</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="etiqueta">Telefono:</label>
                        <input type="text" [class]="'form-control'" placeholder="Telefono" [(ngModel)]="company.telefono" (keyup)="validaTelefono()" maxlength="10"
                        [style]="validaError(errors.telefono) ? 'border-color: red; margin-bottom: 3px; color: red ':''">
                        <span *ngIf="validaError(errors.telefono)" style="color: red; font-size: 14px;">{{errors.telefono}}</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="etiqueta">Correo:</label>
                        <input type="text" [class]="'form-control'" placeholder="Correo" [(ngModel)]="company.correo"
                        [style]="validaError(errors.correo) ? 'border-color: red; margin-bottom: 3px; color: red ':''" (keyup)="validaCorreo()">
                        <span *ngIf="validaError(errors.correo)" style="color: red; font-size: 14px;">{{errors.correo}}</span>
                    </div>
                </div>
                <div  class="col-md-3">
                    <div class="form-group">
                        <label>Contact traicing</label>
                        <input class="form-control" type="checkbox" [(ngModel)]="company.contact_tracing">
                    </div>
                </div>
                <div  class="col-md-9">
                    <div class="form-group">
                        <label class="etiqueta">Encuestas a no mostrar:</label>
                        <ng-select [items]="encuestas" bindLabel="name" bindValue="id" [multiple]="true" groupBy="selectedAllGroup" [clearable]="false"
                            [selectableGroup]="true" placeholder="Selecciona las encuestas a no mostrar"
                            [selectableGroupAsModel]="false" [closeOnSelect]="false" [(ngModel)]="company.id_encuesta_no_mostrar" >
                            <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index" >
                                <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}"/>
                                Select All
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}" [disabled]="item.disabled" />
                                {{ item.name }}
                            </ng-template>
                        </ng-select>
                        <span *ngIf="validaError(errors.id_encuesta_no_mostrar)" style="color: red; font-size: 14px;">{{errors.id_encuesta_no_mostrar}}</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="etiqueta">Grupo:</label>
                        <ng-select [items]="grupos" [multiple]="false" [closeOnSelect]="true" [disabled]="pageAction.groupId != undefined && pageAction.groupId != null"
                          [hideSelected]="true" bindLabel="name"  bindValue="group_id" placeholder="Selecciona un país" [(ngModel)]="company.id_grupo" 
                          [style]="validaError(errors.id_grupo) ? 'border-color: red; margin-bottom: 3px; color: red ':''"
                          [class]="validaError(errors.id_grupo) ? 'ngSelectError ':''"
                          (change)="validaGrupo()">
                      </ng-select>
                      <span *ngIf="validaError(errors.id_grupo)" style="color: red; font-size: 14px;">{{errors.id_grupo}}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div  class="col-md-12"><span style="font-weight: bold; font-size: 18px; ">Token</span></div> <br/><br/>
                <div  class="col-md-12">

                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation" *ngIf="!showNewToken">
                            <a class="nav-link" [ngClass]="{active: tabSelected == tabHistoricTokens}" id="history-tab" data-toggle="tab" href="#history" 
                                role="tab" aria-controls="history" aria-selected="true" (click)="tabSelected = tabHistoricTokens">Historial</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" [ngClass]="{active: tabSelected == tabToken}" id="setget-tab" data-toggle="tab" href="#setget"
                                role="tab" aria-controls="setget" aria-selected="false" (click)="tabSelected = tabToken"> {{ !showNewToken ? 'Generar Nuevo / Actualizar Vigencia': 'Generar'}}</a>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade table-responsive-xl" [ngClass]="{'show active': tabSelected == tabHistoricTokens}" id="history" role="tabpanel" aria-labelledby="history-tab">
                            <table class="table table-striped mb-0">
                                <thead>
                                    <tr>
                                        <th style="font-size: 15px; width: 198px;"> Token</th>
                                        <th style="font-size: 15px; width: 131px;"> Fecha Inicio</th>
                                        <th style="font-size: 15px; width: 131px;"> Fecha Vigencia</th>
                                        <th style="font-size: 15px; width: 131px;"> Estatus</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of tokens ">
                                        <td><span [style]="item.token == token ? 'font-weight: bold; font-size:18px; color: green;':''">{{ item.token }}</span></td>
                                        <td><span [style]="item.token == token ? 'font-weight: bold; font-size:18px; color: green;':''">{{ item.fecha_inicial }}</span></td>
                                        <td><span [style]="item.token == token ? 'font-weight: bold; font-size:18px; color: green;':''">{{ item.token_vigencia }}</span></td>
                                        <td><span [style]="item.token == token ? 'font-weight: bold; font-size:18px; color: green;':''">{{ item.estatus ? 'Activo':'Inactivo' }}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="tab-pane fade table-responsive-xl" [ngClass]="{'show active': tabSelected == tabToken}" id="history" role="tabpanel" aria-labelledby="history-tab">
                            <div class="form-group mt-2" *ngIf="!showNewToken">
                                <div class="alert alert-warning" >
                                    <div>Fecha de la vigencia del token activo</div>
                                    <div>
                                        <strong>
                                            {{endDate | date:'MMM d, y h:mm a'}}
                                        </strong>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group mt-2" *ngIf="showNewToken">
                                <small>Token Generado:</small>
                                <input type="text" class="form-control" [(ngModel)]="token" readonly>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group mt-2">
                                        <small>Fecha de inicio:</small>
                                        <div class="input-group" [style]="validaError(errors.startDate) ? 'border: 1px solid red; margin-bottom: 3px; color: red ':''">
                                            <input class="form-control mb-0" [(ngModel)]="startDate" placeholder="yyyy-mm-dd" [owlDateTimeTrigger]="startDateOpen" [owlDateTime]="startDateOpen" selectMode="single" readonly>
                                            <owl-date-time [pickerType]="'both'" #startDateOpen [hour12Timer]="false" (afterPickerClosed)="minDate('change'); validaFechaInicio()"> </owl-date-time>
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary fas fa-calendar" type="button"  [owlDateTimeTrigger]="startDateOpen"></button>
                                            </div>
                                        </div>
                                        <span *ngIf="validaError(errors.startDate)" style="color: red; font-size: 14px;">{{errors.startDate}}</span>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group mt-2">
                                        <small>Fecha de vigencia:</small>
                                        <div class="input-group" [style]="validaError(errors.endDate) ? 'border: 1px solid red; margin-bottom: 3px; color: red ':''">
                                            <input class="form-control mb-0" [(ngModel)]="endDate" [min]="minDate('endDateMin')" placeholder="yyyy-mm-dd" [owlDateTimeTrigger]="endDateOpen" 
                                            [owlDateTime]="endDateOpen" selectMode="single"  readonly>
                                            <owl-date-time [pickerType]="'both'" #endDateOpen [hour12Timer]="false" (afterPickerClosed)="minDate('finish'); validaFechaVigencia()"> </owl-date-time>
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary fas fa-calendar" type="button" [owlDateTimeTrigger]="endDateOpen"></button>
                                            </div>
                                        </div>
                                        <span *ngIf="validaError(errors.endDate)" style="color: red; font-size: 14px;">{{errors.endDate}}</span>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="d-flex justify-content-between">
                                        <button type="button" class="btn btn-primary mb-0" (click)="generarUpdateToken(false)">
                                            <i class="fas fa-file-upload mr-2"></i>
                                            Generar nuevo
                                        </button>
                                        <button type="button" class="btn btn-primary mb-0" (click)="generarUpdateToken(true)" *ngIf="!showNewToken">
                                            <i class="fas fa-sync mr-2"></i>
                                            Actualizar vigencia
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br/><br/><br/>
            <div class="row">
                <div  class="col-md-12"><span style="font-weight: bold; font-size: 18px; ">Imagenes de la empresa</span></div> <br/><br/>
                <div class="v-col-12 v-col-md-4 v-col-lg-4 v-col-xl-3 v-col-xxl-2 mb-4" *ngFor="let item of imagenesEmpresa">
                    <label class="mb-1">Imagen {{ item.num }}</label>
                    <div *ngIf="item.urlImg == undefined || item.urlImg == null || item.urlImg == ''">
                        <abarcms-file-upload [singleFile]="true" typeFile="jpg,png,jpeg" [sizeFile]="5"
                            [contentID]="null" [callServiceInThisComponent]="true" (getFilesToUpload)="item.urlImg = $event.url"
                            (isSingleFiles)="true" [serviceCms]="true">
                        </abarcms-file-upload>
                    </div>
                    <div *ngIf="item.urlImg != undefined && item.urlImg != null && item.urlImg != ''" style="padding-top: 5px;padding-bottom: 10px; padding-left: auto; padding-right: auto;" align='center'>
                        <img [src]="item.urlImg" style="width: 155px;" alt="push-image">
                        <button type="button" class="btn btn-danger btn-block" (click)="item.urlImg = ''">
                            <i class="fas fa-trash-alt"></i>
                        </button>
                    </div>
                </div>
                
            </div>
            <br/><br/>
            <!--acciones a realizar-->
            <div class="col-12 accordion" id="accordionActions">
                <div class="v-card">
                    <div class="card-header" id="headingOne">
                        <h5 class="text-left d-flex justify-content-between mb-0 collapsed"
                            data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                            aria-controls="collapseOne" style="cursor: pointer"
                            (click)="accionesShow = !accionesShow">
                            Contenido de acciones a realizar de acuerdo a resultados
                            <i class="fas" [ngClass]="{'fa-chevron-up': !accionesShow, 'fa-chevron-down': accionesShow}"></i>
                        </h5>
                    </div>
                    
                    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionActions">
                        <div class="card-body">
                            <div class="col-12" >
                                <ngb-tabset [destroyOnHide]="false">
                                    <ngb-tab *ngFor="let item of acciones_realizar">
                                        <ng-template ngbTabTitle>Resultado {{ item.color_riesgo | titlecase }}
                                        </ng-template>
                                        <ng-template ngbTabContent>
                                            <div class="card-body" >
                                                <div class="row">
                                                    <div class="v-col-sm-6 v-col-md-6 v-col-xl-3">
                                                        <div class="form-group">
                                                            <label for="prev_telehone">Texto previo al teléfono en el Thank You Page</label>
                                                            <input type="text" name="prev_telehone" id="prev_telehone" class="form-control" [(ngModel)]="item.texto_telefono_1">
                                                        </div>
                                                    </div>
                                                    <div class="v-col-sm-6 v-col-md-6 v-col-xl-3">
                                                        <div class="form-group">
                                                            <label for="aft_telehone">Texto posterior al teléfono en el Thank You Page</label>
                                                            <input type="text" name="aft_telehone" id="aft_telehone" class="form-control" [(ngModel)]="item.texto_telefono_2">
                                                        </div>
                                                    </div>
                                                    <div class="v-col-sm-6 v-col-md-6 v-col-xl-3">
                                                        <div class="form-group">
                                                            <label for="prev_email">Texto previo al correo electrónico en el Thank You Page</label>
                                                            <input type="text" name="prev_email" id="prev_email" class="form-control" [(ngModel)]="item.texto_correo_1">
                                                        </div>
                                                    </div>
                                                    <div class="v-col-sm-6 v-col-md-6 v-col-xl-3">
                                                        <div class="form-group">
                                                            <label for="aft_email">Texto posterior al correo electrónico en el Thank You Page</label>
                                                            <input type="text" name="aft_email" id="aft_email" class="form-control" [(ngModel)]="item.texto_correo_2">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="v-col-12">
                                                        <label for="postPhoneAndMailContent">Contenido de recomendaciones posterior a teléfonos y correos</label>
                                                    </div>
                                                    <div class="v-col-sm-6 v-col-md-6 v-col-xl-4 v-col-xxl-3">
                                                        <div class="form-group" class="recomendation-content" >
                                                            <label for="postPhoneAndMailContent"> </label>
                                                            <textarea class="form-control" rows="10" cols="50" style="height: 100px;" placeholder="Contenido 1" [(ngModel)]='item.accion_1'></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="v-col-sm-6 v-col-md-6 v-col-xl-4  v-col-xxl-3">
                                                        <div class="form-group" class="recomendation-content" >
                                                            <label for="postPhoneAndMailContent"> </label>
                                                            <textarea class="form-control" rows="10" cols="50" style="height: 100px;" placeholder="Contenido 2" [(ngModel)]='item.accion_2'></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="v-col-sm-6 v-col-md-6 v-col-xl-4  v-col-xxl-3">
                                                        <div class="form-group" class="recomendation-content" >
                                                            <label for="postPhoneAndMailContent"> </label>
                                                            <textarea class="form-control" rows="10" cols="50" style="height: 100px;" placeholder="Contenido 3" [(ngModel)]='item.accion_3'></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="v-col-sm-6 v-col-md-6 v-col-xl-4  v-col-xxl-3">
                                                        <div class="form-group" class="recomendation-content" >
                                                            <label for="postPhoneAndMailContent"> </label>
                                                            <textarea class="form-control" rows="10" cols="50" style="height: 100px;" placeholder="Contenido 4" [(ngModel)]='item.accion_4'></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="v-col-sm-6 v-col-md-6 v-col-xl-4  v-col-xxl-3">
                                                        <div class="form-group" class="recomendation-content" >
                                                            <label for="postPhoneAndMailContent"> </label>
                                                            <textarea class="form-control" rows="10" cols="50" style="height: 100px;" placeholder="Contenido 5" [(ngModel)]='item.accion_5'></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngb-tab>
                                </ngb-tabset>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="botonera mb-4">
                    <button type="button" class="btn btn-dark btn-block mb-0"
                        (click)="getBackUrl()">Regresar</button>
                    <button type="button" class="btn btn-primary my-0 btn-block"  (click)="saveCompany();" >Guardar</button>
                </div>
            </div>
        </div>
    </div>
</div>
