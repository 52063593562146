import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SettingsService } from '../../settings.service';
import { ResponseData } from 'src/app/dto';
import { environment } from 'src/environments/environment';
import { ApiService } from '../../api.service';
import { CitasDataService } from '../citas-data.service';

@Injectable({
  providedIn: 'root'
})

export class CitasDataWsService implements CitasDataService {

    private pathInfo: any;
    private baseUrl: string;
    private segment: any;

    constructor(
        private settings: SettingsService,
        private api: ApiService
    ) {
        this.pathInfo = this.settings.get('pathInfo');
        this.segment  = this.pathInfo.res.admin.appointments;
        this.baseUrl  = this.pathInfo.base.baseUrl + this.pathInfo.base.prefix;
        if (!environment.production && this.segment.override) {
            this.baseUrl = this.segment.override.baseUrl + this.segment.override.prefix;
            this.segment.prefix = this.segment.prefix.replace(this.pathInfo.base.apiVersion, this.segment.override.apiVersion);
        }
    }

    public read(): Observable<ResponseData> {
        const endpoint = this.baseUrl + this.segment.prefix;
        return this.api.get( endpoint );
    }
    public readOne(menuUUID: any): Observable<ResponseData> {
        throw new Error('Method not implemented.');
    }
    public bulkCreate(body: any): Observable<ResponseData> {
        const endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.bulkCreate;
        return this.api.post( endpoint, body );
    }
    public bulkUpdate(body: any): Observable<ResponseData> {
        const endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.bulkUpdate;
        return this.api.put( endpoint, body );
    }
    public create(body: any): Observable<ResponseData> {
        const endpoint = this.baseUrl + this.segment.prefix;
        return this.api.post( endpoint, body );
    }
    public update(formUUID: any, body: any): Observable<ResponseData> {
        const endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.update.replace(/:id/g, formUUID);
        return this.api.put( endpoint, body );
    }
    public delete(formUUID: any): Observable<ResponseData> {
        throw new Error('Method not implemented.');
    }
}
