<router-outlet *ngIf="ready"></router-outlet>
<app-alert></app-alert>
<div *ngIf="!ready">
    Cargando configuración.
</div>
<abarcms-loader-full *ngIf="loading"></abarcms-loader-full>
<abarcms-modal [title]="errorModal.title" *ngIf="errorModal.show" (close)="errorModal.show = false"
    [btnRight]="'Aceptar'" (right)="errorModal.show = false">
    <ng-container *ngIf="settings.get('production')">
        {{errorModal.message}}
    </ng-container>
    <ng-container *ngIf="!settings.get('production')">
        <pre>{{errorModal.message | json}}</pre>
    </ng-container>
</abarcms-modal>