<div class="container-fluid" *ngIf="showComponent">
  <div class="card border-card">
    <div class="card-content">
      <div class="row mt-10">
        <div class="col-12">
          <h2>Configuración</h2>
        </div>
      </div>
      <form [formGroup]="form">
        <div class="row">
          <div class=" col-md-6 ">
            <div class="form-group card ">
              <div class="card-body">
                <label for="text" class=" col-form-label m-0">Leyenda</label>
                <div class="text">
                  <textarea name="text" class="form-control textarea" [style.height.px]="100" formControlName="text"
                    [ngStyle]="(f.text?.errors && (f.text.touched || f.text.dirty)) && {'border-color': 'red','margin-bottom': '3px'}"></textarea>
                  <div class=" invalidFieldForm" style="color: red;font-size: 13px;">
                    <div
                      *ngIf="(f.text?.errors?.required || f.text?.errors?.minlength) && (f.text.touched || f.text.dirty)">
                      Este campo es requerido
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card ">
              <div class="card-body">
                <div class="form-group">
                  <label class="title" for="estadoGrupo">Estado</label>
                  <select class="form-control" formControlName="active">
                    <option [value]="1">Activo</option>
                    <option [value]="0">Desactivado</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-12 col-xl-12">
            <div class="row">
              <div class="col-xs-12 col-md-6 col-lg-6 col-xl-6">
                <label for="" class="col-xs-12 col-md-12 col-lg-12 col-xl-12 col-form-label">Logo</label>

                <div *ngIf="!f.logo.value">
                  <abarcms-file-upload [singleFile]="true" typeFile="jpg,png,jpeg" [sizeFile]="5"
                    [files]="configData.logo.files" [contentID]="null" [callServiceInThisComponent]="true"
                    (getFilesToUpload)="onAction('logo', $event)" (isSingleFiles)="isSingleFile($event, 'logo')"
                    (disabledButton)="configData.buttonDisabled = $event"
                    [disabled]="(!permissions.edit && !permissions.create)">
                  </abarcms-file-upload>
                </div>
                <div *ngIf="f.logo.value && configData.logo.files[0].url" class="image_wrapper">
                  <img [src]="configData.logo.files[0].url" style="width: 305px;" alt="push-image">
                  <button type="button" class="btn btn-danger btn-block" [disabled]="!f.logo.value" (click)="resetPicture('logo')"
                    *ngIf="permissions.edit || permissions.create"><i class="fas fa-trash-alt"></i></button>
                </div>
              </div>

              <div class="col-xs-12 col-md-6 col-lg-6 col-xl-6">
                <label for="" class="col-xs-12 col-md-12 col-lg-12 col-xl-12 col-form-label">Imagen</label>
                <div *ngIf="!f.image.value">
                  <abarcms-file-upload [singleFile]="true" typeFile="jpg,png,jpeg" [sizeFile]="5"
                    [files]="configData.image.files" [contentID]="null" [callServiceInThisComponent]="true"
                    (getFilesToUpload)="onAction('image', $event)" (isSingleFiles)="isSingleFile($event, 'image')"
                    (disabledButton)="configData.buttonDisabled = $event"
                    [disabled]="(!permissions.edit && !permissions.create)">
                  </abarcms-file-upload>
                </div>
                <div *ngIf="f.image.value && configData.image.files[0].url" class="image_wrapper">
                  <img [src]="configData.image.files[0].url" style="width: 305px;" alt="push-image">
                  <button type="button" class="btn btn-danger btn-block" [disabled]="!f.image.value" (click)="resetPicture('image')"
                    *ngIf="permissions.edit || permissions.create"><i class="fas fa-trash-alt"></i></button>
                </div>
              </div>

            </div>
          </div>

          <div class="col-12 col-lg-8 col-xl-6">
            <div class="botonera mt-4">
              <button type="button" class="btn btn-dark  btn-block" routerLink="/dashboard">Cancelar</button>
              <button type="button" class="btn btn-primary my-0 btn-block" type="submit" (click)="save()"
                [disabled]="configData.buttonDisabled" *ngIf="permissions.create">Guardar</button>
            </div>
          </div>

        </div>
      </form>
    </div>
  </div>
</div>