import { Directive, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[googleSignOut]'
})
export class GoogleSignOutDirective {

  @Output('signOut')
  public signOutEE: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  @HostListener('click')
  signOut() {
    if (window['gapi_auth2'] && window['gapi_auth2'].isSignedIn.get()) {
      window['gapi_auth2'].signOut()
    }
    this.signOutEE.emit(true);
  }

}
