import { Component, OnInit } from '@angular/core';
import { DashboardWidget } from 'src/app/dto/DashboardWidget';
import { DashboardDataService } from 'src/app/services/data/dashboard-data.service';
import { SessionService } from 'src/app/services/session.service';
import { SettingsService } from 'src/app/services';
import { Router } from '@angular/router';
import { getData, getValid, getArray } from 'src/app/utils/_http';
import { getPermissions } from 'src/app/utils/_utils'
import { constants } from 'src/app/constants';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { Log } from 'src/app/dto';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  permissionPage = [];
  logsFound: Array<Log> = [];
  logs: Array<Log> = [];
  logsPage: Array<Log> = [];
  public dashboardWidgets: DashboardWidget[][][] = [];
  private interval: any = {
    timer: null,
    count: 0,
    flag: null
  }

  paginator: {
    current: number,
    perPage: number,
    total: number
  } = {
      current: 1,
      perPage: 10,
      total: 0,
    }
  init: boolean = true

  constructor(public dashboardDataService: DashboardDataService,
    private session: SessionService,
    private router: Router,
    public settings: SettingsService,
    private modalHelper: ModalHelperService
  ) {
    //this.initInterval();
    this.getTableData();
  }
  ngOnInit() { }

  getTableData(): void {
    this.dashboardDataService.read(this.session.accessToken.accessToken, this.paginator.current, this.paginator.perPage)
      .subscribe(
        (rs) => {
          // if (getValid(_rs, { title: `Error al obtener los grupos`, modalHelper: this.modalHelper, settings: this.settings })) {
          this.paginator.total = rs.headers.get('X-Total-Items');
          // this.dashboardDataService.readPaginator(this.session.accessToken.accessToken, this.page, this.totalItems).subscribe(resp =>{
          this.logsPage = getArray(rs);
          // this.logsFound = getArray(rs);
          this.modalHelper.changeLoading(false);
          // });
          // }
        }
      )
  }

  changePage($event: any) {
    this.paginator = $event
    if (this.init) {
      this.init = false
    }
    else {
      this.getTableData()
    }
  }

  dataFound($event: any) {
    this.logs = $event;
  }
  //loadDashboardWidgets() {
  //  this.dashboardDataService.getDashboardWidgets(this.session.user != null ? this.session.user.username : '', this.session.accessToken)
  //    .subscribe((data) => {
  //      data.map((i, ik) => {
  //        if (i.length > 1) {
  //          i.map((j, jk) => {
  //            j.map((k, kk) => {
  //              if (k.type == 'users') {
  //                if (this.permissionPage.includes(constants.permission.create) == false && this.permissionPage.includes(constants.permission.edit) == false) data[ik][jk].splice(kk, 1);
  //              }
  //            })
  //          });
  //        }
  //      });
  //      this.dashboardWidgets = data;
  //      this.modalHelper.changeLoading(false);
  //    });
  //}
  //initInterval() {
  //  this.interval.timer = setInterval(() => {
  //    this.interval.count++
  //    if (this.interval.flag || this.interval.count > 300) {
  //      clearTimeout(this.interval.timer);
  //      this.loadDashboardWidgets();
  //    } else {
  //      this.permissionPage = getPermissions(this.router.url);
  //      if (this.permissionPage.length) this.interval.flag = true;
  //    }
  //  }, 10);
  //}

  subString(text: string, positions: number): string {
    if (text.length > positions) {
      return text.substr(0, positions) + '...'
    }
    return text
  }
}
