import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServicesDataModule } from './services/data/services-data.module';
import localeEs from '@angular/common/locales/es-MX';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { OwlDateTimeModule, OwlNativeDateTimeModule, OwlDateTimeIntl, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { NgSelectModule } from '@ng-select/ng-select';
import { DatePipe, registerLocaleData } from '@angular/common';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';

import { AppComponent } from './app.component';
import { CmsAdminComponent } from './layout/cms-admin/cms-admin.component';
import { SignInComponent } from './screens/sign-in/sign-in.component';
import { AppRoutingModule } from './/app-routing.module';
import { CmsSideMenuComponent } from './nav/cms-side-menu/cms-side-menu.component';
import { CmsAuthedAdminComponent } from './layout/cms-authed-admin/cms-authed-admin.component';
import { DashboardComponent } from './screens/admin/dashboard/dashboard.component';
import { ProfileComponent } from './screens/admin/profile/profile.component';
import { RegisterComponent } from './screens/register/register.component';
import { PasswordRecoveryComponent } from './screens/password-recovery/password-recovery.component';
import { ModalComponent } from './views/modal/modal.component';
import { GoogleSignInDirective } from './directives/google-sign-in.directive';
import { LoaderFullComponent } from './views/loader-full/loader-full.component';
import { LoaderModalComponent } from './views/loader-modal/loader-modal.component';
import { LoaderIconComponent } from './views/loader-icon/loader-icon.component';
import { GoogleSignOutDirective } from './directives/google-sign-out.directive';
import { DashboardRowComponent } from './views/dashboard/dashboard-row/dashboard-row.component';
import { DashboardCardComponent } from './views/dashboard/dashboard-card/dashboard-card.component';
import { DashboardWidgetLogsComponent } from './views/dashboard/dashboard-widget-logs/dashboard-widget-logs.component';
import { DashboardWidgetUsersComponent } from './views/dashboard/dashboard-widget-users/dashboard-widget-users.component';
import { DashboardWidgetMetricsComponent } from './views/dashboard/dashboard-widget-metrics/dashboard-widget-metrics.component';
import { DashboardWidgetAssetsComponent } from './views/dashboard/dashboard-widget-assets/dashboard-widget-assets.component';
import { DragAndDropFileUploadDirective } from './directives/drag-and-drop-file-upload.directive';
import { ProgressComponent } from './views/progress/progress.component';
import { FileUploadComponent } from './views/file-upload/file-upload.component';
import { AlertComponent } from './views/alert/alert.component';
import { PaginatorComponent } from './views/paginator/paginator.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CodeVerifyComponent } from './screens/code-verify/code-verify.component';
import { MultiselectComponent } from './views/multiselect/multiselect.component';
import { UserListComponent } from './screens/admin/users/user-list/user-list.component';
import { InputSearchComponent } from './views/input-search/input-search.component';
import { RolesListComponent } from './screens/admin/roles/roles-list/roles.component';
import { RolesActionComponent } from './screens/admin/roles/roles-action/roles-action.component';
import { pipesDeclarations } from './pipes';
import { CKEditorModule } from 'ckeditor4-angular';
import { JoinArrayPipe } from './pipes/join-array.pipe';
import { EndpointPaginatorComponent } from './views/endpoint-paginator/endpoint-paginator.component';
import { AddPasswordComponent } from './screens/add-password/add-password.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTreeModule } from '@angular/material/tree';
import { ErrorInterceptor } from './services/error.interceptor';
import { NotFoundComponent } from './screens/not-found/not-found.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { FilesPipe } from './pipes/files.pipe';
import { GroupFilterComponent } from './views/group-filter/group-filter.component';
import { GroupDetailListComponent } from './views/group-detail-list/group-detail-list.component';
import { PasswordRecoveryModalComponent } from './screens/password-recovery/modals/password-recovery-modal/password-recovery-modal.component';
import { GroupProfileComponent } from './screens/admin/grups/group-profile/group-profile.component';
import { GroupListComponent } from './screens/admin/grups/group-list/group-list.component';
import { CompanyProfileComponent } from './screens/admin/grups/company-profile/company-profile.component';
import { PushNotificationsListComponent } from './screens/admin/push-notifications/push-notifications-list/push-notifications-list.component';
import { EstatusPipe } from './pipes/estatus.pipe';
import { ColorContentPipe } from './pipes/color-by-content.pipe';
import { PollsListComponent } from './screens/admin/polls/polls-list/polls-list.component';
import { PushNotificationsActionComponent } from './screens/admin/push-notifications/push-notifications-action/push-notifications-action.component';
import { PushAvisosActionComponent } from './screens/admin/push-avisos/push-avisos-action/push-avisos-action.component';
//import { PushAvisosListComponent } from './screens/admin/push-avisos/push-avisos-list/push-avisos-list.component'
import { CompanyListComponent } from './screens/admin/company/company-list/company-list.component';
import { CompanyComponent } from './screens/admin/company/company/company.component';


import { PollsActionComponent } from './screens/admin/polls/polls-action/polls-action.component';
import { FrecuencyFormatPipe } from './pipes/frecuency-format.pipe';
import { QuestionsListComponent } from './screens/admin/polls/questions-list/questions-list.component';
import { QuestionsActionComponent } from './screens/admin/polls/questions-action/questions-action.component';
import { ConfigurationComponent } from './screens/admin/configuration/configuration.component';
import { CreateTemplateComponent } from './screens/admin/templates/create-template/create-template/create-template.component';
import { ApplyTemplateComponent } from './screens/admin/templates/apply-template/apply-template/apply-template.component';
import { PollsSectionComponent } from './screens/admin/polls/polls-section/polls-section.component';
import { PollsGroupsComponent } from './screens/admin/polls/polls-groups/polls-groups.component';
import { PollsQuestionsComponent } from './screens/admin/polls/polls-questions/polls-questions.component';
import { PollQuestionComponent } from './screens/admin/polls/polls-questions/poll-question/poll-question.component';
import { PollQuestionTypeComponent } from './screens/admin/polls/polls-questions/poll-question-type/poll-question-type.component';
import { DropdownGroupsBusinessComponent } from './views/dropdown-groups-business/dropdown-groups-business.component';
import { GifloadComponent } from './screens/admin/gifload/gifload.component';
import { DefaultIntl } from './utils/_utils';
import { ListTemplateComponent } from './screens/admin/templates/list-template/list-template/list-template.component';
import { GroupsBusinessTemplateComponent } from './views/groups-business-template/groups-business-template.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SuccessComponent } from './views/success/success.component';
import { ConfirmDeleteComponent } from './views/confirm-delete/confirm-delete.component';
import { ConfirmComponent } from './views/confirm/confirm.component';
import {MatDialogModule} from '@angular/material/dialog';

import {MatButtonModule} from '@angular/material/button';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';

import { CrearPaginaComponent } from './screens/admin/seccion-monitor/components/crear-pagina/crear-pagina.component';
import { VerPaginaComponent } from './screens/admin/seccion-monitor/components/ver-pagina/ver-pagina.component';
import { ViewResultsComponent } from './screens/admin/seccion-monitor/components/view-results/view-results.component';
import { ViewPaginaResultsComponent } from './screens/admin/seccion-monitor/components/view-pagina-results/view-pagina-results.component';
import { ElementsOrderComponent } from './screens/admin/seccion-monitor/components/elements-order/elements-order.component';
import { OrderPageComponent } from './screens/admin/seccion-monitor/components/order-page/order-page.component';

import * as seccionesComponents from '../app/screens/admin/seccion-monitor/components';
import {MatIconModule} from '@angular/material/icon';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { EditorModule } from '@tinymce/tinymce-angular';

// registerLocaleData(localeEs, 'es-MX');

@NgModule({
  declarations: [...seccionesComponents.components,
    // pipes
    pipesDeclarations,

    // components
    CrearPaginaComponent,
    VerPaginaComponent,
    ViewResultsComponent,
    ViewPaginaResultsComponent,
    AppComponent,
    CmsAdminComponent,
    SignInComponent,
    CmsSideMenuComponent,
    CmsAuthedAdminComponent,
    DashboardComponent,
    ProfileComponent,
    RegisterComponent,
    PasswordRecoveryComponent,
    ModalComponent,
    GoogleSignInDirective,
    LoaderFullComponent,
    LoaderModalComponent,
    LoaderIconComponent,
    GoogleSignOutDirective,
    DashboardRowComponent,
    DashboardCardComponent,
    DashboardWidgetLogsComponent,
    DashboardWidgetUsersComponent,
    DashboardWidgetMetricsComponent,
    DashboardWidgetAssetsComponent,
    DragAndDropFileUploadDirective,
    ProgressComponent,
    FileUploadComponent,
    AlertComponent,
    PaginatorComponent,
    CodeVerifyComponent,
    MultiselectComponent,
    UserListComponent,
    InputSearchComponent,
    RolesListComponent,
    RolesActionComponent,
    SuccessComponent,
    ConfirmDeleteComponent,
    ConfirmComponent,
    JoinArrayPipe,
    EndpointPaginatorComponent,
    AddPasswordComponent,
    AddPasswordComponent,
    NotFoundComponent,
    PasswordRecoveryModalComponent,
    GroupFilterComponent,
    GroupDetailListComponent,
    PushNotificationsListComponent,
    EstatusPipe,
    GroupProfileComponent,
    GroupListComponent,
    CompanyProfileComponent,
    ColorContentPipe,
    PollsListComponent,
    PushNotificationsActionComponent,
    PushAvisosActionComponent,
    //PushAvisosListComponent,
    CompanyListComponent,
    CompanyComponent,
    PollsActionComponent,
    FrecuencyFormatPipe,
    QuestionsListComponent,
    QuestionsActionComponent,
    ConfigurationComponent,
    CreateTemplateComponent,
    ApplyTemplateComponent,
    // -- Encuestas
    PollsSectionComponent,
    PollsGroupsComponent,
    PollsQuestionsComponent,
    PollQuestionComponent,
    PollQuestionTypeComponent,
    DropdownGroupsBusinessComponent,
    GifloadComponent,
    // --
    NumbersOnlyDirective,
    ListTemplateComponent,
    GroupsBusinessTemplateComponent,
    ElementsOrderComponent,
    OrderPageComponent
    
  ],
  imports: [
    EditorModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ServicesDataModule,
    ReactiveFormsModule,
    MatTreeModule,
    DragDropModule,
    CKEditorModule,
    BrowserAnimationsModule,
    AutocompleteLibModule,
    NgbModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgSelectModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatButtonModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatRadioModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule,
    MatAutocompleteModule,
    MatCardModule

    
  ],
  providers: [
    pipesDeclarations,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    FilesPipe,
    DatePipe,
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'es-MX' },
    { provide: OwlDateTimeIntl, useClass: DefaultIntl },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }