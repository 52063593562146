import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '../../settings.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { ResponseData } from 'src/app/dto/ResponseData';
import { makeHeaders, createResponseData } from 'src/app/utils/_http';
import { ApiService } from '../../api.service';

@Injectable({
  providedIn: 'root'
})
export class ModuleDataWsService {
  private pathInfo: any;
  private baseUrl: string;
  private segment: any;
  constructor(
    private settings: SettingsService,
    private api: ApiService
  ) {
    this.pathInfo = this.settings.get('pathInfo');    
    this.baseUrl = this.pathInfo.base.baseUrl + this.pathInfo.base.prefix;
    this.segment = this.pathInfo.res.admin['modules'];
  }
  private read(accessToken: string): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix;
    return this.api.get(endpoint);
  }
  private readOne(uuid: string, accessToken: string): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources + this.segment.resources.getOne.replace(':uuid', uuid);
    return this.api.get(endpoint);
  }
}
