import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { constants } from "src/app/constants";
import { AlertNotificationService, SettingsService } from "src/app/services";
import { ModalHelperService } from "src/app/services/modal-helper.service";
import { labels } from "src/app/strings";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PollDataService } from "src/app/services/data/poll-data.service";
import { DatePipe, DOCUMENT } from "@angular/common";
import { CompanyService } from "src/app/screens/admin/seccion-monitor/services/company.service";
import { Subscription } from "rxjs";
import { ConfirmDeleteComponent } from "src/app/views/confirm-delete/confirm-delete.component";
import { Location } from "@angular/common";
import { error } from "console";

interface LocationHistory {
  previousUrl: string | null;
  currentUrl: string | null;
}

@Component({
  selector: "app-company",
  templateUrl: "./company.component.html",
  styleUrls: ["./company.component.css"],
  providers: [DatePipe],
})
export class CompanyComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  loading: boolean = false;
  pageAction: any = {
    action: null,
    uuid: null,
    titlePage: null,
  };
  banderaErrores: boolean = false;
  company: any = {};
  empresaId: any = null;
  encuestas: Array<any>;
  grupos: Array<any>;
  errors: any = {};
  paises: Array<any>;
  tabHistoricTokens: string = "historic";
  tabToken: string = "token";
  tabSelected: string = this.tabToken;
  showNewToken = true;

  tokens: Array<any>;
  token: string = "";
  startDate = null;
  endDate = null;
  imagenesEmpresa: Array<any> = [
    { num: 1, urlImg: "" },
    { num: 2, urlImg: "" },
    { num: 3, urlImg: "" },
    { num: 4, urlImg: "" },
    { num: 5, urlImg: "" },
  ];

  acciones_realizar: Array<any> = [
    {
      color_riesgo: "azul",
      accion_1: "",
      accion_2: "",
      accion_3: "",
      accion_4: "",
      accion_5: "",
      texto_telefono_1: "",
      texto_telefono_2: "",
      texto_correo_1: "",
      texto_correo_2: "",
      estatus: true,
    },
    {
      color_riesgo: "verde",
      accion_1: "",
      accion_2: "",
      accion_3: "",
      accion_4: "",
      accion_5: "",
      texto_telefono_1: "",
      texto_telefono_2: "",
      texto_correo_1: "",
      texto_correo_2: "",
      estatus: true,
    },
    {
      color_riesgo: "amarillo",
      accion_1: "",
      accion_2: "",
      accion_3: "",
      accion_4: "",
      accion_5: "",
      texto_telefono_1: "",
      texto_telefono_2: "",
      texto_correo_1: "",
      texto_correo_2: "",
      estatus: true,
    },
    {
      color_riesgo: "rojo",
      accion_1: "",
      accion_2: "",
      accion_3: "",
      accion_4: "",
      accion_5: "",
      texto_telefono_1: "",
      texto_telefono_2: "",
      texto_correo_1: "",
      texto_correo_2: "",
      estatus: true,
    },
  ];
  accionesShow: boolean = false;
  calendar = {
    minStartdate: null,
    minEnddate: null,
    startDate: null,
    endDate: null,
  };
  /*listaToken:any = []*/
  /*media: any = {
        filesToUpload: [],
        files: [],
        isSingleFiles: null,
        mediaChange: false,
    }*/

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private modalHelper: ModalHelperService,
    public settings: SettingsService,
    public pollDataService: PollDataService,
    protected alertNotificationService: AlertNotificationService,
    private modalSrv: NgbModal,
    private datePipe: DatePipe,
    private companyService: CompanyService,
    private location: Location
  ) {}

  getBackUrl() {
    this.location.back();
  }

  ngOnInit(): void {
    this.cargaInicial();
    this.getListCountrys();
    this.getListEncuestas();
    this.getListGroups();
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  cargaInicial(): void {
    const urlParams = this.router.url.split("/");
    const action = this.router.url.split("/").pop();
    let empresaId = null;
    let groupId = null;
    let update = false;
    if (action == constants.screens.empresas.empresa_edicion) {
      empresaId = urlParams[2] ?? null;
      update = true;
    } else if (action == constants.screens.empresas.editByGroup) {
      empresaId = urlParams[2] ?? null;
      groupId = urlParams[3] ?? null;
      update = true;
    } else if (action == constants.screens.empresas.empresa_nueva) {
      groupId = urlParams[3] ?? null;
    }

    this.pageAction = {
      action:
        constants.screens.empresas[this.router.url.split("/").pop()] ?? null,
      empresaId: empresaId ?? null,
      groupId: groupId ?? null,
      groupUuid: groupId ?? null,
      businessUuid: empresaId ?? null,
      titlePage:
        action == constants.screens.empresas.empresa_edicion ||
        action == constants.screens.empresas.editByGroup
          ? labels.empresas.update.titlePage
          : action == constants.screens.empresas.empresa_nueva
          ? labels.empresas.create.titlePage
          : "",
    };
    this.company.contact_tracing = false;
    if (update) {
      //Actualiza registro
      this.getCompanyById(this.pageAction.empresaId);
    } else {
      //Es nuevo Registro
      if (this.pageAction.groupId != null) {
        this.company.id_grupo = parseInt(this.pageAction.groupId, 10);
      }
      this.token = this.generateId();
      this.startDate = this.minDate("startDate");
      this.endDate = this.minDate("endDate");
    }
  }
  async getCompanyById(empresaId) {
    this.loading = true;
    (await this.companyService.findByIdEmpresa(empresaId)).subscribe(
      (resp) => {
        this.llenaCompany(resp.body.company);
        this.modalHelper.changeLoading(false);
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        console.error("Ocurrio un error al obtener la empresa", error);
      }
    );
  }

  llenaCompany(company) {
    this.empresaId = company.company_id;
    this.company.id_grupo = company.group_id;
    this.company.id_pais = company.id_country;
    this.company.nombre = company.name;
    this.company.telefono = company.phone;
    this.company.correo = company.email;
    let posicion = 0;
    company.images.forEach((item) => {
      this.imagenesEmpresa[posicion].urlImg = item;
      this.imagenesEmpresa[posicion].num = posicion + 1;
      posicion++;
    });
    this.token = company.token;
    company.tokens.forEach((item) => {
      if (item.token == this.token) {
        this.startDate = this.convierteStringToDate(item.fecha_inicial);
        this.endDate = this.convierteStringToDate(item.token_vigencia);
      }
    });
    let tokenList = [];
    for (let i = company.tokens.length - 1; i >= 0; i--) {
      tokenList.push(company.tokens[i]);
    }
    this.tokens = tokenList;
    this.company.token = company.tokens;
    this.company.contact_tracing = company.contactTracing;
    //acciones_realizar
    this.acciones_realizar = company.acciones_realizar;
    this.company.id_encuesta_no_mostrar = company.encuestas_no_mostrar;

    this.showNewToken = false;
    this.tabSelected = this.tabHistoricTokens;
  }

  async saveCompany() {
    if (this.validaFormulario()) {
      this.alertNotificationService.error("Aviso", "Faltan campos por llenar", {
        autoClose: true,
        keepAfterRouteChange: true,
      });
      return;
    }
    this.company.id_encuesta_no_mostrar =
      this.company.id_encuesta_no_mostrar == undefined
        ? []
        : this.company.id_encuesta_no_mostrar;
    this.company.estatus = true;
    this.company.acciones_realizar = this.acciones_realizar;
    this.company.imagen_1 = this.imagenesEmpresa[0].urlImg;
    this.company.imagen_2 = this.imagenesEmpresa[1].urlImg;
    this.company.imagen_3 = this.imagenesEmpresa[2].urlImg;
    this.company.imagen_4 = this.imagenesEmpresa[3].urlImg;
    this.company.imagen_5 = this.imagenesEmpresa[4].urlImg;
    this.minDate("finishStartDate");
    this.minDate("finishEndDate");
    this.company.token = {
      token: this.token,
      fecha_inicial: this.startDate,
      fecha_vigencia: this.endDate,
      estatus: true,
    };
    this.loading = true;
    (await this.companyService.save(this.empresaId, this.company)).subscribe(
      (resp) => {
        this.alertNotificationService.info(
          "Aviso",
          "El registro se guardo con éxito",
          { autoClose: true, keepAfterRouteChange: true }
        );
        setTimeout(() => {
          if (this.pageAction.groupId) {
            this.router.navigate([
              "/groups-business/" + this.pageAction.groupId + "/listCompany",
            ]);
          } else {
            this.router.navigate(["/empresas"]);
          }
          this.loading = false;
        }, 2000);
      },
      (error) => {
        this.erroresServicios(error);
        console.error("Ocurrio un error al guardar la empresa", error);
      }
    );
  }

  async getListGroups() {
    (await this.companyService.allGroups()).subscribe(
      (resp) => {
        this.grupos = resp.body.groups;
      },
      (error) => {
        console.error("Ocurrio un error al obtener los grupos", error);
      }
    );
  }

  async getListCountrys() {
    (await this.companyService.allPaises()).subscribe(
      (resp) => {
        this.paises = resp.body.data;
      },
      (error) => {
        console.error("Ocurrio un error al obtener los grupos", error);
      }
    );
  }

  async getListEncuestas() {
    (await this.companyService.allEncuestas()).subscribe(
      (resp) => {
        this.encuestas = resp.body.data;
        this.encuestas.forEach((element) => {
          element.disabled = !element.status;
        });
      },
      (error) => {
        console.error("Ocurrio un error al obtener los grupos", error);
      }
    );
  }

  tabSelect(tab: string) {
    this.tabSelected = tab;
  }

  generateId() {
    var arr = new Uint8Array(25 / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, this.dec2hex).join("");
  }

  dec2hex(dec) {
    return dec.toString(16).padStart(2, "0");
  }

  minDate(type: string): void | any {
    let date;
    switch (type) {
      case "startDate":
        if (!this.calendar.minStartdate) {
          this.calendar.minStartdate = new Date();
        }
        return this.calendar.minStartdate;
      case "endDateMin":
        date = new Date(this.startDate);
        date.setMinutes(date.getMinutes() + 1);
        return date;
      case "endDate":
        date = new Date(this.startDate);
        date.setMonth(date.getMonth() + 12);
        return date;
      case "change":
        this.endDate = "";
        break;
      case "finishStartDate":
        this.startDate = this.datePipe.transform(
          this.startDate,
          "yyyy-MM-ddTHH:mm:ss"
        );
        break;
      case "finishEndDate":
        this.endDate = this.datePipe.transform(
          this.endDate,
          "yyyy-MM-ddTHH:mm:ss"
        );
        break;
    }
  }

  async generarUpdateToken(actualizaVigencia: boolean) {
    let token = {
      id_empresas: [this.empresaId],
      fecha_inicial: this.startDate,
      fecha_vigencia: this.endDate,
      token_nuevo: false,
    };

    if (actualizaVigencia) {
      this.loading = true;
      this.minDate("finishStartDate");
      this.minDate("finishEndDate");
      (await this.companyService.refreshToken(token)).subscribe(
        (resp) => {
          this.getCompanyById(this.empresaId);
          let ejecuta = setInterval(() => {
            if (!this.loading) {
              this.alertNotificationService.info(
                "Aviso",
                "La vigencia se actualizo con éxito.",
                { autoClose: true, keepAfterRouteChange: true }
              );
              clearInterval(ejecuta);
            }
          }, 500);
        },
        (error) => {
          this.erroresServicios(error);
          console.error("Ocurrio un error al actualizar la vigencia", error);
        }
      );
    } else {
      if (this.empresaId) {
        this.loading = true;
        this.minDate("finishStartDate");
        this.minDate("finishEndDate");
        token.token_nuevo = true;
        (await this.companyService.refreshToken(token)).subscribe(
          (resp) => {
            this.getCompanyById(this.empresaId);
            let ejecuta = setInterval(() => {
              if (!this.loading) {
                this.alertNotificationService.info(
                  "Aviso",
                  "Se registro un nuevo token.",
                  { autoClose: true, keepAfterRouteChange: true }
                );
                clearInterval(ejecuta);
              }
            }, 500);
          },
          (error) => {
            this.erroresServicios(error);
            console.error("Ocurrio un error al actualizar la vigencia", error);
          }
        );
      } else this.token = this.generateId();
    }
  }

  actionItem(action: string, elementType: string, elementId: string) {
    if (elementType === "group") {
      if (action === "edit") {
        this.router.navigate(["/groups-business/" + elementId + "/edit"]);
      }
    }
    if (elementType === "registration-form") {
      if (action === "back") {
        let data = this.pageAction["groupUuid"].split(",");
        this.router.navigate(["/groups-business/" + data[0] + "/edit"]);
      }
    }
    if (elementType === "token") {
      let tokenBody = {
        uuidbusiness: elementId,
        uuidgroup: this.pageAction["groupUuid"],
        action: "",
      };
      if (action === "generate-new") {
        tokenBody["action"] = "refresh";
      } else {
        tokenBody["action"] = "send";
      }
    }
    if (elementType === "user") {
      if (action === "edit") {
        this.router.navigate([
          "/" +
            constants.screens.users.path +
            "/" +
            elementId +
            "/" +
            constants.screens.users.edit,
        ]);
      } else {
        let deleteModal = this.modalSrv.open(ConfirmDeleteComponent);
        deleteModal.componentInstance.title = "Borar grupo";
        deleteModal.componentInstance.message =
          labels.users.delete.confirmMessage;
        deleteModal.result
          .then((_response) => {
            if (_response === "CONFIRMED") {
              this.cargaInicial();
            }
          })
          .catch((res) => {});
      }
    }
  }

  validaFormulario() {
    this.banderaErrores = false;
    if (this.validaNombre()) {
      this.banderaErrores = true;
    }
    if (this.validaPais()) {
      this.banderaErrores = true;
    }
    if (this.validaTelefono()) {
      this.banderaErrores = true;
    }
    if (this.validaCorreo()) {
      this.banderaErrores = true;
    }
    if (this.validaGrupo()) {
      this.banderaErrores = true;
    }
    if (this.validaFechaInicio()) {
      this.banderaErrores = true;
    }
    if (this.validaFechaVigencia()) {
      this.banderaErrores = true;
    }
    return this.banderaErrores;
  }

  validaError(error) {
    if (
      error != undefined &&
      error != null &&
      error != "" &&
      error != "undefined"
    ) {
      return true;
    }
    return false;
  }

  validaGrupo() {
    this.errors.id_grupo = "";
    if (!this.validaError(this.company.id_grupo)) {
      this.errors.id_grupo = "Este campo es requerido.";
      return true;
    }
    return false;
  }

  validaNombre() {
    this.errors.nombre = "";
    if (!this.validaError(this.company.nombre)) {
      this.errors.nombre = "Este campo es requerido.";
      return true;
    }
    return false;
  }

  validaFechaInicio() {
    this.errors.startDate = "";
    if (!this.validaError(this.startDate)) {
      this.errors.startDate = "Este campo es requerido.";
      return true;
    }
    return false;
  }

  validaFechaVigencia() {
    this.errors.endDate = "";
    if (!this.validaError(this.endDate)) {
      this.errors.endDate = "Este campo es requerido.";
      return true;
    }
    return false;
  }

  validaPais() {
    this.errors.id_pais = "";
    if (!this.validaError(this.company.id_pais)) {
      this.errors.id_pais = "Este campo es requerido.";
      return true;
    }
    return false;
  }

  validaTelefono() {
    this.errors.telefono = "";
    let matchNumero = /^[0-9]{10}$/g;
    if (!this.validaError(this.company.telefono)) {
      this.errors.telefono = "Este campo es requerido.";
      return true;
    } else if (!this.company.telefono.match(matchNumero)) {
      this.errors.telefono =
        "Ingresa un teléfono válido, debe contener solo números.";
      return true;
    }
    return false;
  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items) => {
      items.forEach((element) => {
        element["selectedAllGroup"] = "selectedAllGroup";
      });
    };
    allSelect(items);
  }

  validaCorreo() {
    let matchCorreo = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    this.errors.correo = "";
    if (!this.validaError(this.company.correo)) {
      this.errors.correo = "Este campo es requerido.";
      return true;
    } else if (!this.company.correo.match(matchCorreo)) {
      this.errors.correo = "Ingresa un correo electrónico válido.";
      return true;
    }
    return false;
  }

  convierteStringToDate(initialDate) {
    //YYY-mm-dd hh:mm:ss
    var splitDate = initialDate.split(" ");
    var date = splitDate[0].split("-");
    var time = splitDate[1].split(":");

    // Obtenemos los campos individuales para todas las partes de la fecha
    var yyyy = date[0];
    var mm = date[1] - 1;
    var dd = date[2];
    var hh = time[0];
    var min = time[1];
    var ss = time[2];

    // Creamos la fecha con Javascript
    return new Date(yyyy, mm, dd, hh, min, ss);
  }
  erroresServicios(error) {
    if (
      error.error.mensajeError != undefined &&
      error.error.mensajeError != null &&
      error.error.mensajeError != ""
    ) {
      this.alertNotificationService.error("Error", error.error.mensajeError, {
        autoClose: true,
        keepAfterRouteChange: true,
      });
    } else if (error.error.listMensajeError) {
      error.error.listMensajeError.forEach((element) => {
        this.alertNotificationService.error("Error", element.mensajeError, {
          autoClose: true,
          keepAfterRouteChange: true,
        });
      });
    } else {
      this.alertNotificationService.error(
        "Error",
        "Ocurrio un error en el servidor.",
        { autoClose: true, keepAfterRouteChange: true }
      );
    }
    this.loading = false;
  }
}
