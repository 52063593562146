import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { SessionDataService } from 'src/app/services/data/session-data.service';
import { SessionService } from 'src/app/services/session.service';
import { UserDataService } from 'src/app/services/data/user-data.service';
import { BasicUserInfo } from 'src/app/dto/BasicUserInfo';
import { constants } from 'src/app/constants';
import { getValid } from 'src/app/utils/_http';
import { setStorage, removeStorage } from 'src/app/utils/_utils';
import { ModalHelperService } from 'src/app/services/modal-helper.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  public forma: FormGroup;
  public data: any = {};
  constructor(
    private router: Router,
    public settings: SettingsService,
    public sessionDataService: SessionDataService,
    private session: SessionService,
    public userDataService: UserDataService,
    private fb: FormBuilder,
    private modalHelper: ModalHelperService
  ) {
    this.createForm();
    removeStorage([constants.ls.passwordRecovery, constants.ls.codeVerify]);
  }
  ngOnInit() {
    this.modalHelper.changeLoading(false);
  }
  get validate(): any {
    return this.settings.get('validate');
  }
  get validName() {
    return this.forma.get('name').invalid && this.forma.get('name').touched;
  }
  get validLastname() {
    return this.forma.get('lastname').invalid && this.forma.get('lastname').touched;
  }
  get validUsername() {
    return this.forma.get('username').invalid && this.forma.get('username').touched;
  }
  get validEmail() {
    return this.forma.get('email').invalid && this.forma.get('email').touched;
  }
  createForm() {
    this.forma = this.fb.group({
      name: ['', [Validators.required, Validators.pattern(this.validate.name)]],
      lastname: ['', [Validators.required, Validators.pattern(this.validate.lastname)]],
      username: ['', [Validators.required, Validators.pattern(this.validate.username)]],
      email: ['', [Validators.required, Validators.pattern(this.validate.email)]],
    });
  }
  onGoBack() {
    this.onGoHome();
  }
  onGoHome() {
    this.router.navigate(['/']);
  }
  onRegister() {
    // tslint:disable-next-line
    if (this.forma.invalid) {
      return Object.values(this.forma.controls).forEach(control => {
        control.markAsTouched();
      });
    }
    this.data = { ...this.forma.value };
    const body = new BasicUserInfo();
    body.name = this.data.name;
    body.lastname = this.data.lastname;
    body.username = this.data.username;
    body.email = this.data.email;
    this.userDataService.createUser(body, null)
      .subscribe((rs) => {
        if (getValid(rs, { title: `Error al crear el usuario`, modalHelper: this.modalHelper, settings: this.settings })) {
          const data = {
            action: constants.screens.register.path,
            data: body
          };
          setStorage(constants.ls.codeVerify, JSON.stringify(data));
          this.router.navigate([constants.screens.register.path, constants.screens.register.code]);
        }
      });
  }
}
