import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseData } from 'src/app/dto';

@Injectable({
  providedIn: 'root'
})
export abstract class CitasDataService {
  abstract read(): Observable<ResponseData>;
  abstract readOne(citaId: any): Observable<ResponseData>;
  abstract create(body: any): Observable<ResponseData>;
  abstract update(citaId: any, body: any): Observable<ResponseData>;
  abstract delete(citaId: any): Observable<ResponseData>;
}
