import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getArray, getHeader, getObject } from 'src/app/utils/_http';
import { constants } from 'src/app/constants';
import { ResponseData } from 'src/app/dto/ResponseData';
import { clone, existStorage, getPermissionsPage, getStorage, setStorage } from 'src/app/utils/_utils';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PollDataService } from 'src/app/services/data/poll-data.service';
import { Poll, PollQuestion } from 'src/app/dto/Poll';
import { Subscription } from 'rxjs';
import { AlertNotificationService } from 'src/app/services';
import { v1 as uuidv1 } from 'uuid'
import { DatePipe } from '@angular/common';
import { EmailDataService } from 'src/app/services/data/email-data.servcie';

@Component({
  selector: 'app-polls-list',
  templateUrl: './polls-list.component.html',
  styleUrls: ['./polls-list.component.css']
})
export class PollsListComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription
  permissions: any = {}
  deleteModal = { show: false, item: null, type: '' }
  constants = constants
  polls: Poll[] = []
  pollsList: Poll[] = []
  pollsPage: Poll[] = []
  _selectedAllItems: boolean = false;
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };

  searchText: string = ''
  paginator: {
    current: number,
    perPage: number,
    total: number
  } = {
      current: 1,
      perPage: 10,
      total: 0,
    }
  dataTable: any = {
    size: 100,
    showTable: false
  }

  batchPoll: string = 'activar'
  batchPollItems: string[] = []
  pollSelected: any[] = []

  constructor(
    private router: Router,
    private pollService: PollDataService,
    private alertNotificationService: AlertNotificationService,
    private datePipe: DatePipe,
    private emailService: EmailDataService,
  ) { }

  ngOnInit(): void {
    this.permissions = getPermissionsPage(this.router.url)
    this.loginMonitor();
    this.getPolls();
  }
  // --
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
  // --
  getPolls(perPage?: any, current?: any, total?: any): void {
    this.polls = []
    this.pollsPage = []
    this.pollsList = []
    this.dataTable.showTable = false
    this.batchPollItems = []
    this.pollSelected = []
    this._selectedAllItems = false
    // --
    let paginator = clone(this.paginator)
    paginator.perPage = perPage ? perPage : 1
    paginator.current = current ? current : paginator.current
    this.subscription.add(
      this.pollService.read(paginator)
        .subscribe(
          (rs) => {
            if (perPage) {
              getArray(rs).map((x, i) => {
                this.polls.push(x)
                this.pollsPage.push(x)
                if (i == getArray(rs).length - 1) {
                  if (current < total) {
                    current++
                    this.getPolls(this.dataTable.size, current, total)
                  }
                }
              })
              this.dataTable.showTable = true
            }
            else {
              this.paginator.total = getHeader(rs, 'X-Total-Items')
              let total = this.paginator.total / this.dataTable.size
              let page = Math.trunc(total)
              page += total - page > 0 ? 1 : 0
              this.getPolls(this.dataTable.size, 1, page)
            }
          }
        )
    )
  }
  // --
  dataFound(event) {
    this.pollsPage = event
  }
  // --
  changePage(event: any) {
    this.pollsList = event;
  }
  // --
  actionItem(actionName: string, item: any = null) {
    if (actionName == 'delete') {
      this.deleteModal.show = true;
      this.deleteModal.item = item;
      this.deleteModal.type = 'eliminar';
    }
    else if (actionName == 'copy') {
      this.copyPoll(item);
    }
    else if (item) this.router.navigate([constants.screens.polls.path, item, constants.screens.polls[actionName]]);
    else this.router.navigate([constants.screens.polls.path, constants.screens.polls[actionName]]);
  }
  // --
  selectedAllItems() {
    this._selectedAllItems = !this._selectedAllItems
    this.batchPollItems = []
    if (this._selectedAllItems) {
      this.polls.map((x: any) => {
        this.batchPollItems.push(x.uuid)
        this.pollSelected.push(x)
      })
    }
  }
  selectedItem(item: any) {
    let index = this.batchPollItems.indexOf(item.uuid)
    if (index != -1) {
      this.batchPollItems.splice(index, 1)
      this.pollSelected.splice(index, 1)
    }
    else {
      this.batchPollItems.push(item.uuid)
      this.pollSelected.push(item)
    }
  }
  // --
  isChecked(item?: any): boolean {
    let value = false
    if (item) {
      let index = this.batchPollItems.indexOf(item.uuid)
      if (index != -1) {
        value = true
      }
    }
    else {
      if (this.batchPollItems.length == this.polls.length) {
        this._selectedAllItems = true
        value = true
      }
      else {
        this._selectedAllItems = false
      }
    }
    return value
  }
  // --
  deleteItem(data: any = null) {
    this.deleteModal.show = false
    if (data) {
      this.pollSelected[data.item]
      this.existPollMonitor()
      // let body = [
      //   {
      //     uuid: data.item.uuid,
      //     action: data.type
      //   }
      // ]
      // this.pollService.batch(body)
      //   .subscribe(
      //     rs => {
      //       if (getObject(rs)) {
      //         this.getPolls()
      //         this.alertNotificationService.success('Encuesta', 'Se eliminó la encuesta con éxito', { autoClose: false, keepAfterRouteChange: true });
      //       }
      //       else {
      //         this.alertNotificationService.warn('Encuesta', 'Ocurrio un error eliminando la encuesta, intente mas tarde', { autoClose: false, keepAfterRouteChange: true });
      //       }
      //     },
      //     error => {
      //       this.alertNotificationService.error('Encuesta', 'Ocurrio un error, intente más tarde', { autoClose: true, keepAfterRouteChange: true });
      //     }
      //   )
    }
    else {
      this.deleteModal.item = null
      this.pollSelected[data.item]
    }
  }
  // --
  copyPoll(poll: Poll) {
    this.subscription.add(
      this.pollService.readOne(poll.uuid)
        .subscribe(
          (rs: ResponseData) => {
            if (getObject(rs)) {
              let { uuid, ...fields } = getObject(rs)
              let pollClone = new Poll(fields)
              pollClone.systemname = this.systemnameCounterCopy(pollClone.systemname)
              pollClone.sections.map((xSection, iSection) => {
                xSection.uuid = uuidv1()
                xSection.polluuid = pollClone.uuid
                xSection.systemname = this.systemnameCounterCopy(xSection.systemname)
                this.copyPollQuestions(xSection.questions, xSection.uuid)
              })
            }
            else {
              this.alertNotificationService.warn('Encuesta', 'Ocurrio un error consultar la encuesta, intente mas tarde', { autoClose: false, keepAfterRouteChange: true });
              this.router.navigateByUrl('/polls')
            }
          },
          error => {
            this.alertNotificationService.error('Encuesta', 'Ocurrio un error, intente más tarde', { autoClose: true, keepAfterRouteChange: true });
            this.router.navigateByUrl('/polls')
          }
        )
    )
  }
  // --
  savePoll(poll: Poll) {
    this.subscription.add(
      this.pollService.create(poll)
        .subscribe(
          (rs: ResponseData) => {
            if (getObject(rs)) {
              this.paginator.current = 1
              this.getPolls()
              this.alertNotificationService.warn('Encuesta', 'La copia de la encuesta fue exitosa', { autoClose: false, keepAfterRouteChange: true });
            }
            else {
              this.alertNotificationService.warn('Encuesta', 'Ocurrio un error al realizar la copia, intente mas tarde', { autoClose: false, keepAfterRouteChange: true });
              this.router.navigateByUrl('/polls')
            }
          },
          error => {
            this.alertNotificationService.error('Encuesta', 'Ocurrio un error, intente más tarde', { autoClose: true, keepAfterRouteChange: true });
            this.router.navigateByUrl('/polls')
          }
        )
    )
  }
  // --
  onClick(action: string) {
    switch (action) {
      case 'batch':
        if (this.batchPollItems.length) {
          this.existPollMonitor()
        }
        else {
          this.alertNotificationService.warn('Encuestas', 'Debes seleccionar al menos una encuesta', { autoClose: false, keepAfterRouteChange: true });
        }
        break;
    }
  }
  // --
  systemnameCounterCopy(value: string): string {
    let system = value + ''
    if (system.indexOf('_copy') == -1) {
      system = system + '_copy'
    }
    else {
      let counter = system.split('_copy')
      if (counter.length > 1) {
        if (isNaN(Number(counter))) {
          system = system + '1'
        }
        else {
          system = system + `${Number(counter) + 1}`
        }
      }
      else {
        system = system + '1'
      }
    }
    return system
  }
  // --
  copyPollQuestions(question: PollQuestion[], parentuuid: string) {
    question.map(xQuestion => {
      xQuestion.uuid = uuidv1()
      xQuestion.parentuuid = parentuuid
      xQuestion.systemname = this.systemnameCounterCopy(xQuestion.systemname)
      xQuestion.choices.map(xChoice => {
        xChoice.uuid = uuidv1()
        xChoice.parentuuid = xQuestion.uuid
        this.copyPollQuestions(xChoice.nested, xChoice.uuid)
      })
    })
  }
  // --
  openBusiness(business) {
    this.router.navigate(['groups-business', business.data.uuidgroup, 'business', business.data.uuid, this.permissions.edit ? 'edit' : 'read']);
  }
  // --
  getBusiness(poll: any[]): any[] {
    let business = []
    poll.map(x => {
      x.business.map(y => {
        business.push(y)
      })
    })
    return business
  }
  // --
  saveBatch() {
    let body = []
    this.batchPollItems.map(x => {
      body.push(
        {
          uuid: x,
          action: this.batchPoll
        }
      )
    })
    // --
    let messageSuccess = 'Se activaron las encuestas con éxito'
    let messageWarning = 'Ocurrio un error activando las encuestas, intente mas tarde'
    let messageError = 'Ocurrio un error, intente más tarde'
    if (this.batchPoll == 'desactivar') {
      messageSuccess = 'Se desativaron las encuesta con éxito'
      messageWarning = 'Ocurrio un error desactivando las encuestas, intente mas tarde'
    }
    if (this.batchPoll == 'eliminar') {
      messageSuccess = 'Se eliminaron las encuestas con éxito'
      messageWarning = 'Ocurrio un error eliminando las encuestas, intente mas tarde'
    }
    // --
    this.subscription.add(
      this.pollService.batch(body)
        .subscribe(
          rs => {
            if (getObject(rs)) {
              this.getPolls()
              this.alertNotificationService.success('Encuestas', messageSuccess, { autoClose: false, keepAfterRouteChange: true });
            }
            else {
              this.alertNotificationService.warn('Encuestas', messageWarning, { autoClose: false, keepAfterRouteChange: true });
            }
          },
          error => {
            this.alertNotificationService.error('Encuestas', messageError, { autoClose: true, keepAfterRouteChange: true });
          }
        )
    )
  }
  // --
  monitor: any = {}
  loginMonitor() {
    if (existStorage(constants.ls.sessionMonitor)) {
      this.monitor = getStorage(constants.ls.sessionMonitor, true)
    }
    else {
      this.subscription.add(
        this.pollService.monitorLogin()
          .subscribe(
            rs => {
              this.monitor = getObject(rs)
              if (this.monitor) {
                setStorage(constants.ls.sessionMonitor, this.monitor)
              }
            }
          )
      )
    }
  }
  // --
  existMonitor(): boolean {
    if (this.monitor) {
      return this.monitor?.user_id && this.monitor?.token
    }
    return false
  }
  // --
  recursiveActionUpdate() {
    this.monitor.itemCurrent++
    if (this.monitor.itemCurrent < this.pollSelected.length) {
      this.existPollMonitor(this.monitor.itemCurrent, this.pollSelected[this.monitor.itemCurrent])
    }
    else {
      this.saveBatch()
    }
  }
  // --
  updatePollMonitor(form: any, id: number) {
    let encuesta: any = {
      name: form.title,
      system_name: form.systemname,
      description: form.description,
      status: this.batchPoll == 'activar' ? 1 : 0,
      startPublicationDate: !!form.schedule.startDate ? this.datePipe.transform(form.schedule.endDate, 'YYYY-MM-dd HH:mm:ss') : null,
      endPublicationDate: !!form.schedule.endDate ? this.datePipe.transform(form.schedule.endDate, 'YYYY-MM-dd HH:mm:ss') : null,
      id: id
    }
    this.subscription.add(
      this.pollService.monitorUpdatePoll(encuesta, this.monitor)
        .subscribe(
          (rs: ResponseData) => {
            this.updatePollCMS(form)
          },
          error => {
            console.error(error)
            this.alertNotificationService.error('Encuesta', 'Ocurrio un error al actualizar la encuesta en Monitor', { autoClose: true, keepAfterRouteChange: true });
          }
        )
    )
  }
  // --
  updatePollCMS(form) {
    form = { ...form, status: this.batchPoll == 'activar' ? 'publicado' : 'desactivado' }
    this.subscription.add(
      this.pollService.updateWithOutSection(form.uuid, form)
        .subscribe(
          (rs: ResponseData) => {
            this.alertNotificationService.success('Encuesta', 'Se actualizó la encuesta correctamente en el CMS', { autoClose: true, keepAfterRouteChange: true });
            this.sendEmail(form)
          },
          error => {
            console.error(error)
            this.alertNotificationService.error('Encuesta', 'Ocurrio un error al actualizar la encuesta en el CMS', { autoClose: true, keepAfterRouteChange: true });
          }
        )
    )
  }
  // --
  existPollMonitor(index?: number, poll?: any) {
    if (!isNaN(index)) {
      this.subscription.add(
        this.pollService.monitorListPoll(this.monitor, poll.systemname)
          .subscribe(
            rs => {
              let exist: any = getObject(rs).data
              if (exist.pollExist) {
                this.updatePollMonitor(poll, exist.idPoll)
              }
              else {
                this.recursiveActionUpdate()
              }
            },
            error => {
              console.error(error)
              this.alertNotificationService.error('Encuesta', 'Ocurrio un error al obtener la existencia de la encuesta en monitor', { autoClose: true, keepAfterRouteChange: true });
            }
          )
      )
    }
    else {
      this.monitor.itemCurrent = 0
      this.monitor.itemExist = 0
      this.existPollMonitor(this.monitor.itemCurrent, this.pollSelected[this.monitor.itemCurrent])
    }
  }
  // --
  sendEmail(form: any) {
    if (form.email) {
      let newStatus = {
        'publicado': 'Publicado',
        'desactivado': 'Desactivado',
      }
      if (newStatus[form.status]) {
        let body = {
          subject: 'CMS Monitor - Encuesta',
          to: form.email,
          plainTextContent: `¡Hola! Hemos detectado cambios en la encuesta ${form.title}`,
          htmlContent: `¡Hola! <br><br> La encuesta <b> ${form.title}</b> fue ${newStatus[form.status]}`,
        }
        this.subscription.add(
          this.emailService.sendEmail(body)
            .subscribe(
              rs => {
                this.recursiveActionUpdate()
              },
              err => {
                this.recursiveActionUpdate()
              }
            )
        )
      }
    }
    else {
      this.recursiveActionUpdate()
    }
  }
}
