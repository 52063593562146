import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseData } from 'src/app/dto/ResponseData';
import { SettingsService } from '../../settings.service';
import { makeHeaders } from 'src/app/utils/_http';
import { ApiService } from '../../api.service';
import { SessionService } from '../../session.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsDataWsService {
  private pathInfo: any;
  private baseUrl: string;
  private segment: any;
  private baseUrlMonitor: any;
  private segmentNotification: any;
  constructor(
    private settings: SettingsService,
    private api: ApiService,
    private session: SessionService
  ) {
    this.pathInfo = this.settings.get('pathInfo');
    this.baseUrl = this.pathInfo.base.baseUrl + this.pathInfo.base.prefix;
    this.segment = this.pathInfo.res.admin['notification'];
    this.baseUrlMonitor = this.pathInfo.base.monitor;
    this.segmentNotification = this.pathInfo.res.admin['monitor'];
  }
  private create(body: object, accessToken: string): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix;
    return this.api.post(endpoint, body, true, true);
  }
  private read(paginator: any): Observable<ResponseData> {
    let options = {
      'X-Auth-Token': this.session.accessToken.accessToken,
      'X-Current-Page': paginator.current,
      'X-Items-Page': paginator.perPage
    };
    let endpoint = this.baseUrl + this.segment.prefix;
    return this.api.get(endpoint, options);
  }
  private readOne(uuid: any, accessToken: string): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.getOne.replace(':uuid', uuid);
    return this.api.get(endpoint);
  }
  private update(uuid: any, body: object, accessToken: string): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.edit.replace(':uuid', uuid);
    return this.api.put(endpoint, body, true, true);
  }
  private delete(uuid: any, accessToken: string): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.delete.replace(':uuid', uuid);
    return this.api.delete(endpoint, uuid, true, true);
  }
  private readRules(): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.rules.prefix
    return this.api.get(endpoint);
  }
  private monitorLogin(): Observable<ResponseData> {
    let endpoint = this.baseUrlMonitor.url + this.segmentNotification.login
    return this.api.post(endpoint, this.baseUrlMonitor.credentials, false)
  }
  private monitorFilterUsers(data: any, body: any): Observable<ResponseData> {
    let endpoint = this.baseUrlMonitor.url + this.segmentNotification.filter_users
    endpoint = endpoint.replace('{id}', data.user_id)
    return this.api.post(endpoint, body, false, { Authorization: `Bearer ${data.token}` }, false)
  }
}
