<div class="container-fluid">
    <div class="card border-card">
        <div class="card-content">
            <!--Cabecera-->
            <div class="row mb-20">
                <div class="card__title col-xs-12 col-md-4 col-lg-4">
                    <h3>Usuarios</h3>
                </div>
                <div class="col-xs-12 col-md-auto">
                    <button type="button" (click)="actionItem('create')" class="btn btn-success" *ngIf="permissions.create">
                        <span class="fas fa-plus" aria-hidden="true"></span> Crear usuario
                    </button>
                </div>
                <!-- <div class="col-12 col-xl-4">
                    <abarcms-input-search [items]="users" [fieldsToSearch]="['name','lastname','email']"
                        (dataFound)="dataFound($event)" [placeholder]="'Buscar...'"></abarcms-input-search>
                </div> -->
                
            </div>

            <!--Cuerpo-->
            <div class="mt-30">
                <div id="contenido-table_wrapper" class="dataTables_wrapper no-footer">
                    <table style="width:100%">
                        <!--Cabecera de tabla-->
                        <thead>
                            <tr>
                                <!-- <th>
                                    <span class="blue-checkbox">
                                        <input type="checkbox" (click)="selectedAllItems()"
                                            ([ngModel])="_selectedAllItems">
                                        <label for=""></label>
                                    </span>
                                </th> -->
                                <th>Nombre(s)</th>
                                <th>Apellidos</th>
                                <th>Rol</th>
                                <th>
                                    Grupo
                                </th>
                                <th>Estado</th>
                                <th>Última sesión</th>
                                <th></th>
                            </tr>
                        </thead>
                        <!--Cuerpo de tabla-->
                        <tbody>
                            <tr *ngFor="let user of usersPage; let i = index">
                                <!-- 
                                <td>
                                    <span class="blue-checkbox">
                                        <input type="checkbox" class="userItem" id="userItem-{{i}}" [value]="user.uuid">
                                        <label for=""></label>
                                    </span>
                                </td> -->
                                <td>{{ user.name }}</td>
                                <td>{{ user.lastname }}</td>
                                <td>{{ ( user?.roles ) ? (user.roles | getProperty:'name') : '' }}</td>
                                <td>
                                    <!--Componente para desplegable de grupo-->
                                    <abarcms-group-detail-list [show-name]="false" [business]="false"
                                        [bussness-data]="user.groups" [click-event-enabled]="true"
                                        (clicked)="openBusiness($event)">
                                    </abarcms-group-detail-list>
                                </td>
                                <td>{{user.active ? 'Activo': 'Inactivo' }}</td>
                                <td>
                                    {{user.lastsignin}}
                                </td>
                                <td>
                                    <div class="btn-group btn-group-sm" role="group" aria-label="User action">
                                        <ng-container *ngIf="!permissions?.edit">
                                            <button type="button" class="btn btn-warning" *ngIf="permissions?.read"
                                                (click)="actionItem('read', user.uuid)">
                                                <i class="far fa-eye"
                                                    style="position: relative; left: -2px; top: 2px;"></i>
                                            </button>
                                        </ng-container>
                                        <button type="button" class="btn btn-primary"
                                            (click)="actionItem('edit', user.uuid)" *ngIf="permissions.edit">
                                            <i class="fas fa-pencil-alt"></i>
                                        </button>
                                        <ng-container *ngIf="userLogged.uuid != user.uuid">
                                            <button type="button" class="btn btn-danger" *ngIf="permissions.delete"
                                                (click)="actionItem('delete', user)">
                                                <i class="fas fa-trash-alt"></i>
                                            </button>
                                        </ng-container>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-12 mt-4">
                <abarcms-paginator [total]="paginator.total" [initialPage]="paginator.current"
                    [perPage]="paginator.perPage" [maxPages]="5" [lblFirst]="'Primera'" [lblLast]="'Última'"
                    [lblPrev]="'Anterior'" [lblNext]="'Siguiente'" [btnSec]="true" [btnPri]="true"
                    (changePage)="changePage($event)" [align]="'center'"></abarcms-paginator>
            </div>
            <p *ngIf="usersPage.length == 0" class="alert alert-warning text-center">No se encontraron datos</p>
        </div>
    </div>
</div>

<abarcms-modal title="Eliminar usuario" *ngIf="deleteModal.show" (close)="deleteItem()" [btnMiddle]="'Cancelar'"
    [btnRight]="'Aceptar'" (middle)="deleteItem()" (right)="deleteItem(deleteModal.item)">
    <p class="alert alert-danger">Estas apunto de eliminar este usuario</p>
    <form class="text-left">
        <div class="form-group">
            <label for="id">Identificador</label>
            <input type="text" readonly class="form-control" value="{{deleteModal.item.uuid}}">
        </div>
        <div class=" form-group">
            <label for="name">Nombre de la usuario</label>
            <input type="text" readonly class="form-control"
                value="{{deleteModal.item.name}} {{deleteModal.item.lastname}}">
        </div>
        <div class=" form-group">
            <label for="name">Correo</label>
            <input type="text" readonly class="form-control" value="{{deleteModal.item.email}}">
        </div>
    </form>
</abarcms-modal>