/* tslint:disable:variable-name */
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ValidateService } from 'src/app/services/validate.service';
import { SessionDataService } from 'src/app/services/data/session-data.service';
import { UserDataService } from 'src/app/services/data/user-data.service';
import { constants } from 'src/app/constants';
import { CodeVerifyDataService } from 'src/app/services/data/code-verify-data.service';
import { validation, labels } from 'src/app/strings';
import { CodeVerify } from 'src/app/dto/CodeVerify';
import { ResponseData } from 'src/app/dto/ResponseData';
import { getValid } from 'src/app/utils/_http';
import { getStorage, removeStorage, existStorage, camelCase, setStorage } from 'src/app/utils/_utils';
import { ModalHelperService } from 'src/app/services/modal-helper.service';

@Component({
  selector: 'app-code-verify',
  templateUrl: './code-verify.component.html',
  styleUrls: ['./code-verify.component.css']
})
export class CodeVerifyComponent implements OnInit {
  @ViewChild('code_1') code_1: ElementRef;
  @ViewChild('code_2') code_2: ElementRef;
  @ViewChild('code_3') code_3: ElementRef;
  @ViewChild('code_4') code_4: ElementRef;
  @ViewChild('code_5') code_5: ElementRef;
  public formaCode: FormGroup;
  public formaPassword: FormGroup;
  public validationText: any = validation;
  public validationError: string = null;
  public showValidationErrorModal = false;
  public showForwardCodeModal = false;
  public error = { show: false, message: null };
  public data: any = {};
  public url: any = {
    screen: {},
    current: '',
    child: '',
    parent: '',
    next: ''
  };
  public confirm: any = {};
  public code: Array<string> = [];
  private actionKeyDownBackspace = 0;
  public showPasswordChanged = false;

  constructor(
    private router: Router,
    private actRouter: ActivatedRoute,
    public settings: SettingsService,
    private validate: ValidateService,
    public sessionDataService: SessionDataService,
    public userDataService: UserDataService,
    public codeVerifyDataService: CodeVerifyDataService,
    private fb: FormBuilder,
    private modalHelper: ModalHelperService
  ) {
    this.createFormCode();
    this.createFormPassword();
    this.getUrlCurrent();
  }
  ngOnInit() {
    this.modalHelper.changeLoading(false);
  }
  get valid(): any {
    return this.settings.get('validate');
  }
  get validCode_1() {
    return this.formaCode.get('code_1').invalid && this.formaCode.get('code_1').touched;
  }
  get validCode_2() {
    return this.formaCode.get('code_2').invalid && this.formaCode.get('code_2').touched;
  }
  get validCode_3() {
    return this.formaCode.get('code_3').invalid && this.formaCode.get('code_3').touched;
  }
  get validCode_4() {
    return this.formaCode.get('code_4').invalid && this.formaCode.get('code_4').touched;
  }
  get validCode_5() {
    return this.formaCode.get('code_5').invalid && this.formaCode.get('code_5').touched;
  }
  get validPassword() {
    return this.formaPassword.get('password').invalid && this.formaPassword.get('password').touched;
  }
  get validPasswordConfirm() {
    const password = this.formaPassword.get('password').value;
    const passwordConfirm = this.formaPassword.get('passwordConfirm').value;
    return password === passwordConfirm ? false : true;
  }
  createFormCode() {
    this.formaCode = this.fb.group({
      code_1: ['', [Validators.required, Validators.pattern(this.valid.code), Validators.minLength(1), Validators.maxLength(1)]],
      code_2: ['', [Validators.required, Validators.pattern(this.valid.code), Validators.minLength(1), Validators.maxLength(1)]],
      code_3: ['', [Validators.required, Validators.pattern(this.valid.code), Validators.minLength(1), Validators.maxLength(1)]],
      code_4: ['', [Validators.required, Validators.pattern(this.valid.code), Validators.minLength(1), Validators.maxLength(1)]],
      code_5: ['', [Validators.required, Validators.pattern(this.valid.code), Validators.minLength(1), Validators.maxLength(1)]],
    });
  }
  createFormPassword() {
    this.formaPassword = this.fb.group({
      password: ['', [Validators.required, Validators.pattern(this.valid.password)]],
      passwordConfirm: ['', [Validators.required, Validators.pattern(this.valid.password)]],
    }, {
      validators: this.validate.passwordConfirm('password', 'passwordConfirm')
    });
  }
  setFormCode(code: Array<string>) {
    this.formaCode.setValue({
      code_1: code[0],
      code_2: code[1],
      code_3: code[2],
      code_4: code[3],
      code_5: code[4],
    });
  }
  getUrlCurrent() {
    let uri = this.router.url.split('/user/');
    if (uri.length === 2) {
      const code = uri[0].split('/').pop().split('');
      const email = atob(uri[1]);
      if (code.length !== 5 || email.length === 0) { this.onGoHome(); }
      const data = {
        action: constants.screens.passwordRecovery.path,
        data: {
          email
        }
      };
      setStorage(constants.ls.passwordRecovery, JSON.stringify(data));
      this.setFormCode(code);
    } else {
      this.actRouter.queryParams
        .subscribe(params => {
          if (Object.keys(params).length) {
            if (params.hasOwnProperty('code') === false || params.hasOwnProperty('user') === false) { this.onGoHome(); }
            const code = params.code.split('');
            const email = params.user;
            if (code.length !== 5 || email.length === 0) { this.onGoHome(); }
            const data = {
              action: constants.screens.passwordRecovery.path,
              data: {
                email: atob(email)
              }
            };
            setStorage(constants.ls.passwordRecovery, JSON.stringify(data));
            this.setFormCode(code);
          }
        });
    }
    if ((existStorage(constants.ls.codeVerify) || existStorage(constants.ls.passwordRecovery)) === false) { this.onGoHome(); }
    let routesList = this.router.url.split('?')[0].split('/');
    if (uri.length === 2) {
      uri = uri[0].split('/');
      uri.pop();
      routesList = uri;
    }
    this.url.child = routesList.pop();
    this.url.parent = routesList.pop();
    this.url.screen = constants.screens[camelCase(this.url.parent)];
    Object.entries(this.url.screen).forEach(([key, value]) => {
      if (value.toString().indexOf(this.url.child) !== -1) {
        this.url.current = key.toString();
        return;
      }
    });
    switch (this.url.current) {
      case 'code':
        if (this.url.screen.hasOwnProperty('add')) {
          this.url.next = this.url.screen.add.replace(/[/]/g, '');
        } else if (this.url.screen.hasOwnProperty('change')) {
          this.url.next = this.url.screen.change.replace(/[/]/g, '');
        }
        break;
      case 'add':
      case 'change':
        this.url.next = '';
        if (this.url.screen.hasOwnProperty('confirm')) { this.url.next = this.url.screen.confirm.replace(/[/]/g, ''); }
        break;
      case 'confirm':
        if (getStorage(constants.ls.codeVerify)) {
          this.confirm.title = labels.confirm.addPassword.title;
          this.confirm.message = labels.confirm.addPassword.message;
        }
        if (getStorage(constants.ls.passwordRecovery)) {
          this.confirm.title = labels.confirm.passwordChanged.title;
          this.confirm.message = labels.confirm.passwordChanged.message
            .replace('{correo}', `<strong>${getStorage(constants.ls.passwordRecovery, true).data.email}</strong>`);
        }
        removeStorage([constants.ls.codeVerify, constants.ls.passwordRecovery]);
        break;
      default:
        this.onGoHome();
        break;
    }
  }
  onChangeText(event, index) {
    setTimeout(() => {
      const value = event.target.value;
      const cont = this.code.length;
      if (event.key.toLowerCase() === 'backspace') {
        this.code.splice(index - 1, 1);
        this.actionKeyDownBackspace += 1;
        if (this.actionKeyDownBackspace === 2) {
          index -= 1;
          this.actionKeyDownBackspace = 0;
        }
      } else {
        this.actionKeyDownBackspace = 0;
        if (cont <= 5) {
          if (value.toString().trim().length) {
            if (event.key.toLowerCase() !== 'tab') {
              this.code.splice(index - 1, 0, value);
            }
            index += 1;
          }
        }
      }
      this.onFocusElement(index);
    });
  }
  onFocusElement(index = 0) {
    let cont = index;
    if (index === 0) {
      cont = this.code.length + 1;
    }
    if (this[`code_${cont}`]) { this[`code_${cont}`].nativeElement.focus(); }
  }
  onGoBack() {
    this.onGoHome();
  }
  onGoHome() {
    removeStorage([constants.ls.codeVerify, constants.ls.passwordRecovery]);
    this.router.navigate(['/']);
  }
  onCodeVerifyRegister() {
    if (this.formaCode.invalid) {
      return Object.values(this.formaCode.controls).forEach(control => {
        control.markAsTouched();
      });
    }
    this.codeVerifyDataService.getCodeVerify['loading'] = true;
    this.data = { code: this.code.join().replace(/[,]/g, '') };
    let dataStore;
    if (existStorage(constants.ls.codeVerify)) { dataStore = getStorage(constants.ls.codeVerify, true); }
    if (existStorage(constants.ls.passwordRecovery)) { dataStore = getStorage(constants.ls.passwordRecovery, true); }
    const body = new CodeVerify();
    body.action = dataStore.action;
    body.code = this.data.code;
    body.email = dataStore.data.email;
    this.codeVerifyDataService.getCodeVerify(body)
      .subscribe((rs: ResponseData) => {
        if (getValid(rs, { title: `Error al verificar el código`, modalHelper: this.modalHelper, settings: this.settings })) {
          this.router.navigate([this.url.parent, this.url.next]);
        }
      });
  }
  onRegister() {
    if (this.formaPassword.invalid) {
      return Object.values(this.formaPassword.controls).forEach(control => {
        control.markAsTouched();
      });
    }
    this.data = { ...this.formaPassword.value };
    let actionLogic;
    let data;
    if (existStorage(constants.ls.codeVerify)) {
      actionLogic = getStorage(constants.ls.codeVerify, true).action;
      data = getStorage(constants.ls.codeVerify, true).data;
    }
    if (existStorage(constants.ls.passwordRecovery)) {
      actionLogic = getStorage(constants.ls.passwordRecovery, true).action;
      data = getStorage(constants.ls.passwordRecovery, true).data;
    }
    switch (actionLogic) {
      case 'register':
        // tslint:disable-next-line
        this.userDataService.createPassword['loading'] = true;
        this.data = { ...this.data, ...data };
        this.userDataService.createPassword(this.data)
          .subscribe((rs) => {
            if (getValid(rs, { title: `Error al crear la contraseña`, modalHelper: this.modalHelper, settings: this.settings })) {
              this.router.navigate([this.url.parent, this.url.next]);
            }
          })
        break;
      case 'password-recovery':
        // tslint:disable-next-line
        this.userDataService.createPassword['loading'] = true;
        this.data = { ...this.data, ...data };
        delete this.data.message;
        this.userDataService.createPassword(this.data)
          .subscribe((rs) => {
            if (getValid(rs, { title: `Error al crear la contraseña el usuario`, modalHelper: this.modalHelper, settings: this.settings })) {
              this.router.navigate([this.url.parent, this.url.next]);
              this.modalHelper.changeLoading(false);
            }
          });
        break;

      default:
        break;
    }
  }
  forwardCode() {
    // tslint:disable-next-line
    this.codeVerifyDataService.forwardCode['loading'] = true;
    let data;
    if (existStorage(constants.ls.codeVerify)) { data = getStorage(constants.ls.codeVerify, true); }
    if (existStorage(constants.ls.passwordRecovery)) { data = getStorage(constants.ls.passwordRecovery, true); }
    const body = new CodeVerify();
    body.action = data.action;
    body.code = this.data.code;
    body.email = data.data.email;
    this.codeVerifyDataService.forwardCode(body)
      .subscribe((rs: ResponseData) => {
        if (getValid(rs, { title: `Error al reenviar el codigo de verificación`, modalHelper: this.modalHelper, settings: this.settings })) {
          this.data.generate = labels.forwardCode.replace('{email}', body.email);
          this.modalHelper.changeLoading(false);
          this.showForwardCodeModal = true;
        }
      });
  }
}
