<ng-container [ngSwitch]="type" *ngIf="showComponent">

    <ng-container *ngSwitchCase="'abierta'">
        <div class="form-group">
            <textarea class="form-control textarea" id="answer-2" rows="4" placeholder="Parrafo"></textarea>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'opcion_unica'">
        <div class="form-group">
            <div class="row" [ngClass]="{'mb-3': data.length}">
                <ng-container *ngFor="let item of data; index as key">
                    <div class="col-6 d-flex justify-content-between align-items-center">
                        <div class="d-flex align-items-center">
                            <input type="radio" name="options" class="mr-2">
                            <label class="m-0 mr-2">{{ item.title }}</label>
                        </div>

                        <ng-container *ngIf="!isFormPublicate">
                            <a (click)="onSection('delete', key)"><span aria-hidden="true"
                                    class="fas fa-window-close"></span></a>
                        </ng-container>

                    </div>
                    <div class="form-group mb-1 col-6">
                        <select class="form-control mb-0" [(ngModel)]="choiceData.questionSelected[key]"
                            (change)="onSection('nested', key)" [disabled]="isFormPublicate">
                            <option value=''>No anidar pregunta</option>
                            <ng-container *ngFor="let question of setQuestions(key)">
                                <ng-container *ngIf="question.uuid !== parent.uuid">
                                    <ng-container *ngIf="!existParentQuestion(question.uuid, parent.uuid)">
                                        <option [value]="question.uuid">{{question.title}}</option>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </select>
                    </div>
                </ng-container>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="d-flex d-flex justify-content-between">

                        <ng-container *ngIf="!isFormPublicate">
                            <input type="radio" name="options" class="mr-2 align-self-center">
                            <input type="text" class="form-control mb-0 align-self-center"
                                placeholder="Título de la opción" [(ngModel)]="choiceData.choiceTitle"
                                [ngStyle]="choiceData.choiceTitleFail && {'border': '1px solid red','margin-bottom': '3px'}"
                                [readonly]="isFormPublicate">
                            <a placement="top" ngbTooltip="Añadir opción" class="ml-2 align-self-center"
                                (click)="onSection('add')">
                                <span aria-hidden="true" class="fas fa-plus"></span>
                            </a>
                        </ng-container>

                    </div>
                    <div class="alert alert-danger mt-1 p-1 mb-0" [style.fontSize.px]="12"
                        *ngIf="choiceData.choiceTitleFail">Este campo es requerido</div>
                    <div class="alert alert-danger mt-1 p-1 mb-0" [style.fontSize.px]="12" *ngIf="errorChoicesLength()">
                        Debes ingresar al menos 2 opciones</div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'opcion_multiple'">
        <div class="form-group">
            <div class="row" [ngClass]="{'mb-3': data.length}">
                <ng-container *ngFor="let item of data; index as key">
                    <div class="col-6 d-flex justify-content-between align-items-center">
                        <div class="d-flex align-items-center">
                            <input type="checkbox" name="options" class="mr-2">
                            <label class="m-0 mr-2">{{ item.title }}</label>
                        </div>

                        <ng-container *ngIf="!isFormPublicate">
                            <a (click)="onSection('delete', key)"><span aria-hidden="true"
                                    class="fas fa-window-close"></span></a>
                        </ng-container>

                    </div>
                    <!-- <div class="form-group mb-1 col-6">
                        <select class="form-control mb-0" [(ngModel)]="choiceData.questionSelected[key]"
                            (change)="onSection('nested', key)">
                            <option value=''>No anidar pregunta</option>
                            <ng-container *ngFor="let question of setQuestions(key)">
                                <ng-container *ngIf="question.uuid !== parent.uuid">
                                    <option [value]="question.uuid">{{question.title}}</option>
                                </ng-container>
                            </ng-container>
                        </select>
                    </div> -->
                </ng-container>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="d-flex d-flex justify-content-between">

                        <ng-container *ngIf="!isFormPublicate">
                            <input type="checkbox" name="options" class="mr-2 align-self-center">
                            <input type="text" class="form-control mb-0 align-self-center"
                                placeholder="Título de la opción" [(ngModel)]="choiceData.choiceTitle"
                                [ngStyle]="choiceData.choiceTitleFail && {'border': '1px solid red','margin-bottom': '3px'}"
                                [readonly]="isFormPublicate">
                            <a placement="top" ngbTooltip="Añadir opción" class="ml-2 align-self-center"
                                (click)="onSection('add')">
                                <span aria-hidden="true" class="fas fa-plus"></span>
                            </a>
                        </ng-container>

                    </div>
                    <div class="alert alert-danger mt-1 p-1 mb-0" [style.fontSize.px]="12"
                        *ngIf="choiceData.choiceTitleFail">Este campo es requerido</div>
                    <div class="alert alert-danger mt-1 p-1 mb-0" [style.fontSize.px]="12" *ngIf="errorChoicesLength()">
                        Debes ingresar al menos 2 opciones</div>
                </div>
            </div>
        </div>
        <!-- <div class="form-group">
            <div class="row" [ngClass]="{'mb-3': data.length}">
                <ng-container *ngFor="let item of data; index as key">
                    <div class="col-12 d-flex justify-content-between align-items-center">
                        <div class="d-flex align-items-center">
                            <input type="checkbox" name="options" class="mr-2">
                            <label class="m-0 mr-2">{{ item.title }}</label>
                        </div>
                        <a (click)="onSection('delete', key)"><span aria-hidden="true"
                                class="fas fa-window-close"></span></a>
                    </div>
                </ng-container>
            </div>
            <div class="row">
                <div class="col-6" [ngClass]="{'col-12': choiceData.validation}">
                    <div class="d-flex d-flex justify-content-between">
                        <input type="checkbox" name="options" class="mr-2 align-self-center">
                        <input type="text" class="form-control mb-0 align-self-center" placeholder="Título de la opción"
                            [(ngModel)]="choiceData.choiceTitle"
                            [ngStyle]="choiceData.choiceTitleFail && {'border': '1px solid red','margin-bottom': '3px'}">
                        <a placement="top" ngbTooltip="Añadir opción" class="ml-2 align-self-center"
                            (click)="onSection('add')">
                            <span aria-hidden="true" class="fas fa-plus"></span>
                        </a>
                    </div>
                    <div class="alert alert-danger mt-1 p-1 mb-0" [style.fontSize.px]="12"
                        *ngIf="choiceData.choiceTitleFail">Este campo es requerido</div>
                </div>
            </div>
        </div> -->
    </ng-container>

    <ng-container *ngSwitchCase="'opcion_unica_imagen'">
        <div class="form-group">
            <div class="row" [ngClass]="{'mb-3': data.length}">
                <ng-container *ngFor="let item of data; index as key">
                    <div class="col-6 d-flex justify-content-between align-items-center">
                        <div class="d-flex align-items-center">
                            <input type="radio" name="options" class="mr-2">
                            <!-- <label class="m-0 mr-2">{{ item.title }}</label> -->
                            <img [src]="item.title" [alt]="item.title.split('/').pop()"
                                style="height: 70px !important;">
                        </div>

                        <ng-container *ngIf="!isFormPublicate">
                            <a (click)="onSection('delete', key)"><span aria-hidden="true"
                                    class="fas fa-window-close"></span></a>
                        </ng-container>

                    </div>
                    <div class="form-group mb-1 col-6">
                        <select class="form-control mb-0" [(ngModel)]="choiceData.questionSelected[key]"
                            (change)="onSection('nested', key)" [disabled]="isFormPublicate">
                            <option value=''>No anidar pregunta</option>
                            <ng-container *ngFor="let question of setQuestions(key)">
                                <ng-container
                                    *ngIf="question.uuid !== parent.uuid && nestedAux[question.uuid] == parent.uuid">
                                    <option [value]="question.uuid">{{question.title}}</option>
                                </ng-container>
                            </ng-container>
                        </select>
                    </div>
                </ng-container>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="d-flex d-flex justify-content-between">

                        <ng-container *ngIf="!isFormPublicate">
                            <input type="radio" name="options" class="mr-2 align-self-center">
                            <abarcms-file-upload [singleFile]="true" typeFile="jpg,png,jpeg" [sizeFile]="5"
                                [files]="media.files" [callServiceInThisComponent]="true"
                                (getFilesToUpload)="getFilesToUpload($event)" (isSingleFiles)="true"
                                [ngStyle]="choiceData.choiceTitleFail && {'border': '1px solid red','margin-bottom': '3px'}"
                                [preview]="false">
                            </abarcms-file-upload>
                        </ng-container>

                    </div>
                    <div class="alert alert-danger mt-1 p-1 mb-0" [style.fontSize.px]="12"
                        *ngIf="choiceData.choiceTitleFail">Este campo es requerido</div>
                    <div class="alert alert-danger mt-1 p-1 mb-0" [style.fontSize.px]="12" *ngIf="errorChoicesLength()">
                        Debes ingresar al menos 2 opciones</div>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- <ng-container *ngSwitchCase="'repuesta_corta'">
        <div class="form-group">
            <input type="text" class="form-control mb-0" placeholder="Texto corto">
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'desplegables'">
        <div class="form-group">
            <div class="row ">
                <div class="col-6 d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center selected-option">
                        Opción 1
                    </div>
                    <a><span aria-hidden="true" class="fas fa-window-close"></span></a>
                </div>
                <div class="form-group mb-1 col-6">
                    <select class="form-control mb-0" name="action-options" id="action-options">
                        <option value="0">Al seleccionar pasa a la
                            pregunta
                            X</option>
                        <option value="1">Al seleccionar pasa a la
                            pregunta
                            X</option>
                        <option value="2">Al seleccionar terminar
                            encuesta
                        </option>
                    </select>
                </div>
            </div>
            <div class="row ">
                <div class="col-6 d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center selected-option">
                        Opción 2
                    </div>
                    <a><span aria-hidden="true" class="fas fa-window-close"></span></a>
                </div>
                <div class="form-group mb-1 col-6">
                    <select class="form-control mb-0" name="action-options" id="action-options">
                        <option value="0">Al seleccionar pasa a la
                            pregunta
                            X</option>
                        <option value="1">Al seleccionar pasa a la
                            pregunta
                            X</option>
                        <option value="2">Al seleccionar terminar
                            encuesta
                        </option>
                    </select>
                </div>
            </div>
            <div class="row ">
                <div class="col-6 d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center selected-option">
                        Opción 3
                    </div>
                    <a><span aria-hidden="true" class="fas fa-window-close"></span></a>
                </div>
                <div class="form-group mb-1 col-6">
                    <select class="form-control mb-0" name="action-options" id="action-options">
                        <option value="0">Al seleccionar pasa a la
                            pregunta
                            X</option>
                        <option value="1">Al seleccionar pasa a la
                            pregunta
                            X</option>
                        <option value="2">Al seleccionar terminar
                            encuesta
                        </option>
                    </select>
                </div>
            </div>

            <div class="row ">
                <div class="col-6 d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center">
                        <a class="link">Añadir opción</a>
                        <p class="mb-0 mx-2">o</p>
                        <a class="link">Añadir opción Otro</a>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="col-6 d-flex justify-content-between align-items-center">
                    <input type="text" name="newOption" class="mr-2">
                    <a><span aria-hidden="true" class="fas fa-window-close"></span></a>
                </div>
                <div class="form-group mb-1 col-6">
                    <select class="form-control mb-0" name="action-options" id="action-options">
                        <option value="0">Al seleccionar pasa a la
                            pregunta
                            X</option>
                        <option value="1">Al seleccionar pasa a la
                            pregunta
                            X</option>
                        <option value="2">Al seleccionar terminar
                            encuesta
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'escala_lineal'">
        <div class="form-group">
            <div class="row">
                <div class="col-4 d-flex justify-content-between align-items-center">
                    <label for="from">De</label>
                    <select class="form-control" name="from" style="width:50px">
                        <option value="0">0</option>
                        <option value="1">1</option>
                    </select>
                    <label for="to">a</label>
                    <select class="form-control" name="to" style="width:50px">
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-6 d-flex justify-content-between align-items-center">
                    <label for="option-one">1.-</label>
                    <input type="text" name="option-one" class="mr-2" style="width:80%"
                        placeholder="etiqueta (opcional)">
                    <a><span aria-hidden="true" class="fas fa-window-close"></span></a>
                </div>
            </div>
            <div class="row">
                <div class="col-6 d-flex justify-content-between align-items-center">
                    <label for="option-two">2.-</label>
                    <input type="text" name="option-two" class="mr-2" style="width:80%"
                        placeholder="etiqueta (opcional)">
                    <a><span aria-hidden="true" class="fas fa-window-close"></span></a>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'fecha'">
        <div class="form-group">
            <input type="date" class="form-control mb-0">
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'hora'">
        <div class="form-group">
            <input type="time" class="form-control mb-0">
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'anexo_de_archivos'">
        <div class="form-group d-flex justify-content-end align-items-center">
            <label for="type-file-check" style="width: 70rem;">Permitir
                solo ciertos
                tipos de
                archivo</label>
            <input type="checkbox" class="form-control mb-0" name="type-file-check">
        </div>
        <div class="row">
            <div class="form-group col-6">
                <input type="checkbox" name="type-file-checks">
                <label>PDF</label>
            </div>
            <div class="form-group col-6">
                <input type="checkbox" name="type-file-checks">
                <label>Imagen</label>
            </div>
            <div class="form-group col-6">
                <input type="checkbox" name="type-file-checks">
                <label>Video</label>
            </div>
            <div class="form-group col-6">
                <input type="checkbox" name="type-file-checks">
                <label>Audio</label>
            </div>
        </div>
    </ng-container> -->

</ng-container>