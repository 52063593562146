import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseData } from 'src/app/dto/ResponseData';

@Injectable({
  providedIn: 'root'
})
export abstract class ContentsDataService {
  abstract clone(body: object): Observable<ResponseData>;
  abstract create(body: object, id?: string, action?: string): Observable<ResponseData>;
  abstract read(filters?: any, headers?: any): Observable<ResponseData>;
  abstract readOne(id: any, contentTypeId: any, languageId: string): Observable<ResponseData>;
  abstract update(body: object, id?: string, action?: string): Observable<ResponseData>;
  abstract bulk(body: object, action?: string): Observable<ResponseData>;
  abstract delete(id: string, contentType: string): Observable<ResponseData>;
}
