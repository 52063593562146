import { Component, OnInit, Input } from '@angular/core';
import { SessionService, SettingsService, AlertNotificationService } from 'src/app/services';
import { MediaService } from 'src/app/services/media.service';
import { getBase64ImageFromUrl, getFileImageToBase64, getStorage, setStorage } from 'src/app/utils/_utils';
import { Notification, ResponseData } from 'src/app/dto';
import { UserDataService } from 'src/app/services/data/user-data.service';
import { getValid, hiddenModalLoading, changeImageProfile } from 'src/app/utils/_http';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { labels } from 'src/app/strings';
import { constants } from 'src/app/constants';
import { FilesPipe } from 'src/app/pipes/files.pipe';

@Component({
  selector: 'abarcms-dashboard-widget-assets',
  templateUrl: './dashboard-widget-assets.component.html',
  styleUrls: ['./dashboard-widget-assets.component.css']
})
export class DashboardWidgetAssetsComponent implements OnInit {
  @Input('data')
  imageProfile: any = {
    filesToUpload: [],
    files: [],
    isSingleFiles: null,
    mediaChange: false,
  }
  notification: Notification = {
    title: '',
    message: '',
    options: { autoClose: true, keepAfterRouteChange: true }
  };
  data: any = null;
  constructor(
    public settings: SettingsService,
    protected session: SessionService,
    protected mediaService: MediaService,
    protected alertNotificationService: AlertNotificationService,
    private userDataService: UserDataService,
    private modalHelper: ModalHelperService,
    private filesPipe: FilesPipe
  ) {
  }

  ngOnInit() {
    this.imageProfile = {
      filesToUpload: [],
      files: [],
      isSingleFiles: null,
      mediaChange: false,
    }
    this.getMedia();
  }
  isSingleFile($event) {
    this.imageProfile = { ...this.imageProfile, isSingleFiles: $event };
    if (this.imageProfile['files'].length) if (this.imageProfile['isSingleFiles']) this.imageProfile['files'] = [this.imageProfile['files'].pop()];
  }
  getFilesToUpload($event) {
    setTimeout(() => {
      this.imageProfile['filesToUpload'] = $event;
      if (this.imageProfile['filesToUpload'].length) {
        let { last_signing, password, roles, ...user } = this.session.user;
        let id = this.imageProfile['filesToUpload'][0].hasOwnProperty('uuid') ? this.imageProfile['filesToUpload'][0].uuid : this.imageProfile['filesToUpload'][0].id;
        user.image = id;
        this.userUpdate(user, this.imageProfile['filesToUpload'][0]);
      }
    }, 50)
  }
  private getMedia() {
    setTimeout(() => {
      let mediaID = this.session.user.image;
      this.imageProfile = { ...this.imageProfile, files: [] };
      if (mediaID) {
        this.mediaService.getOne(mediaID)
          .subscribe(rs => {
            if (rs) {
              if (rs.hasOwnProperty('error')) {
                let error = {
                  title: 'Error al obtener la imagen',
                  message: this.settings.get('production') ? rs.error['message'] : rs.error,
                  show: true
                }
                console.error(error);
              } else {
                if (rs.name) {
                  rs.name = this.filesPipe.transform(rs.name);
                  this.imageProfile.files.push(JSON.stringify(rs))
                  // changeImageProfile(mediaID, rs.name, this.modalHelper);
                  // getBase64ImageFromUrl(rs.name)
                  //   .then(result => {
                  //     if (result) {
                  //       let file = getFileImageToBase64(result, rs.name.split('/').pop());
                  //       this.imageProfile['files'].push({
                  //         id: rs.uuid,
                  //         name: file.name.split('/').pop(),
                  //         type: file.type,
                  //         size: file.size,
                  //         path: result,
                  //         creaatedAt: file.lastModified,
                  //         alt: "AltProfile",
                  //         extension: `.${rs.name.split('/').pop().split('.').pop()}`
                  //       });
                  //       let { title, message, options } = this.notification;
                  //       title = 'Se obtuvo correctamente la imagen';
                  //       message = `Imagen ${rs.name.split('/').pop()}`;
                  //       this.alertNotificationService.success(title, message, options);
                  //     }
                  //   })
                  //   .catch(err => console.error(err));
                }
              }
            }
          });
      }
    }, 200);
  }
  private userUpdate(data: object, file: string) {
    this.userDataService.updateUser(data, this.session.accessToken.accessToken)
      .subscribe((rs: ResponseData) => {
        if (rs) {
          if (getValid(rs, { title: `Error al actualizar la imagen del perfil`, modalHelper: this.modalHelper, settings: this.settings })) {
            let { title, message, options } = this.notification;
            title = 'Se actualizó correctamente la imagen';
            message = `Imagen ${file['name'].split('/').pop()}`;
            this.alertNotificationService.success(title, message, options);
            changeImageProfile(data['image'], file['url'], this.modalHelper);
            hiddenModalLoading({ updated: false }, this.modalHelper);
          }
        }
      })
  }
}
