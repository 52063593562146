import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '../../settings.service';
import { Media } from 'src/app/dto/Media';
import { Observable, BehaviorSubject } from 'rxjs';
import { ResponseData } from 'src/app/dto/ResponseData';
import { createResponseData, makeHeaders } from 'src/app/utils/_http';
import { ModalHelperService } from '../../modal-helper.service';
import { ApiService } from '../../api.service';

@Injectable({
  providedIn: 'root'
})
export class MediaDataWsService {
  private pathInfo: any;
  private baseUrl: string;
  private segment: any;
  constructor(
    private settings: SettingsService,
    private api: ApiService
  ) {
    this.pathInfo = this.settings.get('pathInfo');
    this.segment = this.pathInfo.res.admin.media;
    this.baseUrl = this.pathInfo.base.baseUrl + this.pathInfo.base.prefix;
  }
  private read(accessToken?: string): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix;
    return this.api.get(endpoint);
  }
  private create(body: any, accessToken?: string): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix;
    //if (Array.isArray(body) == false) body = [body];
    return this.api.post(endpoint, body);
  }
  private readOne(id: string): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.getOne.replace(':id', id);
    return this.api.get(endpoint);
  }
  private update(id: any, body: object, accessToken?: string): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.update.replace(':id', id);
    return this.api.put(endpoint, body);
  }
  private delete(id: any, accessToken?: string): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.delete.replace(':id', id);
    return this.api.delete(endpoint, id);
  }
}
