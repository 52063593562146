import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SettingsService } from '../../settings.service';
import { ResponseData } from 'src/app/dto';
import { environment } from 'src/environments/environment';
import { ApiService } from '../../api.service';
import { FormulariosFieldDataService } from '../formularios-field-data.service';

@Injectable({
  providedIn: 'root'
})

export class FormulariosFieldDataWsService implements FormulariosFieldDataService {

    private pathInfo: any;
    private baseUrl: string;
    private segment: any;

    constructor(
        private settings: SettingsService,
        private api: ApiService
    ) {
        this.pathInfo = this.settings.get('pathInfo');
        this.segment  = this.pathInfo.res.admin.formFields;
        this.baseUrl  = this.pathInfo.base.baseUrl + this.pathInfo.base.prefix;
        if (!environment.production && this.segment.override) {
            this.baseUrl = this.segment.override.baseUrl + this.segment.override.prefix;
            this.segment.prefix = this.segment.prefix.replace(this.pathInfo.base.apiVersion, this.segment.override.apiVersion);
        }
    }

    public read(formId): Observable<ResponseData> {
        const endpoint = this.baseUrl + this.segment.prefix;
        return this.api.get( endpoint );
    }
    public readOne(menuUUID: any): Observable<ResponseData> {
        throw new Error('Method not implemented.');
    }
    public bulkCreate(body: any): Observable<ResponseData> {
        const endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.bulkCreate;
        return this.api.post( endpoint, body );
    }
    public bulkUpdate(body: any): Observable<ResponseData> {
        const endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.bulkUpdate;
        return this.api.put( endpoint, body );
    }
    public create(formUUID: string, body: any): Observable<ResponseData> {
        const endpoint = this.baseUrl + this.segment.prefix.replace(/:uuid/g, formUUID);
        return this.api.post( endpoint, body );
    }
    public update(menuUUID: any, body: any): Observable<ResponseData> {
        const endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.update.replace(/:uuid/g, menuUUID);
        return this.api.put( endpoint, body );
    }
    public delete(formUUID: string, fieldUUID: string): Observable<ResponseData> {
        const endpoint = this.baseUrl
            + this.segment.prefix.replace(/:uuid/g, formUUID)
            + this.segment.resources.delete.replace(/:uuid/g, fieldUUID);
        return this.api.delete( endpoint, fieldUUID );
    }
}
