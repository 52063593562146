<div class="container-fluid" *ngIf="showComponent">
  <div class="card border-card">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <h2>{{pageAction['titlePage']}}</h2>
        </div>
        <div class="col-md-12">
          <form [formGroup]="form">
            <!--Tipo Accion-->
            <div class="col-md-12">
              <div class="form-group">
                <label class="etiqueta">Tipo de acción</label><br/>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="tipoAccion1" 
                  formControlName="tipoAccion" value="1" checked (change)="limpiaTipoAccion()">
                  <label class="form-check-label " for="tipoAccion1">
                    Solo guarda avisos
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="tipoAccion2" 
                  formControlName="tipoAccion" value="2" (change)="limpiaTipoAccion()">
                  <label class="form-check-label" for="tipoAccion2">
                    Solo envía notificación push
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="tipoAccion3" 
                  formControlName="tipoAccion" value="3" (change)="limpiaTipoAccion()">
                  <label class="form-check-label" for="tipoAccion3">
                    Guarda aviso y envía notificaciones push
                  </label>
                </div>
                <div class=" invalidFieldForm" style="color: red;font-size: 13px;">
                  <div *ngIf="(f.title?.errors?.required || f.title?.errors?.minlength) && (f.title.touched || f.title.dirty)">
                    Este campo es requerido
                  </div>
                </div>
              </div>
            </div>
            <!--Tipo Aviso-->
            <div class="col-md-12">
              <div class="form-group">
                <label class="etiqueta">Tipo de envio</label><br/>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="tipoEnvio1" 
                  formControlName="tipoEnvio" value="1" checked (change)="limpiaTipoEnvio()">
                  <label class="form-check-label" for="tipoEnvio1">
                    Por Usuario
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="tipoEnvio2" 
                  formControlName="tipoEnvio" value="2" checked (change)="limpiaTipoEnvio()">
                  <label class="form-check-label" for="tipoEnvio2">
                    Por Página
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="tipoEnvio3" 
                  formControlName="tipoEnvio" value="3" checked (change)="limpiaTipoEnvio()">
                  <label class="form-check-label" for="tipoEnvio3">
                    Por Grupo de visualización
                  </label>
                </div>
                <div class=" invalidFieldForm" style="color: red;font-size: 13px;">
                  <div *ngIf="(f.title?.errors?.required || f.title?.errors?.minlength) && (f.title.touched || f.title.dirty)">
                    Este campo es requerido
                  </div>
                </div>
              </div>
              
            </div>
            <!--Título aviso-->
            <div class="col-md-12">
              <div class="form-group">
                <label class="etiqueta">Título aviso</label><br/>
                <input type="text" class="form-control" placeholder="Título" formControlName="title"
                  [ngStyle]="(f.title?.errors && (f.title.touched || f.title.dirty)) && {'border-color': 'red','margin-bottom': '3px'}">
                <div class=" invalidFieldForm" style="color: red;font-size: 13px;">
                  <div *ngIf="(f.title?.errors?.required || f.title?.errors?.minlength) && (f.title.touched || f.title.dirty)">
                    Este campo es requerido
                  </div>
                </div>
              </div>
            </div>
            <!--Url imagen-->
            <div class="col-md-12" *ngIf="this.form.value.tipoAccion == 2 || this.form.value.tipoAccion == 3">
              <div class="form-group" >
                <label class="etiqueta">Url imagen</label>
                <input type="text" class="form-control" placeholder="Https://urlimg.com/img.png" formControlName="imageUrl"
                [ngStyle]="(f.title?.errors && (f.title.touched || f.title.dirty)) && {'border-color': 'red','margin-bottom': '3px'}">
              </div>
            </div>
            <!--Opciones dependiendo del Tipo de envio-->
            <div class="col-md-12">
              <!--Por Usuario-->
              <div *ngIf="this.form.value.tipoEnvio == 1">
                <!--Filtro de usuarios-->
                <div class="row">
                  <div class="col-md-12">
                    <br/>
                    <label class="etiqueta">Filtros</label>
                    <div class=" invalidFieldForm" style="color: red;font-size: 13px;">
                      <div *ngIf="banderaIsNoFiltro">
                        Se requiere mínimo un filtro
                      </div>
                    </div>
                    <br/>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="etiqueta">Selecciona una empresa</label>
                      <ng-select [items]="empresas" [multiple]="false" [closeOnSelect]="true"
                          [hideSelected]="true" bindLabel="name" placeholder="Selecciona una empresa"
                          [(ngModel)]="empresaSelected" formControlName="empresaSelected">
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="etiqueta">Edad</label>
                      <input type="text" class="form-control" placeholder="Edad" formControlName="edad"
                      [ngStyle]="(f.title?.errors && (f.title.touched || f.title.dirty)) && {'border-color': 'red','margin-bottom': '3px'}">
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="etiqueta">Selecciona una enfermedad</label>
                      <ng-select [items]="enfermedades" [multiple]="false" [closeOnSelect]="true"
                          [hideSelected]="true" bindLabel="name" placeholder="Selecciona una enfermedad"
                          [(ngModel)]="enfermedadSelected" formControlName="enfermedadSelected">
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="etiqueta">Selecciona un tipo</label>
                      <ng-select [items]="tipos" [multiple]="false" [closeOnSelect]="true"
                          [hideSelected]="true" bindLabel="name" placeholder="Selecciona un Tipo"
                          [(ngModel)]="tipoSelected" formControlName="tipoSelected">
                      </ng-select>
                    </div>
                  </div>
                  <!--div class="col-md-4">
                    <div class="form-group">
                      <label class="etiqueta">Selecciona una plantilla</label>
                      <ng-select [items]="plantillas" [multiple]="false" [closeOnSelect]="true"
                          [hideSelected]="true" bindLabel="name" placeholder="Selecciona una Plantilla"
                          [(ngModel)]="plantillaSelected" formControlName="plantillaSelected">
                      </ng-select>
                    </div>
                  </div-->
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="etiqueta">CP</label>
                      <input type="text" class="form-control" placeholder="CP" formControlName="cp"
                      [ngStyle]="(f.title?.errors && (f.title.touched || f.title.dirty)) && {'border-color': 'red','margin-bottom': '3px'}">
                    </div>
                  </div>
                </div>
                <br/>
                <br/>
                <!--botones para filtro de usuarios-->
                <div class="row">
                  <div class="col-md-12">
                    <button class="btn btn-primary btn-block my-0" style="float:right; margin-left: 10px;" (click)="buscarUsuarios()">Buscar</button>
                    <button class="btn btn-danger btn-block my-0" style="float:right; margin-left: 10px;" (click)="limpiarFiltro()">Limpiar</button>  
                  </div>
                </div>
                <br/>
                <br/>
                <!--usuarios-->
                <div class="form-group" *ngIf="showListUsuarios">
                  <label class="etiqueta">Selecciona los usuarios</label>
                  <ng-select [items]="usuarios" bindLabel="nombre" bindValue="id_usuario" [multiple]="true"  groupBy="selectedAllGroup" 
                            [selectableGroup]="true" placeholder="Selecciona los usuarios"
                            [selectableGroupAsModel]="false" [closeOnSelect]="false" 
                            [(ngModel)]="usuariosSelected" formControlName="usuariosSelected" [disabled]="usuarios">
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index" >
                      <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}"/>
                      Select All
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" >
                      <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}"/>
                      {{ item.nombre }}
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <!--Por Pagina-->
              <div class="form-group" *ngIf="this.form.value.tipoEnvio == 2">
                <label class="etiqueta">Selecciona la pagina</label>
                
                  <ng-select [items]="pages" [multiple]="false" [closeOnSelect]="true"
                      [hideSelected]="true" bindLabel="name" placeholder="Selecciona la pagina"
                      [(ngModel)]="pageSelected" formControlName="pageSelected">
                  </ng-select>
              </div>
              <!--Por Grupo-->
              <div class="form-group" *ngIf="this.form.value.tipoEnvio == 3">
                <label class="etiqueta">Selecciona el grupo de visualización</label>
                <ng-select [items]="groups" [multiple]="false" [closeOnSelect]="true"
                    [hideSelected]="true" bindLabel="name" placeholder="Selecciona el grupo de visualización"
                    [(ngModel)]="groupSelected" formControlName="groupSelected">
                </ng-select>
              </div>
            </div>
            <br/>
            <!--Enviar html-->
            <div class="col-md-12" *ngIf="form.value.tipoAccion != 2">
              <div class="custom-control custom-switch">
                <input class="custom-control-input" type="checkbox" id="htmlRadioButons" formControlName="isMenssagePush">
                <label class="custom-control-label" for="htmlRadioButons">Enviar html</label>
              </div>
            </div>
            <br/>

            <!--mensaje-->
            <div class="col-md-12" *ngIf="(this.form.value.tipoAccion == 1 && !this.form.value.isMenssagePush) || this.form.value.tipoAccion == 3 || this.form.value.tipoAccion == 2">
              <div class="form-group">
                <label class="etiqueta">{{this.form.value.tipoAccion == 3 || this.form.value.tipoAccion == 2 ? 'Mensaje push':'Mensaje'}}</label>
                <input type="text" class="form-control" [placeholder]="this.form.value.tipoAccion == 3 || this.form.value.tipoAccion == 2 ?'Mensaje push':'Mensaje'" formControlName="messagePush"
                [ngStyle]="(f.title?.errors && (f.title.touched || f.title.dirty)) && {'border-color': 'red','margin-bottom': '3px'}">
              </div>
            </div>
            <br/>

            <!--html-->
            <div class="col-md-12" *ngIf="form.value.isMenssagePush">
              <div class="form-group ">
                <label class="etiqueta">Html</label>
                <editor apiKey="pb35ger7tvm1x0kikj4ijw7uik5coks0odrzod7ilt02ln5j" formControlName="message" [init]="initOptionText"></editor>
              </div>
            </div>
            
          </form>
          <div class="col-12 mb-4">
            <div class="botonera">
              <button class="btn btn-dark btn-block" routerLink="/">Regresar</button>
              <button class="btn btn-primary btn-block my-0"  (click)="guardar()">Guardar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>