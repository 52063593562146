import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getArray, getHeader, getObject } from 'src/app/utils/_http';
import { constants } from 'src/app/constants';
import { NotificationsDataService } from 'src/app/services/data/notifications-data.service';
import { Subscription } from 'rxjs';
import { AlertNotificationService } from 'src/app/services';
import { PushNotification } from 'src/app/dto/PushNotifications';
import { clone } from 'src/app/utils/_utils';

@Component({
  selector: 'app-push-notifications-list',
  templateUrl: './push-notifications-list.component.html',
  styleUrls: ['./push-notifications-list.component.css']
})
export class PushNotificationsListComponent implements OnInit {
  subscription: Subscription = new Subscription
  permissions: any = {}
  deleteModal = { show: false, item: null, type: '' }

  searchText: string = ''
  paginator: {
    current: number,
    perPage: number,
    total: number
  } = {
      current: 1,
      perPage: 10,
      total: 0,
    }
  dataTable: any = {
    size: 100,
    showTable: false
  }
  init: boolean = true

  _selectedAllItems: boolean = false
  notifications: PushNotification[] = []
  notificationsPage: PushNotification[] = []
  notificationsList: PushNotification[] = []

  batchNotifications: string = 'activar'
  batchNotificationsItems: string[] = []
  notificationsSelected: any[] = []

  constructor(
    private router: Router,
    private notificationsService: NotificationsDataService,
    private alertNotificationService: AlertNotificationService,
  ) { }

  ngOnInit(): void {
    this.getNotifications()
  }
  // --
  // getNotifications(): void {
  //   this.subscription.add(
  //     this.notificationsService.read(this.paginator)
  //       .subscribe(
  //         rs => {
  //           let data = getObject(rs)
  //           if (data.status) {
  //             this.notifications = data.notificationList
  //             this.paginator.total = data.xTotalItems
  //           }
  //           else {
  //             this.alertNotificationService.error('Notificaciones Push', 'Ocurrio un error, intente más tarde', { autoClose: true, keepAfterRouteChange: true })
  //           }
  //         },
  //         error => {
  //           this.alertNotificationService.error('Notificaciones Push', 'Ocurrio un error, intente más tarde', { autoClose: true, keepAfterRouteChange: true })
  //         }
  //       )
  //   )
  // }
  // --
  getNotifications(perPage?: any, current?: any, total?: any): void {
    this.notifications = []
    this.notificationsPage = []
    this.notificationsList = []
    this.dataTable.showTable = false
    this.batchNotificationsItems = []
    this.notificationsSelected = []
    this._selectedAllItems = false
    // --
    let paginator = clone(this.paginator)
    paginator.perPage = perPage ? perPage : 1
    paginator.current = current ? current : paginator.current
    this.subscription.add(
      this.notificationsService.read(paginator)
        .subscribe(
          (rs) => {
            if (perPage) {
              getObject(rs).notificationList.map((x, i) => {
                this.notifications.push(x)
                this.notificationsPage.push(x)
                if (i == getObject(rs).notificationList.length - 1) {
                  if (current < total) {
                    current++
                    this.getNotifications(this.dataTable.size, current, total)
                  }
                }
              })
              this.dataTable.showTable = true
            }
            else {
              this.paginator.total = getObject(rs).xTotalItems
              let total = this.paginator.total / this.dataTable.size
              let page = Math.trunc(total)
              page += total - page > 0 ? 1 : 0
              this.getNotifications(this.dataTable.size, 1, page)
            }
          }
        )
    )
  }
  // --
  dataFound(event) {
    this.notificationsPage = event
  }
  // --
  changePage(event: any) {
    this.notificationsList = event;
  }
  // --
  actionItem(actionName: string, item: any = null) {
    if (actionName == 'delete') {
      this.deleteModal.show = true
      this.deleteModal.item = item
      this.deleteModal.type = 'eliminar'
    }
    else if (item) this.router.navigate([constants.screens.notifications.path, item, constants.screens.notifications[actionName]])
    else this.router.navigate([constants.screens.notifications.path, constants.screens.notifications[actionName]])
  }
  // --
  selectedAllItems() {
    this._selectedAllItems = !this._selectedAllItems
    this.batchNotificationsItems = []
    if (this._selectedAllItems) {
      this.notifications.map((x: any) => {
        this.batchNotificationsItems.push(x.uuid)
        this.notificationsSelected.push(x)
      })
    }
  }
  selectedItem(item: any) {
    let index = this.batchNotificationsItems.indexOf(item.uuid)
    if (index != -1) {
      this.batchNotificationsItems.splice(index, 1)
      this.notificationsSelected.splice(index, 1)
    }
    else {
      this.batchNotificationsItems.push(item.uuid)
      this.notificationsSelected.push(item)
    }
  }
  // --
  isChecked(item?: any): boolean {
    let value = false
    if (item) {
      let index = this.batchNotificationsItems.indexOf(item.uuid)
      if (index != -1) {
        value = true
      }
    }
    else {
      if (this.batchNotificationsItems.length == this.notifications.length) {
        this._selectedAllItems = true
        value = true
      }
      else {
        this._selectedAllItems = false
      }
    }
    return value
  }
  // --
  onSearchChange(event: any) {
    this.paginator.current = 1
    this.getNotifications()
  }
  // --
  deleteItem(data: any = null) {
    this.deleteModal.show = false
    if (data) {
      this.subscription.add(
        this.notificationsService.delete(data.uuid)
          .subscribe(
            rs => {
              // console.log(rs)
              // if (getObject(rs)) {
              this.paginator.current = 1
              this.getNotifications()
              this.alertNotificationService.success('Notificaciones Push', 'Se eliminó la Notificación Push con éxito', { autoClose: false, keepAfterRouteChange: true })
              // }
              // else {
              // this.alertNotificationService.warn('Notificaciones Push', 'Ocurrio un error durante la eliminación, intente mas tarde', { autoClose: false, keepAfterRouteChange: true })
              // }
            },
            error => {
              this.alertNotificationService.error('Notificaciones Push', 'Ocurrio un error, intente más tarde', { autoClose: true, keepAfterRouteChange: true })
            }
          )
      )
    }
    else {
      this.deleteModal.item = null
    }
  }
  // --
  openBusiness(business) {
    this.router.navigate(['groups-business', business.data.uuidgroup, 'business', business.data.uuid, this.permissions?.edit ? 'edit' : 'read']);
  }
}
