<abarcms-loader-full *ngIf="loading"></abarcms-loader-full>
<div class="container-fluid">
    <div class="card border-card">
        <div class="card-content size" >

            <h6>{{title}}</h6>

            <div class="cardM">
                <div class="button-content size">
                  <div class="text-right">
                    <div class="buttons">
                      <app-boton [textContent]="'Crear Páginas'" (click)="crearPagina()"></app-boton>
                    </div>
                  </div>
                </div>
              </div>
            <div class="card size">
                <div class="card-body">
                    <label for="rolName" class="mr-2">Buscar</label>
                    <div class="form-group form-inline d-flex container-fluid">
                        <input class="form-control flex-fill mr-2" type="text" [(ngModel)]="buscaTxt" placeholder="Buscar">
                        <app-boton [textContent]="'Buscar'" (click)="buscar()" style="align-self: flex-start; width: auto;"></app-boton>
                      </div>
                       
                </div>
            </div>

            <app-view-results *ngIf="vista=='toSearchPage' && activeSearch==true" [clicks]="clicks"></app-view-results>

                   
            <div *ngIf="origin=='secciones' && activeSearch==true||origin=='paginas'" class="divRow groupButton">
                  <app-boton  [textContent]="'Guardar y salir'" (click)="guardarAsignacion()"></app-boton>
                  <app-boton [textContent]="'Cancelar'" [backgroundColor]="'#000000'" class="buttonCancelar" (click)="cancelar()"></app-boton>
              </div>
        </div>
    </div>
</div>
