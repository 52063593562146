<abarcms-loader-full *ngIf="loading"></abarcms-loader-full>
<div class="container-fluid">
  <div class="card border-card">
      <div class="card-content size" >
            <h6>{{title}}</h6>
            <div *ngIf="vista=='toSearch'" class="divRow"><app-boton [textContent]="'Crear grupo de visualización'" (click)="openViewCreate()"></app-boton></div>
            <div *ngIf="vista=='toCreate' || vista=='toUpdate' " class="card">
              <div class="card-body">
                  <div class="form-group ">
                      <label for="rolName">Nombre del Grupo de visualización</label>
                      <input matInput class="form-control" type="text" [(ngModel)]="inputNewGV" placeholder="Nombre" >
                  </div>      
              </div>
          </div>
          <div *ngIf="vista=='toCreate' || vista=='toUpdate'" class="card">
            <div class="card-body">
              <p>Seleccione un grupo de visualización</p>
                <div class="form-group">
                  <mat-radio-group  aria-labelledby="example-radio-group-label" [(ngModel)]="typeGroupChecked" (change)="checkingTypeGroup($event)">
                    <mat-radio-button class="radioGroup"  *ngFor="let catGrupoVisualizacion of catGruposVisualizacion"  [value]="catGrupoVisualizacion.id">
                      {{catGrupoVisualizacion.name}}
                    </mat-radio-button>
                  </mat-radio-group>
                </div> 
            </div>
        </div>
            <div class="card" *ngIf="vista=='toSearch' || vista=='toCreate' && typeGroupChecked!=0 && typeGroupChecked!=undefined || vista=='toUpdate'">
                <div class="card-body">
                    <div class="form-group ">
                        <label for="rolName">Buscar</label><br>
                        <input  *ngIf="vista=='toSearch'" matInput class="form-control" type="text" [(ngModel)]="inputSearchTGV" placeholder="Nombre de grupo de visualización " >
                        <input  *ngIf="vista=='toCreate' && typeGroupChecked==1 || vista=='toUpdate' && typeGroupChecked==1"  matInput class="form-control" type="text" [(ngModel)]="inputSearchG" placeholder="Nombre">
                        <!-- INICIO Filtro EMPRESAS-->
                        <div class="group4" *ngIf="vista=='toCreate' && typeGroupChecked==2 || vista=='toUpdate'&& typeGroupChecked==2">
                            <mat-select class="select" [(value)]="selectedGroup" (selectionChange)="selectionGroup()" multiple placeholder="Grupos">
                              <mat-option *ngFor="let grupo of grupos" [value]="grupo.group_id">{{grupo.name}}</mat-option>
                            </mat-select>
                          <input matInput class="form-control" type="text" [(ngModel)]="inputTextC" placeholder="Empresa" >
                        </div>
                        <!-- FIN Filtro EMPRESAS-->
                        <!-- INICIO Filtro SITIOS DE TRABAJO-->
                        <div class="group4" *ngIf="vista=='toCreate'  && typeGroupChecked==3 || vista=='toUpdate' && typeGroupChecked==3">
                          
                            <mat-select class="select" [(value)]="selectedGroup1" (selectionChange)="selectionGroup1()" placeholder="Grupos">
                              <mat-option *ngFor="let grupo of grupos" [value]="grupo.group_id">{{grupo.name}}</mat-option>
                            </mat-select>
                          
                            <mat-select class="select" [(value)]="selectedCompany" (selectionChange)="selectionCompany()" placeholder="Empresas">
                              <mat-option *ngFor="let company of companies" [value]="company.companyId">{{company.name}}</mat-option>
                            </mat-select>
                          
                            <mat-select class="select" [(value)]="selectedS" (selectionChange)="selectionState()" placeholder="Estados">
                              <mat-option *ngFor="let estado of states" [value]="estado.stateId" >{{estado.name}}</mat-option>
                            </mat-select>
                          
                            <mat-select class="select" [(value)]="selectedM" (selectionChange)="selectionMunicipio()" multiple placeholder="Municipios">
                              <mat-option *ngFor="let estado of municipios" [value]="estado.municipalityId">{{estado.name}}</mat-option>
                            </mat-select>
                        </div>
                        <!-- INICIO Filtro SITIOS DE TRABAJO-->
                        <!-- INICIO Filtro USUARIOS-->
                        <div class="group4" *ngIf="vista=='toCreate'  && typeGroupChecked==4 || vista=='toUpdate' && typeGroupChecked==4">
                          
                            <mat-select class="select" [(value)]="selectedGroup1" (selectionChange)="selectionGroup1()" placeholder="Grupos">
                              <mat-option *ngFor="let grupo of grupos" [value]="grupo.group_id">{{grupo.name}}</mat-option>
                            </mat-select>
                          
                            <mat-select class="select" [(value)]="selectedCompany" (selectionChange)="selectionCompany()" placeholder="Empresas">
                              <mat-option *ngFor="let company of companies" [value]="company.companyId">{{company.name}}</mat-option>
                            </mat-select>

                            <mat-select class="select" [(value)]="selectedS" (selectionChange)="selectionState()" placeholder="Estados">
                              <mat-option *ngFor="let estado of states" [value]="estado.stateId" >{{estado.name}}</mat-option>
                            </mat-select>                          
                            
                            <mat-select class="select" [(value)]="selectedM" (selectionChange)="selectionMunicipio()" placeholder="Municipios">
                              <mat-option *ngFor="let municipio of municipios" [value]="municipio.municipalityId">{{municipio.name}}</mat-option>
                            </mat-select>

                            <mat-select class="select" [(ngModel)]="selectedWorkPlace" (selectionChange)="selectionWorkPlace()" multiple placeholder="Sitios de trabajo">
                              <mat-option *ngFor="let workPlace of workPlaces" [value]="workPlace.workPlaceId">{{workPlace.workPlace}}</mat-option>
                            </mat-select>
                            <br>
                            <mat-select class="select" [(ngModel)]="selectedTypeUser" (selectionChange)="selectionTypeUsers()" placeholder="Tipo de usuario">
                              <mat-option *ngFor="let typeUser of typeUsers" [value]="typeUser.id">{{typeUser.name}}</mat-option>
                            </mat-select>
                            <br>
                            <mat-select class="select" [(ngModel)]="selectedGenero" (selectionChange)="selectionGenero()" placeholder="Genero">
                              <mat-option [value]="'M'">Hombre</mat-option>
                              <mat-option [value]="'F'">Mujer</mat-option>
                            </mat-select>
                            <br>
                            <mat-select class="select" [(ngModel)]="selectedEnfermedad" (selectionChange)="selectionEnfermedades()" placeholder="Enfermedades">
                              <mat-option *ngFor="let enfermedad of enfermedades" [value]="enfermedad.id">{{enfermedad.name}}</mat-option>
                            </mat-select>
                            <br>
                            <input matInput class="form-control" type="text" [(ngModel)]="inputUserId" placeholder="Id Usuario" >
                            
                        </div>
                        <!-- INICIO Filtro USUARIOS-->
                      </div>      
                
                    <div class="form-group">
                      <mat-radio-group *ngIf="vista=='toSearch'" aria-labelledby="example-radio-group-label"  [(ngModel)]="typeGroupChecked" (change)="checkingTypeGroup($event)">
                        <mat-radio-button class="radioGroup" *ngFor="let catGrupoVisualizacion of catGruposVisualizacion"  [value]="catGrupoVisualizacion.id">
                          {{catGrupoVisualizacion.name}}
                        </mat-radio-button>
                      </mat-radio-group>
                            <app-boton [textContent]="'Buscar'" (click)="buscar()"></app-boton>
                    </div> 
                </div>
            </div> 


            

          <app-view-results *ngIf="vista=='toSearch'&&activeSearch==true" [clicks]="clicks"></app-view-results>

          <app-view-results *ngIf="vista=='toUpdate'||vista=='toCreate'&&activeSearch==true" [clicks]="clicks"></app-view-results>
   
          <div class="divRow groupButton">
            <app-boton *ngIf="vista=='toUpdate' " [textContent]="'Guardar'" (click)="guardarCambiosSeleccion()" ></app-boton>
                <app-boton *ngIf="vista=='toCreate' && enableButton==true " [textContent]="'Guardar y siguiente'" (click)="guardarSeleccion()" ></app-boton>
                <app-boton *ngIf="vista=='toSearch'&& activeSearch==true&&origin=='paginas'" [textContent]="'Guardar y salir'" (click)="guardarAsignacion()" ></app-boton>
                <app-boton *ngIf="vista!='toSearch'||activeSearch==true&&origin=='paginas'" [textContent]="'Cancelar'" [backgroundColor]="'#000000'" class="buttonCancelar" (click)="cancelar()"></app-boton>
            </div>
        </div>
    </div>
</div>

