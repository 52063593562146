import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { constantes } from "../../constants/seccion-monitor";
import {
  CatGrupoVisualizacion,
  DisplayGroup,
  Pagination,
  Sections,
} from "../../dto/secciones-monitorDTO";
import { SeccionMonitorService } from "../../services/seccion-monitor.service";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { NgForm } from "@angular/forms";
import { DetailsDialogComponent } from "../details-dialog/details-dialog.component";

@Component({
  selector: "app-secciones",
  templateUrl: "./secciones.component.html",
  styleUrls: ["./secciones.component.css"],
})
export class SeccionesComponent implements OnInit {
  title: string;
  vista: string;

  pageSize = constantes.pagination.pageSize;
  page: number = 0;
  total_rows: number = 0;

  inputSearchSection: string;
  cambio: number;
  contador: number = 0;
  displayGroupTypeId: number;
  catGruposVisualizacion: CatGrupoVisualizacion[];
  paginator: Pagination;
  displayGroups: DisplayGroup[] = [];
  displayGroupsList: DisplayGroup[] = [];
  selectedDisplayGroupName: string;
  selectedDisplayGroupId: number;
  sections: Sections[];
  section: Sections[];
  //showOptions: boolean = false;
  showFilters: boolean = false;
  loading: boolean = false;
  sectionName: string;
  sectionDescription: string;
  sectionStatus: number;
  sectionPosicion: number;
  sectionColor: string = "#ffffff";
  previewImage!: string;
  base64Image!: string;
  fileName: string;
  formSection: FormGroup;
  constructor(
    private seccionesMonitorService: SeccionMonitorService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.vista = constantes.secciones.vista;
    this.getCatGruposVisualizacion();

    if (this.vista == "toSearch") {
      constantes.title = "Consulta de secciones";
    } else if (this.vista == "toEdit") {
      constantes.title = "Editar sección";
    }
  }

  async ngOnInit(): Promise<void> {
    this.title = constantes.title;
    if (this.vista == "toEdit") {
      (
        await this.seccionesMonitorService.getSectionBySectionId(
          constantes.secciones.sectionId
        )
      ).subscribe(
        (result) => {
          console.log("consulta exitosa por sectionId");
          this.sectionName = result.data.name;
          this.sectionDescription = result.data.description;
          this.previewImage = result.data.icon;
          this.sectionColor = result.data.color;
          this.sectionStatus = result.data.status;
          this.sectionPosicion = result.data.position;
          constantes.secciones.pages = result.data.pages;
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      this.sectionName = "";
      this.sectionDescription = "";
      this.previewImage = "";
      this.sectionStatus = null;
      this.sectionPosicion = 1;
    }
  }

  focus() {
    this.displayGroupsList = this.displayGroups;
  }

  async onInput(event: any) {
    if (this.displayGroups.length > 0) {
      const value = event.target.value;
      const displayGroups = this.displayGroups.reduce((acc, group) => {
        if (group.name.toLowerCase().startsWith(value.toLowerCase())) {
          acc.push(group);
        }
        return acc;
      }, []);
      if (displayGroups.length > 0) {
        //this.showOptions=true
        this.displayGroupsList = displayGroups;
      } else {
        this.loading = true;
        let dataSearchJSON = {
          pageSize: constantes.pagination.pageSize,
          page: this.page,
          sortDirection: "asc",
          sort: "dg.name",
          filters: {
            name: this.selectedDisplayGroupName,
            displayGroupTypeId: [constantes.secciones.displayGroupTypeId],
          },
        };
        (
          await this.seccionesMonitorService.getAllGrupoVisualizacion(
            dataSearchJSON
          )
        ).subscribe((result) => {
          this.displayGroups = result.data.displayGroup;
          console.log(this.displayGroups);
          const displayGroups = this.displayGroups.reduce((acc, group) => {
            if (group.name.toLowerCase().startsWith(value.toLowerCase())) {
              acc.push(group);
            }
            return acc;
          }, []);
          //this.showOptions=displayGroups.length>0?true:false
          this.displayGroupsList = displayGroups;
          this.loading = false;
        });
      }
    } else {
      this.getAllGrupoVisualizacion("");
    }
  }

  onOptionSelected(sections: number, name: string) {
    this.selectedDisplayGroupName = name;
    this.selectedDisplayGroupId = sections;
    //this.showOptions = false;
    this.getAllSections();
  }

  onFileSelected(event: Event): void {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file.type === "image/png") {
      if (file) {
        this.fileName = file.name;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.previewImage = reader.result as string;
          this.base64Image = reader.result as string;
        };
      }
    } else {
      alert("Formato no permitido");
    }
  }

  getCatGruposVisualizacion() {
    this.loading = true;
    this.seccionesMonitorService.getCatGrupoVisualizacion().subscribe(
      (result) => {
        console.log(result);
        this.catGruposVisualizacion = result.data;
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.loading = false;
      }
    );
  }

  displayGroupTypeIdChecked(): void {
    constantes.secciones.displayGroupTypeId = this.displayGroupTypeId;
    this.inputSearchSection = "";
    this.selectedDisplayGroupName = null;
    this.getAllGrupoVisualizacion("");
    this.showFilters = true;
  }

  async getAllGrupoVisualizacion(name: string): Promise<void> {
    this.loading = true;
    let dataSearchJSON = {
      pageSize: constantes.pagination.pageSize,
      page: this.page,
      sortDirection: "asc",
      sort: "dg.name",
      filters: {
        name: name,
        displayGroupTypeId: [constantes.secciones.displayGroupTypeId],
      },
    };
    (
      await this.seccionesMonitorService.getAllGrupoVisualizacion(
        dataSearchJSON
      )
    ).subscribe(
      (result) => {
        //console.log(result)
        this.displayGroups = result.data.displayGroup;
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.loading = false;
      }
    );
  }

  selectionDisplayGroup(): void {
    this.getAllSections();
  }

  searchSection(): void {
    this.getAllSections();
  }

  async getAllSections(): Promise<void> {
    this.loading = true;
    let dataSectionsJSON = {
      pageSize: this.pageSize,
      page: this.page + 1,
      sortDirection: "asc",
      sort: "s.position",
      filters: {
        name: this.inputSearchSection,
        sectionIds: [],
        displayGroupIds: [this.selectedDisplayGroupId],
      },
    };
    if (this.selectedDisplayGroupId == undefined) {
      delete dataSectionsJSON.filters.displayGroupIds;
    }

    (
      await this.seccionesMonitorService.getAllSections(dataSectionsJSON)
    ).subscribe(
      (result) => {
        this.total_rows = result.data.pagination.total_rows;
        console.log("Consulta exitosa de secciones desde getAllSections");
        this.sections = result.data.sections;
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.loading = false;
      }
    );
  }

  openEdit(sectionId: number): void {
    sessionStorage.setItem("secciones", "true");
    constantes.secciones.vista = "toEdit";
    constantes.secciones.sectionId = sectionId;
    this.router.navigate(["/seccionesMonitor/editarSeccion"]);
  }
  openCreateSection(): void {
    sessionStorage.setItem("secciones", "true");
    constantes.secciones.vista = "toCreate";
    this.resetData();
    this.router.navigate(["/seccionesMonitor/CrearSeccion"]);
  }

  async createUpdateSection(mode: number, f: NgForm): Promise<void> {
    if (f.valid == true) {
      let createUpdateSectionJson = {
        nombre: this.sectionName,
        descripcion: this.sectionDescription,
        icono: this.base64Image,
        posicion: this.sectionPosicion,
        color: this.sectionColor,
        estatus: this.sectionStatus,
      };
      if (this.vista == "toEdit") {
        (
          await this.seccionesMonitorService.updateSection(
            createUpdateSectionJson,
            constantes.secciones.sectionId
          )
        ).subscribe(
          (result) => {
            if (mode == 1) {
              if (result.data.pages.length > 0) {
                for (let page = 0; page < result.data.pages.length; page++) {
                  constantes.secciones.pageIds.push(
                    result.data.pages[page].pageId
                  );
                  constantes.secciones.atachPagesIds.push(
                    result.data.pages[page].pageId
                  );
                }
                console.log(
                  "Se obtinen los pageIds que ya se encontraban asignados"
                );
              }
              //constantes.secciones.vista='updatePage';
              constantes.origin = "secciones";
              constantes.vista = "toSearchPage";
              constantes.activeSearch = true;
              constantes.title = "Asignar páginas";
              this.router.navigate(["/seccionesMonitor/asignarPagina"]);
            } else {
              sessionStorage.removeItem("secciones");
              constantes.secciones.vista = "toSearch";
              this.router.navigate(["/seccionesMonitor/consultarSecciones"]);
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
      if (this.vista == "toCreate") {
        let sectionList = { secciones: [createUpdateSectionJson] };
        (
          await this.seccionesMonitorService.createSection(sectionList)
        ).subscribe(
          (result) => {
            constantes.secciones.accion = "create";
            constantes.secciones.sectionId = result.data[0].sectionId;
            this.openConfirmDialog("Se guardo correctamente");
            console.log(result);

            constantes.secciones.vista = "toSearch";
            constantes.origin = "secciones";
            constantes.title = "Asignar páginas";
            this.router.navigate(["/seccionesMonitor/asignarPagina"]);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    } else {
      this.openConfirmDialog("Por favor verifica los campos requeridos");
    }
  }
  deleteSection(sectionId: number): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { origen: "deleteSection", titulo: "AVISO.." },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result == 1) {
        //1= se usa para indicar que el usuario cancelo la eliminacion
        console.log("El usuario cancelo la eliminacion");
      } else if (result == 0) {
        //0=se usa para idicar que el usuario continuo con la eliminacion de la seccion
        (await this.seccionesMonitorService.deleteSection(sectionId)).subscribe(
          (result) => {
            console.log("Seccion eliminada correctamente");
            this.getAllSections();
          },
          (error) => {
            console.log(error);
          }
        );
      }
    });
  }

  cancel(): void {
    sessionStorage.removeItem("secciones");
    constantes.secciones.vista = "toSearch";
    this.router.navigate(["/seccionesMonitor/consultarSecciones"]);
  }

  cambiarPagina(e: PageEvent) {
    this.page = e.pageIndex;
    this.getAllSections();
  }

  resetData(): void {
    constantes.secciones.pageIds = [];
    constantes.secciones.pageName = "";
    constantes.secciones.sectionId = 0;
    constantes.origin = "";
  }

  openConfirmDialog(mensaje: string | null): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { origen: "crearPagina", titulo: mensaje },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 1) {
        console.log("The dialog was closed");
      } else if (result == 0) {
      }
    });
  }

  openDetailsSection(sectionId: number): void {
    const dialogRef = this.dialog.open(DetailsDialogComponent, {
      data: { sectionId: sectionId },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
    });
  }
}
