import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { constants } from 'src/app/constants';
import { PollBusiness, PollGroup } from 'src/app/dto/Poll';
import { SessionService } from 'src/app/services';
import { GroupDataService } from 'src/app/services/data/group-data.service';
import { UsersDataService } from 'src/app/services/data/users-data.service';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { getArray, getObject } from 'src/app/utils/_http';
import { getStorage, setStorage } from 'src/app/utils/_utils';

@Component({
  selector: 'app-groups-business-template',
  templateUrl: './groups-business-template.component.html',
  styleUrls: ['./groups-business-template.component.css']
})
export class GroupsBusinessTemplateComponent implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription
  @Input() data: PollGroup[] = []
  @Input() action: string | null = null
  @Input() permissions: any = {}
  @Output() onAction: EventEmitter<any> = new EventEmitter
  @Input() plantilla: any = () =>{}

  module: string = 'groups'
  groupData: {
    groups: PollGroup[],
    business: PollBusiness[],
    groupSelected: PollGroup | null,
    businessSelected: PollBusiness[],
    error: any,
  } = {
      groups: [],
      business: [],
      groupSelected: null,
      businessSelected: [],
      error: null,
    }

  hover = {
    item: '-1',
  }

  constructor(
    private userService: UsersDataService,
    private session: SessionService,
    private groupService: GroupDataService,
    private modalHelper: ModalHelperService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.modalHelper.poll$
        .subscribe(
          value => {
            if (value?.module) {
              if (value?.module == 'validForm') {
                this.modalHelper.pollChange({
                  module: 'validFormResponse',
                  invalid: this.validation()
                })
              }
            }
          }
        )
    )
    this.getGroups()
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  validation(): boolean {
    this.groupData.error = null
    if (!this.data.length) {
      this.groupData.error = 2
      return true
    }
    return false
  }

  getGroups() {
    this.subscription.add(
      this.groupService.readDropdown()
        .subscribe(
          rs => {
            this.groupData.groups = getArray(rs)
          },
          error => {
          }
        )
    )
  }

  onSection(action: string, indexMain?: number, indexChild?: number) {
    switch (action) {
      case 'group':
        this.groupData.business = this.groupData.groupSelected?.business ?? []
        this.groupData.businessSelected = []
        break;
      case 'business':
        this.groupData.error = null
        let businesSelect
        if (this.groupData.groupSelected) {
          if(!Array.isArray(this.groupData.businessSelected)){
            businesSelect = [this.groupData.businessSelected]
          }else{
            businesSelect = this.groupData.businessSelected
          }
          if (businesSelect.length) {
            let group = { ...this.groupData.groupSelected }
            group.business = [...businesSelect]
            let indexGroup: number = -1
            this.data.map((x, i) => {
              if (x.uuid == group.uuid) {
                x.business = group.business
                indexGroup = i
              }
            })
            if (indexGroup == -1) {
              this.data.pop();
              this.data.push(group)
            }
            this.onAction.emit({
              module: this.module,
              action: action,
              data: [...this.data]
            })
          }
        }
        else {
          this.groupData.error = 1
        }
        break;
      case 'business-all':
        this.groupData.error = null
        if (this.groupData.groupSelected) {
          this.groupData.businessSelected = this.groupData.groupSelected?.business
          this.onSection('business')
          setTimeout(() => {
            this.groupData.businessSelected = []
          }, 200)
        }
        else {
          this.groupData.error = 1
        }
        break;
      case 'delete':
        let items = JSON.parse(JSON.stringify(this.data))
        let deleteItem = JSON.parse(JSON.stringify(this.data[indexMain]))
        if (indexChild != -1) {
          deleteItem.business = [JSON.parse(JSON.stringify(this.data[indexMain].business[indexChild]))]
          items[indexMain].business.splice(indexChild, 1)
          if (!items[indexMain].business.length) {
            items.splice(indexMain, 1)
          }
        }
        else {
          items.splice(indexMain, 1)
        }
        this.onAction.emit({
          module: this.module,
          action: action,
          data: [...items],
          items: deleteItem
        })
        break;
      case 'group-list':
        this.hover.item = '-1'
        if (indexMain != -1) {
          this.hover.item = `${indexMain}`
        }
        if (indexChild != -1) {
          this.hover.item += `${indexChild}`
        }
        break;
    }
  }

}
