import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ResponseData } from 'src/app/dto/ResponseData';
import { SettingsService } from '../../settings.service';
import { createResponseData, } from 'src/app/utils/_http';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../api.service';
import { getStorage } from 'src/app/utils/_utils';
import { constants } from 'src/app/constants';

@Injectable({
  providedIn: 'root'
})
export class PollDataWsService {
  private pathInfo: any;
  private baseUrl: string;
  private segment: any;
  private baseUrlMonitor: any;
  private segmentPoll: any;
  constructor(
    private settings: SettingsService,
    private api: ApiService
  ) {
    this.pathInfo = this.settings.get('pathInfo');
    this.baseUrl = this.pathInfo.base.baseUrl + this.pathInfo.base.prefix;
    this.segment = this.pathInfo.res.admin['poll'];
    this.baseUrlMonitor = this.pathInfo.base.monitor;
    this.segmentPoll = this.pathInfo.res.admin['monitor'];
  }
  private create(body: object, accessToken: string): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix;
    return this.api.post(endpoint, body);
  }
  private read(pagination?: any, searchText?: string): Observable<ResponseData> {
    let options = {}
    if (pagination) {
      options = {
        'X-Current-Page': pagination.current || 1,
        'X-Items-Page': pagination.perPage || 100,
        'X-Search': searchText || "",
      };
    }
    let endpoint = this.baseUrl + this.segment.prefix;
    return this.api.get(endpoint, options);
  }
  private readOne(uuid: any): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.getOne.replace(':uuid', uuid);
    return this.api.get(endpoint);
  }
  private update(uuid: any, body: object): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.edit.replace(':uuid', uuid);
    return this.api.put(endpoint, body, true, true);
  }
  private updateWithOutSection(uuid: any, body: object): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.edit + this.segment.resources.noSection
    endpoint = endpoint.replace(':uuid', uuid);
    return this.api.put(endpoint, body, true, true);
  }
  private batch(body: any): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.deleteBatch;
    return this.api.put(endpoint, body, true, true);
  }
  private typeQuestion(): Observable<ResponseData> {
    let options = {};
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.typeQuestion.prefix + this.segment.question.prefix
    return this.api.get(endpoint, options);
  }
  private repositoryCreate(body: object): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.question.prefix
    return this.api.post(endpoint, body, true, {}, true);
  }
  private repositoryRead(pagination: any): Observable<ResponseData> {
    let options = {
      'X-Current-Page': pagination.current,
      'X-Items-Page': pagination.perPage
    };
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.question.prefix
    return this.api.get(endpoint, options);
  }
  private repositoryReadOne(uuid: any): Observable<ResponseData> {
    let options = {};
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.question.prefix + this.segment.resources.getOne.replace(':uuid', uuid)
    return this.api.get(endpoint, options);
  }
  private repositoryUpdate(uuid: any, body: object): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.question.prefix + this.segment.resources.edit.replace(':uuid', uuid);
    return this.api.put(endpoint, body, true, true);
  }
  // private repositoryDelete(body: any): Observable<ResponseData> {
  //   let endpoint = this.baseUrl + this.segment.prefix + this.segment.question.prefix + this.segment.resources.deleteBatch;
  //   return this.api.put(endpoint, body);
  // }
  private monitorLogin(): Observable<ResponseData> {
    let endpoint = this.baseUrlMonitor.url + this.segmentPoll.login
    return this.api.post(endpoint, this.baseUrlMonitor.credentials, false)
  }
  private monitorListPoll(data: any, system: string): Observable<ResponseData> {
    let endpoint = this.baseUrlMonitor.url + this.segmentPoll.list
    endpoint = endpoint.replace('{id}', data.user_id).replace('{system}', system)
    return this.api.get(endpoint, { Authorization: `Bearer ${data.token}` }, false)
  }
  private monitorAddPoll(body: any, data: any): Observable<ResponseData> {
    let endpoint = this.baseUrlMonitor.url + this.segmentPoll.poll.prefix + this.segmentPoll.poll.resource.add
    endpoint = endpoint.replace('{id}', data.user_id)
    return this.api.post(endpoint, body, false, { Authorization: `Bearer ${data.token}` }, true)
  }
  private monitorUpdatePoll(body: any, data: any): Observable<ResponseData> {
    let endpoint = this.baseUrlMonitor.url + this.segmentPoll.poll.prefix + this.segmentPoll.poll.resource.update
    endpoint = endpoint.replace('{id}', data.user_id)
    return this.api.put(endpoint, body, false, true, { Authorization: `Bearer ${data.token}` })
  }
  private monitorAddBusiness(body: any, data: any): Observable<ResponseData> {
    let endpoint = this.baseUrlMonitor.url + this.segmentPoll.poll.prefix + this.segmentPoll.poll.resource.add + this.segmentPoll.poll.business
    endpoint = endpoint.replace('{id}', data.user_id).replace('{poll}', data.poll)
    return this.api.post(endpoint, body, false, { Authorization: `Bearer ${data.token}` }, true)
  }
  private monitorAddSection(body: any, data: any): Observable<ResponseData> {
    let endpoint = this.baseUrlMonitor.url + this.segmentPoll.poll.prefix + this.segmentPoll.poll.resource.add + this.segmentPoll.poll.section
    endpoint = endpoint.replace('{id}', data.user_id).replace('{poll}', data.poll)
    return this.api.post(endpoint, body, false, { Authorization: `Bearer ${data.token}` }, true)
  }
  private monitorAddQuestion(body: any, data: any): Observable<ResponseData> {
    let endpoint = this.baseUrlMonitor.url + this.segmentPoll.poll.prefix + this.segmentPoll.poll.resource.add + this.segmentPoll.poll.section + this.segmentPoll.poll.question
    endpoint = endpoint.replace('{id}', data.user_id).replace('{poll}', data.poll).replace('{section}', data.section[data.index])
    return this.api.post(endpoint, body, false, { Authorization: `Bearer ${data.token}` }, true)
  }
  private monitorActivated(data: any): Observable<ResponseData> {
    let endpoint = this.baseUrlMonitor.url + this.segmentPoll.poll.prefix + this.segmentPoll.poll.activate
    endpoint = endpoint.replace('{id}', data.user_id).replace('{poll}', data.poll)
    return this.api.post(endpoint, null, false, { Authorization: `Bearer ${data.token}` }, true)
  }
  private readCountries(accessToken: string) {
    let endpoint = this.baseUrl + "/getPaises";
    return this.api.get(endpoint);
  }
  private readPolls(body: object, accessToken: string): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.all;
    return this.api.post(endpoint, body, true, {}, false);
  }
  private updateGroups(body: object): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.groups.prefix;
    return this.api.post(endpoint, body, true, {}, true);
  }
}
