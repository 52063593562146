import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { constantes } from "../../constants/seccion-monitor";
import { SeccionMonitorService } from "../../services/seccion-monitor.service";
import { VerPaginaService } from "../../services/ver-pagina.service";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-view-results",
  templateUrl: "./view-results.component.html",
  styleUrls: ["./view-results.component.css"],
})
export class ViewResultsComponent implements OnChanges, OnInit {
  loading: boolean = false;
  grupos: any = [];
  companies: any[];
  viewResults: any = [];
  paginas: any = [];
  pageSize = constantes.pagination.pageSize; //<-------------------------------------
  i: number = 1; //         --
  desde: number = 0; //      --variables para el paginador
  hasta: number = constantes.pagination.pageSize; //      --
  total_rows: number = 0; //<-----------------------------------
  pageIndex: number = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  vista!: string;
  activeSearch!: boolean;
  page: number;
  origin: string = constantes.origin;
  pageView: string;
  @Input() clicks: number;
  constructor(
    private verPaginaService: VerPaginaService,
    private seccionesMonitorService: SeccionMonitorService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    this.vista = constantes.vista;
    this.activeSearch = constantes.activeSearch;

    /* if (this.vista=='toSearch'&&this.activeSearch==true) {
        this.getAllGrupoVisualizacion();
    }*/
    /*
    if (this.vista=='toUpdate'||this.vista=='toCreate') {
      if (constantes.ids.idCatGV==1||constantes.detallesGrupoVisualizacion.idTGV==1) {
        this.getGroupByTextName();
      }

        if (constantes.ids.idCatGV==2||constantes.detallesGrupoVisualizacion.idTGV==2) {
        this.getCompaniesByGroupsIdsCompanyName();
        }
        
        if (constantes.ids.idCatGV==3||constantes.detallesGrupoVisualizacion.idTGV==3) {
        this.getWorkPlace();
        }
        
        if (constantes.ids.idCatGV==4||constantes.detallesGrupoVisualizacion.idTGV==4) {
        this.searchUsersGroupByWorkPlaceId();
        }
    }

    if (this.vista=='toSearchPage') {
      this.getAllPages();
    }
*/
  }

  /*getAllGrupoVisualizacion():void{
    let filters;
    if (constantes.ids.idCatGV>0) {
      filters={
        name:constantes.inputs.inputSearchTGV,
        displayGroupTypeId:[constantes.ids.idCatGV]
      }
    }else{
      filters={
        name:constantes.inputs.inputSearchTGV,
      }
    }

    let data={
      pageSize: constantes.pagination.pageSize,
      page:1,
      sortDirection: "asc",
      sort: "dg.name",
      filters:filters
    }
    constantes.jSONs.filters=data.filters
    
    this.seccionesMonitorService.getAllGrupoVisualizacion(data).subscribe(result=>{
      constantes.pagination.total_rows=result.data.pagination.total_rows
      this.total_rows=result.data.pagination.total_rows
    },error=>{
      console.log(error)
    });
    
  }*/

  getGroupByTextName(): void {
    let searchGroupJson = {
      pageSize: constantes.pagination.pageSize,
      page: 1,
      sortDirection: "asc",
      sort: "g.groupId",
      filters: {
        name: constantes.inputs.inputSearchGroup,
        displayGroupTypeId: [constantes.ids.idCatGV],
      },
    };
    constantes.jSONs.filters = searchGroupJson.filters;
    this.seccionesMonitorService.searchGroup(searchGroupJson).subscribe(
      (result) => {
        constantes.pagination.total_rows = result.data.pagination.total_rows;
        this.total_rows = result.data.pagination.total_rows;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getCompaniesByGroupsIdsCompanyName(): void {
    let searchCompaniesJson = {
      pageSize: constantes.pagination.pageSize,
      page: 1,
      sortDirection: "asc",
      sort: "c.name",
      filters: {
        groupIds: constantes.ids.groupIds,
        name: constantes.inputs.inputTextCompany,
      },
    };
    if (constantes.ids.groupIds == 0) {
      delete searchCompaniesJson.filters.groupIds;
    }
    constantes.jSONs.filters = searchCompaniesJson.filters;
    this.seccionesMonitorService.searchCompanies(searchCompaniesJson).subscribe(
      (result) => {
        this.total_rows = result.data.pagination.total_rows;
        this.viewResults = [];
        constantes.pagination.total_rows = result.data.pagination.total_rows;
        if (constantes.elementsIds.length > 0) {
          for (
            let element = 0;
            element < result.data.companys.length;
            element++
          ) {
            this.viewResults.push({
              Id: result.data.companys[element].companyId,
              name: result.data.companys[element].name,
              checked: constantes.elementsIds.includes(
                result.data.companys[element].companyId
              ),
            });
          }
        } else {
          for (
            let element = 0;
            element < result.data.companys.length;
            element++
          ) {
            this.viewResults.push({
              Id: result.data.companys[element].companyId,
              name: result.data.companys[element].name,
              checked: false,
            });
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getWorkPlace(): void {
    let workPlacesJson = {
      pageSize: constantes.pagination.pageSize,
      page: 1,
      sortDirection: "asc",
      sort: "st.name",
      filters: {
        groupsIds: [constantes.usersGroupByWorkP.groupId], //solo para precargar datos
        companyIds: [constantes.ids.companyIds],
        stateIds: [constantes.ids.stateIds],
        municipalityIds: constantes.ids.municipalityIds,
      },
    };
    constantes.jSONs.filters = workPlacesJson.filters;
    if (constantes.ids.stateIds == null) {
      delete workPlacesJson.filters.stateIds;
      delete workPlacesJson.filters.municipalityIds;
    } else if (constantes.ids.municipalityIds == null) {
      delete workPlacesJson.filters.municipalityIds;
    }

    //delete workPlacesJson.filters.groupsIds
    this.seccionesMonitorService
      .geAllWorkPlaceByCompanyId(workPlacesJson)
      .subscribe(
        (result) => {
          if (result.code == 200) {
            this.total_rows = result.data.pagination.total_rows;
            constantes.pagination.total_rows =
              result.data.pagination.total_rows;
            if (constantes.elementsIds.length > 0) {
              for (
                let element = 0;
                element < result.data.workPlaces.length;
                element++
              ) {
                this.viewResults.push({
                  Id: result.data.workPlaces[element].workPlaceId,
                  name: result.data.workPlaces[element].nameClueg,
                  checked: constantes.elementsIds.includes(
                    result.data.workPlaces[element].workPlaceId
                  ),
                });
              }
            } else {
              for (
                let element = 0;
                element < result.data.workPlaces.length;
                element++
              ) {
                this.viewResults.push({
                  Id: result.data.workPlaces[element].workPlaceId,
                  name: result.data.workPlaces[element].nameClueg,
                  checked: false,
                });
              }
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  searchUsersGroupByWorkPlaceId(): void {
    this.loading = true;
    let usersGroupByWorkP = {
      pageSize: constantes.pagination.pageSize,
      page: 1,
      sortDirection: "asc",
      sort: "us.id",
      filters: {
        groupsIds: [constantes.usersGroupByWorkP.groupId], //solo para precargar datos
        idCompanys: [constantes.usersGroupByWorkP.companyId],
        stateIds: [constantes.usersGroupByWorkP.stateId],
        municipalityIds: [constantes.usersGroupByWorkP.municipalityId],
        workPlaces: constantes.usersGroupByWorkP.workPlaceId,
      },
    };
    constantes.jSONs.filters = usersGroupByWorkP.filters; //filtros que se guardaran
    if (constantes.usersGroupByWorkP.groupId == 0) {
      delete usersGroupByWorkP.filters.groupsIds;
    }
    if (constantes.usersGroupByWorkP.companyId == 0) {
      delete usersGroupByWorkP.filters.idCompanys;
    }
    if (constantes.usersGroupByWorkP.stateId == 0) {
      delete usersGroupByWorkP.filters.stateIds;
    }
    if (constantes.usersGroupByWorkP.municipalityId == 0) {
      delete usersGroupByWorkP.filters.municipalityIds;
    }
    if (constantes.usersGroupByWorkP.workPlaceId.length > 0) {
      delete usersGroupByWorkP.filters.workPlaces;
    }

    if (this.vista == "toUpdate") {
      delete usersGroupByWorkP.filters.groupsIds;
      delete usersGroupByWorkP.filters.stateIds;
      delete usersGroupByWorkP.filters.municipalityIds;
    }

    this.seccionesMonitorService
      .searchUsersGroupByWorkPlaceId(usersGroupByWorkP)
      .subscribe(
        (result) => {
          if (result.code == 404) {
            this.openConfirmDialog("No se encontraron resultados");
          }
          if (result.code == 200) {
            this.total_rows = result.data.pagination.total_rows;
            if (this.total_rows <= 0) {
              this.openConfirmDialog("No se encontró ningún usuario");
            } else {
              this.viewResults = [];
              if (constantes.elementsIds.length > 0) {
                for (
                  let element = 0;
                  element < result.data.usuarios.length;
                  element++
                ) {
                  this.viewResults.push({
                    Id: result.data.usuarios[element].id_usuario,
                    name: result.data.usuarios[element].nombre,
                    checked: constantes.elementsIds.includes(
                      result.data.usuarios[element].id_usuario
                    ),
                  });
                }
              } else {
                for (
                  let element = 0;
                  element < result.data.usuarios.length;
                  element++
                ) {
                  this.viewResults.push({
                    Id: result.data.usuarios[element].id_usuario,
                    name: result.data.usuarios[element].nombre,
                    checked: false,
                  });
                }
              }
            }
          }
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.loading = false;
        }
      );
  }

  async getAllPages(): Promise<void> {
    this.loading = true;
    let data = {
      pageSize: constantes.pagination.pageSize,
      page: 1,
      sortDirection: "asc",
      sort: "p.name",
      filters: {
        name:
          constantes.paginas.inputSearchPage != undefined
            ? constantes.paginas.inputSearchPage
            : "",
        pageIds:
          constantes.paginas.inputSearchPage == "" ||
          constantes.paginas.inputSearchPage == undefined
            ? constantes.secciones.pageIds
            : [],
        withSectionId:
          constantes.secciones.accion == "create"
            ? null
            : constantes.secciones.sectionId,
        withAssignedSections: false /*withTrashed:false*/,
      },
    };
    (await this.verPaginaService.getAllPaginas(data)).subscribe(
      (result) => {
        this.paginas = [];
        for (let page = 0; page < result.data.pages.length; page++) {
          this.paginas.push({
            id: result.data.pages[page].id,
            name: result.data.pages[page].name,
            url: result.data.pages[page].url,
            status: result.data.pages[page].status,
            descripcion: result.data.pages[page].descripcion,
            createdAt: result.data.pages[page].createdAt,
            updatedAt: result.data.pages[page].updatedAt,
            checked: constantes.secciones.pageIds.includes(
              result.data.pages[page].id
            ),
          });
        }
        this.total_rows = result.data.pagination.total_rows;

        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.loading = false;
      }
    );
  }

  changeUsersPage(e: PageEvent) {
    this.desde = e.pageIndex * e.pageSize;
    this.hasta = this.desde + e.pageSize;
    this.page = e.pageIndex + this.i;
    this.pageIndex = e.pageIndex;
  }

  resetFiltros(): void {
    constantes.usersGroupByWorkP.groupId = 0;
    constantes.usersGroupByWorkP.companyId = 0;
    constantes.usersGroupByWorkP.stateId = 0;
    constantes.usersGroupByWorkP.municipalityId = 0;
    constantes.usersGroupByWorkP.workPlaceId = [];
  }

  openConfirmDialog(mensaje: string | null): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { origen: "crearPagina", titulo: mensaje },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 1) {
        console.log("The dialog was closed");
      } else if (result == 0) {
      }
    });
  }
}
