<div class="content">
  <div class="container">
    <div class="card login-card">
      <div class="card-header">
        <div class="back-wrap">
          <a (click)="onGoBack()">
            <i class="fas fa-arrow-left"></i>
            <span class="light-text">Regresar</span>
          </a>
        </div>
        <div class="logo-wrap">
          <a (click)="onGoHome()">
            <img src="assets/images/logo.svg" alt="">
          </a>
        </div>
        <h3>Registro</h3>
      </div>
      <div class="card-body">
        <form [formGroup]="forma">
          <div class="form-group">
            <label for="name">Nombre</label>
            <input autocomplete="off" type="text" class="form-control" placeholder="Jessica" formControlName="name"
              [ngClass]="{'is-invalid invalidFieldForm': validName}"
              [ngStyle]="validName && {'border-color': 'red','margin-bottom': '3px'}">
            <div class="invalidFieldForm" style="color: red;font-size: 13px;" *ngIf="validName">
              Debe ingresar al menos 2 caracteres
            </div>
          </div>
          <div class="form-group">
            <label for="last-name">Apellidos</label>
            <input autocomplete="off" type="text" class="form-control" placeholder="Jones" formControlName="lastname"
              [ngClass]="{'is-invalid invalidFieldForm': validLastname}"
              [ngStyle]="validLastname && {'border-color': 'red','margin-bottom': '3px'}">
            <div class="invalidFieldForm" style="color: red;font-size: 13px;" *ngIf="validLastname">
              Debe ingresar al menos 2 caracteres
            </div>
          </div>
          <div class="form-group">
            <label for="username">Usuario</label>
            <input autocomplete="off" type="text" class="form-control" placeholder="Jones" formControlName="username"
              [ngClass]="{'is-invalid invalidFieldForm': validUsername}"
              [ngStyle]="validUsername && {'border-color': 'red','margin-bottom': '3px'}">
            <div class="invalidFieldForm" style="color: red;font-size: 13px;" *ngIf="validUsername">
              Debe ingresar al menos 2 caracteres
            </div>
          </div>
          <div class="form-group">
            <label for="email">Correo electrónico</label>
            <input autocomplete="off" type="text" class="form-control" placeholder="mimail@mail.com"
              formControlName="email" [ngClass]="{'is-invalid invalidFieldForm': validEmail}"
              [ngStyle]="validEmail && {'border-color': 'red','margin-bottom': '3px'}">
            <div class="invalidFieldForm" style="color: red;font-size: 13px;" *ngIf="validEmail">
              Ingrese un correo electrónico válido
            </div>
          </div>
          <div class="mtb-20 text-center">
            <button (click)="onRegister()" type="submit" class="btn-primary">Enviar</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>