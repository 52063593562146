import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { settings } from 'src/environments/settings';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

/**
 * Servicio que expone las configuraciones de la aplicación.
 * De manera inicial carga sus settings desde el environments compilado.
 * Y de manera opcional, parcha los valores con invocaciones a loadSettings.
 * Por default, en su construcción, tratará actualizar los valores desde /assets/settings.json.
 * 
 * Los valores de configuración se pueden recuperar bien sea con el método
 * get, que recibe uno o varios keys para extraer la ruta. cada key puede contener
 * "." que serán separados como partes del keypath.
 * 
 * Tambien se puede acceder directamente la variable pública "data", donde se almacenan
 * los valores de configuración actualizados.
 */
@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  public data: any = {};
  public statusObservable: BehaviorSubject<string> = new BehaviorSubject<string>('constructor');

  constructor(private http: HttpClient) {
    this.initSettings();
    this.patch(this.data, settings);
    this.redefineGetters();
    this.statusObservable.next('ready');
  }

  public get<T>(...keypath: string[]): T {
    if (keypath == null || keypath.length <= 0) {
      return null;
    }
    for (var i = 0; i < keypath.length; i++) {
      let kp: string = keypath[i];
      let kp2: string[] = kp.split(".");
      if (kp2.length > 1) {
        var args: any[] = [i + 1, 0];
        args = args.concat(kp2);
        Array.prototype.splice.apply(keypath, args);
        i += kp2.length - 1;
      }
    }
    return this.doGet(this.data, keypath);
  }
  private doGet<T>(at: any, keypath: string[]): T {
    let key: string = keypath.shift();
    if (!!keypath && at[key] != null || at[key] != undefined) {
      let v: any = at[key];
      return this.doGet(v, keypath);
    }
    return at;
  }

  private patch(object: any, withObject: any) {
    for (let k in withObject) {
      let objectV: any = object[k];
      let withObjectV: any = withObject[k];
      if (typeof (objectV) == 'object' && typeof (withObjectV) == 'object') {
        this.patch(objectV, withObjectV);
      } else {
        object[k] = withObjectV;
      }
    }
  }

  /**
   * Aplica los valores de environment como los settings iniciales
   */
  public initSettings() {
    this.data = JSON.parse(JSON.stringify(environment));
    this.redefineGetters();
  }

  /**
   * Define aliases a los valores de data para que puedan ser accedidos directamente desde settings Service.
   * I.e. si data tiene una propiedad "x"
   * Este metodo hará que se pueda acceder ese valor desde settings Service directamente. De tal forma
   * que desde cualquier parte del código, se pueda acceder este valor de estas tres maneras:
   * - settingsService.data.x;
   * - settingsService.get('x');
   * - settingsService.x;
   */
  private redefineGetters() {
    for (let key in this.data) {
      if (key == 'ws') {
        this.data['pathInfo'] = this.getUrl();
        delete this.data[key];
      }
      if (this.data[key] && key != "production") {
        Object.defineProperty(this, key, {
          get: () => {
            return this.data[key];
          },
          set: (value: any) => {
            if (this.data[key] !== value) {
              this.data[key] = value;
            }
          },
        });
      }
    }
  }

  /**
   * Aplica los valores de environment como los settings iniciales
   */
  private getUrl() {
    let pathInfo = {
      "base": this.data.ws.base.dev,
      "res": this.data.ws.res,
      "files": this.data.ws.base.dev
    }
    if (environment.production) {
      pathInfo.base = this.data.ws.base.prod;
      pathInfo.files = this.data.ws.base.prod;
    }
    pathInfo.files = pathInfo.files.baseUrl + pathInfo.files.files;
    Object.keys(pathInfo.res.admin).forEach(pathItem => {
      this.redefinePrefixes(pathInfo.res.admin, pathItem, pathInfo);
    });
    Object.keys(pathInfo.res.public).forEach(pathItem => {
      this.redefinePrefixes(pathInfo.res.public, pathItem, pathInfo);
    });
    return pathInfo;
  }

  private redefinePrefixes = (parent: any, pathItem: string, pathInfo: any) => {
    const withApiVersion = !parent[pathItem]["noApiVersion"] ? pathInfo.base.apiVersion : '';
    if (!!parent[pathItem]["segment"]) {
      parent[pathItem]["prefix"] = `${parent[pathItem]["segment"]}${withApiVersion}${parent[pathItem]["prefix"]}`;
    } else {
      parent[pathItem]["prefix"] = `${withApiVersion}${parent[pathItem]["prefix"]}`;
    }
  }
}
