import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalHelperService {

  constructor() { }
  // loader modal
  private loading = new BehaviorSubject<boolean>(true);
  public loading$ = this.loading.asObservable();
  public changeLoading(msg: boolean): void {
    setTimeout(() => {
      this.loading.next(msg);
    }, 200)
  }
  // error modal
  private errorModal = new BehaviorSubject<any>({ title: 'Error', show: false, message: null });
  public errorModal$ = this.errorModal.asObservable();
  public changeErrorModal(msg: any): void {
    this.errorModal.next(msg);
  }
  // imagen de perfil menu
  private imageProfile = new BehaviorSubject<string>(null);
  public imageProfile$ = this.imageProfile.asObservable();
  public changeImageProfile(msg: any): void {
    this.imageProfile.next(msg);
  }
  // observable questions
  private poll = new Subject<any>();
  public poll$ = this.poll.asObservable();
  public pollChange(msg: any): void {
    this.poll.next(msg);
  }
  // Menu
  private menu = new Subject<any>();
  public menu$ = this.menu.asObservable();
  public menuChange(msg: any): void {
    this.menu.next(msg);
  }
}
