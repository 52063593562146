import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { AlertNotificationService } from "src/app/services/alert-notification.service";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { labels } from "src/app/strings";
import { MediaService } from "src/app/services/media.service";
import { SettingsService } from "src/app/services/settings.service";
import { getData, getObject, hiddenModalLoading } from "src/app/utils/_http";
import { ModalHelperService } from "src/app/services/modal-helper.service";
import {
  getBase64ImageFromUrl,
  getFileImageToBase64,
} from "src/app/utils/_utils";
import { FilesPipe } from "src/app/pipes/files.pipe";
import { v4 as uuidv4 } from "uuid";
import { ResponseData } from "src/app/dto";

export interface FileHandle {
  uuid?: string;
  file: File | null;
  id?: any | null;
  url: SafeUrl | string;
  progress: number;
  size: string;
  type: string;
  extension: string;
  name: string;
  alt: string;
}

@Component({
  selector: "abarcms-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.css"],
})
export class FileUploadComponent implements OnInit {
  _singleFile: boolean = false;
  @Input()
  set singleFile(data: boolean) {
    this._singleFile = data || false;
  }

  filesToUpload: FileHandle[] = [];

  private _innerTitleText: string;
  private temporalInnerText: string;
  @Input()
  set innerTitleText(data: string) {
    let isEmpty: boolean = !data || data == "empty_string";
    this._innerTitleText = data;
    if (isEmpty && !!this.temporalInnerText) {
      this._innerTitleText = this.temporalInnerText;
    }
    if (!!data && data != "empty_string") {
      this.temporalInnerText = data;
    }
  }
  get innerTitleText() {
    return (
      this._innerTitleText ||
      "Arrastre y suelte aquí sus <b>archivos multimedia</b> o de click para buscar"
    );
  }

  _typeFile: string = "";
  @Input()
  set typeFile(data: string) {
    this._typeFile = this.getMimesType(data) || "";
  }

  _sizeFile: number = 5;
  @Input()
  set sizeFile(data: number) {
    this._sizeFile = data || 5;
  }

  _files: Array<any> = [];
  @Input()
  set files(data: Array<any>) {
    this._files = data || [];
  }

  _contentID: number | string = null;
  @Input()
  set contentID(data: number | string) {
    this._contentID = data || null;
  }

  _callServiceInThisComponent: boolean = false;
  @Input()
  set callServiceInThisComponent(data: boolean) {
    this._callServiceInThisComponent = data || false;
  }

  _getOnlyUrl: boolean = false;
  @Input()
  set getOnlyUrl(data: boolean) {
    this._getOnlyUrl = data || false;
  }

  _disabled: boolean = false;
  @Input()
  set disabled(data: boolean) {
    this._disabled = data || false;
  }

  _serviceCms: boolean = false;
  @Input()
  set serviceCms(data: boolean) {
    this._serviceCms = data || false;
  }

  showDelete: boolean = false;
  @Input() preview: boolean = true;

  @Output() getFilesToUpload = new EventEmitter<Array<object>>();
  @Output() isSingleFiles = new EventEmitter<boolean>();
  @Output() removedFile = new EventEmitter<boolean>();
  @Output() disabledButton = new EventEmitter<boolean>();

  filesBase64: Array<object> = [];
  error = { show: false, message: null };
  loading = { create: false, delete: false };
  token: number;
  constructor(
    protected alertNotificationService: AlertNotificationService,
    private sanitizer: DomSanitizer,
    protected mediaService: MediaService,
    public settings: SettingsService,
    private modalHelper: ModalHelperService,
    private filesPipe: FilesPipe
  ) {}
  ngOnInit(): void {
    this.token = parseInt(
      Number(Date.now() + Math.random() * 10000).toFixed(0)
    );
    setTimeout(() => {
      this.middleware();
    }, 200);
  }
  paintImage(data, returnData: boolean = false): void {
    let media = [];
    getBase64ImageFromUrl(this.filesPipe.transform(data.name))
      .then((result) => {
        if (result) {
          let file = getFileImageToBase64(result, data.name.split("/").pop());
          media.push({
            id: data.uuid,
            name: file.name.split("/").pop(),
            type: file.type,
            size: file.size,
            path: result,
            creaatedAt: file.lastModified,
            alt: data.name.split(".").shift(),
            extension: `.${data.name.split("/").pop().split(".").pop()}`,
          });
          let title = "Se obtuvo correctamente la imagen";
          let message = `Imagen ${data.name.split("/").pop()}`;
          this.alertNotificationService.success(title, message, {
            autoClose: true,
          });

          this.addUrlImageFiles(media);
        }
      })
      .catch((err) => {
        console.error(err);
        return media;
      });
  }
  middleware() {
    let other = [];
    if (typeof this._files == "string") {
      let filesData = JSON.parse(this._files);
      if (Object.keys(filesData).length) {
        if (filesData.hasOwnProperty("name")) {
          this.getFilesToUpload.emit(filesData);

          this.paintImage(filesData);
        }
      }
    } else if (Array.isArray(this._files)) {
      this._files.map((item) => {
        if (typeof item == "string") {
          let filesData = JSON.parse(item);
          if (Object.keys(filesData).length) {
            if (filesData.hasOwnProperty("name")) {
              this.getFilesToUpload.emit(filesData);

              this.paintImage(filesData);
            }
          }
        }
      });
    } else {
      other = this._files;
    }
    if (other.length) {
      this.addUrlImageFiles(other);
    }
  }
  addUrlImageFiles(filesData) {
    if (Array.isArray(filesData)) {
      filesData.map((item, key) => {
        let extension = item["name"].split(".").pop();
        if (extension.length == 0) extension = "";
        let id = item.hasOwnProperty("uuid") ? "uuid" : "id";
        this.filesToUpload.push({
          file: null,
          id: item[id],
          url: item["path"],
          progress: 100,
          size: item["size"],
          type: item["type"],
          extension: item["extension"] ?? `.${extension}`,
          name: item["name"].replace(/[\s]/g, "-"),
          alt: item["alt"].replace(/[\s]/g, "-"),
        });
      });
      if (this.filesToUpload.length) {
        if (this._singleFile) this.filesToUpload = [this.filesToUpload.pop()];
        if (this._callServiceInThisComponent == false) this.sendFilesToUpload();
      }
    }
  }
  getMimesType(data: string = "") {
    let types = [];
    if (data.replace(/[.]/g, "").trim().length) {
      data
        .replace(/[.]/g, "")
        .trim()
        .split(",")
        .map((item) => {
          types.push(`.${item}`);
        });
    }
    return types.join(",");
  }
  onFileDropped($event) {
    this.prepareFilesList($event);
  }
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }
  deleteFile(data: string) {
    this.loading.delete = true;
    let files = [...this.filesToUpload];
    this._innerTitleText = this.temporalInnerText;
    files.map((item, index) => {
      if (item.name == data) {
        let id = item.hasOwnProperty("uuid") ? "uuid" : "id";
        if (this._callServiceInThisComponent && item[id]) {
          this.mediaService.delete(item.id).subscribe((rs) => {
            if (rs) {
              if (rs.hasOwnProperty("error")) {
                this.error.show = true;
                this.error.message = this.settings.get("production")
                  ? rs.error["message"]
                  : rs.error;
                console.error(rs.error);
              } else {
                // this.alertNotificationService.success(labels.fileUpload.delete.title, labels.fileUpload.delete.body.replace('{name}', this.getNameComplete(item)), { autoClose: true });
                this.filesToUpload.splice(index, 1);
                this.getFilesToUpload.emit(this.filesToUpload);
              }
            }
          });
        } else {
          // this.alertNotificationService.success(labels.fileUpload.delete.title, labels.fileUpload.delete.body.replace('{name}', this.getNameComplete(item)), { autoClose: true });
          this.filesToUpload.splice(index, 1);
        }
      }
    });
    let filesBase64 = [...this.filesBase64];
    filesBase64.map((item, index) => {
      if (item["name"] == data) {
        this.filesBase64.splice(index, 1);
      }
    });
    this.removedFile.emit(true);
    this.loading.delete = false;
    this.modalHelper.changeLoading(false);
    hiddenModalLoading({ deleted: false }, this.modalHelper);
  }
  uploadFilesSimulator(index: number) {
    this.disabledButton.emit(true);
    setTimeout(() => {
      if (index === this.filesToUpload.length) {
        this.sendFilesToUpload();
        this.showDelete = true;
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (
            !!this.filesToUpload[index] &&
            this.filesToUpload[index].progress === 100
          ) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.filesToUpload[index].progress += 25;
          }
        }, 200);
      }
    }, 1000);
  }
  getNameComplete(data) {
    return `${data.name}`;
  }
  prepareFilesList(_files: Array<any>) {
    this.loading.create = true;
    const files = this.returnFileHandle(_files);
    const filesAuxiliar = this.returnFileStrings(this.filesToUpload);
    let filesFiltered = [];
    for (const item of files) {
      if (filesAuxiliar.indexOf(this.getNameComplete(item)) == -1) {
        if (Number(item.size) < this._sizeFile * 1024 * 1024) {
          let types = this._typeFile.toLowerCase().split(",");
          if (types.length) {
            if (types.indexOf(item.extension.trim().toLowerCase()) !== -1) {
              item.progress = 0;
              filesFiltered.push(item);
            } else {
              this.loading.create = false;
              this.alertNotificationService.error(
                labels.fileUpload.incorrectType.title,
                labels.fileUpload.incorrectType.body.replace(
                  "{name}",
                  this.getNameComplete(item)
                ),
                { autoClose: true }
              );
            }
          } else {
            item.progress = 0;
            filesFiltered.push(item);
          }
        } else {
          this.loading.create = false;
          this.alertNotificationService.error(
            labels.fileUpload.sizeExceeded.title,
            labels.fileUpload.sizeExceeded.body.replace(
              "{size}",
              this._sizeFile.toFixed(2)
            ),
            { autoClose: true }
          );
        }
      } else {
        this.loading.create = false;
        this.alertNotificationService.error(
          labels.fileUpload.fileExist.title,
          labels.fileUpload.fileExist.body.replace(
            "{name}",
            this.getNameComplete(item)
          ),
          { autoClose: true }
        );
      }
    }
    if (filesFiltered.length) {
      if (this._singleFile) {
        if (this.filesToUpload.length)
          this.deleteFile(this.filesToUpload[0].name);
        this.filesToUpload.push(filesFiltered[0]);
        // if (this._callServiceInThisComponent == false)
        // this.alertNotificationService.success(labels.fileUpload.upload.title, labels.fileUpload.upload.body.replace('{name}', this.getNameComplete(this.filesToUpload[0])), { autoClose: true });
      } else {
        filesFiltered.map((item) => {
          this.filesToUpload.push(item);
          // if (this._callServiceInThisComponent == false)
          // this.alertNotificationService.success(labels.fileUpload.upload.title, labels.fileUpload.upload.body.replace('{name}', this.getNameComplete(item)), { autoClose: true });
        });
      }
      if (true) {
        this.loading.create = false;
        this.uploadFilesSimulator(0);
      }
    }
  }
  returnFileHandle(_files: Array<any>): FileHandle[] {
    let files: FileHandle[] = [];
    this.showDelete = false;
    for (const item of _files) {
      if (!item.hasOwnProperty("url")) {
        const file = item;
        const extension = `.${item.name.split(".").pop()}`;
        const name = `${item.name
          .replace(/[\s]/g, "-")
          .replace(extension, "")}-${this.token}${extension}`;
        const alt = item.name.split(".")[0].replace(/[\s]/g, "-");
        const url = this.sanitizer.bypassSecurityTrustUrl(
          window.URL.createObjectURL(item)
        );
        const size = item.size;
        const progress = 0;
        const type = item.type;
        files.push({ file, url, size, progress, type, extension, name, alt });
      } else {
        files.push(item);
      }
    }
    return files;
  }
  returnFileStrings(_files: FileHandle[]): string[] {
    let files: string[] = [];
    for (const item of _files) {
      if (item.file) files.push(item.file.name);
      else files.push(this.getNameComplete(item));
    }
    return files;
  }
  sendFilesToUpload() {
    this.filesBase64 = [];
    this.filesToUpload.map((item) => {
      if (item.file) {
        let file = item.file;
        let reader = new FileReader();
        reader.onload = () => {
          if (reader["result"]) {
            this.addImageBase64(item, reader["result"].toString());
          }
        };
        reader.readAsDataURL(file);
      } else {
        if (this._callServiceInThisComponent == false) {
          this.addImageBase64(item, item.url.toString());
        }
      }
    });
  }
  isSingleFile() {
    if (this._singleFile) this.isSingleFiles.emit(true);
    else this.isSingleFiles.emit(true);
  }
  addImageBase64(data: object, url: string) {
    let myuuid: string = uuidv4();
    this.filesBase64.push({
      uuid: myuuid,
      name: data["name"],
      base64: url.toString().split(",")[1],
      type: data["type"],
      size: data["size"],
      extension: `.${data["extension"].split(".")[1]}`,
      alt: data["alt"].split(".")[0],
    });
    if (this._callServiceInThisComponent) {
      setTimeout(() => {
        this.callToService();
      }, 250);
    } else {
      this.getFilesToUpload.emit(this.filesBase64);
      this.disabledButton.emit(false);
      this.loading.create = false;
    }
  }
  fileToBase64(filename, filepath, filetype) {
    return new Promise((resolve) => {
      var file = new File([filename], filepath, { type: filetype });
      var reader = new FileReader();
      reader.onload = function (event) {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });
  }
  async callToService() {
    if (this._serviceCms) {
      let files = this.filesToUpload[0];

      const formData = new FormData();
      formData.append("images", files.file);

      (await this.mediaService.createImgCms(formData)).subscribe((rs) => {
        /*this.filesToUpload.map((item, index) => {
          rs.map(value => {
            if (value.uuid.indexOf(item.uuid) != -1) {
              let id = value.hasOwnProperty('uuid') ? 'uuid' : 'id';
              this.filesToUpload[index].id = value[id];
              this.filesToUpload[index].file = null;
            }
          });
        });
        let files = [];
        this.filesBase64.map(item => {
          let notExist = true;
          rs.map(value => {
            if (value.uuid.indexOf(item['uuid']) != -1) {
              notExist = false;
              // this.alertNotificationService.error(labels.fileUpload.errorUpload.title, labels.fileUpload.errorUpload.body.replace('{name}', this.getNameComplete(item)), { autoClose: true });
              return;
            }
          });
          if (notExist) files.push(item);
        });
        this.filesBase64 = files;
*/

        this.loading.create = false;
        /* this.modalHelper.changeLoading(false);
                  hiddenModalLoading({ created: false }, this.modalHelper);*/
        this.getFilesToUpload.emit(rs.body);
        this.disabledButton.emit(false);
        this.loading.create = false;
        if (!this.preview) {
          this._files = [];
          this.filesToUpload = [];
          this.filesBase64 = [];
        }
      });
    } else {
      if (this.filesBase64.length) {
        this.filesBase64.map((item, key) => {
          if (item["contentID"] == null)
            delete this.filesBase64[key]["contentID"];
        });
        if (this.filesBase64.length) {
          this.mediaService.create(this.filesBase64[0]).subscribe(
            (rs) => {
              if (rs) {
                if (rs.hasOwnProperty("error")) {
                  this.error.show = true;
                  this.error.message = this.settings.get("production")
                    ? rs.error["message"]
                    : rs.error;
                  this.disabledButton.emit(false);
                  console.error(rs.error);
                } else {
                  this.filesToUpload.map((item, index) => {
                    rs.map((value) => {
                      if (value.uuid.indexOf(item.uuid) != -1) {
                        let id = value.hasOwnProperty("uuid") ? "uuid" : "id";
                        this.filesToUpload[index].id = value[id];
                        this.filesToUpload[index].file = null;
                        // this.alertNotificationService.success(labels.fileUpload.upload.title, labels.fileUpload.upload.body.replace('{name}', this.getNameComplete(item)), { autoClose: true });
                      }
                    });
                  });
                  let files = [];
                  this.filesBase64.map((item) => {
                    let notExist = true;
                    rs.map((value) => {
                      if (value.uuid.indexOf(item["uuid"]) != -1) {
                        notExist = false;
                        // this.alertNotificationService.error(labels.fileUpload.errorUpload.title, labels.fileUpload.errorUpload.body.replace('{name}', this.getNameComplete(item)), { autoClose: true });
                        return;
                      }
                    });
                    if (notExist) files.push(item);
                  });
                  this.filesBase64 = files;
                }
                this.loading.create = false;
                this.modalHelper.changeLoading(false);
                hiddenModalLoading({ created: false }, this.modalHelper);
                this.getFilesToUpload.emit(rs);
                this.disabledButton.emit(false);
                this.loading.create = false;
                if (!this.preview) {
                  this._files = [];
                  this.filesToUpload = [];
                  this.filesBase64 = [];
                }
              }
            },
            (err) => {
              this.disabledButton.emit(false);
            }
          );
        } else {
          this.disabledButton.emit(false);
        }
      } else {
        this.disabledButton.emit(false);
      }
    }
  }
}
