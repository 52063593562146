<div class="btn-group multi-select p-0">
    <button type="button" class="mb-0 btn btn-outline dropdown-toggle"
        [ngClass]="{'bg-role': _disabled, 'is-invalid invalidFieldForm': isRequired()}"
        [ngStyle]="isRequired() && {'border-color': 'red','margin-bottom': '3px'}" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false" #header (click)="toggleSelect()" (blur)="toggleSelect('blur')">
        <span>{{_title}}</span>
    </button>
    <ul class="dropdown-menu multi-select-popup" [ngStyle]="{display:isOpen ? 'block' : 'none'}" style="display:block">
        <li *ngIf="enableFilter" class="filter-container">
            <div class="form-group has-feedback filter mb-0">
                <input class="form-control mb-0" type="text" [value]="filterText" [placeholder]="filterPlaceholder"
                    [formControl]="filterInput" #filter (focus)="toggleSelect('focus')" (blur)="toggleSelect('blur')" />
                <span class="clear-filter fa fa-times-circle-o form-control-feedback" (click)="clearFilter()"></span>
            </div>
        </li>
        <li *ngFor="let item of _items; let index = index">
            <a (click)="selectItem(item, index)" class="dropdown-item" *ngIf="item.show">
                <i class="fa fa-fw" [ngClass]="{'fa-check': item.checked, 'glyphicon-none': !item.checked}"></i>
                <span [innerHtml]="item.label" class="pl-2"></span>
            </a>
        </li>
    </ul>
</div>
<div class="invalidFieldForm" style="color: red;font-size: 13px;" *ngIf="isRequired()">
    Debes seleccionar una opción
</div>