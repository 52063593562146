import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidateService } from 'src/app/services/validate.service';
import { SettingsService } from 'src/app/services';
import { removeStorage, getStorage } from 'src/app/utils/_utils';
import { constants } from 'src/app/constants';
import { Router } from '@angular/router';
import { getValid } from 'src/app/utils/_http';
import { UserDataService } from 'src/app/services/data/user-data.service';
import { ModalHelperService } from 'src/app/services/modal-helper.service';

@Component({
  selector: 'app-add-password',
  templateUrl: './add-password.component.html',
  styleUrls: ['./add-password.component.css']
})
export class AddPasswordComponent implements OnInit {

  public formaPassword: FormGroup;
  public data: any = {};
  public validationError: string = null;
  public showValidationErrorModal = false;
  public error = { show: false, message: null };
  constructor(
    private router: Router,
    public userDataService: UserDataService,
    private modalHelper: ModalHelperService,
    public settings: SettingsService,
    private validate: ValidateService,
    private fb: FormBuilder,
  ) {
    this.createFormPassword();
  }

  ngOnInit(): void {
    this.modalHelper.changeLoading(false);
  }

  get valid(): any {
    return this.settings.get('validate');
  }

  get validPassword() {
    return this.formaPassword.get('password').invalid && this.formaPassword.get('password').touched;
  }

  get validPasswordConfirm() {
    const password = this.formaPassword.get('password').value;
    const passwordConfirm = this.formaPassword.get('passwordConfirm').value;
    return password === passwordConfirm ? false : true;
  }

  createFormPassword() {
    this.formaPassword = this.fb.group({
      password: ['', [Validators.required, Validators.pattern(this.valid.password)]],
      passwordConfirm: ['', [Validators.required, Validators.pattern(this.valid.password)]],
    }, {
      validators: this.validate.passwordConfirm('password', 'passwordConfirm')
    });
  }

  onGoBack() {
    this.onGoHome();
  }

  onGoHome() {
    removeStorage([constants.ls.codeVerify, constants.ls.passwordRecovery]);
    this.router.navigate(['/']);
  }

  onSubmit() {
    if (this.formaPassword.invalid) {
      return Object.values(this.formaPassword.controls).forEach(control => {
        control.markAsTouched();
      });
    }
    this.data = { ...this.formaPassword.value };
    let actionLogic = getStorage(constants.ls.codeVerify, true).action;
    let data = getStorage(constants.ls.codeVerify, true).data;
    this.data = { ...this.data, ...data };
    delete this.data.passwordConfirm;
    this.userDataService.createPassword(this.data)
      .subscribe((rs) => {
        if (getValid(rs, { title: 'Error al crear la contraseña', modalHelper: this.modalHelper, settings: this.settings })) {
          this.router.navigate(['/', constants.screens.register.path, constants.screens.register.confirm]);
        }
      });
  }
}
