import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/services/settings.service';
import { ValidateService } from 'src/app/services/validate.service';
import { UserDataService } from 'src/app/services/data/user-data.service';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { constants } from 'src/app/constants';
import { getValid } from 'src/app/utils/_http';
import { setStorage, removeStorage, existStorage } from 'src/app/utils/_utils';
import { ModalHelperService } from 'src/app/services/modal-helper.service';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.css'],
})
export class PasswordRecoveryComponent implements OnInit {
  public linkSent: boolean = false;
  public validationError: string = null;
  public showValidationErrorModal: boolean = false;
  public error = { show: false, message: null };
  public data = {};
  public forma: FormGroup;
  constructor(private router: Router,
    public settings: SettingsService,
    private validate: ValidateService,
    public userDataService: UserDataService,
    private fb: FormBuilder,
    private modalHelper: ModalHelperService
  ) {
    this.createForm();
    if (existStorage(constants.ls.passwordRecovery)) this.linkSent = true;
    else {
      removeStorage([constants.ls.passwordRecovery, constants.ls.codeVerify]);
    }
  }

  ngOnInit() {
    this.modalHelper.changeLoading(false);
  }
  get validEmail() {
    return this.forma.get('email').invalid && this.forma.get('email').touched;
  }
  createForm() {
    this.forma = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(this.settings.get('validate')['email'])]],
    })
  }
  onRecoverPassword() {
    if (this.userDataService.startPasswordRecovery['loading']) { return; }
    // this.validate.validateValues(this.data, 'passwordRecovery');
    if (this.forma.invalid) {
      return Object.values(this.forma.controls).forEach(control => {
        control.markAsTouched();
      });
    }
    this.userDataService.startPasswordRecovery['loading'] = true;
    this.data = { ...this.forma.value };
    var dataSend = {
      email: this.data['email']
    };
    delete dataSend['passwordConfirm'];
    this.userDataService.startPasswordRecovery(dataSend)
      .subscribe((rs) => {
        if (getValid(rs, { title: `Error recuperar la contraseña`, modalHelper: this.modalHelper, settings: this.settings })) {
          let data = {
            action: constants.screens.passwordRecovery.path,
            data: dataSend
          }
          setStorage(constants.ls.passwordRecovery, JSON.stringify(data));
          this.modalHelper.changeLoading(false);
          this.linkSent = true;
        }
      })
  }
  onGoHome() {
    removeStorage(constants.ls.passwordRecovery);
    this.router.navigate(['/']);
  }
  onGoCode() {
    this.router.navigate([constants.screens.passwordRecovery.path, constants.screens.passwordRecovery.code]);
  }
}
