/* tslint:disable:triple-equals no-unused-expression */
import { environment } from 'src/environments/environment';
import { constants } from '../constants';
import { OwlDateTimeIntl } from 'ng-pick-datetime';
import { Router } from '@angular/router';

const encryptionTimes = 2;
export const isAnyNull = (value: any) => value == 'null' || value === null || value === undefined;
/**
 * Function that encrypts a given string [encryptionTimes] times
 * @param toEncrypt String to encrypt
 * @param current Number of current encryptions
 * @returns Base64 encrypted string
 * @example ("_ls_session_access_token") will return "X2xzX3Nlc3Npb25fYWNjZXNzX3Rva2Vu"
 */
export const encrypt = (toEncrypt: string, current: number = 1) => {
    if (current <= encryptionTimes) {
        return encrypt(btoa(toEncrypt), current + 1);
    }
    return toEncrypt;
}
/**
 * Function that decrypts a given string [encryptionTimes] times
 * @param toDecrypt String to encrypt
 * @param current Number of current decryptions
 * @returns Base64 decrypted string
 * @example ("X2xzX3Nlc3Npb25fYWNjZXNzX3Rva2Vu") will return "_ls_session_access_token"
 */
export const decrypt = (toDecrypt: string, current: number = 1) => {
    if (current <= encryptionTimes) {
        return decrypt(atob(toDecrypt), current + 1);
    }
    return toDecrypt;
}
/**
 *
 * @param regexp Validador RegEx
 * @param cadena Cadena a validar
 */
export function validatePattern(regexp, cadena) {
    const reg = new RegExp(regexp);
    if (reg.test(cadena)) { return true; }
    return false;
}
/**
 *
 * @param key Nombre de la llave a contener valor en el storage
 * @param value Valor que contendra la llave
 */
export function setStorage(key: string, value: any) {
    let v: any = null;
    switch (typeof value) {
        case 'string':
        case 'number':
        case 'boolean':
            v = value;
            break;
        default:
            v = JSON.stringify(value);
            break;
    }
    if (environment.production) {
        sessionStorage.setItem(encrypt(key), encrypt(v));
    } else {
        sessionStorage.setItem(key, v);
    }
    return true;
}
/**
 * Function that evaluates if a given string is JSON formatted and then parses it
 * @param jsonString Expected JSON string to proccess
 * @returns {object|string} If parameter's value passed is not a JSON string formatted, function will return same string given
 * @example "i'm a string" returns "i'm a string"
 * @example "\\{value\\: i\\'m a string\\}" returns {value: "i'm a string"}
 */
export function JSONTryer(jsonString: string) {
    try {
        return JSON.parse(jsonString);
    } catch (e) {
        return jsonString;
    }
}
/**
 *
 * @param key  Nombre de la llave a contener valor en el storage
 * @param jsonParse Si es true el valor lo parseara a JSON, por defecto es false
 */
export function getStorage(key: string, jsonParse = true): any {
    if (!key) {
        return undefined;
    }
    let data: string;
    if (environment.production) {
        data = sessionStorage.getItem(encrypt(key));
        if (!data) {
            return undefined;
        }
        data = decrypt(data);
        return jsonParse ? JSONTryer(data) : data;
    } else {
        data = sessionStorage.getItem(key);
        return jsonParse ? JSONTryer(data) : data;
    }
}
/**
 *
 * @param key Nombre de la llave a contener valor en el storage, puede ser una cadena o un Arreglo de cadenas,
 */
export function removeStorage(key: string | Array<string>) {
    if (environment.production) {
        if (typeof key == 'string') { sessionStorage.removeItem(encrypt(key)); } else {
            key.map(item => {
                sessionStorage.removeItem(encrypt(item));
            });
        }
    } else {
        if (typeof key == 'string') { sessionStorage.removeItem(key); } else {
            key.map(item => {
                sessionStorage.removeItem(item);
            });
        }
    }
}
/**
 *
 * @param key Nombre de la llave a contener valor en el storage, puede ser una cadena o un Arreglo de cadenas,
 */
export function removeStorageAll() {
    sessionStorage.clear()
}
/**
 *
 * @param key Nombre de la llave a contener valor en el storage
 */
export function existStorage(key: string | Array<string>): boolean {
    if (environment.production) {
        if (typeof key == 'string') {
            return sessionStorage.getItem(encrypt(key)) !== null ? true : false;
        } else {
            let exist = true;
            key.map(item => {
                const result = sessionStorage.getItem(encrypt(item)) !== null ? true : false;
                if (result == false) { exist = false; }
            });
            return exist;
        }
    } else {
        if (typeof key == 'string') { return sessionStorage.getItem(key) !== null ? true : false; } else {
            let exist = true;
            key.map(item => {
                const result = sessionStorage.getItem(item) !== null ? true : false;
                if (result == false) { exist = false; }
            });
            return exist;
        }
    }
}
/**
 *
 * @param t Cadena a tranformar a camelCase
 */
export function camelCase(t: string): string {
    const list = t.replace(/[-_]/g, ' ').split(' ');
    const l: Array<string> = [];
    list.map((item, key) => {
        if (key) {
            l.push(item.charAt(0).toUpperCase() + item.slice(1).toLowerCase());
        } else { l.push(item.toLowerCase()); }
    });
    return l.join().replace(/[,]/g, '');
}
/**
 *
 * @param uri Uri de la ruta actual
 */
export function getPermissions(url: string): string[] {
    const permissionPage = getStorage(constants.ls.pagesPermission, true);
    let permission = [];
    const uri = url.replace(/\//g, ',/').split(',');
    if (uri && permissionPage) {
        uri.map(item => {
            if (item) {
                if (permissionPage.hasOwnProperty(item)) {
                    permission = permissionPage[item];
                    return false;
                }
            }
        });
    }
    return permission;
}
/**
 *
 * @param userRole Roles del usuario
 * @param callback Callback
 */
export function setPermissionToPage(userRole: any[], callback?: any) {
    let indexEnd: number;
    const userPermissions = {};
    userRole.map((itemRole, index) => {
        itemRole.permission.map((itemRolePermission: any) => {
            const permissions = !!itemRolePermission['permissions'] ? itemRolePermission['permissions'] : itemRolePermission['permission'];
            if (permissions) {
                if (permissions.length) {
                    userPermissions[`/${slugifyPipe(itemRolePermission.module)}`] = permissions.map((p: any) => {
                        return p.sysname;
                    });
                }
            }
            if (index == userRole.length - 1) indexEnd = userRole.length;
        });
    });
    if (indexEnd == userRole.length) {
        if (userPermissions.hasOwnProperty(`/${slugifyPipe('dashboard')}`) == false) {
            userPermissions[`/${slugifyPipe('dashboard')}`] = [constants.permission.read];
        }
        let addMenuToPermission=JSON.stringify (userPermissions).slice(1)
        addMenuToPermission=addMenuToPermission.substring(0,addMenuToPermission.length-1)+`,"/seccionesMonitor":["Create","Update","Delete","Access"]`
        let newPermissions={
            permisos:{addMenuToPermission}
        }
        console.log(newPermissions.permisos.addMenuToPermission);
//
        if (setStorage(constants.ls.sessionPermission, userPermissions)) setTimeout(() => { 
            if (callback) { callback } 
        }, 500);
    }
}
/**
 *
 * @param input Cadena a realizar a formatear
 */
export function slugifyPipe(input: string, char_separetor = '-'): string {
    input = input.replace(/^\s+|\s+$/g, ''); // trim
    input = input.toLowerCase();
    // remove accents, swap ñ for n, etc
    const from = 'àáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
    const to = 'aaaaaeeeeiiiioooouuuunc------';
    for (let i = 0, l = from.length; i < l; i++) {
        input = input.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }
    input = input.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, char_separetor) // collapse whitespace and replace by -
        .replace(/-+/g, char_separetor); // collapse dashes
    return input;
}

export function clone(data: any): any {
    return JSON.parse(JSON.stringify(data));
}
/**
* http_build_query - Generate a URL style encoded query string
* @param  {Object} obj  An object that contains properties
* @return {String}      The URL style encoded query string
*/
export const http_build_query = (obj) => {
    let temp = [];
    Object.entries(obj).forEach(([key, value]) => {
        temp.push([key, value].join('='));
    });
    return temp.join('&');
};

export function addMenus(data: any[]) {
    let menuAdmin = data.filter(item => item.group == 1);
    let menuHelp = data.filter(item => item.group == 2);
    return { menus: createMenuAdmin(menuAdmin), menus2: menuHelp };
}

export function createMenuAdmin(menuService: any[]) {
    let menus = menuService;
    let userPermission = getStorage(constants.ls.sessionPermission, true);
    let pagesPermission = {};
    menuService.map(itemMenu => {
        // let existMenu = false;
        // let menuInterior = [];
        // this.createPermissionPage(userPermission, pagesPermission, itemMenu, existMenu);
        let permission = userPermission[`/${slugifyPipe(itemMenu.title)}`];
        if (permission !== undefined) {
            if (permission.includes(constants.permission.read)) {
                // existMenu = true;
                let slug = itemMenu.params.join().replace(',', '');
                // if (slug.length == 0) slug = `/${slugifyPipe(itemMenu.title)}`;
                if (slug.length) {
                    if (pagesPermission.hasOwnProperty(slug)) {
                        let p = [...pagesPermission[slug], ...permission];
                        p.map(item => {
                            if (pagesPermission[slug].includes(item) == false) pagesPermission[slug].push(item);
                        });
                    }
                    else pagesPermission[slug] = permission;
                }
                else {
                    itemMenu.menus.map(item => {
                        let slug = item.params.join().replace(',', '');
                        if (slug.length) {
                            if (pagesPermission.hasOwnProperty(slug)) {
                                let p = [...pagesPermission[slug], ...permission];
                                p.map(item => {
                                    if (pagesPermission[slug].includes(item) == false) pagesPermission[slug].push(item);
                                });
                            }
                            else pagesPermission[slug] = permission;
                        }
                    });
                }
            }
        }
        pagesPermission['/profile'] = [constants.permission.create, constants.permission.edit, constants.permission.read, constants.permission.status, constants.permission.traduction];
    });
    setStorage(constants.ls.pagesPermission, JSON.stringify(pagesPermission));
    return menus;
}

export async function getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.addEventListener("load", function () {
            resolve(reader.result);
        }, false);

        reader.onerror = () => {
            return reject(this);
        };
        reader.readAsDataURL(blob);
    })
}

export function getFileImageToBase64(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}
export function getPermissionsPage(routeURL: string) {
    let urlPermission = routeURL
    if (routeURL.indexOf('/profile') != -1) {
        urlPermission = '/users'
    }
    let permissions: any = {
        permissionPage: [],
        create: false, // readonly o ngif
        edit: false, // readonly o ngif
        read: false, // readonly o ngif
        delete: false, // readonly o ngif
        status: false, // Disabled selected
    }
    permissions.permissionPage = getPermissions(urlPermission);
    if (permissions.permissionPage.includes(constants.permission.create) && permissions.permissionPage.includes(constants.permission.edit)) {
        permissions.create = true;
        permissions.edit = true;
        permissions.read = false;
    }
    else if (permissions.permissionPage.includes(constants.permission.create) && permissions.permissionPage.includes(constants.permission.edit) == false) {
        permissions.create = true;
        permissions.edit = false;
        permissions.read = true;
    }
    else if (permissions.permissionPage.includes(constants.permission.create) == false && permissions.permissionPage.includes(constants.permission.edit)) {
        permissions.create = false;
        permissions.edit = true;
        permissions.read = true;
    }
    else {
        permissions.create = false;
        permissions.edit = false;
        permissions.read = true;
    }
    if (permissions.permissionPage.includes(constants.permission.delete)) {
        permissions.delete = true;
    }
    if (permissions.permissionPage.includes(constants.permission.status) && permissions.read == false) {
        permissions.status = false;
    }
    else permissions.status = true;
    if (permissions.permissionPage.includes(constants.permission.traduction)) {
        permissions.traduction = true;
    }
    return permissions;
}

export const filterData = (data: any) => {
    let filter = [
        'id', 'title', 'locked', 'author', 'content_type', 'language', 'created', 'updated', 'deleted', 'password', 'parentuuid'
    ];
    let dataContent = [];
    let isArray = Array.isArray(data);
    if (isArray == false) data = [data];
    data.map(item => {
        if (item) {
            if (item.hasOwnProperty('contents')) {
                let data_aux = {
                    status: 1
                };
                item.contents.map(content => {
                    // if (filter.includes(content.system_name) == false) {
                    switch (content.type) {
                        case 3:
                        case 4:
                        case 11:
                            let link = (typeof content.value == 'string' && content.value.length) ? JSON.parse(content.value) : content.value;
                            data_aux[content.system_name] = Object.keys(link).length ? link : null;
                            break;
                        case 7:
                            let id = (typeof content.value == 'number' && typeof content.value !== 'object') ? content.value : null;
                            data_aux[content.system_name] = id != null ? content.value : null;
                            break;
                        default:
                            data_aux[content.system_name] = content.value;
                            break;
                    }
                    // }
                })
                if (Object.keys(data_aux)) {
                    if (data_aux.status == 1) dataContent.push(data_aux);
                }
            }
        }
    });
    return isArray ? dataContent : dataContent[0];
};

// --
export const flatPoll = (poll: any[], questions) => {
    poll.map((xQuestion, iQuestion) => {
        questions.push(xQuestion)
        if (xQuestion.choices.length) {
            xQuestion.choices.map(xChoice => {
                flatPoll(xChoice.nested, questions)
            })
        }
    })
}


// here is the default text string
export class DefaultIntl extends OwlDateTimeIntl {
    /** A label for the cancel button */
    cancelBtnLabel = 'Cancelar'
    /** A label for the set button */
    setBtnLabel = 'Aceptar'
}

export const permissionPageUser = (permissions: any, action: string, router: Router, param: any): boolean => {
    let showButtonSave = false
    switch (action) {
        case 'create':
            if (!permissions.create) {
                if (typeof param == 'string') {
                    router.navigateByUrl(param)
                }
                else {
                    param()
                }
            }
            else {
                showButtonSave = true
            }
            break;
        case 'edit':
            if (!permissions.edit) {
                if (typeof param == 'string') {
                    router.navigateByUrl(param)
                }
                else {
                    param()
                }
            }
            else {
                showButtonSave = true
            }
            break;
        case 'read':
            if (!permissions.read) {
                if (typeof param == 'string') {
                    router.navigateByUrl(param)
                }
                else {
                    param()
                }
            }
            break;
    }
    return showButtonSave
}