import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { constantes } from "../../constants/seccion-monitor";
import { SeccionMonitorService } from "../../services/seccion-monitor.service";
import Sortable from "sortablejs";
import { Router } from "@angular/router";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-order-page",
  templateUrl: "./order-page.component.html",
  styleUrls: ["./order-page.component.css"],
})
export class OrderPageComponent implements OnInit {
  paginas: any = [];
  loading: boolean;
  items: any = [];
  sortedItems = [];
  @ViewChild("sortableList") sortableListElement: ElementRef;
  sortableList: Sortable;
  sectionName: string;
  sectionIcon: string;
  constructor(
    private seccionesMonitorService: SeccionMonitorService,
    private router: Router,
    public dialog: MatDialog
  ) {
    if (constantes.title == "") {
      constantes.title = "Ordenar elementos";
    }
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    (
      await this.seccionesMonitorService.getSectionBySectionId(
        constantes.secciones.sectionId
      )
    ).subscribe(
      (result) => {
        this.paginas = result.data.pages;
        this.sectionName = result.data.name;
        this.sectionIcon = result.data.icon;
        for (let index = 0; index < this.paginas.length; index++) {
          this.items.push(this.paginas[index].pageId);
        }
        console.log(result.data);
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.loading = false;
      }
    );
  }

  ngAfterViewInit() {
    this.sortableList = Sortable.create(
      this.sortableListElement.nativeElement,
      {
        // código para reordenar los elementos de la lista
        onEnd: (event) => {
          const newIndex = event.newIndex;
          const item = this.items.splice(event.oldIndex, 1)[0];
          this.items.splice(newIndex, 0, item);
          this.sortedItems = this.items.slice(); // crear una copia ordenada del array
        },
      }
    );
  }

  disableSortable(event: MouseEvent) {
    this.sortableList.option("disabled", true);
  }

  enableSortable(event: MouseEvent) {
    this.sortableList.option("disabled", false);
    this.router.navigate(["/seccionesMonitor/ordenarPaginas"]);
  }

  async saveOrderPage() {
    this.loading = true;
    console.log(this.sortedItems);
    let PageIdsJson = {
      pageIds: this.sortedItems,
    };
    (
      await this.seccionesMonitorService.saveOrderPage(
        constantes.secciones.sectionId,
        PageIdsJson
      )
    ).subscribe(
      (result) => {
        this.loading = false;
        this.openConfirmDialog("Se guardo correctamente");
        console.log(result);
        this.router.navigate(["/seccionesMonitor/ordenarElementos"]);
      },
      (error) => {
        console.log(error);
        this.loading = false;
      }
    );
  }

  openConfirmDialog(mensaje: string | null): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { origen: "crearPagina", titulo: mensaje },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 1) {
        console.log("The dialog was closed");
      } else if (result == 0) {
      }
    });
  }
}
