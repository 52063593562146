import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '../../settings.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { ResponseData } from 'src/app/dto/ResponseData';
import { makeHeaders, createResponseData } from 'src/app/utils/_http';
import { ApiService } from '../../api.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionDataWsService {
  private pathInfo: any;
  private baseUrl: string;
  private segment: any;
  constructor(
    private settings: SettingsService,
    private api: ApiService
  ) {
    this.pathInfo = this.settings.get('pathInfo');
    this.baseUrl = this.pathInfo.base.baseUrl + this.pathInfo.base.prefix;
    this.segment = this.pathInfo.res.admin['permission'];
  }
  private read(accessToken: string): Observable<ResponseData> {
    let options = {
      'X-Auth-Token': accessToken,
      'X-Current-Page': 0,
      'X-Items-Page': 0
  };
    let endpoint = this.baseUrl + this.segment.prefix;
    return this.api.get(endpoint, options);
  }
}
