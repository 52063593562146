import { v1 as uuidv1 } from 'uuid'

export class PushNotification {
    public uuid: string;
    public active: boolean;
    public title: string;
    public message: string;
    public image: string;
    public created: string;
    public schedule: PushNotificationSchedule;
    public rules: PushNotificationRules[];
    public groups: PushNotificationGroup[];
    public timezone: number;

    constructor(data?: any) {
        this.uuid = data.uuid || uuidv1();
        if (data.active != undefined || data.active != null) {
            this.active = data.active
        }
        else {
            this.active = true
        }
        this.title = data.title || '';
        this.message = data.message || '';
        this.image = data.image || '';
        this.created = data.created || ''
        this.schedule = data.schedule || new PushNotificationSchedule({});
        this.rules = data.rules || [];
        this.groups = data.groups || [];
        this.timezone = data.timezone || 0;
    }
}

export class PushNotificationSchedule {
    public fromdate: string;
    public todate: string;
    public frequency: string;
    public weekday: number;
    public day: number;
    public month: number;

    constructor(data?: any) {
        this.fromdate = data.fromdate || ''
        this.todate = data.todate || ''
        this.frequency = data.frequency || 'unico'
        this.weekday = data.weekday || -1
        this.day = data.day || -1
        this.month = data.month || -1
    }
}

export class PushNotificationRule {
    public field: string;
    public fielddb: string;
    public condition: string;
    public value: string;

    constructor(data?: any) {
        this.field = data.field || ''
        this.fielddb = data.fielddb || ''
        this.condition = data.condition || ''
        this.value = data.value || ''
    }
}

export class PushNotificationGroup {
    public uuid: string
    public name: string
    public monitorid: string
    public business: PushNotificationBusiness[]

    constructor(data) {
        this.uuid = data.uuid || ''
        this.name = data.name || ''
        this.monitorid = data.monitorid || ''
        this.business = data.business || []
    }
}

export class PushNotificationBusiness {
    public uuid: string
    public name: string
    public monitorid: string

    constructor(data) {
        this.uuid = data.uuid || ''
        this.name = data.name || ''
        this.monitorid = data.monitorid || ''
    }
}

export class PushNotificationRules {
    public field: string
    public fielddb: string
    public condition: string
    public value: string

    constructor(data) {
        this.field = data.field || ''
        this.fielddb = data.fielddb || ''
        this.condition = data.condition || ''
        this.value = data.value || ''
    }
}