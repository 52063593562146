  <div class="content" *ngIf="!linkSent">
    <div class="container">
      <div class="card login-card">
        <div class="card-header">
          <div class="back-wrap">
            <a (click)="onGoHome()">
              <i class="fas fa-arrow-left"></i>
              <span class="light-text">Regresar</span>
            </a>
          </div>
          <div class="logo-wrap">
            <a (click)="onGoHome()">
              <img src="assets/images/logo.svg" alt="">
            </a>
          </div>
          <h3>Restablecer la contraseña</h3>
          <div class="row justify-content-center">
            <div class="col-9">
              <p class="light-text">Ingresa tu correo electrónico y te haremos llegar un link donde podrás reemplazar tu
                contraseña.</p>
            </div>
          </div>
        </div>
        <div class="card-body pt-10">
          <form [formGroup]="forma">
            <div class="form-group">
              <label for="mail">Correo electrónico</label>
              <input autocomplete="off" type="text" class="form-control" placeholder="mimal@mail.com" formControlName="email"
                [ngClass]="{'is-invalid invalidFieldForm': validEmail}"
                [ngStyle]="validEmail && {'border-color': 'red','margin-bottom': '3px'}">
              <div class="invalidFieldForm" style="color: red;font-size: 13px;" *ngIf="validEmail">
                Ingrese un correo electrónico válido
              </div>
            </div>
          </form>
          <div class="text-center mb-20">
            <a (click)="onRecoverPassword()"><button class="btn-primary">Enviar</button></a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="content" *ngIf="linkSent">
    <div class="container">
      <div class="card login-card">
        <div class="card-header">
          <div class="logo-wrap">
            <a (click)="onGoHome()">
              <img src="assets/images/logo.svg" alt="">
            </a>
          </div>
        </div>
        <div class="card-body pt-10 text-center">
          <div class="img-wrap">
            <img src="assets/images/icons/success.svg" alt="">
          </div>
          <div class="row justify-content-center">
            <div class="col-8">
              <p><b>Link de restauración de contraseña enviado.</b></p>
            </div>
          </div>
          <div class="text-center mtb-20">
            <button class="btn-primary" (click)="onGoCode()">Ingresar código</button>
          </div>
          <div class="text-center mtb-20">
            <button class="btn-primary" (click)="onGoHome()">Ir a inicio</button>
          </div>
        </div>
      </div>
    </div>
  </div>