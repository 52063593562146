import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';
import { DashboardComponent } from './screens/admin/dashboard/dashboard.component';
import { ProfileComponent } from './screens/admin/profile/profile.component';
import { SignInComponent } from './screens/sign-in/sign-in.component';
import { RegisterComponent } from './screens/register/register.component';
import { CodeVerifyComponent } from './screens/code-verify/code-verify.component';
import { PasswordRecoveryComponent } from './screens/password-recovery/password-recovery.component';
import { UserListComponent } from './screens/admin/users/user-list/user-list.component';
import { RolesListComponent } from './screens/admin/roles/roles-list/roles.component';
import { RolesActionComponent } from './screens/admin/roles/roles-action/roles-action.component';
import { CmsAuthedAdminComponent } from './layout/cms-authed-admin/cms-authed-admin.component';
import { CmsAdminComponent } from './layout/cms-admin/cms-admin.component';
import { AddPasswordComponent } from './screens/add-password/add-password.component';
import { NotFoundComponent } from './screens/not-found/not-found.component';
import { GroupProfileComponent } from './screens/admin/grups/group-profile/group-profile.component';
import { GroupListComponent } from './screens/admin/grups/group-list/group-list.component';
import { CompanyProfileComponent } from './screens/admin/grups/company-profile/company-profile.component';
import { PushNotificationsListComponent } from './screens/admin/push-notifications/push-notifications-list/push-notifications-list.component';
import { PollsListComponent } from './screens/admin/polls/polls-list/polls-list.component';
import { PushNotificationsActionComponent } from './screens/admin/push-notifications/push-notifications-action/push-notifications-action.component';

import { CompanyListComponent } from './screens/admin/company/company-list/company-list.component'
import { CompanyComponent } from './screens/admin/company/company/company.component'

import { PushAvisosActionComponent } from './screens/admin/push-avisos/push-avisos-action/push-avisos-action.component';

import { PollsActionComponent } from './screens/admin/polls/polls-action/polls-action.component';
import { QuestionsListComponent } from './screens/admin/polls/questions-list/questions-list.component';
import { QuestionsActionComponent } from './screens/admin/polls/questions-action/questions-action.component';
import { ConfigurationComponent } from './screens/admin/configuration/configuration.component';
import { CreateTemplateComponent } from './screens/admin/templates/create-template/create-template/create-template.component';
import { ApplyTemplateComponent } from './screens/admin/templates/apply-template/apply-template/apply-template.component';
import { ListTemplateComponent } from './screens/admin/templates/list-template/list-template/list-template.component';
import {PaginasComponent, GrupoVisualizacionComponent, SeccionesComponent } from './screens/admin/seccion-monitor/components';
import { CrearPaginaComponent } from './screens/admin/seccion-monitor/components/crear-pagina/crear-pagina.component';
import { VerPaginaComponent } from './screens/admin/seccion-monitor/components/ver-pagina/ver-pagina.component';
import { ElementsOrderComponent } from './screens/admin/seccion-monitor/components/elements-order/elements-order.component';
import { OrderPageComponent } from './screens/admin/seccion-monitor/components/order-page/order-page.component';

const routes: Routes = [
    {
        path: '',
        component: CmsAuthedAdminComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: 'dashboard', component: DashboardComponent },
            { path: 'profile/:uuid/edit', component: ProfileComponent },
            {
                path: 'users',
                children: [
                    { path: '', component: UserListComponent },
                    { path: 'create', component: ProfileComponent },
                    { path: ':uuid/edit', component: ProfileComponent },
                    { path: ':uuid/read', component: ProfileComponent },
                ]
            },
            {
                path: 'empresas',
                children: [
                    { path: '', component: CompanyListComponent },
                    { path: 'empresa_nueva', component: CompanyComponent },
                    { path: ':idEmpresa/:idGrupo/empresa_nueva', component: CompanyComponent },
                    { path: ':idEmpresa/empresa_edicion', component: CompanyComponent },
                    { path: ':idEmpresa/:idGrupo/editByGroup', component: CompanyComponent }
                ]
            },
            {
                path: 'groups-business',
                children: [
                    { path: '', component: GroupListComponent },
                    { path: 'create', component: GroupProfileComponent },
                    { path: ':uuid/edit', component: GroupProfileComponent },
                    { path: ':uuid/read', component: GroupProfileComponent },
                    { path: 'create-template/:id', component: CreateTemplateComponent },
                    { path: 'create-template', component: CreateTemplateComponent },
                    { path: 'apply-template', component: ApplyTemplateComponent },
                    { path: 'list-template', component: ListTemplateComponent },

                    { path: ':uuid/listCompany', component: CompanyProfileComponent },
                    { path: 'business/:uuid/edit', component: CompanyProfileComponent },
                    { path: 'business/:uuid/read', component: CompanyProfileComponent },
                    {
                        path: ':uuid',
                        children: [
                            { path: 'edit', component: GroupProfileComponent },
                            { path: 'read', component: GroupProfileComponent },
                            {
                                path: 'business',
                                children: [
                                    { path: 'create', component: CompanyProfileComponent },
                                    { path: ':uuid/edit', component: CompanyProfileComponent },
                                    { path: ':uuid/read', component: CompanyProfileComponent },
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                path: 'roles',
                children: [
                    { path: '', component: RolesListComponent },
                    { path: 'create', component: RolesActionComponent },
                    { path: ':uuid/edit', component: RolesActionComponent },
                    { path: ':uuid/read', component: RolesActionComponent },
                ]
            },
            {
                path: 'notifications',
                children: [
                    { path: '', component: PushNotificationsListComponent },
                    { path: 'create', component: PushNotificationsActionComponent },
                    { path: ':uuid/edit', component: PushNotificationsActionComponent },
                    { path: ':uuid/read', component: PushNotificationsActionComponent },
                ]
            },
            {
                path: 'avisos',
                children: [
                    { path: 'alta', component: PushAvisosActionComponent },
                ]
            },
            {
                path: 'polls',
                children: [
                    { path: '', component: PollsListComponent },
                    { path: 'create', component: PollsActionComponent },
                    { path: ':uuid/edit', component: PollsActionComponent },
                    { path: ':uuid/read', component: PollsActionComponent },
                    {
                        path: 'questions',
                        children: [
                            { path: '', component: QuestionsListComponent },
                            { path: 'create', component: QuestionsActionComponent },
                            { path: ':uuid/edit', component: QuestionsActionComponent },
                            { path: ':uuid/read', component: QuestionsActionComponent },
                        ]
                    }
                ]
            },
            { path: 'configuration', component: ConfigurationComponent },
            
            {
                path: 'seccionesMonitor',
                children: [
                    { path:'secciones', component:SeccionesComponent},
                    { path:'consultarSecciones', component:SeccionesComponent},
                    { path:'CrearSeccion', component:SeccionesComponent},
                    { path:'editarSeccion', component:SeccionesComponent},
                    { path:'paginas', component:PaginasComponent},
                    { path:'crearGrupoVisualizacion', component:GrupoVisualizacionComponent},
                    { path:'grupoVisualizacion', component:GrupoVisualizacionComponent},
                    { path:'asignarGrupoVisualizacion', component:GrupoVisualizacionComponent},
                    { path:'detallesGrupoVisualizacion', component:GrupoVisualizacionComponent},
                    {path:'secciones', component:SeccionesComponent},
                    {path:'crearPagina/:id', component:CrearPaginaComponent},
                    {path:'crearPagina', component:CrearPaginaComponent},
                    {path:'verPagina', component:VerPaginaComponent},
                    {path:'asignarPagina', component:VerPaginaComponent},
                    {path:'ordenarElementos', component:ElementsOrderComponent},
                    {path:'ordenarPaginas', component:OrderPageComponent},


                ]
            },

        ],
    },
    
    {
        path: '',
        component: CmsAdminComponent,
        children: [
            {
                path: 'sign-in',
                children: [
                    { path: '', component: SignInComponent },
                    { path: 'sign-in-error', component: SignInComponent },
                ]
            },
            {
                path: 'register',
                children: [
                    { path: '', component: RegisterComponent },
                    { path: 'code-verify', component: CodeVerifyComponent },
                    { path: 'add-password', component: AddPasswordComponent },
                    { path: 'confirm', component: CodeVerifyComponent },
                ]
            },
            {
                path: 'password-recovery',
                children: [
                    { path: '', component: PasswordRecoveryComponent },
                    { path: 'code-verify', component: CodeVerifyComponent },
                    { path: 'update-password', component: CodeVerifyComponent },
                    { path: 'confirm', component: CodeVerifyComponent },
                ]
            }
        ]
    },
    /*{
        path: 'seccionesMonitor',
        canActivate: [AuthGuard],
        loadChildren: () =>import('src/app/screens/admin/seccion-monitor/seccion-monitor.module').then(m=>m.SeccionMonitorModule),
      },*/
    {
        path: '**', component: NotFoundComponent
    },
    
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
