<div>
    <label class="title" for="nombreEncuesta">GRUPOS/EMPRESAS</label>
    <div class="col-12">
        <div class="form-group">
            <ng-select [items]="groupData.groups" [multiple]="false" [closeOnSelect]="true" [hideSelected]="true"
                bindLabel="name" placeholder="Selecciona un grupo" [(ngModel)]="groupData.groupSelected"
                (change)="onSection('group')">
            </ng-select>
        </div>
        <div class="form-group">
            <ng-select [items]="groupData.business" [multiple]="true" [closeOnSelect]="false" [hideSelected]="true"
                bindLabel="name" placeholder="Selecciona las empresas" [(ngModel)]="groupData.businessSelected">
            </ng-select>
        </div>
        <div class="d-flex justify-content-between" *ngIf="permissions?.create || permissions?.edit">
            <a class="link" (click)="onSection('business')">
                Agregar
            </a>
            <a class="link" (click)="onSection('business-all')">
                Agregar todas
            </a>
        </div>
        <div class="alert alert-danger mt-4" *ngIf="groupData.error">
            <span *ngIf="groupData.error == 1">
                Debes seleccionar un grupo para poder agregar empresas
            </span>
            <span *ngIf="groupData.error == 2">
                Debes agregar al menos 1 grupo y empresa
            </span>
        </div>
    </div>

    <div class="col-12 mt-3" style="overflow: auto;max-height: 150px">
        <ul>
            <ng-container *ngFor="let group of data; index as mainKey">
                <li class="mb-2" [ngStyle]="{'background-color': mainKey+'' === hover.item ? '#eee' : 'transparent'}">
                    <div class="p-1 d-flex justify-content-between group-list"
                        (mouseenter)="onSection('group-list', mainKey, -1)"
                        (mouseleave)="onSection('group-list', -1, -1)">
                        <div>{{ group.name }}</div>
                        <a class="align-self-center group-delete" [ngClass]="{'d-none': mainKey+'' !== hover.item}"
                            id="{{mainKey+''}}" (click)="onSection('delete', mainKey, -1)" *ngIf="permissions?.delete">
                            <svg version="1.2" preserveAspectRatio="none" viewBox="0 0 24 24" class="ng-element"
                                data-id="40412833983a4f2da710d72da927d6d5"
                                style="opacity: 1;mix-blend-mode: normal;fill: rgb(102, 102, 102);width: 27px;height: 27px;pointer-events: all;">
                                <g>
                                    <path xmlns:default="http://www.w3.org/2000/svg"
                                        d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                                        style="fill: rgb(102, 102, 102);"></path>
                                </g>
                            </svg>
                        </a>
                    </div>
                    <ul>
                        <ng-container *ngFor="let business of group.business; index as childKey">
                            <li class="my-1 p-1 ml-3 d-flex justify-content-between group-list"
                                (mouseenter)="onSection('group-list', mainKey, childKey)"
                                (mouseleave)="onSection('group-list', -1, -1)">
                                <div class="align-self-center">{{ business.name }}</div>
                                <a class="align-self-center group-delete"
                                    [ngClass]="{'d-none': mainKey+''+childKey !== hover.item}"
                                    id="{{mainKey+''+childKey}}" (click)="onSection('delete', mainKey, childKey)"
                                    *ngIf="permissions?.delete">
                                    <svg version="1.2" preserveAspectRatio="none" viewBox="0 0 24 24" class="ng-element"
                                        data-id="40412833983a4f2da710d72da927d6d5"
                                        style="opacity: 1;mix-blend-mode: normal;fill: rgb(102, 102, 102);width: 27px;height: 27px;pointer-events: all;">
                                        <g>
                                            <path xmlns:default="http://www.w3.org/2000/svg"
                                                d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                                                style="fill: rgb(102, 102, 102);"></path>
                                        </g>
                                    </svg>
                                </a>
                            </li>
                        </ng-container>
                    </ul>
                </li>
            </ng-container>
        </ul>
    </div>
</div>