<div class="container-fluid" *ngIf="showComponent">
  <div class="card border-card">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <h2>{{pageAction['titlePage']}}</h2>
        </div>
        <div class="col-md-12">
          <form [formGroup]="form">
            <div class="row">
              <div class="col-8">
                <div class="row">
                  <!--Nombre de empresa-->
                  <div class="col-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="form-group">
                          <label>TÍTULO DE LA NOTIFICACIÓN</label>
                          <input type="text" class="form-control" placeholder="Título de la notificación"
                            formControlName="title"
                            [ngStyle]="(f.title?.errors && (f.title.touched || f.title.dirty)) && {'border-color': 'red','margin-bottom': '3px'}">
                          <div class=" invalidFieldForm" style="color: red;font-size: 13px;">
                            <div
                              *ngIf="(f.title?.errors?.required || f.title?.errors?.minlength) && (f.title.touched || f.title.dirty)">
                              Este campo es requerido
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--Correo de contacto-->
                  <div class="col-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="form-group ">
                          <label>MENSAJE</label>
                          <textarea class="form-control mensaje" rows="6" formControlName="message"
                            [ngStyle]="(f.message?.errors && (f.message?.touched || f.message?.dirty)) && {'border-color': 'red','margin-bottom': '3px'}"></textarea>
                          <div class=" invalidFieldForm" style="color: red;font-size: 13px;">
                            <div
                              *ngIf="(f.message?.errors?.required || f.message?.errors?.minlength) && (f.message?.touched || f.message?.dirty)">
                              Este campo es requerido
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--Reglas de envio-->
                  <div class="col-12 overflow-custom">
                    <div class="card">
                      <div class="card-body">
                        <div class="form-group ">
                          <label for="mensajeNotificacion">REGLAS DE ENVIO</label>
                          <!-- <div class="row">
                            <div class="col-12 d-flex justify-content-around">
                              <div class="form-group d-flex justify-content-around align-items-center">
                                <input class="mr-2" type="radio" name="active">
                                <label class="m-0 mr-2">Todas las condiciones</label>
                              </div>
                              <div class="form-group d-flex justify-content-around align-items-center">
                                <input class="mr-2" type="radio" name="active">
                                <label class="m-0 mr-2">Cualquier condición</label>
                              </div>
                            </div>
                          </div> -->
                          <div class="row">
                            <div class="col-12">
                              <ng-container *ngFor="let form of formRules; index as index">
                                <form [formGroup]="form" class="d-flex justify-content-between">
                                  <div class="d-flex justify-content-start">
                                    <div class="form-group mr-2">
                                      <select class="form-control w-120-custom"
                                        [(ngModel)]="pushData.rules.fieldSelected[index]"
                                        [ngModelOptions]="{standalone: true}"
                                        (change)="onAction('rules', index+'-field')"
                                        [ngStyle]="(formRules[index].controls.field?.errors && (formRules[index].controls.field?.touched || formRules[index].controls.field?.dirty)) && {'border-color': 'red','margin-bottom': '3px'}">
                                        <ng-container *ngFor="let item of pushData.rules.field[index]; index as key">
                                          <ng-container *ngIf="showIndexSelectedField(key)">
                                            <option [value]="key">{{item.name}}</option>
                                          </ng-container>
                                        </ng-container>
                                      </select>
                                      <div class=" invalidFieldForm" style="color: red;font-size: 13px;">
                                        <div
                                          *ngIf="(formRules[index].controls.field?.errors?.required) && (formRules[index].controls.field?.touched || formRules[index].controls.field?.dirty)">
                                          Este campo es requerido
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group mr-2">
                                      <select class="form-control min-w-select"
                                        [(ngModel)]="pushData.rules.fielddbSelected[index]"
                                        [ngModelOptions]="{standalone: true}"
                                        (change)="onAction('rules', index+'-fielddb')"
                                        [ngStyle]="(formRules[index].controls.fielddb?.errors && (formRules[index].controls.fielddb?.touched || formRules[index].controls.fielddb?.dirty)) && {'border-color': 'red','margin-bottom': '3px'}">
                                        <ng-container
                                          *ngFor="let item of pushData.rules.field[index][pushData.rules.fieldSelected[index]].fielddb; index as key">
                                          <ng-container
                                            *ngIf="existOptionSelected(index, pushData.rules.fieldSelected[index], key)">
                                            <option [value]="key">{{item.name}}</option>
                                          </ng-container>
                                        </ng-container>
                                      </select>
                                      <div class=" invalidFieldForm" style="color: red;font-size: 13px;">
                                        <div
                                          *ngIf="(formRules[index].controls.fielddb?.errors?.required) && (formRules[index].controls.fielddb?.touched || formRules[index].controls.fielddb?.dirty)">
                                          Este campo es requerido
                                        </div>
                                      </div>
                                    </div>
                                    <ng-container
                                      *ngIf="pushData.rules.field[index][pushData.rules.fieldSelected[index]].fielddb[pushData.rules.fielddbSelected[index]].type != 'boolean'">
                                      <ng-container
                                        *ngIf="pushData.rules.field[index][pushData.rules.fieldSelected[index]].fielddb[pushData.rules.fielddbSelected[index]].type != 'select'">
                                        <div class="form-group mr-2">
                                          <select class="form-control"
                                            [(ngModel)]="pushData.rules.conditionSelected[index]"
                                            [ngModelOptions]="{standalone: true}"
                                            (change)="onAction('rules', index+'-condition')"
                                            [ngStyle]="(formRules[index].controls.condition?.errors && (formRules[index].controls.condition?.touched || formRules[index].controls.condition?.dirty)) && {'border-color': 'red','margin-bottom': '3px'}">
                                            <ng-container
                                              *ngFor="let item of pushData.rules.condition[index]; index as key">
                                              <option [value]="key">{{item.name}}</option>
                                            </ng-container>
                                          </select>
                                          <div class=" invalidFieldForm" style="color: red;font-size: 13px;">
                                            <div
                                              *ngIf="(formRules[index].controls.condition?.errors?.required) && (formRules[index].controls.condition?.touched || formRules[index].controls.condition?.dirty)">
                                              Este campo es requerido
                                            </div>
                                          </div>
                                        </div>
                                      </ng-container>

                                      <div class="form-group">
                                        <ng-container
                                          *ngIf="pushData.rules.field[index][pushData.rules.fieldSelected[index]].fielddb[pushData.rules.fielddbSelected[index]].type != 'select'; else typeSelect">
                                          <input type="text" class="form-control" placeholder="" formControlName="value"
                                            (keydown)="onAction('rules', index+'-value')"
                                            (change)="onAction('validate-users')"
                                            [ngStyle]="(formRules[index].controls.value?.errors && (formRules[index].controls.value?.touched || formRules[index].controls.value?.dirty)) && {'border-color': 'red','margin-bottom': '3px'}">
                                          <div class=" invalidFieldForm" style="color: red;font-size: 13px;">
                                            <div
                                              *ngIf="(formRules[index].controls.value?.errors?.required) && (formRules[index].controls.value?.touched || formRules[index].controls.value?.dirty)">
                                              Este campo es requerido
                                            </div>
                                          </div>
                                        </ng-container>

                                        <ng-template #typeSelect>
                                          <select class="form-control" formControlName="value"
                                            [ngStyle]="(formRules[index].controls.value?.errors && (formRules[index].controls.value?.touched || formRules[index].controls.value?.dirty)) && {'border-color': 'red','margin-bottom': '3px'}">
                                            <ng-container
                                              *ngFor="let item of pushData.rules.field[index][pushData.rules.fieldSelected[index]].fielddb[pushData.rules.fielddbSelected[index]].options; index as key">
                                              <option [value]="item.value">{{item.title}}</option>
                                            </ng-container>
                                          </select>
                                        </ng-template>

                                      </div>

                                    </ng-container>
                                  </div>
                                  <button class="btn btn-primary icon-pulls mb-0" placement="left"
                                    ngbTooltip="Eliminar regla" (click)="setFormRules('delete', index)"
                                    [style.height.px]="40">
                                    <span aria-hidden="true" class="fas fa-trash py-2"></span>
                                  </button>
                                </form>
                                <div *ngIf="usersNotExistBusiness[index]" class=" invalidFieldForm"
                                  style="color: red;font-size: 13px;position: relative;top: -15px;">
                                  Este usuario no pertenece en ninguna de las empresas seleccionadas
                                </div>
                              </ng-container>
                            </div>
                            <ng-container *ngIf="haveOptionsToAdd()">
                              <div class="actions-wrap col-auto">
                                <a class="d-flex justify-content-around align-items-center"
                                  (click)="setFormRules('add')">
                                  <i><img src="assets/images/icons/plus.svg" alt=""></i>
                                  Agregar Condición
                                </a>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--Programacion -->
                  <div class="col-12">
                    <div class="card">
                      <div class="card-body">
                        <form [formGroup]="formSchedule">
                          <label>PROGRAMACIÓN DE ENVIO</label>
                          <!--fecha / rango-->
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label class="sub-label">Desde</label>
                                  <div class="form-group">
                                    <div class="input-group"
                                      [ngStyle]="(fs.fromdate?.errors && (fs.fromdate?.touched || fs.fromdate?.dirty)) && {'border': '1px solid red'}">
                                      <input class="form-control mb-0" [(ngModel)]="pushData.calendar.fromdate"
                                        [ngModelOptions]="{standalone: true}" (dateSelect)="onAction('fromdate')"
                                        [minDate]="pushData.calendar.mindateFrom" placeholder="yyyy-mm-dd" ngbDatepicker
                                        #fromdate="ngbDatepicker" readonly>
                                      <div class="input-group-append">
                                        <button class="btn btn-outline-secondary fas fa-calendar" type="button"
                                          (click)="fromdate.toggle()"></button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group" *ngIf="pushData.frequencySelected">
                                  <label class="sub-label">Hasta</label>
                                  <div class="form-group">
                                    <div class="input-group"
                                      [ngStyle]="(fs.todate?.errors && (fs.todate?.touched || fs.todate?.dirty)) && {'border': '1px solid red'}">
                                      <input class="form-control mb-0" [(ngModel)]="pushData.calendar.todate"
                                        [ngModelOptions]="{standalone: true}" (dateSelect)="onAction('todate')"
                                        [minDate]="pushData.calendar.mindateTo" placeholder="yyyy-mm-dd" ngbDatepicker
                                        #todate="ngbDatepicker" readonly>
                                      <div class="input-group-append">
                                        <button class="btn btn-outline-secondary fas fa-calendar" type="button"
                                          (click)="todate.toggle()"></button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!--frecuencia / horario-->
                          <div class="col-12">
                            <div class="row">
                              <div class="col-6">
                                <div class="form-group">
                                  <label class="sub-label">Frecuencia</label>
                                  <select class="form-control" formControlName="frequency"
                                    [ngStyle]="(fs.frequency?.errors && (fs.frequency.touched || fs.frequency.dirty)) && {'border-color': 'red','margin-bottom': '3px'}"
                                    (change)="onAction('frequency')">
                                    <ng-container *ngFor="let item of pushData.frequency; index as key">
                                      <option [value]="item.sysname">{{item.title}}</option>
                                    </ng-container>
                                  </select>
                                  <div class=" invalidFieldForm" style="color: red;font-size: 13px;">
                                    <div
                                      *ngIf="(fs.frequency?.errors?.required || fs.frequency?.errors?.minlength) && (fs.frequency.touched || f.message?.dirty)">
                                      Este campo es requerido
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-6">
                                <label class="col-12 sub-label" for="horario">
                                  Hora de envío
                                </label>
                                <div class="d-flex justify-content-center">
                                  <div class="img-fluid text-center align-self-center mr-4">
                                    <i class="fas fa-clock fa-2x mt-1"></i>
                                  </div>
                                  <div class="align-self-center">
                                    <div class="input-group"
                                      [ngStyle]="(fs.fromdate?.errors && (fs.fromdate?.touched || fs.fromdate?.dirty)) && {'border': '1px solid red'}">
                                      <ngb-timepicker [(ngModel)]="pushData.calendar.time"
                                        [ngModelOptions]="{standalone: true}" size="small" [spinners]="false"
                                        [meridian]="false">
                                      </ngb-timepicker>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!--semanal -->
                          <div class="col-12" *ngIf="pushData.frequencySelected == 2">
                            <div class="col-12">
                              <div class="row">
                                <label class="sub-label">
                                  Seleccionar día de la semana
                                </label>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="row mt-2">
                                <div class="col-3"
                                  *ngFor="let item of pushData.frequency[pushData.frequencySelected].value; index as key">
                                  <button type="button" class="btn btn-secondary btn-block"
                                    (click)="onAction('weekday', key)"
                                    [ngClass]="{'active': key === fs.weekday.value}">{{item}}</button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!--Anual -->
                          <div class="col-12 mt-3" *ngIf="pushData.frequencySelected == 4 && !showAnualToMonth">
                            <div class="col-12">
                              <div class="row">
                                <label class="sub-label">
                                  Selecciona el mes que deseas realizar el envío
                                </label>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="row mt-2">
                                <div class="col-3"
                                  *ngFor="let item of pushData.frequency[pushData.frequencySelected].value; index as key">
                                  <button type="button" class="btn btn-secondary btn-block"
                                    (click)="onAction('month', key)"
                                    [ngClass]="{'active': key + 1 === fs.month.value}">{{item}}</button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!--Mensual || Anual -->
                          <div class="col-12 mt-3" *ngIf="pushData.frequencySelected == 3 || showAnualToMonth">
                            <div class="col-12">
                              <div class="row">
                                <label class="sub-label">
                                  Selecciona día del mes en que quieres realizar el envío
                                  <a [style.color]="'#007bff'" *ngIf="fs.month.value != -1"
                                    (click)="showAnualToMonth = false">
                                    Regresar
                                  </a>
                                </label>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="row mt-2">
                                <div [style.width.%]="14.2857143" [style.paddingLeft.px]="15"
                                  [style.paddingRight.px]="15" *ngFor="let item of typeCatalog(); index as key">
                                  <button type="button" class="btn btn-secondary btn-block"
                                    (click)="onAction('day', key)"
                                    [ngClass]="{'active': key + 1 === fs.day.value}">{{item}}</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="col-4">
                <div class="row">
                  <!--Estado del registro-->
                  <div class="col-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="form-group">
                          <label for="estadoGrupo">ESTADO</label>
                          <select class="form-control" formControlName="active">
                            <option [value]="true">Activo</option>
                            <option [value]="false">Desactivado</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--grupos / empresas-->
                  <div class="col-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="form-group">
                          <!-- <app-dropdown-groups-business [action]="pageAction.action" [data]="fg.groups.value"
                            (onAction)="onAction('groups', $event)"></app-dropdown-groups-business> -->
                          <!--grupos / empresas-->
                          <ng-container *ngIf="groupsShow">
                            <app-polls-groups [action]="pageAction.action" [data]="fg.groups.value"
                              (onAction)="onAction('groups', $event)" [groups]="groups">
                            </app-polls-groups>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--Logo de grupo-->
                  <div class="col-12">
                    <div class="card">
                      <div class="card-body box-logo">
                        <div class="form-group">
                          <label for="">IMAGEN DEL LA NOTIFICACIÓN</label>
                          <div class="row">
                            <div class="col-12 files-items-box">
                              <div *ngIf="!f.image.value">
                                <abarcms-file-upload [singleFile]="true" typeFile="jpg,png,jpeg" [sizeFile]="5"
                                  [files]="pushData.media.files" [contentID]="null" [callServiceInThisComponent]="true"
                                  (getFilesToUpload)="onAction('image', $event)" (isSingleFiles)="isSingleFile($event)">
                                </abarcms-file-upload>
                              </div>
                              <div *ngIf="f.image.value" class="image_wrapper">
                                <img [src]="f.image.value" style="width: 305px;" alt="push-image">
                                <button class="btn btn-danger btn-block" [disabled]="!f.image.value"
                                  (click)="resetPicture()"><i class="fas fa-trash-alt"></i></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="col-12 mb-4">
            <div class="botonera">
              <button class="btn btn-dark btn-block" routerLink="/notifications">Regresar</button>
              <button class="btn btn-primary btn-block my-0" (click)="onAction('save')">Guardar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>