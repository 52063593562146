import { Component, OnInit, Input, HostBinding, Output, EventEmitter, OnDestroy } from '@angular/core';

@Component({
  selector: 'abarcms-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit, OnDestroy {

  @Input('title')
  public title: string = '';

  @Input('faClass')
  public faClass: string = null;

  @Input('modalTextCentered')
  public modalTextCentered: boolean = true;

  @Input('btnLeft')
  public btnLeft: string = '';
  @Output('left')
  public leftEE: EventEmitter<any> = new EventEmitter<any>();

  @Input('btnMiddle')
  public btnMiddle: string = '';
  @Output('middle')
  public middleEE: EventEmitter<any> = new EventEmitter<any>();

  @Input('btnRight')
  public btnRight = '';
  @Input() public btnRightEnabled = true;
  @Output('right')
  public rightEE: EventEmitter<any> = new EventEmitter<any>();

  @Output('close')
  public closeEE: EventEmitter<any> = new EventEmitter<any>();

  @Input() opacity = 1

  constructor() { }

  ngOnInit() { }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    let body = document.getElementsByTagName("body");
    let elements = document.getElementsByClassName("modal-backdrop");
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
    body[0].removeAttribute("style");
    body[0].removeAttribute("class");
  }

  onClose() {
    this.closeEE.emit(this);
  }

  onLeft() {
    this.leftEE.emit(this);
  }

  onMiddle() {
    this.middleEE.emit(this);
  }

  onRight() {
    this.rightEE.emit(this);
  }
}
