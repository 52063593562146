import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ResponseData } from 'src/app/dto/ResponseData';
import { SettingsService } from '../../settings.service';
import { createResponseData, makeHeaders } from 'src/app/utils/_http';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../api.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationDataWsService {
  private pathInfo: any;
  private baseUrl: string;
  private segment: any;
  constructor(
    private settings: SettingsService,
    private api: ApiService
  ) {
    this.pathInfo = this.settings.get('pathInfo');
    this.baseUrl = this.pathInfo.base.baseUrl + this.pathInfo.base.prefix;
    this.segment = this.pathInfo.res.admin['configuration'];
  }
  private create(body: object): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix;
    return this.api.post(endpoint, body);
  }
  private read(): Observable<ResponseData> {
    let options = {}
    let endpoint = this.baseUrl + this.segment.prefix;
    return this.api.get(endpoint, makeHeaders(options));
  }
  private readOne(uuid: any): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.getOne.replace(':uuid', uuid);
    return this.api.get(endpoint);
  }
  private update(uuid: any, body: object): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.edit.replace(':uuid', uuid);
    return this.api.put(endpoint, body);
  }
}
