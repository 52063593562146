<div
  *ngIf="
    (origin == 'secciones' && vista == 'toSearchPage') ||
    vista == 'toCreate' ||
    vista == 'toUpdate'
  "
  style="text-align: right"
>
  <a (click)="verLista()" style="text-decoration: underline"
    ><i class="fa fa-eye-slash" *ngIf="mostrarLista"></i>
    <i class="fas fa-eye" *ngIf="!mostrarLista"></i>{{ opcion }}</a
  >
</div>
<div class="divScroll">
  <div *ngIf="mostrarLista">
    <div>
      <h6>{{ title }}</h6>
    </div>
    <ul
      *ngIf="vista != 'toSearchPage'"
      style="
        width: 60%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      "
    >
      <li
        style="
          margin: 10px;
          flex-basis: 30%;
          word-wrap: break-word;
          max-height: 200px;
          overflow: hidden;
        "
        *ngFor="let elemento of elementos"
      >
        <span>*{{ elemento.nombre }}</span>
      </li>
    </ul>
    <ul
      *ngIf="vista == 'toSearchPage'"
      style="
        width: 80%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      "
    >
      <li
        style="
          margin: 10px;
          flex-basis: 30%;
          word-wrap: break-word;
          max-height: 200px;
          overflow: hidden;
        "
        *ngFor="let page of pages"
      >
        <span>{{ page.nombre }}</span>
      </li>
    </ul>
  </div>
</div>
<abarcms-modal
  title="Filtros de búsqueda inválidos"
  *ngIf="showValidationErrorModal"
  (close)="showValidationErrorModal = false"
  [btnRight]="'Aceptar'"
  (right)="showValidationErrorModal = false"
>
  <p class="alert alert-danger">
    Es necesario agregar más filtros para realizar una búsqueda correcta.
  </p>
</abarcms-modal>
<table *ngIf="vista == 'toSearch' && activeSearch == true && !mostrarLista">
  <tr>
    <th>Nombre de grupo de visualización</th>
    <th>Acciones</th>
  </tr>
  <tbody>
    <tr *ngFor="let grupoVisualizacion of gruposVisualizacion">
      <td *ngIf="origin != 'paginas'">{{ grupoVisualizacion.name }}</td>
      <td *ngIf="origin == 'paginas'">
        <mat-radio-group
          aria-labelledby="example-radio-group-label"
          [(ngModel)]="tipoGrupoSelect"
          (change)="asignaRadio($event)"
        >
          <mat-radio-button [value]="grupoVisualizacion.id"
            >{{ grupoVisualizacion.name }}
          </mat-radio-button>
        </mat-radio-group>
      </td>
      <td><a (click)="openDetails(grupoVisualizacion.id)">Ver detalles</a></td>
    </tr>
  </tbody>
</table>

<table
  class="tableResults"
  *ngIf="
    (vista == 'toUpdate' && !mostrarLista) ||
    (vista == 'toCreate' && !mostrarLista)
  "
>
  <tr>
    <th *ngIf="viewResults.length > 0"></th>
    <th *ngIf="typeGroupChecked == 4">Id monitor</th>
    <th>Nombre de usuario</th>
    <th></th>
  </tr>
  <tbody *ngIf="viewResults.length > 0">
    <tr *ngFor="let viewResult of viewResults">
      <td>
        <mat-checkbox
          [(ngModel)]="viewResult.checked"
          (change)="
            addElementsIds(viewResult.checked, viewResult.Id, viewResult.name)
          "
        ></mat-checkbox>
      </td>
      <td *ngIf="typeGroupChecked == 4">{{ viewResult.Id }}</td>
      <td>{{ viewResult.name }}</td>
    </tr>
  </tbody>
  <tbody *ngIf="viewResults.length == 0">
    <tr>
      <td>
        <p>No se encontraron resultados</p>
      </td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td></td>
    </tr>
  </tbody>
</table>

<table *ngIf="vista == 'toSearchPage' && activeSearch == true && !mostrarLista">
  <tr>
    <th>Nombre</th>
    <th>Descripción</th>
    <th>URL</th>
    <th>Estatus</th>
    <th>Fecha Creación</th>
    <th>Acción</th>
  </tr>
  <tbody>
    <tr *ngFor="let pagina of paginas">
      <td *ngIf="origin == 'secciones' || origin == 'paginas'">
        <mat-checkbox
          [(ngModel)]="pagina.checked"
          (change)="pageChecked(pagina.checked, pagina.id, pagina.name)"
          >{{ pagina.name }}</mat-checkbox
        >
      </td>
      <td
        *ngIf="
          (vista == 'toSearchPage' && origin != 'secciones') ||
          origin != 'paginas'
        "
      >
        {{ pagina.name }}
      </td>
      <td>{{ pagina.descripcion }}</td>
      <td>{{ pagina.url }}</td>
      <td>{{ pagina.status === 1 ? "Activo" : "Inactivo" }}</td>
      <td>{{ pagina.createdAt }}</td>
      <td style="text-align: center">
        <a (click)="irAPagina(pagina.id)"><i class="fas fa-eye"></i></a>
      </td>
    </tr>
  </tbody>
</table>

<div class="paginator" *ngIf="!mostrarLista">
  <mat-paginator
    [length]="total_rows"
    [pageSize]="pageSize"
    [hidePageSize]="true"
    [pageIndex]="currentPage"
    (page)="cambiarPagina($event)"
  ></mat-paginator>
</div>
<abarcms-loader-full *ngIf="loading"></abarcms-loader-full>
