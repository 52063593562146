<abarcms-loader-full *ngIf="loading"></abarcms-loader-full>
<div class="container-fluid">
    <div class="card border-card">
        <div class="card-content size" >

            <h6>{{title}}</h6>
            <div *ngIf="vista=='toSearch'" class="divRow"><app-boton [textContent]="'Crear sección'" (click)="openCreateSection()"></app-boton></div>
        
        <!--Inicio ToCreate-->
            <div *ngIf="vista=='toEdit' || vista=='toCreate'"> 
                <form #f="ngForm" >
            <div class="card">
                <div class="card-body">
                    <div class="form-group ">
                        <label>*Nombre Sección:</label>
                        <input matInput class="form-control" type="text"  [(ngModel)]="sectionName" name="sectionName" placeholder="Nombre seccion" required>
                        <div *ngIf="f.submitted && f.controls['sectionName'].invalid">
                            <p class="error-message">El campo nombre sección es requerido.</p>
                          </div>
                    </div>      
                </div>
            </div>
            <div class="card size">
                <div class="card-body">        
                    <div class="form-group ">
                        <label>*Descripción:</label>
                        <textarea matInput class="form-control" [(ngModel)]="sectionDescription" name="sectionDescription" placeholder="Descripción" required></textarea>
                        <div *ngIf="f.submitted && f.controls['sectionDescription'].invalid">
                            <p class="error-message">El campo descripción es requerido.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card size">
                <div class="card-body">
                    <div class="form-group" style="display: flex; flex-direction: column;">
                        <label for="icono">Icono:</label>
                        <div style="display: flex;">
                          <label class="btn btn-outline-primary border-0" style="border-radius: 0;">
                            <i class="fas fa-folder"></i> 
                            <input type="file" accept=".png" (change)="onFileSelected($event)" style="display: none;">
                          </label>                          
                          <img [src]="previewImage" *ngIf="previewImage" style="max-width: 200px; margin-left: 10px;">
                        </div>
                        <input type="hidden" [value]="base64Image">
                      </div>  
                </div>
            </div>
            <div class="card size">
                <div class="card-body">
                    <div class="form-group ">
                        <label for="rolName">*Color:</label><br>
                        <input type="color" [(ngModel)]="sectionColor" name="sectionColor" required>
                        <div *ngIf="f.submitted && f.controls['sectionColor'].invalid">
                            <p class="error-message">El campo color es requerido.</p>
                        </div>
                    </div>   
                </div>
            </div>
            <div class="card size">
                <div class="card-body">
                    <div class="form-group ">
                        <label for="rolName">*Estatus:</label><br>
                        <mat-radio-group  aria-labelledby="example-radio-group-label" [(ngModel)]="sectionStatus" name="sectionStatus" required>
                            <mat-radio-button class="radioGroup" [checked]='sectionStatus==1?true:false' [value]="1">Activo </mat-radio-button>
                            <mat-radio-button class="radioGroup" [checked]='sectionStatus==0?true:false' [value]="0">Inactivo </mat-radio-button>
                        </mat-radio-group>
                        <div *ngIf="f.submitted && f.controls['sectionStatus'].invalid">
                            <p class="error-message">Por favor seleccione una de las opciones.</p>
                        </div>
                    </div> 
                </div>
            </div>

            <div class="divRow groupButton">
                    <app-boton [textContent]="'Guardar y siguiente'" (click)="createUpdateSection(1,f)"></app-boton>
                    <app-boton [textContent]="'Guardar y salir'" (click)="createUpdateSection(2,f)"></app-boton>
                    <app-boton [textContent]="'Cancelar'" [backgroundColor]="'#000000'" class="buttonCancelar" (click)="cancel()"></app-boton>
                </div>
            </form>
            </div>  
        <!--fin ToCreate-->

        <!--Inicio ToSearch-->
            <div *ngIf="vista=='toSearch'" class="searchSection">
            <div class="card">
                <div class="card-body">
                    <div class="form-group ">
                        <div>
                        <label for="Nombre">Buscar</label><br>
                        <mat-radio-group aria-labelledby="example-radio-group-label" [(ngModel)]="displayGroupTypeId" (change)="displayGroupTypeIdChecked()">
                            <mat-radio-button class="radioGroup" *ngFor="let catGrupoVisualizacion of catGruposVisualizacion" [value]="catGrupoVisualizacion.id">
                              {{catGrupoVisualizacion.name}}
                            </mat-radio-button>
                          </mat-radio-group>
                        </div>
                        <br>
                        <table class="contentForm" *ngIf="showFilters">
                            <tr>
                                <td class="nombre">
                                    <input matInput class="form-control" type="text" [(ngModel)]="inputSearchSection" placeholder="Nombre de la seccion" >
                                </td>
                                <td>
                                    <input type="text" matInput class="form-control inputDisplayGroup"  [matAutocomplete]="auto" [(ngModel)]="selectedDisplayGroupName" placeholder="Grupo de visualización" (input)="onInput($event)" (focus)="focus()">
                                    <mat-autocomplete #auto="matAutocomplete" >
                                        <mat-option *ngFor="let displayGroup of displayGroupsList" (click)="onOptionSelected(displayGroup.id,displayGroup.name)">
                                            {{ displayGroup.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </td>
                            </tr>
                        </table>
                        <app-boton [textContent]="'Buscar'" (click)="searchSection()"></app-boton>
                    </div>    
                </div>
            </div>
            <div class="card relativ">
                <div class="card-body">
                    <div class="table-responsive p-2">
                        <div class="table" >
                          <div class="columHeader">
                            <table>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Descripción</th>
                                        <th>Estatus</th>
                                        <th>Fecha Creación</th>
                                        <th>Acciones</th> 
                                    </tr>
                                    <tr *ngFor="let section of sections">
                                        <td>{{section.name}}</td>
                                        <td>{{section.descripcion}}</td>
                                        <td>{{section.status===1?'Activo':'Inactivo'}}</td>
                                        <td>{{section.createdAt}}</td>
                                        <td style="text-align: center;"><a (click)="openDetailsSection(section.id)"><i class="fas fa-eye"></i></a><mat-icon (click)="openEdit(section.id)">edit</mat-icon><mat-icon (click)="deleteSection(section.id)">delete</mat-icon></td>
                                      </tr>
                            </table>
                          </div>
                        </div>
                        <div class="paginator">
                            <mat-paginator [length]="total_rows" [pageSize]="pageSize" [hidePageSize]="true" [pageIndex]="page" (page)="cambiarPagina($event)"></mat-paginator>
                        </div>
                      </div>
                </div>
            </div>
            </div>
        <!--fin ToSearch-->
        </div>
    </div>
</div>
