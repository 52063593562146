<h2>Usuarios</h2>
<div class="wrap" *ngIf="data.users !== undefined && data.users.length">
  <h4>Validación de nuevos registros</h4>
  <ul class="validation-table">
    <li class="row" *ngFor="let d of data.users; let i = index">
      <div class="col-7 no-pad">
        <p>{{d["name"]}} ({{d["email"]}})</p>
      </div>
      <div class="col-2">
        <input class="switch" id="switch_{{d['uuid']}}" type="checkbox" (click)="onSetActiveUser(d, i)"
          name="cb_{{d['uuid']}}" [(ngModel)]="d['status']">
        <label for="switch_{{d['uuid']}}"></label>
      </div>
      <div class="col-3 no-pad">
        <a class="underline-link" [routerLink]="['/users/', d['uuid'], 'edit']">Detalle</a>
      </div>
    </li>
  </ul>
</div>

<div class="">
  <h4>Agregar usuario</h4>
  <form [formGroup]="formaUser">
    <div class="form-group">
      <label for="email">Correo electrónico</label>
      <input autocomplete="off" type="text" class="form-control" placeholder="" formControlName="email"
        [ngClass]="{'is-invalid invalidFieldForm': validEmail}"
        [ngStyle]="validEmail && {'border-color': 'red','margin-bottom': '3px'}">
      <div class="invalidFieldForm" style="color: red;font-size: 13px;" *ngIf="validEmail">
        Ingrese un correo electrónico válido
      </div>
    </div>
    <div class="form-group" *ngIf="data.roles">
      <label for="">Rol</label>
      <div [ngClass]="{'is-invalid invalidFieldForm': validRole}"
        [ngStyle]="validRole && {'border': '1px solid red','margin-bottom': '3px','border-radius': '4px'}">
        <abarcms-multiselect class="form-group" [enableFilter]="false" [filterPlaceholder]="'Filtrar...'"
          [title]="'Asignar rol'" [items]="data.roles" [fieldToLabel]="['name']" [fieldToValue]="'id'"
          [values]="formaUser.value.roles" (getDropdownSelected)="getDropdownSelected($event)">
        </abarcms-multiselect>
      </div>
      <div class="invalidFieldForm" style="color: red;font-size: 13px;" *ngIf="validRole">
        Debes seleccionar una opción
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-6 text-center">
      <button class="btn-secondary" (click)="onAddUser()">Agregar</button>
    </div>
    <div class="col-6 text-center">
      <button class="btn-outline" (click)="onCancelAddUser()">Cancelar</button>
    </div>
  </div>
</div>