import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SettingsService } from './settings.service';
import { Runner } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class ValidateService {
  validateSection:string = "validate";
  constructor(private settings:SettingsService) { }

  public validate(value:any,key:string,domain:string=null):boolean{
    if(!this.settings || !this.settings.data || !this.settings.data[this.validateSection]){
      console.warn("There is no validation settings. Returning valid.");
      return true;
    }
    var section = this.settings.data[this.validateSection];
    if(domain){
      if(!section[domain]){
        console.warn("There is no validation section ",domain," using default section");
      }else{
        section = section[domain];
      }
    }
    if(!section[key]){
      console.warn("There is no validation for key ",key,"returning true.");
      return true;
    }
    let pat = new RegExp(section[key]);
    if(!pat.test(value)){
      throw new Error(value+" is invalid for key "+key);
    }
    return true;
  }
  public validateValues(values:object,domain:string=null){
    for(var i in values){
      if(values.hasOwnProperty(i)){
        this.validate(values[i],i,domain);
      }
    }
    return true;
  }

  public passwordConfirm( passOrg:string, passConfirm:string )  {
    return ( formGroup: FormGroup ) => {
      const passOrgControl = formGroup.controls[passOrg];
      const passConfirmControl = formGroup.controls[passConfirm];

      if ( passOrgControl.value === passConfirmControl.value ) passConfirmControl.setErrors(null);
      else passConfirmControl.setErrors({notEqual:true})
    }
  }
}
