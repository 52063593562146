<abarcms-loader-full *ngIf="loading"></abarcms-loader-full>


  <div class="card">
    <div class="card-body">
      <div class="table-responsive p-2">
        <!-- inicio Grupos de visualizaciónvista=='toSearch'||origin!='searchPage'-->
            <div class="table" *ngIf="vista=='toSearch' && activeSearch==true">
              <div class="columHeader">
                <app-table [page]="page" [clicks]="clicks"></app-table>
              </div>
            </div>
        <!-- Fin Grupos de visualización-->
        <div class="table" *ngIf="vista=='toCreate' && activeSearch==true||vista=='toSearchPage' && activeSearch==true||vista=='toUpdate' && activeSearch==true">
          <div class="columHeader">
            <app-table [page]="page" [clicks]="clicks"></app-table>
          </div>
        </div>

      </div>
    </div>
  </div>

