import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { SettingsService } from 'src/app/services/settings.service';
import { ValidateService } from 'src/app/services/validate.service';
import { UserDataService } from 'src/app/services/data/user-data.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { constants } from 'src/app/constants';
import { getValid } from 'src/app/utils/_http';
import { setStorage, removeStorage, existStorage } from 'src/app/utils/_utils';
import { ModalHelperService } from 'src/app/services/modal-helper.service';

@Component({
  selector: 'app-password-recovery-modal',
  templateUrl: './password-recovery-modal.component.html',
  styleUrls: ['./password-recovery-modal.component.css']
})
export class PasswordRecoveryModalComponent implements OnInit {
  public linkSent: boolean = false;
  public validationError: string = null;
  public showValidationErrorModal: boolean = false;
  public error = { show: false, message: null };
  public data = {};
  public forma: FormGroup;

  constructor(
    private router: Router,
    public settings: SettingsService,
    private validate: ValidateService,
    public userDataService: UserDataService,
    private fb: FormBuilder,
    private modalHelper: ModalHelperService,
    public activeModal: NgbActiveModal,
  ) {
    this.createForm();
    if (existStorage(constants.ls.passwordRecovery)) this.linkSent = true;
    else {
      removeStorage([constants.ls.passwordRecovery, constants.ls.codeVerify]);
    }
  }

  ngOnInit(): void {
  }
  get validEmail() {
    return this.forma.get('email').invalid && this.forma.get('email').touched;
  }
  createForm() {
    this.forma = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(this.settings.get('validate')['email'])]],
    })
  }
  onRecoverPassword() {
    if (this.userDataService.startPasswordRecovery['loading']) { return; }
    // this.validate.validateValues(this.data, 'passwordRecovery');
    if (this.forma.invalid) {
      return Object.values(this.forma.controls).forEach(control => {
        control.markAsTouched();
      });
    }
    this.userDataService.startPasswordRecovery['loading'] = true;
    this.data = { ...this.forma.value };
    var dataSend = {
      email: this.data['email']
    };
    delete dataSend['passwordConfirm'];
    this.userDataService.startPasswordRecovery(dataSend)
      .subscribe((rs) => {
        if (getValid(rs, { title: `Error recuperar la contraseña`, modalHelper: this.modalHelper, settings: this.settings })) {
          if(rs.body.estatus === true)
          {
            let data = {
              action: constants.screens.passwordRecovery.path,
              data: dataSend
            }
            setStorage(constants.ls.passwordRecovery, JSON.stringify(data));
            this.modalHelper.changeLoading(false);
            this.linkSent = true;
            this.activeModal.close('success');
          }else{
            this.activeModal.close('error');
          }
        }
      })
  }
  onGoHome() {
    removeStorage(constants.ls.passwordRecovery);
    this.router.navigate(['/']);
  }
  onGoCode() {
    this.router.navigate([constants.screens.passwordRecovery.path, constants.screens.passwordRecovery.code]);
  }

  onClose() {
    this.activeModal.close('CLOSED');
}

}
