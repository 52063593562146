import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SeccionMonitorService } from '../../services/seccion-monitor.service';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {
  detalles:any=[]
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private seccionesMonitorService:SeccionMonitorService,
  ) {}

  ngOnInit(): void {
    this.dialogRef.updateSize('30%', 'auto');
    
  }
  deleteSection():void{
    this.dialogRef.close(0)//0 = EL USUARIO CONTINUO CON LA ELIMINACION 
  }
  
  closeDialog():void{
    this.dialogRef.close(1);// 1 = EL USUARIO CANCELO LA OPERACION
  }

}
