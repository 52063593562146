<div class="card-content">

  <div class="card">
    <div class="card-body">
      <div class="table-responsive p-2">
        <!-- inicio Grupos de visualización-->
            <div class="table " *ngIf="gruposVisualizacion.length!=0 ">
              <div class="columHeader">
                <table>
                  <tr>
                    <th> Nombre de grupo de visualización</th>
                    <th> Acción </th>
                  </tr>
                  <tr *ngFor="let grupoVisualizacion of gruposVisualizacion | slice:desde:hasta let i=index">
                    <td> 
                      <mat-radio-group  aria-labelledby="example-radio-group-label" [(ngModel)]="tipoGrupoSelect" (change)="asignaRadio($event)" >
                        <mat-radio-button   [value]="grupoVisualizacion.id">{{grupoVisualizacion.name}}
                        </mat-radio-button>
                      </mat-radio-group>
                    </td>
                    <td><a (click)="openDetailsDialog(grupoVisualizacion.id)">Ver detalles</a> </td>
                  </tr>
                </table>
              </div>
            </div>
        <!-- Fin Grupos de visualización-->
           <div class="paginator">
            <mat-paginator [length]="total_rows" [pageSize]="pageSize" [hidePageSize]="true" (page)="changeUsersPage($event)"></mat-paginator>
           </div>
      </div>
    </div>
  </div>
</div>
