import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SettingsService } from "src/app/services";
import { makeHeaders } from "src/app/utils/_http";
import { getMonitorToken, getMonitorUserId } from "./http-monitor";
import { HttpMonitorService } from "./http-monitor.service";

@Injectable({
  providedIn: "root",
})
export class AvisoService {
  private baseUrl: string;
  pathInfo: any;
  constructor(
    private settingsService: SettingsService,
    private http: HttpClient,
    private monitorService: HttpMonitorService
  ) {
    this.pathInfo = this.settingsService.get("pathInfo");
    this.baseUrl = this.pathInfo.base.monitor.url;
  }

  async saveAviso(data: any): Promise<Observable<any>> {
    let monitorUserId: string = await this.monitorService.getMonitorUserId();
    let monitorToken: string = await this.monitorService.getMonitorToken();
    let url = this.baseUrl + "/v2/admin/" + monitorUserId + "/avisos";
    return this.http.post<any>(
      url,
      data,
      makeHeaders({ Authorization: "Bearer " + monitorToken })
    );
  }

  async allGrupoVisualizacion(data: any): Promise<Observable<any>> {
    let monitorUserId: string = await this.monitorService.getMonitorUserId();
    let monitorToken: string = await this.monitorService.getMonitorToken();
    let url =
      this.baseUrl + "/v2/admin/" + monitorUserId + "/allGrupoVisualizacion";
    return this.http.post<any>(
      url,
      data,
      makeHeaders({ Authorization: "Bearer " + monitorToken })
    );
  }

  async allPaginas(data: any): Promise<Observable<any>> {
    let monitorUserId: string = await this.monitorService.getMonitorUserId();
    let monitorToken: string = await this.monitorService.getMonitorToken();
    let url = this.baseUrl + "/v2/admin/" + monitorUserId + "/allPaginas";
    return this.http.post<any>(
      url,
      data,
      makeHeaders({ Authorization: "Bearer " + monitorToken })
    );
  }

  async allCompany(): Promise<Observable<any>> {
    let monitorToken: string = await this.monitorService.getMonitorToken();
    let url = this.baseUrl + "/v1/company";
    return this.http.get<any>(
      url,
      makeHeaders({ Authorization: "Bearer " + monitorToken })
    );
  }

  async allDiseases(): Promise<Observable<any>> {
    let monitorToken: string = await this.monitorService.getMonitorToken();
    let url = this.baseUrl + "/v1/diseases";
    return this.http.get<any>(
      url,
      makeHeaders({ Authorization: "Bearer " + monitorToken })
    );
  }

  async allTypeUsers(): Promise<Observable<any>> {
    let monitorToken: string = await this.monitorService.getMonitorToken();
    let url = this.baseUrl + "/v2/typeUsers";
    return this.http.get<any>(
      url,
      makeHeaders({ Authorization: "Bearer " + monitorToken })
    );
  }

  async allUsuarios(data: any): Promise<Observable<any>> {
    let monitorUserId: string = await this.monitorService.getMonitorUserId();
    let monitorToken: string = await this.monitorService.getMonitorToken();
    let url = this.baseUrl + "/v2/admin/" + monitorUserId + "/usuarios";
    return this.http.post<any>(
      url,
      data,
      makeHeaders({ Authorization: "Bearer " + monitorToken })
    );
  }
}
