export class Media {
    public uuid: any;
    public name: string;
    public author?: string;
    public description?: string;
    public type: string;
    public size: number;
    public base64: string;
    public dimension?: string;
    public path?: string;
    public created?: string;
    public modified?: string;
    public alt: string;
    public folder?: any;
    public tag?: any;
    public extension: string;
}
