<div class="content">
  <div class="container">

    <div class="card login-card confirm" *ngIf="url.current == 'code'">
      <div class="card-header">
        <div class="back-wrap">
          <a (click)="onGoBack()">
            <i class="fas fa-arrow-left"></i>
            <span class="light-text">Regresar</span>
          </a>
        </div>
        <div class="logo-wrap">
          <a (click)="onGoHome()">
            <img src="assets/images/logo.svg" alt="">
          </a>
        </div>
        <h3>Te hemos enviado un código de confirmación, por favor introduce el código</h3>
      </div>
      <div class="card-body pt-10 pb-5 text-center">

        <form [formGroup]="formaCode">
          <div class="d-flex justify-content-between pb-4">
            <input autocomplete="off" type="text" class="form-control text-center" minlength="1" maxlength="1" #code_1
              formControlName="code_1" [ngClass]="{'is-invalid invalidFieldForm': validCode_1}"
              [ngStyle]="validCode_1 && {'border-color': 'red','margin-bottom': '3px'}"
              (keydown)="onChangeText($event,1)">
            <input autocomplete="off" type="text" class="form-control text-center" minlength="1" maxlength="1" #code_2
              formControlName="code_2" [ngClass]="{'is-invalid invalidFieldForm': validCode_2}"
              [ngStyle]="validCode_2 && {'border-color': 'red','margin-bottom': '3px'}"
              (keydown)="onChangeText($event,2)">
            <input autocomplete="off" type="text" class="form-control text-center" minlength="1" maxlength="1" #code_3
              formControlName="code_3" [ngClass]="{'is-invalid invalidFieldForm': validCode_3}"
              [ngStyle]="validCode_3 && {'border-color': 'red','margin-bottom': '3px'}"
              (keydown)="onChangeText($event,3)">
            <input autocomplete="off" type="text" class="form-control text-center" minlength="1" maxlength="1" #code_4
              formControlName="code_4" [ngClass]="{'is-invalid invalidFieldForm': validCode_4}"
              [ngStyle]="validCode_4 && {'border-color': 'red','margin-bottom': '3px'}"
              (keydown)="onChangeText($event,4)">
            <input autocomplete="off" type="text" class="form-control text-center" minlength="1" maxlength="1" #code_5
              formControlName="code_5" [ngClass]="{'is-invalid invalidFieldForm': validCode_5}"
              [ngStyle]="validCode_5 && {'border-color': 'red','margin-bottom': '3px'}"
              (keydown)="onChangeText($event,5)">
          </div>
          <input autocomplete="off" type="hidden" value="{{code.join() | replace:',':''}}">
          <div class="invalidFieldForm text-center" style="color: red;font-size: 13px; position: relative;top: -20px"
            *ngIf="validCode_1 || validCode_2 || validCode_3 || validCode_4 || validCode_5">
            {{validationText.textSize | replace:'{minlength}':'5'}}
          </div>
          <div class="text-center mb-20">
            <button (click)="onCodeVerifyRegister()" type="submit" class="btn-primary">Continuar</button>
          </div>
        </form>
        <p class="text-center">¿No has recibido ningún código?</p>
        <a (click)="forwardCode()" type="button" class="link-simple d-flex justify-content-center">Reenviar
          código</a>
      </div>
    </div>

    <div class="card login-card" *ngIf="url.current == 'add' || url.current == 'change'">
      <div class="card-header">
        <div class="back-wrap">
          <a (click)="onGoBack()">
            <i class="fas fa-arrow-left"></i>
            <span class="light-text">Regresar</span>
          </a>
        </div>
        <div class="logo-wrap">
          <a (click)="onGoHome()">
            <img src="assets/images/logo.svg" alt="">
          </a>
        </div>
        <h3>Ingresa la contraseña</h3>
      </div>
      <div class="card-body pt-10 pb-5 text-center">
        <form [formGroup]="formaPassword">
          <div class="form-group">
            <label for="password">Contraseña</label>
            <input autocomplete="off" type="password" class="form-control" placeholder="**********"
              formControlName="password" [ngClass]="{'is-invalid invalidFieldForm': validPassword}"
              [ngStyle]="validPassword && {'border-color': 'red','margin-bottom': '3px'}">
            <div class="invalidFieldForm" style="color: red;font-size: 13px;" *ngIf="validPassword">
              Debe ingresar de 8 hasta 32 caracteres
            </div>
          </div>
          <div class="form-group">
            <label for="repeat-password">Confirmar contraseña</label>
            <input autocomplete="off" type="password" class="form-control" placeholder="**********"
              formControlName="passwordConfirm" [ngClass]="{'is-invalid invalidFieldForm': validPasswordConfirm}"
              [ngStyle]="validPasswordConfirm && {'border-color': 'red','margin-bottom': '3px'}">
            <div class="invalidFieldForm" style="color: red;font-size: 13px;" *ngIf="validPasswordConfirm">
              La contraseña debe ser igual a la anterior
            </div>
          </div>
          <div class="mtb-20 text-center">
            <button (click)="onRegister()" type="submit" class="btn-primary">Enviar</button>
          </div>
        </form>
      </div>
    </div>

    <div class="card login-card" *ngIf="url.current == 'confirm'">
      <div>
        <div class="card-header">
          <div class="logo-wrap">
            <a href="">
              <img src="assets/images/logo.svg" alt="">
            </a>
          </div>
        </div>
        <div class="card-body pt-10 text-center">
          <div class="img-wrap">
            <img src="assets/images/icons/success.svg" alt="">
          </div>
          <div class="row justify-content-center">
            <div class="col-12">
              <h3>{{confirm?.title}}</h3>
              <p class="light-text" [innerHTML]="confirm?.message"></p>
            </div>
          </div>
          <div class="text-center mtb-20">
            <a (click)="onGoHome()"><button class="btn-primary">Ir a inicio</button></a>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
<abarcms-modal title="Código reenviado" *ngIf="showForwardCodeModal" (close)="showForwardCodeModal = false"
  [btnRight]="'Continuar'" (right)="showForwardCodeModal = false">
  <p [innerHTML]="data['generate'] | safeHtml"></p>
</abarcms-modal>
<abarcms-modal title="Contraseña cambiada" *ngIf="showPasswordChanged" (close)="onGoHome()" [btnRight]="'Ir a inicio'"
  (right)="onGoHome()">
  <p>{{data['changePasswordText']}}</p>
</abarcms-modal>