import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { CreateTemplateDataService } from 'src/app/services/data/create-template-data.service';
import { SessionService } from 'src/app/services/session.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertNotificationService } from 'src/app/services';
import { isValidFile } from 'src/assets/js/plugins/dropzone';

@Component({
  selector: 'app-create-template',
  templateUrl: './create-template.component.html',
  styleUrls: ['./create-template.component.css']
})
export class CreateTemplateComponent implements OnInit {

  createForm: FormGroup;
  selectedItems: string[];
  groupInfoGeneral: Array<CamposModuloMonitor> = [];
  groupEmpresa: Array<CamposModuloMonitor> = [];
  groupTrabajo: Array<CamposModuloMonitor> = [];
  todosJuntos: Array<any> = [];
  datosBack: Array<CreaPlantilla> = [];
  public crear: boolean;
  desabilitado: boolean = true;
  todosInfo: boolean = false;
  todosEmpresa: boolean = false;
  todosTrabajo: boolean = false;

  // datos de ejemplos
  datos: any[] = [
    { nombre: "Numero de empleado", checked: false, idcamposmodulomonitor: "1" },
    { nombre: "Nombre", checked: false, idcamposmodulomonitor: "2" },
    { nombre: "Sexo", checked: false, idcamposmodulomonitor: "3" },
  ]
  datosEmpresa: any[] = [
    { informacion: "Empresa en la que elaboras", checked: false, id: "1" },
    { informacion: "Área en la que elaboras", checked: false, id: "2" },
    { informacion: "Teléfono celular", checked: false, id: "3" },
  ]
  datosTrabajo: any[] = [
    { informacion: "Tu sitio de trabajo esta en", checked: false, id: "1" },
    { informacion: "Empresa", checked: false, id: "2" },
    { informacion: "Estado", checked: false, id: "3" },
  ]

  constructor(
    public createTemplateDataService: CreateTemplateDataService,
    private form: FormBuilder,
    private session: SessionService,
    private route: ActivatedRoute,
    private router: Router,
    private alertNotificationService: AlertNotificationService
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get('id') == null) {
      this.crear = true;
      this.getData();
    }
    else {
      this.crear = false;
      this.pedirInfoPlantillaAlBack();
      this.desabilitado = false;
    }

    this.selectedItems = new Array<string>();

  }


  buildForm() {
    this.createForm = this.form.group({
      namePlantilla: new FormControl('', [Validators.required]),
      groupInfoGeneral: new FormArray([]),
      groupEmpresa: new FormArray([]),
      groupTrabajo: new FormArray([])
    });
  }


  pedirInfoPlantillaAlBack() {
    this.createTemplateDataService.readById(this.route.snapshot.paramMap.get('id'), this.session.accessToken.accessToken)
      .subscribe(
        (rs) => {
          this.createForm.controls.namePlantilla.setValue(rs.body.plantillapermisomonitor.nombre);

          this.datosBack = rs.body.plantillapermisomonitor.modulosmonitor;

          let formArray: Array<CamposModuloMonitor> = [];
          this.todosInfo = true
          rs.body.plantillapermisomonitor.modulosmonitor[0].camposplantillapermisosmonitor.forEach((elemento) => {
            formArray.push({ "estatus": elemento.estatus, "idcamposmodulomonitor": elemento.idcampomodulomonitor, "alias": elemento.alias, "checked": elemento.estatus });
            if (elemento.estatus) {
              (<FormArray>this.createForm.get('groupInfoGeneral')).push(new FormControl({ "estatus": elemento.estatus, "idcamposmodulomonitor": elemento.idcampomodulomonitor, "alias": elemento.alias, "checked": elemento.estatus }));
            }
            if (!elemento.estatus) {
              this.todosInfo = false
            }
          });

          this.groupInfoGeneral = formArray;
          formArray = [];

          rs.body.plantillapermisomonitor.modulosmonitor[1].camposplantillapermisosmonitor.forEach((elemento) => {
            formArray.push({ "estatus": elemento.estatus, "idcamposmodulomonitor": elemento.idcampomodulomonitor, "alias": elemento.alias, "checked": elemento.estatus });
            if (elemento.estatus) {
              (<FormArray>this.createForm.get('groupEmpresa')).push(new FormControl({ "estatus": elemento.estatus, "idcamposmodulomonitor": elemento.idcampomodulomonitor, "alias": elemento.alias, "checked": elemento.estatus }));
            }
          });

          this.groupEmpresa = formArray;
          formArray = [];

          rs.body.plantillapermisomonitor.modulosmonitor[2].camposplantillapermisosmonitor.forEach((elemento) => {
            formArray.push({ "estatus": elemento.estatus, "idcamposmodulomonitor": elemento.idcampomodulomonitor, "alias": elemento.alias, "checked": elemento.estatus });
            if (elemento.estatus) {
              (<FormArray>this.createForm.get('groupTrabajo')).push(new FormControl({ "estatus": elemento.estatus, "idcamposmodulomonitor": elemento.idcampomodulomonitor, "alias": elemento.alias, "checked": elemento.estatus }));
            }
          });

          this.groupTrabajo = formArray;
          this.juntarGrupos();
        }
      );
  }

  getData(): void {
    this.createTemplateDataService.read(this.session.accessToken.accessToken)
      .subscribe(
        (rs) => {
          this.getInfoGeneral(rs);
          this.getEmpresa(rs);
          this.getTrabajo(rs);
          this.juntarGrupos();
          this.datosBack = rs.body.data;
        }
      );
  }

  getInfoGeneral(datos: any) {
    //Llamar al servidor para llenar la pestaña de Informacion General
    //this.groupInfoGeneral = this.datos;
    datos.body.data[0].camposmodulomonitor.map(x => {
      this.groupInfoGeneral.push({
        ...x,
        checked: false
      });
    })
  }

  getEmpresa(datos: any) {
    //Llamar al servidor para llenar la pestaña de Informacion de la Empresa
    //this.groupEmpresa = this.datosEmpresa;
    datos.body.data[1].camposmodulomonitor.map(x => {
      this.groupEmpresa.push({
        ...x,
        checked: false
      });
    })
  }

  getTrabajo(datos: any) {
    //Llamar al servidor para llenar la pestaña de Informacion de Trabajo
    //this.groupTrabajo = this.datosTrabajo;
    datos.body.data[2].camposmodulomonitor.map(x => {
      this.groupTrabajo.push({
        ...x,
        checked: false
      });
    })
  }

  juntarGrupos() {
    this.todosJuntos = [...this.groupInfoGeneral, ...this.groupEmpresa, ...this.groupTrabajo];
    //this.todosJuntos = rs.body.data;
  }

  getSelectedId(e: any, id: string) {
    if (e.target.checked) {
      this.selectedItems.push(id);
    }
    else {
      this.selectedItems = this.selectedItems.filter(m => m != id);
    }
  }

  getSelectedInfoGral(e: any, id: string) {
    let formArray: FormArray = this.createForm.get('groupInfoGeneral') as FormArray;
    let formArrayT: FormArray = this.createForm.get('groupTrabajo') as FormArray;
    let formArrayE: FormArray = this.createForm.get('groupEmpresa') as FormArray;

    if (e.target.checked) {
      formArray.push(new FormControl(this.todosJuntos[Number(id) - 1]));
    }
    else {
      formArray.controls.forEach((ctrl: FormControl, i) => {
        if (ctrl.value.idcamposmodulomonitor == id) {
          formArray.removeAt(i);
        }
      });
    }

    // if (this.groupInfoGeneral.length == formArray.length) {
    //   this.todosInfo = true
    // } else {
    //   this.todosInfo = false
    // }

    if ((formArrayT.length > 0) || (formArrayE.length > 0) || (formArray.length > 0))
      this.desabilitado = false;
    else
      this.desabilitado = true;

    if (this.createForm.value.namePlantilla == "") {
      this.desabilitado = true;
    }
  }

  keyFunc(e: any) {

    let formArray: FormArray = this.createForm.get('groupInfoGeneral') as FormArray;
    let formArrayT: FormArray = this.createForm.get('groupTrabajo') as FormArray;
    let formArrayE: FormArray = this.createForm.get('groupEmpresa') as FormArray;

    if ((formArrayT.length > 0) || (formArrayE.length > 0) || (formArray.length > 0))
      this.desabilitado = false;
    else
      this.desabilitado = true;

    if (this.createForm.value.namePlantilla == "")
      this.desabilitado = true;

  }


  getSelectedInfoEmpresa(e: any, id: string) {
    let formArrayE: FormArray = this.createForm.get('groupEmpresa') as FormArray;
    let formArrayT: FormArray = this.createForm.get('groupTrabajo') as FormArray;
    let formArrayI: FormArray = this.createForm.get('groupInfoGeneral') as FormArray;

    if (e.target.checked) {
      formArrayE.push(new FormControl(this.todosJuntos[Number(id) - 1]));
    }
    else {
      formArrayE.controls.forEach((ctrl: FormControl, i) => {
        if (ctrl.value.idcamposmodulomonitor == id) {
          formArrayE.removeAt(i);
        }
      });
    }
    // if (this.groupEmpresa.length == formArrayE.length)
    //   this.todosEmpresa = true
    // else
    //   this.todosEmpresa = false

    if ((formArrayT.length > 0) || (formArrayE.length > 0) || (formArrayI.length > 0))
      this.desabilitado = false;
    else
      this.desabilitado = true;

    if (this.createForm.value.namePlantilla == "")
      this.desabilitado = true;

  }


  getSelectedInfoTrabajo(e: any, id: string) {
    let formArrayT: FormArray = this.createForm.get('groupTrabajo') as FormArray;
    let formArrayE: FormArray = this.createForm.get('groupEmpresa') as FormArray;
    let formArrayI: FormArray = this.createForm.get('groupInfoGeneral') as FormArray;
    if (e.target.checked) {
      formArrayT.push(new FormControl(this.todosJuntos[Number(id) - 1]));
    }
    else {
      formArrayT.controls.forEach((ctrl: FormControl, i) => {
        if (ctrl.value.idcamposmodulomonitor == id) {
          formArrayT.removeAt(i);
        }
      });
    }
    // if (this.groupTrabajo.length == formArrayT.length)
    //   this.todosTrabajo = true
    // else
    //   this.todosTrabajo = false

    if ((formArrayT.length > 0) || (formArrayE.length > 0) || (formArrayI.length > 0))
      this.desabilitado = false;
    else
      this.desabilitado = true;

    if (this.createForm.value.namePlantilla == "")
      this.desabilitado = true;
  }


  /*selectedAllInfo(e:any){
    this.groupInfoGeneral.forEach(x=> x.checked = e.target.checked);
  }*/

  selectedAllInfo(e: any) {
    this.todosInfo = e.target.checked;
    let formArray: FormArray = this.createForm.get('groupInfoGeneral') as FormArray;
    let formArrayE: FormArray = this.createForm.get('groupEmpresa') as FormArray;
    let formArrayT: FormArray = this.createForm.get('groupTrabajo') as FormArray;

    /*if(this.groupInfoGeneral.length == formArray.length)
    {
      this.todosInfo = true
    }else{
      this.todosInfo = false
    }*/

    if (this.groupInfoGeneral.length > 0) {
      while (formArray.length !== 0) {
        formArray.removeAt(0);
      }
    }

    this.groupInfoGeneral.map((x, i) => {
      x.checked = !!e.target.checked
      formArray.push(new FormControl(x));
    })

    if (!e.target.checked) {
      while (formArray.length !== 0) {
        formArray.removeAt(0);
      }
    }

    // if ((formArrayT.length > 0) || (formArrayE.length > 0) || (formArray.length > 0))
    //   this.desabilitado = false;
    // else
    //   this.desabilitado = true;

    if (this.createForm.value.namePlantilla == "")
      this.desabilitado = true;
  }
  /*selectedAllEmpresa(e:any){
    this.datosEmpresa.forEach(x=> x.checked = e.target.checked);
  }*/


  selectedAllEmpresa(e: any) {
    let formArrayE: FormArray = this.createForm.get('groupEmpresa') as FormArray;
    let formArrayI: FormArray = this.createForm.get('groupInfoGeneral') as FormArray;
    let formArrayT: FormArray = this.createForm.get('groupTrabajo') as FormArray;

    if (this.groupEmpresa.length > 0) {
      while (formArrayE.length !== 0) {
        formArrayE.removeAt(0);
      }
    }

    for (var i = 0; i < this.groupEmpresa.length; i++) {
      this.groupEmpresa[i].checked = e.target.checked;
      formArrayE.push(new FormControl(this.groupEmpresa[i]));
    }

    if (!e.target.checked) {
      while (formArrayE.length !== 0) {
        formArrayE.removeAt(0);
      }
    }

    if ((formArrayT.length > 0) || (formArrayE.length > 0) || (formArrayI.length > 0))
      this.desabilitado = false;
    else
      this.desabilitado = true;

    if (this.createForm.value.namePlantilla == "")
      this.desabilitado = true;

  }

  /*selectedAllTrabajo(e:any){
    this.datosTrabajo.forEach(x=> x.checked = e.target.checked);
  }*/


  selectedAllTrabajo(e: any) {
    let formArrayT: FormArray = this.createForm.get('groupTrabajo') as FormArray;
    let formArrayI: FormArray = this.createForm.get('groupInfoGeneral') as FormArray;
    let formArrayE: FormArray = this.createForm.get('groupEmpresa') as FormArray;

    if (this.groupTrabajo.length > 0) {
      while (formArrayT.length !== 0) {
        formArrayT.removeAt(0);
      }
    }

    for (var i = 0; i < this.groupTrabajo.length; i++) {
      this.groupTrabajo[i].checked = e.target.checked;
      formArrayT.push(new FormControl(this.groupTrabajo[i]));
    }

    if (!e.target.checked) {
      while (formArrayT.length !== 0) {
        formArrayT.removeAt(0);
      }
    }

    if ((formArrayT.length > 0) || (formArrayE.length > 0) || (formArrayI.length > 0))
      this.desabilitado = false;
    else
      this.desabilitado = true;

    if (this.createForm.value.namePlantilla == "")
      this.desabilitado = true;

  }

  saveData() {
    let datosAlBack: Array<ModulosMonitor> = [];
    let camposCategorias: Array<CamposPlantillaPermisosMonitor> = [];

    let infoGralArrayBack;
    let infoEmpresaArrayBack;
    let infoSitioArrayBack;

    if (this.route.snapshot.paramMap.get('id') == null) {

      infoGralArrayBack = this.datosBack[0].camposmodulomonitor;
      infoEmpresaArrayBack = this.datosBack[1].camposmodulomonitor;
      infoSitioArrayBack = this.datosBack[2].camposmodulomonitor;
    } else {
      infoGralArrayBack = this.datosBack[0].camposplantillapermisosmonitor;
      infoEmpresaArrayBack = this.datosBack[1].camposplantillapermisosmonitor;
      infoSitioArrayBack = this.datosBack[2].camposplantillapermisosmonitor;

    }

    let infoGralArrayUser = (<FormArray>this.createForm.get('groupInfoGeneral'));


    let infoEmpresaArrayUser = (<FormArray>this.createForm.get('groupEmpresa'));


    let infoSitioArrayUser = (<FormArray>this.createForm.get('groupTrabajo'));

    for (let i = 0; i < this.datosBack.length; i++) {
      datosAlBack.push({ "idmodulomonitor": this.datosBack[i].idmodulomonitor, "estatus": true })
    }


    for (let i = 0; i < infoGralArrayBack.length; i++) {
      this.todosJuntos[i].estatus = false;
      camposCategorias.push({ "idcampomodulomonitor": this.todosJuntos[i].idcamposmodulomonitor, "estatus": false })
      for (let j = 0; j < infoGralArrayUser.length; j++) {
        if (infoGralArrayUser.at(j).value.idcamposmodulomonitor == this.todosJuntos[i].idcamposmodulomonitor) {
          this.todosJuntos[infoGralArrayUser.at(j).value.idcamposmodulomonitor - 1].estatus = true;
          camposCategorias.forEach((element, index) => {
            if (element.idcampomodulomonitor == this.todosJuntos[infoGralArrayUser.at(j).value.idcamposmodulomonitor - 1].idcamposmodulomonitor)
              camposCategorias.splice(index, 1);
          });
          camposCategorias.push({ "idcampomodulomonitor": this.todosJuntos[infoGralArrayUser.at(j).value.idcamposmodulomonitor - 1].idcamposmodulomonitor, "estatus": true })
        }

      }

    }

    datosAlBack[0] = { "idmodulomonitor": datosAlBack[0].idmodulomonitor, "estatus": datosAlBack[0].estatus, "camposplantillapermisosmonitor": camposCategorias };

    camposCategorias = [];

    for (let i = infoGralArrayBack.length; i < this.todosJuntos.length - 1; i++) {
      this.todosJuntos[i].estatus = false;
      camposCategorias.push({ "idcampomodulomonitor": this.todosJuntos[i].idcamposmodulomonitor, "estatus": false })
      for (let j = 0; j < infoEmpresaArrayUser.length; j++) {
        if (infoEmpresaArrayUser.at(j).value.idcamposmodulomonitor == this.todosJuntos[i].idcamposmodulomonitor) {
          this.todosJuntos[infoEmpresaArrayUser.at(j).value.idcamposmodulomonitor - 1].estatus = true;
          camposCategorias.forEach((element, index) => {
            if (element.idcampomodulomonitor == this.todosJuntos[infoEmpresaArrayUser.at(j).value.idcamposmodulomonitor - 1].idcamposmodulomonitor)
              camposCategorias.splice(index, 1);
          });
          camposCategorias.push({ "idcampomodulomonitor": this.todosJuntos[infoEmpresaArrayUser.at(j).value.idcamposmodulomonitor - 1].idcamposmodulomonitor, "estatus": true })
        }

      }

    }

    datosAlBack[1] = { "idmodulomonitor": datosAlBack[1].idmodulomonitor, "estatus": datosAlBack[1].estatus, "camposplantillapermisosmonitor": camposCategorias };

    camposCategorias = [];

    for (let i = infoEmpresaArrayBack.length + infoGralArrayBack.length; i < this.todosJuntos.length; i++) {
      this.todosJuntos[i].estatus = false;
      camposCategorias.push({ "idcampomodulomonitor": this.todosJuntos[i].idcamposmodulomonitor, "estatus": false })
      for (let j = 0; j < infoSitioArrayUser.length; j++) {
        if (infoSitioArrayUser.at(j).value.idcamposmodulomonitor == this.todosJuntos[i].idcamposmodulomonitor) {
          this.todosJuntos[infoSitioArrayUser.at(j).value.idcamposmodulomonitor - 1].estatus = true;
          camposCategorias.forEach((element, index) => {
            if (element.idcampomodulomonitor == this.todosJuntos[infoSitioArrayUser.at(j).value.idcamposmodulomonitor - 1].idcamposmodulomonitor)
              camposCategorias.splice(index, 1);
          });
          camposCategorias.push({ "idcampomodulomonitor": this.todosJuntos[infoSitioArrayUser.at(j).value.idcamposmodulomonitor - 1].idcamposmodulomonitor, "estatus": true })
        }

      }

    }

    datosAlBack[2] = { "idmodulomonitor": datosAlBack[2].idmodulomonitor, "estatus": datosAlBack[2].estatus, "camposplantillapermisosmonitor": camposCategorias };

    let body: CrePlantillaDos = {
      "nombre": this.createForm.value.namePlantilla,
      "estatus": true,
      "modulosmonitor": datosAlBack
    };

    if (this.route.snapshot.paramMap.get('id') == null) {
      this.createTemplateDataService.create(body, this.session.accessToken.accessToken)
        .subscribe(
          (rs) => {
            this.alertNotificationService.success('Plantilla', 'Se creo la plantilla correctamente', { autoClose: true, keepAfterRouteChange: true });
            this.router.navigate(['groups-business/list-template']);
          }, (error) => {
            this.alertNotificationService.error('Plantilla', 'Ocurrio un error, creando la plantilla', { autoClose: true, keepAfterRouteChange: true })
          }
        );
    } else {
      this.createTemplateDataService.update(this.route.snapshot.paramMap.get('id'), body, this.session.accessToken.accessToken)
        .subscribe(
          (rs) => {
            this.alertNotificationService.success('Plantilla', 'Se actualizó la plantilla correctamente', { autoClose: true, keepAfterRouteChange: true });
            this.router.navigate(['groups-business/list-template']);
          }, (error) => {
            this.alertNotificationService.error('Plantilla', 'Ocurrio un error, actualizando la plantilla', { autoClose: true, keepAfterRouteChange: true })
          }
        );
    }
  }

  cancel() {
    this.router.navigate(['groups-business/list-template']);
  }

  checkItem(key, item?: any): boolean {
    let formArray: FormArray
    switch (key) {
      case 'info':
        formArray = this.createForm.get('groupInfoGeneral') as FormArray;
        if (item) {
          let data = formArray.value.find(x => x.idcamposmodulomonitor == item.idcamposmodulomonitor)
          if (data) {
            return data.checked
          }
          else {
            return false
          }
        }
        else {
          return this.groupInfoGeneral.length == formArray.length
        }
      case 'empresa':
        formArray = this.createForm.get('groupEmpresa') as FormArray;
        if (item) {
          let data = formArray.value.find(x => x.idcamposmodulomonitor == item.idcamposmodulomonitor)
          if (data) {
            return data.checked
          }
          else {
            return false
          }
        }
        else {
          return this.groupEmpresa.length == formArray.length
        }
      case 'trabajo':
        formArray = this.createForm.get('groupTrabajo') as FormArray;
        if (item) {
          let data = formArray.value.find(x => x.idcamposmodulomonitor == item.idcamposmodulomonitor)
          if (data) {
            return data.checked
          }
          else {
            return false
          }
        }
        else {
          return this.groupTrabajo.length == formArray.length
        }
    }
  }

  isValidName(): Boolean {
    return this.createForm.get('namePlantilla').value.trim().length > 0
  }
}

//json de informacion
export interface InfoGeneral {
  informacion: string,
  checked: boolean,
  id: string
}

//json de Empresa
export interface Empresa {
  informacion: string,
  checked: boolean,
  id: string
}

//json de Trabajo
export interface Trabajo {
  informacion: string,
  checked: boolean,
  id: string
}


export interface CreaPlantilla {
  camposmodulomonitor: Array<CamposModuloMonitor>,
  estatus: boolean,
  idmodulomonitor: number,
  alias: string,
  camposplantillapermisosmonitor: Array<CamposModuloMonitor>
}


export interface CamposModuloMonitor {
  estatus: boolean,
  idcamposmodulomonitor: number,
  alias: string,
  checked: boolean
}

export interface CrePlantillaDos {
  nombre: string,
  modulosmonitor: Array<ModulosMonitor>,
  estatus: boolean

}

export interface ModulosMonitor {
  idmodulomonitor: number,
  camposplantillapermisosmonitor?: Array<CamposPlantillaPermisosMonitor>,
  estatus?: boolean
}

export interface CamposPlantillaPermisosMonitor {
  idcampomodulomonitor: number,
  estatus: boolean
}