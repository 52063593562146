
  <h2>Centro de contenidos</h2>
  <div class="wrap" *ngIf="data">
    <div class="row">
      <div class="col-4 chart-item" *ngFor="let d of data">
        <h4>{{d[1]}}</h4>
        <div class="chart-wrap">
          <canvas id="myChart" width="90" height="90"></canvas>
          <div class="chart-info">
            <p>{{d[2]}}</p>
            <span class="light-text">Uso</span>
          </div>
        </div>
      </div>
    </div>
  </div>