import { v1 as uuidv1 } from 'uuid'

export class Configuration {
    public uuid: string;
    public active: number;
    public text: string;
    public logo: string;
    public image: string;

    constructor(data?: any) {
        this.uuid = data.uuid || uuidv1();
        if (data.active != undefined || data.active != null) {
            this.active = data.active
        }
        else {
            this.active = 1
        }
        this.text = data.text || '';
        this.logo = data.logo || '';
        this.image = data.image || '';
    }
}