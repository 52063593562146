import { Injectable } from '@angular/core';
import { SettingsService } from '../../settings.service';
import { ApiService } from '../../api.service';
import { Observable } from 'rxjs';
import { ResponseData } from 'src/app/dto';

@Injectable({
  providedIn: 'root'
})
export class ContentTypeFieldDataWsService {
  private pathInfo: any;
  private baseUrl: string;
  private segment: any;
  constructor(
    private settings: SettingsService,
    private api: ApiService
  ) {
    this.pathInfo = this.settings.get('pathInfo');
    this.baseUrl = this.pathInfo.base.baseUrl + this.pathInfo.base.prefix;
    this.segment = this.pathInfo.res.admin['contentTypeField'];
  }  
  private read(uuid: any): Observable<ResponseData> {
    let endpoint = this.baseUrl +
      this.segment.prefix.replace(':uuid', uuid);
    return this.api.get(endpoint);
  }
  private create(uuid: any, body: object): Observable<ResponseData> {
    let endpoint = this.baseUrl +
      this.segment.prefix.replace(':uuid', uuid);
    return this.api.post(endpoint, body);
  }
  private readOne(uuid: any, id: any): Observable<ResponseData> {
    let endpoint = this.baseUrl +
      this.segment.prefix.replace(':uuid', uuid) +
      this.segment.resources.readOne.replace(':id', id);
    return this.api.get(endpoint);
  }
  private update(uuid: any, id: any, body: object): Observable<ResponseData> {
    let endpoint = this.baseUrl +
      this.segment.prefix.replace(':uuid', uuid) +
      this.segment.resources.update.replace(':id', id);
    return this.api.put(endpoint, body);
  }
  private delete(uuid: any, id: any): Observable<ResponseData> {
    let endpoint = this.baseUrl +
      this.segment.prefix.replace(':uuid', uuid) +
      this.segment.resources.delete.replace(':id', id);
    return this.api.delete(endpoint, uuid);
  }
}
