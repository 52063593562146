import {
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { DetailsDialogComponent } from "../details-dialog/details-dialog.component";
import { constantes } from "../../constants/seccion-monitor";
import { SeccionMonitorService } from "../../services/seccion-monitor.service";

@Component({
  selector: "app-view-pagina-results",
  templateUrl: "./view-pagina-results.component.html",
  styleUrls: ["./view-pagina-results.component.css"],
})
export class ViewPaginaResultsComponent implements OnInit {
  gruposVisualizacion: any = [];
  grupos: any = [];
  viewResults: any = [];
  pageSize = 10; //<-------------------------------------
  i: number = 1; //         --
  desde: number = 0; //      --variables para el paginador
  hasta: number = 10; //      --
  total_rows: number = 0; //<-----------------------------------
  @Input() inputSearchGroup!: string;
  @Input() typeGroupChecked!: number;
  @Input() inputTextTypeGroup!: string;
  @Input() tipoGrupoSelect!: number;

  grupoAsigancion: number;
  idGV: number;
  constructor(
    private seccionesMonitorService: SeccionMonitorService,
    public dialog: MatDialog
  ) {
    console.log("Contructor Grupos de visualización");
  }

  async ngOnInit(): Promise<void> {
    let data = {
      pageSize: constantes.pagination.pageSize,
      page: 1,
      sortDirection: "asc",
      sort: "dg.name",
      filters: {
        name: constantes.groupVisualization.nameGV,
        displayGroupTypeId: [constantes.ids.idCatGV],
      },
    };
    if (this.inputTextTypeGroup != undefined && this.inputTextTypeGroup != "") {
      data.filters.displayGroupTypeId = [];
    }
    (
      await this.seccionesMonitorService.getAllGrupoVisualizacion(data)
    ).subscribe((result) => {
      this.gruposVisualizacion = result.data.displayGroup;
      this.total_rows = result.data.pagination.total_rows;
    });
    this.tipoGrupoSelect = constantes.ids.idGV;
    console.log("init " + this.tipoGrupoSelect);
  }

  openDetailsDialog(id: number): void {
    const dialogRef = this.dialog.open(DetailsDialogComponent, {
      data: { id: id },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
    });
  }

  changeUsersPage(e: PageEvent) {
    this.desde = e.pageIndex * e.pageSize;
    this.hasta = this.desde + e.pageSize;
  }

  addGroupI(checked: boolean): void {
    //constantes.jSONs.groupsSelected=this.grupos
  }

  asignaRadio(checked: boolean): void {
    constantes.ids.idGrupoVisual = this.tipoGrupoSelect;
  }
}
