<div class="container-fluid">
    <div class="card border-card">
        <div class="card-content">

            <!--Cabecera-->
            <div class="row mb-20">
                <div class="card__title col-xs-12 col-md-4 col-lg-4">
                    <h2 class="title-list">Listado de roles y permisos</h2>
                </div>
                
                <div class="col-xs-12 col-md-4 col-lg-3" *ngIf="permissions?.create">
                    <button  type="button" class="btn btn-success" (click)="actionItem('create')"
                    *ngIf="permissions.create">
                        <span class="fas fa-plus" aria-hidden="true"></span> Crear rol/permiso
                    </button>
                    
                </div>
                <div class="col-12 col-xl-4">
                    <!-- <abarcms-input-search [items]="roles" [fieldsToSearch]="['name']" (dataFound)="dataFound($event)"
                        [placeholder]="'Buscar...'">
                    </abarcms-input-search> -->
                </div>
            </div>


            <div class="mt-30">
                <div id="contenido-table_wrapper" class="dataTables_wrapper no-footer">
                    <table style="width:100%" class="table">
                        <thead>
                            <tr>
                                <th>NOMBRE DEL ROL</th>
                                <!-- <th>DESCRIPCIÓN</th> -->
                                <!-- <th>Fecha de creación</th> -->
                                <th>ACCIONES</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of rolesPage">
                                <td>{{item.name}}</td>
                                <!-- <td>{{item.description}}</td> -->
                                <!-- <td>DateTime</td> -->
                                <td>
                                    <div class="btn-group btn-group-sm" role="group" aria-label="Rol action">
                                        <ng-container *ngIf="!permissions?.edit">
                                            <button type="button" class="btn btn-warning" *ngIf="permissions?.read"
                                                (click)="actionItem('read', item)">
                                                <i class="far fa-eye"
                                                    style="position: relative; left: -2px; top: 2px;"></i>
                                            </button>
                                        </ng-container>
                                        <button type="button" class="btn btn-primary" (click)="actionItem('edit',item)"
                                            *ngIf="permissions.edit">
                                            <i class="fas fa-pencil-alt"></i>
                                        </button>
                                        <ng-container>
                                            <button type="button" class="btn btn-danger" *ngIf="permissions.delete"
                                                (click)="actionItem('delete',item)">
                                                <i class="fas fa-trash-alt"></i>
                                            </button>
                                        </ng-container>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-12 mt-4">
                <abarcms-paginator [total]="paginator.total" [initialPage]="paginator.current"
                    [perPage]="paginator.perPage" [maxPages]="5" [lblFirst]="'Primera'" [lblLast]="'Última'"
                    [lblPrev]="'Anterior'" [lblNext]="'Siguiente'" [btnSec]="true" [btnPri]="true"
                    (changePage)="changePage($event)" [align]="'center'"></abarcms-paginator>
            </div>
            <p *ngIf="rolesPage.length == undefined" class="alert alert-warning text-center">No se encontraron datos</p>
        </div>
    </div>
</div>