import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseData } from 'src/app/dto/ResponseData';
import { Media } from 'src/app/dto/Media';

@Injectable({
  providedIn: 'root'
})
export abstract class MediaDataService {
  abstract read(accessToken?: string): Observable<ResponseData>;
  abstract readOne(media_id: any): Observable<ResponseData>;
  abstract create(body: Media, accessToken?: string): Observable<ResponseData>;
  abstract update(media_id: any, body: object, accessToken?: string): Observable<ResponseData>;
  abstract delete(media_id: any, accessToken?: string): Observable<ResponseData>;
}
