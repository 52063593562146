<abarcms-loader-full *ngIf="loading"></abarcms-loader-full>
<div class="container-fluid">
    <div class="card border-card">
        <div class="card-content">
            <!--Cabecera-->
            <div class="row mb-10">
                <div class="card__title col-xs-12 col-md-6 col-lg-6">
                    <h2 class="title-list">Listado de empresas</h2>
                </div>
                <div class="col-12 col-lg-6 col-md-6">
                    <div class="form-group mb-0">
                        <input type="text" class="form-control input-search mb-0" [(ngModel)]="searchText"
                            (change)="onSearchChange($event)" placeholder="Buscar...">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-md-4 col-lg-4 mb-1">
                    <div class="botonera">
                        <button class="btn btn-dark btn-block m-0" routerLink="/groups-business">Regresar</button>
                        <button type="button" class="btn btn-success" (click)="createEmpresa()">
                            <span class="fas fa-plus" aria-hidden="true"></span> Crear Empresa
                        </button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <!--Cuerpo-->
                    <div class="mt-30">
                        <div id="contenido-table_wrapper" class="dataTables_wrapper no-footer mb-3">
                            <table class="table table-striped" style="width:100%">
                                <!--Cabecera de tabla-->
                                <thead>
                                    <tr>
                                        <th width="50%">Nombre Empresas</th>
                                        <th width="10%">Estado</th>
                                        <th width="10%">Creado</th>
                                        <th width="20%">Acciones</th>
                                    </tr>
                                </thead>
                                <!--Cuerpo de tabla-->
                                <tbody>
                                    <tr *ngFor="let company of companys; let i = index">
                                        <td> {{company.name}}</td>
                                        <td> {{company.status | estatus}} </td>
                                        <td> {{company.fecha_creacion | date:'dd/MM/yyyy'}} </td>
                                        <td>
                                            <div class="btn-group btn-group-sm" role="group" aria-label="Action">
                                                <div class="containerActivate" >
                                                    <button type="button" [disabled]="!company.status"
                                                    [class]="!company.status ? 'btn btn-secondary buttonPencil disbledEdit':'btn btn-primary buttonPencil'"
                                                        (click)="editEmpresa(company)">
                                                        <i class="fas fa-pencil-alt"></i>
                                                    </button>
                                                </div>
                                                <ng-container>
                                                    <div class="containerActivate" *ngIf="company.status">
                                                    <button  class="btn btn-success buttonActivate" (click)="activarInactivarEmpresa(company)">
                                                        <i class="fas fa-toggle-on" title="Desactivar"></i>
                                                    </button>
                                                    </div>
                                                    <div class="containerActivate" *ngIf="!company.status">
                                                    <button  class="btn btn-secondary buttonActivate" (click)="activarInactivarEmpresa(company)">
                                                        <i class="fas fa-toggle-off" title="Activar"></i>
                                                    </button>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-12 mt-4">
                            <abarcms-paginator [total]="paginator.total" [initialPage]="paginator.current"
                                [perPage]="paginator.perPage" [maxPages]="5" [lblFirst]="'Primera'" [lblLast]="'Última'"
                                [lblPrev]="'Anterior'" [lblNext]="'Siguiente'" [btnSec]="true" [btnPri]="true"
                                (changePage)="changePage($event)" [align]="'center'"></abarcms-paginator>
                        </div>
                        <p *ngIf="!companys.length" class="alert alert-warning text-center">No se encontraron datos</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>