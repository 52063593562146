export class SimpleContent {    
    id: number;
    title: string;
    alias: string;
    description: string;
    position: number;
    status: number;

    constructor(
        opt: {
            id?: any,
            title?: string,
            alias?: string,
            description?: string,
            position?: number,
            status?: number,
        } = {}
    ){
        this.id = opt.id || 0;
        this.title = opt.title || '';
        this.alias = opt.alias || '';
        this.description = opt.description || '';
        this.position = opt.position || 0;
        this.status = opt.status || 1;
    }
}