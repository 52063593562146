import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { SettingsService } from 'src/app/services/settings.service';
import { CodeVerify } from 'src/app/dto/CodeVerify';
import { ResponseData } from 'src/app/dto/ResponseData';
import { createResponseData, makeHeaders } from 'src/app/utils/_http';
import { ModalHelperService } from '../../modal-helper.service';
import { ApiService } from '../../api.service';

@Injectable({
  providedIn: 'root'
})
export class CodeVerifyDataWsService {
  private pathInfo: any;
  private baseUrl: string;
  private segment: any;
  constructor(
    private settings: SettingsService,
    private api: ApiService
  ) {
    this.pathInfo = this.settings.get('pathInfo');
    this.baseUrl = this.pathInfo.base.baseUrl + this.pathInfo.base.prefix;
    this.segment = this.pathInfo.res.public['verificationCode'];
  }
  getCodeVerify(body: CodeVerify): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.validate;
    return this.api.post(endpoint, body, false);
  }
  forwardCode(body: CodeVerify): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.generate;
    return this.api.post(endpoint, body, false);
  }
}
