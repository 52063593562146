import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { constants } from "src/app/constants";
import { Group } from "src/app/dto/group.dto";
import { SettingsService, AlertNotificationService } from "src/app/services";
import { GroupDataService } from "src/app/services/data/group-data.service";
import { GruposService } from "src/app/screens/admin/seccion-monitor/services/groups.service";

import { ModalHelperService } from "src/app/services/modal-helper.service";
import { SessionService } from "src/app/services/session.service";
import { labels } from "src/app/strings";
import { getValid } from "src/app/utils/_http";
import { getPermissionsPage } from "src/app/utils/_utils";
import { ConfirmDeleteComponent } from "src/app/views/confirm-delete/confirm-delete.component";

@Component({
  selector: "app-group-list",
  templateUrl: "./group-list.component.html",
  styleUrls: ["./group-list.component.css"],
})
export class GroupListComponent implements OnInit {
  groups: Array<Group> = [];
  loading: boolean = false;
  permissions: any;
  constants = constants;
  searchText: string = "";
  paginator: {
    current: number;
    perPage: number;
    total: number;
  } = {
    current: 1,
    perPage: 10,
    total: 0,
  };
  init: boolean = true;

  constructor(
    private modalSrv: NgbModal,
    private gruposService: GruposService,
    protected session: SessionService,
    private modalHelper: ModalHelperService,
    public settings: SettingsService,
    private router: Router,
    protected alertNotificationService: AlertNotificationService
  ) {
    this.getTableData();
  }

  ngOnInit(): void {
    this.permissions = getPermissionsPage(this.router.url);
  }

  editItem(item) {
    this.router.navigate([
      constants.screens.groups.path,
      item.groupId,
      constants.screens.groups["edit"],
    ]);
  }
  actionItem(actionName: string, item?) {
    if (actionName === "listCompany") {
      this.router.navigate([
        constants.screens.groups.path,
        item.groupId,
        constants.screens.groups[actionName],
      ]);
    } else {
      this.router.navigate([
        constants.screens.groups.path,
        constants.screens.groups[actionName],
      ]);
    }
  }
  async getTableData(): Promise<void> {
    let body = {
      pageSize: this.paginator.perPage,
      page: this.paginator.current,
      sortDirection: "asc",
      sort: "g.groupId",
      filters: {
        name: this.searchText,
      },
    };
    (await this.gruposService.allGrups(body)).subscribe((_rs) => {
      if (
        getValid(_rs, {
          title: `Error al obtener los grupos`,
          modalHelper: this.modalHelper,
          settings: this.settings,
        })
      ) {
        this.paginator.total = _rs.body.data.pagination.total_rows;
        this.groups = _rs.body.data.groups;
      }
    });
  }

  openBusiness(business) {
    this.router.navigate([
      "groups-business",
      business.data.uuidgroup,
      "business",
      business.data.uuid,
      this.permissions?.edit ? "edit" : "read",
    ]);
  }

  statusGroup(item: any): void {
    let label = !item.status
      ? labels.groups.delete.confirmMessageActive
      : labels.groups.delete.confirmMessageInactive;
    if (label == undefined)
      label = !item.status
        ? "¿Estás seguro que quieres Activar este <b>Grupo</b>?."
        : "¿Estás seguro que quieres Inactivar este <b>Grupo</b>?.";
    let deleteModal = this.modalSrv.open(ConfirmDeleteComponent);
    deleteModal.componentInstance.title = "Cambiar estatus de grupo";
    deleteModal.componentInstance.message = label;
    deleteModal.result.then(async (_response) => {
      if (_response === "CONFIRMED") {
        this.loading = true;
        let body = {
          status: !item.status,
        };
        (await this.gruposService.statusGroup(body, item.groupId)).subscribe(
          (_res) => {
            this.loading = false;
            if (
              getValid(_res, {
                title: `Error al cambiar el estatus del grupo`,
                modalHelper: this.modalHelper,
                settings: this.settings,
              })
            ) {
              this.getTableData();
            }
          },
          (error) => {
            this.loading = false;
            this.erroresServicios(error);
            this.getTableData();
          }
        );
      }
    });
  }

  onSearchChange(event: any) {
    this.paginator.current = 1;
    this.getTableData();
  }

  changePage($event: any) {
    this.paginator = $event;
    if (this.init) {
      this.init = false;
    } else {
      this.getTableData();
    }
  }

  erroresServicios(error) {
    if (
      error.error.mensajeError != undefined &&
      error.error.mensajeError != null &&
      error.error.mensajeError != ""
    ) {
      this.alertNotificationService.error("Error", error.error.mensajeError, {
        autoClose: true,
        keepAfterRouteChange: true,
      });
    } else if (error.error.listMensajeError) {
      error.error.listMensajeError.forEach((element) => {
        this.alertNotificationService.error("Error", element.mensajeError, {
          autoClose: true,
          keepAfterRouteChange: true,
        });
      });
    } else {
      this.alertNotificationService.error(
        "Error",
        "Ocurrio un error en el servidor.",
        { autoClose: true, keepAfterRouteChange: true }
      );
    }
    this.loading = false;
  }
}
