import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'slugify'})
export class SlugifyPipe implements PipeTransform {
  transform(input: string): string {
    if (input !== undefined && input !== null) {
      input = input.replace(/^\s+|\s+$/g, '-'); // trim
      input = input.toLowerCase();
      // remove accents, swap ñ for n, etc
      const from = 'àáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
      const to   = 'aaaaaeeeeiiiioooouuuunc------';
  
      for (let i = 0, l = from.length ; i < l ; i++) {
          input = input.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }
      
      input = input.replace(/[^a-z0-9\-\s]/g, '-') // remove invalid chars
          .replace(/\s+/g, '-') // collapse whitespace and replace by -
          .replace(/-+/g, '-'); // collapse dashes
    }
    return input;
  }
}
