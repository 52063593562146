import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PaginaDataService } from "src/app/services/data/pagina-data.service";
import { SettingsService } from "src/app/services/settings.service";
import { CrearPaginaService } from "../../services/crear-pagina.service";
import { VerPaginaService } from "../../services/ver-pagina.service";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { constantes } from "../../constants/seccion-monitor";

@Component({
  selector: "app-crear-pagina",
  templateUrl: "./crear-pagina.component.html",
  styleUrls: ["./crear-pagina.component.css"],
})
export class CrearPaginaComponent implements OnInit {
  loading: boolean;
  title: string;
  origin: string = constantes.origin;
  public previewImage: string | undefined;
  public base64Image: string | undefined = "";
  nombre: string = "";
  descripcion: string = "";
  icono: string = "";
  url: string = "";
  token: string = "";
  parametros: string = "";
  estatus: string = "1";
  isWebInternal: boolean = false;
  nombreActividad: string = "";
  interna: boolean = false;
  displayGroupId: string = "";
  displayGroupTypeId: string = "";
  esValida: boolean = true;
  nombreObligatorio: boolean = false;
  urlObligatoria: boolean = false;
  descripcionObligatorio: boolean = false;
  tokenObligatorio: boolean = false;
  parametrosObligatorios: boolean = false;
  base64ImageObligatoria: boolean = false;
  errores: boolean = false;
  flujoSimple: boolean = false;
  flujoComplejo: boolean = true;
  idPagina: any = 0;
  pageSize = 3;
  i: number = 1;
  desde: number = 0;
  hasta: number = 3;
  length: number = 0;
  catGruposSelected: number;
  idUsuario: string;
  dto: string;
  paginas: any = [];
  buscaTxt: string;
  isUpdate: boolean = false;
  idPaginaInsert: number;
  position: number;

  constructor(
    public settings: SettingsService,
    public paginaDataService: PaginaDataService,
    private crearPaginaService: CrearPaginaService,
    private activeRouter: ActivatedRoute,
    private verPaginaService: VerPaginaService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.title = constantes.title;
    this.idPagina = parseInt(
      "" + this.activeRouter.snapshot.paramMap.get("id")
    );

    if (
      this.idPagina !== null &&
      this.idPagina !== undefined &&
      !isNaN(this.idPagina)
    ) {
      this.getOnePage();
      this.isUpdate = true;
      this.flujoSimple = false;
      this.flujoComplejo = false;
    } else {
      this.isUpdate = false;
      this.flujoSimple = false;
      this.flujoComplejo = true;
      this.position = 1;
    }
  }

  public onFileSelected(event: Event): void {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file.type == "image/png") {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.previewImage = reader.result as string;
          this.base64Image = reader.result as string;
        };
      }
    } else {
      alert("Formato no permitido");
    }
  }

  validarURL(miurl) {
    try {
      new URL(miurl);
      this.esValida = true;
      return true;
    } catch (err) {
      this.esValida = false;
      return false;
    }
  }

  limpiaParametro() {
    let txt = this.parametros.trim();
    txt = txt.replace(/[\t]+/g, " "); //remplaza los tabuladores
    txt = txt.replace(/[\n]+/g, " "); //remplaza los saltos de linea
    this.parametros = txt.replace(/ +/gm, " "); //remplaza los espacios
  }

  async guardar() {
    this.valida();

    if (!this.errores) {
      // Convertir el objeto a JSON
      const paginaJSON = JSON.stringify(this.obtenJson());
      console.log(paginaJSON);
      (await this.crearPaginaService.setCreatePage(paginaJSON)).subscribe(
        (result) => {
          console.log("Se guardo correctamente");
          this.irAVerPagina();
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  async updatePage(tipoUpdate: number) {
    this.valida();
    if (!this.errores) {
      this.loading = true;
      // Convertir el objeto a JSON
      const paginaJSON = JSON.stringify(this.obtenJson());
      console.log(paginaJSON);
      (
        await this.crearPaginaService.setUpdatePage(paginaJSON, this.idPagina)
      ).subscribe(
        (result) => {
          console.log("Se Actualizo correctamente");
          this.loading = false;
          if (tipoUpdate === 1) this.irAVerPagina();
          else if (tipoUpdate === 2) {
            sessionStorage.setItem("idPaginaInsert", this.idPagina.toString());
            sessionStorage.setItem("namePaginaInsert", this.nombre.toString());
            this.irAPaginaAsignar();
          }
        },
        (error) => {
          this.loading = false;
          console.log(error);
          //this.openConfirmDialog(2);
        }
      );
    }
  }

  async guardarYSiguiente(tipoGuardar: number) {
    this.valida();
    if (!this.errores) {
      this.loading = true;
      const paginaJSON = JSON.stringify(this.obtenJson());
      (await this.crearPaginaService.setCreatePage(paginaJSON)).subscribe(
        (result) => {
          this.loading = false;
          this.idPaginaInsert = result.body.data.pageId;
          constantes.paginas.pageId = result.body.data.pageId;
          console.log("Se guardo correctamente");
          sessionStorage.setItem("namePaginaInsert", this.nombre.toString());
          if (tipoGuardar === 1) this.irAPaginaAsignar();
          else if (tipoGuardar === 2) this.irAVerPagina();
        },
        (error) => {
          this.loading = false;
          console.log(error);
          /*if(error && error.error && error.error.error){
            error.error.error.forEach(element => {
              const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                data: {origen:'dialogConfirm', titulo:'ERROR', mensaje:element.error},
              });

              console.log(element.error);
            });
          }*/
          //this.openConfirmDialog(1);
        }
      );
    }
  }

  valida() {
    let errorValidaURL: boolean;

    if (this.nombre.trim() === "") {
      this.nombreObligatorio = true;
    } else this.nombreObligatorio = false;

    if (this.url.trim() === "") {
      this.urlObligatoria = true;
    } else {
      this.urlObligatoria = false;
    }

    if (this.base64Image.trim() === "") {
      this.base64ImageObligatoria = true;
    } else {
      this.base64ImageObligatoria = false;
    }

    if (this.descripcion.trim() === "") {
      this.descripcionObligatorio = true;
    } else {
      this.descripcionObligatorio = false;
    }
    if (!this.isWebInternal) {
      if (this.token.trim() === "") {
        this.tokenObligatorio = true;
      } else {
        this.tokenObligatorio = false;
      }
      errorValidaURL = !this.validarURL(this.url);
      this.esValida = this.validarURL(this.url);
    } else {
      this.esValida = true;
      this.tokenObligatorio = false;
    }
    if (this.parametros.trim() === "") this.parametrosObligatorios = true;
    else this.parametrosObligatorios = false;

    if (
      this.nombreObligatorio ||
      errorValidaURL ||
      this.urlObligatoria ||
      this.descripcionObligatorio ||
      this.tokenObligatorio ||
      this.base64ImageObligatoria ||
      this.parametrosObligatorios /*|| this.nombreActividadObligatorio*/
    ) {
      this.errores = true;
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: { origen: "invalidForm", titulo: "AVISO" },
      });
    } else {
      this.errores = false;
    }
  }

  obtenJson() {
    const parametros = this.parametros;
    const nombre = this.nombre;
    const descripcion = this.descripcion;
    const icono = this.base64Image;
    const url = this.url;
    const token = this.token;
    let estado = false;
    if (this.estatus === "1") estado = true;
    else estado = false;
    const estatus = estado;
    const posicion = this.position;
    const interna = this.isWebInternal;
    const activity = this.nombreActividad == "" ? null : this.nombreActividad;

    // Crear un objeto con los valores del formulario
    const pagina = {
      nombre,
      descripcion,
      icono,
      posicion,
      url,
      token,
      estatus,
      interna,
      activity,
      parametros,
    };

    return pagina;
  }

  async getOnePage() {
    this.loading = true;
    (await this.crearPaginaService.getOnePage(this.idPagina)).subscribe(
      (result) => {
        this.loading = false;
        this.paginas = result.data;
        console.log("getOnePage");
        console.log(result.data);
        if (this.paginas.displayGroup != null) {
          constantes.groupVisualization.nameGV = this.paginas.displayGroup.name;
          constantes.ids.idCatGV = this.paginas.displayGroup.displayGroupTypeId;
          constantes.ids.idGV = this.paginas.displayGroup.displayGroupId;
        }
        constantes.paginas.pageId = result.data.pageId;
        if (this.paginas.pageId === this.idPagina) {
          console.log(this.paginas.name);
          console.log(this.paginas.url);

          this.nombre = this.paginas.name;
          this.descripcion = this.paginas.description;
          this.url = this.paginas.url;
          this.token = this.paginas.token;
          this.parametros = atob(this.paginas.parameters);
          this.estatus = this.paginas.status + "";
          this.icono = this.paginas.icon;
          this.position = this.paginas.position;
          this.previewImage = this.icono as string;
          this.base64Image = this.icono as string;

          this.isWebInternal = this.paginas.isWebInternal;
          this.nombreActividad = this.paginas.activity;

          this.displayGroupId = this.paginas.displayGroupId;

          this.displayGroupTypeId =
            this.paginas.displayGroup.displayGroupTypeId;

          sessionStorage.setItem(
            "displayGroupId",
            this.displayGroupId.toString()
          );
          sessionStorage.setItem(
            "displayGroupTypeId",
            this.displayGroupTypeId.toString()
          );
        }
      },
      (error) => {
        this.loading = false;
        console.log(error);
      }
    );
  }

  openConfirmDialog(tipo: number): void {
    let tituloName = "";

    if (tipo == 1) tituloName = "Guardado Correctamente";
    else if (tipo == 2) tituloName = "Actualizado Correctamente";

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { origen: "crearPagina", titulo: tituloName },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 1) {
        console.log("The dialog was closed");
      } else if (result == 0) {
      }
    });
  }

  irAVerPagina() {
    sessionStorage.removeItem("paginas");
    constantes.title = "Consultar páginas";
    this.router.navigate(["/seccionesMonitor/verPagina"]);
  }

  irAPaginaAsignar() {
    this.router.navigate(["/seccionesMonitor/asignarGrupoVisualizacion"]);
    constantes.title = "Asignar grupo de visualización";
    constantes.vista = "toSearch";
    constantes.activeSearch = true;
    constantes.origin = "paginas";
    //constantes.paginas.vista='updatePage'
  }

  cancelar(): void {
    sessionStorage.removeItem("paginas");
    if (this.origin == "secciones") {
      constantes.title = "Asignar páginas";
      this.router.navigate(["/seccionesMonitor/asignarPagina"]);
    } else {
      constantes.title = "Consultar páginas";
      this.router.navigate(["/seccionesMonitor/verPagina"]);
    }
  }
}
