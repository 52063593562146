import { FormatBytesPipe } from './format-bytes.pipe';
import { GetPropertyPipe } from './get-property.pipe';
import { ReplacePipe } from './replace.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SlugifyPipe } from './slugify.pipe';
import { SearchPipe } from './filter.pipe';

export * from './format-bytes.pipe';
export * from './get-property.pipe';
export * from './replace.pipe';
export * from './safe-html.pipe';
export * from './slugify.pipe';
export * from './filter.pipe';

export const pipesDeclarations = [
    FormatBytesPipe,
    GetPropertyPipe,
    ReplacePipe,
    SafeHtmlPipe,
    SlugifyPipe,
    SearchPipe
];
