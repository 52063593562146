import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseData } from 'src/app/dto/ResponseData';

@Injectable({
  providedIn: 'root'
})
export abstract class NotificationsDataService {
  abstract create(body: any): Observable<ResponseData>;
  abstract read(paginator: any): Observable<ResponseData>;
  abstract readOne(uuid: any): Observable<ResponseData>;
  abstract update(uuid: any, body: any): Observable<ResponseData>;
  abstract delete(uuid: string): Observable<ResponseData>;
  abstract readRules(): Observable<ResponseData>;
  abstract monitorLogin(): Observable<ResponseData>;
  abstract monitorFilterUsers(data: any, body: any): Observable<ResponseData>;
}
