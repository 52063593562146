<nav *ngIf="(items.length > perPage) || (total > perPage)" aria-label="Page navigation example">
    <ul class="pagination"
        [ngClass]="{'pagination-sm':size == 'sm', 'pagination-lg':size == 'lg', 'justify-content-start':align == 'start', 'justify-content-center':align == 'center','justify-content-end':align == 'end'}">
        <li *ngIf="btnSec" [ngClass]="{disabled:pager.currentPage === 1}" class="page-item first-item">
            <a (click)="setPage(1)" class="page-link">{{lblFirst}}</a>
        </li>
        <li *ngIf="btnPri" [ngClass]="{disabled:pager.currentPage === 1}" class="page-item previous-item">
            <a (click)="setPage(pager.currentPage - 1)" class="page-link">{{lblPrev}}</a>
        </li>
        <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}"
            class="page-item number-item">
            <a (click)="setPage(page)" class="page-link">{{page}}</a>
        </li>
        <li *ngIf="btnPri" [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item next-item">
            <a (click)="setPage(pager.currentPage + 1)" class="page-link">{{lblNext}}</a>
        </li>
        <li *ngIf="btnSec" [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item last-item">
            <a (click)="setPage(pager.totalPages)" class="page-link">{{lblLast}}</a>
        </li>
    </ul>
</nav>