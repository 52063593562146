import { Injectable } from '@angular/core';
import { constants } from '../constants';
import { SessionDataService } from './data/session-data.service';
import { AuthenticationCredential } from '../dto/AuthenticationCredential';
import { BasicUserInfo } from '../dto/BasicUserInfo';
import { ResponseData } from '../dto/ResponseData';
import { getData, getStatus, getMessage, getValid } from '../utils/_http';
import { removeStorage, setStorage, getStorage, JSONTryer, existStorage } from '../utils/_utils';
import { Subscription, Observable } from 'rxjs';
import { SettingsService } from './settings.service';
import { Router } from '@angular/router';
import { isValidFile } from 'src/assets/js/plugins/dropzone';
import { ModalHelperService } from './modal-helper.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  public user: BasicUserInfo;
  public get usr() {
    if (this.accessToken != null) {
      let credential = new AuthenticationCredential();
      credential.token = this.accessToken;
      return this.sessionDataService.getUser(credential);
    }
  }
  public get accessToken() {
    return JSONTryer(getStorage(constants.ls.session));
  }

  public set accessToken(accessToken) {
    if (accessToken == null) {
      let removeItems = [];
      Object.keys(constants.ls).map(item => {
        removeItems.push(constants.ls[item]);
      })
      removeStorage(removeItems);
    } else {
      setStorage(constants.ls.session, JSON.stringify(accessToken));
    }
  }
  constructor(
    private sessionDataService: SessionDataService,
    public settings: SettingsService,
    private router: Router,
    private modalHelper: ModalHelperService
  ) {
    let pathInfo = this.settings.get('pathInfo');
    if (pathInfo == null || pathInfo['base'] == null) {
      this.router.navigate(['/']);
    } else {
      if (this.accessToken != null) {
        let credential = new AuthenticationCredential();
        credential.token = this.accessToken;
        if (existStorage(constants.ls.sessionProfile)) {
          this.user = getStorage(constants.ls.sessionProfile, true);
        }
        else {
          this.sessionDataService.getUser(credential).subscribe((rs: ResponseData) => {
            if (rs) {
              this.user = getData(rs);
              if (this.user) setStorage(constants.ls.sessionProfile, getData(rs));
            }
          }, error => console.error(error));
        }
      } else {
        this.user = new BasicUserInfo();
      }
    }
  }
  public isSignedIn(): boolean {
    return (this.accessToken != null);
  }
  public getDataUser(): any {
    this.usr.subscribe((rs: ResponseData) => {
      if (rs) {
        if (getValid(rs, { title: `Error al obtener el usuario`, modalHelper: this.modalHelper, settings: this.settings })) {
          return getData(rs);
        }
      }
    });
  }
}
