<h2>Últimas actividades</h2>
<div id="last-activities-content">
    <table id="last-activities" class="display dataTable no-footer dashboard-table" style="width:100%" *ngIf="!data">
        <thead>
            <tr>
                <th>Módulo</th>
                <th>Acción</th>
                <th>Creado</th>
                <th>Elemento</th>
                <th>Usuario</th>
            </tr>
        </thead>
        <tbody *ngIf="logData!==undefined&&logData.length>0">
            <tr *ngFor="let d of logData">
                <td>{{ d.module }}</td>
                <td>{{ d.action }}</td>
                <td>{{ d.date | date:'medium' }}</td>
                <td>
                    <p>
                        {{d.field}}
                    </p>
                </td>
                <td>{{ d.user }}</td>
            </tr>
        </tbody>
        <div *ngIf="logData===undefined||logData.length==0">
            No hay actividades recientes en el CMS
        </div>
    </table>
</div>