import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SettingsService } from "src/app/services";
import { makeHeaders } from "src/app/utils/_http";
import { getMonitorToken, getMonitorUserId } from "./http-monitor";
import { HttpMonitorService } from "./http-monitor.service";

@Injectable({
  providedIn: "root",
})
export class CompanyService {
  private baseUrl: string;
  pathInfo: any;

  constructor(
    private settingsService: SettingsService,
    private http: HttpClient,
    private monitorService: HttpMonitorService
  ) {
    this.pathInfo = this.settingsService.get("pathInfo");
    this.baseUrl = this.pathInfo.base.monitor.url;
  }

  async allEmpresas(data: any): Promise<Observable<any>> {
    let monitorToken: string = await this.monitorService.getMonitorToken();
    let url = this.baseUrl + "/v2/search/2";
    return this.http.post<any>(
      url,
      data,
      makeHeaders({ Authorization: "Bearer " + monitorToken })
    );
  }

  async activarInactivarEmpresa(idEmpresa, bandera) {
    let monitorToken: string = await this.monitorService.getMonitorToken();
    let userMonitor: string = await this.monitorService.getMonitorUserId();
    let url =
      this.baseUrl + "/v3/admin/" + userMonitor + "/empresa/" + idEmpresa;

    return this.http.patch<any>(
      url,
      { estatus: bandera },
      makeHeaders({ Authorization: "Bearer " + monitorToken })
    );
  }

  async findByIdEmpresa(id) {
    let monitorToken: string = await this.monitorService.getMonitorToken();
    let url = this.baseUrl + "/v1/company/" + id;
    return this.http.get<any>(
      url,
      makeHeaders({ Authorization: "Bearer " + monitorToken })
    );
  }
  async save(idEmpresa, company) {
    let monitorToken: string = await this.monitorService.getMonitorToken();
    let userMonitor: string = await this.monitorService.getMonitorUserId();
    let url =
      this.baseUrl +
      "/v3/admin/" +
      userMonitor +
      "/empresa" +
      (idEmpresa != null ? "/" + idEmpresa : "");
    if (idEmpresa != null) {
      return this.http.put<any>(
        url,
        company,
        makeHeaders({ Authorization: "Bearer " + monitorToken })
      );
    } else {
      return this.http.post<any>(
        url,
        company,
        makeHeaders({ Authorization: "Bearer " + monitorToken })
      );
    }
  }

  async refreshToken(token) {
    let monitorToken: string = await this.monitorService.getMonitorToken();
    let userMonitor: string = await this.monitorService.getMonitorUserId();
    let url = this.baseUrl + "/v2/admin/" + userMonitor + "/tokenCompany";

    return this.http.post<any>(
      url,
      token,
      makeHeaders({ Authorization: "Bearer " + monitorToken })
    );
  }

  async allGroups() {
    let monitorToken: string = await this.monitorService.getMonitorToken();
    let url = this.baseUrl + "/v2/group";
    return this.http.get<any>(
      url,
      makeHeaders({ Authorization: "Bearer " + monitorToken })
    );
  }
  async allEncuestas() {
    let monitorToken: string = await this.monitorService.getMonitorToken();
    let url = this.baseUrl + "/v2/encuestas";
    return this.http.get<any>(
      url,
      makeHeaders({ Authorization: "Bearer " + monitorToken })
    );
  }
  async allPaises() {
    let monitorToken: string = await this.monitorService.getMonitorToken();
    let url = this.baseUrl + "/v2/paises";
    return this.http.get<any>(
      url,
      makeHeaders({ Authorization: "Bearer " + monitorToken })
    );
  }
}
