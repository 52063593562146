import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-gifload',
  templateUrl: './gifload.component.html',
  styleUrls: ['./gifload.component.css']
})
export class GifloadComponent implements OnInit {

  active: boolean;
  constructor(public activeModal: NgbActiveModal) 
  {
    setTimeout(() =>{
      this.activeModal.close();
    }, 1500)

   }

  ngOnInit(): void {
  }

}
