import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseData } from 'src/app/dto';

@Injectable({
  providedIn: 'root'
})
export abstract class MenusModuleDataService {
  abstract read(): Observable<ResponseData>;
  abstract readOne(menuUUID: any): Observable<ResponseData>;
  abstract create(body: any): Observable<ResponseData>;
  abstract update(menuUUID: any, body: any): Observable<ResponseData>;
  abstract bulkCreate(body: any): Observable<ResponseData>;
  abstract bulkUpdate(body: any): Observable<ResponseData>;
  abstract delete(menuUUID: any): Observable<ResponseData>;
}
