import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingsService } from 'src/app/services/settings.service';
import { ValidateService } from 'src/app/services/validate.service';
import { SessionService } from 'src/app/services/session.service';
import { ResponseData } from 'src/app/dto/ResponseData';
import { getArray, getValid, getData, hiddenModalLoading } from 'src/app/utils/_http';
import { labels } from 'src/app/strings';
import { AlertNotificationService } from 'src/app/services/alert-notification.service';
import { MediaService } from 'src/app/services/media.service';
import { Router } from '@angular/router';
import { BasicUserInfo } from 'src/app/dto/BasicUserInfo';
import { constants } from 'src/app/constants';
import { UsersDataService } from 'src/app/services/data/users-data.service';
import { RoleDataService } from 'src/app/services/data/role-data.service';
import { Role } from 'src/app/dto';
import { setStorage, getStorage, existStorage, getPermissionsPage, permissionPageUser, clone } from 'src/app/utils/_utils';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { Notification } from 'src/app/dto';
import { FilesPipe } from 'src/app/pipes/files.pipe';
import { Group } from 'src/app/dto/group.dto';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';

function passwordPattern(data: FormControl) {
  let password = data.value;
  let notValid = '<>'
  let alphabetLower = 'abcdefghijklmnñopqrstuvwxyz'
  let alphabetUpper = alphabetLower.toUpperCase()
  let numbers = '1234567890'

  let validLowercase = 0
  let validUppercase = 0
  let validNumeric = 0
  let validCharactersNotValid = 0

  if (password) {
    password.split('').map(item => {
      if (alphabetUpper.indexOf(item) != -1) {
        validUppercase++
      }
      if (numbers.indexOf(item) != -1) {
        validNumeric++
      }
      if (notValid.indexOf(item) != -1) {
        validCharactersNotValid++
      }
    })
    if (validUppercase && validNumeric && password.length > 7 && password.length < 11 && validCharactersNotValid == 0) {
      return null;
    }
    return {
      passwordPattern: {
        value: 'Tamaño mínimo de 8 caracteres y máximo de 10. La contraseña debe estar formada por al menos una mayúscula y un dígito, acepta caracteres especiales'
      }
    }
  }
  return null
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  subscription: Subscription = new Subscription

  formaProfile: FormGroup;
  groupSelct: FormGroup;
  formaPassword: FormGroup;
  private dataProfile: any = {};
  media: any = {
    filesToUpload: [],
    files: [],
    isSingleFiles: null,
    mediaChange: false,
  }
  roles: Role[] = [];
  groups: Group[] = [];
  selectedGroup: String = "--Elija Grupo--";
  business: Array<any>;
  selectedBusiness: Array<any>;
  checkedBusiness: boolean = false;
  elgroup: any = [];
  datosGrupo: any = {
    uuid: '',
    name: '',
    monitorid: ''
  }
  pageAction: any = {
    action: null,
    uuid: null,
    titlePage: null,
  };
  varToForm: any = {
    hiddenChangePassword: true,
    constants: constants,
    optionsStatus: [
      {
        name: 'Activo',
        value: true
      },
      {
        name: 'Inactivo',
        value: false
      }
    ],
    role: {
      selected: [],
      label: ['name'],
      value: 'id',
    },
    touchedRole: false
  }
  permissions: any = {}
  notification: Notification = {
    title: '',
    message: '',
    options: { autoClose: true, keepAfterRouteChange: true }
  };
  isHidden: boolean = true;
  isDots: boolean = true;
  changePassword: boolean = false;
  grupoElegido: boolean = true;
  showAgregarTodas: true;
  showAgregarTodasMenos: true;
  findResult: any = [];
  editUser: boolean = true;
  grupoOrg: any = [];
  empresaUser: boolean = false;
  BusinessUser: any = {
    BusinessId: null,
    UserId: null,
    GroupId: null,
  }
  showButtonSave: boolean = false
  groupsClone: any

  deleteModal = { show: false, item: null, groups: [] }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private fb: FormBuilder,
    private validate: ValidateService,
    public settings: SettingsService,
    protected session: SessionService,
    protected alertNotificationService: AlertNotificationService,
    protected mediaService: MediaService,
    private router: Router,
    private usersDataService: UsersDataService,
    private roleDataService: RoleDataService,
    private modalHelper: ModalHelperService,
    private filesPipe: FilesPipe,
    private ngModal: NgbModal
  ) {
    this.pageAction = {
      action: constants.screens.users[this.router.url.split('/').pop()] ?? null,
      uuid: this.router.url.split('/')[2] ?? null,
      titlePage: constants.screens.users[this.router.url.split('/').pop()] == constants.screens.users.edit ? labels.users.update.titlePage : (constants.screens.users[this.router.url.split('/').pop()] == constants.screens.users.create ? labels.users.create.titlePage : labels.profile.titlePage)
    }
    this.permissions = getPermissionsPage(this.router.url);
    this.showButtonSave = permissionPageUser(this.permissions, this.pageAction.action, router, '/users')
    this.varToForm['hiddenChangePassword'] = this.pageAction['action'] == constants.screens.users.create ? false : true;
    this.getRoles();
    this.createFormProfile();
    this.createFormNewPassword();
  }
  ngOnInit() {
    this.subscription.add(
      this.modalHelper.poll$
        .subscribe(
          value => {
            if (value?.module) {
              if (value.module == 'validFormResponse') {
                if (value.invalid) {
                  this.validateData.invalidForm++
                }
                this.validateData.sectionFormCounterReponse++

                if (this.validateData.sectionFormCounter == this.validateData.sectionFormCounterReponse) {
                  if (!this.validateData.invalidForm) {
                    this.saveuser()
                  }
                }
              }
            }
          }
        )
    )
  }
  unHide() {
    this.isHidden = !this.isHidden;
    this.formaPassword.reset()
  }
  unHidedots() {
    this.isDots = !this.isDots;
  }
  private createFormProfile() {
    this.formaProfile = this.fb.group({
      name: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      telephone: ['', [Validators.required, Validators.pattern(this.settings.get('validate')['phone'])]],
      extension: ['', [Validators.maxLength(10)]],
      username: ['', Validators.required],
      password: ['', [Validators.required, passwordPattern]],
      active: [true, Validators.required],
      image: [{}],
      groups: [[]],
      roles: [[]],
    });

    this.setProfileDefault()
  }
  private setFormUserProfile(data: any) {
    this.dataProfile = data;
    let idRoles = []
    if (this.dataProfile.roles.length) {
      this.dataProfile.roles.map(x => {
        idRoles.push({ id: x.id })
      })
    }
    this.formaProfile.setValue({
      name: this.dataProfile['name'] + '',
      lastname: this.dataProfile['lastname'] + '',
      email: this.dataProfile['email'] + '',
      telephone: this.dataProfile['telephone'] + '',
      extension: this.dataProfile['extension'] + '',
      username: this.dataProfile['email'] + '',
      password: this.dataProfile['password'] + '',
      active: true,
      image: {
        "uuid": this.dataProfile['image']?.uuid || '',
        "url": this.dataProfile['image']?.url || '',
        "alt": this.dataProfile['image']?.alt || ''
      },
      groups: this.dataProfile['groups'].length ? this.dataProfile['groups'] : [],
      roles: idRoles,
    })
  }
  private setProfileDefault() {
    setTimeout(() => {
      this.dataProfile = null;
      let user = null;
      switch (this.pageAction['action']) {
        case 'read':
        case constants.screens.users.edit:
          this.usersDataService.readOne(this.pageAction['uuid'], this.session.accessToken.accessToken)
            .subscribe(
              rs => {
                user = getData(rs);
                let rol = document.getElementById('selectRolUsuario') as HTMLInputElement;
                rol.value = user.roles[0].id;

                this.formaProfile.controls.password.clearValidators()
                this.formaProfile.controls.password.updateValueAndValidity()
                this.changePassword = false;
                this.setFormUserProfile(user);
              }
            )
          break;
        case constants.screens.users.create:
          user = new BasicUserInfo();
          user.email = '';
          user.name = '';
          user.lastname = '';
          user.username = '';
          user.telephone = '';
          user.extension = '';
          user.password = '';
          user.active = false;
          user.roles = [];
          user.groups = [];
          user.image = '';
          this.setFormUserProfile(user);
          break;
        default:
          user = null;
          if (existStorage(constants.ls.sessionProfile)) {
            user = getStorage(constants.ls.sessionProfile, true);
          } else {
            if (this.session.user !== undefined && this.session.user !== null) {
              user = this.session.user;
              setStorage(constants.ls.sessionProfile, user);
            }
            else {
              this.session.usr
                .subscribe((rs: ResponseData) => {
                  if (getValid(rs, { title: `Error al obtener el usuario`, modalHelper: this.modalHelper, settings: this.settings })) {
                    if (getData(rs)) {
                      setStorage(constants.ls.sessionProfile, getData(rs));
                      user = getData(rs);
                    }
                  }
                });
            }
          }
          if (user) {
            user['roles'].filter(item => this.varToForm.role.selected.push(item.id));
            this.setFormUserProfile(user);
          }
          break;
      }
    }, 750);

  }

  changeGroup(group) {
    if (group.action == "delete" && group.module == "groups") {
      this.deleteModal.item = []
      group.items.business.map(x => {
        this.deleteModal.item.push({
          BusinessId: x.uuid,
          UserId: this.pageAction['uuid'],
          GroupId: group.items.uuid,
          BusinessName: x.name
        })
      })
      this.deleteModal.groups = group.data;
      this.deleteModal.show = true;
    }
  }
  // --
  deleteItem(data: any = null) {
    this.deleteModal.show = false;
    if (data) {
      this.deleteModal.item.map(x => {
        this.subscription.add(
          this.usersDataService.deleteBusiness(x, this.session.accessToken)
            .subscribe(
              (_res) => {
                this.deleteModal.show = false;
                this.formaProfile.patchValue({ groups: [...this.deleteModal.groups] })
              },
              error => {
                this.deleteModal.show = false;
                this.formaProfile.patchValue({ groups: [...this.deleteModal.groups] })
              }
            )
        )
      })
    }
    else {
      this.deleteModal.item = null;
    }
  }
  private createFormNewPassword() {
    this.formaPassword = this.fb.group({
      password: ['', [Validators.required, passwordPattern]],
      passwordConfirm: ['', [Validators.required, passwordPattern]],
    })
  }
  resetPicture(): void {
    this.formaProfile.patchValue({
      image: {
        "uuid": '',
        "url": '',
        "alt": ''
      }
    });
  }
  onChangePassword() {
    if (this.formaPassword.invalid) {
      Object.values(this.formaPassword.controls).forEach(control => {
        control.markAsTouched();
      });
    }
    else {
      this.formaProfile.controls.password.setValidators([passwordPattern, Validators.required])
      this.formaProfile.controls.password.updateValueAndValidity()
      this.formaProfile.value.password = this.formaPassword.value.password;
      this.formaProfile.patchValue({
        password: this.formaPassword.value.password + '',
      });
      this.changePassword = true;
      this.unHide()
    }
  }

  saveuser() {
    if (!this.validateData.invalid) {
      if (this.pageAction['action'] === 'create') {
        this.usersDataService.create(this.formaProfile.value, this.session.accessToken.accessToken)
          .subscribe((rs: ResponseData) => {
            if (getValid(rs, { title: `Error al crear el usuario`, modalHelper: this.modalHelper, settings: this.settings })) {
              this.alertNotificationService.success(labels.users.create.title, labels.users.create.body, { autoClose: true, keepAfterRouteChange: true });
              this.router.navigate([constants.screens.users.path]);
            }
          });
      }
      else if (this.pageAction['action'] === 'edit') {
        this.formaProfile.value.uuid = this.pageAction['uuid'];
        if (!this.formaProfile.value.image) {
          this.formaProfile.value.image = null;
        }
        if (!this.changePassword) {
          this.formaProfile.patchValue({ password: '' })
        }
        this.usersDataService.update(this.pageAction['uuid'], this.formaProfile.value, this.session.accessToken.accessToken)
          .subscribe(
            (rs: ResponseData) => {
              if (getValid(rs, { title: `Error al actualizar el usuario`, modalHelper: this.modalHelper, settings: this.settings })) {
                if (this.pageAction['uuid'] == this.session.user.uuid) {
                  this.modalHelper.changeImageProfile(this.formaProfile.value.image?.url)
                }
                this.router.navigate([constants.screens.users.path]);
              }
            }
          )
      }
    }
  }
  // --
  validateData = {
    invalid: 0,
    invalidForm: 0,
    sectionFormCounter: 0,
    sectionFormCounterReponse: 0,
  }
  saveProfile() {
    this.roleChange = true
    if (this.formaProfile.invalid) {
      Object.values(this.formaProfile.controls).forEach(control => {
        control.markAsTouched();
      });
    }
    // -- llamamos las validaciones de los componentes internos
    this.validateData.invalidForm = 0
    this.validateData.sectionFormCounter = 1
    this.validateData.sectionFormCounterReponse = 0
    this.modalHelper.pollChange({
      module: 'validForm',
    })
  }
  isSingleFile($event) {
    this.media['isSingleFiles'] = $event;
    if (this.media['files'].length) if (this.media['isSingleFiles']) this.media['files'] = [this.media['files'].pop()];
  }
  getFilesToUpload($event) {
    setTimeout(() => {
      this.media['filesToUpload'] = $event;
      if (this.media['filesToUpload'].length) {
        let id = this.media['filesToUpload'][0].hasOwnProperty('uuid') ? 'uuid' : 'id';
        let url = this.media['filesToUpload'][0].hasOwnProperty('url') ? 'url' : 'url';
        let alt = this.media['filesToUpload'][0].hasOwnProperty('alt') ? 'alt' : 'alt';
        this.formaProfile.patchValue({ image: { uuid: this.media['filesToUpload'][0][id] !== undefined ? this.media['filesToUpload'][0][id] : '', url: this.media['filesToUpload'][0][url], alt: this.media['filesToUpload'][0][alt] } });
      }
      else {
        this.formaProfile.patchValue({ image: '' });
      }
    }, 50)
  }
  getDropdownSelected($event) {
    this.formaProfile.patchValue({ roles: this.roles.filter(item => $event.includes(item.id)) })
    this.varToForm['touchedRole'] = true;
  }
  private getRoles() {
    this.roleDataService.read(this.session.accessToken.accessToken)
      .subscribe(
        rs => {
          if (getValid(rs, { title: `Error al obtener los roles`, modalHelper: this.modalHelper, settings: this.settings })) {
            this.roles = getArray(rs).filter(item => item.active);
            if (getArray(rs)) setStorage(constants.ls.catalogRoles, getArray(rs));
          }
        }
      )
  }

  roleChange: boolean = false
  onRoleChange(event) {
    this.formaProfile.patchValue({ roles: [{ id: parseInt(event.target.value) }] })
    this.roleChange = true
  }

  selectAll() {
    this.checkedBusiness = !this.checkedBusiness;
    const checkArray: FormArray = this.formaProfile.get('groups') as FormArray;
    this.findResult.map((item) => {
      const input = document.getElementById(item.uuid) as HTMLInputElement;
      input.checked = this.checkedBusiness;
      this.elgroup.push({ uuid: item.uuid, monitorid: item.monitorid, name: item.name });
    });
    checkArray.push(new FormControl({ uuid: this.datosGrupo.uuid, name: this.datosGrupo.name, monitorid: this.datosGrupo.monitorid, business: this.elgroup }));
  }
  searchEmpresa(empresaName: any) {
    empresaName = empresaName.toLowerCase();

    this.findResult = this.business.filter(item => item.name.toLowerCase().startsWith(empresaName));

  }
  optionBusiness(name: any, uuid: any) {
    this.selectedBusiness.push({ "uuid": uuid, "name": name });
  }

  deleteSelection(uuid: any) {
    this.selectedBusiness = this.selectedBusiness.filter(item => item.uuid !== uuid);
  }
  usernameField(event) {
    this.formaProfile.get('username').setValue(event.target.value);
  }
  goBack() {
    this.modalHelper.changeLoading(true);
    if (this.pageAction['action']) this.router.navigate([constants.screens.users.path]);
    this.router.navigate([constants.screens.dashboard.path]);
  }

  get f() {
    return this.formaProfile.controls
  }

  get fp() {
    return this.formaPassword.controls
  }

  fieldPassword = {
    inputPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  }
  viewPassword(element: string) {
    this.fieldPassword[element] = !this.fieldPassword[element]
  }
}
