import { Injectable } from '@angular/core';
import { CodeVerify } from 'src/app/dto/CodeVerify';
import { Observable } from 'rxjs';
import { ResponseData } from 'src/app/dto/ResponseData';

@Injectable({
  providedIn: 'root'
})
export abstract class CodeVerifyDataService {
  abstract getCodeVerify(body: CodeVerify): Observable<ResponseData>;
  abstract forwardCode(body: CodeVerify): Observable<ResponseData>;
}
