import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'abarcms-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.css']
})
export class InputSearchComponent implements OnInit, OnChanges {
  textToSearch: string = '';
  @Input() placeholder: string = 'Buscar...';
  @Input() fieldsToSearch: Array<string> = [];
  @Input() items: Array<any> = [];
  @Output() dataFound = new EventEmitter<any>();
  constructor() { }
  ngOnInit(): void { }
  ngOnChanges(change): void {
    if (Array.isArray(change.items.previousValue) && change.items.currentValue) {
      if (change.items.previousValue.length !== change.items.currentValue.length) this.textToSearch = '';
    }
  }
  searchText(event: any) {
    setTimeout(() => {
      let data = [];
      this.items.map(item => {
        if (this.fieldsToSearch.length) {
          let exist = false;
          this.fieldsToSearch.map(field => {
            if (item[field] !== undefined && item[field] !== null) {
              if (item[field].toString().toLowerCase().indexOf(this.textToSearch.toString().toLowerCase()) !== -1) {
                exist = true;
                return false;
              }
            }
          });
          if (exist) {
            data.push(item);
          }
        }
        else if (item.toString().toLowerCase().indexOf(this.textToSearch.toString().toLowerCase()) !== -1) {
          data.push(item);
        }
      });
      this.dataFound.emit(data);
    });
  }
}
