import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { PollSection } from 'src/app/dto/Poll';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { clone, slugifyPipe } from 'src/app/utils/_utils';

@Component({
  selector: 'app-polls-section',
  templateUrl: './polls-section.component.html',
  styleUrls: ['./polls-section.component.css']
})
export class PollsSectionComponent implements OnInit, OnDestroy, OnChanges {
  subscription: Subscription = new Subscription
  @Input() data: PollSection[] = []
  @Input() action: string | null = null
  @Input() isFormPublicate: boolean = false
  @Output() onAction: EventEmitter<any> = new EventEmitter

  dataClone: PollSection[] = []
  formSection: FormGroup[] = []
  module: string = 'sections'
  haveError: boolean = false
  haveErrorSystemname: boolean[] = []
  haveErrorSystemnameLength: boolean[] = []
  notValidTitle: boolean[] = []
  write: boolean[] = []
  elementDelete: number = -1

  constructor(
    private modalHelper: ModalHelperService,
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.modalHelper.poll$
        .subscribe(
          value => {
            if (value?.module) {
              if (value?.module == 'validForm') {
                this.modalHelper.pollChange({
                  module: 'validFormResponse',
                  invalid: this.validation(true)
                })
              }
            }
          }
        )
    )
  }
  // --
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
  // --
  ngOnChanges(changes: SimpleChanges) {
    if (changes?.data) {
      if (changes?.data.currentValue.length) {
      }
    }
    this.cloneData()
  }
  // --
  cloneData() {
    this.data = clone(this.data)
  }
  // --
  onWrite(index: number) {
    this.write[index] = true
    this.notValidTitle[index] = false
    this.haveErrorSystemname[index] = false
    this.haveErrorSystemnameLength[index] = false
    setTimeout(() => {
      if (this.data[index].title) {
        if (!this.data[index].title) {
          this.notValidTitle[index] = true
        }
        // if (this.data[index].order == -1) {
        this.data[index].systemname = this.data[index].uuid
        // }
        if (!this.isUniqueSystemName(this.data[index].uuid)) {
          this.haveErrorSystemname[index] = true
        }
        if (this.minMaxSystemName(this.data[index].title.length)) {
          this.haveErrorSystemnameLength[index] = true
        }
      }
    })
  }
  // --
  onSection(action: string, index?: number) {
    switch (action) {
      case 'add':
        this.onAction.emit({
          module: this.module,
          action: action,
          data: [...clone(this.data)]
        })
        break;
      case 'save':
        this.haveErrorSystemname = []
        // if (this.elementDelete == -1) {
        if (!this.validation(true)) {
          let validToSave = true
          this.sectionsActive().map((x, i) => {
            x.order = i
            if (this.action == 'create') {
              x.systemname = x.uuid
            }
            if (!this.isUniqueSystemName(x.uuid)) {
              this.haveErrorSystemname[i] = true
              validToSave = false
            }
            if (i == this.sectionsActive().length - 1) {
              if (validToSave) {
                this.onAction.emit({
                  module: this.module,
                  action: action,
                  data: [...clone(this.data)]
                })
              }
            }
          })
        }
        // }
        // else {
        //   this.elementDelete = -1
        // }
        break;
      case 'delete':
        // this.elementDelete = index
        // if (this.action == 'create') {
        // this.onAction.emit({
        //   module: this.module,
        //   action: action,
        //   data: index
        // })
        // }
        // else {
        let cloneData = clone(this.data)
        cloneData.splice(index, 1)
        this.onAction.emit({
          module: this.module,
          action: action,
          data: [...cloneData]
        })
        // }
        break;
    }
  }
  // --
  validation(validateEmpty: boolean = false): boolean {
    this.haveError = false
    this.notValidTitle = []
    this.write = []
    if (this.sectionsActive().length) {
      this.data.map((x, i) => {
        if (!x.title) {
          this.notValidTitle[i] = true
          this.write[i] = true
          this.haveError = true
        }
      })
    }
    if (validateEmpty) {
      if (!this.sectionsActive().length) {
        this.haveError = true
      }
    }
    return this.haveError
  }
  // --
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.data, event.previousIndex, event.currentIndex);
  }
  // --
  isUniqueSystemName(systemname: string): boolean {
    let unique = -1
    this.sectionsActive().map(x => {
      if (x.systemname == systemname) {
        unique++
      }
    })
    return unique > 0 ? false : true
  }
  // --
  minMaxSystemName(length: number): boolean {
    let min = 5
    let max = 150
    return length < min || length > max
  }
  // --
  sectionsActive(): any {
    return this.data.filter(x => x.active)
  }
}
