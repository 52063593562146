export const labels = {
    fileUpload: {
        upload: {
            title: 'Archivo agregado',
            body: 'El archivo <strong>{name}</strong> se agregó correctamente',
        },
        delete: {
            title: 'Archivo eliminado',
            body: 'El archivo <strong>{name}</strong> fué eliminado correctamente',
        },
        incorrectType: {
            title: 'Tipo de archivo incorrecto',
            body: 'El archivo <strong>{name}</strong> no es del tipo válido',
        },
        sizeExceeded: {
            title: 'Excedió el tamaño permitido',
            body: 'El máximo permitido es de <strong>{size}MB</strong>',
        },
        fileExist: {
            title: 'El archivo ya existe',
            body: 'El archivo <strong>{name}</strong> ya esta agregado',
        },
        errorUpload: {
            title: 'Error al subir',
            body: 'Ocurrió un error al subir el archivo <strong>{name}</strong>, intente nuevamente',
        },
    },
    confirm: {
        addPassword: {
            title: 'Tu solicitud de registro ha sido enviada exitosamente',
            message: 'Recibirás un correo electrónico por parte del administrador cuando tus accesos sean activados.',

        },
        passwordChanged: {
            title: 'Contraseña actualizada',
            message: 'La contraseña del {correo} fue cambiada con éxito.'
        },
    },
    forwardCode: 'Se reenvio el codigo correctamente a <strong>{email}</strong>',
    noDataToUpdate: 'No se han modificado campos para actualizarlos',
    profile: {
        titlePage: 'Perfil de usuario',
        updated: {
            title: 'Actualización del perfil',
            data: 'Se actualizo correctamente la información del usuario',
            password: 'Se actualizó correctamente la contraseña del usuario'
        }
    },
    users: {
        create: {
            titlePage: 'Crear usuario',
            title: 'Usuario creado',
            body: 'Se creó correctamente el usuario',
            alert: {
                success: 'Se envió el correo de registró',
                error: 'Ocurrió un error al enviar el correo de registró de usuario'
            }
        },
        update: {
            titlePage: 'Modificar datos del usuario',
            title: 'Usuario actualizado',
            body: 'Se actualizó correctamente el usuario',
            password: 'Se actualizó correctamente la contraseña del usuario'
        },
        delete: {
            titlePage: 'Eliminar usuario',
            title: 'Usuario eliminado',
            body: 'Se creó correctamente el usuario',
            confirmMessage: '¿Estás seguro que quieres eliminar este <b>Usuario</b> de la <b>Empresa</b>? El eliminado del <b>Usuario</b> no se puede recuperar'
        },
        active: {
            titlePage: 'Activación de usuario',
            alert: {
                success: 'Se envió el correo se activación',
                error: 'Ocurrió un error al enviar el correo de activación'
            }
        },
    },
    groups: {
        create: {
            titlePage: 'Alta de grupo',
            title: 'Grupo creado',
            body: 'Se creó correctamente el grupo',
        },
        update: {
            titlePage: 'Modificar datos del grupo',
            title: 'Grupo actualizado',
            body: 'Se actualizó correctamente el grupo',
        },
        delete: {
            title: 'Grupo eliminado',
            body: 'Se eliminó correctamente el grupo',
            confirmMessage: '¿Estás seguro que quieres eliminar este <b>Grupo</b>?  El eliminado del <b>Grupo</b> borra también todas las <b>Empresas</b> y <b>Usuarios</b> pertenecientes al <b>Grupo</b>, y no se pueden recuperar.',
            confirmMessageActive: '¿Estás seguro que quieres Activar este <b>Grupo</b>?.',
            confirmMessageInactive: '¿Estás seguro que quieres Inactivar este <b>Grupo</b>?.'
        }
    },
   
    empresas:{
        create: {
            titlePage: 'Alta de Empresa',
            title: 'Empresa creado',
            body: 'Se creó correctamente la empresa',
        },
        update: {
            titlePage: 'Modificar Empresa',
            title: 'Empresa actualizado',
            body: 'Se actualizó correctamente la empresa',
        },
        delete: {
            title: 'Empresa eliminada',
            body: 'Se eliminó correctamente la empresa',
            confirmDeleteMessage: '¿Estás seguro que quieres eliminar esta <b>Empresa</b>?',
            confirmMessage: '¿Estás seguro que quieres eliminar esta <b>Empresa</b>?  El eliminado de la <b>Empresa</b> no se podra recuperar.'
        },
        active: {
            title: 'Empresa activada',
            body: 'Se activo correctamente la empresa',
            confirmActiveMessage: '¿Estás seguro que quieres activar esta <b>Empresa</b>?'
        }
    },

    business: {
        create: {
            titlePage: 'Alta de grupo | Alta de empresa',
            title: 'Empresa creada',
            body: 'Se creó correctamente la empresa',
        },
        update: {
            titlePage: 'Alta de grupo | Alta de empresa',
            title: 'EMPRESA actualizado',
            body: 'Se actualizó correctamente la empresa',
        },
        delete: {
            title: 'Empresa eliminada',
            body: 'Se eliminó correctamente la empresa',
            confirmMessage: '¿Estás seguro que quieres eliminar esta <b>Empresa</b> del <b>Grupo</b>?  El eliminado de la <b>Empresa</b> borra también todos los usuarios pertenecientes a la <b>Empresa</b>, y no se pueden recuperar.'
        }
    },
    role: {
        create: {
            titlePage: 'Crear role',
            title: 'Role creado',
            body: 'Se creó correctamente el rol',
        },
        update: {
            titlePage: 'Modificar datos del rol',
            title: 'Role actualizado',
            body: 'Se actualizó correctamente el rol',
        },
        delete: {
            title: 'Role eliminado',
            body: 'Se eliminó correctamente el rol',
        },
        nodelete: {
            title: 'No se puede Borrar este ROL',
            body: 'Este ROL no se puede eliminar',
            confirmMessage: 'No Puedes Eliminar este <b>ROL</b> Debido a que hay usuarios con este tipo de rol activos'
        }
    },
    language: {
        create: {
            titlePage: 'Crear idioma',
            title: 'Idioma creado',
            body: 'Se creó correctamente el idioma',
        },
        update: {
            titlePage: 'Modificar datos del idioma',
            title: 'Idioma actualizado',
            body: 'Se actualizó correctamente el idioma',
        },
        delete: {
            title: 'idioma eliminado',
            body: 'Se eliminó correctamente el idioma',
        }
    },
    banner: {
        create: {
            title: 'Banner creado',
            body: 'Se creó correctamente el banner',
        },
        update: {
            title: 'Banner actualizado',
            body: 'Se actualizó correctamente el banner',
        },
        delete: {
            title: 'Banner eliminado',
            body: 'Se eliminó correctamente el banner',
        }
    },
    slider: {
        create: {
            title: 'Slider creado',
            body: 'Se creó correctamente el slider',
        },
        update: {
            title: 'Slider actualizado',
            body: 'Se actualizó correctamente el slider',
        },
        delete: {
            title: 'Slider eliminado',
            body: 'Se eliminó correctamente el slider',
        }
    },
    faq: {
        create: {
            title: 'Pregunta creada',
            body: 'Se creó correctamente la pregunta',
        },
        update: {
            title: 'Pregunta actualizado',
            body: 'Se actualizó correctamente la pregunta',
        },
        delete: {
            title: 'Pregunta eliminado',
            body: 'Se eliminó correctamente la pregunta',
        }
    },
    statement: {
        terms: {
            create: {
                titlePage: 'Términos y Condiciones ',
                title: 'Página creada',
                body: 'Se creó correctamente la paáina',
            },
            update: {
                titlePage: 'Términos y Condiciones ',
                title: 'Página actualizada',
                body: 'Se actualizó correctamente la página',
            },
        },
        privacy: {
            create: {
                titlePage: 'Aviso de privacidad',
                title: 'Página creada',
                body: 'Se creó correctamente la página',
            },
            update: {
                titlePage: 'Aviso de privacidad',
                title: 'Página actualizada',
                body: 'Se actualizó correctamente la página',
            },
        },
    },
    folder: {
        create: {
            titlePage: 'Crear carpeta',
            title: 'Carpeta creada',
            body: 'Se creó correctamente la carpeta',
        },
        update: {
            titlePage: 'Modificar datos de la carpeta',
            title: 'Carpeta actualizada',
            body: 'Se actualizó correctamente la carpeta',
        },
        delete: {
            title: 'Carpeta eliminada',
            body: 'Se eliminó correctamente la carpeta',
        }
    },
    media: {
        create: {
            titlePage: 'Crear media',
            title: 'Elemento media creado',
            body: 'Se creó correctamente el elemento de medios',
        },
        update: {
            titlePage: 'Modificar datos del elemento',
            title: 'Elemento actualizado',
            body: 'Se actualizó correctamente el elemento de medios',
        },
        delete: {
            title: 'Elemento eliminado',
            body: 'Se eliminó correctamente el elemento de medios',
        }
    },
    tag: {
        create: {
            titlePage: 'Crear etiqueta',
            title: 'Etiqueta creada',
            body: 'Se creó correctamente la etiqueta',
        },
        update: {
            titlePage: 'Modificar datos de la etiqueta',
            title: 'Etiqueta actualizada',
            body: 'Se actualizó correctamente la etiqueta',
        },
        delete: {
            title: 'Etiqueta eliminada',
            body: 'Se eliminó correctamente la etiqueta',
        }
    },
    statuses: {
        1: 'Publicado',
        2: 'No publicado',
        3: 'Bloqueado',
        4: 'Desbloqueado',
        5: 'Eliminado',
    },
    actions: {
        publish: 'Publicar',
        unpublish: 'Quitar publicación',
        lock: 'Bloquear',
        unlock: 'Desbloquear',
        delete: 'Eliminar',
        recover: 'Recuperar'
    },
    acessors: {
        create: {
            titlePage: 'Crear asesor',
            title: 'Asesor creado',
            body: 'Se creó correctamente el asesor',
        },
        update: {
            titlePage: 'Modificar datos del asesor',
            title: 'Asesor actualizado',
            body: 'Se actualizó correctamente el asesor',
        },
        delete: {
            title: 'Asesor eliminado',
            body: 'Se eliminó correctamente el asesor',
        }
    },
    notifications: {
        create: {
            titlePage: 'Alta de notificaciones',
            title: 'Notificación creada',
            body: 'Se creó correctamente la noticación',
        },
        update: {
            titlePage: 'Modificar datos de la notificación',
            title: 'Notificación actualizada',
            body: 'Se actualizó correctamente la notificación',
        },
        delete: {
            title: 'Notificación eliminada',
            body: 'Se eliminó correctamente la notificación',
        }
    },
    avisos: {
        create: {
            titlePage: 'Alta de aviso',
            title: 'Aviso creada',
            body: 'Se creó correctamente el aviso',
        },
        update: {
            titlePage: 'Modificar datos del aviso',
            title: 'Aviso actualizado',
            body: 'Se actualizó correctamente el aviso',
        },
        delete: {
            title: 'Aviso eliminado',
            body: 'Se eliminó correctamente el aviso',
        }
    },
};

export const errors = {
    general: 'Ha ocurrido un error mientras se realizaba la búsqueda, intenta de nuevo más tarde.'
};

export const contentFormationBody = {
    root: {
        name: 'contenido'
    },
    contentTypeId: {
        name: 'contentTypeId'
    },
    action: {
        needsOverlap: ['traduccion', 'editar'],
        noOverlap: ['crear']
    },
    contentId: {
        name: 'contentId'
    },
    actionOverlap: {
        needsKey: ['traduccion'],
        noKey: ['simple', 'frontend']
    }
};

export const validation = {
    email: 'Ingrese un correo electrónico válido',
    textlength: 'Debe ingresar de {minlength} hasta {maxlength} caracteres',
    textmin: 'Debe ingresar al menos {minlength} caracter(es)',
    textSize: 'Debe ingresar {minlength} caracter(es)',
    alphanumeric: 'Solo se acepta caracteres alphanuméricos',
    tokenExpire: 'El token expiró'
};