<div class="modal-body md-password-recovery">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="col-md-12 mt-3">
    <h3 class="text-center">Restablecer la contraseña</h3>
  </div>
  <div class="col-md-12">
    <p class="light-text modal-recovery-description pl-5 pr-5">Ingresa tu correo y enviaremos tu nueva contraseña.</p>
  </div>
  <div class="col-md-12">
    <form [formGroup]="forma">
      <div class="form-group">
        <label for="mail">Correo electrónico</label>
        <input autocomplete="off" type="text" class="form-control" placeholder="" formControlName="email"
          [ngClass]="{'is-invalid invalidFieldForm': validEmail}"
          [ngStyle]="validEmail && {'border-color': 'red','margin-bottom': '3px'}">
        <div class="invalidFieldForm" style="color: red;font-size: 13px;" *ngIf="validEmail">
          Ingrese un correo electrónico válido
        </div>
      </div>
    </form>
  </div>  
  <div class="text-center mb-20">
    <a (click)="onRecoverPassword()"><button class="btn btn-secondary background-black">ENVIAR</button></a>
  </div>
</div>
