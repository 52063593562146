<div>
    <h4 #title>
        <ng-content></ng-content>
    </h4>
    <ng-container *ngIf="!loading['create'] && !loading['delete']">
        <form class="dropzone" enctype="multipart/form-data">
            <div class="container" appDragAndDropFileUpload (fileDropped)="onFileDropped($event)">
                <input type="file" [class.active]="filesToUpload.length == 0 || !_singleFile" #fileDropRef
                    id="fileDropRef" [multiple]="!_singleFile" (change)="fileBrowseHandler($event.target.files)"
                    [accept]="_typeFile" [disabled]="filesToUpload.length == 1 && _singleFile" />
                <p [innerHtml]="innerTitleText" *ngIf="filesToUpload.length == 0"></p>
                <div class="files-list">
                    <div class="single-file" *ngFor="let file of filesToUpload; let i = index">
                        <img [src]="file?.type.indexOf('image') !== -1 ? file?.url : 'assets/images/defaultFile.jpg'"
                            width="45px" [alt]="file?.alt">
                        <div class="info">
                            <h4 class="name">
                                {{ file?.name }}
                            </h4>
                            <p class="size">
                                {{ file?.size | formatBytes:2 }}
                            </p>
                            <app-progress [progress]="file?.progress"></app-progress>
                        </div>
                        <img *ngIf="showDelete || file?.progress == 100" src="assets/images/icons/delete.svg"
                            class="delete" width="20px" alt="file" (click)="deleteFile(file?.name)">
                    </div>
                </div>
            </div>
        </form>
    </ng-container>
    <div class="loaderFile" *ngIf="loading['create'] || loading['delete']">Loading...</div>
    <div class="bg-upload" *ngIf="_disabled"></div>
</div>
<abarcms-modal title="Error" *ngIf="error.show" (close)="error.show = false" [btnRight]="'Aceptar'"
    (right)="error.show = false">
    <ng-container *ngIf="settings.get('production')">
        {{error.message}}
    </ng-container>
    <ng-container *ngIf="!settings.get('production')">
        <pre>{{error.message | json}}</pre>
    </ng-container>
</abarcms-modal>