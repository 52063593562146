import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, timer } from 'rxjs';
import { ResponseData } from 'src/app/dto';
import { Poll, PollChoices, PollQuestion, PollSection, PollTypeQuestion, PollTypeQuestionOption } from 'src/app/dto/Poll';
import { AlertNotificationService } from 'src/app/services';
import { PollDataService } from 'src/app/services/data/poll-data.service';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { getArray } from 'src/app/utils/_http';
import { clone, slugifyPipe } from 'src/app/utils/_utils';
import { v1 as uuidv1 } from 'uuid'

@Component({
  selector: 'app-poll-question',
  templateUrl: './poll-question.component.html',
  styleUrls: ['./poll-question.component.css']
})
export class PollQuestionComponent implements OnInit, OnDestroy, OnChanges {
  subscription: Subscription = new Subscription
  @Input() parent: PollSection | null = null
  @Input() data: PollQuestion
  @Input() pollTypeQuestions: PollTypeQuestionOption[]
  @Input() index: number = 0
  @Input() indexParent: number = 0
  @Input() mode: string = 'question'
  @Input() isFormPublicate: boolean = false
  @Output() onAction: EventEmitter<any> = new EventEmitter

  questions: PollQuestion[] = []
  module: string = 'question'
  formQuestion: FormGroup
  formChoice: FormGroup[] = []
  questionData = {
    options: {
      descriptionOption: {
        name: 'Descripcion',
        sysname: 'descriptionOption',
        show: false,
        active: true,
      },
      validationOption: {
        name: 'Validacion de respuesta',
        sysname: 'validationOption',
        show: false,
        active: false,
        value: '',
      },
    },
    question: {
      type: null,
      options: [],
      option: null,
    },
    questionsExist: []
  }

  validateData: {
    haveError: boolean,
    write: any,
    field: any,
    error: any,
  } = {
      haveError: false,
      write: {},
      field: {},
      error: [],
    }

  constructor(
    private formBuilder: FormBuilder,
    private modalHelper: ModalHelperService,
    private pollService: PollDataService,
    private alertNotificationService: AlertNotificationService,
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.modalHelper.poll$
        .subscribe(
          value => {
            if (value?.module) {
              if (value?.module == 'question') {
                if (value?.invalidForm) {
                  this.validateData.haveError = value.invalid
                  this.validateData.error = value.errors
                }
              }
              if (value?.module == 'validForm') {
                this.validateForm(true)
              }
            }
          }
        )
    )
  }
  // --
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
  // --
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.data) {
      if (changes.data?.currentValue != changes.data?.previousValue) {
        this.setFormQuestion(this.data)
      }
    }
  }
  // --
  setFormQuestion(data: PollQuestion) {
    this.formQuestion = this.formBuilder.group({
      uuid: [data.uuid],
      parentuuid: [this.parent.uuid],
      active: [data.active],
      title: [data.title],
      systemname: [data.systemname],
      description: [data.description],
      order: [data.order],
      required: [data.required],
      typeuuid: [data.typeuuid],
      validation: [data.validation],
      choices: [data.choices],
    })
    if (data.choices.length) {
      this.setFormChoice(data.choices)
    }
    setTimeout(() => {
      this.setData('type')
      this.setData('validationOption')
      this.setData('descriptionOption')
    }, 500)
    data = this.formQuestion.value
  }
  // --
  get fq() {
    return this.formQuestion.controls
  }
  // --
  setFormChoice(data: PollChoices[]) {
    data.map((x: PollChoices, i) => {
      this.formChoice[i] = this.formBuilder.group({
        uuid: [x.uuid],
        parentuuid: [x.parentuuid],
        title: [x.title],
        order: [x.order],
        active: [x.active],
        nested: [x.nested],
      })
    })
  }
  // --
  setData(action: string) {
    switch (action) {
      case 'type':
        if (this.data.typeuuid) {
          this.questionData.question.type = this.pollTypeQuestions.find(x => x.uuid == this.data.typeuuid)
          if (this.questionData.question.type) {
            this.questionData.question.options = this.questionData.question.type?.options
            if (this.questionData.question.options.length) {
              this.questionData.options.validationOption.active = true
            }
            this.data.typeuuid = this.questionData.question.type?.uuid
          }
        }
        break;
      case 'validationOption':
        if (this.data.validation) {
          if (this.data.validation.uuidoption) {
            this.questionData.question.option = this.questionData.question.options.find(x => x.uuid == this.data.validation.uuidoption)
            this.questionData.options.validationOption.show = true
            this.questionData.options.validationOption.value = this.data.validation.value
          }
          else {
            this.data.validation = null
          }
        }
        break;
      case 'descriptionOption':
        if (this.data.description) {
          this.questionData.options.descriptionOption.show = true
        }
        break;
    }
  }
  // --
  getOptions(): any {
    return Object.values(this.questionData.options)
  }
  // --
  getTypeQuestion(type: string = 'class'): any {
    switch (type) {
      case 'name':
        return this.questionData.question.type?.systemname

      default:
        return this.questionData.question.type?.systemname == 'opcion_unica'
    }
  }
  // --
  resetData(type: string = 'all') {
    switch (type) {
      case 'choices':
        this.data.choices = []
        break;
      default:
        this.questionData.question.options = []
        this.questionData.question.option = null
        this.questionData.options.validationOption.value = ''
        this.questionData.options.validationOption.active = false
        this.questionData.options.validationOption.show = false
        this.data.typeuuid = ''
        this.data.validation = null
        this.modalHelper.pollChange({
          validation: this.questionData.options.validationOption.show
        })
        break;
    }
  }
  // --
  onSection(action: string) {
    switch (action) {
      case 'type':
        this.resetData()
        if (this.questionData.question.type) {
          this.questionData.question.options = this.questionData.question.type?.options
          if (this.questionData.question.options.length) {
            this.questionData.options.validationOption.active = true
          }
          this.data.typeuuid = this.questionData.question.type?.uuid
          if (this.questionData.question.type.systemname != 'opcion_multiple' || this.questionData.question.type.systemname != 'opcion_unica') {
            this.resetData('choices')
          }
        }
        this.onWrite('typeuuid')
        break;
      case 'option':
        this.questionData.options.validationOption.value = ''
        let uuid = ''
        if (this.questionData.question.option?.uuid) {
          uuid = this.questionData.question.option?.uuid
        }
        this.data.validation = {
          uuidoption: uuid,
          value: ''
        }
        break;
      case 'descriptionOption':
      case 'validationOption':
        this.questionData.options[action].show = !this.questionData.options[action].show
        switch (action) {
          case 'descriptionOption':
            if (!this.questionData.options[action].show) {
              this.data.description = ''
            }
            break;
          case 'validationOption':
            if (!this.questionData.options[action].show) {
              this.questionData.question.option = null
              this.questionData.options[action].value = ''
              this.data.validation = null
            }
            else {
              if (action == 'validationOption') {
                this.data.validation = {
                  uuidoption: '',
                  value: ''
                }
              }
            }
            this.modalHelper.pollChange({
              validation: this.questionData.options[action].show
            })
            break;
        }
        break;
      case 'systemname':
        setTimeout(() => {
          this.data[action] = slugifyPipe(this.data.title, '_')
          this.onWrite('title')
        })
        break;
      case 'validation':
        setTimeout(() => {
          this.data[action] = { ...this.data[action], value: this.questionData.options.validationOption.value }
        })
        break;
      case 'copy':
        this.onAction.emit({
          module: this.module,
          action: action,
          data: {
            value: { ...clone(this.data), uuid: uuidv1(), parentuuid: this.parent.uuid },
            parent: this.indexParent,
          }
        })
        break;
      case 'delete':
        this.onAction.emit({
          module: this.module,
          action: action,
          data: {
            parent: this.indexParent,
            index: this.index
          }
        })
        break;
      case 'add':
        let question = clone(this.data)
        question.parentuuid = ""
        this.cleanQuestionToRepository(question)
        break;
    }
  }
  // --
  onActionChoices(event: any) {
    switch (event.module) {
      case 'choices':
        switch (event.action) {
          case 'add':
            this.data.choices.push(event.data)
            break;
        }
        break;
    }
  }
  // -- Validaciones de las secciones
  onWrite(type?: string) {
    setTimeout(() => {
      this.validateData.write[type] = true
      this.validateForm()
    })
  }
  // --
  validateForm(all: boolean = false) {
    let fieldsExceptions = ['description', 'order', 'required', 'active']
    let fields = ['title', 'typeuuid']
    // this.data.map((x, i) => {
    let isInvalid = false
    this.validateData.haveError = false
    this.validateData.error = []
    Object.keys(this.data).map(y => {
      let validateField = true
      if (!fieldsExceptions.includes(y)) {
        if (!all) {
          if (!fields.includes(y)) {
            validateField = false
          }
          validateField = this.validateData.write[y]
        }
        if (validateField) {
          this.validateData.field[y] = false
          if (y == 'validation') {
            if (this.data[y]) {
              let invalidValidation = false
              Object.values(this.data[y]).map((v, iv) => {
                if (!v) {
                  invalidValidation = true
                }
                if (iv == Object.values(this.data[y]).length - 1) {
                  if (invalidValidation) {
                    this.validateData.field[y] = true
                    isInvalid = true
                    this.validateData.error.push({
                      modulo: 'question',
                      field: y
                    })
                  }
                }
              })
            }
          }
          else if (y == 'title') {
            this.validateData.field['titleLength'] = false
            if (!this.data[y]) {
              this.validateData.field[y] = true
              isInvalid = true
              this.validateData.error.push({
                modulo: 'question',
                field: y
              })
            }
            else {
              let min = 5
              let max = 150
              if (this.data[y].length < min || this.data[y].length > max) {
                this.validateData.field['titleLength'] = true
                isInvalid = true
                this.validateData.error.push({
                  modulo: 'question',
                  field: y
                })
              }
            }
          }
          else if (!this.data[y]) {
            this.validateData.field[y] = true
            isInvalid = true
            this.validateData.error.push({
              modulo: 'question',
              field: y
            })
          }
        }
      }
    })
    if (isInvalid) {
      this.modalHelper.pollChange({
        module: this.module,
        invalidForm: true,
        section: this.data,
        invalid: isInvalid,
        errors: this.validateData.error,
        type: all
      })
    }
    this.modalHelper.pollChange({
      module: 'questions',
      invalidForm: true,
      section: this.parent.uuid,
      invalid: isInvalid,
      errors: this.validateData.error,
      type: all
    })
    // })
  }
  // --
  cleanQuestionToRepository(question: any) {
    if (question.choices.length) {
      question.choices.map((y, i) => {
        y.nested = []
        if (i == question.choices.length - 1) {
          this.saveRepositoryQuestion(question)
        }
      })
    }
    else {
      this.saveRepositoryQuestion(question)
    }
  }
  // --
  saveRepositoryQuestion(question: PollQuestion) {
    this.subscription.add(
      this.pollService.repositoryCreate(question)
        .subscribe(
          (rs: ResponseData) => {
            this.alertNotificationService.warn('Repositorio', 'Se guardo la pregunta en el repositorio', { autoClose: false, keepAfterRouteChange: true });
          },
          error => {
            console.error(error)
            this.alertNotificationService.error('Repositorio', 'Ocurrio un error, intente más tarde', { autoClose: true, keepAfterRouteChange: true });
          }
        )
    )
  }
}
