import { Injectable } from '@angular/core';
import { Session } from 'src/app/dto/Session';
import { Observable } from 'rxjs';
import { AuthenticationCredential } from 'src/app/dto/AuthenticationCredential';
import { BasicUserInfo } from 'src/app/dto/BasicUserInfo';
import { ResponseData } from 'src/app/dto/ResponseData';

@Injectable({
  providedIn: 'root'
})
export abstract class SessionDataService {
  abstract signIn(credential:AuthenticationCredential):Observable<ResponseData>;
  abstract getUser(credential:AuthenticationCredential):Observable<ResponseData>;
  abstract signOut(credential:AuthenticationCredential, uuid:string):Observable<ResponseData>;
}
