import { title } from "process";

export const constantes = {

    elementsIds:[], 
    newElementsIds:[],
    selectedElementsIds:[],
    usersGroupByWorkP:{
        groupId:0,
        companyId:0,
        stateId:0,
        municipalityId:0,
        workPlaceId:[],
        displayGroupId:0
    },
    dataAcces:{
        monitorToken:'',
        monitorUserId:0
    },

    ids:{
        
        idCatGV:0,
        idGrupo:0,
        companyIds:0,
        stateIds:0,
        municipalityIds:0,
        workPlaceIds:0,
        idUsers:0,
        groupIds:0,
        grupoId:0,
        idGrupoVisual:0,
        idGV:0,
        pageId:0,
        typeUserId:0,
        genero:'',
        enfermedadId:0,
        userId:0
    },
    jSONs:{
        groupsSelected:[{
        Id:0,
        name:'',
        checked:false
        }],
        filters:{},
        
    },

    pagination:{
        pageSize:10,
        total_rows:0,
        pagina:0
    },

    groupVisualization:{
        vista:'toSearch',
        activeSearch:false,
        nameGV:'',
        title:''
    },
    detallesGrupoVisualizacion:{
        idGV:0,
        idTGV:0,
        nameGV:'',
        filters:{},
    },

    
    inputs:{
        inputSearchTGV:'',
        inputSearchGroup:'',
        inputTextCompany:''
    },
    selects:{
        selectedCompany:0,
    },
    origin:'',
    title:'',
    vista:'toSearch',
    activeSearch:false,
    displayGroupId:0,
  


    secciones:{
        vista:'toSearch',
        displayGroupTypeId:0,
        sectionId:0,
        sectionName:'',
        sectionDescription:'',
        status:0,
        color:'',
        icon:'',
        pageIds:[],
        atachPagesIds:[],
        detachPagesIds:[],
        pageName:'',
        accion:'',
        pages:[]
    },
    paginas:{
        pageId:0,
        displayGroupId:0,
        inputSearchPage:'',
        vista:''
    },
    return:{
        displayGroupName:'',
        displayGroupTypeId:0,
    },

    setings:{
        baseUrl:'',
        credentials:{}
    }
    
    };
