import { Role } from './Role';

export class BasicUserInfo {
    public uuid?: any;
    public id?: any;
    public name: string;
    public lastname: string;
    public username: string;
    public email: string;
    public status?: boolean;
    public password?: string;
    public last_signing?: string;
    public image?: string;
    public roles?: Array<Role>;
}