import { Component } from '@angular/core';
import { SettingsService } from './services/settings.service';
import { Router } from '@angular/router';
import { ModalHelperService } from './services/modal-helper.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'abarcms';
  loading: boolean = false;
  errorModal: any = { title: 'Error', show: false, message: null };
  public ready: boolean = false;
  public constructor(
    public settings: SettingsService,
    private router: Router,
    private modalHelper: ModalHelperService
  ) {
    this.settings.statusObservable.subscribe((status: string) => {
      this.ready = (status == 'ready');
    });
    modalHelper.errorModal$.subscribe(data => {
      this.errorModal = data;
    });
    modalHelper.loading$.subscribe(data => {
      this.loading = data;
    });
  }
}
