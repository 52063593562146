import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseData } from 'src/app/dto/ResponseData';
import { SettingsService } from '../../settings.service';
import { ApiService } from '../../api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatementDataWsService {
  private pathInfo: any;
  private baseUrl: string;
  private segment: any;
  constructor(
    private settings: SettingsService,
    private api: ApiService
  ) {
    this.pathInfo = this.settings.get('pathInfo');
    this.segment  = this.pathInfo.res.admin.statement;
    this.baseUrl  = this.pathInfo.base.baseUrl + this.pathInfo.base.prefix;
    if((!environment.production || this.pathInfo.production) && this.segment.override) {
      this.baseUrl = this.pathInfo.base.baseUrl + this.segment.override.prefix;
      this.segment.prefix = this.segment.prefix.replace(this.pathInfo.base.apiVersion, this.segment.override.apiVersion)
    }
  }
  public create(body: object): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix;
    return this.api.post(endpoint, body);
  }
  public read(): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix;
    return this.api.get(endpoint);
  }
  private update(id: any, body: object): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.update.replace(':id', id);
    return this.api.put(endpoint, body);
  }
  private delete(id: any): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.delete.replace(':id', id);
    return this.api.delete(endpoint, id);
  }
}
