<ng-template #onlyOnePage>
    <div class="dataTables_paginate paging_simple_numbers no-more-pages" id="contenido-table_paginate" *ngIf="!!items && items.length > 0">
        <span>
            <a class="page-item number-item no-more-pages" >1</a>
        </span>
    </div>
</ng-template>
<ng-container *ngIf="!!items && items.length > paginator.itemsPerPage && totalPages > 1; else onlyOnePage">
<div class="dataTables_paginate paging_simple_numbers" id="contenido-table_paginate">
    <ng-container *ngIf="paginator.currentPage > 1">
        <a 
            class="paginate_button previous" 
            [class.disabled]="paginator.currentPage == 2"
            (click)="setPage(1)"
            aria-controls="contenido-table" 
            data-dt-idx="1" 
            tabindex="-1" 
            title="{{paginator.lblFirst}}"
            id="contenido-table_previous"
            *ngIf="totalPages > 2 && paginator.currentPage > 2"
        >
            <i class="fas fa-chevron-left"></i>
            <i class="fas fa-chevron-left"></i>
        </a>
        <a 
            class="paginate_button previous"
            aria-controls="contenido-table" 
            data-dt-idx="0" 
            tabindex="-1" 
            (click)="setPage(paginator.currentPage - 1)"
            title="{{paginator.lblPrev}}"
            id="contenido-table_previous"
            *ngIf="totalPages > 1 && paginator.currentPage > 1"
        >
            <i class="fas fa-chevron-left"></i>
        </a>
    </ng-container>
    <span>
        <ng-container *ngFor="let page of pagination">
            <a
            class="page-item number-item paginate_button" 
            [class.current]="paginator.currentPage === page" 
            [class.active]="paginator.currentPage === page" 
            (click)="setPage(page)"
            *ngIf="page !== '...'; else ellipsis"
            >{{page}}</a>
        </ng-container>
        <ng-template #ellipsis>
            <span class="ellipsis">…</span>
        </ng-template>
    </span>

    <ng-container *ngIf="totalPages > paginator.currentPage">
        <a class="paginate_button next" 
            [class.disabled]="paginator.currentPage === totalPages" 
            aria-controls="contenido-table" 
            data-dt-idx="paginator.currentPage - 1" 
            tabindex="0" 
            id="contenido-table_next"
            (click)="setPage(paginator.currentPage + 1)"
            title="{{paginator.lblNext}}"
            *ngIf="totalPages > paginator.currentPage"
        >
            <i class="fas fa-chevron-right"></i>
        </a>
        <a 
            *ngIf="(totalPages - 1) > paginator.currentPage"
            class="paginate_button next" 
            [class.disabled]="paginator.currentPage === totalPages"
            aria-controls="contenido-table" 
            data-dt-idx="7" 
            tabindex="0" 
            (click)="setPage(totalPages)"
            title="{{paginator.lblLast}}"
            id="contenido-table_next"
        >
            <i class="fas fa-chevron-right"></i>
            <i class="fas fa-chevron-right"></i>
        </a>
    </ng-container>
</div>
</ng-container>