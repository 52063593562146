import { Injectable } from '@angular/core';
import { UserDataService } from '../user-data.service';
import { of, Observable, Subject } from 'rxjs';
import { BasicUserInfo } from 'src/app/dto/BasicUserInfo';
import { delay } from 'rxjs/operators';
import { SettingsService } from '../../settings.service';
import { HttpClient } from '@angular/common/http';
import { makeHeaders, createResponseData } from 'src/app/utils/_http';
import { ResponseData } from 'src/app/dto/ResponseData';
import { ModalHelperService } from '../../modal-helper.service';

@Injectable({
  providedIn: 'root'
})
export class UserDataWsService implements UserDataService {
  pathInfo: any;
  constructor(
    private settings: SettingsService,
    private http: HttpClient,
    private modalHelper: ModalHelperService
  ) {
    this.pathInfo = this.settings.get('pathInfo');
  }
  // public createUserProfile(body: any, accessToken: string): Observable<ResponseData> {
  //   this.modalHelper.changeLoading(true);
  //   let options = {};
  //   if (accessToken) {
  //     options = { 'X-Auth-Token': accessToken };
  //   }
  //   let obs: Subject<ResponseData> = new Subject<ResponseData>();
  //   this.http.post<ResponseData>(this.pathInfo.base.baseUrl + this.pathInfo.base.prefix + this.pathInfo.res.admin.user.prefix, body, makeHeaders(options))
  //     .subscribe((response: ResponseData) => {
  //       obs.next(createResponseData(response));
  //     })
  //   return obs;
  // }
  public createUser(body: any, accessToken: string): Observable<ResponseData> {
    this.modalHelper.changeLoading(true);
    let options = {};
    if (accessToken) {
      options = { 'X-Auth-Token': accessToken };
    }
    let obs: Subject<ResponseData> = new Subject<ResponseData>();
    this.http.post<ResponseData>(this.pathInfo.base.baseUrl + this.pathInfo.base.prefix + this.pathInfo.res.public.access.prefix + this.pathInfo.res.public.access.resources.register, body, makeHeaders(options))
      .subscribe((response: ResponseData) => {
        obs.next(createResponseData(response));
      })
    return obs;
  }
  public createPassword(body: any): Observable<ResponseData> {
    this.modalHelper.changeLoading(true);
    let options = {};
    let obs: Subject<ResponseData> = new Subject<ResponseData>();
    this.http.post<ResponseData>(this.pathInfo.base.baseUrl + this.pathInfo.base.prefix + this.pathInfo.res.public.access.prefix + this.pathInfo.res.public.access.resources.registerPassword, body, makeHeaders(options))
      .subscribe((response: ResponseData) => {
        obs.next(createResponseData(response));
      })
    return obs;
  }
  public startPasswordRecovery(body: any): Observable<ResponseData> {
    this.modalHelper.changeLoading(true);
    let options = {};
    let endpoint = this.pathInfo.base.baseUrl + "/recoveryPassword";
    let obs: Subject<ResponseData> = new Subject<ResponseData>();
    //this.http.post<ResponseData>(this.pathInfo.base.baseUrl + this.pathInfo.base.prefix + this.pathInfo.res.public.access.prefix + this.pathInfo.res.public.access.resources.passwordRecovery, body, makeHeaders(options))
    this.http.post<ResponseData>(endpoint, JSON.stringify(body), makeHeaders(options))
      .subscribe((response: ResponseData) => {
        obs.next(createResponseData(response));
      });
    return obs;
  }
  public updateUser(body: any, accessToken: string): Observable<ResponseData> {
    this.modalHelper.changeLoading(true);
    let options = {};
    if (accessToken) {
      options = { 'X-Auth-Token': accessToken };
    }
    let obs: Subject<ResponseData> = new Subject<ResponseData>();
    this.http.put<ResponseData>(this.pathInfo.base.baseUrl + this.pathInfo.base.prefix + this.pathInfo.res.admin.profile.prefix, body, makeHeaders(options))
      .subscribe((response: ResponseData) => {
        obs.next(createResponseData(response));
      })
    return obs;
  }
  // public deleteUser(username: string, accessToken: string): Observable<ResponseData> {
  //   this.modalHelper.changeLoading(true);
  //   return of().pipe(delay(Math.random() * 3000));
  // }
  // public updateUserPassword(body: any, accessToken: string): Observable<ResponseData> {
  //   this.modalHelper.changeLoading(true);
  //   let options = {};
  //   if (accessToken) {
  //     options = { 'X-Auth-Token': accessToken };
  //   }
  //   let obs: Subject<ResponseData> = new Subject<ResponseData>();
  //   this.http.put<ResponseData>(this.pathInfo.base.baseUrl + this.pathInfo.base.prefix + this.pathInfo.res.public.prefix + this.pathInfo.public.user.prefix + this.pathInfo.public.user.profile, body, makeHeaders(options))
  //     .subscribe((response: ResponseData) => {
  //       obs.next(createResponseData(response));
  //     })
  //   return obs;
  // }
}
