import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseData } from 'src/app/dto/ResponseData';

@Injectable({
  providedIn: 'root'
})
export abstract class EmailDataService {
  abstract sendEmail(body: any): Observable<ResponseData>;
}
