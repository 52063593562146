import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { PollQuestion, PollSection, PollTypeQuestionOption } from 'src/app/dto/Poll';
import { PollDataService } from 'src/app/services/data/poll-data.service';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { getArray, getObject } from 'src/app/utils/_http';
import { clone } from 'src/app/utils/_utils';
import { v1 as uuidv1 } from 'uuid'

@Component({
  selector: 'app-polls-questions',
  templateUrl: './polls-questions.component.html',
  styleUrls: ['./polls-questions.component.css']
})
export class PollsQuestionsComponent implements OnInit, OnDestroy, OnChanges {
  subscription: Subscription = new Subscription
  @Input() data: PollSection[] = []
  @Input() mode: string = 'questions'
  @Input() action: string | null = null
  @Input() isFormPublicate: boolean = false
  @Input() pollTypeQuestions: PollTypeQuestionOption[]
  @Output() onAction: EventEmitter<any> = new EventEmitter

  formSection: FormGroup[] = []
  module: string = 'questions'
  questionData: {
    showRepository: any,
    showComponent: boolean,
  } = {
      showRepository: -1,
      showComponent: false,
    }
  questionsRepository: any[] = []

  validateData: {
    haveError: boolean[],
    write: any[],
    field: any[],
    error: any[],
  } = {
      haveError: [],
      write: [],
      field: [],
      error: [],
    }

  constructor(
    private formBuilder: FormBuilder,
    private modalHelper: ModalHelperService,
    private pollService: PollDataService,
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.modalHelper.poll$
        .subscribe(
          value => {
            if (value?.module) {
              if (value?.module == 'questions') {
                if (value?.invalidForm) {
                  // this.data.map((x, i) => {
                  //   if (x.uuid == value.section) {
                  this.validateData.haveError[value.index] = value.invalid
                  this.validateData.error[value.index] = value.errors
                  if (value.type) {
                    this.modalHelper.pollChange({
                      module: 'validFormResponse',
                      invalid: value.invalid,
                      errors: this.validateData.error[value.index]
                    })
                  }
                  //   }
                  // })
                }
              }
              if (value?.module == 'validForm') {
                this.validateForm(true)
              }
            }
          }
        )
    )
  }
  // --
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
  // --
  ngOnChanges(changes: SimpleChanges) {
    if (changes?.data) {
      if (changes?.data.currentValue.length) {
        this.setForms()
        this.getQuestions()
      }
    }
  }
  // --
  setForms() {
    this.data.map((x: PollSection, i) => {
      this.formSection[i] = this.formBuilder.group({
        uuid: [x.uuid, [Validators.required]],
        polluuid: [x.polluuid, [Validators.required]],
        active: [x.active, [Validators.required]],
        title: [x.title, [Validators.required]],
        systemname: [x.systemname, [Validators.required]],
        description: [x.description, [Validators.required]],
        order: [x.order, [Validators.required]],
        questions: [x.questions, [Validators.required]],
      })
      if (!this.validateData.haveError[i]) {
        this.validateData.haveError.push(false)
        this.validateData.write.push({
          description: false,
          title: false,
        })

        this.validateData.write[i] = {}
        this.validateData.field[i] = {}
        Object.keys(x).map(y => {
          this.validateData.write[i][y] = false
          this.validateData.field[i][y] = false
        })
      }
    })
  }
  // --
  drop(event: CdkDragDrop<string[]>, index: any) {
    moveItemInArray(this.data[index].questions, event.previousIndex, event.currentIndex);
  }
  // --
  onActionQuestion(event: any, indexParent?: any, indexChild?: any) {
    if (event?.module) {
      switch (event.module) {
        case 'question':
          switch (event.action) {
            case 'copy':
              let question = clone(event.data.value)
              question.choices.map(x => {
                x.parentuuid = question.uuid
                x.uuid = uuidv1()
                x.nested = []
              })
              this.data[event.data.parent].questions.push(new PollQuestion(question))
              this.getQuestions()
              break;
            case 'delete':
              this.searchQuestionToDeleteOnNested(event)
              // if (this.action == 'create') {
              this.data[event.data.parent].questions.splice(event.data.index, 1)
              // }
              // else {
              //   this.data[event.data.parent].questions[event.data.index].active = 0
              //   this.data[event.data.parent].questions[event.data.index].order = -1
              //   this.data[event.data.parent].questions[event.data.index].systemname = this.data[event.data.parent].questions[event.data.index].systemname + '_delete'
              // }
              if (!this.data[event.data.parent].questions.filter(x => x.active).length) {
                this.onActionQuestion('add', event.data.parent)
              }
              else {
                this.getQuestions()
              }
              break;
          }
          break;
      }
    }
    else {
      switch (event) {
        case 'add':
          this.data[indexParent].questions.push(new PollQuestion({ parentuuid: this.data[indexParent].uuid }))
          this.getQuestions()
          break;
        case 'add-repository':
          if (indexParent >= 0) {
            this.questionData.showRepository = indexParent
            this.getRepositoryQuestions()
          }
          else {
            this.questionData.showRepository = -1
          }
          break;
        case 'copy':
          this.getRepositoryQuestions(indexParent)
          break;
      }
    }
  }
  // --
  getQuestions() {
    setTimeout(() => {
      this.modalHelper.pollChange({
        questions: true
      })
    }, 500)
  }
  // -- Validaciones de las secciones
  onWrite(index: number, type?: string) {
    setTimeout(() => {
      this.validateData.write[index][type] = true
      this.validateForm()
    })
  }
  // --
  validateForm(all: boolean = false) {
    let fieldsExceptions = ['description', 'order', 'active']
    let fields = ['title']
    this.data.map((x, i) => {
      this.validateData.haveError[i] = false
      this.validateData.error[i] = null
      let isInvalid = false
      Object.keys(x).map(y => {
        let validateField = true
        if (!fieldsExceptions.includes(y)) {
          if (!all) {
            if (!fields.includes(y)) {
              validateField = false
            }
            validateField = this.validateData.write[i][y]
          }
          if (validateField) {
            this.validateData.field[i][y] = false
            if (!x[y]) {
              this.validateData.field[i][y] = true
              isInvalid = true
              if (!this.validateData.error[i]) {
                this.validateData.error[i] = []
              }
              this.validateData.error[i].push({
                modulo: 'Section',
                field: y
              })
            }
            if (y == 'questions') {
              let error = false
              x[y].map((z, zi) => {
                let typeOption = this.pollTypeQuestions.find(xx => xx.uuid == z.typeuuid)
                if (typeOption.systemname != 'abierta') {
                  if (z.choices.length < 2) {
                    error = true
                  }
                }
                if (zi == x[y].length - 1) {
                  if (error) {
                    this.validateData.field[i][y] = true
                    isInvalid = true
                    if (!this.validateData.error[i]) {
                      this.validateData.error[i] = []
                    }
                    this.validateData.error[i].push({
                      modulo: 'Section',
                      field: `${y} | Choices options`
                    })
                  }
                }
              })
            }
          }
        }
      })
      // if (isInvalid) {
      this.modalHelper.pollChange({
        module: this.module,
        invalidForm: true,
        section: this.data[i].uuid,
        invalid: isInvalid,
        errors: this.validateData.error[i],
        type: all,
        index: i,
      })
      // }
    })
  }
  // --
  searchText: string = ''
  paginator: {
    current: number,
    perPage: number,
    total: number
  } = {
      current: 1,
      perPage: 10,
      total: 0,
    }
  init: boolean = true
  getRepositoryQuestions(item?: any, index?: any) {
    if (!item) {
      this.subscription.add(
        this.pollService.repositoryRead(this.paginator)
          .subscribe(
            rs => {
              this.questionsRepository = getArray(rs).filter(x => x.active)
            }
          )
      )
    }
    else {
      this.subscription.add(
        this.pollService.repositoryReadOne(item.uuid)
          .subscribe(
            rs => {
              let { uuid, ...data } = getObject(rs)
              let question: PollQuestion = new PollQuestion(data)
              question.choices.map(x => {
                x.nested = []
              })
              this.data[this.questionData.showRepository].questions.push(question)
              this.questionData.showRepository = -1
              this.getQuestions()
            }
          )
      )
    }
  }
  // --
  searchQuestionToDeleteOnNested(event: any) {
    let questionString = JSON.stringify(this.data[event.data.parent].questions[event.data.index])
    this.data[event.data.parent].questions.map((x, i) => {
      if (i != event.data.index) {
        x.choices.map(y => {
          let questions = []
          y.nested.map((z, zi) => {
            if (questionString != JSON.stringify(z)) {
              questions.push(z)
            }
            if (zi == y.nested.length - 1) {
              y.nested = questions
            }
          })
        })
      }
    })
  }
}
