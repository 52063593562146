import { Injectable } from '@angular/core';
import { BasicUserInfo } from 'src/app/dto/BasicUserInfo';
import { Observable } from 'rxjs';
import { ResponseData } from 'src/app/dto/ResponseData';

@Injectable({
  providedIn: 'root'
})
export abstract class PaginaDataService {
  
  /*abstract createUser(user: any, accessToken: string): Observable<ResponseData>;
  abstract createPassword(rq: any): Observable<ResponseData>;
  abstract startPasswordRecovery(email: any): Observable<ResponseData>;
  abstract updateUser(user: any, accessToken: string): Observable<ResponseData>;*/

  abstract createPage(): Observable<ResponseData>;
}
