import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseData } from 'src/app/dto/ResponseData';
import { SettingsService } from '../../settings.service';
import { ApiService } from '../../api.service';
import { constants } from '../../../constants';
import { http_build_query } from 'src/app/utils/_utils';

@Injectable({
  providedIn: 'root'
})
export class ContentsDataWsService {

    private pathInfo: any;
    private baseUrl: string;
    private segment: any;
    private readonly methods: any = constants.screens.contents;

    constructor(
        private settings: SettingsService,
        private api: ApiService
    ) {
        this.pathInfo = this.settings.get('pathInfo');
        this.baseUrl  = this.pathInfo.base.baseUrl + this.pathInfo.base.prefix;
        this.segment  = this.pathInfo.res.admin.contents;
    }

    private create = (body: object, id?: string, action?: string): Observable<ResponseData> =>
        this.api.post(this.endpointQuotation(id, action), body)

    private delete =
        (id: string, contentType: string): Observable<ResponseData> =>
            this.api.delete(this.endpointQuotation(id, this.methods.delete, contentType), id)

    private endpointQuotation = (id?: string, method?: string, contentTypeId?: string, languageId?: string): string => {
        switch (method) {
            case this.methods.edit:
            case this.methods.read:
            case this.methods.lock:
            case this.methods.unlock:
            case this.methods.edit:
            case this.methods.delete: {
                if (this.segment.resources[method].indexOf(':contentId') !== -1) {
                    const uriFormed = this.segment.resources[method]
                        .replace(':contentId', id)
                        .replace(':contentType', contentTypeId ? contentTypeId : '');
                    return `${this.baseUrl}${this.segment.prefix}${uriFormed}`;
                }
                return `${this.baseUrl}${this.segment.prefix}${this.segment.resources[method]}`;
            }
            case this.methods.getOne: {
                const lang = languageId ? languageId : '';
                let endpoint = `${this.baseUrl}${this.segment.prefix}`;
                endpoint += `${this.segment.resources[method].replace(':contentTypeId', contentTypeId)}`;
                endpoint = `${endpoint.replace(':contentId', id)}`;
                if (endpoint.indexOf('/:langId') !== -1) {
                    endpoint = `${endpoint.replace('/:langId', '')}`;
                }
                return endpoint;
            }
            case this.methods.bulk:
            case this.methods.clone: {
                return `${this.baseUrl}${this.segment.prefix}${this.segment.resources[method]}`;
            }
            default: return `${this.baseUrl}${this.segment.prefix}`;
        }
    }

    private read = (filters?: any, headers?: any): Observable<ResponseData> =>
        this.api.get(`${this.endpointQuotation()}${!!filters ? '?' + http_build_query(filters) : ''}`, headers)

    private readOne = (id: string, contentTypeId: string, languageId?: string): Observable<ResponseData> => {
        if (languageId) {
            return this.api.get(this.endpointQuotation(id, this.methods.getOne, contentTypeId, languageId), { language_id: languageId });
        }
        return this.api.get(this.endpointQuotation(id, this.methods.getOne, contentTypeId, languageId));
    }

    private update = (body: object, id?: string, action?: string): Observable<ResponseData> =>
        this.api.put(this.endpointQuotation(id, this.methods[action]), body)

    private bulk = (body: object): Observable<ResponseData> =>
        this.api.put(this.endpointQuotation(undefined, this.methods.bulk), body)

    private clone = (body: object): Observable<ResponseData> =>
        this.api.post(this.endpointQuotation(undefined, this.methods.clone), body)
}
