import { Injectable } from '@angular/core';
import { ResponseData, Log } from 'src/app/dto';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export abstract class LogsDataService {
  abstract create(module, action, field): Observable<ResponseData>;
  abstract createServices(body): Observable<ResponseData>;
}
