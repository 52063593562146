import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseData } from 'src/app/dto/ResponseData';

@Injectable({
  providedIn: 'root'
})
export abstract class UsersDataService {
  abstract create(body: any, accessToken: string): Observable<ResponseData>;
  abstract read(accessToken: string, page: number, itemsPerPage: number): Observable<ResponseData>;
  abstract readOne(uuid: any, accessToken: string): Observable<ResponseData>;
  abstract update(uuid: any, body: any, accessToken: string): Observable<ResponseData>;
  abstract delete(uuid: string, accessToken?: string): Observable<ResponseData>;
  abstract deleteBusiness(body: any, accessToken: string): Observable<ResponseData>;
}
