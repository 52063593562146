import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpInterceptor,
  HttpHeaders,
  HttpResponseBase,
  HttpResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, map, retry } from "rxjs/operators";
import { ModalHelperService } from "./modal-helper.service";
import { SettingsService } from "./settings.service";
import { Injectable } from "@angular/core";
import { SessionService } from "./session.service";
import { handleError } from "../utils/_http";
import { Router } from "@angular/router";
import { removeStorage, removeStorageAll } from "../utils/_utils";
import { constants } from "../constants";
import { LogsDataService } from "./data/logs-data.service";
import { SeccionMonitorService } from "../screens/admin/seccion-monitor/services/seccion-monitor.service";
import { HttpMonitorService } from "../screens/admin/seccion-monitor/services/http-monitor.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private modalHelper: ModalHelperService,
    private settingsService: SettingsService,
    protected session: SessionService,
    protected router: Router,
    private logsService: LogsDataService,
    private seccionesMonitorService: SeccionMonitorService,
    private monitoreoService: HttpMonitorService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.headers.get("modal")) {
      request = request.clone({
        headers: request.headers.delete("modal"),
      });
    }

    if (request.headers.get("services")) {
      request = request.clone({
        headers: request.headers.delete("services"),
      });
      return next.handle(request);
    } else {
      return next.handle(request).pipe(
        map((rs) => {
          if (rs instanceof HttpResponse) {
            let body = {
              url: request.url,
              method: request.method,
              bodyRequest: request,
              bodyResponse: rs,
            };
            this.logsService.createServices(body).subscribe(
              (rsLogs) => {},
              (error) => {}
            );
          }
          return rs;
        }),
        catchError((error: HttpErrorResponse) => {
          console.log("Error http Status " + error.status);
          if (error.status == 401) {
            this.session.accessToken = null;
            this.session.user = null;
            removeStorageAll();
            this.router.navigate([`/${constants.screens.signIn.path}`]);
          } else {
            if (/*error.status==404 || */ error.status == 440) {
              this.monitoreoService.getAccesToken().subscribe((result) => {
                sessionStorage.setItem("monitorToken", result.token);
                sessionStorage.setItem("monitorUserId", result.user_id);
              });
              let er = {
                title: "AVISO",
                message:
                  "Las credenciales de acceso han cambiado por favor ejecuta nuevamente tu consulta",
                show: true,
              };
              this.modalHelper.changeErrorModal(er);
            } else {
              handleError(error, {
                modalHelper: this.modalHelper,
                settings: this.settingsService,
              });
            }
            return throwError(error);
          }
        })
      );
    }
  }
}
