import { Component, OnInit, Input } from '@angular/core';
import { DashboardWidget } from 'src/app/dto/DashboardWidget';

@Component({
  selector: 'abarcms-dashboard-row',
  templateUrl: './dashboard-row.component.html',
  styleUrls: ['./dashboard-row.component.css']
})
export class DashboardRowComponent implements OnInit {

  @Input('data')
  public data=[];

  constructor() { }

  ngOnInit() {
  }

}
