<div style="position: fixed;right: 15px; bottom: 15px;">
    <div *ngFor="let alert of alerts" class="toast" role="alert" aria-live="assertive" aria-atomic="true" style="opacity: 1;z-index: 1051;" [class]="color">
        <div class="toast-header" style="opacity: 1" [class]="color">
            <svg class="bd-placeholder-img rounded mr-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMidYMid slice" focusable="false" role="img" [ngSwitch]="cssClass(alert)">
                <rect width="100%" height="100%" fill="#28a745" *ngSwitchCase="'Success'"></rect>
                <rect width="100%" height="100%" fill="#dc3545" *ngSwitchCase="'Error'"></rect>
                <rect width="100%" height="100%" fill="#17a2b8" *ngSwitchCase="'Info'"></rect>
                <rect width="100%" height="100%" fill="#ffc107" *ngSwitchCase="'Warning'"></rect>
            </svg>
            <strong class="mr-auto">{{alert.title}}</strong>
            <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close"
                (click)="close(alert)">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="toast-body" [innerHTML]="alert.message"></div>
    </div>
</div>