<ng-container>
    <div class="row">
        <div class="col-12 label-poll">
            <div class="card " style="min-height: 380px;">
                <div class="card-body">
                    <div class="form-group">
                        <label class="title" for="nombreEncuesta">Pestañas de la encuesta</label>
                        <div class="container-labels-sections" cdkDropList (cdkDropListDropped)="drop($event)">
                            <ng-container *ngFor="let item of data; index as key">
                                <ng-container *ngIf="item.active">
                                    <div class="row d-flex custom-field" cdkDrag>
                                        <div class="custom-placeholder" *cdkDragPlaceholder></div>

                                        <button type="button" cdkDragHandle placement="right"
                                            ngbTooltip="Arrastra el bloque a la posición deseada"
                                            class="btn btn-link mb-0" [ngClass]="{disabled: isFormPublicate}">
                                            <span class="fas fa-bars" *ngIf="!isFormPublicate"></span>
                                        </button>

                                        <input type="text" class="form-control nombrePagina" [(ngModel)]="item.title"
                                            [ngClass]="{'is-invalid invalidFieldForm': (notValidTitle[key] && write[key]) || (haveErrorSystemname[key] || haveErrorSystemnameLength[key])}"
                                            [ngStyle]="((notValidTitle[key] && write[key]) || (haveErrorSystemname[key] || haveErrorSystemnameLength[key])) && {'border-color': 'red','margin-bottom': '3px'}"
                                            (blur)="onWrite(key)" (keydown)="onWrite(key)" [readonly]="isFormPublicate">

                                        <ng-container *ngIf="!isFormPublicate">
                                            <button type="button" *ngIf="sectionsActive().length > 1" placement="left"
                                                ngbTooltip="Borrar" class="btn btn-link mb-0"
                                                [ngClass]="{'d-none': false}" (click)="onSection('delete', key)">
                                                <span class="fas fa-trash"></span>
                                            </button>
                                        </ng-container>

                                    </div>
                                    <div class="invalidFieldForm"
                                        style="color: red;font-size: 13px;padding-left: 20px;">
                                        <ng-container *ngIf="notValidTitle[key] && write[key]">
                                            <div class="mb-3">
                                                Este campo es requerido
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="haveErrorSystemname[key]">
                                            <div class="mb-3">
                                                El nombre de la seccion ya existe, debes modificar el nombre
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="haveErrorSystemnameLength[key]">
                                            <div class="mb-3">
                                                Debe contener mínimo de 5 hasta 150 caracteres
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <div class="alert alert-danger" *ngIf="!sectionsActive().length && haveError">
                                Debe agregar al menos 1 sección
                            </div>
                        </div>
                    </div>

                    <ng-container *ngIf="!isFormPublicate">
                        <div class="row">
                            <div class="col-8">
                                <a (click)="onSection('add')" class="link">
                                    Agregar pestaña
                                </a>
                            </div>
                            <div class="col-4">
                                <button type="button" class="btn btn-primary btn-block" [style.max-height]="'auto'"
                                    (click)="onSection('save')">
                                    Guardar pestañas
                                </button>
                            </div>
                        </div>
                    </ng-container>

                </div>
            </div>
        </div>
    </div>
</ng-container>