import { SeccionesComponent } from "./secciones/secciones.component";
import { PaginasComponent } from "./paginas/paginas.component";
import { GrupoVisualizacionComponent } from "./grupo-visualizacion/grupo-visualizacion.component";
import { BotonComponent } from "./boton/boton.component";
import { CatgrupovisualizacionComponent } from "./catgrupovisualizacion/catgrupovisualizacion.component";
import { DetailsDialogComponent } from "./details-dialog/details-dialog.component";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { TableComponent } from "./table/table.component";

export const components = [SeccionesComponent,PaginasComponent,GrupoVisualizacionComponent,BotonComponent,CatgrupovisualizacionComponent,DetailsDialogComponent,ConfirmDialogComponent,TableComponent]

export * from "./secciones/secciones.component";
export * from "./paginas/paginas.component";
export * from "./grupo-visualizacion/grupo-visualizacion.component";
export * from "./boton/boton.component";
export * from "./catgrupovisualizacion/catgrupovisualizacion.component";
export * from "./details-dialog/details-dialog.component";
export * from "./confirm-dialog/confirm-dialog.component";
export * from "./table/table.component";