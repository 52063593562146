import { Pipe, PipeTransform } from '@angular/core';
import { SettingsService } from '../services/settings.service';

@Pipe({
  name: 'files'
})
export class FilesPipe implements PipeTransform {

  constructor(
    private settingsService: SettingsService,
  ) { }

  transform(value: string): string {
    let pathInfo: any = this.settingsService.get('pathInfo');
    if (pathInfo.hasOwnProperty('files') && value.indexOf(';base64,') == -1) {
      return `${pathInfo.files}/${value.split('/').pop()}`;
    }
    else {
      return value;
    }
  }

}
