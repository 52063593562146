import { Component, OnInit } from '@angular/core';
import { ModalHelperService } from 'src/app/services/modal-helper.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

  constructor(
    private modalHelper: ModalHelperService
  ) { }

  ngOnInit(): void {
    this.modalHelper.changeLoading(false);
  }

}
