<div class="user-group-list-component dropdown">
    <div class="container-lables">
        <p class="title" *ngIf="showName">Grupo N</p>
        <span class="number-of-companies">{{data?.length}} {{ business ? 'empresas' : 'grupos' }}</span>
    </div>
    <div class="container-buttons">
        <button class="btn button-compaines dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <span class="fas fa-angle-down"></span>
        </button>
        <div class="dropdown-menu items-box-drop" aria-labelledby="dropdownMenuButton">
            <div class="items-box">
                <ul class="items-list">
                    <li class="item" *ngFor="let empresa of data;">
                        <ng-container *ngIf="existPermission()">
                            <span (click)="clickOn(empresa)">{{empresa.name}}</span>
                        </ng-container>
                        <ng-container *ngIf="!existPermission()">
                            <span>{{empresa.name}}</span>
                        </ng-container>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>