import { Time } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {

  @Input() public title: string;
  @Input() public timeView: number;
  @Input() public image: boolean = false;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    if(!this.timeView){
      this.timeView = 2000;
    }
    setTimeout(() => {
      this.close();
    }, this.timeView)
  }
  
  public close(){
    this.activeModal.close('success');
  }

}
