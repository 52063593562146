import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { VerPaginaService } from "../../services/ver-pagina.service";
import { constantes } from "../../constants/seccion-monitor";
import { SeccionMonitorService } from "../../services/seccion-monitor.service";
import { CrearPaginaService } from "../../services/crear-pagina.service";
import { Page } from "../../dto/secciones-monitorDTO";

@Component({
  selector: "app-ver-pagina",
  templateUrl: "./ver-pagina.component.html",
  styleUrls: ["./ver-pagina.component.css"],
})
export class VerPaginaComponent implements OnInit {
  title: string;
  loading: boolean;
  pageSize = constantes.pagination.pageSize;
  i: number = 1;
  desde: number = 0;
  hasta: number = constantes.pagination.pageSize;
  total_rows: number = 0;
  catGruposSelected: number;
  idUsuario: string;
  token: string;
  dto: string;
  paginas: Page[] = [];
  buscaTxt: string;
  isBuscar: string = "0";
  origin: string;
  vista: string;
  pageSelected: any;
  page: number = 1;
  miVariable: string = "Hola";

  inputSearchPage: string = "";
  activeSearch: boolean = false;

  clicks: number = 0;

  constructor(
    private crearPaginaService: CrearPaginaService,
    private router: Router,
    private verPaginaService: VerPaginaService,
    private seccionesMonitorService: SeccionMonitorService
  ) {
    this.vista =
      constantes.vista == "toSearch" ? "toSearchPage" : constantes.vista;
    this.activeSearch = constantes.activeSearch;
    if (constantes.title == "") {
      constantes.title = "Consultar páginas";
    }
  }

  ngOnInit(): void {
    this.origin = constantes.origin;
    this.title = constantes.title;
    if (sessionStorage.getItem("secciones") == "true") {
      this.vista = "toSearchPage";
      this.buscar();
    }
  }

  crearPagina(): void {
    constantes.detallesGrupoVisualizacion.nameGV = "";
    constantes.detallesGrupoVisualizacion.idTGV = 0;
    constantes.detallesGrupoVisualizacion.idGV = 0;
    constantes.title = "Crear páginas";
    sessionStorage.setItem("paginas", "true");
    this.router.navigate(["/seccionesMonitor/crearPagina"]);
  }

  buscar() {
    if (this.vista == "toSearchPage") {
      this.clicks = this.clicks + 1;
      this.activeSearch = true;
      constantes.activeSearch = true;
      constantes.vista = this.vista;
      constantes.paginas.inputSearchPage = this.buscaTxt;
    }
  }

  async guardarAsignacion(): Promise<void> {
    this.loading = true;

    let data = {
      paginas: constantes.secciones.atachPagesIds,
    };
    let detachPagesIdsJson = {
      pageIds: constantes.secciones.detachPagesIds,
    };
    console.log(data);
    console.log(detachPagesIdsJson);
    (
      await this.seccionesMonitorService.attachPagesTosection(
        data,
        constantes.secciones.sectionId
      )
    ).subscribe(
      async (result) => {
        console.log(result);
        (
          await this.seccionesMonitorService.detachPagesPagesTosection(
            detachPagesIdsJson,
            constantes.secciones.sectionId
          )
        ).subscribe(
          (detachResult) => {
            console.log(detachResult);
            this.loading = false;
            sessionStorage.removeItem("secciones");
            sessionStorage.removeItem("paginas");
            sessionStorage.removeItem("grupoVisualizacion");
            constantes.paginas.inputSearchPage = "";
            constantes.secciones.pageIds = [];
            constantes.secciones.atachPagesIds = [];
            constantes.secciones.detachPagesIds = [];
            this.resetData();
            this.router.navigate(["/seccionesMonitor/consultarSecciones"]);
          },
          (error) => {
            this.loading = false;
            console.log(error);
          }
        );

        //this.resetData();
      },
      (error) => {
        this.loading = false;
        console.log(error);
      }
    );
  }

  async getOnePage() {
    (await this.crearPaginaService.getOnePage(this.pageSelected)).subscribe(
      (result) => {
        console.log("resultado getOnePage ");
        console.log(result.data);
        let paginas = result.data;
        if (paginas.displayGroup != null) {
          constantes.groupVisualization.nameGV = paginas.displayGroup.name;
          constantes.ids.idCatGV = paginas.displayGroup.displayGroupTypeId;
          constantes.ids.idGV = paginas.displayGroup.displayGroupId;
        }
        constantes.paginas.pageId = result.data.pageId;
        this.router.navigate(["/seccionesMonitor/grupoVisualizacion"]);
        constantes.groupVisualization.title =
          "Asignación de grupo de visualización";
        constantes.origin = "searchPage";
        constantes.groupVisualization.vista = "toSearch";
      }
    );
  }

  cancelar(): void {
    sessionStorage.removeItem("secciones");
    sessionStorage.removeItem("paginas");
    sessionStorage.removeItem("grupoVisualizacion");
    constantes.paginas.inputSearchPage = "";
    constantes.secciones.pageIds = [];
    constantes.secciones.detachPagesIds = [];
    constantes.secciones.atachPagesIds = [];
    this.resetData();
    this.router.navigate(["/seccionesMonitor/consultarSecciones"]);
  }

  resetData(): void {
    constantes.secciones.pageIds = [];
    constantes.secciones.pageName = "";
    constantes.secciones.vista = "toSearch";
    constantes.secciones.sectionId = 0;
    constantes.secciones.accion = "";
    constantes.origin = "";
  }
}
