import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BasicUserInfo } from 'src/app/dto';
import { SessionService, SettingsService } from 'src/app/services';
import { RoleDataService } from 'src/app/services/data/role-data.service';
import { UsersDataService } from 'src/app/services/data/users-data.service';
import { ModalHelperService } from 'src/app/services/modal-helper.service';


@Component({
    selector: 'app-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {

    @Input('title')
    public title: string;
    @Input('item')
    public item: any;
    @Input('message') public message: string;

    users: Array<BasicUserInfo> = [];

    constructor(
        private session: SessionService,
        public settings: SettingsService,
        private router: Router,
        private modalHelper: ModalHelperService,
        public activeModal: NgbActiveModal,
        public usersDataService: UsersDataService,
        public roleDataService: RoleDataService
    ) { }

    ngOnInit(): void {
    }

    onConfirm() {
        this.activeModal.close('CONFIRMED');
    }    

}
