import { Component, Input, Output, OnInit, EventEmitter, ChangeDetectorRef, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { labels } from 'src/app/strings';

@Component({
  selector: 'abarcms-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.css']
})
export class MultiselectComponent implements OnInit, OnChanges {
  public _items: Array<any> = [];
  public _selectedItems: Array<any> = [];
  public isOpen: boolean = false;
  public filterText: string = ""
  public filterInput = new FormControl();
  private interval: any;
  private timer: any;
  private count: number = 0;
  private itemsSelected = [];
  public _title: string = "";
  @Input() fieldToLabel: Array<string> = [];
  @Input() fieldToValue: string = null;
  @Input() items: Array<any> = [];
  @Input() values: Array<any> = [];
  @Input() title: string = "";
  @Input() filterPlaceholder: string = 'Filtrar...';
  @Input() multiselect: boolean = false;
  @Input() required: boolean = false;
  _disabled: boolean = false;
  @Input()
  set disabled(data: boolean) {
    this._disabled = data || false;
  }
  @Input() enableFilter: boolean = false;
  @Output() getDropdownSelected = new EventEmitter<Array<any>>();
  @ViewChild('filter') filter: ElementRef;
  @ViewChild('header') header: ElementRef;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    this.setTitle(this.itemsSelected);
    this.filterInput
      .valueChanges
      .subscribe(term => {
        this.filterText = term;
        this.changeDetectorRef.markForCheck();
        this._items.map((item, key) => {
          if (item.label.toLowerCase().indexOf(term.toLowerCase()) !== -1) this._items[key].show = true;
          else this._items[key].show = false;
        })
      });
  }

  ngOnInit() {
    this.count = 0;
    this.initInterval();
  }
  ngOnChanges(changes: SimpleChanges) {
    // if (changes.values !== undefined && changes.values.currentValue !== undefined && changes.values.previousValue !== undefined) {
    //   if (changes.values.currentValue !== changes.values.previousValue) {
    //     this._items.map((item, key) => {
    //       if (changes.values.currentValue.length) {
    //         if (changes.values.currentValue.includes(item.value)) this._items[key].checked = true;
    //         else this._items[key].checked = false;
    //       }
    //       else {
    //         this._items[key].checked = false;
    //         this.itemsSelected = [];
    //         this.setTitle([]);
    //       }
    //     })
    //     setTimeout(() => {
    //       this._selectedItems = changes.values.currentValue;
    //     }, 200)
    //   }
    // }
  }

  setTitle(label: Array<string>) {
    if (label.length) this._title = label.join(', ');
    else this._title = this.title;
  }

  writeValue(data: any, index: number) {
    if (this.multiselect) {
      if (this._selectedItems.includes(data.value)) {
        this._selectedItems.splice(this._selectedItems.indexOf(data.value), 1);
        this.itemsSelected.splice(this.itemsSelected.indexOf(data.label), 1);
        this.setTitle(this.itemsSelected);
      }
      else {
        this._selectedItems.push(data.value);
        this.itemsSelected.push(data.label);
        this.setTitle(this.itemsSelected);
      }
    }
    else {
      if (this._selectedItems.includes(data.value)) {
        this._selectedItems = [];
        this.itemsSelected = [];
        this.setTitle(this.itemsSelected);
      }
      else {
        this._selectedItems = [data.value];
        this.itemsSelected = [data.label];
        this.setTitle(this.itemsSelected);
      }
    }
    this.getDropdownSelected.emit(this._selectedItems);
  }

  selectItem(item: any, index: number) {
    if (this.isOpen) {
      this.toggleSelect('focus');
      if (this.enableFilter) this.filter.nativeElement.focus();
      else this.header.nativeElement.focus();
    }

    //cambiar de class fa-check
    if(!this.multiselect){
      this._items.map((element, i) =>{
        if(element.checked){
          element.checked = false;
          this.writeValue(element, i)
        }
      })
    }
    
    item.checked = !item.checked;
    this.writeValue(item, index)
  }

  toggleSelect(action: string = '') {
    switch (action) {
      case 'blur':
        if (this.isOpen) {
          this.timer = setTimeout(() => {
            this.isOpen = !this.isOpen;
          }, 250);
        }
        break;
      case 'focus':
        clearTimeout(this.timer);
        break;
      default:
        clearTimeout(this.timer);
        this.isOpen = !this.isOpen;
        break;
    }
    this.isRequired();
  }

  isRequired() {
    if (this.required) {
      return this.itemsSelected.length ? false : true;
    }
  }

  clearFilter() {
    this.filterText = "";
  }

  initInterval() {
    this.interval = setInterval(() => {
      this.count++;
      if (this.values) {
        if (this.values.length && this.items.length || this.count > 100) {
          this.count = 0;
          clearTimeout(this.interval);
          this.setItems();
        }
      }
    }, 10)
  }

  setItems() {
    this.items.map((item, key) => {
      let label = item;
      if (this.fieldToLabel) {
        label = '';
        this.fieldToLabel.map(i => {
          if (item.hasOwnProperty(i)) label += item[i] + ' ';
        });
        label = label.trim();
        if (label.length == 0) label = item;
      }
      let value = key;
      if (this.fieldToValue) {
        if (item.hasOwnProperty(this.fieldToValue)) {
          value = item[this.fieldToValue];
        }
        else value = key;
      }
      let checked = false;
      if (this.values.includes(value)) {
        checked = true;
        this.itemsSelected.push(label);
        this._selectedItems.push(value);
      }
      this._items.push({
        value: value,
        label: label,
        checked: checked,
        show: true
      });
    });
    this.setTitle(this.itemsSelected);
    clearTimeout(this.interval);
  }
}
