import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { constantes } from "../../constants/seccion-monitor";
import { Page } from "../../dto/secciones-monitorDTO";
import { SeccionMonitorService } from "../../services/seccion-monitor.service";
import { VerPaginaService } from "../../services/ver-pagina.service";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { PageEvent } from "@angular/material/paginator";
import { AlertNotificationService } from "src/app/services";

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.css"],
})
export class TableComponent implements OnChanges {
  gruposVisualizacion: any = [];
  viewResults: any = [];
  paginas: Page[] = [];
  pageSize: number = constantes.pagination.pageSize;
  currentPage: number = 0;
  total_rows: number = 0;

  loading: boolean;
  vista: string;

  @Input() page: number;

  origin: string = constantes.origin;
  tipoGrupoSelect: number;
  details: any = [];
  usuarios: any = [];

  @Input() inputSearchPage: string;
  pageView: string;

  activeSearch: boolean;
  @Input() clicks: number;
  origen: string;
  typeGroupChecked: number;
  mostrarLista: boolean = false;
  elementos: any = [];
  pages: any = [];
  opcion: string = "Selección";
  checkedPageSize: number = 0;
  title: string;

  public showValidationErrorModal: boolean = false;
  constructor(
    private verPaginaService: VerPaginaService,
    private router: Router,
    private seccionesMonitorService: SeccionMonitorService,
    public dialog: MatDialog,
    private alertNotificationService: AlertNotificationService
  ) {
    /*if (this.origin=='secciones') {
      this.vista='toSearchPages'
    }*/
  }

  ngOnInit(): void {
    this.tipoGrupoSelect = constantes.ids.idGV;
  }
  ngOnChanges(changes: SimpleChanges) {
    this.page = this.page == undefined ? 1 : this.page;
    this.vista = constantes.vista;
    this.activeSearch = constantes.activeSearch;
    this.typeGroupChecked =
      constantes.ids.idCatGV != 0
        ? constantes.ids.idCatGV
        : constantes.detallesGrupoVisualizacion.idTGV;

    if (sessionStorage.getItem("paginas") == "true") {
      this.origen = "paginas";
    }

    if (this.vista == "toSearch" && this.activeSearch == true) {
      this.getAllGrupoVisualizacion();
    }
    if (this.vista == "toUpdate" || this.vista == "toCreate") {
      this.currentPage = 0;
      this.checkedPageSize = constantes.elementsIds.length;

      if (
        constantes.ids.idCatGV == 1 ||
        constantes.detallesGrupoVisualizacion.idTGV == 1
      ) {
        this.title = "Grupos seleccionados";
        if (this.vista == "toUpdate" && constantes.elementsIds.length > 0) {
          this.getGroupByIds();
        }
        this.getGroupByTextName();
      }

      if (
        constantes.ids.idCatGV == 2 ||
        constantes.detallesGrupoVisualizacion.idTGV == 2
      ) {
        this.title = "Empresas seleccionadas";
        if (this.vista == "toUpdate" && constantes.elementsIds.length > 0) {
          this.getCompaniesByCompaniesIds();
        }
        this.getCompaniesByGroupsIdsCompanyName();
      }

      if (
        constantes.ids.idCatGV == 3 ||
        constantes.detallesGrupoVisualizacion.idTGV == 3
      ) {
        this.title = "Sitios de trabajo seleccionados";
        if (this.vista == "toUpdate" && constantes.elementsIds.length > 0) {
          this.getWorkPlaceByIds();
        }
        this.getWorkPlace();
      }

      if (
        constantes.ids.idCatGV == 4 ||
        constantes.detallesGrupoVisualizacion.idTGV == 4
      ) {
        this.title = "Usuarios seleccionados";
        if (this.vista == "toUpdate" && constantes.elementsIds.length > 0) {
          this.searchUsersByUserIds();
        }
        this.searchUsersGroupByWorkPlaceId();
      }
    }
    if (this.vista == "toSearchPage") {
      this.title = "Paginas seleccionadas";
      if (
        (this.origin == "secciones" &&
          constantes.secciones.atachPagesIds.length > 0) ||
        (this.origin == "paginas" &&
          constantes.secciones.atachPagesIds.length > 0)
      ) {
        this.getPagesBypageIds();
      }
      this.getAllPages();
    }
  }

  /***************
   Metodo que devuelve los Grupos de visualizacion
   de una categoria (Grupos,Empresas,Sitios de trabajo,Usuario)
  ****************/
  async getAllGrupoVisualizacion(): Promise<void> {
    this.loading = true;
    let filters;
    if (constantes.ids.idCatGV > 0) {
      filters = {
        name: constantes.inputs.inputSearchTGV,
        displayGroupTypeId: [constantes.ids.idCatGV],
      };
    } else {
      filters = {
        name: constantes.inputs.inputSearchTGV,
      };
    }

    let data = {
      pageSize: this.pageSize,
      page: this.currentPage + 1,
      sortDirection: "asc",
      sort: "dg.name",
      filters: filters,
    };
    (
      await this.seccionesMonitorService.getAllGrupoVisualizacion(data)
    ).subscribe(
      (result) => {
        this.gruposVisualizacion = result.data.displayGroup;
        this.total_rows = result.data.pagination.total_rows;
        this.loading = false;
      },
      (error) => {
        console.log("error: ", error);
        this.loading = false;
      }
    );
  }

  getGroupByIds(): void {
    this.loading = true;
    let searchGroupJson = {
      pageSize: this.checkedPageSize,
      page: 1,
      sortDirection: "asc",
      sort: "g.groupId",
      allRows: true,
      filters: {
        groupsIds: constantes.elementsIds,
      },
    };

    this.seccionesMonitorService
      .searchGroup(searchGroupJson)
      .subscribe((result) => {
        this.elementos = [];
        for (let index = 0; index < result.data.groups.length; index++) {
          this.elementos.push({
            id: result.data.groups[index].groupId,
            nombre: result.data.groups[index].name,
          });
        }
      });
  }
  getGroupByTextName(): void {
    this.loading = true;
    let searchGroupJson = {
      pageSize: this.pageSize,
      page: this.currentPage + 1,
      sortDirection: "asc",
      sort: "g.groupId",
      filters: {
        name: constantes.inputs.inputSearchGroup,
        displayGroupTypeId: [constantes.ids.idCatGV],
      },
    };
    constantes.jSONs.filters = searchGroupJson.filters;

    this.seccionesMonitorService.searchGroup(searchGroupJson).subscribe(
      (result) => {
        this.total_rows = result.data.pagination.total_rows;
        this.viewResults = [];

        if (constantes.elementsIds.length > 0) {
          for (
            let element = 0;
            element < result.data.groups.length;
            element++
          ) {
            this.viewResults.push({
              Id: result.data.groups[element].groupId,
              name: result.data.groups[element].name,
              checked: constantes.elementsIds.includes(
                result.data.groups[element].groupId
              ),
            });
          }
        } else {
          for (
            let element = 0;
            element < result.data.groups.length;
            element++
          ) {
            this.viewResults.push({
              Id: result.data.groups[element].groupId,
              name: result.data.groups[element].name,
              checked: this.elementos.some(
                (elemento) =>
                  elemento.id === result.data.groups[element].groupId
              ),
            });
          }
        }
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.loading = false;
      }
    );
  }
  getCompaniesByCompaniesIds(): void {
    this.loading = true;
    let searchCompaniesJson = {
      pageSize: this.checkedPageSize,
      page: 1,
      sortDirection: "asc",
      sort: "c.name",
      allRows: true,
      filters: {
        companyIds: constantes.elementsIds,
      },
    };
    this.seccionesMonitorService
      .searchCompanies(searchCompaniesJson)
      .subscribe((result) => {
        this.elementos = [];
        for (let index = 0; index < result.data.companys.length; index++) {
          this.elementos.push({
            id: result.data.companys[index].companyId,
            nombre: result.data.companys[index].name,
          });
        }
      });
  }
  getCompaniesByGroupsIdsCompanyName(): void {
    this.loading = true;
    let searchCompaniesJson = {
      pageSize: this.pageSize,
      page: this.currentPage + 1,
      sortDirection: "asc",
      sort: "c.name",
      filters: {
        groupIds: constantes.ids.groupIds,
        name: constantes.inputs.inputTextCompany,
      },
    };
    if (constantes.ids.groupIds == 0) {
      delete searchCompaniesJson.filters.groupIds;
    }
    constantes.jSONs.filters = searchCompaniesJson.filters;
    this.seccionesMonitorService.searchCompanies(searchCompaniesJson).subscribe(
      (result) => {
        this.total_rows = result.data.pagination.total_rows;
        this.viewResults = [];

        if (constantes.elementsIds.length > 0) {
          for (
            let element = 0;
            element < result.data.companys.length;
            element++
          ) {
            this.viewResults.push({
              Id: result.data.companys[element].companyId,
              name: result.data.companys[element].name,
              checked: constantes.elementsIds.includes(
                result.data.companys[element].companyId
              ),
            });
          }
        } else {
          for (
            let element = 0;
            element < result.data.companys.length;
            element++
          ) {
            this.viewResults.push({
              Id: result.data.companys[element].companyId,
              name: result.data.companys[element].name,
              checked: this.elementos.some(
                (elemento) =>
                  elemento.id === result.data.companys[element].companyId
              ),
            });
          }
        }
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.loading = false;
      }
    );
  }

  getWorkPlaceByIds(): void {
    this.loading = true;
    let workPlacesJson = {
      pageSize: this.checkedPageSize,
      page: 1,
      sortDirection: "asc",
      sort: "st.name",
      allRows: true,
      filters: {
        workPlaceIds: constantes.elementsIds,
      },
    };
    this.seccionesMonitorService
      .geAllWorkPlaceByCompanyId(workPlacesJson)
      .subscribe((result) => {
        this.elementos = [];
        console.log(result.data);
        for (let index = 0; index < result.data.workPlaces.length; index++) {
          this.elementos.push({
            id: result.data.workPlaces[index].workPlaceId,
            nombre: result.data.workPlaces[index].nameClueg,
          });
        }
      });
  }
  getWorkPlace(): void {
    this.loading = true;
    let workPlacesJson = {
      pageSize: this.pageSize,
      page: this.currentPage + 1,
      sortDirection: "asc",
      sort: "st.name",
      filters: {
        groupsIds: [constantes.usersGroupByWorkP.groupId], //solo para precargar datos
        companyIds: [constantes.ids.companyIds],
        stateIds: [constantes.ids.stateIds],
        municipalityIds: constantes.ids.municipalityIds,
      },
    };
    constantes.jSONs.filters = workPlacesJson.filters;
    if (constantes.ids.stateIds == null) {
      delete workPlacesJson.filters.stateIds;
      delete workPlacesJson.filters.municipalityIds;
    } else if (constantes.ids.municipalityIds == null) {
      delete workPlacesJson.filters.municipalityIds;
    }
    //delete workPlacesJson.filters.groupsIds
    this.seccionesMonitorService
      .geAllWorkPlaceByCompanyId(workPlacesJson)
      .subscribe(
        (result) => {
          if (result.code == 404) {
            console.log("no se encontraron resultados");
          }
          if (result.code == 200) {
            this.viewResults = [];
            this.total_rows = result.data.pagination.total_rows;
            if (constantes.elementsIds.length > 0) {
              for (
                let element = 0;
                element < result.data.workPlaces.length;
                element++
              ) {
                this.viewResults.push({
                  Id: result.data.workPlaces[element].workPlaceId,
                  name: result.data.workPlaces[element].nameClueg,
                  checked: constantes.elementsIds.includes(
                    result.data.workPlaces[element].workPlaceId
                  ),
                });
              }
            } else {
              for (
                let element = 0;
                element < result.data.workPlaces.length;
                element++
              ) {
                this.viewResults.push({
                  Id: result.data.workPlaces[element].workPlaceId,
                  name: result.data.workPlaces[element].nameClueg,
                  checked: this.elementos.some(
                    (elemento) =>
                      elemento.id ===
                      result.data.workPlaces[element].workPlaceId
                  ),
                });
              }
            }
          }
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.loading = false;
        }
      );
  }

  searchUsersByUserIds(): void {
    this.loading = true;
    let usersGroupByWorkP = {
      pageSize: this.checkedPageSize,
      page: 1,
      sortDirection: "asc",
      sort: "us.id",
      filters: {
        idUsers: constantes.elementsIds,
      },
    };
    console.log(constantes.elementsIds);
    this.seccionesMonitorService
      .searchUsersGroupByWorkPlaceId(usersGroupByWorkP)
      .subscribe(
        (result) => {
          this.elementos = [];
          for (let index = 0; index < result.data.usuarios.length; index++) {
            this.elementos.push({
              id: result.data.usuarios[index].id_usuario,
              nombre: result.data.usuarios[index].nombre,
            });
          }
        },
        (error) => {
          console.log(error);
          this.loading = false;
        }
      );
  }
  searchUsersGroupByWorkPlaceId(): void {
    this.loading = true;
    let usersGroupByWorkP = {
      pageSize: this.pageSize,
      page: this.currentPage + 1,
      sortDirection: "asc",
      sort: "us.id",
      filters: {
        groupsIds:
          constantes.usersGroupByWorkP.groupId > 0
            ? [constantes.usersGroupByWorkP.groupId]
            : [], //solo para precargar datos
        idCompanys:
          constantes.usersGroupByWorkP.companyId > 0
            ? [constantes.usersGroupByWorkP.companyId]
            : [],
        stateIds:
          constantes.usersGroupByWorkP.stateId > 0
            ? [constantes.usersGroupByWorkP.stateId]
            : [],
        municipalityIds:
          constantes.usersGroupByWorkP.municipalityId > 0
            ? [constantes.usersGroupByWorkP.municipalityId]
            : [],
        workPlaces:
          constantes.usersGroupByWorkP.workPlaceId.length > 0
            ? constantes.usersGroupByWorkP.workPlaceId
            : [],
        userTypeId:
          constantes.ids.typeUserId > 0 ? [constantes.ids.typeUserId] : [],
        sex: constantes.ids.genero != "" ? [constantes.ids.genero] : [],
        diseases:
          constantes.ids.enfermedadId > 0 ? [constantes.ids.enfermedadId] : [],
        idUsers: constantes.ids.userId > 0 ? [constantes.ids.userId] : [],
      },
    };
    constantes.jSONs.filters = usersGroupByWorkP.filters; //filtros que se guardaran
    setTimeout(() => {
      if (this.filtrosVacios(usersGroupByWorkP.filters)) {
        this.showValidationErrorModal = true;
        this.loading = false;
        this.viewResults = [];
        return;
      }
    }, 1500);

    this.seccionesMonitorService
      .searchUsersGroupByWorkPlaceId(usersGroupByWorkP)
      .subscribe(
        (result) => {
          if (result.code == 404) {
            console.log("no se encontraron resultados");
          }
          if (result.code == 200) {
            this.total_rows = result.data.pagination.total_rows;
            if (this.total_rows <= 0) {
              console.log("no se encontro ningun usuario vista tabla");
            } else {
              this.viewResults = [];
              if (constantes.elementsIds.length > 0) {
                for (
                  let element = 0;
                  element < result.data.usuarios.length;
                  element++
                ) {
                  this.viewResults.push({
                    Id: result.data.usuarios[element].id_usuario,
                    name: result.data.usuarios[element].nombre,
                    checked: constantes.elementsIds.includes(
                      result.data.usuarios[element].id_usuario
                    ),
                  });
                }
              } else {
                for (
                  let element = 0;
                  element < result.data.usuarios.length;
                  element++
                ) {
                  this.viewResults.push({
                    Id: result.data.usuarios[element].id_usuario,
                    name: result.data.usuarios[element].nombre,
                    checked: this.elementos.some(
                      (elemento) =>
                        elemento.id === result.data.usuarios[element].id_usuario
                    ),
                  });
                }
              }
            }
          }
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.loading = false;
        }
      );
  }

  asignaRadio(checked: boolean): void {
    constantes.paginas.displayGroupId = this.tipoGrupoSelect;
  }
  addGroupId(checked: boolean): void {
    constantes.jSONs.groupsSelected = this.viewResults;
  }

  addElementsIds(check: boolean, elementId: number, nombre: string): void {
    if (check == true) {
      if (this.vista == "toCreate") {
        this.elementos.push({ id: elementId, nombre: nombre });
        constantes.newElementsIds.push(elementId);
      } else {
        this.elementos.push({ id: elementId, nombre: nombre });
        constantes.elementsIds.push(elementId);
      }
    } else {
      if (this.vista == "toCreate") {
        const indiceAEliminar = this.elementos.findIndex(
          (elemento) => elemento.id === elementId
        );
        this.elementos.splice(indiceAEliminar, 1);
        constantes.newElementsIds.splice(
          constantes.newElementsIds.indexOf(elementId),
          1
        );
      } else {
        constantes.elementsIds.splice(
          constantes.elementsIds.indexOf(elementId),
          1
        );
        const indiceAEliminar = this.elementos.findIndex(
          (elemento) => elemento.id === elementId
        );
        this.elementos.splice(indiceAEliminar, 1);
        constantes.newElementsIds.splice(
          constantes.newElementsIds.indexOf(elementId),
          1
        );
      }
    }
  }

  pageChecked(checked: boolean, pageId: number, nombre: string): void {
    console.log(pageId);
    if (checked == true) {
      this.pages.push({ id: pageId, nombre: nombre });
      constantes.secciones.atachPagesIds.push(pageId);
    } else {
      const indiceAEliminar = this.pages.findIndex(
        (page) => page.id === pageId
      );
      this.pages.splice(indiceAEliminar, 1);
      constantes.secciones.detachPagesIds.push(pageId);
      constantes.secciones.atachPagesIds.splice(
        constantes.secciones.atachPagesIds.indexOf(pageId),
        1
      );
    }
  }

  async openDetails(id: number): Promise<void> {
    sessionStorage.setItem("grupoVisualizacion", "true");
    constantes.title = "Editar grupo de visualización";
    constantes.vista = "toUpdate";
    constantes.activeSearch = true;
    (await this.seccionesMonitorService.geGrupoVisualizacionById(id)).subscribe(
      (result) => {
        this.details = result.data;
        constantes.detallesGrupoVisualizacion.idTGV =
          result.data.displayGroupTypeId;
        constantes.detallesGrupoVisualizacion.nameGV = result.data.name;
        constantes.usersGroupByWorkP.displayGroupId =
          result.data.displayGroupId;
        constantes.elementsIds = this.details.elementIds;
        constantes.detallesGrupoVisualizacion.filters = result.data.filters;
        if (result.data.filters != null) {
          constantes.ids.groupIds = result.data.filters.groupIds;
        }
        this.router.navigateByUrl(
          "/seccionesMonitor/detallesGrupoVisualizacion"
        );
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.loading = false;
      }
    );
  }

  ngOnDestroy() {
    this.tipoGrupoSelect = null;
  }

  getPagesBypageIds(): void {
    if (this.pages.length <= 0) {
      this.pages = [];
      for (let index = 0; index < constantes.secciones.pages.length; index++) {
        this.pages.push({
          id: constantes.secciones.pages[index].pageId,
          nombre: constantes.secciones.pages[index].name,
        });
      }
    }
    /*let data={
      pageSize: this.checkedPageSize,
      page: 1,
      sortDirection: "asc",
      sort: "p.name",
      allRows: true,
      filters: {
        pageIds:constantes.secciones.atachPagesIds,
        withAssignedSections:true
      }
    }
   
    console.log(data)
    this.verPaginaService.getAllPaginas(data).subscribe(result=>{
      
      if (this.pages.length<=0) {
        this.pages=[]
      for (let index = 0; index < result.data.pages.length; index++) {
        this.pages.push({id:result.data.pages[index].id,nombre:result.data.pages[index].name})
      }
      console.log(this.pages)
      }
      
    });*/
  }
  async getAllPages(): Promise<void> {
    console.log(constantes.secciones.accion);
    //let data;
    this.loading = true;
    /*if (constantes.secciones.accion==''&&constantes.secciones.sectionId==0) {
       data={
        pageSize: this.pageSize,
        page: this.currentPage+1,
        sortDirection: "asc",
        sort: "p.name",
        filters: {
          name: constantes.paginas.inputSearchPage!=undefined?constantes.paginas.inputSearchPage:'',
          pageIds:constantes.paginas.inputSearchPage==''&&constantes.secciones.sectionId==0||constantes.paginas.inputSearchPage==undefined&&constantes.secciones.sectionId==0?constantes.secciones.atachPagesIds:[],
          withAssignedSections:null,
        }
      }*/
    //}else{
    let data = {
      pageSize: this.pageSize,
      page: this.currentPage + 1,
      sortDirection: "asc",
      sort: "p.name",
      filters: {
        name:
          constantes.paginas.inputSearchPage != undefined
            ? constantes.paginas.inputSearchPage
            : "",
        //pageIds:constantes.paginas.inputSearchPage==''&&constantes.secciones.sectionId==0||constantes.paginas.inputSearchPage==undefined&&constantes.secciones.sectionId==0?constantes.secciones.atachPagesIds:[],
        withSectionId:
          constantes.secciones.accion == "create"
            ? null
            : constantes.paginas.inputSearchPage != undefined &&
              constantes.paginas.inputSearchPage != ""
            ? null
            : constantes.secciones.sectionId == 0
            ? null
            : constantes.secciones.sectionId,
        withAssignedSections:
          constantes.secciones.sectionId == 0 ? null : false,
      },
    };
    // }

    (await this.verPaginaService.getAllPaginas(data)).subscribe((result) => {
      this.loading = false;
      this.paginas = [];
      for (let page = 0; page < result.data.pages.length; page++) {
        this.paginas.push({
          id: result.data.pages[page].id,
          name: result.data.pages[page].name,
          url: result.data.pages[page].url,
          status: result.data.pages[page].status,
          descripcion: result.data.pages[page].descripcion,
          createdAt: result.data.pages[page].createdAt,
          updatedAt: result.data.pages[page].updatedAt,
          checked: constantes.secciones.atachPagesIds.includes(
            result.data.pages[page].id
          ),
        });
      }
      this.total_rows = result.data.pagination.total_rows;
      this.loading = false;
    });
  }

  irAPagina(id: string) {
    sessionStorage.setItem("paginas", "true");
    constantes.title = "Editar pagina";
    this.router.navigate(["/seccionesMonitor/crearPagina/" + id]);
  }
  openConfirmDialog(mensaje: string | null): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { origen: "crearPagina", titulo: mensaje },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 1) {
        console.log("The dialog was closed");
      } else if (result == 0) {
      }
    });
  }

  cambiarPagina(e: PageEvent) {
    this.currentPage = e.pageIndex;
    if (this.vista == "toSearchPage") {
      this.getAllPages();
    }
    if (this.vista == "toSearch") {
      this.getAllGrupoVisualizacion();
    }
    if (this.vista == "toUpdate" || this.vista == "toCreate") {
      if (
        constantes.ids.idCatGV == 1 ||
        constantes.detallesGrupoVisualizacion.idTGV == 1
      ) {
        this.getGroupByTextName();
      }

      if (
        constantes.ids.idCatGV == 2 ||
        constantes.detallesGrupoVisualizacion.idTGV == 2
      ) {
        this.getCompaniesByGroupsIdsCompanyName();
      }

      if (
        constantes.ids.idCatGV == 3 ||
        constantes.detallesGrupoVisualizacion.idTGV == 3
      ) {
        this.getWorkPlace();
      }

      if (
        constantes.ids.idCatGV == 4 ||
        constantes.detallesGrupoVisualizacion.idTGV == 4
      ) {
        this.searchUsersGroupByWorkPlaceId();
      }
    }
  }

  verLista(): void {
    this.mostrarLista = this.mostrarLista == true ? false : true;
    //this.opcion=this.opcion=='Mostrar seleccionados'?'Ocultar seleccionados':'Mostrar seleccionados'
  }

  filtrosVacios(filters: any): boolean {
    return Object.values(filters).every(
      (value) =>
        Array.isArray(value) &&
        (value.length === 0 || value.every((item) => item === null))
    );
  }
}
