import { Injectable } from '@angular/core'
import { CanActivate, UrlTree, Router } from '@angular/router'
import { constants } from '../constants'
import { existStorage, getStorage } from '../utils/_utils'
import { SessionService } from '../services'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  protected rawRootPath = '/'
  constructor(
    private session: SessionService,
    private router: Router
  ) { }
  canActivate(): boolean | UrlTree {
    if (this.session.isSignedIn()) {
      if (existStorage(constants.ls.sessionPermission)) {
        const actualUrl = this.router.url === this.rawRootPath ? `/${constants.screens.dashboard.path}` : this.router.url
        const pages = getStorage(constants.ls.sessionPermission, true)
        if (pages[actualUrl] === undefined && actualUrl.indexOf(`/${constants.screens.signIn.path}`) === -1) {
          return this.router.parseUrl('/' + constants.screens.dashboard.path)
        }
        return true
      }
      else {
        return this.router.parseUrl('/' + constants.screens.dashboard.path)
      }
    } else {
      return this.router.parseUrl('/' + constants.screens.signIn.path)
    }
  }
}
