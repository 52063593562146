<abarcms-loader-full *ngIf="showSpinner"></abarcms-loader-full>
<div>
<img class="sectionIcon" [src]="sectionDetails.icon" alt="">
<label>{{sectionDetails.name}}</label>
</div>
<br>
<div class="card">
    <div class="card-body">
<div class="table ">
    <div class="columHeader">
        <table>
            <tr>
                <th>Nombre</th>
                <th>Descripción</th>
                <th>Icono</th>
                <th>URL</th>
                <th>Estatus</th>
                <th>Fecha Creación</th>
            </tr>
            <tbody>
                <tr   *ngFor="let sectionDetails of sectionDetails.pages">
                    <td>{{sectionDetails.name}}</td>
                    <td>{{sectionDetails.description}}</td>
                    <td><img class="sectionIcon" [src]="sectionDetails.icon" alt=""></td>
                    <td>{{sectionDetails.url}}</td>
                    <td>{{sectionDetails.status}}</td>
                    <td>{{sectionDetails.createdAt}}</td>
                </tr>
            </tbody>
        </table>

    </div>
</div>

</div>
</div>
       