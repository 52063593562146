import { Injectable } from "@angular/core";
import { DashboardDataService } from "../dashboard-data.service";
import { HttpClient } from "@angular/common/http";
import { SettingsService } from "../../settings.service";
import { DashboardWidget } from "src/app/dto/DashboardWidget";
import { of, Observable } from "rxjs";
import { delay } from "rxjs/operators";
import { SessionService } from "../../session.service";
import { LoadProgress } from 'src/app/decorators/loadProgress';
import { ApiService } from '../../api.service';
import { ResponseData, Log } from 'src/app/dto';
import { makeHeaders } from 'src/app/utils/_http';
import { ModalHelperService } from '../../modal-helper.service';

@Injectable({
  providedIn: "root"
})
@LoadProgress
export class DashboardDataWsService {
  private pathInfo: any;
  private baseUrl: string;
  private segmentLog: any;
  private segmentUser: any;
  constructor(
    private settings: SettingsService,
    private api: ApiService
  ) {
    this.pathInfo = this.settings.get('pathInfo');
    this.baseUrl = this.pathInfo.base.baseUrl + this.pathInfo.base.prefix;
    this.segmentLog = this.pathInfo.res.admin['log'];
    this.segmentUser = this.pathInfo.res.admin['user'];
  }
  getDashboardWidgets(
    username: string,
    accessToken: string
  ): Observable<DashboardWidget[][][]> {
    let widgets = [
      [[new DashboardWidget("logs", [])]],
      [
        [new DashboardWidget("users", [])],
        [new DashboardWidget("metrics", []), new DashboardWidget("assets", [])]
      ]
    ];
    return of(widgets).pipe(delay(Math.random() * 3000));
  }
  getDashboardWidgetData(type: string): Observable<any> {
    var data: any = null;
    switch (type) {
      case "logs":
        data = this.getLogsData();
        break;
      case "users":
        data = this.getUsersData();
        break;
      case "metrics":
        break;
    }

    return data;
  }
  private getLogsData() {
    let endpoint = this.baseUrl + this.segmentLog.prefix;
    return this.api.get(endpoint);
  }
  private read(accessToken, page: number, itemsPerPage: number) {
    let options = {
      'X-Auth-Token': accessToken,
      'X-Current-Page': page,
      'X-Items-Page': itemsPerPage
    };
    let endpoint = this.baseUrl + this.segmentLog.prefix;
    return this.api.get(endpoint, options);
  }
  private readPaginator(accessToken: string, page: number, itemsPerPage: number): Observable<ResponseData> {
    let options = {
      'X-Auth-Token': accessToken,
      'X-Current-Page': page,
      'X-Items-Page': itemsPerPage
    };
    let endpoint = this.baseUrl + this.segmentLog.prefix;
    return this.api.get(endpoint, options);
  }
  private getUsersData() {
    let endpoint = this.baseUrl + this.segmentUser.prefix;
    return this.api.get(endpoint);
  }

}
