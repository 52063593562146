/* tslint:disable:triple-equals */
import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { SettingsService } from "src/app/services/settings.service";
import { ValidateService } from "src/app/services/validate.service";
import { SessionService } from "src/app/services/session.service";
import { MediaService } from "src/app/services/media.service";
import { SessionDataService } from "src/app/services/data/session-data.service";
import { AuthenticationCredential } from "src/app/dto/AuthenticationCredential";
import { constants } from "src/app/constants";
import { validation } from "src/app/strings";
import { ResponseData } from "src/app/dto/ResponseData";
import { getData, getValid, getStatus, getMessage } from "src/app/utils/_http";
import {
  removeStorage,
  setPermissionToPage,
  setStorage,
} from "src/app/utils/_utils";
import { ModalHelperService } from "src/app/services/modal-helper.service";
import { Subscription } from "rxjs";
import { LogsDataService } from "src/app/services/data/logs-data.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PasswordRecoveryModalComponent } from "../password-recovery/modals/password-recovery-modal/password-recovery-modal.component";
import { DOCUMENT } from "@angular/common";
import { SettingsDataService } from "src/app/services/data/settings-data.service";
import { SettingsData } from "src/app/dto/SettingsData";
import { SuccessComponent } from "src/app/views/success/success.component";
import { SeccionMonitorService } from "../admin/seccion-monitor/services/seccion-monitor.service";
import { HttpMonitorService } from "../admin/seccion-monitor/services/http-monitor.service";

@Component({
  selector: "app-sign-in",
  templateUrl: "./sign-in.component.html",
  styleUrls: ["./sign-in.component.css"],
})
export class SignInComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public validationText: any = validation;
  public forma: FormGroup;
  public data: any = {};
  public validationError: string = null;
  public showValidationErrorModal = false;
  public googleSignInError: any = null;
  public showGoogleSignInError = false;
  public showAuthenticationError = false;
  public authenticationError: any = null;
  public mediaBackground: string = null;
  public mediaLogo: string = null;
  public settingsData: SettingsData;
  private interval: any = {
    timer: null,
    count: 0,
    flag: null,
  };
  constructor(
    private seccionesMonitorService: SeccionMonitorService,
    private monitorService: HttpMonitorService,
    private router: Router,
    public settings: SettingsService,
    private validate: ValidateService,
    private session: SessionService,
    public sessionDataService: SessionDataService,
    private fb: FormBuilder,
    private modalHelper: ModalHelperService,
    private logsDataService: LogsDataService,
    private MediaSrv: MediaService,
    public modalService: NgbModal,
    public modalServiceSuccess: NgbModal,
    private settingsDataService: SettingsDataService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.getSettingsData();
    this.modalHelper.changeLoading(false);
  }
  ngOnInit() {
    this.createForm();
    if (
      !this.settings.data.signIn.enabled ||
      this.session.accessToken != null
    ) {
      this.router.navigate([this.settings.data.signIn.successRedirect.default]);
    }
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  get valid(): any {
    return this.settings.get("validate");
  }
  private createForm() {
    this.forma = this.fb.group({
      username: [
        this.settings.get("production") ? "" : "santillan.itc@gmail.com",
        [Validators.required, Validators.pattern(this.valid.username)],
      ],
      password: [
        this.settings.get("production") ? "" : "UCPGULUR",
        [Validators.required, Validators.pattern(this.valid.password)],
      ],
    });
  }
  get validUsername() {
    return (
      this.forma.get("username").invalid && this.forma.get("username").touched
    );
  }
  get validPassword() {
    return (
      this.forma.get("password").invalid && this.forma.get("password").touched
    );
  }
  onSignIn() {
    if (this.forma.invalid) {
      return Object.values(this.forma.controls).forEach((control) => {
        control.markAsTouched();
      });
    }
    this.data = { ...this.forma.value };
    this.validate.validateValues(this.data, "signIn");
    const credential: AuthenticationCredential = new AuthenticationCredential();
    credential.isPasswordEncrypted = false;
    credential.password = this.data.password;
    credential.provider = this.settings.data.signIn.std.provider;
    credential.email = this.data.username;
    this.sessionDataService.signIn(credential).subscribe((rs) => {
      if (
        getValid(rs, {
          title: "Error al iniciar sesión",
          modalHelper: this.modalHelper,
          settings: this.settings,
        })
      ) {
        let body = getData(rs);
        if (body) {
          this.session.accessToken = JSON.parse(
            JSON.stringify(getData(rs, "accessTokenInfo"))
          );
          this.session.user = getData(rs, "user");
          setStorage(constants.ls.sessionProfile, {
            ...this.session.user,
            password: this.data.password,
            status: true,
          });
          this.logsDataService
            .create(
              "sign-in",
              "login",
              JSON.stringify({ email: this.session.user.username })
            )
            .subscribe((rs) => {});
          this.initInterval();
          //login para datos de acceso para servicios monitor
          if (
            sessionStorage.getItem("monitorToken") == undefined ||
            sessionStorage.getItem("monitorToken") == null ||
            sessionStorage.getItem("monitorToken") == "undefined"
          ) {
            this.monitorService.getAccesToken().subscribe((result) => {
              sessionStorage.setItem("monitorToken", result.token);
              sessionStorage.setItem("monitorUserId", result.user_id);
            });
          }
        }
      }
    });
  }
  onGoogleSignedIn(event) {
    const credential: AuthenticationCredential = new AuthenticationCredential();
    credential.isPasswordEncrypted = false;
    credential.provider = this.settings.data.signIn.google.provider;
    credential.token = event.accessToken;
    let email = Object.values(event.profile).filter((item) => {});
    credential.email = "goggle";
    this.sessionDataService.signIn(credential).subscribe((rs: ResponseData) => {
      if (rs) {
        this.session.accessToken = { ...getData(rs, "accessToken") };
        this.session.user = getData(rs, "user");
        this.initInterval();
      } else {
        let error = {
          title: "Error de autenticación",
          message: this.settings.get("production")
            ? getMessage(rs)
            : getStatus(rs),
          show: true,
        };
        this.modalHelper.changeErrorModal(error);
        this.modalHelper.changeLoading(false);
      }
    });
  }
  onGoogleSignInError(err) {
    if (err.error !== "popup_closed_by_user") {
      let error = {
        title: "Error de autenticación",
        message:
          this.settings.get("production") && typeof err == "object"
            ? JSON.stringify(err)
            : err,
        show: true,
      };
      this.modalHelper.changeErrorModal(error);
    }
    this.modalHelper.changeLoading(false);
  }
  onRegister() {
    this.router.navigate([constants.screens.register.path]);
  }
  onPasswordRecovery() {
    const modalRef = this.modalService.open(PasswordRecoveryModalComponent, {
      centered: true,
    });
    modalRef.result.then(
      (result) => {
        if (result === "success") {
          const modalSuccess = this.modalServiceSuccess.open(SuccessComponent, {
            centered: true,
            size: "sm",
          });
          modalSuccess.componentInstance.title =
            constants.messages.passwordRecovery.success;
          modalSuccess.result.then(
            (resultSuccess) => {
              if (resultSuccess === "success") {
                this.onGoHome();
              }
            },
            (reason) => {}
          );
        } else if (result === "error") {
          const modalSuccess = this.modalServiceSuccess.open(SuccessComponent, {
            centered: true,
            size: "sm",
          });
          modalSuccess.componentInstance.image = true;
          modalSuccess.componentInstance.title =
            constants.messages.passwordRecovery.error;
          modalSuccess.result.then(
            (resultSuccess) => {
              if (resultSuccess === "success") {
                this.onGoHome();
              }
            },
            (reason) => {}
          );
        }
      },
      (reason) => {}
    );
  }
  private initInterval() {
    if (!!this.session.user && !!this.session.user.roles) {
      this.interval.flag = true;
      this.interval.timer = setInterval(() => {
        this.interval.count++;
        if (this.interval.flag || this.interval.count > 300) {
          clearTimeout(this.interval.timer);
          this.interval.count = 0;
          this.interval.flag = null;
          this.interval.timer = null;
          setPermissionToPage(
            this.session.user.roles,
            this.router.navigate([constants.screens.dashboard.path])
          );
        }
      }, 10);
    } else {
      this.showGenericError();
    }
  }
  private showGenericError(error: any = undefined) {
    this.authenticationError = !error
      ? "Ha ocurrido un error, por favor intentelo de nuevo más tarde"
      : error;
    this.showAuthenticationError = true;
    this.modalHelper.changeLoading(false);
  }

  private onGoHome() {
    removeStorage(constants.ls.passwordRecovery);
    this._document.defaultView.location.reload();
  }

  getSettingsData(): void {
    /*this.settingsDataService.read().subscribe((_rs) => {
      if (getValid(_rs, { title: `Error al obtener la configuración`, modalHelper: this.modalHelper, settings: this.settings })) {
        this.settingsData = getData(_rs);
        if (this.settingsData.image) {
          this.getBackground(this.settingsData.image);
        }
        if (this.settingsData.logo) {
          this.getLogo(this.settingsData.logo);
        }
      }
    });*/
  }
  getBackground(uuid: string) {
    this.MediaSrv.getOne(uuid).subscribe((rs) => {
      this.mediaBackground = rs.url;
      if (
        getValid(rs, {
          title: `Error al obtener la imagen`,
          modalHelper: this.modalHelper,
          settings: this.settings,
        })
      ) {
      }
    });
  }
  getLogo(uuid: string) {
    this.MediaSrv.getOne(uuid).subscribe((rs) => {
      this.mediaLogo = rs.url;
      if (
        getValid(rs, {
          title: `Error al obtener el logo`,
          modalHelper: this.modalHelper,
          settings: this.settings,
        })
      ) {
      }
    });
  }
}
