import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { SettingsService } from 'src/app/services/settings.service';
// import { RoleDataService } from 'src/app/services/data/cms/role-data.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { constants } from 'src/app/constants';
import { labels } from 'src/app/strings';
import { getValid, getArray, getObject, getData } from 'src/app/utils/_http';
// import { ResponseData } from 'src/app/dto/cms/ResponseData';
// import { Role } from 'src/app/dto/cms/Role';
// import { Module } from 'src/app/dto/cms/Module';
// import { Permission } from 'src/app/dto/cms/Permission';
// import { ModuleDataService } from 'src/app/services/data/cms/module-data.service';
// import { PermissionDataService } from 'src/app/services/data/cms/permission-data.service';
import { AlertNotificationService } from 'src/app/services/alert-notification.service';
import { setPermissionToPage, getStorage, slugifyPipe, setStorage, existStorage, removeStorage, addMenus, getPermissionsPage, clone } from 'src/app/utils/_utils';
import { Module, Permission, ResponseData, Role } from 'src/app/dto';
import { RoleDataService } from 'src/app/services/data/role-data.service';
import { ModuleDataService } from 'src/app/services/data/module-data.service';
import { PermissionDataService } from 'src/app/services/data/permission-data.service';
import { MenuDataService } from 'src/app/services/data/menu-data.service';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { UsersDataService } from 'src/app/services/data/users-data.service';
// import { MenuDataService } from 'src/app/services/data/cms/menu-data.service';
// import { ModalHelperService } from 'src/app/services/observables/cms/modal-helper.service';
// import { UsersDataService } from 'src/app/services/data/cms/users-data.service';

interface moduleR {
  id: number,
  module: string,
  permission: Array<any>
}

@Component({
  selector: 'app-roles-action',
  templateUrl: './roles-action.component.html',
  styleUrls: ['./roles-action.component.css']
})
export class RolesActionComponent implements OnInit {
  constants: any = constants;
  formaRole: FormGroup;
  dataRole: Role = null;
  dataPermission: Array<any> = [];
  dataPermissionRole: Array<any> = [];
  permissionsList: Array<Permission> = [];
  modulesList: Array<Module> = [];
  dataNecessary = {
    permissionCheck: false,
    modulesCheck: false,
    selectedCheck: false,
    optionsSelected: [{
      name: 'Activo',
      value: true
    },
    {
      name: 'Inactivo',
      value: false
    }],
    selectedAllItems: [],
  }
  deleteModal: any = { show: false, item: null };
  pageAction: any = {
    action: null,
    uuid: null,
    titlePage: null
  };
  private interval: any = {
    timer: null,
    count: 0
  }
  permissions: any = {}
  constructor(
    private session: SessionService,
    public settings: SettingsService,
    public roleDataService: RoleDataService,
    private fb: FormBuilder,
    private router: Router,
    public moduleDataService: ModuleDataService,
    public permissionDataService: PermissionDataService,
    protected alertNotificationService: AlertNotificationService,
    public menuDataService: MenuDataService,
    private modalHelper: ModalHelperService,
    private usersDataService: UsersDataService
  ) {
    this.pageAction = {
      action: constants.screens.roles[this.router.url.split('/').pop()],
      uuid: this.router.url.split('/')[2],
      titlePage: constants.screens.roles[this.router.url.split('/').pop()] == constants.screens.roles.edit ? labels.role.update.titlePage : labels.role.create.titlePage
    }
    this.getModules();
    this.createFormRole();
  }
  ngOnInit(): void {
    this.initInterval();
    this.permissions = getPermissionsPage(this.router.url);
  }
  get validName() {
    return this.formaRole.get('name').invalid && this.formaRole.get('name').touched;
  }
  get validDescription() {
    return this.formaRole.get('description').invalid && this.formaRole.get('description').touched;
  }
  get validStatus() {
    return this.formaRole.get('active').invalid && this.formaRole.get('active').touched;
  }
  private createFormRole() {
    this.formaRole = this.fb.group({
      name: ['', [Validators.required, Validators.pattern(this.settings.get('validate')['name'])]],
      active: [true],
      description: [''],
    });
    // this.setRolesDefault();
  }
  private setFormUserProfile(data: Role) {
    this.dataRole = data;
    this.dataRole['description'] || ""
    this.formaRole.setValue({
      name: this.dataRole['name'] + '',
      active: this.dataRole['active'] ? true : false,
      description: this.dataRole['description'] + ""
    });
    this.setDataPermission(this.dataRole['permission']);
  }
  private initInterval() {
    this.interval.timer = setInterval(() => {
      this.interval.count++
      if (this.dataNecessary.modulesCheck == null || this.dataNecessary.permissionCheck == null)
        clearTimeout(this.interval.timer);
      else if ((this.dataNecessary.modulesCheck && this.dataNecessary.permissionCheck) || this.interval.count > 3000) {
        clearTimeout(this.interval.timer);
        this.setRolesDefault();
      }
    }, 10);
  }
  private setRolesDefault() {
    this.dataRole = null;
    if (this.pageAction['action'] == 'edit' || this.pageAction['action'] == 'read') {
      // if (existStorage(constants.ls.catalogRoles)) {
      //   let roles = getStorage(constants.ls.catalogRoles, true);
      //   let role = roles.filter(item => item.id == this.pageAction['uuid']);
      //   this.setFormUserProfile(role[0]);
      // }
      // else {
      this.roleDataService.readOne(this.pageAction['uuid'], this.session.accessToken.accessToken)
        .subscribe(
          (rs: ResponseData) => {
            if (getValid(rs, { title: `Error al obtener el role`, modalHelper: this.modalHelper, settings: this.settings })) {
              this.setFormUserProfile(getObject(rs));
            }
          },
          error => {
            console.error(error);
            getValid(error, { title: `Error`, modalHelper: this.modalHelper, settings: this.settings });
          }
        );
      // }
    }
    else if (this.pageAction['action'] == constants.screens.roles.create) {
      let role = new Role;
      role.name = '';
      role.active = false;
      role.permission = [];
      this.setFormUserProfile(role);
    }
  }
  public setDataPermission(modules: Array<Module>) {
    let data: Array<any> = [];
    this.modulesList.map(itemModule => {
      this.permissionsList.map(itemPermission => {
        modules.map(itemRoleModule => {
          if (slugifyPipe(itemRoleModule['module']) == slugifyPipe(itemModule.name)) {
            if (itemRoleModule.permission.length !== undefined) {
              itemRoleModule.permission.map(itemRolePermission => {
                let idPermission = itemRolePermission.hasOwnProperty('uuid') ? 'uuid' : 'id';
                if (itemRolePermission.hasOwnProperty(idPermission)) {
                  itemRolePermission[idPermission], itemPermission[idPermission]
                  if (itemRolePermission[idPermission] == itemPermission[idPermission]) {
                    let value = this.dataToSaveChecbox(itemRolePermission, itemModule);
                    if (data.includes(value) == false)
                      data.push(value)
                    return false;
                  }
                }
              });
            }
          }
        });
      });
    });
    this.dataNecessary.selectedCheck = true;
    this.dataPermission = [...data];
    this.dataPermissionRole = [...data];
  }
  private getModules() {
    // if (existStorage(constants.ls.catalogModules)) {
    //   this.modulesList = getStorage(constants.ls.catalogModules, true);
    //   this.dataNecessary.modulesCheck = true;
    //   this.getPermissions();
    //   return false;
    // }
    this.moduleDataService.read(this.session.accessToken.accessToken)
      .subscribe(
        (rs: ResponseData) => {
          if (getValid(rs, { title: `Error al obtener los roles`, modalHelper: this.modalHelper, settings: this.settings })) {
            this.modulesList = getArray(rs);
            if (this.modulesList.length) setStorage(constants.ls.catalogModules, this.modulesList);
            this.dataNecessary.modulesCheck = true;
            this.getPermissions();
          }
        },
        error => {
          console.error(error);
          getValid(error, { title: `Error`, modalHelper: this.modalHelper, settings: this.settings });
        }
      );
  }
  private getPermissions() {
    // if (existStorage(constants.ls.catalogPermissions)) {
    //   this.permissionsList = getStorage(constants.ls.catalogPermissions, true);
    //   this.permissionsList.map(item => {
    //     this.dataNecessary.selectedAllItems[item.name] = false;
    //   });
    //   this.dataNecessary.permissionCheck = true;
    //   return false;
    // }
    this.permissionDataService.read(this.session.accessToken.accessToken)
      .subscribe(
        (rs: ResponseData) => {
          if (getValid(rs, { title: `Error al obtener los roles`, modalHelper: this.modalHelper, settings: this.settings })) {
            this.permissionsList = getArray(rs).filter(item => item.sysname !== constants.permission.traduction);
            this.permissionsList.map(item => {
              this.dataNecessary.selectedAllItems[item.name] = false;
            });
            this.dataNecessary.permissionCheck = true;
            if (this.permissionsList.length) setStorage(constants.ls.catalogPermissions, this.permissionsList);
          }
        },
        error => {
          console.error(error);
          getValid(error, { title: `Error`, modalHelper: this.modalHelper, settings: this.settings });
        }
      );
  }
  selectedAllItems(permission) {
    let value = !this.dataNecessary.selectedAllItems[permission.name];
    this.dataNecessary.selectedAllItems[permission.name] = value;
    this.modulesList.map(itemModule => {
      let dataSelected = this.dataToSaveChecbox(permission, itemModule)
      let index = [];
      if (this.dataPermission.length) {
        this.dataPermission.map((itemP, keyP) => {
          if (itemP !== dataSelected) {
            if (value) {
              if (index.includes(dataSelected) == false)
                index.push(dataSelected);
            }
          } else {
            if (value == false) {
              if (index.includes(dataSelected) == false)
                index.push(keyP);
            }
          }
        });
      }
      else index.push(dataSelected);
      if (index.length) {
        index.map(itemIndex => {
          if (value == false) {
            this.dataPermission.splice(itemIndex, 1);
          }
          else {
            if (this.dataPermission.includes(itemIndex) == false) this.dataPermission.push(itemIndex);
          }
        });
      }
    });
  }
  selectedItem(permission, module) {
    let data = this.dataToSaveChecbox(permission, module)
    let index = this.dataPermission.indexOf(data);
    if (index !== -1) this.dataPermission.splice(index, 1);
    else this.dataPermission.push(data);
    this.isCheckedAll(permission);
  }
  // changePage($event: any) {
  // }
  saveRole() {
    if (this.formaRole.invalid) {
      return Object.values(this.formaRole.controls).forEach(control => {
        control.markAsTouched();
      });
    }
    let dataProfileUpdate = { ...this.formaRole.value };
    let values: any = this.setValueToChangeProfile(dataProfileUpdate);
    if (Object.keys(values).length) {
      let permissions = [];
      values['permission'].map(item => {
        getStorage(constants.ls.catalogModules, true).map(module => {
          if (module.name == item.module) {
            let moduleID = module.hasOwnProperty('id') ? module.id : module.uuid;
            permissions.push({
              id: moduleID,
              module: module.name,
              permission: item.permission
            });
          }
        });
      });
      let active = (this.formaRole.value.active === 'true' || this.formaRole.value.active === true) ? true : false;
      values = { ...this.dataRole, ...dataProfileUpdate, ...values, active: active };
      if (!values.name) {
        values.name = dataProfileUpdate.name
      }
      if (!values.systemName) {
        values.systemName = slugifyPipe(dataProfileUpdate.name, '_')
      }
      const { status, description, systemName, ...other } = values
      values = { ...other, system_name: systemName }
      values.active = true
      this.callToServiceAction(values, this.pageAction['action']);
    }
    else {
      let error = {
        show: true,
        message: labels.noDataToUpdate,
        title: 'Error al actualizar'
      }
      this.modalHelper.changeErrorModal(error);
    }
  }
  private callToServiceAction(data, action) {
    switch (action) {
      case constants.screens.roles.edit:
        this.roleDataService.update(this.pageAction['uuid'], data, this.session.accessToken.accessToken)
          .subscribe(
            (rs: ResponseData) => {
              if (getValid(rs, { title: `Error al actualizar el role`, modalHelper: this.modalHelper, settings: this.settings })) {
                this.alertNotificationService.success('Rol', 'Se actualizó correctamente el rol, los cambios tendrán efecto al iniciar sesión', { autoClose: true, keepAfterRouteChange: true });
                removeStorage(constants.ls.catalogRoles);
                this.goBack(constants.screens.roles.path);
              }
            },
            error => {
              console.error(error);
              this.alertNotificationService.success('Rol', 'Ocurrio un error al actualizar', { autoClose: true, keepAfterRouteChange: true });
            }
          );
        break;
      case constants.screens.roles.create:
        this.roleDataService.create(data, this.session.accessToken.accessToken)
          .subscribe(
            (rs: ResponseData) => {
              if (getValid(rs, { title: `Error al crear el role`, modalHelper: this.modalHelper, settings: this.settings })) {
                this.alertNotificationService.success(labels.role.create.title, labels.role.create.body, { autoClose: true, keepAfterRouteChange: true });
                removeStorage(constants.ls.catalogRoles);
                this.goBack(constants.screens.roles.path);
              }
            },
            error => {
              console.error(error);
              this.alertNotificationService.error('Rol', 'Error al crear el rol', { autoClose: true, keepAfterRouteChange: true });
            }
          );
        break;
    }
  }
  private setValueToChangeProfile(formValue: object) {
    let values = {};
    Object.keys(formValue).map(item => {
      if (this.dataRole[item]) {
        if (formValue[item].toString().trim() !== this.dataRole[item].toString().trim()) {
          values[item] = formValue[item];
        }
      }
    });
    // verificamos los campos que se quedaron en el arreglo.
    let itemRemove = [];
    let itemExist = [];
    this.dataPermissionRole.map((itemPRole) => {
      let exist = false;
      this.dataPermission.map(itemP => {
        if (itemPRole == itemP) {
          itemExist.push(itemPRole);
          exist = true;
          return false;
        }
      });
      if (exist == false) itemRemove.push(itemPRole);
    });
    let itemAdd = [];
    this.dataPermission.map((itemP) => {
      let exist = false;
      this.dataPermissionRole.map(itemPRole => {
        if (itemPRole == itemP) {
          exist = true;
          return false;
        }
      });
      if (exist == false) {
        itemAdd.push(itemP);
        itemExist.push(itemP);
      }
    });
    if (itemRemove.length || itemAdd.length) {
      let modules: Array<moduleR> = []
      let aux_id = [];
      if (itemExist.length) {
        itemExist.map(item => {
          let module = item.split(':')[0];
          let permission = item.split(':')[1];
          if (modules[aux_id.indexOf(module)] == undefined) {
            aux_id.push(module);
            modules.push({ id: parseInt(module.split(',')[0]), module: module.split(',')[1], permission: [] });
          }
          modules[aux_id.indexOf(module)].permission.push(
            {
              id: parseInt(permission.split(',')[0]),
              name: permission.split(',')[1],
              sysname: permission.split(',')[2],
            }
          );
        });
      }
      else if (itemRemove.length) {
        itemRemove.map(item => {
          let module = item.split(':')[0];
          if (modules[aux_id.indexOf(module)] == undefined) {
            aux_id.push(module);
            modules.push({ id: parseInt(module.split(',')[0]), module: module.split(',')[1], permission: [] });
          }
        });
      }
      values['permission'] = Object.values(modules);
    }
    return values;
  }
  goBack(url: string = null) {
    if (url) this.router.navigate([url]);
    else this.router.navigate([constants.screens.roles.path]);
  }
  isChecked(permission, module) {
    return this.dataPermission.includes(this.dataToSaveChecbox(permission, module));
  }
  isCheckedAll(permission) {
    let idPermission = permission.hasOwnProperty('uuid') ? 'uuid' : 'id';
    let permissionID = permission[idPermission];
    let count = 0;
    this.dataPermission.map(itemP => {
      if (itemP.indexOf(`:${permissionID},`) !== -1) count++;
    });
    if (this.modulesList.length == count) this.dataNecessary.selectedAllItems[permission.name] = true;
    else this.dataNecessary.selectedAllItems[permission.name] = false;
    return this.dataNecessary.selectedAllItems[permission.name];
  }
  private dataToSaveChecbox(permission, module) {
    let idPermission = permission.hasOwnProperty('uuid') ? 'uuid' : 'id';
    let idModule = module.hasOwnProperty('id') ? 'id' : 'uuid';
    return module[idModule] + ',' + (module.name) + ':' + permission[idPermission] + ',' + (permission.name) + ',' + (permission?.sysname ? permission?.sysname : permission?.systemname);
  }
}
