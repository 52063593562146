import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SeccionMonitorService } from "../../services/seccion-monitor.service";

@Component({
  selector: "app-details-dialog",
  templateUrl: "./details-dialog.component.html",
  styleUrls: ["./details-dialog.component.css"],
})
export class DetailsDialogComponent implements OnInit {
  sectionDetails: any = [];
  showSpinner: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<DetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private seccionesMonitorService: SeccionMonitorService
  ) {}

  async ngOnInit(): Promise<void> {
    this.showSpinner = true;
    this.dialogRef.updateSize("70%", "auto");
    (
      await this.seccionesMonitorService.getSectionBySectionId(
        this.data.sectionId
      )
    ).subscribe(
      (result) => {
        this.showSpinner = false;
        this.sectionDetails = result.data;
        console.log(this.sectionDetails);
      },
      (error) => {
        this.showSpinner = false;
        console.log(error);
      }
    );
  }
}
