import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'abarcms-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent implements OnInit, OnChanges {
  @Input() items: Array<any> = [];
  @Input() total: number = -1;
  @Input() initialPage: number = 1;
  @Input() perPage: number = 10;
  @Input() maxPages: number = 10;
  @Input() lblFirst: string = 'Primera';
  @Input() lblLast: string = 'Última';
  @Input() lblPrev: string = 'Anterior';
  @Input() lblNext: string = 'Siguiente';
  @Input() btnSec: boolean = true;
  @Input() btnPri: boolean = true;
  @Input() size: string = '';
  @Input() align: string = 'start';
  @Output() changePage = new EventEmitter<any>(true);

  initial = true
  pager: any = {};

  ngOnInit() {
    // set page if items array isn't empty
    this.setPage(this.initialPage);
  }

  ngOnChanges(changes: SimpleChanges) {
    // reset page if items array has changed
    if (changes?.items) {
      if (changes.items.currentValue !== changes.items.previousValue) {
        this.setPage(this.initialPage);
      }
    }
    else if (changes?.total) {
      if (changes.total.currentValue !== changes.total.previousValue) {
        this.setPage(this.initialPage);
      }
    }
  }

  setPage(page: number) {
    if (this.total > -1) {
      // get new pager object for specified page
      this.pager = this.paginate(this.total, page, this.perPage, this.maxPages, this.btnPri);
      // call change page function in parent component
      this.changePage.emit({
        call: !this.initial,
        current: page,
        perPage: this.perPage,
        total: this.total,
      });
      this.initial = false
    }
    else {
      // get new pager object for specified page
      this.pager = this.paginate(this.items.length, page, this.perPage, this.maxPages, this.btnPri);
      // get new page of items from items array
      var pageOfItems = this.items.slice(this.pager.startIndex, this.pager.endIndex + 1);
      // call change page function in parent component
      this.changePage.emit(pageOfItems);
    }
  }

  private paginate(totalItems: number, currentPage: number = 1, pageSize: number = 10, maxPages: number = 10, btnPri: boolean = true) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (!btnPri) {
      // total pages less than max so show all pages
      startPage = 1;
      endPage = totalPages;
    }
    else if (totalPages <= maxPages) {
      // total pages less than max so show all pages
      startPage = 1;
      endPage = totalPages;
    } else {
      // total pages more than max so calculate start and end pages
      let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
      let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
      if (currentPage <= maxPagesBeforeCurrentPage) {
        // current page near the start
        startPage = 1;
        endPage = maxPages;
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
        // current page near the end
        startPage = totalPages - maxPages + 1;
        endPage = totalPages;
      } else {
        // current page somewhere in the middle
        startPage = currentPage - maxPagesBeforeCurrentPage;
        endPage = currentPage + maxPagesAfterCurrentPage;
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }
}
