import { Component, OnInit, Input } from '@angular/core';
import { DashboardDataService } from 'src/app/services/data/dashboard-data.service';
import { SessionService } from 'src/app/services/session.service';
import { BasicUserInfo } from 'src/app/dto/BasicUserInfo';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SettingsService } from 'src/app/services/settings.service';
import { Role } from 'src/app/dto/Role';
import { RoleDataService } from 'src/app/services/data/role-data.service';
import { getValid, getArray, hiddenModalLoading } from 'src/app/utils/_http';
import { labels } from 'src/app/strings';
import { AlertNotificationService } from 'src/app/services/alert-notification.service';
import { existStorage, getStorage, setStorage } from 'src/app/utils/_utils';
import { constants } from 'src/app/constants';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { UsersDataService } from 'src/app/services/data/users-data.service';

interface IProps {
  roles: Role[];
  users: BasicUserInfo[];
}

@Component({
  selector: 'abarcms-dashboard-widget-users',
  templateUrl: './dashboard-widget-users.component.html',
  styleUrls: ['./dashboard-widget-users.component.css']
})

export class DashboardWidgetUsersComponent implements OnInit {
  @Input('data')
  public data: IProps = {
    roles: [],
    users: []
  };
  dataUser: BasicUserInfo = new BasicUserInfo;
  formaUser: FormGroup;
  onSelectedRole = false;
  loading = {
    status: true,
    roles: true
  }
  constructor(public dashboardDataService: DashboardDataService,
    public session: SessionService,
    public usersDataService: UsersDataService,
    public settings: SettingsService,
    protected fb: FormBuilder,
    private roleDataService: RoleDataService,
    private alertNotificationService: AlertNotificationService,
    private modalHelper: ModalHelperService
  ) {
    this.createFormUser();
  }
  ngOnInit() {
    this.getRoles();
  }
  // -- Validacion del formulario
  get validEmail() {
    return this.formaUser.get('email').invalid && this.formaUser.get('email').touched;
  }
  get validRole() {
    return this.formaUser.get('roles').value.length == 0 && this.onSelectedRole;
  }
  createFormUser() {
    this.formaUser = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(this.settings.get('validate')['email'])]],
      roles: [[]],
    });
    this.setUserDefault();
  }
  setUserDefault() {
    setTimeout(() => {
      this.formaUser.setValue({
        email: "",
        roles: []
      });
    }, 500);
  }
  setResetUserDefault() {
    this.formaUser.setValue({
      email: ""
    });
  }
  getDropdownSelected($event) {
    let roles = [];
    this.data.roles.map(item => {
      if ($event.includes(item.id)) roles.push({ id: item.id });
    });
    this.onSelectedRole = true;
    this.formaUser.patchValue({ roles: roles });
  }
  onAddUser() {
    this.onSelectedRole = true;
    if (this.formaUser.invalid || this.formaUser.get('roles').value.length == 0) {
      return Object.values(this.formaUser.controls).forEach(control => {
        control.markAsTouched();
      });
    }
    let dataUser = {
      ...this.formaUser.value,
      name: this.formaUser.get('email').value.split('@')[0] + '',
      lastname: this.formaUser.get('email').value.split('@')[0] + '',
      username: this.formaUser.get('email').value.split('@')[0] + '',
      password: this.formaUser.get('email').value + '',
      status: true,
    };
    this.usersDataService.create(dataUser, null)
      .subscribe((rs) => {
        if (rs) {
          if (getValid(rs, { title: `Error al crear el usuario`, modalHelper: this.modalHelper, settings: this.settings })) {
            this.alertNotificationService.success(labels.users.create.titlePage, labels.users.create.alert.success, { autoClose: true });
            this.modalHelper.changeLoading(false);
            this.setResetUserDefault();
          }
        }
      })
  }
  onCancelAddUser() {
    this.setResetUserDefault();
  }
  private getUsersInactives = () => {
    setTimeout(() => {
      this.dashboardDataService
        .getDashboardWidgetData("users")
        .subscribe(rs => {
          if (rs) {
            if (getValid(rs, { title: 'Error al obtener los usuarios inactivos', modalHelper: this.modalHelper, settings: this.settings })) {
              let users = getArray(rs);
              if (users.length) this.data.users = users.filter(item => !item.status);
              this.loading.status = false;
              hiddenModalLoading(this.loading, this.modalHelper);
            }
          }
        })
    }, 1000)
  }
  private getRoles() {
    if (existStorage(constants.ls.catalogRoles)) {
      this.data.roles = getStorage(constants.ls.catalogRoles, true).filter(item => item.active);
      this.loading.roles = false;
      hiddenModalLoading(this.loading, this.modalHelper);
    }
    else {
      this.roleDataService.read(this.session.accessToken.accessToken)
        .subscribe(rs => {
          if (rs) {
            if (getValid(rs, { title: `Error al obtener los roles`, modalHelper: this.modalHelper, settings: this.settings })) {
              this.data.roles = getArray(rs).filter(item => item.active);
              if (getArray(rs)) setStorage(constants.ls.catalogRoles, getArray(rs));
            }
            this.loading.roles = false;
            hiddenModalLoading(this.loading, this.modalHelper);
          }
        });
    }
    this.getUsersInactives();
  }
  onSetActiveUser(item: any, index: number) {
    setTimeout(() => {
      let data = { ...item, status: item.status }
      delete data.roles;
      delete data.last_signing;
      if (item.status) {
        this.usersDataService.update(data.uuid, data, this.session.accessToken.accessToken)
          .subscribe(rs => {
            if (rs) {
              if (getValid(rs, { title: `Error al activar el usuario`, modalHelper: this.modalHelper, settings: this.settings })) {
                this.data.users.splice(index, 1);
                this.alertNotificationService.success(labels.users.active.titlePage, labels.users.active.alert.success, { autoClose: true });
                this.modalHelper.changeLoading(false);
              }
            }
          });
      }
    });
  }
}
