<div class="container-fluid">
    <div class="card border-card">
        <div class="card-content">
            <div class="row mb-20">
                <div class="card__title col-xs-12 col-md-4 col-lg-4">
                    <h2>Plantillas</h2>
                </div>
                <div class="col-xs-12 col-md-4 col-lg-3">
                    <button type="button" class="btn btn-success" (click)="actionItem('create')">
                        <span class="fas fa-plus"></span>
                        Crear plantilla
                    </button>
                </div>
                
            </div>
            <div class="mt-30">
                <div id="contenido-table_wrapper" class="dataTables_wrapper no-footer">
                    <table style="width:100%" class="table">
                        <thead>
                            <tr>
                                <th>NOMBRE</th>
                                <th>ESTATUS</th>
                                <th>FECHA DE MODIFICACION</th>
                                <th>ACCIONES</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of plantillas">
                                <td>{{item.nombre}}</td>
                                <td>{{item.estatus}}</td>
                                <td>{{item.fechamodificacion}}</td>
                                <td>
                                    <div class="btn-group btn-group-sm" role="group" aria-label="Rol action">
                                        <button type="button" class="btn btn-primary" (click)="actionItem('edit', item)">
                                            <i class="fas fa-pencil-alt"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!--<div class="col-12 mt-4">
                <abarcms-paginator [total]="paginator.total" [initialPage]="paginator.current"
                    [perPage]="paginator.perPage" [maxPages]="5" [lblFirst]="'Primera'" [lblLast]="'Última'"
                    [lblPrev]="'Anterior'" [lblNext]="'Siguiente'" [btnSec]="true" [btnPri]="true"
                    (changePage)="changePage($event)" [align]="'center'"></abarcms-paginator>
            </div>-->
            <p *ngIf="plantillas.length == 0" class="alert alert-warning text-center">No se encontraron datos</p>
        </div>
    </div>
</div>
