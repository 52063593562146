import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MenuItem } from 'src/app/dto/MenuItem';
import { ResponseData } from 'src/app/dto';

@Injectable({
  providedIn: 'root'
})
export abstract class MenuDataService {

  abstract read(accessToken: string): Observable<ResponseData>;
  abstract readOne(idMenu: string, accessToken: string): Observable<ResponseData>;
}
