export * from 'src/app/services/data/ws/code-verify-data-ws.service';
export * from 'src/app/services/data/ws/dashboard-data-ws.service';
export * from 'src/app/services/data/ws/media-data-ws.service';
export * from 'src/app/services/data/ws/module-data-ws.service';
export * from 'src/app/services/data/ws/permission-data-ws.service';
export * from 'src/app/services/data/ws/role-data-ws.service';
export * from 'src/app/services/data/ws/session-data-ws.service';
export * from 'src/app/services/data/ws/statement-data-ws.service';
export * from 'src/app/services/data/ws/user-data-ws-service';
export * from 'src/app/services/data/ws/users-data-ws.service';
export * from 'src/app/services/data/ws/faq-data-ws.service';
export * from 'src/app/services/data/ws/media-guga-data-ws.service';
export * from 'src/app/services/data/ws/content-type-data-ws.service';
export * from 'src/app/services/data/ws/content-type-field-data-ws.service';
export * from 'src/app/services/data/ws/field-data-ws.service';
export * from 'src/app/services/data/ws/contents-data-ws.service';
export * from 'src/app/services/data/ws/contentsType-data-ws.service';
export * from 'src/app/services/data/ws/formularios-data-ws.service';
export * from 'src/app/services/data/ws/citas-data-ws.service';
