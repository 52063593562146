import { Injectable } from "@angular/core";
import { SeccionMonitorService } from "./seccion-monitor.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SettingsService } from "src/app/services";

@Injectable({
  providedIn: "root",
})
export class HttpMonitorService {
  private baseUrl: string;
  pathInfo: any;
  credentials: any;

  constructor(
    private settingsService: SettingsService,
    private http: HttpClient
  ) {
    this.pathInfo = this.settingsService.get("pathInfo");
    this.baseUrl = this.pathInfo.base.monitor.url;
    this.credentials = this.pathInfo.base.monitor.credentials;
  }

  getAccesToken(): Observable<any> {
    let url = this.baseUrl + "/v1/login";
    return this.http.post(url, this.credentials).pipe(
      map((json) => {
        const result = json;
        return result as any;
      })
    );
  }

  async getHeaders(): Promise<HttpHeaders> {
    const monitorToken = await this.getMonitorToken();
    return new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${monitorToken}`,
    });
  }

  async getMonitorUserId(): Promise<string> {
    let monitorUserId: string = "";

    const tempMonitorUserId = sessionStorage.getItem("monitorUserId");

    if (!tempMonitorUserId || tempMonitorUserId === "undefined") {
      try {
        const result = await this.getAccesToken().toPromise();
        sessionStorage.setItem("monitorToken", result.token);
        sessionStorage.setItem("monitorUserId", result.user_id);
        monitorUserId = result.user_id;
      } catch (error) {
        console.error("Error al obtener el token de acceso", error);
      }
    } else {
      monitorUserId = sessionStorage.getItem("monitorUserId");
    }

    return monitorUserId;
  }

  async getMonitorToken(): Promise<string> {
    let monitorToken: string = "";

    const tempMonitorToken = sessionStorage.getItem("monitorToken");

    if (!tempMonitorToken || tempMonitorToken === "undefined") {
      try {
        const result = await this.getAccesToken().toPromise();
        sessionStorage.setItem("monitorToken", result.token);
        sessionStorage.setItem("monitorUserId", result.user_id);
        monitorToken = result.token;
      } catch (error) {
        console.error("Error al obtener el token de acceso", error);
      }
    } else {
      monitorToken = sessionStorage.getItem("monitorToken");
    }

    return monitorToken;
  }
}
