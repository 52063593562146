import { Injectable } from '@angular/core';
import { SettingsService } from '../../settings.service';
import { ApiService } from '../../api.service';
import { ResponseData } from 'src/app/dto';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuDataWsService {
  private pathInfo: any;
  private baseUrl: string;
  private segment: any;
  constructor(
    private settings: SettingsService,
    private api: ApiService
  ) {
    this.pathInfo = this.settings.get('pathInfo');
    this.segment = this.pathInfo.res.admin.menu;
    this.baseUrl = this.pathInfo.base.baseUrl + this.pathInfo.base.prefix;
  }
  private read(accessToken): Observable<ResponseData> {
    let tokenHeader = {
      'X-Auth-Token': accessToken,
    }
    let endpoint = this.baseUrl + this.segment.prefix;
    return this.api.get(endpoint,tokenHeader);
  }
  private readOne(idMenu: string): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.group.replace(":group", idMenu);
    return this.api.get(endpoint);
  }
}
