export class Aviso {
    public title: string;
    public tipoAccion: number;
    public tipoEnvio: number;
    public message: string;
    public messagePush: string;
    public imageUrl: string;
    public userIds: Array<any>;
    public pageId: number;
    public displayGroupId: number;
    public groupSelected: any;
    public isMenssagePush: boolean;
    public pageSelected:any;

    public empresaSelected:any;
    public enfermedadSelected:any;
    public tipoSelected:any;
    public plantillaSelected:any;
    public edad:any;
    public cp:any;
    public usuariosSelected:Array<any>;

    constructor(data?: any, limpiarFiltro?: boolean) {
        this.title = data.title || '';
        this.tipoAccion = data.tipoAccion || '1';
        this.tipoEnvio = data.tipoEnvio || '1';
        this.message = data.message || '';
        this.messagePush = data.messagePush || '';
        this.imageUrl = data.imageUrl || '';
        this.groupSelected = data.groupSelected || null
        this.pageSelected = data.pageSelected || null

        if(limpiarFiltro){
            this.empresaSelected = null
            this.enfermedadSelected =  null
            this.tipoSelected = null
            this.plantillaSelected = null
            this.edad = null
            this.cp = null
            this.usuariosSelected =  null
        }
        else{
            this.empresaSelected = data.empresaSelected || null
            this.enfermedadSelected = data.enfermedadSelected || null
            this.tipoSelected = data.tipoSelected || null
            this.plantillaSelected = data.plantillaSelected || null
            this.edad = data.edad || null
            this.cp = data.cp || null
            this.usuariosSelected = data.usuariosSelected || null
        }
        

        this.isMenssagePush = data.isMenssagePush || false
    }
}

export class AvisoRule {
    public field: string;
    public fielddb: string;
    public condition: string;
    public value: string;

    constructor(data?: any) {
        this.field = data.field || ''
        this.fielddb = data.fielddb || ''
        this.condition = data.condition || ''
        this.value = data.value || ''
    }
}
export class AvisoRules {
    public field: string
    public fielddb: string
    public condition: string
    public value: string

    constructor(data) {
        this.field = data.field || ''
        this.fielddb = data.fielddb || ''
        this.condition = data.condition || ''
        this.value = data.value || ''
    }
}

export class AvisoDysplayGroupResponse {
    public type: number;
    public displayGroupId: number;

    public title: string;
    public message: string;
    public messagePush: string;
    public imageUrl: string;
    public status: boolean;

    constructor(data?: any) {
        this.type = data.tipoAccion || null
        this.displayGroupId = data.groupSelected ? data.groupSelected.id : null

        this.title = data.title || '';
        this.message = data.message || '';
        this.messagePush = data.messagePush || '';
        this.imageUrl = data.imageUrl || '';
        this.status = data.status || true
    }
}

export class AvisoPageResponse {
    public type: number;
    public pageId: number;

    public title: string;
    public message: string;
    public messagePush: string;
    public imageUrl: string;
    public status: boolean;

    constructor(data?: any) {
        this.type = data.tipoAccion || null
        this.pageId = data.pageSelected ? data.pageSelected.id : null

        this.title = data.title || '';
        this.message = data.message || '';
        this.messagePush = data.messagePush || '';
        this.imageUrl = data.imageUrl || '';
        this.status = data.status || true
    }
}

export class AvisoUsersResponse {
    public type: number;
    public userIds: Array<number>;

    public title: string;
    public message: string;
    public messagePush: string;
    public imageUrl: string;
    public status: boolean;

    constructor(data?: any) {
        this.type = data.tipoAccion || null
        this.userIds = data.userIds || null

        this.title = data.title || '';
        this.message = data.message || '';
        this.messagePush = data.messagePush || '';
        this.imageUrl = data.imageUrl || '';
        this.status = data.status || true
    }
}