import { Injectable } from '@angular/core';
import { SettingsService } from '../../settings.service';
import { Observable, Subject } from 'rxjs';
import { ResponseData, Log } from 'src/app/dto';
import { HttpClient } from '@angular/common/http';
import { makeHeaders, createResponseData } from 'src/app/utils/_http';
import { SessionService } from '../../session.service';
import { constants } from 'src/app/constants';

@Injectable({
  providedIn: 'root'
})
export class LogsDataWsService {
  private pathInfo: any;
  private baseUrl: string;
  private segment: any;
  constructor(
    private settings: SettingsService,
    private http: HttpClient,
    protected session: SessionService,
  ) {
    this.pathInfo = this.settings.get('pathInfo');
    this.segment = this.pathInfo.res.admin.log;
    this.baseUrl = this.pathInfo.base.baseUrl + this.pathInfo.base.prefix;
  }
  private createLog(module, action, field): any {
    let username = `${this.session.user.username}`;
    if (this.session.user.username == null || this.session.user.username == undefined) {
      let data = JSON.parse(field);
      if (data.hasOwnProperty('username')) username = data.username;
      else username = '-'
    }
    let log = new Log();
    log.module = module;
    log.actions = constants.logs[action];
    log.field = field;
    log.name = username;
    return log;
  }
  public create(module, action, field): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix;
    let options = {
    };
    let obs: Subject<ResponseData> = new Subject<ResponseData>();
    let body = this.createLog(module, action, field);
    if (body !== false) {
      this.http.post<ResponseData>(endpoint, body, makeHeaders(options))
        .subscribe((response: ResponseData) => {
          obs.next(createResponseData(response));
        })
      return obs.asObservable();
    }
  }
  public createServices(body): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.services;
    let options = {
      'services': true,
      'modal': false
    };
    let obs: Subject<ResponseData> = new Subject<ResponseData>();
    this.http.post<ResponseData>(endpoint, { data: JSON.stringify(body) }, makeHeaders(options))
      .subscribe((response: ResponseData) => {
        obs.next(createResponseData(response));
      })
    return obs.asObservable();
  }
}
