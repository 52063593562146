<div class="container-fluid">
    <div class="card border-card">
        <div class="card-content">
            <!--Cabecera-->
            <div class="row mb-20">
                <div class="card__title col-xs-12 col-md-4 col-lg-4">
                    <h2 class="title-list">Listado de notificaciones</h2>
                </div>

                <div class="col-xs-12 col-md-4 col-lg-3 mb-2">
                    <button (click)="actionItem('create')" class="btn btn-success">
                        <span class="fas fa-plus" aria-hidden="true"></span> Crear notificación
                    </button>

                </div>
                <div class="col-12 col-lg-5">
                    <abarcms-input-search [items]="notifications" [fieldsToSearch]="['title','status']"
                        (dataFound)="dataFound($event)" placeholder="Para buscar puede introducir el titulo ó estatus">
                    </abarcms-input-search>
                </div>
            </div>
            <!--Sub actions-->
            <!-- <div class="row" *ngIf="(permissions?.edit || permissions?.delete)">
                <div class="col-xs-12 col-sm-8 col-lg-2">
                    <div class="form-group">
                        <select class="form-control" [(ngModel)]="batchNotifications">
                            <option value="activar" *ngIf="permissions?.edit">Activar</option>
                            <option value="desactivar" *ngIf="permissions?.edit">Desactivar</option>
                            <option value="eliminar" *ngIf="permissions?.delete">Eliminar</option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-2">
                    <button type="button" class="btn btn-primary" (click)="onClick('batch')">
                        Ejecutar
                    </button>
                </div>
            </div> -->

            <!--Cuerpo-->
            <ng-container *ngIf="dataTable.showTable">
                <div class="mt-30">
                    <div id="contenido-table_wrapper" class="dataTables_wrapper no-footer">
                        <table style="width:100%">
                            <!--Cabecera de tabla-->
                            <thead>
                                <tr>
                                    <!-- <th>
                                        <span class="blue-checkbox">
                                            <input type="checkbox" (click)="selectedAllItems()"
                                                ([ngModel])="_selectedAllItems">
                                            <label for=""></label>
                                        </span>
                                    </th> -->
                                    <th>NOMBRE</th>
                                    <th>EMPRESA</th>
                                    <th>ESTATUS</th>
                                    <th>CREADO</th>
                                    <th>FRECUENCIA</th>
                                    <th>INICIA</th>
                                    <th>FINALIZA</th>
                                    <th>ACCIONES</th>
                                </tr>
                            </thead>
                            <!--Cuerpo de tabla-->
                            <tbody>
                                <tr *ngFor="let notification of notificationsList; let i = index">
                                    <!-- <td *ngIf="permissions?.edit || permissions?.delete">
                                        <span class="blue-checkbox">
                                            <input type="checkbox" [checked]="isChecked(notification)"
                                                (click)="selectedItem(notification)">
                                            <label></label>
                                        </span>
                                    </td> -->
                                    <td>{{ notification.title }}</td>
                                    <td>
                                        <abarcms-group-detail-list [show-name]="false" [bussness-data]="notification.groups"
                                            [click-event-enabled]="true" (clicked)="openBusiness($event)">
                                        </abarcms-group-detail-list>
                                    </td>
                                    <td>{{notification.active | estatus}}</td>
                                    <td>{{notification.created | date: 'dd/MM/yyyy'}}</td>
                                    <td>{{notification.schedule.frequency | titlecase}}</td>
                                    <td>{{notification.schedule.fromdate | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                                    <td>{{notification.schedule.todate | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                                    <td>
                                        <div class="btn-group btn-group-sm" role="group" aria-label="User action">
                                            <button type="button" class="btn btn-primary"
                                                (click)="actionItem('edit',notification.uuid)">
                                                <i class="fas fa-pencil-alt"></i>
                                            </button>
                                            <button type="button" class="btn btn-danger"
                                                (click)="actionItem('delete',notification)">
                                                <i class="fas fa-trash-alt"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-12 mt-3">
                        <abarcms-paginator [items]="notificationsPage" [initialPage]="paginator.current"
                            [perPage]="paginator.perPage" [maxPages]="5" [lblFirst]="'Primera'" [lblLast]="'Última'"
                            [lblPrev]="'Anterior'" [lblNext]="'Siguiente'" [btnSec]="true" [btnPri]="true"
                            (changePage)="changePage($event)" [align]="'center'"></abarcms-paginator>
                        <p *ngIf="notificationsPage.length == 0" class="alert alert-warning text-center">No se
                            encontraron
                            datos</p>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<abarcms-modal title="Eliminar notificación" *ngIf="deleteModal.show" (close)="deleteItem()" [btnMiddle]="'Cancelar'"
    [btnRight]="'Aceptar'" (middle)="deleteItem()" (right)="deleteItem(deleteModal.item)">
    <p class="alert alert-danger">Estas apunto de eliminar una notificación</p>
    <form class="text-left">
        <div class="form-group">
            <label for="id">Identificador</label>
            <input type="text" readonly class="form-control" value="{{deleteModal.item.uuid}}">
        </div>
        <div class=" form-group">
            <label for="name">Nombre de la encuesta</label>
            <input type="text" readonly class="form-control" value="{{deleteModal.item.title}}">
        </div>
    </form>
</abarcms-modal>