import { Injectable } from '@angular/core';
import { SettingsService } from '../../settings.service';
import { Observable } from 'rxjs';
import { ResponseData } from 'src/app/dto';
import { ApiService } from '../../api.service';

@Injectable({
  providedIn: 'root'
})
export class FieldDataWsService {
  private pathInfo: any;
  private baseUrl: string;
  private segment: any;
  constructor(
    private settings: SettingsService,
    private api: ApiService
  ) {
    this.pathInfo = this.settings.get('pathInfo');
    this.baseUrl  = this.pathInfo.base.baseUrl + this.pathInfo.base.prefix;
    this.segment  = this.pathInfo.res.admin['field'];
  }
  private read(): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix;
    return this.api.get( endpoint );
  }
}
