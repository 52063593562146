<div class="container-fluid polls-action" *ngIf="pollData.showView">
  <div class="card border-card">
    <div class="card-body">

      <div class="col-12 card__title">
        <h2 class="title-list">{{pageAction.titlePage}}</h2>
      </div>
      <div class="col-md-12">
        <form [formGroup]="form">

          <div class="row">
            <div class="col-12 col-lg-7">
              <div class="row">
                <!--Nombre de encuesta-->
                <div class="col-12">
                  <div class="card ">
                    <div class="card-body">
                      <div class="form-group">
                        <label class="title" for="inputNombreEncuesta">Título de la Encuesta</label>
                        <textarea class="form-control" cols="3" placeholder="Título de la Encuesta"
                          style="height: 73px !important;" formControlName="title" (keydown)="onAction('title')"
                          [ngStyle]="(f.title?.errors && (f.title.touched || f.title.dirty)) && {'border-color': 'red','margin-bottom': '3px'}"></textarea>
                        <div class=" invalidFieldForm" style="color: red;font-size: 13px;">
                          <div *ngIf="(f.title?.errors?.required) && (f.title.touched || f.title.dirty)">
                            Este campo es requerido
                          </div>
                          <div
                            *ngIf="(f.title?.errors?.minlength || f.title?.errors?.maxlength) && (f.title.touched || f.title.dirty)">
                            Debe contener mínimo de 5 hasta 150 caracteres
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--Estado del registro-->
            <div class="col-12 col-lg-5">
              <div class="card ">
                <div class="card-body">
                  <div class="form-group">
                    <label class="title" for="estadoGrupo">Estado</label>
                    <select class="form-control" formControlName="status" (change)="onAction('status')">
                      <option value="pendiente" *ngIf="!isFormDesactivated && !isFormPublicate">
                        Pendiente</option>
                      <option value="autorizado" *ngIf="!isFormDesactivated && !isFormPublicate">
                        Autorizado</option>
                      <option value="no_autorizado" *ngIf="!isFormDesactivated && !isFormPublicate">
                        No Autorizado</option>
                      <option value="desactivado" *ngIf="isFormDesactivated || isFormPublicate">
                        Desactivado</option>
                      <option value="publicado">
                        Publicado</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <!--Descripción de la Encuesta-->
            <div class="col-5">
              <div class="card">
                <div class="card-body">
                  <div class="form-group">
                    <label class="title">Descripción de la Encuesta</label>
                    <textarea class="form-control" cols="3" placeholder="Descripción de la Encuesta"
                      style="height: 73px !important;" formControlName="description"
                      [ngStyle]="(f.description?.errors && (f.description.touched || f.description.dirty)) && {'border-color': 'red','margin-bottom': '3px'}"></textarea>
                    <div class=" invalidFieldForm" style="color: red;font-size: 13px;">
                      <div
                        *ngIf="(f.description?.errors?.required || f.description?.errors?.minlength) && (f.description.touched || f.description.dirty)">
                        Este campo es requerido
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--Publicación de la encuesta-->
            <div class="col-7">
              <div class="row">
                <div class="pr-0"
                  [ngClass]="{'col-12': pollData?.typePublication == 'manual', 'col-5': pollData?.typePublication == 'automa'}">
                  <div class="card  left">
                    <div class="card-body">
                      <div class="form-group mb-0 d-flex flex-column justify-content-around">
                        <label class="title">Publicación de la encuesta</label>
                        <div class="form-check">
                          <input class="form-check-input mr-2" type="radio" name="publication" id="publicationManual"
                            [(ngModel)]="pollData.typePublication" value="manual" [ngModelOptions]="{standalone: true}">
                          <label class="form-check-label m-0 mr-2" for="publicationManual"
                            (click)="minDate('reset')">Publicación manual</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input mr-2" type="radio" name="publication" id="publicationAutoma"
                            [(ngModel)]="pollData.typePublication" value="automa" [ngModelOptions]="{standalone: true}">
                          <label class="form-check-label m-0 mr-2" for="publicationAutoma"
                            (click)="minDate('reset')">Publicación
                            programada</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="pollData?.typePublication == 'automa'">
                  <div class="col-7 pl-0">
                    <div class="card -date-right">
                      <div class="card-body px-2">
                        <form [formGroup]="formShedule">
                          <div class="form-group mb-0">
                            <label class="title" for="nombreEncuesta">Fechas de publicación</label>
                            <div class="form-group m-0 d-flex justify-content-center align-items-center">
                              <label class="sub-label mb-0 mr-2" for="mensajeNotificacion">Inicio</label>
                              <div class="form-group mb-0">
                                <div class="input-group"
                                  [ngStyle]="(fs?.startdate?.errors && (fs?.startdate.touched || fs?.startdate.dirty)) && {'border': '1px solid red'}">
                                  <input class="form-control mb-0" formControlName="startdate"
                                    [min]="minDate('startdate')" placeholder="yyyy-mm-dd"
                                    [owlDateTimeTrigger]="startdate" [owlDateTime]="startdate" selectMode="single"
                                    readonly>
                                  <owl-date-time [pickerType]="'both'" #startdate [hour12Timer]="false"
                                    (afterPickerClosed)="minDate('change')"></owl-date-time>
                                  <div class="input-group-append">
                                    <button class="btn btn-outline-secondary fas fa-calendar" type="button"
                                      [owlDateTimeTrigger]="startdate"></button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group m-0 d-flex justify-content-center align-items-center">
                              <label class="sub-label mb-0 mr-4" for="mensajeNotificacion">Fin</label>
                              <div class="form-group mb-0">
                                <div class="input-group"
                                  [ngStyle]="(fs?.enddate?.errors && (fs?.enddate.touched || fs?.enddate.dirty)) && {'border': '1px solid red'}">
                                  <input class="form-control mb-0" formControlName="enddate" [min]="minDate('enddate')"
                                    placeholder="yyyy-mm-dd" [owlDateTimeTrigger]="enddate" [owlDateTime]="enddate"
                                    selectMode="single" readonly>
                                  <owl-date-time [pickerType]="'both'" #enddate [hour12Timer]="false"
                                    (afterPickerClosed)="minDate('finish')"></owl-date-time>
                                  <div class="input-group-append">
                                    <button class="btn btn-outline-secondary fas fa-calendar" type="button"
                                      [owlDateTimeTrigger]="enddate"></button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class=" invalidFieldForm" style="color: red;font-size: 13px;">
                              <div
                                *ngIf="(fs?.startdate?.errors?.required || fs?.enddate?.errors?.required) && (fs?.startdate.touched || fs?.startdate.dirty || fs?.enddate.touched || fs?.enddate.dirty)">
                                Estos campos son requeridos
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-7">
              <!--secciones-->
              <app-polls-section [action]="pageAction.action" [data]="fsc.sections.value" (onAction)=" onAction($event)"
                [isFormPublicate]="isFormPublicate || isFormDesactivated">
              </app-polls-section>
            </div>
            <div class="col-5">
              <!--grupos / empresas-->
              <ng-container *ngIf="groupsShow">
                <app-polls-groups [action]="pageAction.action" [data]="fg.groups.value" (onAction)=" onAction($event)"
                  [groups]="groups">
                </app-polls-groups>
              </ng-container>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <app-polls-questions [action]="pageAction.action" [data]="f.sections.value" (onAction)=" onAction($event)"
                [pollTypeQuestions]="pollTypeQuestions" [isFormPublicate]="isFormPublicate || isFormDesactivated">
              </app-polls-questions>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-3">
              <button type="button" class="btn btn-dark btn-block" routerLink="/polls">
                Regresar
              </button>
            </div>
            <div class="col-12 col-md-3">
              <button type="button" class="btn btn-primary btn-block" (click)="onAction('save')">
                Guardar
              </button>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>