<div class="card">
    <div class="card-body">

        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label class="title" for="question-1">Pregunta</label>
                    <textarea class="form-control textarea" rows="4" [(ngModel)]="data.title"
                        (keydown)="onSection('systemname')" (blur)="onWrite('title')"
                        [ngClass]="{'is-invalid invalidFieldForm': (validateData.field?.title || validateData.field?.titleLength)}"
                        [ngStyle]="(validateData.field?.title || validateData.field?.titleLength) && {'border-color': 'red','margin-bottom': '3px'}"
                        [readonly]="isFormPublicate"></textarea>
                    <div class=" invalidFieldForm" style="color: red;font-size: 13px;">
                        <div *ngIf="validateData.field?.title">
                            Este campo es requerido
                        </div>
                        <div *ngIf="validateData.field?.titleLength">
                            Debe contener mínimo de 5 hasta 150 caracteres
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label class="title" for="question-options">
                        Opción de respuesta
                    </label>
                    <ng-select [items]="pollTypeQuestions" [multiple]="false" [closeOnSelect]="true"
                        [hideSelected]="true" bindLabel="name" placeholder="Selecciona un grupo"
                        [(ngModel)]="questionData.question.type" (change)="onSection('type')"
                        [ngModelOptions]="{standalone: true}"
                        [ngStyle]="validateData.field?.typeuuid && {'border': '1px solid red','margin-bottom': '3px'}"
                        [readonly]="isFormPublicate">
                    </ng-select>
                    <div class=" invalidFieldForm" style="color: red;font-size: 13px;">
                        <div *ngIf="validateData.field?.typeuuid">
                            Este campo es requerido
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row">
                    <div id="validationOption" class="col-6"
                        [ngClass]="{'col-12': !questionData.options.validationOption.show}">
                        <app-poll-question-type [data]="data.choices" [parent]="data" [section]="parent" [index]="index"
                            [type]="getTypeQuestion('name')" (onAction)="onActionChoices($event)" [isFormPublicate]="isFormPublicate">
                        </app-poll-question-type>

                        <ng-container *ngIf="questionData.options.descriptionOption.show">
                            <ng-container *ngIf="questionData.question.type?.systemname != 'opcion_multiple'">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Descripción"
                                        [(ngModel)]="data.description" [readonly]="isFormPublicate">
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                    <div class="col-6">
                        <ng-container *ngIf="questionData.options.validationOption.show">
                            <div class="form-group">
                                <ng-select [items]="questionData.question.options" [multiple]="false"
                                    [closeOnSelect]="true" [hideSelected]="true" bindLabel="name"
                                    placeholder="Selecciona un grupo" [(ngModel)]="questionData.question.option"
                                    (change)="onSection('option')" [ngModelOptions]="{standalone: true}"
                                    [ngStyle]="validateData.field?.validation && {'border': '1px solid red','margin-bottom': '3px'}">
                                </ng-select>
                            </div>
                            <div class="form-group">
                                <label class="title"
                                    *ngIf="questionData.question.type?.systemname != 'opcion_multiple'">
                                    Límite de caracteres
                                </label>
                                <input type="text" class="form-control"
                                    [(ngModel)]="questionData.options.validationOption.value"
                                    (keydown)="onSection('validation')" [ngModelOptions]="{standalone: true}"
                                    [ngStyle]="validateData.field?.validation && {'border': '1px solid red','margin-bottom': '3px'}"
                                    [readonly]="isFormPublicate">
                            </div>
                            <div class=" invalidFieldForm" style="color: red;font-size: 13px;">
                                <div *ngIf="validateData.field?.validation">
                                    Este campo es requerido
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <ng-container *ngIf="questionData.options.descriptionOption.show">
                        <div class="col-12" *ngIf="questionData.question.type?.systemname == 'opcion_multiple'">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Descripción"
                                    [(ngModel)]="data.description" [readonly]="isFormPublicate">
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <ng-container *ngIf="!isFormPublicate">
            <div class="row footer-question">
                <div class="col-12">
                    <ul class="d-flex justify-content-end my-2">
                        <li>
                            <button type="button" class="btn btn-primary icon-pulls mb-0" *ngIf="mode == 'question'"
                                placement="top" ngbTooltip="Agregar a listado de preguntas" (click)="onSection('add')">
                                <span aria-hidden="true" class="fas fa-plus-square"></span>
                            </button>
                        </li>
                        <li>
                            <button type="button" class="btn btn-primary icon-pulls mb-0" *ngIf="mode == 'question'"
                                placement="top" ngbTooltip="Duplicar pregunta" (click)="onSection('copy')">
                                <span aria-hidden="true" class="far fa-copy"></span>
                            </button>
                        </li>
                        <li>
                            <button type="button" class="btn btn-primary icon-pulls mb-0" *ngIf="mode == 'question'"
                                placement="top" ngbTooltip="Borrar pregunta" (click)="onSection('delete')">
                                <span aria-hidden="true" class="fas fa-trash"></span>
                            </button>
                        </li>
                        <!-- <li>
                            <div class="form-check align-items-center">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="data.required"
                                    id="question-response">
                                <label class="form-check-label" for="question-response">
                                    Respuesta obligatoria
                                </label>
                            </div>
                        </li> -->
                        <li class="btn-group dropdown">
                            <button type="button" class="question-options dropdown-toggle" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <span class="fas fa-ellipsis-v"></span>
                            </button>
                            <ul class=" dropdown-menu">
                                <ng-container *ngFor="let item of getOptions()">
                                    <li>
                                        <button type="button" (click)="onSection(item.sysname)"
                                            class="btn btn-link text-left p-0"
                                            [disabled]="!item.active || (questionData.question.type?.systemname != 'abierta' && item.sysname == 'validationOption')">
                                            {{item.name}}
                                        </button>
                                    </li>
                                </ng-container>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </ng-container>

    </div>
</div>