import { Component, OnInit, Input } from '@angular/core';
import { DashboardWidget } from 'src/app/dto/DashboardWidget';

@Component({
  selector: 'abarcms-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.css']
})
export class DashboardCardComponent implements OnInit {

  @Input('data')
  public data:DashboardWidget = null;

  constructor() { }

  ngOnInit() {
  }

}
