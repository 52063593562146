<div class="container-fluid">
  <div class="card border-card">
    <div class="card-body">
      <div class="row mt-20">
        <div class="col-12 card__title col-auto">
          <h3>Perfil del usuario</h3>
        </div>
      </div>
      <!--Datos del usuario-->
      <form [formGroup]="formaProfile">
        <div class="row mt-30">
          <div class="col-lg-6">
            <div class="form-group row">
              <label for="inputNombre" class="col-xs-12 col-md-12 col-lg-12 col-xl-12 col-form-label">Nombre(s)*</label>
              <div class="col-xs-12 col-md-12 col-lg-12 col-xl-12">
                <input type="text" class="form-control" formControlName="name" id="inputNombre"
                  [ngStyle]="(f.name?.errors && (f.name.touched || f.name.dirty)) && {'border-color': 'red','margin-bottom': '3px'}">
                <div class=" invalidFieldForm" style="color: red;font-size: 13px;">
                  <div *ngIf="(f.name?.errors?.required) && (f.name.touched || f.name.dirty)">
                    Este campo es requerido
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputApellidos"
                class="col-xs-12 col-md-12 col-lg-12 col-xl-12 col-form-label">Apellidos*</label>
              <div class="col-xs-12 col-md-12 col-lg-12 col-xl-12">
                <input type="text" class="form-control" formControlName="lastname" id="inputApellidos"
                  [ngStyle]="(f.lastname?.errors && (f.lastname.touched || f.lastname.dirty)) && {'border-color': 'red','margin-bottom': '3px'}">
                <div class=" invalidFieldForm" style="color: red;font-size: 13px;">
                  <div *ngIf="(f.lastname?.errors?.required) && (f.lastname.touched || f.lastname.dirty)">
                    Este campo es requerido
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="inptCorreo" class="col-xs-12 col-md-12 col-lg-12 col-xl-12 col-form-label">Correo
                electronico*</label>
              <div class="col-xs-12 col-md-12 col-lg-12 col-xl-12">
                <input type="email" class="form-control" formControlName="email" id="inptCorreo"
                  (keyup)="usernameField($event)"
                  [ngStyle]="(f.email?.errors && (f.email.touched || f.email.dirty)) && {'border-color': 'red','margin-bottom': '3px'}">
                <div class=" invalidFieldForm" style="color: red;font-size: 13px;">
                  <div *ngIf="(f.email?.errors?.required) && (f.email.touched || f.email.dirty)">
                    Este campo es requerido
                  </div>
                  <div *ngIf="(f.email?.errors?.email) && (f.email.touched || f.email.dirty)">
                    Ingresa un correo eléctronico válido
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <ng-container *ngIf="pageAction.action == 'create'">
                <label for="inputPassword"
                  class="col-xs-12 col-md-12 col-lg-12 col-xl-12 col-form-label">Contraseña*</label>
                <div class="col-xs-12 col-md-12 col-lg-12">
                  <div class="d-flex justify-content-between">
                    <input [type]="fieldPassword.inputPassword ? 'text' : 'password'" formControlName="password"
                      class="form-control"
                      [ngStyle]="(f.password?.errors && (f.password.touched || f.password.dirty)) && {'margin-bottom': '3px', 'border-color': 'red'}">
                    <span class="input-group-text" (click)="viewPassword('inputPassword')">
                      <i class="fa"
                        [ngClass]="{'fa-eye':!fieldPassword.inputPassword, 'fa-eye-slash':fieldPassword.inputPassword}"></i>
                    </span>
                  </div>
                  <div class=" invalidFieldForm" style="color: red; font-size: 13px">
                    <div *ngIf="(f.password?.errors?.required) && (f.password.touched || f.password.dirty)">
                      Este campo es requerido
                    </div>
                    <div *ngIf="(f.password?.errors?.passwordPattern) && (f.password.touched || f.password.dirty)">
                      {{f.password?.errors?.passwordPattern.value}}
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="pageAction.action == 'edit'">
                <label for="inputPassword"
                  class="col-xs-12 col-md-12 col-lg-12 col-xl-12 col-form-label">Contraseña*</label>
                <div class="input-group mb-3 col-xs-12 col-md-12 col-lg-12 col-xl-12">
                  <input type="password" class="form-control" readonly>
                  <div class="input-group-append">
                    <!-- <button class="btn btn-outline-secondary" *ngIf="isDots" (click)="unHidedots()"
                      type="button">...</button> -->
                    <button class="btn btn-outline-secondary" (click)="unHide()" type="button"
                      *ngIf="permissions.edit || permissions.create">Cambiar</button>
                  </div>
                </div>
              </ng-container>
            </div>
            <!--Formulario contraseña-->
            <div *ngIf="!isHidden">
              <form [formGroup]="formaPassword">
                <div class="form-group row">
                  <label for="newPassword" class="col-xs-12 col-md-12 col-lg-12 col-xl-3 col-form-label">Cambiar
                    contraseña*</label>
                  <div class="col-xs-12 col-md-12 col-lg-12 col-xl-9">
                    <div class="separador"></div>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="newPassword" class="col-xs-12 col-md-12 col-lg-12 col-xl-3 col-form-label">Nueva
                    contraseña*</label>
                  <div class="col-xs-12 col-md-12 col-lg-12 col-xl-9">
                    <div class="d-flex justify-content-between">
                      <input [type]="fieldPassword.newPassword ? 'text' : 'password'" class="form-control"
                        formControlName="password"
                        [ngStyle]="(fp.password?.errors && (fp.password.touched || fp.password.dirty)) && {'border-color': 'red','margin-bottom': '3px'}">
                      <span class="input-group-text" (click)="viewPassword('newPassword')">
                        <i class="fa"
                          [ngClass]="{'fa-eye':!fieldPassword.newPassword, 'fa-eye-slash':fieldPassword.newPassword}"></i>
                      </span>
                    </div>
                    <div class=" invalidFieldForm" style="color: red;font-size: 13px;">
                      <div *ngIf="(fp.password?.errors?.required) && (fp.password.touched || fp.password.dirty)">
                        Este campo es requerido
                      </div>
                      <div *ngIf="(fp.password?.errors?.passwordPattern) && (fp.password.touched || fp.password.dirty)">
                        {{fp.password.errors.passwordPattern.value}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="confirmNewPassword"
                    class="col-xs-12 col-md-12 col-lg-12 col-xl-3 col-form-label">Confirmar
                    contraseña *</label>
                  <div class="col-xs-12 col-md-12 col-lg-12 col-xl-9">
                    <div class="d-flex justify-content-between">
                      <input [type]="fieldPassword.confirmNewPassword ? 'text' : 'password'" class="form-control"
                        formControlName="passwordConfirm"
                        [ngStyle]="(fp.passwordConfirm?.errors && (fp.passwordConfirm.touched || fp.passwordConfirm.dirty)) && {'border-color': 'red','margin-bottom': '3px'}">
                      <span class="input-group-text" (click)="viewPassword('confirmNewPassword')">
                        <i class="fa"
                          [ngClass]="{'fa-eye':!fieldPassword.confirmNewPassword, 'fa-eye-slash':fieldPassword.confirmNewPassword}"></i>
                      </span>
                    </div>
                    <div class=" invalidFieldForm" style="color: red;font-size: 13px;">
                      <div
                        *ngIf="(fp.passwordConfirm?.errors?.required) && (fp.passwordConfirm.touched || fp.passwordConfirm.dirty)">
                        Este campo es requerido
                      </div>
                      <div
                        *ngIf="(fp.passwordConfirm?.errors?.passwordPattern) && (fp.passwordConfirm.touched || fp.passwordConfirm.dirty)">
                        {{fp.passwordConfirm.errors.passwordPattern.value}}
                      </div>
                      <div
                        *ngIf="(fp.passwordConfirm.value != fp.password.value) && (fp.passwordConfirm.touched || fp.passwordConfirm.dirty)">
                        La contraseña no es la misma
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-md-12 col-lg-12 col-xl-12 mt-sm-3">
                    <button class="btn btn-primary float-right" type="button" (click)="onChangePassword()"
                      *ngIf="permissions.edit || permissions.create">Guardar</button>
                  </div>
                </div>
              </form>
            </div>
            <div class="form-group row">
              <label for="inputTelefono"
                class="col-xs-12 col-md-12 col-lg-12 col-xl-12 col-form-label">Teléfono*</label>
              <div class="col-xs-12 col-md-12 col-lg-12 col-xl-12">
                <input type="tel" class="form-control" formControlName="telephone" id="inputTelefono" NumbersOnly
                  [allowDecimals]="false" [allowSign]="false"
                  [ngStyle]="(f.telephone?.errors && (f.telephone.touched || f.telephone.dirty)) && {'border-color': 'red','margin-bottom': '3px'}">
                <div class=" invalidFieldForm" style="color: red;font-size: 13px;">
                  <div *ngIf="(f.telephone?.errors?.required) && (f.telephone.touched || f.telephone.dirty)">
                    Este campo es requerido
                  </div>
                  <div *ngIf="(f.telephone?.errors?.pattern) && (f.telephone.touched || f.telephone.dirty)">
                    Ingresa un teléfono válido
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputExtension"
                class="col-xs-12 col-md-12 col-lg-12 col-xl-12 col-form-label">Extensión</label>
              <div class="col-xs-12 col-md-12 col-lg-12 col-xl-12">
                <input type="tel" class="form-control" formControlName="extension" id="inputExtension" NumbersOnly
                  [allowDecimals]="false" [allowSign]="false"
                  [ngStyle]="(f.extension?.errors && (f.extension.touched || f.extension.dirty)) && {'border-color': 'red','margin-bottom': '3px'}">
                <div class=" invalidFieldForm" style="color: red;font-size: 13px;">
                  <div *ngIf="(f.extension?.errors?.maxlength) && (f.extension.touched || f.extension.dirty)">
                    Longitud máxima 10 dígitos
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="" class="col-xs-12 col-md-12 col-lg-12 col-xl-12 col-form-label">Imagen de perfil</label>
              <div class="col-xs-12 col-md-12 col-lg-12 col-xl-12">
                <div *ngIf="!formaProfile.get('image').value.url">
                  <abarcms-file-upload [singleFile]="true" [typeFile]="'jpg,png,jpeg'" [sizeFile]="5"
                    [files]="media.files" [contentID]="null" [callServiceInThisComponent]="true"
                    (getFilesToUpload)="getFilesToUpload($event)" (isSingleFiles)="isSingleFile($event)"
                    [disabled]="(!permissions.edit && !permissions.create)">
                  </abarcms-file-upload>
                </div>
                <div *ngIf="formaProfile.get('image').value.url" class="image_wrapper">
                  <img [src]="formaProfile.get('image').value.url" style="width: 205px;">
                  <button type="button" class="btn btn-danger btn-block btn-delete-imagen"
                    [disabled]="!formaProfile.get('image').value.url" (click)="resetPicture()"
                    *ngIf="permissions.edit || permissions.create"><i class="fas fa-trash-alt"></i></button>
                </div>

              </div>
            </div>
          </div>
          <div class="col-lg-5 offset-lg-1">
            <div class="form-group">
              <label for="selectRolUsuario">Rol del usuario</label>
              <select class="form-control" id="selectRolUsuario" (change)="onRoleChange($event)"
                [ngStyle]="f.roles.value.length == 0 && roleChange && {'border-color': 'red','margin-bottom': '3px'}">
                <option value="">Seleccione</option>
                <option value="{{role.id}}" *ngFor="let role of roles">{{role.name}}</option>
              </select>
              <div class=" invalidFieldForm" style="color: red;font-size: 13px;">
                <div *ngIf="f.roles.value.length == 0 && roleChange">
                  Este campo es requerido
                </div>
              </div>
            </div>
            <div>
              <div class="card" style="box-shadow: none;">
                <div class="card-body">
                  <app-dropdown-groups-business [action]="pageAction.action" [data]="f.groups.value"
                    (onAction)="changeGroup($event)" [permissions]="permissions"></app-dropdown-groups-business>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="botonera">
          <button type="button" class="btn btn-dark float-left btn-block m-0" routerLink="/users">Cancelar</button>
          <button type="button" class="btn btn-primary btn-block m-0" (click)="saveProfile()"
            *ngIf="showButtonSave">Guardar</button>
        </div>
      </form>
    </div>
  </div>
</div>

<abarcms-modal title="Eliminar encuesta" *ngIf="deleteModal.show" (close)="deleteItem()" [btnMiddle]="'Cancelar'"
  [btnRight]="'Aceptar'" (middle)="deleteItem()" (right)="deleteItem(deleteModal)">
  <p class="alert alert-danger">Estas apunto de eliminar estas empresas</p>
  <form class="text-left">
    <div class="form-group">
      <div *ngFor="let item of deleteModal.item">
        <p>
          <span><b>Empresa</b>:</span> {{item.BusinessName}}
        </p>
      </div>
    </div>
  </form>
</abarcms-modal>
