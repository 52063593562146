import { Component, OnInit, Input } from "@angular/core";
import { DashboardDataService } from "src/app/services/data/dashboard-data.service";
import { SessionService } from "src/app/services/session.service";
import { Log } from "src/app/dto/Log";
import { getValid, getArray, getStatus, getMessage } from 'src/app/utils/_http';
import { SettingsService } from 'src/app/services';
import { ModalHelperService } from 'src/app/services/modal-helper.service';

@Component({
  selector: "abarcms-dashboard-widget-logs",
  templateUrl: "./dashboard-widget-logs.component.html",
  styleUrls: ["./dashboard-widget-logs.component.css"]
})
export class DashboardWidgetLogsComponent implements OnInit {
  @Input("data")
  public data;
  public logData: Log[] = [];

  constructor(
    public dashboardDataService: DashboardDataService,
    public session: SessionService,
    private settings: SettingsService,
    private modalHelper: ModalHelperService
  ) { }

  ngOnInit() {
    if (!this.data.length) this.loadData();
  }

  private loadData = () => {
    this.dashboardDataService
      .getDashboardWidgetData("logs")
      .subscribe(rs => {
        if (rs) {
          if (getValid(rs, { title: `Error al obtener los logs`, modalHelper: this.modalHelper, settings: this.settings })) {
            this.logData = getArray(rs);
            this.modalHelper.changeLoading(false);
          }
        }
      }
      );
  }
}
