export class MediaGuga {
    id: number;
    contentType: string;
    name: string;
    image: string;
    position: number;
    property1: string;
    property2: string;
    summary: string;
    constructor(
        opt: {
            id?: number,
            contentType?: string,
            name?: string,
            image?: string,
            position?: number,
            property1?: string,
            property2?: string,
            summary?: string
        } = {}
    ) {
        this.id = opt.id || 0;
        this.contentType = opt.contentType || '';
        this.name = opt.name || '';
        this.image = opt.image || '';
        this.position = opt.position || 0;
        this.property1 = opt.property1 || '';
        this.property2 = opt.property2 || '';
        this.summary = opt.summary || '';
    }
}
