import { Pipe, PipeTransform } from '@angular/core';
import { isArray } from 'util';

@Pipe({
  name: 'getProperty'
})
export class GetPropertyPipe implements PipeTransform {

  transform(value: Array<object>, ...args: string[]): unknown {
    if (args.length) {
      let label = [];
      let data = value;
      args.map(itemArg => {
        [].map.call(data, (itemData) => {
          if (itemData.hasOwnProperty(itemArg)) label.push(itemData[itemArg]);
        });
      });
      return label.join(', ');
    }
    return null;
  }

}
