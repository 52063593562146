import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseData } from 'src/app/dto/ResponseData';
import { SettingsService } from '../../settings.service';
import { ApiService } from '../../api.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageDataWsService {
  private pathInfo: any;
  private baseUrl: string;
  private segment: any;
  constructor(
    private settings: SettingsService,
    private api: ApiService
  ) {
    this.pathInfo = this.settings.get('pathInfo');
    this.baseUrl = this.pathInfo.base.baseUrl + this.pathInfo.base.prefix;
    this.segment = this.pathInfo.res.admin['language'];
  }
  private create(body: object, accessToken: string): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix;
    return this.api.post(endpoint, body);
  }
  private read(accessToken: string): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix;
    return this.api.get(endpoint);
  }
  private readOne(uuid: any, accessToken: string): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.getOne.replace(':uuid', uuid);
    return this.api.get(endpoint);
  }
  private update(uuid: any, body: object, accessToken: string): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.edit.replace(':uuid', uuid);
    return this.api.put(endpoint, body);
  }
  private delete(uuid: any, accessToken: string): Observable<ResponseData> {
    let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.delete.replace(':uuid', uuid);
    return this.api.delete(endpoint, uuid);
  }
}
