import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { constants } from 'src/app/constants';
import { BasicUserInfo } from 'src/app/dto';
import { SessionService, SettingsService } from 'src/app/services';
import { RoleDataService } from 'src/app/services/data/role-data.service';
import { UsersDataService } from 'src/app/services/data/users-data.service';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { getValid } from 'src/app/utils/_http';

@Component({
    selector: 'app-confirm-delete',
    templateUrl: './confirm-delete.component.html',
    styleUrls: ['./confirm-delete.component.css']
})
export class ConfirmDeleteComponent implements OnInit {

    @Input('title')
    public title: string;
    @Input('item')
    public item: any;
    @Input('message') public message: string;

    users: Array<BasicUserInfo> = [];

    constructor(
        private session: SessionService,
        public settings: SettingsService,
        private router: Router,
        private modalHelper: ModalHelperService,
        public activeModal: NgbActiveModal,
        public usersDataService: UsersDataService,
        public roleDataService: RoleDataService
    ) { }

    ngOnInit(): void {
    }

    onClose() {
        this.activeModal.close('CLOSED');
    }
    onConfirmDelete() {
        if (this.title == "Eliminar Usuario") {
            this.usersDataService.delete(this.item.uuid, this.session.accessToken)
                .subscribe(rs => {
                    if (getValid(rs, { title: `Error al eliminar el usuario`, modalHelper: this.modalHelper, settings: this.settings })) {
                        let users = this.users.filter(item => {
                            return item.uuid !== this.item.uuid;
                        })
                    }
                });
            setTimeout(() => {
                this.router.navigate([constants.screens.users.path]);
                this.activeModal.close('CONFIRMED');
            }, 1000);
        }
        if (this.title == "Eliminar Rol") {
            this.roleDataService.delete(this.item.id, this.session.accessToken)
                .subscribe(rs => {
                    if (getValid(rs, { title: `Error al eliminar el rol`, modalHelper: this.modalHelper, settings: this.settings })) {
                        let users = this.users.filter(item => {
                            return item !== this.item.id;
                        })
                    }
                });
            setTimeout(() => {
                this.router.navigate([constants.screens.roles.path]);
                this.activeModal.close('CONFIRMED');
            }, 1000);
        }
        else {
            this.activeModal.close('CONFIRMED');
        }
    }
    onCancelDelete() {
        this.activeModal.close('CANCELED');
    }

}
