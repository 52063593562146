import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseData } from 'src/app/dto/ResponseData';

@Injectable({
  providedIn: 'root'
})
export abstract class PollDataService {
  abstract create(body: any): Observable<ResponseData>;
  abstract read(pagination: any, searchText?: string): Observable<ResponseData>;
  abstract readOne(uuid: any): Observable<ResponseData>;
  abstract update(uuid: any, body: any): Observable<ResponseData>;
  abstract updateWithOutSection(uuid: any, body: any): Observable<ResponseData>;
  abstract batch(body: any): Observable<ResponseData>;
  abstract typeQuestion(): Observable<ResponseData>;
  abstract repositoryCreate(body: any): Observable<ResponseData>;
  abstract repositoryRead(pagination?: any): Observable<ResponseData>;
  abstract repositoryReadOne(uuid: any): Observable<ResponseData>;
  abstract repositoryUpdate(uuid: any, body: any): Observable<ResponseData>;
  abstract monitorLogin(): Observable<ResponseData>;
  abstract monitorListPoll(data: any, system: string): Observable<ResponseData>;
  abstract monitorAddPoll(body: any, data: any): Observable<ResponseData>;
  abstract monitorUpdatePoll(body: any, data: any): Observable<ResponseData>;
  abstract monitorAddBusiness(body: any, data: any): Observable<ResponseData>;
  abstract monitorAddSection(body: any, data: any): Observable<ResponseData>;
  abstract monitorAddQuestion(body: any, data: any): Observable<ResponseData>;
  abstract monitorActivated(data: any): Observable<ResponseData>;
  abstract readCountries(accessToken): Observable<ResponseData>;
  abstract readPolls(body: any, accessToken: string): Observable<ResponseData>;
  abstract updateGroups(body: any): Observable<ResponseData>;
  abstract sendEmail(body: any): Observable<ResponseData>;
}
