import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'colorcontent'
})
export class ColorContentPipe implements PipeTransform {
    colorString: string;
    transform(value: string): string {
        switch (value) {
            case 'blue':
                this.colorString = 'Azul';
                break;
            case 'green':
                this.colorString = 'Verde';
                break;
            case 'yellow':
                this.colorString = 'Amarillo';
                break;
            default:
                this.colorString = 'Rojo'
                break;
        }
        return this.colorString;
    }
}
