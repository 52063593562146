import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinArray'
})
export class JoinArrayPipe implements PipeTransform {

  transform(value: string[] = null, character: string = null): any {
    if (Array.isArray(value)) {
      let char = character ?? ',';
      return value.join(char);
    }
  }

}
