<abarcms-loader-full *ngIf="loading"></abarcms-loader-full>

<div class="container-fluid">
    <div class="card border-card">
        <div class="card-content">
            <!--Cabecera-->
            <div class="row mb-20">
                <div class="card__title col-xs-12 col-md-4 col-lg-4">
                    <h2 class="title-list">Listado de grupos</h2>
                </div>
                
                <div class="col-xs-12 col-md-4 col-lg-3 mb-2">
                    <button type="button" class="btn btn-success" (click)="actionItem('create')" *ngIf="permissions.create">
                        <span class="fas fa-plus" aria-hidden="true"></span> Crear grupo
                    </button>
                    
                </div>
                <div class="col-12 col-lg-5">
                    <div class="form-group mb-0">
                        <input type="text" class="form-control input-search mb-0" [(ngModel)]="searchText"
                            (change)="onSearchChange($event)" placeholder="Buscar...">
                    </div>
                </div>
            </div>

            <!--Cuerpo-->
            <div class="mt-30">
                <div id="contenido-table_wrapper" class="dataTables_wrapper no-footer mb-3">
                    <table style="width:100%">
                        <!--Cabecera de tabla-->
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Estado</th>
                                <th>Creado</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <!--Cuerpo de tabla-->
                        <tbody>
                            <tr *ngFor="let group of groups; let i = index">
                                <td>{{group.name}}</td>
                                <td>{{group.status | estatus}}</td>
                                <td>
                                    {{group.created_at | date:'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    <div class="btn-group btn-group-sm" role="group" aria-label="User action">
                                        <div class="containerActivate">
                                            <button type="button" class="btn buttonPencil" [class]="!group.status ? 'btn-secondary disbledEdit':'btn-primary'" *ngIf="permissions.edit"
                                                (click)="editItem(group)" [disabled]="!group.status">
                                                <i class="fas fa-pencil-alt"></i>
                                            </button>
                                        </div>
                                        <div class="containerActivate"  *ngIf="group.status">
                                            <button type="button" class="btn btn-primary button" (click)="actionItem('listCompany', group)" style="padding: 0px;">
                                                <i class="fas fa-building"></i>
                                            </button>
                                        </div>
                                        <ng-container>
                                            <div class="containerActivate">
                                                <button class="btn buttonActivate" [class]="permissions.delete ? 'btn-success':'btn-secondary'" (click)="statusGroup(group)">
                                                    <i class="fas fa-toggle-on" title="Desactivar" *ngIf="permissions.delete"></i>
                                                    <i class="fas fa-toggle-off" title="Activar" *ngIf="!permissions.delete"></i>
                                                </button>
                                            </div>
                                        </ng-container>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-12 mt-4">
                    <abarcms-paginator [total]="paginator.total" [initialPage]="paginator.current"
                        [perPage]="paginator.perPage" [maxPages]="5" [lblFirst]="'Primera'" [lblLast]="'Última'"
                        [lblPrev]="'Anterior'" [lblNext]="'Siguiente'" [btnSec]="true" [btnPri]="true"
                        (changePage)="changePage($event)" [align]="'center'"></abarcms-paginator>
                </div>
                <p *ngIf="!groups.length" class="alert alert-warning text-center">No se encontraron datos</p>
            </div>
        </div>
    </div>
</div>