import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ResponseData } from "src/app/dto";
import { makeHeaders } from "src/app/utils/_http";
import { SettingsService } from "../..";
import { ApiService } from "../../api.service";
import { SessionService } from "../../session.service";

@Injectable({
    providedIn: 'root'
})
export class GroupDataWsService {
    private pathInfo: any;
    private baseUrl: string;
    private segment: any;
    constructor(
        private settings: SettingsService,
        private session: SessionService,
        private api: ApiService,
    ) {
        this.pathInfo = this.settings.get('pathInfo');
        this.baseUrl = this.pathInfo.base.baseUrl + this.pathInfo.base.prefix;
        this.segment = this.pathInfo.res.admin['group'];
    }

    private create(body: object, accessToken: string): Observable<ResponseData> {
        let endpoint = this.baseUrl + this.segment.prefix;
        return this.api.post(endpoint, body, true, true);
    }

    private read(pagination: any, searchText?: string): Observable<ResponseData> {
        let options = {
            'X-Auth-Token': this.session.accessToken.accessToken,
            'X-Current-Page': pagination.current,
            'X-Items-Page': pagination.perPage,
            'X-Search': searchText,
        };
        let endpoint = this.baseUrl + this.segment.prefix;
        return this.api.get(endpoint, options);
    }

    private readOne(uuid: any, accessToken?: string): Observable<ResponseData> {
        let options = {
            'X-Auth-Token': this.session.accessToken.accessToken,
            'uuid': uuid
        };
        let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.getOne.replace(':uuid', uuid);

        return this.api.get(endpoint, options);
    }

    private readDropdown(accessToken?: string): Observable<ResponseData> {
        let options = {
            'X-Auth-Token': this.session.accessToken.accessToken,
        };
        let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.list;
        return this.api.get(endpoint);
    }

    private update(uuid: any, body: object, accessToken: string): Observable<ResponseData> {
        let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.edit.replace(':uuid', uuid);
        return this.api.put(endpoint, body, true, true);
    }

     /*  private delete(uuid: any, accessToken: string): Observable<ResponseData> {
        let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.delete.replace(':uuid', uuid);
        return this.api.delete(endpoint, uuid, true, true);
    }*/
    private delete(body: object, accessToken: string): Observable<ResponseData> {
        let endpoint = this.baseUrl + this.segment.prefix;
        return this.api.patch(endpoint, body, true, true);
    }
    private token(body: object, accessToken: string): Observable<ResponseData> {
        let endpoint = this.baseUrl + this.segment.prefix + this.segment.resources.token;
        return this.api.post(endpoint, body, true, true);
    }
}
