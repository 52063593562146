<abarcms-loader-full *ngIf="loading"></abarcms-loader-full>
<div class="container-fluid">
    <div class="card border-card">
        <div class="card-content size" >
          <h6>Ordenar elementos</h6>
          <div class="divOrder">
            <ul id="sortable-list" #sortableList>
              <li *ngFor="let seccion of secciones">
                <div>
                  <img class="icon" [src]="seccion.icon" >
                </div>
                <div (mousedown)="disableSortable($event)" (mouseup)="enableSortable($event,seccion.id)">
                  <p>{{seccion.name|uppercase}}</p>
                </div>
              </li>
            </ul>
          </div>
          <app-boton [textContent]="'Guardar'" (click)="saveOrderSections()"></app-boton>
        </div>
    </div>
</div>
