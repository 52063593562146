import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { constants } from "src/app/constants";
import { AlertNotificationService, SettingsService } from "src/app/services";
import { MediaService } from "src/app/services/media.service";
import { ModalHelperService } from "src/app/services/modal-helper.service";
import { labels } from "src/app/strings";
import { getValid } from "src/app/utils/_http";
import { getPermissionsPage, permissionPageUser } from "src/app/utils/_utils";
import { GruposService } from "src/app/screens/admin/seccion-monitor/services/groups.service";
import { ConfirmComponent } from "src/app/views/confirm/confirm.component";

@Component({
  selector: "app-group-profile",
  templateUrl: "./group-profile.component.html",
  styleUrls: ["./group-profile.component.css"],
})
export class GroupProfileComponent implements OnInit {
  loading: boolean = false;
  errors: any = {};
  group: any = {};

  pageAction: any = {
    action: null,
    uuid: null,
    titlePage: null,
  };
  media: any = {
    filesToUpload: [],
    files: [],
    isSingleFiles: null,
    mediaChange: false,
  };
  permissions: any;
  mediaObtained: string = null;
  showUIitemsEdit: boolean;
  //group: Group;

  showButtonSave: boolean = false;

  constructor(
    private modalSrv: NgbModal,
    private router: Router,
    protected mediaService: MediaService,
    private gruposService: GruposService,
    private modalHelper: ModalHelperService,
    public settings: SettingsService,
    protected alertNotificationService: AlertNotificationService
  ) {}

  ngOnInit(): void {
    this.pageAction = {
      action:
        constants.screens.groups[this.router.url.split("/").pop()] ?? null,
      uuid: this.router.url.split("/")[2] ?? null,
      titlePage:
        constants.screens.groups[this.router.url.split("/").pop()] ==
        constants.screens.groups.edit
          ? labels.groups.update.titlePage
          : constants.screens.groups[this.router.url.split("/").pop()] ==
            constants.screens.groups.create
          ? labels.groups.create.titlePage
          : labels.profile.titlePage,
    };
    this.permissions = getPermissionsPage(this.router.url);
    this.showButtonSave = permissionPageUser(
      this.permissions,
      this.pageAction.action,
      this.router,
      "/groups-business"
    );
    this.setUp();
  }

  async setUp(): Promise<void> {
    if (
      (this.pageAction["action"] === "edit" ||
        this.pageAction["action"] === "read") &&
      this.pageAction["uuid"] !== null
    ) {
      let idGrupo = parseInt(this.pageAction["uuid"], 10);
      if (Number.isInteger(idGrupo)) {
        this.showUIitemsEdit = true;
        (await this.gruposService.getFindById(idGrupo)).subscribe((_res) => {
          if (
            getValid(_res, {
              title: `Error al obtener el grupo`,
              modalHelper: this.modalHelper,
              settings: this.settings,
            })
          ) {
            this.group = _res.body.group;
            let ext = this.group.phone.split("Ext");
            this.group.phone = ext[0].trim();
            if (ext.length > 1) {
              this.group.ext = ext[1].trim();
            } else {
              this.group.ext = "";
            }
            this.modalHelper.changeLoading(false);
          }
        });
      }
    } else {
      this.modalHelper.changeLoading(false);
    }
  }

  async guardarItem(action) {
    if (this.validaFormulario()) {
      return;
    }

    this.loading = true;
    let idGrupo = parseInt(this.pageAction["uuid"], 10);
    let grupo = {
      nombre: this.group.name,
      telefono:
        this.group.phone +
        (this.group.ext != null && this.group.ext.length > 0
          ? " Ext " + this.group.ext
          : ""),
      correo: this.group.email,
      imagen: "",
      estatus: true,
    };
    (
      await this.gruposService.saveGroup(
        grupo,
        Number.isInteger(idGrupo) ? idGrupo : null
      )
    ).subscribe(
      (resp) => {
        if (action == "create") {
          setTimeout(() => {
            this.loading = false;
            this.alertNotificationService.info(
              "Aviso",
              "El registro se guardo con éxito",
              { autoClose: true, keepAfterRouteChange: true }
            );
            let modal = this.modalSrv.open(ConfirmComponent);
            modal.componentInstance.title = "Aviso";
            modal.componentInstance.message =
              "Para continuar con el proceso será redirigido para dar de alta las empresas del grupo.";
            modal.result.then((_response) => {
              if (_response === "CONFIRMED") {
                this.loading = true;
                this.router.navigate([
                  "/empresas/0/" + resp.body.group.group_id + "/empresa_nueva",
                ]);
              }
            });
          }, 1000);
        } else {
          this.loading = false;
          this.alertNotificationService.info(
            "Aviso",
            "El registro se guardo con éxito",
            { autoClose: true, keepAfterRouteChange: true }
          );
          this.router.navigate(["/groups-business"]);
        }
      },
      (error) => {
        this.loading = false;
        this.erroresServicios(error);
      }
    );
  }
  cancel() {
    this.router.navigate([constants.screens.groups.path]);
  }

  validaFormulario() {
    let banderaErrores = false;
    if (this.validaNombre()) {
      banderaErrores = true;
    }
    if (this.validaTelefono()) {
      banderaErrores = true;
    }
    if (this.validaCorreo()) {
      banderaErrores = true;
    }
    return banderaErrores;
  }

  validaError(error) {
    if (
      error != undefined &&
      error != null &&
      error != "" &&
      error != "undefined"
    ) {
      return true;
    }
    return false;
  }

  validaNombre() {
    this.errors.name = "";
    if (!this.validaError(this.group.name)) {
      this.errors.name = "Este campo es requerido.";
      return true;
    }
    return false;
  }

  validaCorreo() {
    let matchCorreo = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    this.errors.email = "";
    if (!this.validaError(this.group.email)) {
      this.errors.email = "Este campo es requerido.";
      return true;
    } else if (!this.group.email.match(matchCorreo)) {
      this.errors.email = "Ingresa un correo electrónico válido.";
      return true;
    }
    return false;
  }

  validaTelefono() {
    this.errors.phone = "";
    let matchNumero = /^[0-9]{10}$/g;
    if (!this.validaError(this.group.phone)) {
      this.errors.phone = "Este campo es requerido.";
      return true;
    } else if (!this.group.phone.match(matchNumero)) {
      this.errors.phone =
        "Ingresa un teléfono válido, debe contener solo números.";
      return true;
    }
    return false;
  }

  erroresServicios(error) {
    if (
      error.error.mensajeError != undefined &&
      error.error.mensajeError != null &&
      error.error.mensajeError != ""
    ) {
      this.alertNotificationService.error("Error", error.error.mensajeError, {
        autoClose: true,
        keepAfterRouteChange: true,
      });
    } else if (error.error.listMensajeError) {
      error.error.listMensajeError.forEach((element) => {
        this.alertNotificationService.error("Error", element.mensajeError, {
          autoClose: true,
          keepAfterRouteChange: true,
        });
      });
    } else {
      this.alertNotificationService.error(
        "Error",
        "Ocurrio un error en el servidor.",
        { autoClose: true, keepAfterRouteChange: true }
      );
    }
    this.loading = false;
  }
}
