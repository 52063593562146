<div class="card login-card">
    <div class="card-header">
      <div class="back-wrap">
        <a (click)="onGoBack()">
          <i class="fas fa-arrow-left"></i>
          <span class="light-text">Regresar</span>
        </a>
      </div>
      <div class="logo-wrap">
        <a (click)="onGoHome()">
          <img src="assets/images/logo.svg" alt="">
        </a>
      </div>
      <h3>Ingresa la contraseña</h3>
    </div>
    <div class="card-body pt-10 pb-5 text-center">
      <form [formGroup]="formaPassword">
        <div class="form-group">
          <label for="password">Contraseña</label>
          <input autocomplete="off" type="password" class="form-control" placeholder="**********"
            formControlName="password" [ngClass]="{'is-invalid invalidFieldForm': validPassword}"
            [ngStyle]="validPassword && {'border-color': 'red','margin-bottom': '3px'}">
          <div class="invalidFieldForm" style="color: red;font-size: 13px;" *ngIf="validPassword">
            Debe ingresar de 8 hasta 32 caracteres
          </div>
        </div>
        <div class="form-group">
          <label for="repeat-password">Confirmar contraseña</label>
          <input autocomplete="off" type="password" class="form-control" placeholder="**********"
            formControlName="passwordConfirm" [ngClass]="{'is-invalid invalidFieldForm': validPasswordConfirm}"
            [ngStyle]="validPasswordConfirm && {'border-color': 'red','margin-bottom': '3px'}">
          <div class="invalidFieldForm" style="color: red;font-size: 13px;" *ngIf="validPasswordConfirm">
            La confirmacion de la contraseña debe coincidir con la contraseña dada anteriormente
          </div>
        </div>
        <div class="mtb-20 text-center">
          <button (click)="onSubmit()" type="submit" class="btn-primary">Enviar</button>
        </div>
      </form>
    </div>
</div>
<abarcms-modal title="Datos inválidos" *ngIf="showValidationErrorModal" (close)="showValidationErrorModal = false"
  [btnRight]="'Aceptar'" (right)="showValidationErrorModal = false">
  {{validationError}}
</abarcms-modal>
<abarcms-modal title="Error" *ngIf="error.show" (close)="error.show = false">
  <ng-container *ngIf="settings.get('production')">
    {{error.message}}
  </ng-container>
  <ng-container *ngIf="!settings.get('production')">
    <pre>{{error.message | json}}</pre>
  </ng-container>
</abarcms-modal>
<abarcms-loader-full
  *ngIf=" userDataService['createPassword']['loading'] ">
</abarcms-loader-full>