import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { constants } from 'src/app/constants';
import { Poll, PollQuestion, PollSection, PollTypeQuestion } from 'src/app/dto/Poll';
import { AlertNotificationService } from 'src/app/services';
import { PollDataService } from 'src/app/services/data/poll-data.service';
import { getArray, getObject } from 'src/app/utils/_http';
import { flatPoll, getPermissionsPage, permissionPageUser } from 'src/app/utils/_utils';

@Component({
  selector: 'app-questions-action',
  templateUrl: './questions-action.component.html',
  styleUrls: ['./questions-action.component.css']
})
export class QuestionsActionComponent implements OnInit {
  subscription: Subscription = new Subscription
  permissions: any = {}
  pageAction: {
    action: any,
    uuid: any,
    titlePage: any,
  } = {
      action: null,
      uuid: null,
      titlePage: null,
    }
  showComponent: boolean = false

  poll: Poll
  question: PollQuestion
  pollTypeQuestions: PollTypeQuestion
  
  showButtonSave: boolean = false

  constructor(
    private pollService: PollDataService,
    private router: Router,
    private alertNotificationService: AlertNotificationService,
  ) { }

  ngOnInit(): void {
    this.pageAction = {
      action: constants.screens.polls[this.router.url.split('/').pop()] ?? null,
      uuid: this.router.url.split('/')[3] ?? null,
      titlePage: this.router.url.split('/').pop() == 'create' ? 'Crear pregunta' : 'Editar pregunta'
    }
    this.permissions = getPermissionsPage(this.router.url)
    this.showButtonSave = permissionPageUser(this.permissions, this.pageAction.action, this.router, '/polls/questions')
    this.poll = new Poll({})
    this.poll.sections.push(new PollSection({}))
    this.getTypeQuestion()
    this.getQuestion()
  }
  // --
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
  // --
  getTypeQuestion() {
    this.subscription.add(
      this.pollService.typeQuestion()
        .subscribe(
          rs => {
            this.pollTypeQuestions = getArray(rs)
          }
        )
    )
  }
  // --
  getQuestion() {
    this.subscription.add(
      this.pollService.repositoryReadOne(this.pageAction.uuid)
        .subscribe(
          rs => {
            let question = new PollQuestion(getObject(rs))
            this.poll.sections[0].questions = [question]
            this.poll.sections.map((xSection, iSection) => {
              let questions = []
              this.flatPoll(xSection.questions, questions)
              xSection.questions = questions
            })
            this.showComponent = true
          },
          error => {
            this.alertNotificationService.error('Pregunta', 'Ocurrio un error, intente más tarde', { autoClose: true, keepAfterRouteChange: true });
            this.router.navigateByUrl('/polls/questions')
          }
        )
    )
  }
  // --
  flatPoll(poll: any[], questions) {
    poll.map((xQuestion, iQuestion) => {
      questions.push(xQuestion)
      if (xQuestion.choices.length) {
        xQuestion.choices.map(xChoice => {
          this.flatPoll(xChoice.nested, questions)
        })
      }
    })
  }
  // --
  onActionQuestion(event) {
  }
  onAction(event) {
  }
  // --
  hiddenPollFunction() {

  }
}
