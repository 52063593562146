import { v1 as uuidv1 } from 'uuid'

export class Poll {
    public uuid: any
    public active: number
    public order: number
    public title: string
    public systemname: string
    public description: string
    public status: string
    public email: string
    public schedule?: PollSchedule
    public groups?: PollGroup[]
    public sections?: PollSection[]

    constructor(data) {
        this.uuid = data.uuid || uuidv1()
        if (data.active != undefined || data.active != null) {
            this.active = data.active
        }
        else {
            this.active = 1
        }
        this.order = data.order > -1 ? data.order : 0
        this.title = data.title || ''
        this.systemname = data.systemname || ''
        this.email = data.email || ''
        this.description = data.description || ''
        this.status = data.status || 'pendiente'
        this.schedule = data.schedule || new PollSchedule({})
        this.groups = data.groups || []
        this.sections = data.sections || []
    }
}

export class PollSchedule {
    public startdate: string
    public enddate: string

    constructor(data) {
        this.startdate = data.startdate || ''
        this.enddate = data.enddate || ''
    }
}

export class PollGroup {
    public uuid: string
    public name: string
    public monitorid: any
    public business: PollBusiness[]

    constructor(data) {
        this.uuid = data.uuid || ''
        this.name = data.name || ''
        this.business = data.business || []
    }
}

export class PollBusiness {
    public uuid: string
    public name: string
    public monitorid: any

    constructor(data) {
        this.uuid = data.uuid || ''
        this.name = data.name || ''
        this.monitorid = data.monitorid || ''
    }
}

export class PollSection {
    public uuid: string
    public polluuid: string
    public active: number
    public title: string
    public systemname: string
    public description: string
    public order: number
    public questions?: PollQuestion[]

    constructor(data) {
        this.uuid = data.uuid || uuidv1()
        this.polluuid = data.polluuid || ''
        if (data.active != undefined || data.active != null) {
            this.active = data.active
        }
        else {
            this.active = 1
        }
        this.title = data.title || ''
        this.systemname = data.systemname || ''
        this.description = data.description || ''
        this.order = data.order > -1 ? data.order : 0
        this.questions = data.questions || [new PollQuestion({})]
    }
}

export class PollQuestion {
    public uuid: any
    public parentuuid: any
    public active: number
    public title: string
    public systemname: string
    public order: number
    public required: number
    public description: string
    public typeuuid: string
    public validation: any
    public choices: PollChoices[]

    constructor(data) {
        this.uuid = data.uuid || uuidv1()
        this.parentuuid = data.parentuuid || ''
        if (data.active != undefined || data.active != null) {
            this.active = data.active
        }
        else {
            this.active = 1
        }
        this.title = data.title || ''
        this.systemname = data.systemname || ''
        this.order = data.order > -1 ? data.order : 0
        this.required = data.required || 1
        this.description = data.description || ''
        this.typeuuid = data.typeuuid || ''
        this.validation = data.validation || null
        this.choices = data.choices || []
    }
}

export class PollValidation {
    public uuidoption: any
    public value: any

    constructor(data) {
        this.uuidoption = data.uuidoption || ''
        this.value = data.value || ''
    }
}

export class PollChoices {
    public uuid: any
    public parentuuid: any
    public active: number
    public title: string
    public order: number
    public nested: PollQuestion[]

    constructor(data) {
        this.uuid = data.uuid || uuidv1()
        this.parentuuid = data.parentuuid || ''
        if (data.active != undefined || data.active != null) {
            this.active = data.active
        }
        else {
            this.active = 1
        }
        this.title = data.title || ''
        this.order = data.order > -1 ? data.order : 0
        this.nested = data.nested || []
    }
}

export class PollTypeQuestion {
    public uuid: any
    public name: string
    public systemname: string
    public options: PollTypeQuestionOption[]

    constructor(data) {
        this.uuid = data.uuid || ''
        this.name = data.name || ''
        this.systemname = data.systemname || ''
        this.options = data.options || []
    }
}

export class PollTypeQuestionOption {
    public uuid: any
    public name: string
    public systemname: string

    constructor(data) {
        this.uuid = data.uuid || ''
        this.name = data.name || ''
        this.systemname = data.systemname || ''
    }
}