<div class="modal fade" id="clone-modal" tabindex="-1" role="dialog" aria-hidden="true" [style.opacity]="opacity">
  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          <i class="fas fa-{{faClass}} icon-title" *ngIf="faClass != null || faClass != ''"></i>{{title}}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClose()"
          *ngIf="closeEE.observers.length>=1">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" [class.text-center]="modalTextCentered">
        <ng-content></ng-content>
      </div>
      <div class="modal-footer justify-content-end">
        <div class="col-md-4" *ngIf="btnLeft.length">
          <div class="btn-outline text-center" (click)="onLeft()" [innerHTML]="btnLeft"></div>
        </div>
        <div class="col-md-4" *ngIf="btnMiddle.length">
          <div class="btn-secondary text-center" (click)="onMiddle()" [innerHTML]="btnMiddle">
          </div>
        </div>
        <div class="col-md-4" *ngIf="btnRightEnabled && btnRight.length">
          <div class="btn-primary text-center" (click)="onRight()" [innerHTML]="btnRight"></div>
        </div>
      </div>
    </div>
  </div>
</div>