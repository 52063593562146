import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseData } from 'src/app/dto';

@Injectable({
  providedIn: 'root'
})
export abstract class FormulariosFieldDataService {
  abstract read(formId: string): Observable<ResponseData>;
  abstract readOne(menuUUID: any): Observable<ResponseData>;
  abstract create(formUUID: string, body: any): Observable<ResponseData>;
  abstract update(menuUUID: any, body: any): Observable<ResponseData>;
  abstract delete(formUUID: string, formFieldUUID: string): Observable<ResponseData>;
}
