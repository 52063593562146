import { UserDataService } from "./user-data.service";
import { UserDataWsService } from './ws/user-data-ws-service';
import { SessionDataService } from "./session-data.service";
import { NgModule } from "@angular/core";
import { DashboardDataService } from "./dashboard-data.service";
import { MenuDataService } from "./menu-data.service";
import { MenusModuleDataService } from "./menus-module-data.service";
import { MenuDataDummyService } from "./dummy/menu-data-dummy.service";
import { SessionDataWsService } from "./ws/session-data-ws.service";
import { DashboardDataWsService } from './ws/dashboard-data-ws.service';
import { CodeVerifyDataService } from './code-verify-data.service';
import { CodeVerifyDataWsService } from './ws/code-verify-data-ws.service';
import { MediaDataService } from './media-data.service';
import { MediaDataWsService } from './ws/media-data-ws.service';
import { UsersDataService } from './users-data.service';
import { UsersDataWsService } from './ws/users-data-ws.service';
import { RoleDataService } from './role-data.service';
import { RoleDataWsService } from './ws/role-data-ws.service';
import { PermissionDataService } from './permission-data.service';
import { PermissionDataWsService } from './ws/permission-data-ws.service';
import { ModuleDataService } from './module-data.service';
import { ModuleDataWsService } from './ws/module-data-ws.service';
import { StatementDataService } from './statement-data.service';
import { StatementDataWsService } from './ws/statement-data-ws.service';
import { FaqDataService } from './faq-data.service';
import { FaqDataWsService } from './ws/faq-data-ws.service';
import { MediaGugaDataService } from './media-guga-data.servcie';
import { MediaGugaDataWsService } from './ws/media-guga-data-ws.service';
import { LanguageDataService } from './language-data.service';
import { LanguageDataWsService } from './ws/language-data-ws.service';
import { ContentTypeDataService } from './content-type-data.service';
import { ContentTypeDataWsService } from './ws/content-type-data-ws.service';
import { ContentTypeFieldDataService } from './content-type-field-data.service';
import { ContentTypeFieldDataWsService } from './ws/content-type-field-data-ws.service';
import { FieldDataService } from './field-data.service';
import { FieldDataWsService } from './ws/field-data-ws.service';
import { ContentsDataService } from './contents-data.service';
import { ContentsDataWsService } from './ws/contents-data-ws.service';
import { ContentsTypeDataService } from './contentsType-data.service';
import { ContentsTypeDataWsService } from './ws/contentsType-data-ws.service';
import { MenusModuleDataWsService } from './ws/menus-module-data-ws.service';
import { MenuDataWsService } from './ws/menu-data-ws.service';
import { LogsDataService } from './logs-data.service';
import { LogsDataWsService } from './ws/logs-data-ws.service';
import { FormulariosDataService } from './formularios-data.service';
import { CitasDataWsService, FormulariosDataWsService } from './ws';
import { FormulariosFieldDataService } from './formularios-field-data.service';
import { FormulariosFieldDataWsService } from './ws/formularios-field-data-ws.service';
import { CitasDataService } from './citas-data.service';
import { AcessorsDataService } from "./acessors-data.service";
import { AcessorsDataWsService } from "./ws/acessors-data-ws.service";
import { NotificationsDataService } from "./notifications-data.service";
import { NotificationsDataWsService } from "./ws/notifications-data-ws.service";
import { GroupDataWsService } from "./ws/groups-data-ws.service";
import { GroupDataService } from "./group-data.service";
import { BusinessDataService } from "./business-data.service";
import { BusinessDataWsService } from "./ws/business-data-ws.service";
import { SettingsDataService } from "./settings-data.service";
import { SettingsDataWsService } from "./ws/settings-data-ws.service";
import { PollDataService } from "./poll-data.service";
import { PollDataWsService } from "./ws/poll-data-ws.service";
import { ConfigurationDataWsService } from "./ws/configuration-data-ws.service";
import { ConfigurationDataService } from "./configuration-data.service";
import { ApplyTemplateDataService } from "./apply-template-data.service";
import { ApplyTemplateDataWsService } from './ws/apply-template-data-ws.service';
import { CreateTemplateDataService } from "./create-template-data.service";
import { CreateTemplateDataWsService } from './ws/create-template-data-ws.service';
import { EmailDataService } from "./email-data.servcie";
import { EmailDataWsService } from "./ws/email-data-ws.service";


@NgModule({
    imports: [
    ],
    declarations: [],
    providers: [
        { provide: UserDataService, useClass: UserDataWsService },
        { provide: SessionDataService, useClass: SessionDataWsService },
        { provide: ContentsDataService, useClass: ContentsDataWsService },
        { provide: ContentsTypeDataService, useClass: ContentsTypeDataWsService },
        // {provide:DashboardDataService,useClass:DashboardDataDummyService},
        { provide: DashboardDataService, useClass: DashboardDataWsService },
        { provide: FormulariosDataService, useClass: FormulariosDataWsService },
        { provide: FormulariosFieldDataService, useClass: FormulariosFieldDataWsService },
        { provide: CitasDataService, useClass: CitasDataWsService },
        // { provide: MenuDataService, useClass: MenuDataDummyService },
        { provide: MenusModuleDataService, useClass: MenusModuleDataWsService },
        { provide: DashboardDataService, useClass: DashboardDataWsService },
        { provide: MenuDataService, useClass: MenuDataWsService },
        { provide: CodeVerifyDataService, useClass: CodeVerifyDataWsService },
        { provide: MediaDataService, useClass: MediaDataWsService },
        { provide: UsersDataService, useClass: UsersDataWsService },
        { provide: GroupDataService, useClass: GroupDataWsService },
        { provide: BusinessDataService, useClass: BusinessDataWsService },
        { provide: RoleDataService, useClass: RoleDataWsService },
        { provide: ModuleDataService, useClass: ModuleDataWsService },
        { provide: PermissionDataService, useClass: PermissionDataWsService },
        { provide: StatementDataService, useClass: StatementDataWsService },
        { provide: FaqDataService, useClass: FaqDataWsService },
        { provide: MediaGugaDataService, useClass: MediaGugaDataWsService },
        { provide: LanguageDataService, useClass: LanguageDataWsService },
        { provide: ContentTypeDataService, useClass: ContentTypeDataWsService },
        { provide: ContentTypeFieldDataService, useClass: ContentTypeFieldDataWsService },
        { provide: FieldDataService, useClass: FieldDataWsService },
        { provide: LogsDataService, useClass: LogsDataWsService },
        { provide: AcessorsDataService, useClass: AcessorsDataWsService },
        { provide: NotificationsDataService, useClass: NotificationsDataWsService },
        { provide: SettingsDataService, useClass: SettingsDataWsService },
        { provide: PollDataService, useClass: PollDataWsService },
        { provide: ConfigurationDataService, useClass: ConfigurationDataWsService },
        { provide: ApplyTemplateDataService, useClass: ApplyTemplateDataWsService },
        { provide: CreateTemplateDataService, useClass: CreateTemplateDataWsService },
        { provide: EmailDataService, useClass: EmailDataWsService },

    ]
})
export class ServicesDataModule { }
