import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseData } from 'src/app/dto';

@Injectable({
  providedIn: 'root'
})
export abstract class FieldDataService {
  abstract read(): Observable<ResponseData>;
}
