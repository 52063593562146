<abarcms-loader-full *ngIf="loading"></abarcms-loader-full>
<div class="container-fluid">
    <div class="card border-card">
        <div class="card-content size" >
            <h6>Ordenar elementos</h6>
            <figure>
                <div><img class="icon" [src]="sectionIcon" ></div>
                <figcaption>{{sectionName|uppercase}}</figcaption>
              </figure>
              <div class="divOrder">
                <ul id="sortable-list" #sortableList>
                    <li *ngFor="let pagina of paginas">
                      <div>
                        <img class="icon" [src]="pagina.icon" >
                      </div>
                      <div>
                        <p>{{pagina.name}}</p>
                      </div>
                    </li>
                  </ul>
              </div>
          
          <app-boton [textContent]="'Guardar'" (click)="saveOrderPage()"></app-boton>
        </div>
    </div>
</div>