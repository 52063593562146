import { Injectable } from '@angular/core';
import { ResponseData } from 'src/app/dto/ResponseData';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export abstract class ModuleDataService {
  abstract read(accessToken: string): Observable<ResponseData>;
}
