<abarcms-loader-full *ngIf="loading"></abarcms-loader-full>
<div class="container-fluid">
    <div class="card border-card">
        <div class="card-content size" >
            <h6>{{title}}</h6>
            
            <div class="card size">
                <div class="card-body">
                    <div class="form-group ">
                        <label for="rolName">¿Es Interna?</label><br>
                        <input type="radio" name="isWebInternal" [(ngModel)]="isWebInternal" value="true" [value]="true">
                        <label for=""> Si</label> 
                        <span>&nbsp;&nbsp;</span>
                        <input type="radio" name="isWebInternal" [(ngModel)]="isWebInternal" value="false" [value]="false">
                        <label for=""> No</label>
                    </div>      
                </div>
            </div>

            <div class="card size">
                <div class="card-body">
                    <div class="form-group ">
                        <label for="rolName">*Nombre Página:</label>
                        <input matInput class="form-control" type="text" placeholder="Nombre" [(ngModel)]="nombre" required>
                        <div *ngIf="nombreObligatorio" class="error-message">
                            Campo Nombre Página es obligatorio.
                          </div>
                    </div>      
                </div>
            </div>
            
            <div class="card size" *ngIf="isWebInternal === true">
                <div class="card-body">
                    <div class="form-group ">
                        <label for="rolName">Nombre Actividad:</label>
                        <input matInput class="form-control" type="text" placeholder="Nombre Actividad" [(ngModel)]="nombreActividad">
                        <!--div *ngIf="nombreActividadObligatorio==true" class="error-message">
                            Campo Nombre Actividad es obligatorio.
                          </div-->
                    </div>      
                </div>
            </div>


            <div class="card size">
                <div class="card-body">        
                    <div class="form-group ">
                        <label for="rolName">Descripción:</label>
                        <textarea class="form-control custom-textarea" rows="5" placeholder="Descripción" [(ngModel)]="descripcion"></textarea>
                        <div *ngIf="descripcionObligatorio==true" class="error-message">
                            Campo descripción es obligatorio.
                        </div>
                    </div>
                </div>
            </div>
            <div class="card size">
                <div class="card-body">
                    
                    <div class="form-group" style="display: flex; flex-direction: column;">
                        <label for="icono">Icono:</label>
                        <div style="display: flex;">
                          <label class="btn btn-outline-primary border-0" style="border-radius: 0;">
                            <span id="file-name">  </span>
                            <i class="fas fa-folder"></i> 
                            <input type="file" accept=".png" (change)="onFileSelected($event)" style="display: none;">
                          </label>                          
                          <img [src]="previewImage" *ngIf="previewImage" style="max-width: 200px; margin-left: 10px;">
                        </div>
                        <input type="hidden" id="icono" [value]="base64Image">
                        <div *ngIf="urlObligatoria==true" class="error-message">
                            Icono obligatorio.
                          </div>
                      </div>                                          

                      
                </div>
            </div>
            <div class="card size">
                <div class="card-body">
                    
                    <div class="form-group">
                        <label for="url">URL:</label>
                        <input type="text" class="form-control" [(ngModel)]="url">
                        <div *ngIf="urlObligatoria==true" class="error-message">
                            Campo url obligatorio.
                          </div>
                          <div *ngIf="!esValida" class="error-message">
                            La URL debe tener un formato correcto.
                          </div>
                      </div>

                </div>
            </div>
            <div class="card size">
                <div class="card-body">
                    <div class="form-group ">
                        <label for="rolName">Token:</label><br>
                        <textarea class="form-control custom-textarea" rows="5" placeholder="Token" [(ngModel)]="token"></textarea>
                        <div *ngIf="tokenObligatorio==true" class="error-message">
                            Campo Token es obligatorio.
                        </div>
                    </div>     
                </div>
            </div>
            <div class="card size">
                <div class="card-body">
                    <div class="form-group ">
                        <label for="rolName">Parametros:</label><br>
                        <textarea class="form-control custom-textarea" rows="5" 
                        placeholder="Parametros" [(ngModel)]="parametros" (ngModelChange)="limpiaParametro()"></textarea>
                        <div *ngIf="parametrosObligatorios" class="error-message">
                            Campo Parametros es obligatorio.
                        </div>
                    </div>     
                </div>
            </div>


            <div class="card size">
                <div class="card-body">
                   

                    <div class="form-group">
                        <label for="rolName">Estatus:</label><br>
                        <input type="radio" name="estatus" [(ngModel)]="estatus" value="1">
                        <label for=""> Activo</label> 
                        <span>&nbsp;&nbsp;</span>
                        <input type="radio" name="estatus" [(ngModel)]="estatus" value="0">
                        <label for=""> Inactivo</label>
                      </div>

                </div>
            </div>

            <div class="button-content size" *ngIf="isUpdate">
                <div class="buttons">
                    <app-boton [textContent]="'Actualizar'" (click)="updatePage(1)"></app-boton>
                    <app-boton [textContent]="'Actualizar y Siguiente'" (click)="updatePage(2)"></app-boton>
                    <app-boton [textContent]="'Cancelar'" [backgroundColor]="'#000000'" class="buttonCancelar" (click)="cancelar()"></app-boton>
                  </div>
            </div>  

            <div class="button-content size" *ngIf="flujoSimple">
                <div class="buttons">
                    <app-boton [textContent]="'Guardar'" (click)="guardar()"></app-boton>
                    <app-boton [textContent]="'Cancelar'" [backgroundColor]="'#000000'" class="buttonCancelar" (click)="cancelar()"></app-boton>
                  </div>
            </div>  

            <div class="button-content size" *ngIf="flujoComplejo">
                <div class="buttons">
                    <app-boton [textContent]="'Guardar y siguiente'" (click)="guardarYSiguiente(1)"></app-boton>
                    <app-boton [textContent]="'Guardar y salir'" (click)="guardarYSiguiente(2)"></app-boton>
                    <app-boton [textContent]="'Cancela'" [backgroundColor]="'#000000'" class="buttonCancelar" (click)="cancelar()"></app-boton>
                  </div>
            </div>    
                       
        </div>
    </div>
</div>
