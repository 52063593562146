<div class="container-fluid">
    <div class="card border-card">
        <div class="card-content">
            <!--Cabecera-->
            <div class="row mb-20">
                <div class="card__title col-xs-12 col-md-4 col-lg-4">
                    <h2 class="title-list">Listado de encuestas</h2>
                </div>

                <div class="col-xs-12 col-md-4 col-lg-3" *ngIf="permissions?.create">
                    <button type="button" (click)="actionItem('create')" class="btn btn-success">
                        <span class="fas fa-plus" aria-hidden="true"></span> Crear encuesta
                    </button>

                </div>
                <div class="col-12 col-xl-4">
                    <abarcms-input-search [items]="polls" [fieldsToSearch]="['title','status']"
                        (dataFound)="dataFound($event)" placeholder="Para buscar puede introducir el titulo ó estatus">
                    </abarcms-input-search>
                </div>
            </div>
            <!--Sub actions-->
            <div class="row" *ngIf="(permissions?.edit || permissions?.delete) && existMonitor()">
                <div class="col-xs-12 col-sm-8 col-lg-2">
                    <div class="form-group">
                        <select class="form-control" [(ngModel)]="batchPoll">
                            <option value="activar" *ngIf="permissions?.edit">Activar</option>
                            <option value="desactivar" *ngIf="permissions?.edit">Desactivar</option>
                            <option value="eliminar" *ngIf="permissions?.delete">Eliminar</option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-2">
                    <button type="button" class="btn btn-primary" (click)="onClick('batch')">
                        Ejecutar
                    </button>
                </div>
            </div>

            <!--Cuerpo-->
            <ng-container *ngIf="dataTable.showTable">
                <div class="mt-30">
                    <div id="contenido-table_wrapper" class="dataTables_wrapper no-footer">
                        <table style="width:100%">
                            <!--Cabecera de tabla-->
                            <thead>
                                <tr>
                                    <th *ngIf="permissions?.edit || permissions?.delete">
                                        <span class="blue-checkbox">
                                            <input type="checkbox" (click)="selectedAllItems()" [checked]="isChecked()">
                                            <label></label>
                                        </span>
                                    </th>
                                    <th>NOMBRE</th>
                                    <th>
                                        EMPRESA
                                        <!--<abarcms-group-filter button-title="EMPRESA" [data]="groupsListFilter"></abarcms-group-filter>-->
                                    </th>
                                    <th>ESTADO</th>
                                    <!-- <th>CREADO</th> -->
                                    <th>FECHA INICIO</th>
                                    <th>FECHA FIN</th>
                                    <th>ACCIONES</th>
                                </tr>
                            </thead>
                            <!--Cuerpo de tabla-->
                            <tbody>
                                <tr *ngFor="let poll of pollsList; let i = index">
                                    <td *ngIf="permissions?.edit || permissions?.delete">
                                        <span class="blue-checkbox">
                                            <input type="checkbox" [checked]="isChecked(poll)"
                                                (click)="selectedItem(poll)">
                                            <label></label>
                                        </span>
                                    </td>
                                    <td>{{ poll.title }}</td>
                                    <td>
                                        <abarcms-group-detail-list [show-name]="false"
                                            [bussness-data]="getBusiness(poll.groups)" [click-event-enabled]="true"
                                            (clicked)="openBusiness($event)"></abarcms-group-detail-list>
                                        <!-- <div class="user-group-list-component dropdown">
                                        <ng-container *ngFor="let group of poll.groups">
                                            <div class="container-lables">
                                                <p class="title">{{group.name}}</p>
                                                <span class="number-of-companies">
                                                    {{group.business?.length}} empresas
                                                </span>
                                            </div>
                                            <div class="container-buttons">
                                                <button class="btn button-compaines dropdown-toggle"
                                                    id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false">
                                                </button>
                                                <div class="dropdown-menu items-box-drop"
                                                    aria-labelledby="dropdownMenuButton">
                                                    <div class="items-box">
                                                        <ul class="items-list">
                                                            <li class="item" *ngFor="let business of group.business">
                                                                <span (click)="onClick('business', business)">
                                                                    {{business.name}}
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div> -->
                                    </td>
                                    <td>{{ poll.active ? poll.status : 'Desactivado' }}</td>
                                    <!-- <td>
                                    {{poll.status}}
                                </td> -->
                                    <td>
                                        {{poll.schedule.startDate ?
                                        (poll.schedule.startDate | date: 'dd/MM/yyyy') :
                                        'no configurado'}}
                                    </td>
                                    <td>
                                        {{poll.schedule.endDate ?
                                        (poll.schedule.endDate | date: 'dd/MM/yyyy') :
                                        'no configurado'}}
                                    </td>
                                    <td>
                                        <div class="btn-group btn-group-sm" role="group" aria-label="User action">
                                            <ng-container *ngIf="!permissions?.edit">
                                                <button type="button" class="btn btn-warning" *ngIf="permissions?.read"
                                                    (click)="actionItem('read', poll.uuid)">
                                                    <i class="far fa-eye"
                                                        style="position: relative; left: -2px; top: 2px;"></i>
                                                </button>
                                            </ng-container>
                                            <!-- <button type="button" class="btn btn-success" *ngIf="permissions?.create"
                                            (click)="actionItem('copy', poll)">
                                            <i class="far fa-copy"></i>
                                        </button> -->
                                            <button type="button" class="btn btn-primary" *ngIf="permissions?.edit"
                                                (click)="actionItem('edit',poll.uuid)">
                                                <i class="fas fa-pencil-alt"></i>
                                            </button>
                                            <button type="button" class="btn btn-danger" *ngIf="permissions?.delete"
                                                (click)="actionItem('delete',poll)">
                                                <i class="fas fa-trash-alt"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-12 mt-4">
                        <abarcms-paginator [items]="pollsPage" [initialPage]="paginator.current"
                            [perPage]="paginator.perPage" [maxPages]="5" [lblFirst]="'Primera'" [lblLast]="'Última'"
                            [lblPrev]="'Anterior'" [lblNext]="'Siguiente'" [btnSec]="true" [btnPri]="true"
                            (changePage)="changePage($event)" [align]="'center'"></abarcms-paginator>
                        <p *ngIf="pollsPage.length == 0" class="alert alert-warning text-center">
                            No se encontraron datos
                        </p>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<abarcms-modal title="Eliminar encuesta" *ngIf="deleteModal.show" (close)="deleteItem()" [btnMiddle]="'Cancelar'"
    [btnRight]="'Aceptar'" (middle)="deleteItem()" (right)="deleteItem(deleteModal)">
    <p class="alert alert-danger">Estas apunto de eliminar esta encuesta</p>
    <form class="text-left">
        <div class="form-group">
            <label for="id">Identificador</label>
            <input type="text" readonly class="form-control" value="{{deleteModal.item.uuid}}">
        </div>
        <div class=" form-group">
            <label for="name">Nombre de la encuesta</label>
            <input type="text" readonly class="form-control" value="{{deleteModal.item.title}}">
        </div>
    </form>
</abarcms-modal>