import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SettingsService } from "src/app/services";
import { makeHeaders } from "src/app/utils/_http";
import { getMonitorToken, getMonitorUserId } from "./http-monitor";
import { HttpMonitorService } from "./http-monitor.service";

@Injectable({
  providedIn: "root",
})
export class GruposService {
  private baseUrl: string;
  pathInfo: any;

  constructor(
    private settingsService: SettingsService,
    private http: HttpClient,
    private monitorService: HttpMonitorService
  ) {
    this.pathInfo = this.settingsService.get("pathInfo");
    this.baseUrl = this.pathInfo.base.monitor.url;
  }

  async allGrups(data: any): Promise<Observable<any>> {
    let monitorToken: string = await this.monitorService.getMonitorToken();
    let url = this.baseUrl + "/v2/search/1";
    return this.http.post<any>(
      url,
      data,
      makeHeaders({ Authorization: "Bearer " + monitorToken })
    );
  }

  async getFindById(id) {
    let monitorToken: string = await this.monitorService.getMonitorToken();
    let url = this.baseUrl + "/v2/group/" + id;
    return this.http.get<any>(
      url,
      makeHeaders({ Authorization: "Bearer " + monitorToken })
    );
  }
  async saveGroup(grupo, idGrupo) {
    let monitorToken: string = await this.monitorService.getMonitorToken();
    let userMonitor: string = await this.monitorService.getMonitorUserId();
    let url =
      this.baseUrl +
      "/v2/admin/" +
      userMonitor +
      "/grupo" +
      (idGrupo != null ? "/" + idGrupo : "");
    if (idGrupo != null) {
      return this.http.put<any>(
        url,
        grupo,
        makeHeaders({ Authorization: "Bearer " + monitorToken })
      );
    } else {
      return this.http.post<any>(
        url,
        grupo,
        makeHeaders({ Authorization: "Bearer " + monitorToken })
      );
    }
  }
  async statusGroup(status, idGrupo) {
    let monitorToken: string = await this.monitorService.getMonitorToken();
    let userMonitor: string = await this.monitorService.getMonitorUserId();
    let url =
      this.baseUrl + "/v3/admin/" + userMonitor + "/grupo" + "/" + idGrupo;
    return this.http.patch<any>(
      url,
      status,
      makeHeaders({ Authorization: "Bearer " + monitorToken })
    );
  }
}
