import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SettingsService } from "src/app/services";
import { getHeaders, getMonitorUserId } from "./http-monitor";
import { HttpMonitorService } from "./http-monitor.service";

@Injectable({
  providedIn: "root",
})
export class SeccionMonitorService {
  private baseUrl: string;
  pathInfo: any;
  credentials: any;
  constructor(
    private settingsService: SettingsService,
    private http: HttpClient,
    private monitorService: HttpMonitorService
  ) {
    this.pathInfo = this.settingsService.get("pathInfo");
    this.baseUrl = this.pathInfo.base.monitor.url;
    this.credentials = this.pathInfo.base.monitor.credentials;
  }

  getCatGrupoVisualizacion(): Observable<any> {
    let url = this.baseUrl + "/v2/admin/catGrupoVisualizacion";
    return this.http.get(url).pipe(
      map((json) => {
        const result = json;
        return result as any;
      })
    );
  }

  async getAllGrupoVisualizacion(data: any): Promise<Observable<any>> {
    const monitorUserId = await this.monitorService.getMonitorUserId();
    let url =
      this.baseUrl + "/v2/admin/" + monitorUserId + "/allGrupoVisualizacion";
    const headers = await this.monitorService.getHeaders();
    return this.http.post<any>(url, data, { headers });
  }

  async getAllSections(dataSectionsJSON: any): Promise<Observable<any>> {
    const monitorUserId = await this.monitorService.getMonitorUserId();
    const headers = await this.monitorService.getHeaders();
    let url = this.baseUrl + "/v2/admin/" + monitorUserId + "/allSecciones";
    return this.http.post<any>(url, dataSectionsJSON, { headers });
  }

  async saveOrderSection(sectionIds: any): Promise<Observable<any>> {
    const monitorUserId = await this.monitorService.getMonitorUserId();
    const headers = await this.monitorService.getHeaders();
    let url =
      this.baseUrl +
      "/v2/admin/" +
      monitorUserId +
      "/secciones/ordenarSecciones";
    return this.http.post<any>(url, sectionIds, { headers });
  }

  async saveOrderPage(
    sectionId: number,
    pageIds: any
  ): Promise<Observable<any>> {
    const monitorUserId = await this.monitorService.getMonitorUserId();
    const headers = await this.monitorService.getHeaders();
    let url =
      this.baseUrl +
      "/v2/admin/" +
      monitorUserId +
      "/seccion/" +
      sectionId +
      "/ordenarPaginas";
    return this.http.post<any>(url, pageIds, { headers });
  }
  async getAllPagesBySectionId(
    dataSectionsJSON: any
  ): Promise<Observable<any>> {
    const monitorUserId = await this.monitorService.getMonitorUserId();
    const headers = await this.monitorService.getHeaders();
    let url = this.baseUrl + "/v2/admin/" + monitorUserId + "/allSecciones";
    return this.http.post<any>(url, dataSectionsJSON, { headers });
  }

  async getSectionBySectionId(sectionId: any): Promise<Observable<any>> {
    const monitorUserId = await this.monitorService.getMonitorUserId();
    const headers = await this.monitorService.getHeaders();
    let url =
      this.baseUrl + "/v2/admin/" + monitorUserId + "/secciones/" + sectionId;
    return this.http.get<any>(url, { headers });
  }

  async updateSection(
    updateSectionJson: any,
    idSection: number
  ): Promise<Observable<any>> {
    const monitorUserId = await this.monitorService.getMonitorUserId();
    const headers = await this.monitorService.getHeaders();
    let url =
      this.baseUrl + "/v2/admin/" + monitorUserId + "/secciones/" + idSection;
    return this.http.put<any>(url, updateSectionJson, { headers });
  }

  async attachPagesTosection(
    updateSectionJson: any,
    idSection: number
  ): Promise<Observable<any>> {
    const monitorUserId = await this.monitorService.getMonitorUserId();
    const headers = await this.monitorService.getHeaders();
    let url =
      this.baseUrl +
      "/v2/admin/" +
      monitorUserId +
      "/secciones/" +
      idSection +
      "/attachPages";
    return this.http.post<any>(url, updateSectionJson, { headers });
  }

  async detachPagesPagesTosection(
    updateSectionJson: any,
    idSection: number
  ): Promise<Observable<any>> {
    const monitorUserId = await this.monitorService.getMonitorUserId();
    const headers = await this.monitorService.getHeaders();
    let url =
      this.baseUrl +
      "/v2/admin/" +
      monitorUserId +
      "/secciones/" +
      idSection +
      "/detachPages";
    return this.http.put<any>(url, updateSectionJson, { headers });
  }

  async createSection(createSectionJson: any): Promise<Observable<any>> {
    const monitorUserId = await this.monitorService.getMonitorUserId();
    const headers = await this.monitorService.getHeaders();
    let url = this.baseUrl + "/v2/admin/" + monitorUserId + "/secciones";
    return this.http.post<any>(url, createSectionJson, { headers });
  }

  async deleteSection(sectionId: number): Promise<Observable<any>> {
    const monitorUserId = await this.monitorService.getMonitorUserId();
    const headers = await this.monitorService.getHeaders();
    let url =
      this.baseUrl + "/v2/admin/" + monitorUserId + "/secciones/" + sectionId;
    return this.http.delete<any>(url, { headers });
  }

  async geGrupoVisualizacionById(id: number): Promise<Observable<any>> {
    const monitorUserId = await this.monitorService.getMonitorUserId();
    const headers = await this.monitorService.getHeaders();
    let url =
      this.baseUrl + "/v2/admin/" + monitorUserId + "/grupoVisualizacion/" + id;
    return this.http.get(url, { headers }).pipe(
      map((json) => {
        const result = json;
        return result as any;
      })
    );
  }

  geAllGroup(): Observable<any> {
    let url = this.baseUrl + "/v2/group";
    return this.http.get(url).pipe(
      map((json) => {
        const result = json;
        return result as any;
      })
    );
  }

  getWorkPlaceByMunicipio(municipalityId: number): Observable<any> {
    let url =
      this.baseUrl +
      "/v2/workPlaces/companyStateMunicipalityId/" +
      municipalityId;
    return this.http.get(url).pipe(
      map((json) => {
        const result = json;
        return result as any;
      })
    );
  }

  searchGroup(searchGroupJson: any): Observable<any> {
    let url = this.baseUrl + "/v2/search/1";
    return this.http.post(url, searchGroupJson).pipe(
      map((json) => {
        const result = json;
        return result as any;
      })
    );
  }

  searchElementsIds(searchElementsIdsJson: any): Observable<any> {
    let url = this.baseUrl + "/v2/search";
    return this.http.post(url, searchElementsIdsJson).pipe(
      map((json) => {
        const result = json;
        return result as any;
      })
    );
  }
  searchCompanies(searchCompanyJson: any): Observable<any> {
    let url = this.baseUrl + "/v2/search/2";
    return this.http.post(url, searchCompanyJson).pipe(
      map((json) => {
        const result = json;
        return result as any;
      })
    );
  }

  geAllCompaniesByIdGroup(idGroup: number): Observable<any> {
    let url = this.baseUrl + "/v2/groupCompanys/" + idGroup;
    return this.http.get(url).pipe(
      map((json) => {
        const result = json;
        return result as any;
      })
    );
  }

  geAllStates(companId: number): Observable<any> {
    let url = this.baseUrl + "/v2/companyStates/company/" + companId;
    return this.http.get(url).pipe(
      map((json) => {
        const result = json;
        return result as any;
      })
    );
  }
  //
  getMunicipios(companId: number, stateId: number): Observable<any> {
    let url =
      this.baseUrl +
      "/v2/companyStatesMunicipalities/company/" +
      companId +
      "/state/" +
      stateId;
    return this.http.get(url).pipe(
      map((json) => {
        const result = json;
        return result as any;
      })
    );
  }

  geAllWorkPlaceByCompanyId(workPlacesJson: any): Observable<any> {
    let url = this.baseUrl + "/v2/search/3";
    return this.http.post(url, workPlacesJson).pipe(
      map((json) => {
        const result = json;
        return result as any;
      })
    );
  }

  searchUsersGroupByWorkPlaceId(usersGroupJson: any): Observable<any> {
    let url = this.baseUrl + "/v2/search/4";
    return this.http.post(url, usersGroupJson).pipe(
      map((json) => {
        const result = json;
        return result as any;
      })
    );
  }

  async addGrupoVisualizacion(newGVJson: any): Promise<Observable<any>> {
    const monitorUserId = await this.monitorService.getMonitorUserId();
    const headers = await this.monitorService.getHeaders();
    let url =
      this.baseUrl + "/v2/admin/" + monitorUserId + "/grupoVisualizacion";
    return this.http.post<any>(url, newGVJson, { headers });
  }

  async updateGrupoVisualizacion(
    updateGVJson: any,
    idGV: number
  ): Promise<Observable<any>> {
    const monitorUserId = await this.monitorService.getMonitorUserId();
    const headers = await this.monitorService.getHeaders();
    let url =
      this.baseUrl +
      "/v2/admin/" +
      monitorUserId +
      "/grupoVisualizacion/" +
      idGV;
    return this.http.put<any>(url, updateGVJson, { headers });
  }

  ///diseases
  getEnfermedades(): Observable<any> {
    let url = this.baseUrl + "/v1/diseases";
    return this.http.get(url).pipe(
      map((json) => {
        const result = json;
        return result as any;
      })
    );
  }
  ///typeUsers
  getTypeUsers(): Observable<any> {
    let url = this.baseUrl + "/v2/typeUsers";
    return this.http.get(url).pipe(
      map((json) => {
        const result = json;
        return result as any;
      })
    );
  }
}
