import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ApplyTemplateDataService } from 'src/app/services/data/apply-template-data.service';
import { ResponseData } from 'src/app/dto';
import { SessionService } from 'src/app/services/session.service';
import { getPermissionsPage } from 'src/app/utils/_utils';
import { Router } from '@angular/router';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { Empleado } from 'src/app/dto/UsuariosMonitor';
import { CreateTemplateDataService } from 'src/app/services/data/create-template-data.service';
import { AlertNotificationService } from 'src/app/services';
import { Subscription } from 'rxjs';
import { getObject } from 'src/app/utils/_http';

@Component({
  selector: 'app-apply-template',
  templateUrl: './apply-template.component.html',
  styleUrls: ['./apply-template.component.css']
})
export class ApplyTemplateComponent implements OnInit, OnDestroy {

  empresaForm: FormGroup;
  plantillas: Plantilla[] = [];

  paginator: {
    current: number,
    perPage: number,
    total: number
  } = {
      current: 1,
      perPage: 20,
      total: 0,
    }

  dataTable = {
    showTable: false,
    empleados: <Empleado[]>[],
    empleadosPage: <Empleado[]>[],
    empleadosList: <Empleado[]>[],
    totalChecked: 0,
    size: 20000
  }

  permissions: any = {}
  private subscription: Subscription = new Subscription();

  constructor(
    private form: FormBuilder,
    public applyTemplateDataService: ApplyTemplateDataService,
    public createTemplateDataService: CreateTemplateDataService,
    private session: SessionService,
    private router: Router,
    private modalHelper: ModalHelperService,
    private alertNotificationService: AlertNotificationService
  ) {
    this.permissions = getPermissionsPage(this.router.url);
    this.buildForm();
  }

  ngOnInit(): void {
    this.getPlantilla();
    this.subscription.add(
      this.modalHelper.poll$
        .subscribe(
          value => {
            if (value?.module) {
              if (value.module == 'validFormResponse') {
                if (!value.invalid) {
                  this.reset()
                  this.applyTemplate()
                }
              }
            }
          }
        )
    )
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  buildForm() {
    this.empresaForm = this.form.group({
      plantillas: new FormControl([], [Validators.required]),
      idMonitor: [''],
      groups: [[]],
    });

  }

  reset() {
    this.dataTable.showTable = false
    this.dataTable.empleados = []
    this.dataTable.empleadosList = []
    this.dataTable.empleadosPage = []
    this.dataTable.totalChecked = 0
  }

  getPlantilla() {
    this.createTemplateDataService.readAll(this.session.accessToken.accessToken)
      .subscribe(
        rs => {
          let rsPlantilla = rs.body.plantillas;
          let dataEmpresa: Array<Plantilla> = [];
          rsPlantilla.map((item) => {
            dataEmpresa.push({
              id: item.idplantillapermisomonitor,
              name: item.nombre
            });
          })
          this.plantillas = dataEmpresa;
        }
      )
  }

  buscarPlantilla = () => {
    this.modalHelper.pollChange({
      module: 'validForm',
    })
  }

  get fg() {
    return this.empresaForm.controls
  }

  applyTemplate(pageSize?: any, page?: any, totalPages?: any) {
    let body: UsuarioEmpresa = {
      pageSize: pageSize ? pageSize : this.paginator.perPage,
      page: page ? page : this.paginator.current,
      idcompany: this.empresaForm.value.groups[0].business[0].monitorid,
      iduser: 0
    };
    if (this.empresaForm.value.idMonitor) {
      body.iduser = this.empresaForm.value.idMonitor
    }
    this.applyTemplateDataService.read(body, this.session.accessToken.accessToken)
      .subscribe(
        (rs) => {
          let rsEmpleado = getObject(rs).data;
          if (pageSize) {
            rsEmpleado.usuarios.map((item, index) => {
              let checked = item.plantillamonitor.idplantilla == this.empresaForm.value.plantillas ? true : false
              this.dataTable.empleados.push({
                empresa: item.empresa,
                idusuario: item.idusuario,
                nombre: item.nombre,
                checked: checked,
              })
              this.dataTable.empleadosPage = this.dataTable.empleados
              if (checked) {
                this.dataTable.totalChecked++
              }
              if (index == rsEmpleado.usuarios.length - 1) {
                if (page < totalPages) {
                  page++
                  this.applyTemplate(this.dataTable.size, page, totalPages)
                }
                else {
                  this.dataTable.showTable = true
                }
              }
            })
          }
          else {
            this.paginator.total = rsEmpleado.totalrows
            let total = this.paginator.total / this.dataTable.size
            let page = Math.trunc(total)
            page += total - page > 0 ? 1 : 0
            this.applyTemplate(this.dataTable.size, 1, page)
          }
        }
      );
  }

  dataFound(event) {
    this.dataTable.empleadosPage = event
  }

  changePage(event: any) {
    this.dataTable.empleadosList = event;
  }

  onChecked(id: any) {
    this.dataTable.empleados.map((x, i) => {
      if (x.idusuario == id) {
        x.checked = !x.checked
        if (x.checked) {
          this.dataTable.totalChecked++
        }
        else {
          this.dataTable.totalChecked--
        }
        return false
      }
    })
  }

  selectedAllItems(event: any) {
    this.dataTable.empleados.map(x => {
      x.checked = event.target.checked
    })
    if (event.target.checked) {
      this.dataTable.totalChecked = this.dataTable.empleados.length
    }
    else {
      this.dataTable.totalChecked = 0
    }
  }

  onPlantillaChange(event) {
  }

  guardarPlantilla() {
    let total = this.paginator.total / this.dataTable.size
    let page = Math.trunc(total)
    page += total - page > 0 ? 1 : 0
    this.saveTemplate(this.dataTable.size, 1, page)
  }

  saveTemplate(pageSize: any, page: any, totalPages: any) {
    let datosEmpleados = this.dataTable.empleados.slice((page - 1) * pageSize, page * pageSize)
    const data: TodosEmpleados = {
      idplantillapermisomonitor: parseInt(this.empresaForm.value.plantillas),
      acciones: {
        idusuariosactivar: datosEmpleados.map(x => { if (x.checked) { return x.idusuario } }).filter(x => !!x),
        idusuariosdesactivar: datosEmpleados.map(x => { if (!x.checked) { return x.idusuario } }).filter(x => !!x),
      }
    };
    this.applyTemplateDataService.create(data, this.session.accessToken.accessToken)
      .subscribe(
        (rs: ResponseData) => {
          this.alertNotificationService.success('Plantilla', 'Se aplico a la plantilla correctamente', { autoClose: true, keepAfterRouteChange: true });
          if (page < totalPages) {
            page++
            this.saveTemplate(this.dataTable.size, page, totalPages)
          }
          else {
            this.router.navigate(['groups-business/list-template']);
          }
        },
        error => {
          console.error(error, error);
          this.alertNotificationService.error('Plantilla', 'Error al aplicar la plantilla', { autoClose: true, keepAfterRouteChange: true });
          if (page < totalPages) {
            page++
            this.saveTemplate(this.dataTable.size, page, totalPages)
          }
          else {
            this.router.navigate(['groups-business/list-template']);
          }
        }
      );
  }
}


//json de Plantillas
export interface Plantilla {
  id: number,
  name: string,
}

export interface UsuarioEmpresa {
  pageSize: number,
  page: number,
  idcompany: number,
  iduser: number
}

export interface TodosEmpleados {
  idplantillapermisomonitor: number,
  acciones: {
    idusuariosactivar: any[],
    idusuariosdesactivar: any[],
  }
}