import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseData } from 'src/app/dto/ResponseData';

@Injectable({
    providedIn: 'root'
})
export abstract class ConfigurationDataService {
    abstract create(body: any): Observable<ResponseData>;
    abstract read(): Observable<ResponseData>;
    abstract readOne(uuid: any): Observable<ResponseData>;
    abstract update(uuid: any, body: any): Observable<ResponseData>;
}
