import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseData } from 'src/app/dto';

@Injectable({
  providedIn: 'root'
})
export abstract class ContentTypeFieldDataService {
  abstract read(contentTypeUUID: any): Observable<ResponseData>;
  abstract create(contentTypeUUID: any, body: any): Observable<ResponseData>;
  abstract readOne(contentTypeUUID: any, fieldUUID: any): Observable<ResponseData>;
  abstract update(contentTypeUUID: any, fieldUUID: any, body: any): Observable<ResponseData>;
  abstract delete(contentTypeUUID: any, fieldUUID: any): Observable<ResponseData>;
}
